import { message } from 'antd';
import { baseURL } from '../../helpers';
import { fetchRequest } from '../../helpers/fetchRequest';

export const getOadOrders = async (type, filter, sd, ed) => {
	try {
		let url = '';
		if (!filter || filter === '') {
			url = `${baseURL.API_URL_BASE}get-oad-order?order_type=${type}`;
		} else {
			url = `${baseURL.API_URL_BASE}get-oad-order?order_type=${type}&oad_type=${filter}`;
		}

		if (!(!sd || sd === '' || ed === '' || !ed)) {
			url += `&startDate=${sd}&endDate=${ed}`;
		}

		const res = await fetchRequest(url);
		if (res) {
			if (res.hasError) {
				message.error(`Failed to fetch ${type} orders`);
			}
			if (res.data && !res.data.error) {
				return Promise.resolve(res.data);
			}
		}
	} catch {
		message.error('Something went wrong, Request failed!');
	}
};

export const processOrders = async (orders, type = 'flagged') => {
	try {
		const res = await fetchRequest(
			`${baseURL.API_URL_BASE}process-oad-order?type=${type}`,
			{
				method: 'POST',
				body: JSON.stringify(orders),
			}
		);
		if (res) {
			if (res.hasError) {
				message.error('Failed to process orders');
			}
			if (res.data && !res.data.message) {
				message.success('Order processed successfully');
			}
		}
	} catch (err) {
		message.error('Something went wrong, Request failed!');
	}
};
