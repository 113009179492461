import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { generateQuery } from "../../../helpers";

// Ant Design
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Typography from "antd/es/typography";
import { downloadReport } from "../service";
import { Row, List, Space } from "antd";
const { Title } = Typography;

const EngagementReport = () => {
	const [form] = Form.useForm();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async items => {
		const filterItems = [];

		Object.keys(items).map(key => {
			switch (key) {
				case "startDate":
					filterItems.push({
						[key]: startDate
							? moment(startDate).startOf("day").toISOString()
							: null,
					});
					break;

				case "endDate":
					filterItems.push({
						[key]: endDate ? moment(endDate).endOf("day").toISOString() : null,
					});
					break;

				default:
					break;
			}
		});

		const _query = generateQuery(filterItems);

		setIsLoading(true);
		const _res = await downloadReport(_query, "ENGAGEMENT_REPORT");
		if (_res.status === 200) handleClear();
		setIsLoading(false);
	};

	const handleClear = async () => {
		await setStartDate(null);
		await setEndDate(null);
		form.resetFields();
	};

	const formInitialValues = {
		startDate: startDate ? moment(startDate) : null,
		endDate: endDate ? moment(endDate) : null,
	};

	return (
		<Space direction="vertical">
			<Title level={2}>Engagement Report</Title>

			<Form
				form={form}
				initialValues={formInitialValues}
				layout="inline"
				onFinish={onSubmit}>
				<Form.Item name="startDate">
					<DatePicker
						allowClear
						disabledDate={current =>
							endDate && current.isAfter(moment(endDate), "day")
						}
						format={"YYYY-MM-DD"}
						id="start_date_input"
						onChange={e => (e ? setStartDate(e) : setStartDate(null))}
						placeholder="Start Date"
						style={{ width: 176 }}
					/>
				</Form.Item>

				<Form.Item name="endDate">
					<DatePicker
						allowClear
						disabledDate={current =>
							startDate && current.isBefore(moment(startDate), "day")
						}
						format={"YYYY-MM-DD"}
						id="end_date_input"
						onChange={e => (e ? setEndDate(e) : setEndDate(null))}
						placeholder="End Date"
						style={{ width: 176 }}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						disabled={isEmpty(startDate) || isEmpty(endDate)}
						id="generate_report_btn"
						loading={isLoading}
						type="primary"
						htmlType="submit">
						Generate Report
					</Button>
				</Form.Item>
			</Form>
			<Row>
				<Title>Notes</Title>
				<List
					dataSource={fromNotes}
					renderItem={item => (
						<List.Item>
							<Title level={5}>{item}</Title>
						</List.Item>
					)}
				/>
			</Row>
		</Space>
	);
};

const fromNotes = [
	`1.This report generates data related to engagements per order. For each order the latest engagement is shown in this report.`,
	`2. This report also calculates if the order has come after the specified engagement is completed.`,
	`3. Given the date range, there may be multiple engagements Ex: In a typical month, 2-3 engagements of the same order may occur, but in this case only the latest valid engagement for that order is shown in this report.`,
	`4. If an order (#45678) has two engagements Week #2 and Week #3 and an order has been placed after Week #2 of this order (#89071) then the report will show that the latter order has come after Week #2.`,
	`5. If an order (#45678) has two engagements Week #2 and Week #3 and an no order has been placed after Week #4 then the report will show Week #4 in the report. `,
];

export default EngagementReport;
