import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, message, Row, Typography, Upload } from "antd";
import Cookies from "js-cookie";
import { Parser } from "json2csv";
import React, { useState } from "react";
import { getUrl } from "../../../constants/apiUrls";
const { Text, Title, Link } = Typography;

function TransferCustomers() {
	const [fileLoading, setFileLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(true);
	const [fileData, setFileData] = useState({});
	const [totalEnteries, setTotalEnteries] = useState(0);
	const [totalReps, setTotalReps] = useState(0);
	const [totalInvalidEnteries, setTotalInvalidEnteries] = useState(0);

	const downloadCSV = async (status, data, csvName) => {
		try {
			let opts = { includeEmptyRows: true };
			const parser = new Parser(opts);
			const csvString = parser.parse(data);
			const url = window.URL.createObjectURL(new Blob([csvString]));
			let anchorTag = document.createElement("a");
			anchorTag.href = url;
			anchorTag.target = "_Blank";
			anchorTag.download = csvName;
			document.body.appendChild(anchorTag);
			anchorTag.click();
			return Promise.resolve("success");
		} catch (error) {
			message.error("download error");
		}
	};

	const downloadSample = async e => {
		const _fileName = `sample.csv`;
		await downloadCSV(e, sampleData, _fileName);
	};

	const validateTransfer = async _file => {
		let _res = {};
		let res = {};
		try {
			let file = new FormData();
			file.append("file", _file);
			res = await fetch(getUrl("validate-transfer"), {
				method: "POST",
				body: file,
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			});
			_res = await res.json();
			setDisableButton(false);
			setTotalEnteries(_res.count);
			setTotalInvalidEnteries(_res?.incorrectEmailsList?.length);
			setTotalReps(() => {
				if (_res && _res.medrepsList) {
					const set1 = new Set([..._res?.medrepsList]);
					return set1.size;
				}
				return 0;
			});
		} catch (e) {
			console.warn(e.message);
		} finally {
			if (res.status !== 200) {
				message.error(_res.message);
				setDisableButton(true);
				setTotalEnteries(0);
				setTotalInvalidEnteries(0);
				setTotalReps(0);
			} else {
				setDisableButton(false);
			}
		}
	};

	const handleFileUpload = info => {
		if (info.fileList.length !== 0) {
			validateTransfer(info.file);
			setFileData(info.file);
		}
	};

	const beforeUpload = file => {
		if (file.type !== "text/csv" && file.type !== "application/vnd.ms-excel") {
			message.error(`only csv file allowed`);
			return true;
		}
		return false;
	};

	const transfer_customer = async () => {
		let res = {};
		let _res = {};
		try {
			setFileLoading(true);
			let file = new FormData();
			file.append("file", fileData);
			res = await fetch(getUrl("transfer-customers"), {
				method: "POST",
				body: file,
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			});
			_res = await res.json();
		} catch (e) {
			console.warn(e.message);
		} finally {
			if (res.status === 200) {
				message.success(_res, 1, () => {
					setFileLoading(false);
					window.location.reload();
				});
			}
		}
	};

	const handleConfirm = () => {
		transfer_customer();
	};
	const handleRemoveFile = () => {
		setTotalEnteries(0);
		setTotalReps(0);
		setTotalInvalidEnteries(0);
		setDisableButton(true);
	};

	return (
		<div>
			<Title level={2}>Transfer Customers</Title>

			<Row justify="center">
				<Col span={20} className="flex">
					<div className="flex flex-col gap-2 flex-1">
						<Title level={4}>Notes:</Title>
						<Text strong>
							1. Please upload a CSV file that contains the customer_email and
							rep_email.
						</Text>
						<Text strong>
							2. This is one time process that runs as a job, so once submitted,
							it cannot be stopped.
						</Text>
						<Text strong>
							3. An email is sent to ops@tatva.health once the process is
							completed.
						</Text>
						<Text strong>
							4. Please note that Macintosh CSV format is not supported.
						</Text>
					</div>
					<div className="flex flex-col gap-2">
						<Upload
							accept=".csv"
							name="file"
							beforeUpload={file => beforeUpload(file)}
							onChange={handleFileUpload}
							maxCount={1}
							onRemove={handleRemoveFile}>
							<Button icon={<UploadOutlined />}>Upload</Button>
						</Upload>
						<Link underline onClick={downloadSample}>
							Download Sample
						</Link>
					</div>
				</Col>
			</Row>

			<Row justify="center" className="mt-16">
				<Col span={20} className="flex flex-col gap-2">
					<Title level={4}>Summary:</Title>

					<div className="flex justify-between w-full">
						<Text strong>Total Entries in the uploaded file</Text>
						<Text>{totalEnteries}</Text>
					</div>

					<div className="flex justify-between w-full">
						<Text strong>Total Reps in the file</Text>
						<Text>{totalReps}</Text>
					</div>

					<div className="flex justify-between w-full">
						<Text strong>Total Invalid entries</Text>
						<Text>{totalInvalidEnteries}</Text>
					</div>
				</Col>
			</Row>
			<Row justify="center" className="mt-16">
				{totalInvalidEnteries > 0 && (
					<Text type="danger">
						Invalid CSV file. Please correct and re-upload
					</Text>
				)}
			</Row>
			<Row justify="center" className="mt-4">
				<Button
					type="primary"
					disabled={disableButton || totalInvalidEnteries > 0 || !totalEnteries}
					loading={fileLoading}
					onClick={handleConfirm}>
					Confirm
				</Button>
			</Row>
		</div>
	);
}
const sampleData = [
	{ customer_email: "customer@as.com", rep_email: "rep@rep.com" },
];
export default TransferCustomers;
