import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
	AutoComplete,
	Button,
	Card,
	Col,
	message,
	Popover,
	Row,
	Space,
	Table,
	Typography,
} from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../../../helpers";
import { fetchRequest } from "../../../../helpers/fetchRequest";

import { getGlobalSearchMedRepResult } from "../../service";

const { Option } = AutoComplete;
const { Meta } = Card;
const { Text, Title } = Typography;

function TransferMedRepSearch({
	selectedMedRepSearch,
	setSelectedMedRepSearch,
	medRepSearchValue,
	setMedRepSearchValue,
	medRepSearchData,
	setMedRepSearchData,
}) {
	const [isResponse, setIsResponse] = useState(false);
	const [isCloseDropdown, setIsCloseDropdown] = useState(false);
	const [selectedEngagement, setSelectedEngagement] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [medRepName, setMedRepName] = useState("");
	useEffect(() => {
		if (isEmpty(selectedMedRepSearch)) {
			setMedRepSearchValue("");
			setMedRepSearchData([]);
			setSelectedEngagement([]);
			setSelectedKeys([]);
			setMedRepName("");
		}
	}, [selectedMedRepSearch, setMedRepSearchData, setMedRepSearchValue]);

	const columns = [
		{
			title: (
				<>
					<Popover content={"Types of enagaments (i.e Week 1)"}>
						Engagement{"  "}
						<ExclamationCircleOutlined />
					</Popover>
				</>
			),
			dataIndex: "title",
			key: "title",
		},
		{
			title: (
				<>
					<Popover content={"Count of customer with recent engagements"}>
						Count{"  "}
						<ExclamationCircleOutlined />
					</Popover>
				</>
			),
			dataIndex: "count",
			key: "count",
		},
	];

	const handleClear = () => {
		setMedRepSearchValue("");
		setMedRepSearchData([]);
		setSelectedMedRepSearch({});
		setMedRepName("");
	};

	const handleClick = async searchResult => {
		setMedRepName(searchResult.user.first_name);
		const _res = await fetchRequest(
			`${baseURL.API_URL_BASE}assignment-summary/${searchResult.user_id}`
		);
		const response = await fetchRequest(
			`${baseURL.API_URL_BASE}engagementCallCount/${searchResult.user_id}`
		);

		if (!isEmpty(_res.data)) {
			setSelectedMedRepSearch(_res.data);
		}

		if (!isEmpty(response.data)) {
			console.log(response.data);
			let engagementArray = [];
			engagementArray.push(
				{
					key: 0,
					type: "tag",
					title: "Prescription Call",
					value: "Prescription Call",
					count: response.data.prescriptionCall,
				},
				{
					key: 1,
					type: "tag",
					title: "Week 1",
					value: "Week #1",
					count: response.data.week1,
				},
				{
					key: 2,
					type: "tag",
					title: "Week 2",
					value: "Week #2",
					count: response.data.week2,
				},
				{
					key: 3,
					type: "tag",
					title: "Week 3",
					value: "Week #3",
					count: response.data.week3,
				},
				{
					key: 4,
					type: "tag",
					title: "Week 4",
					value: "Week #4",
					count: response.data.week4,
				},
				{
					key: 5,
					type: "tag",
					title: "Week 5",
					value: "Week #5",
					count: response.data.week5,
				},
				{
					key: 6,
					type: "tag",
					title: "Week 6",
					value: "Week #6",
					count: response.data.week6,
				},
				{
					key: 7,
					type: "active_orders",
					title: "Open Order",
					value: _res.data.open_orders,
					count: _res.data.open_orders,
				},
				{
					key: 8,
					type: "engagements_completed",
					title: "Engagements completed",
					value: response.data.engagementsCompleted,
					count: response.data.engagementsCompleted,
				}
			);
			setSelectedEngagement(engagementArray);
		}
	};

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: selectedRowKeys => {
			let findVal = selectedRowKeys
				.filter(e => {
					return selectedEngagement[e];
				})
				.map(value => {
					let selectedTransferVal = selectedEngagement[value];
					return {
						type: selectedTransferVal.type,
						value: selectedTransferVal.value,
					};
				});

			setSelectedMedRepSearch(curr => {
				curr["transferData"] = findVal;
				return { ...curr };
			});
			setSelectedKeys(selectedRowKeys);
		},
	};

	const handleSearch = event => {
		setMedRepSearchValue(event);
		if (isEmpty(event)) setIsCloseDropdown(false);
	};

	const handleSubmit = async () => {
		setIsResponse(true);
		const response = await getGlobalSearchMedRepResult(medRepSearchValue);
		if (!isEmpty(response.userRole)) {
			let findRelatedItem = response.userRole.filter(user =>
				user.user.first_name.toLowerCase().includes(medRepSearchValue)
			);
			setMedRepSearchData(findRelatedItem);

			setIsResponse(false);
			setIsCloseDropdown(true);
		} else {
			message.error("No data availaible");
			setMedRepSearchData([]);
			setIsResponse(false);
		}
	};

	return (
		<>
			<Row>
				<Col>
					<Title level={4} style={{ textAlign: "center" }}>
						From
					</Title>
				</Col>
			</Row>
			<Row>
				<Col span={19}>
					<AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={handleSearch}
						placeholder="Search Med Rep"
						notFoundContent={null}
						open={isCloseDropdown}
						value={medRepSearchValue}
						onSelect={() => setIsCloseDropdown(false)}
						showSearch
						style={{ width: "100%" }}
						suffixIcon={<SearchOutlined />}>
						{!isEmpty(medRepSearchData) &&
							medRepSearchData.map((searchResult, index) => (
								<Option key={index} value={medRepSearchValue}>
									<Card onClick={() => handleClick(searchResult)}>
										<Meta
											title={`${searchResult.user.first_name}`}
											description={
												<Space direction="vertical">
													<Text>{searchResult.user.email}</Text>
													<Space>
														<Text>{searchResult.user.phone_number} </Text>
													</Space>
												</Space>
											}
										/>
									</Card>
								</Option>
							))}
					</AutoComplete>
				</Col>
				<Col span={4}>
					<Space>
						<Button
							style={{ marginLeft: "9px" }}
							icon={<SearchOutlined />}
							type="primary"
							onClick={handleSubmit}
							disabled={isEmpty(medRepSearchValue)}
							loading={isResponse}
						/>
						<Button onClick={handleClear} disabled={isEmpty(medRepSearchValue)}>
							Clear
						</Button>
					</Space>
				</Col>
			</Row>

			<Row>
				<Col
					style={{
						width: "100%",
						height: "20rem",
						padding: "1rem",
						border: "2px solid #f0f0f0",
						marginTop: "1rem",
						overflow: "auto",
					}}>
					{medRepName && (
						<Row>
							<Space style={{ width: "auto" }}>
								<Title level={5}>Name: </Title>

								<Title level={5}>
									<span level={5} style={{ width: "230px" }}>
										{medRepName}
									</span>
								</Title>
							</Space>
						</Row>
					)}

					<Row style={{ justifyContent: "space-between" }}>
						<Space style={{ width: "auto" }}>
							<Title level={5}>Total Patients: </Title>
							<Title level={5}>
								<span level={5} style={{ width: "230px" }}>
									{selectedMedRepSearch?.cases}
								</span>
							</Title>
						</Space>
						<Space style={{ width: "auto" }}>
							<Title level={5}>Active Patients:</Title>
							<Title level={5}>{selectedMedRepSearch?.active_cases}</Title>
						</Space>
						<Space style={{ width: "auto" }}>
							<Title level={5}>Open Orders: </Title>
							<Title level={5}>{selectedMedRepSearch?.open_orders}</Title>
						</Space>
					</Row>

					<Row>
						<Table
							rowSelection={rowSelection}
							columns={columns}
							dataSource={selectedEngagement}
							pagination={false}
							style={{ width: "100%" }}
						/>
					</Row>
				</Col>
			</Row>
		</>
	);
}
export default TransferMedRepSearch;
