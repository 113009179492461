import React, { useEffect, useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getGhostOrders } from "../service";
import { getLineItemsName, getQueryParams, getValue } from "../../../helpers";
import AssignMedicalRep from "./AssignMedicalRep";
import { Col, Row, Table, Tag, Typography } from "antd";
const { Text, Title } = Typography;

function GhostOrders() {
	const history = useHistory();
	const [paginationInfo, setPaginationInfo] = useState({});
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [data, setData] = useState([]);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("current")) || 1
	);

	useEffect(() => {
		getGhostOrders(pageNo);
	}, [pageNo]);

	const { ghostOrders, loading } = useSelector(state => ({
		loading: getValue(state, "orderManagement.ghostOrdersLoading"),
		ghostOrders: getValue(state, "orderManagement.ghostOrders"),
	}));

	useEffect(() => {
		if (isEmpty(ghostOrders)) setData([]);
		else {
			const ndata = ghostOrders.data.map(row => ({
				key: getValue(row, "id"),
				name: getValue(row, "order_meta.customer.first_name"),
				phone_Number: getValue(row, "order_phone_number"),
				order_display_id: getValue(row, "order_display_id"),
				order_name: getLineItemsName(row.order_meta?.line_items),
				order_date: getValue(row, "created_at"),
				assigned_to: getValue(row, "assignment.first_name"),
				order_type: getValue(row, "order_type"),
			}));
			setData(ndata);
			setPaginationInfo(ghostOrders.pagination);
		}
	}, [ghostOrders]);

	useEffect(() => {
		if (!isEmpty(allSelectedOrders)) {
			let currPageData = [];
			let currPageKeys = [];
			let otherPageData = [];
			allSelectedOrders.map(row => {
				if (data.some(i => i.key === row.key)) {
					currPageData = [...currPageData, row];
					currPageKeys = [...currPageKeys, row.key];
					return currPageKeys;
				} else {
					return (otherPageData = [...otherPageData, row]);
				}
			});
			setSelectedKeys(currPageKeys);
			setSelectionFromOtherPage(otherPageData);
		}
	}, [allSelectedOrders, data]);

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectionFromOtherPage, ...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	const updateUi = () => {
		getGhostOrders(pageNo);
		setAllSelectedOrders([]);
		setSelectedKeys([]);
		setSelectionFromOtherPage([]);
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (name, { key, phone_Number }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>
					<Text type="secondary">{phone_Number}</Text>
				</div>
			),
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},
		{
			title: "Order Name",
			dataIndex: "order_name",
			key: "order_name",
			width: 310,
		},
		{
			title: "Order Date",
			dataIndex: "order_date",
			key: "order_date",
			render: order_date => moment(order_date).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned To",
			dataIndex: "assigned_to",
			key: "assigned_to",
		},
	];

	return (
		<>
			<Row>
				<Col>
					<AssignMedicalRep
						selectedOrders={allSelectedOrders}
						updateUi={updateUi}
					/>
				</Col>
			</Row>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>Ghost Orders</Title>
				</Col>
			</Row>

			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				rowSelection={rowSelection}
				pagination={{
					current: pageNo,
					pageSize: 10,
					showSizeChanger: false,
					total: paginationInfo.rowCount,
				}}
				onChange={e => {
					setPageNo(e.current);
					return history.push({
						search: `current=${e.current}`,
					});
				}}
			/>
		</>
	);
}

export default GhostOrders;
