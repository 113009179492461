import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { getMyBookings } from "../service";
import { generateQuery, getQueryParams, getValue } from "../../../helpers";
import { Col, Row, Table, Tag, Typography } from "antd";
const { Title, Text } = Typography;

const SlotBookings = () => {
	const history = useHistory();
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("slot_booking")) || 1
	);

	useEffect(() => {
		getMyBookings();
	}, []);

	useEffect(() => {
		const filterItems = [{ slot_booking: pageNo }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });
	}, [history, pageNo]);

	const { bookingsLoading, bookings } = useSelector(state => ({
		bookingsLoading: getValue(state, "leadmanagement.bookingsLoading"),
		bookings: getValue(state, "leadmanagement.bookings"),
	}));

	const myBookings = useMemo(() => {
		if (isEmpty(bookings)) return [];
		return bookings.map(item => ({
			key: getValue(item, "id"),
			case_id: getValue(item, "case.id"),
			name: getValue(item, "case.user.first_name"),
			phoneno: getValue(item, "case.user.phone_number"),
			email: getValue(item, "case.user.email"),
			status: getValue(item, "case.status"),
			tag: "slot_booking",
			time: getValue(item, "slot.slot_time"),
		}));
	}, [bookings]);

	const column = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { case_id, phoneno }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${case_id}`}>
						{name}
					</Link>

					<Text>{phoneno}</Text>
				</div>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Tag",
			dataIndex: "tag",
			key: "tag",
			render: tag => {
				if (isEmpty(tag)) return "-";
				return (
					<Tag key={tag} color="geekblue">
						{tag.toUpperCase()}
					</Tag>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status = "") => (
				<Tag key={status} color="cyan">
					{status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
			defaultSortOrder: false,
			render: time => moment(time).format("DD MMM YYYY, h:mm a"),
			sorter: (a, b) => moment(a.time) - moment(b.time),
		},
	];

	return (
		<Row>
			<Col>
				<Title level={4}>Slot Bookings</Title>
			</Col>

			<Col span={24}>
				<Table
					columns={column}
					dataSource={myBookings}
					loading={bookingsLoading}
					onChange={e => setPageNo(e.current)}
					pagination={{
						current: pageNo,
						showSizeChanger: false,
						pageSize: 5,
					}}
				/>
			</Col>
		</Row>
	);
};

export default SlotBookings;
