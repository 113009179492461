import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AssignedLeads from "./pages/assignedLeads/components/AssignedLeads";
import {
	DashboardAdmin,
	DashboardDispatch,
	DashboardDoctor,
	DashboardMedicalRep,
	DashboardSales,
} from "./pages/dashboard/components";
import { AllCases, RecentLeads } from "./pages/leadManagement/components";
import AllLeads from "./pages/leadManagement/components/AllLeads";
import AllOrders from "./pages/orderManagement/components/AllOrders";
import AssignedDoctors from "./pages/orderManagement/components/AssignedDoctors";
import AssignedGhostOrders from "./pages/orderManagement/components/AssignedGhostOrders";
import AssignedOrders from "./pages/orderManagement/components/AssignedOrders";
import GhostOrders from "./pages/orderManagement/components/GhostOrders";
import OpenOrders from "./pages/orderManagement/components/OpenOrders";
import RecentOrders from "./pages/orderManagement/components/RecentOrders";
import UsersManagement from "./pages/usersManagment/components/UsersManagment";
// import ReOrderReport from "./pages/report/component/ReOrderReport";
// import PrescriptionReport from "./pages/report/component/PrescriptionReport";
// import EngagementPlanReport from "./pages/report/component/EngagementPlanReport";
import { getCurrentUserRole } from "./helpers";
import { Medicines, ProductMapping, Tags } from "./pages/configure/components";
import ViewDetailsByConsultRep from "./pages/consultRep/ViewDetailsByConsultRep";
import DashboardConsultRep from "./pages/dashboard/components/DashboardConsultRep";
import Search from "./pages/dispatch/components/Search";
import { Prescription } from "./pages/doctors/components";
import { EscalatedCasesForReps } from "./pages/EscalatedCasesForReps";
import {
	ManagePrescriptions,
	ViewDetailsByAdmin,
	ViewDetailsByDoctor,
	ViewDetailsByMedRep,
	ViewDetailsByReadOnly,
	ViewDetailsBySales,
	ViewDetailsBySupport,
} from "./pages/leadDetails/components";
import ManageWeeklySchedules from "./pages/manageWeeklySchedules/ManageWeeklySchedules";
import TransferOrder from "./pages/orderManagement/components/TransferOrder";
import OrderData from "./pages/report/component/OrderData";
import OrderLinkReport from "./pages/report/component/OrderLinkReport";
import UTMReport from "./pages/report/component/UTMReport";
import { SlotBookings } from "./pages/SlotBookings";
import GroupManagment from "./pages/supervisor/components/GroupManagment";
import MyTeam from "./pages/supervisor/components/MyTeam";
// import UTMAggregation from "./pages/report/component/UTMAggregation";
import DashboardConsultDoctor from "./pages/dashboard/components/DashboardConsultDoctor";
import DiscountCodes from "./pages/DiscountCodes";
import PrescribedPatients from "./pages/doctors/components/PrescribedPatients";
// import { TransferCustomers } from "./pages/leadManagement/components";
import { B2BOrders, FlaggedOrders } from "./components/ODA";
import OnholdOrders from "./components/ODA/components/Onhold";
import { WarehouseInwards, WarehouseSkus } from "./components/Warehouse";
import TransferCustomersV2 from "./pages/leadManagement/components/TransferCustomerV2/TransferCustomerv2";
import ManageMedRepEngagements from "./pages/manageWeeklySchedules/ManageMedRepEngagements";
import SupervisorsManageWeeklySchedules from "./pages/manageWeeklySchedules/SupervisorsManageWeeklySchedules";
import MissedCalls from "./pages/missed_calls";
import ActiveCustomerReport from "./pages/report/component/ActiveCustomerReport";
import AdherenceReport from "./pages/report/component/AdherenceReport";
import EngagementReport from "./pages/report/component/EngagementReport";
import EngagementStatusReport from "./pages/report/component/EngagementStatusReport";
import SalesReport from "./pages/report/component/SalesReport";
import UnorderedReport from "./pages/report/component/UnorderedReport";
import WeeklyEngagementAnsweredReport from "./pages/report/component/WeeklyEngagementAnsweredReport";
import SalesManagement from "./pages/SalesManagement";
import SalesMyOrders from "./pages/salesMyOrders";
import SettingsPage from "./pages/settings/SettingsPage";
import Tickets from "./pages/ticketing";
import TodaysEngagementByMedRep from "./pages/TodaysEngagementByMedRep";

function Routes() {
	const [userRole] = useState(getCurrentUserRole());

	return (
		<Switch>
			<Route exact path="/home">
				{/* {userRole === "admin" && <DashboardAdmin />} */}
				{userRole === "sales" && <DashboardSales />}
				{userRole === "dispatch" && <DashboardDispatch />}
				{userRole === "medical_rep" && <DashboardMedicalRep />}
				{userRole === "doctor" && <DashboardDoctor />}
				{userRole === "consult_doctor" && <DashboardConsultDoctor />}
				{userRole === "support" && <Redirect to="/case-management/all-cases" />}
				{userRole === "read_only" && (
					<Redirect to="/case-management/all-cases" />
				)}
				{userRole === "consult_rep" && <DashboardConsultRep />}
			</Route>

			<Route exact path="/prescribed-patients">
				{userRole === "doctor" && <PrescribedPatients />}
			</Route>

			<Route exact path="/case-management/new-leads">
				{userRole === "admin" && <RecentLeads />}
			</Route>

			<Route exact path="/case-management/all-cases">
				{showAllCases.has(userRole) && <AllCases />}
			</Route>

			<Route exact path="/order-management/recent-orders">
				{userRole === "admin" && <RecentOrders />}
			</Route>

			<Route exact path="/case-management/all-leads">
				{userRole === "admin" && <AllLeads />}
			</Route>

			<Route exact path="/case-management/sales-management">
				{userRole === "admin" && <SalesManagement />}
			</Route>

			<Route exact path="/order-management/ghost-orders">
				{userRole === "admin" && <GhostOrders />}
			</Route>

			{/* <Route exact path="/order-management/transfer-orders">
				{userRole === "admin" && <TransferOrder />}
			</Route> */}

			<Route exact path="/order-management/all-orders">
				{userRole === "admin" && <AllOrders />}
			</Route>

			<Route exact path="/order-management/open-orders">
				{userRole === "admin" && <OpenOrders />}
			</Route>

			{/* <Route exact path="/report/reorder">
				{userRole === "admin" && <ReOrderReport />}
			</Route> */}

			{/* <Route exact path="/report/unordered-report">
				{userRole === "admin" && <UnorderedReport />}
			</Route> */}

			{/* <Route exact path="/report/engagement-plan-report">
				{userRole === "admin" && <EngagementPlanReport />}
			</Route> */}

			{/* <Route exact path="/report/prescription-report">
				{userRole === "admin" && <PrescriptionReport />}
			</Route> */}

			{/* <Route exact path="/report/utm-report">
				{userRole === "admin" && <UTMReport />}
			</Route>
			<Route exact path="/report/adherence-report">
				{userRole === "admin" && <AdherenceReport />}
			</Route>
			<Route exact path="/report/sales-report">
				{userRole === "admin" && <SalesReport />}
			</Route> */}

			{/* <Route exact path="/report/utm-aggregation-report">
				{userRole === "admin" && <UTMAggregation />}
			</Route> */}

			{/* <Route exact path="/report/orderLink-report">
				{userRole === "admin" && <OrderLinkReport />}
			</Route>

			<Route exact path="/report/active-customer-report">
				{userRole === "admin" && <ActiveCustomerReport />}
			</Route>
			<Route exact path="/report/feedback-report">
				{userRole === "admin" && <WeeklyEngagementAnsweredReport />}
			</Route>
			<Route exact path="/report/engagement-feedback-report">
				{userRole === "admin" && <EngagementStatusReport />}
			</Route>

			<Route exact path="/report/engagement-report">
				{userRole === "admin" && <EngagementReport />}
			</Route> */}
			{/*
			<Route exact path="/users-management">
				{userRole === "admin" && <UsersManagement />}
			</Route> */}

			{/* <Route exact path="/configure/medicines">
				{userRole === "admin" && <Medicines />}
			</Route> */}

			{/* <Route exact path="/configure/products-mapping">
				{userRole === "admin" && <ProductMapping />}
			</Route> */}

			<Route exact path="/configure/settings">
				{userRole === "admin" && <SettingsPage />}
			</Route>

			{/* <Route exact path="/configure/tags">
				{userRole === "admin" && <Tags />}
			</Route> */}

			<Route path="/lead-details/:id">
				{userRole === "admin" && <ViewDetailsByAdmin />}
				{userRole === "sales" && <ViewDetailsBySales />}
				{userRole === "medical_rep" && <ViewDetailsByMedRep />}
				{userRole === "doctor" && <ViewDetailsByDoctor />}
				{userRole === "support" && <ViewDetailsBySupport />}
				{userRole === "read_only" && <ViewDetailsByReadOnly />}
				{userRole === "consult_rep" && <ViewDetailsByConsultRep />}
				{userRole === "consult_doctor" && <ViewDetailsBySales />}
			</Route>

			<Route path="/assigned-leads">
				{userRole === "sales" && <AssignedLeads />}
			</Route>

			<Route path="/assigned-orders">
				{userRole === "medical_rep" && <AssignedOrders />}
			</Route>

			<Route path="/assigned-ghost-orders">
				{userRole === "medical_rep" && <AssignedGhostOrders />}
			</Route>

			<Route path="/doctors/prescription/:id">
				{userRole === "doctor" && <Prescription />}
			</Route>

			<Route path="/prescription/:id">
				{showManagePrescriptions.has(userRole) && <ManagePrescriptions />}
			</Route>

			{/* <Route path="/group-management">
				{userRole === "admin" && <GroupManagment />}
			</Route> */}

			<Route path="/myteam">
				<MyTeam />
			</Route>

			<Route path="/search">{userRole === "dispatch" && <Search />}</Route>

			<Route path="/report/order-data">
				{userRole === "medical_rep" && <OrderData />}
			</Route>

			<Route exact path="/order-management/assigned-doctors">
				{userRole === "admin" && <AssignedDoctors />}
			</Route>

			<Route exact path="/case-management/slot-bookings">
				{userRole === "admin" && <SlotBookings />}
			</Route>

			{/* <Route exact path="/case-management/transfer-customers">
				{userRole === "admin" && <TransferCustomers />}
			</Route> */}
			<Route exact path="/case-management/transfer-customers">
				{userRole === "admin" && <TransferCustomersV2 />}
			</Route>

			<Route exact path="/escalated-cases">
				{showEscalatedPageForReps.has(userRole) && <EscalatedCasesForReps />}
			</Route>

			<Route exact path="/todays-engagement/:id">
				{userRole === "medical_rep" && <TodaysEngagementByMedRep />}
			</Route>

			{/* <Route exact path="/discount-codes">
				{userRole === "admin" && <DiscountCodes />}
			</Route> */}
			<Route exact path="/manage-weekly-schedules">
				{/* {userRole === "admin" && <ManageWeeklySchedules />} */}
				{userRole === "medical_rep" && <SupervisorsManageWeeklySchedules />}
			</Route>
			<Route exact path="/med-rep-engagement/:id">
				{showMedRepEngagement.has(userRole) && (
					<ManageMedRepEngagements role={userRole} />
				)}
			</Route>

			<Route exact path="/my-orders">
				{userRole === "sales" && <SalesMyOrders />}
			</Route>

			<Route exact path="/missed-calls">
				{userRole === "medical_rep" && <MissedCalls />}
			</Route>

			<Route exact path="/flagged-orders">
				{(userRole === "dispatch" || userRole === "admin") && <FlaggedOrders />}
			</Route>

			<Route exact path="/b2b-orders">
				{(userRole === "dispatch" || userRole === "admin") && <B2BOrders />}
			</Route>

			<Route exact path="/hold-orders">
				{(userRole === "dispatch" || userRole === "admin") && <OnholdOrders />}
			</Route>

			<Route exact path="/warehouse-inwards">
				{userRole === "admin" && <WarehouseInwards />}
			</Route>

			<Route exact path="/warehouse-skus">
				{userRole === "admin" && <WarehouseSkus />}
			</Route>

			<Route exact path="/my-tickets">
				<Tickets />
			</Route>
		</Switch>
	);
}

export default Routes;

const showAllCases = new Set(["admin", "support", "read_only"]);
const showEscalatedPageForReps = new Set(["medical_rep", "sales", "doctor"]);
const showMedRepEngagement = new Set(["admin", "medical_rep"]);
const showManagePrescriptions = new Set([
	"admin",
	"medical_rep",
	"support",
	"read_only",
	"consult_rep",
]);
