import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import rawQuestions from '../../configs/consultCallQuestionnaire.json';
import components from './components';
import { fetchRequest } from '../../helpers/fetchRequest';
import { CONSULT_CALL_FEEDBACK_API } from '../../constants/apiUrls';
import { getValue } from '../../helpers';

// Antd
import { Button, Form, message, Modal } from 'antd/es';
import { Loader } from '../../common';

const ConsultCallForm = ({ modalVisible, setModalVisible, updateUI }) => {
	const [form] = Form.useForm();
	const { id: case_id } = useParams();
	const [loading, setLoading] = useState(false);

	const { feedbacks, loadingFeedback } = useSelector(state => ({
		feedbacks: getValue(state, 'leaddetails.consultCallFeedback'),
		loadingFeedback: getValue(state, 'leaddetails.consultCallFeedbackLoading'),
	}));

	useEffect(() => {
		if (!feedbacks) return;
		form.setFieldsValue(feedbacks);
	}, [feedbacks, form]);

	const closeModal = () => {
		form.setFieldsValue(feedbacks);
		setModalVisible(false);
	};

	const handleSubmit = async data => {
		let res = {};
		try {
			setLoading(true);
			res = await fetchRequest(CONSULT_CALL_FEEDBACK_API(case_id), {
				method: 'POST',
				body: JSON.stringify({ feedback: data, tag: 'general' }),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) message.error('Failed');
			else {
				message.success('Updated successfully');
				updateUI();
				closeModal();
			}
			setLoading(false);
		}
	};

	if (loadingFeedback) return <Loader />;

	return (
		<Modal
			visible={modalVisible}
			title='Consult call feedback'
			onOk={closeModal}
			onCancel={closeModal}
			footer={[
				<Button key='back' onClick={closeModal}>
					Cancel
				</Button>,
			]}>
			<Form
				form={form}
				layout='vertical'
				name='form'
				onFinish={handleSubmit}
				requiredMark={true}>
				{rawQuestions.map(question => (
					<Form.Item
						key={question.id}
						label={question.text}
						name={question.text}
						required={question.required}
						rules={question.rules}>
						{components(question)}
					</Form.Item>
				))}

				<div className='flex w-full justify-center'>
					<Form.Item>
						<Button loading={loading} type='primary' htmlType='submit'>
							Save
						</Button>
					</Form.Item>
				</div>
			</Form>
		</Modal>
	);
};

export default ConsultCallForm;
