import {
	Badge,
	Button,
	DatePicker,
	Form,
	Select,
	Space,
	Typography,
} from "antd/es";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { optionsCaseStatus, optionsFormStatus } from "../constants/options";
import {
	feedOptions,
	generateQuery,
	getCurrentUserRole,
	getQueryParams,
	getValue,
} from "../helpers";
const { Option } = Select;
const { Text } = Typography;

const LeadFilters = ({ clearSelection, setPageNo, setQueryParams }) => {
	const [form] = Form.useForm();
	const [caseStatus, setCaseStatus] = useState(getQueryParams("caseStatus"));
	const [feedback, setFeedback] = useState(getQueryParams("feedback"));
	const [formStatus, setFormStatus] = useState(getQueryParams("formStatus"));
	const [startDate, setStartDate] = useState(
		getQueryParams("startDate")
			? getQueryParams("startDate")
			: moment().format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState(getQueryParams("endDate"));
	const [gender, setGender] = useState(getQueryParams("gender"));
	const [assignedTo, setAssignedTo] = useState(getQueryParams("assignedTo"));
	const [repLevel, setRepLevel] = useState(getQueryParams("repLevel"));
	const [category, setCategory] = useState(getQueryParams("category"));
	const [slot, setSlot] = useState(getQueryParams("slot"));
	const [currPathName] = useState(window.location.pathname);
	const [userRole] = useState(getCurrentUserRole());

	useEffect(() => {
		form.setFieldsValue({
			startDate: startDate ? moment(startDate) : moment(),
			endDate: endDate ? moment(endDate) : null,
			caseStatus: caseStatus,
			feedback: feedback,
			formStatus: formStatus,
			assignedTo: assignedTo,
			repLevel: repLevel,
			category: category,
			slot: slot,
			gender: gender,
		});
	});

	useEffect(() => {
		const init = async () => {
			const filterItems = [];
			filterItems.push({
				startDate: startDate
					? moment(startDate).format("YYYY-MM-DD")
					: moment().format("YYYY-MM-DD"),
			});
			const queryParams = await generateQuery(filterItems);
			setQueryParams(queryParams);
		};
		init();
	}, []);

	const { consultReps, medicalReps, salesReps } = useSelector(state => ({
		consultReps: getValue(state, "orderManagement.consultReps.userRole"),
		medicalReps: getValue(state, "orderManagement.medicalReps.userRole"),
		salesReps: getValue(state, "leadmanagement.salesReps.userRole"),
	}));

	const onSubmit = async items => {
		const filterItems = [];
		Object.entries(items).map(([key, value]) => {
			if (key === "startDate") {
				filterItems.push({
					[key]: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
				});
			} else if (key === "endDate") {
				filterItems.push({
					[key]: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
				});
			} else {
				filterItems.push({ [key]: value });
			}
			filterItems.push({ pageNumber: 1 });
			return filterItems;
		});
		const queryParams = await generateQuery(filterItems);
		setQueryParams(queryParams);
		setPageNo(1);
		if (clearSelection) clearSelection();
	};

	const handleClear = () => {
		form.resetFields();
		setCaseStatus(null);
		setFeedback(null);
		setGender(null);
		setFormStatus(null);
		setStartDate(moment().format("YYYY-MM-DD"));
		setEndDate(null);
		setAssignedTo(null);
		setRepLevel(null);
		setSlot(null);
		form.submit();
	};

	return (
		<>
			<Form
				className="order-filter"
				form={form}
				layout="inline"
				onFinish={onSubmit}>
				{currPathName !== "/home" && (
					<>
						<Form.Item name="startDate">
							<DatePicker
								allowClear
								disabledDate={current =>
									current.isAfter(moment(endDate ? endDate : null), "day")
								}
								format={"YYYY-MM-DD"}
								onChange={e => (e ? setStartDate(e) : setStartDate(moment()))}
								placeholder="Start Date"
							/>
						</Form.Item>
						<Form.Item name="endDate">
							<DatePicker
								allowClear
								disabledDate={current =>
									(startDate && current.isBefore(moment(startDate), "day")) ||
									current.isAfter(moment(), "day")
								}
								format={"YYYY-MM-DD"}
								onChange={e => (e ? setEndDate(e) : setEndDate(null))}
								placeholder="End Date"
							/>
						</Form.Item>
					</>
				)}

				{currPathName === "/case-management/all-leads" && (
					<Form.Item name="gender">
						<Select
							allowClear
							dropdownStyle={{ minWidth: "6rem" }}
							options={genderOptions}
							onChange={e => (e ? setGender(e) : setGender(null))}
							placeholder="Gender"
						/>
					</Form.Item>
				)}

				{currPathName !== "/case-management/new-leads" && (
					<Form.Item name="caseStatus">
						<Select
							allowClear
							dropdownStyle={{ minWidth: "6rem" }}
							options={optionsCaseStatus}
							onChange={e => (e ? setCaseStatus(e) : setCaseStatus(null))}
							placeholder="Status"
						/>
					</Form.Item>
				)}

				{(currPathName === "/case-management/all-cases" ||
					currPathName === "/case-management/all-leads" ||
					currPathName === "/assigned-leads") && (
					<Form.Item name="feedback">
						<Select
							allowClear
							dropdownStyle={{ minWidth: "12rem" }}
							options={feedOptions}
							onChange={e => (e ? setFeedback(e) : setFeedback(null))}
							placeholder="Feedback"
						/>
					</Form.Item>
				)}

				{userRole !== "sales" && (
					<Form.Item name="formStatus">
						<Select
							allowClear
							dropdownStyle={{ minWidth: "6rem" }}
							options={optionsFormStatus}
							onChange={e => (e ? setFormStatus(e) : setFormStatus(null))}
							placeholder="Form Status"
						/>
					</Form.Item>
				)}
				{(currPathName === "/case-management/all-cases" ||
					currPathName === "/case-management/all-leads") && (
					<Form.Item name="assignedTo">
						<Select
							allowClear
							dropdownStyle={{ minWidth: 240 }}
							onChange={e => (e ? setAssignedTo(e) : setAssignedTo(null))}
							optionFilterProp="label"
							placeholder="Assigned To"
							showSearch
							style={{ width: 128 }}>
							{!isEmpty(salesReps) &&
								salesReps.map(({ user }) => (
									<Option label={user.first_name} value={user.id} key={user.id}>
										<Space align="center">
											<Text>{user.first_name}</Text>
											<Badge
												count="Sales Rep"
												style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
											/>
										</Space>
									</Option>
								))}

							{!isEmpty(medicalReps) &&
								medicalReps.map(({ user }) => (
									<Option label={user.first_name} value={user.id} key={user.id}>
										<Space align="center">
											<Text>{user.first_name}</Text>
											<Badge
												count="Medical Rep"
												style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
											/>
										</Space>
									</Option>
								))}

							{!isEmpty(consultReps) &&
								consultReps.map(({ user }) => (
									<Option label={user.first_name} value={user.id} key={user.id}>
										<Space align="center">
											<Text>{user.first_name}</Text>
											<Badge
												count="Consult Rep"
												style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
											/>
										</Space>
									</Option>
								))}

							<Option label="N/A" value="NA">
								<Space align="center">
									<Text>N/A</Text>
									<Badge
										count="Not Assigned"
										style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
									/>
								</Space>
							</Option>
						</Select>
					</Form.Item>
				)}

				{(currPathName === "/case-management/all-cases" ||
					currPathName === "/case-management/all-leads") && (
					<Form.Item name="repLevel">
						<Select
							allowClear
							options={levelOptions}
							onChange={e => (e ? setRepLevel(e) : setRepLevel(null))}
							placeholder="Level"
							style={{ width: "5rem" }}
						/>
					</Form.Item>
				)}

				{(currPathName === "/case-management/all-cases" ||
					currPathName === "/case-management/all-leads" ||
					currPathName === "/case-management/new-leads") && (
					<Form.Item name="category">
						<Select
							allowClear
							options={categoryOptions}
							onChange={e => (e ? setCategory(e) : setCategory(null))}
							placeholder="Form Type"
							style={{ width: "8rem" }}
						/>
					</Form.Item>
				)}

				{(currPathName === "/case-management/all-cases" ||
					currPathName === "/case-management/all-leads" ||
					currPathName === "/case-management/new-leads") && (
					<Form.Item name="slot">
						<Select
							allowClear
							options={slotOptions}
							onChange={e => (e ? setSlot(e) : setSlot(null))}
							placeholder="Slot"
							style={{ width: "8rem" }}
						/>
					</Form.Item>
				)}

				<Form.Item>
					<div className="flex gap-1 ml-2">
						<Button type="primary" htmlType="submit">
							Apply
						</Button>

						<Button htmlType="button" onClick={handleClear}>
							Clear
						</Button>
					</div>
				</Form.Item>
			</Form>
			<Text strong>* Filter as per last form fill date</Text>
		</>
	);
};

export default LeadFilters;

const levelOptions = [
	{ label: "1", value: 1 },
	{ label: "2", value: 2 },
	{ label: "3", value: 3 },
];

const categoryOptions = [
	{ label: "Hair", value: "hair" },
	{ label: "Cholesterol", value: "cholesterol" },
	{ label: "Next Form", value: "next_form" },
];

const slotOptions = [
	{ label: "Yes", value: "Yes" },
	{ label: "No", value: "No" },
];

const genderOptions = [
	{ label: "Male", value: "M" },
	{ label: "Female", value: "F" },
];
