import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import { BookedConsultCall } from "../../../components";
import MedRepEngagements from "../../../components/dashboard/medrep/MedRepEngagements";
import MedRepEngagementsV2 from "../../../components/dashboard/medrepV2";
import { fetchTempData } from "../../../components/dashboard/medrepV2/api";
import AssignmentSummary from "../../../components/dashboard/medrepV2/components/AssignmentSummary";
import Engagements from "../../../components/dashboard/medrepV2/components/Engagements";
import OverdueEngagements from "../../../components/dashboard/medrepV2/components/OverdueEngagements";
import TopayEngagementsV2 from "../../../components/dashboard/medrepV2/components/TodaysEngagements";
import {
	mergeEngagementsFn,
	transformTempEngagements,
	transformtempReminders,
} from "../../../components/dashboard/medrepV2/helpers";
import {
	INITIAL_VALUE,
	reducer,
} from "../../../components/dashboard/medrepV2/store";
import { generateQuery, getQueryParams } from "../../../helpers";
import {
	NewAssignedOrders,
	RepeatAssignedOrders,
	TodaysEngagements,
} from "../../orderManagement/components";

// Ant Design
import { Col, Row, Tabs, Typography } from "antd";
const { TabPane } = Tabs;
const { Title } = Typography;

const repLevel = localStorage.getItem("rep!level");

const DashboardMedicalRep = () => {
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);
	const [currTab, setCurrTab] = useState(getQueryParams("currTab") || "home");
	const [pageNo, setPageNo] = useState(Number(getQueryParams("tppage")) || 1);
	const { tempEngagements, tempReminders } = state;

	const handleTabChange = key => {
		history.push({ search: `currTab=${key}` });
		setCurrTab(key);
	};

	useEffect(() => {
		const medrepId = localStorage.getItem("users");
		if (!medrepId) return;
		fetchTempData(medrepId, dispatch);
	}, []);

	useEffect(() => {
		const filterItems = [{ tppage: pageNo }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });
	}, [history, pageNo]);

	const mergedEngagements = useMemo(() => {
		return mergeEngagementsFn({
			tempEngagements: transformTempEngagements(tempEngagements),
			tempReminders: transformtempReminders(tempReminders),
		});
	}, [tempEngagements, tempReminders]);

	const refreshUI = () => {
		const medrepId = localStorage.getItem("users");
		if (!medrepId) return;
		fetchTempData(medrepId, dispatch);
	};

	return (
		<div className="grid gap-4">
			{repLevel === "1" && (
				<AssignmentSummary medRepId={localStorage.getItem("users")} />
			)}

			<Tabs onChange={handleTabChange} defaultActiveKey={currTab} type="card">
				{repLevel === "1" ? (
					<TabPane tab="Home" key="home">
						<MedRepEngagementsV2
							medRepId={localStorage.getItem("users")}
							showCreateTicket={true}
						/>
					</TabPane>
				) : (
					<TabPane tab="Home" key="home">
						<MedRepEngagements
							medRepId={localStorage.getItem("users")}
							showCreateTicket={true}
						/>
					</TabPane>
				)}

				{repLevel !== "3" && (
					<TabPane tab="Actionables" key="actionables">
						{repLevel !== "1" && <BookedConsultCall />}

						{repLevel !== "1" && (
							<Row gutter={[8, 8]}>
								<Col span={24}>
									<Title level={4}>Open Orders</Title>
								</Col>
								<Col span={11}>
									<NewAssignedOrders />
								</Col>
								<Col span={13}>
									<RepeatAssignedOrders />
								</Col>
							</Row>
						)}

						{repLevel === "1" && (
							<div className="mt-4">
								<Engagements
									data={mergedEngagements}
									title={"Temporary Engagements"}
									refreshUI={refreshUI}
									pageNo={pageNo}
									setPageNo={setPageNo}
								/>
							</div>
						)}

						<Title level={4}>Today's Engagements</Title>
						{repLevel === "1" ? (
							<TopayEngagementsV2 medRepId={localStorage.getItem("users")} />
						) : (
							<TodaysEngagements />
						)}
					</TabPane>
				)}

				{repLevel === "1" && (
					<TabPane tab="Overdue" key="overdue">
						<OverdueEngagements medRepId={localStorage.getItem("users")} />
					</TabPane>
				)}
			</Tabs>
		</div>
	);
};

export default DashboardMedicalRep;
