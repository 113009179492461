import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Antd
import { Button, Col, DatePicker, Form, Row } from 'antd/es';
import { useHistory } from 'react-router-dom';
import { generateQuery, getQueryParams } from '../../../../helpers';

export const DateFilter = ({
	startDate,
	endDate,
	handleSubmit,
	isLoading,
	startDisableDate,
	endDisableDate,
}) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [sd, setSd] = useState(() => {
		if (startDate && typeof startDate === 'object') {
			return moment(startDate);
		} else if (getQueryParams('sd')) {
			return moment(getQueryParams('sd'));
		} else {
			return null;
		}
	});
	const [ed, setEd] = useState(() => {
		if (endDate && typeof endDate === 'object') {
			return moment(endDate);
		} else if (getQueryParams('ed')) {
			return moment(getQueryParams('ed'));
		} else {
			return null;
		}
	});

	const [queryParams, setQueryParams] = useState('');

	const _handleSubmit = () => {
		handleSubmit(sd, ed);
	};

	const resetDate = () => {
		setSd('');
		setEd('');
		handleSubmit();
	};
	// useEffect(() => {
	// 	setSd(startDate);
	// 	setEd(endDate);
	// }, [endDate, startDate]);

	useEffect(() => {
		form.setFieldsValue({
			startDate: sd ? moment(sd) : null,
			endDate: ed ? moment(ed) : null,
		});
	}, [ed, form, sd]);

	useEffect(() => {
		let filterItems = [
			{ sd: moment(sd).toISOString(), ed: moment(ed).toISOString() },
		];
		const _queryParams = generateQuery(filterItems);
		setQueryParams(_queryParams);
		history.push({ search: _queryParams });
	}, [sd, ed, history]);

	return (
		<div className="flex" style={{ width: '100%' }}>
			<div style={{ width: '40%' }}>
				<Form form={form} layout="inline" onFinish={_handleSubmit}>
					<Form.Item name="startDate">
						<DatePicker
							allowClear
							format="YYYY-MM-DD"
							onChange={e => (e ? setSd(e) : setSd(''))}
							disabledDate={c => startDisableDate(c, ed)}
							placeholder="Start Date"
						/>
					</Form.Item>

					<Form.Item name="endDate">
						<DatePicker
							allowClear
							format="YYYY-MM-DD"
							onChange={e => (e ? setEd(e) : setEd(''))}
							placeholder="End Date"
							disabledDate={c => endDisableDate(c, sd)}
						/>
					</Form.Item>

					<Form.Item>
						<div className="flex gap-1">
							<Button type="primary" htmlType="submit" loading={isLoading}>
								Submit
							</Button>
						</div>
					</Form.Item>
				</Form>
			</div>
			<div style={{ marginLeft: '-4rem' }}>
				<Button htmlType="submit" onClick={resetDate}>
					Clear
				</Button>
			</div>
		</div>
	);
};

DateFilter.defaultProps = {
	isLoading: false,
	startDisableDate: () => {},
	endDisableDate: () => {},
};

DateFilter.propTypes = {
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	startDisableDate: PropTypes.func,
	endDisableDate: PropTypes.func,
	isLoading: PropTypes.bool,
};
