import React, { useCallback } from "react";

import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { getValue } from "../../../helpers";
import { dismissMissedCall } from "../../missed_calls/service";

import { Button, Modal, Row, Input } from "antd/es";
import { useState } from "react";
import { Radio } from "antd";
import actions from "../../missed_calls/action";
import { store } from "../../../redux/store";
const { TextArea } = Input;

const MissCallDispositonModal = ({ open, setModalOpen }) => {
	const [comment, setComment] = useState("");
	const [answer, setAnswer] = useState("Answered");

	const { disposeCall } = useSelector(state => ({
		disposeCall: getValue(state, "missedCalls.disposeCall"),
	}));

	const handleDismissCall = useCallback(async () => {
		if(!disposeCall || !disposeCall.call_id) return;
		const call_id = disposeCall.call_id;
		const commentData = {
			user_id: disposeCall.user_id,
			case_id: disposeCall.case_id,
			data: { comment_text: `[${answer}]: ${comment}` },
		};

		const resolved = await dismissMissedCall(call_id, commentData);
		if (resolved) {
			setModalOpen(false);
			store.dispatch({
				type: actions.SET_MISSED_CALL_TO_DISPOSE,
				payload: {
					case_id: null,
					user_id: null,
					call_id: null,
				},
			});
			store.dispatch({
				type: actions.DELETE_DISPOSED_MISS_CALL,
				payload: call_id,
			})
		}
	}, [answer, comment, disposeCall, setModalOpen]);

	return (
		<Modal
			visible={open}
			title="Dispose missed call"
			onOk={handleDismissCall}
			onCancel={() => setModalOpen(false)}
			footer={[
				<Button key="back" onClick={() => setModalOpen(false)}>
					Cancel
				</Button>,
				<Button
					disabled={isEmpty(comment.trim())}
					key="submit"
					type="primary"
					onClick={handleDismissCall}>
					Mark Done
				</Button>,
			]}>
			<Row>
				<Radio.Group
					onChange={e => setAnswer(e.target.value)}
					defaultValue="Answered">
					<Radio.Button value="Answered">Answered</Radio.Button>
					<Radio.Button value="Unanswered">Unanswered</Radio.Button>
				</Radio.Group>
			</Row>
			<br />
			<Row justify="center">
				<TextArea
					onChange={e => setComment(e.target.value)}
					placeholder={"Please write your feedback"}
					rows={4}
					value={comment}
				/>
			</Row>
		</Modal>
	);
};

export default MissCallDispositonModal;
