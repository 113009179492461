import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { getUnansweredLeads } from "../service";
import { getQueryParams, getSearchParams, getValue } from "../../../helpers";
import { optionsCaseStatus } from "../../../constants/options";
import generateQueryParams from "../../../helpers/generateQueryPrams";
import queryParamsForApi from "../../../helpers/queryParamsForApi";
import { statusTagColor } from "../../../helpers/tagColor";

import {
	Button,
	Col,
	Form,
	Row,
	Select,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
const { Title } = Typography;

const renameQueryForAPI = {
	ua_caseStatus: "caseStatus",
	ua_formStatus: "formStatus",
	ua_pageNumber: "pageNumber",
};

const UnansweredLeads = () => {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("ua_pageNumber") || 1)
	);

	useEffect(() => {
		const filterItems = { ua_pageNumber: pageNo };
		setQueryParams(generateQueryParams(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });

		const paramsForAPI = queryParamsForApi(renameQueryForAPI);
		getUnansweredLeads(paramsForAPI);
	}, [history, queryParams]);

	const { loading, unansweredLeads } = useSelector(state => ({
		loading: getValue(state, "leadmanagement.unansweredLeadsLoading"),
		unansweredLeads: getValue(state, "leadmanagement.unansweredLeads"),
	}));

	useEffect(() => {
		if (isEmpty(unansweredLeads)) {
			setData([]);
			return;
		}

		setData(() =>
			unansweredLeads.data.map(row => ({
				key: getValue(row, "id"),
				name: getValue(row, "user.first_name"),
				status: getValue(row, "status"),
				assigned: getValue(row, "caseAssignment[0].created_at"),
				formStatus: getValue(row, "form_status"),
				lastFormFillDate: getValue(row, "last_form_fill_date"),
				caseActivityLog: getValue(row, "caseActivityLog[0].created_at"),
			}))
		);
		setPaginationInfo(unansweredLeads.pagination);
	}, [unansweredLeads]);

	const columns = [
		{
			title: "Name",
			key: "name",
			render: ({ key, name, status }) => (
				<Space direction="vertical">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>

					<Space>
						{status && (
							<Tag color={statusTagColor[status]} key={key}>
								{status.toUpperCase()}
							</Tag>
						)}
					</Space>
				</Space>
			),
		},
		{
			title: "Last form fill date",
			dataIndex: "lastFormFillDate",
			key: "lastFormFillDate",
			render: lastFormFillDate =>
				isEmpty(lastFormFillDate)
					? "-"
					: moment(lastFormFillDate).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Status updated at",
			dataIndex: "caseActivityLog",
			key: "caseActivityLog",
			render: caseActivityLog =>
				isEmpty(caseActivityLog)
					? "-"
					: moment(caseActivityLog).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<>
			<Row justify="space-between">
				<Col>
					<Title level={4}>Unanswered Leads</Title>
				</Col>

				<Col>
					<Filters setPageNo={setPageNo} setQueryParams={setQueryParams} />
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						bordered
						columns={columns}
						dataSource={data}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default UnansweredLeads;

const Filters = ({ setPageNo, setQueryParams }) => {
	const [form] = Form.useForm();
	const [caseStatus, setCaseStatus] = useState(getQueryParams("ua_caseStatus"));
	const [formStatus, setFormStatus] = useState(getQueryParams("ua_formStatus"));

	useEffect(() => {
		form.setFieldsValue({
			ua_caseStatus: caseStatus,
			ua_formStatus: formStatus,
		});
	}, []);

	const onSubmit = items => {
		const query = generateQueryParams(items);
		setQueryParams(query);
		setPageNo(1);
	};

	const handleClear = () => {
		form.resetFields();
		setCaseStatus(undefined);
		setFormStatus(undefined);
		form.submit();
	};

	return (
		<Form form={form} layout="inline" onFinish={onSubmit}>
			<Form.Item name="ua_caseStatus">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "6rem" }}
					options={optionsCaseStatus}
					onChange={e => (e ? setCaseStatus(e) : setCaseStatus(undefined))}
					placeholder="Status"
				/>
			</Form.Item>

			<Form.Item>
				<Space>
					<Button type="primary" htmlType="submit">
						Apply
					</Button>
					<Button htmlType="button" onClick={handleClear}>
						Clear
					</Button>
				</Space>
			</Form.Item>
		</Form>
	);
};
