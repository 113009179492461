import React, { useEffect, useState } from "react";
import moment from "moment";
import { generateQuery, getQueryParams } from "../../helpers";
import { Button, DatePicker, Form, Select, Space } from "antd";
const { Option } = Select;

const optionsStatus = [
	{ label: "Delivered", value: "delivered" },
	{ label: "In Progress", value: "in_progress" },
	{ label: "Pending", value: "pending" },
	{ label: "Pending Prescription", value: "pending_prescription" },
	{ label: "Shipped", value: "shipped" },
	{ label: "Shipping Pending", value: "shipping_pending" },
	{ label: "Unknown", value: "unknown" },
	{ label: "Void", value: "void" },
];

const Filters = ({ setPageNo, setQueryParams }) => {
	const [form] = Form.useForm();
	const [endDate, setEndDate] = useState(getQueryParams("endDate"));
	const [orderType, setOrderType] = useState(getQueryParams("orderType"));
	const [isBulk, setIsBulk] = useState(getQueryParams("isBulk"));
	const [status, setStatus] = useState(getQueryParams("status"));
	const [startDate, setStartDate] = useState(getQueryParams("startDate"));
	const [isSubscription, setIsSubscription] = useState(
		getQueryParams("isSubscription")
	);

	useEffect(() => {
		form.setFieldsValue({
			startDate: startDate ? moment(startDate) : null,
			endDate: endDate ? moment(endDate) : null,
			status,
			orderType,
			isBulk,
			isSubscription,
		});
	}, []);

	const onSubmit = items => {
		const filterItems = [];

		Object.entries(items).map(([key, value]) => {
			if (key === "startDate") {
				filterItems.push({
					[key]: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
				});
			} else if (key === "endDate") {
				filterItems.push({
					[key]: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
				});
			} else {
				filterItems.push({ [key]: value });
			}
			filterItems.push({ pageNumber: 1 });
			return filterItems;
		});

		const queryParams = generateQuery(filterItems);
		setQueryParams(queryParams);
		setPageNo(1);
	};

	const handleClear = () => {
		form.resetFields();
		setStatus(null);
		setStartDate(null);
		setEndDate(null);
		setOrderType(null);
		setIsBulk(null);
		setIsSubscription(null);
		form.submit();
	};

	return (
		<Form form={form} layout="inline" onFinish={onSubmit}>
			<Form.Item name="startDate">
				<DatePicker
					allowClear
					disabledDate={current =>
						endDate
							? current.isAfter(moment(endDate), "day")
							: current.isAfter(moment(), "day")
					}
					format={"YYYY-MM-DD"}
					onChange={e => (e ? setStartDate(e) : setStartDate(null))}
					placeholder="Start Date"
				/>
			</Form.Item>

			<Form.Item name="endDate">
				<DatePicker
					allowClear
					disabledDate={current =>
						(startDate && current.isBefore(moment(startDate), "day")) ||
						current.isAfter(moment(), "day")
					}
					format={"YYYY-MM-DD"}
					onChange={e => (e ? setEndDate(e) : setEndDate(null))}
					placeholder="End Date"
				/>
			</Form.Item>

			<Form.Item name="isBulk">
				<Select
					allowClear
					onChange={e => (e ? setIsBulk(e) : setIsBulk(null))}
					placeholder="Bulk Order">
					<Option value="true">True</Option>
					<Option value="false">False</Option>
				</Select>
			</Form.Item>

			<Form.Item name="isSubscription">
				<Select
					allowClear
					onChange={e => (e ? setIsSubscription(e) : setIsSubscription(null))}
					placeholder="Subscription">
					<Option value="true">True</Option>
					<Option value="false">False</Option>
				</Select>
			</Form.Item>

			<Form.Item name="status">
				<Select
					allowClear
					dropdownStyle={{ minWidth: 160 }}
					options={optionsStatus}
					onChange={e => (e ? setStatus(e) : setStatus(null))}
					placeholder="Order Status"
				/>
			</Form.Item>

			<Form.Item name="orderType">
				<Select
					allowClear
					onChange={e => (e ? setOrderType(e) : setOrderType(null))}
					placeholder="New/Repeat">
					<Option value="new">New</Option>
					<Option value="repeat">Repeat</Option>
				</Select>
			</Form.Item>

			<Form.Item>
				<Space>
					<Button type="primary" htmlType="submit">
						Apply
					</Button>
					<Button htmlType="button" onClick={handleClear}>
						Clear
					</Button>
				</Space>
			</Form.Item>
		</Form>
	);
};

export default Filters;
