import React from "react";
import { Card, Typography } from "antd";
const { Text } = Typography;

const AggregateSummary = ({ loading, title, value, subText }) => {
	return (
		<Card
			className="w-full"
			bordered
			hoverable
			loading={loading}
			title={title}
			extra={<h1 style={{ fontSize: "36px" }}>{value}</h1>}>
			<Text style={{ textAlign: "center" }}>{subText}</Text>
		</Card>
	);
};
export default AggregateSummary;
