export const getAttemptText = count => {
	if (!count) return "1st Attempt";
	count++;

	switch (count) {
		case 1:
			return "1st Attempt";

		case 2:
			return "2nd Attempt";

		case 3:
			return "3rd Attempt";

		default:
			return `${count}th Attempt`;
	}
};
