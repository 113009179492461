import { combineReducers } from "redux";
import { login } from "../pages/login/reducers/login";
import { leadmanagement } from "../pages/leadManagement/reducers/leadManagement";
import { leaddetails } from "../pages/leadDetails/reducers/leadDetails";
import { assignedleads } from "../pages/assignedLeads/reducers/assignedLeads";
import { salesDashboard } from "../pages/dashboard/reducers/salesDashboard";
import { admindashboard } from "../pages/dashboard/reducers/adminDashboard";
import { usersmanagement } from "../pages/usersManagment/reducers";
import { globalSearch } from "../common/globalSearch/reducers/globalSearch";
import { dispatchdashboard } from "../pages/dispatch/reducers/index";
import { orderManagement } from "../pages/orderManagement/reducers";
import { reports } from "../pages/report/reducers/report";
import { doctorsData } from "../pages/doctors/reducers";
import { configure } from "../pages/configure/reducers/configure";
import { supervisorsData } from "../pages/supervisor/reducers";
import slotBookings from "../pages/SlotBookings/reducer";
import ticketing from "../pages/ticketing/reducer";
import missedCalls from "../pages/missed_calls/reducer";

const rootReducer = combineReducers({
	login,
	leadmanagement,
	leaddetails,
	assignedleads,
	salesDashboard,
	admindashboard,
	usersmanagement,
	globalSearch,
	dispatchdashboard,
	orderManagement,
	doctorsData,
	reports,
	configure,
	supervisorsData,
	slotBookings,
	ticketing,
	missedCalls,
});

export default (state, action) => {
	return rootReducer(state, action);
};
