import React from "react";
import { Card, Typography } from "antd/es";
const { Text } = Typography;

const AggregateSummary = ({ children, extra, loading, title, headStyle }) => {
	return (
		<Card
			className="w-full"
			bordered
			hoverable
			loading={loading}
			headStyle={headStyle}
			title={<Text strong>{title}</Text>}
			extra={extra}>
			{children}
		</Card>
	);
};
export default AggregateSummary;
