import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  editProfile,
  getChats,
  getDuplicateChatList,
  viewDetails,
} from "../service";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Space, Typography } from "antd";
import { getCurrentUserRole } from "../../../helpers";

const { Text } = Typography;

function EditBasicInfo({ isModalVisible, setIsModalVisible, user, user_id }) {
  const [form] = Form.useForm();
  const params = useParams();

  const [editedFields, setEditedFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [chatsList, setChatsList] = useState([]);
  const [userRole] = useState(getCurrentUserRole());

  const { duplicateChatList, duplicateChatListLoading } = useSelector(
    state => ({
      duplicateChatList: window.getValue(
        state,
        "leaddetails.duplicateChatList"
      ),
      duplicateChatListLoading: window.getValue(
        state,
        "leaddetails.duplicateChatListLoading"
      ),
    })
  );

  useEffect(() => {
    if (!isEmpty(user) && isModalVisible)
      getDuplicateChatList(encodeURIComponent(user.chat_phone_number));
  }, [user, isModalVisible]);

  useEffect(() => {
    if (isEmpty(duplicateChatList)) setChatsList([]);
    else {
      const filterChats = duplicateChatList.filter(
        list => list.case_id !== params.id
      );
      setChatsList([...filterChats]);
    }
  }, [duplicateChatList, params.id]);

  useEffect(() => {
    if (!isEmpty(user) && isModalVisible) {
      form.setFieldsValue({
        first_name: user.first_name,
        email: user.email,
        alternate_email: user.alternate_email || "",
        alternate_phone: user.alternate_phone?.substr(3) || "",
        phone_number: user.phone_number?.substr(3),
        chat_phone_number: user.chat_phone_number?.substr(3),
        shopify_customer_id: user.shopify_customer_id || "",
      });
    }
  }, [form, user, isModalVisible]);

  const handleValuesChange = async e => {
    const fieldName = Object.keys(e)[0];

    if (
      fieldName === "chat_phone_number" &&
      e.chat_phone_number.length === 10
    ) {
      const chatNo = "+91" + e.chat_phone_number.trim();
      await getDuplicateChatList(encodeURIComponent(chatNo));
    }

    const data = {
      [fieldName]: isPhoneNo.includes(fieldName)
        ? handlePhoneNo(fieldName, e)
        : handleFieldsValue(fieldName, e),
    };

    if (data[fieldName] !== undefined && data[fieldName] !== user[fieldName]) {
      setEditedFields({ ...editedFields, ...data });
    } else {
      if (editedFields[fieldName]) {
        const editedData = { ...editedFields };
        delete editedData[fieldName];
        setEditedFields({ ...editedData });
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await editProfile(user_id, editedFields);

    if (res.status === 200) {
      await viewDetails(params.id);
      if ("chat_phone_number" in editedFields) await getChats(params.id);
      handleModalClose();
    }
    setLoading(false);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setEditedFields({});
    setChatsList([]);
  };

  return (
    <Modal
      title="Edit Basic Information"
      destroyOnClose={true}
      onCancel={handleModalClose}
      visible={isModalVisible}
      footer={[
        <Button key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
      ]}
    >
      <Form
        name="basic information"
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange}
        onFinish={handleSubmit}
      >
        <Row justify="center">
          <Col span={20}>
            <Form.Item
              name="first_name"
              label="Name"
              rules={[
                { required: true, message: "Please enter your name" },
                {
                  pattern: new RegExp(/^[a-zA-Z\s]+$/),
                  message: "Please enter valid name",
                },
                { whitespace: true, message: "Please enter valid name" },
                { max: 30,  message: "Name can't be longer than 30 characters" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter phone number" },
                {
                  pattern: new RegExp(/^\+?[0-9-]+$/),
                  message: "Please enter valid phone number",
                },
                { len: 10, message: "Only 10 digits are allowed" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="chat_phone_number"
              label="Chat Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                {
                  pattern: new RegExp(/^\+?[0-9-]+$/),
                  message: "Please enter valid phone number",
                },
                { len: 10, message: "Only 10 digits are allowed" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="alternate_phone"
              label="Alternate Phone Number"
              rules={[
                {
                  pattern: new RegExp(/^\+?[0-9-]+$/),
                  message: "Please enter valid phone number",
                },
                { len: 10, message: "Only 10 digits are allowed" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email address",
                },
                {
                  type: "email",
                  message: "Please enter valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="alternate_email"
              label="Alternate Email"
              rules={[
                {
                  type: "email",
                  message: "Please enter valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="shopify_customer_id"
              label="Customer ID"
              rules={[{ whitespace: true }]}
            >
              <Input disabled={!isAdmin.includes(userRole)} />
            </Form.Item>
          </Col>

          <Col span={20} style={{ textAlign: "center" }}>
            <Form.Item>
              {editedFields["chat_phone_number"] ? (
                <Space direction="vertical">
                  <Button
                    disabled={isEmpty(editedFields)}
                    danger={!isEmpty(chatsList)}
                    htmlType="submit"
                    icon={
                      isEmpty(chatsList) ? null : <ExclamationCircleOutlined />
                    }
                    loading={loading || duplicateChatListLoading}
                    type="primary"
                  >
                    Submit
                  </Button>
                  <Text type="warning">
                    {!isEmpty(chatsList) &&
                      `Chat number is available in ${chatsList.length} more profile`}
                  </Text>
                </Space>
              ) : (
                <Space direction="vertical">
                  <Button
                    disabled={isEmpty(editedFields)}
                    htmlType="submit"
                    loading={loading || duplicateChatListLoading}
                    type="primary"
                  >
                    Submit
                  </Button>
                  <Text type="warning">
                    {!isEmpty(chatsList) &&
                      `Chat number is available in ${chatsList.length} more profile`}
                  </Text>
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditBasicInfo;

const isPhoneNo = ["alternate_phone", "chat_phone_number", "phone_number"];
const isAdmin = ["admin"];

const handlePhoneNo = (fieldName, e) => {
  if (fieldName === "alternate_phone" && isEmpty(e[fieldName].trim()))
    return "";

  if (e[fieldName].length === 10) return "+91" + e[fieldName].trim();
};

const handleFieldsValue = (fieldName, e) => {
  if (fieldName === "email" || fieldName === "alternate_email")
    return e[fieldName].trim().toLowerCase();

  return e[fieldName].trim();
};
