import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { RescheduleEngagement } from "../../../components";
import Call from "../../../components/Call";
import MarkDoneEngagement from "../../../components/markDone/MarkDoneEngagement";
import { generateQuery, getQueryParams } from "../../../helpers";
import { TodaysReminder } from "../../dashboard/components";
import { getPendingEngagements } from "../service";

// Antd
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table, Tabs, Tag, Tooltip } from "antd";
const { TabPane } = Tabs;

const repLevel = localStorage.getItem("rep!level");

function TodaysEngagements() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [tag, setTag] = useState(getQueryParams("tag") || "Prescription Call");
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("currentt3")) || 1
	);

	const [caseId, setCaseId] = useState("");
	const [currEngagement, setCurrEngagement] = useState({});
	const [currReminderDate, setCurrReminderDate] = useState("");
	const [currReminderId, setCurrReminderId] = useState("");
	const [currTag, setCurrTag] = useState("");
	const [markDoneModal, setMarkDoneModal] = useState(false);
	const [rescheduleModal, setRescheduleModal] = useState(false);

	// LoggedIn user
	const currentUser = useMemo(() => localStorage.getItem("users"), []);

	useEffect(() => {
		if (isEmpty(tag)) return;
		const filterItems = [{ currentt3: pageNo, tag: tag }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });

		if (tag === "my_reminders") return;
		const queryParams = `pageNumber=${pageNo}&tag=${encodeURIComponent(tag)}`;
		getPendingEngagements(queryParams);
	}, [history, pageNo, tag]);

	const { loading, engagements } = useSelector(state => ({
		loading: window.getValue(state, "orderManagement.engagementsLoading"),
		engagements: window.getValue(state, "orderManagement.engagementsData"),
	}));

	useEffect(() => {
		if (isEmpty(engagements) || !currentUser) setData([]);
		else {
			const _data = engagements.data.filter(d => {
				if (!d.temporary_user_id) return true;
				return d.temporary_user_id === currentUser;
			});

			setData(() => {
				return _data.map(row => ({
					key: window.getValue(row, "id"),
					case_id: window.getValue(row, "order.case_id"),
					count: window.getValue(row, "order.count"),
					due_date: window.getValue(row, "reminder_date"),
					name: window.getValue(row, "order.case.user.first_name"),
					order_display_id: window.getValue(row, "order.order_display_id"),
					phone_number: window.getValue(row, "order.case.user.phone_number"),
					status: window.getValue(row, "status"),
					tag: window.getValue(row, "tag"),
					refrence_slot_id: window.getValue(row, "refrence_slot_id"),
				}));
			});
			setPaginationInfo(engagements.pagination);
		}
	}, [currentUser, engagements]);

	const column = useMemo(() => {
		return [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				width: 180,
				render: (text, record) => (
					<Link className="link" to={`/lead-details/${record.case_id}`}>
						{text}
					</Link>
				),
			},
			{
				title: "Phone No",
				dataIndex: "phone_number",
				key: "phone_number",
			},
			{
				title: "Order ID",
				dataIndex: "order_display_id",
				key: "order_display_id",
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: status => (
					<Tag key={status} color="cyan">
						{status && status.toUpperCase()}
					</Tag>
				),
			},
			{
				title: "Tag",
				dataIndex: "tag",
				key: "tag",
				render: tag => (
					<Tag key={tag} color="geekblue">
						{tag && tag.toUpperCase()}
					</Tag>
				),
			},
			{
				title: "New / Repeat",
				dataIndex: "count",
				key: "count",
				defaultSortOrder: false,
				sorter: (a, b) => a.count - b.count,
				render: (count, key) => {
					if (count <= 1)
						return (
							<Tag color="gold" key={key}>
								NEW
							</Tag>
						);
					else
						return (
							<Tag color="green" key={key}>
								REPEAT ORDER {count}
							</Tag>
						);
				},
			},
			{
				title: "Due Date",
				dataIndex: "due_date",
				key: "due_date",
				defaultSortOrder: "descend",
				sorter: (a, b) => moment(a.due_date) - moment(b.due_date),
				render: due_date => moment(due_date).format("DD MMM YYYY, h:mm a"),
			},
			{
				title: "Action",
				key: "action",
				hidden: repLevel !== "1",
				render: record => {
					return (
						<>
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setCaseId(record.case_id);
										setCurrEngagement(record);
										setCurrReminderId(record.key);
										setCurrTag(record.tag);
										setMarkDoneModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Reschedule">
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setCurrEngagement(record);
										setCurrReminderDate(moment(record.due_date));
										setCurrReminderId(record.key);
										setRescheduleModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: record.phone_number,
												case_id: record.case_id,
												note: {
													tag: record.tag,
													order_display_id: record.order_display_id,
												},
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				},
			},
		];
	}, []);

	const tableColumns = useMemo(() => {
		return column.filter(c => !c.hidden);
	}, [column]);

	const refreshUI = () => {
		const queryParams = `pageNumber=${pageNo}&tag=${encodeURIComponent(tag)}`;
		getPendingEngagements(queryParams);
	};

	const handleTabChange = key => {
		setPageNo(1);
		setTag(key);
	};

	const createTable = item => (
		<Table
			columns={tableColumns}
			dataSource={item}
			loading={loading}
			onChange={e => setPageNo(e.current)}
			pagination={{
				current: pageNo,
				pageSize: 10,
				showSizeChanger: false,
				total: paginationInfo.rowCount,
			}}
		/>
	);

	return (
		<>
			<Tabs defaultActiveKey={tag} onChange={handleTabChange}>
				<TabPane tab="Prescription Call" key="Prescription Call">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Week 1" key="Week #1">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Week 2" key="Week #2">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Week 3" key="Week #3">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Week 4" key="Week #4">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Week 5" key="Week #5">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Week 6" key="Week #6">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Special Engagements" key="SPE">
					{createTable(data)}
				</TabPane>
				<TabPane tab="Other Bookings" key="my_reminders">
					<TodaysReminder />
				</TabPane>
			</Tabs>

			<RescheduleEngagement
				engagement={currEngagement}
				isVisible={rescheduleModal}
				refreshUI={refreshUI}
				reminderDate={currReminderDate}
				reminderId={currReminderId}
				setIsVisible={setRescheduleModal}
			/>

			<MarkDoneEngagement
				caseId={caseId}
				engagement={currEngagement}
				modalVisible={markDoneModal}
				refreshUI={refreshUI}
				reminderId={currReminderId}
				setModalVisible={setMarkDoneModal}
				tag={currTag}
			/>
		</>
	);
}

export default TodaysEngagements;
