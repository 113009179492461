import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { getValue } from "../../helpers";

import { Table, Tag, Typography } from "antd/es";
const { Title, Text } = Typography;

const AdminMedRepTemporaryEngagements = ({
	engagements,
	reminders,
	loading,
}) => {
	const _engagements = useMemo(() => {
		if (isEmpty(engagements)) return [];
		return engagements.map(row => ({
			key: window.getValue(row, "id"),
			case_id: window.getValue(row, "order.case_id"),
			count: window.getValue(row, "order.count"),
			due_date: window.getValue(row, "reminder_date"),
			name: window.getValue(row, "order.case.user.first_name"),
			order_display_id: window.getValue(row, "order.order_display_id"),
			phone_number: window.getValue(row, "order.case.user.phone_number"),
			status: window.getValue(row, "status"),
			tag: window.getValue(row, "tag"),
			reschedule_count: getValue(row, "reschedule_count"),
		}));
	}, [engagements]);

	const _reminders = useMemo(() => {
		if (isEmpty(reminders)) return [];
		return reminders.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			comment: getValue(row, "comment"),
			data_type: "reminder",
			due_date: getValue(row, "reminder_date"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag") || "user",
			phone_number: getValue(row, "case.user.phone_number"),
			order_display_id: getValue(row, "case.order_display_id"),
			batch: getValue(row, "batch"),
			booking_type: "reminder",
			datetime: getValue(row, "reminder_date"),
			name: getValue(row, "case.user.first_name"),
			reschedule_count: getValue(row, "reschedule_count"),
		}));
	}, [reminders]);

	const data = [..._engagements, ..._reminders];

	const column = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (name, { case_id, phone_number }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${case_id}`}>
						{name}
					</Link>
					<Text>{phone_number}</Text>
				</div>
			),
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			render: id => (id ? id : "-"),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: status => (
				<Tag key={status} color="cyan">
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Tag",
			key: "tag",
			render: ({ tag, reschedule_count }) => (
				<>
					<Tag key={tag} color="geekblue">
						{tag && tag.toUpperCase()}
					</Tag>
					{reschedule_count && reschedule_count !== null && (
						<Tag color="gold">Rescheduled</Tag>
					)}
				</>
			),
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			defaultSortOrder: false,
			sorter: (a, b) => a.count - b.count,
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Due Date",
			dataIndex: "due_date",
			key: "due_date",
			defaultSortOrder: "descend",
			sorter: (a, b) => moment(a.due_date) - moment(b.due_date),
			render: due_date => moment(due_date).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<>
			<Title level={3}>Temporary Engagements</Title>
			<Table
				columns={column}
				dataSource={data}
				loading={loading}
				pagination={{
					pageSize: 10,
					showSizeChanger: false,
				}}
			/>
		</>
	);
};

export default AdminMedRepTemporaryEngagements;
