import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { getTodaysReminder } from "../../../pages/dashboard/service";
import { getValue } from "../../../helpers";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { MY_BOOKINGS_API } from "../../../constants/apiUrls";
import { statusTagColor } from "../../../helpers/tagColor";

// Antd
import { List, Tag, Tooltip, Typography } from "antd/es";
const { Text, Title } = Typography;

const TodaysBookings = ({ tab }) => {
	const [ed] = useState(moment().endOf("day").toISOString());
	const [sd] = useState(moment().startOf("day").toISOString());
	const [bookings, setBookings] = useState([]);
	const [loadingBookings, setLoadingBookings] = useState(false);

	useEffect(() => {
		getTodaysReminder("pageSize=5000");
	}, [tab]);

	const { loading, reminders } = useSelector(state => ({
		loading: getValue(state, "salesDashboard.todaysReminderLoading"),
		reminders: getValue(state, "salesDashboard.todaysReminders.data") || [],
	}));

	// Fetch My Bookings
	const getMyBookings = useCallback(async () => {
		if (isEmpty(sd) || isEmpty(ed)) setBookings([]);

		let res = {};

		try {
			setLoadingBookings(true);
			res = await fetchRequest(MY_BOOKINGS_API(sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setBookings([]);
			else setBookings(res.data);
			setLoadingBookings(false);
		}
	}, [sd, ed]);

	useEffect(() => {
		getMyBookings();
	}, [getMyBookings]);
	// Ends

	const data = useMemo(() => {
		const _data = [];

		if (!isEmpty(bookings)) {
			const _unfinishedBookings = bookings.filter(booking => {
				return booking.is_finished === false;
			});

			for (let i = 0; i < _unfinishedBookings.length; i++) {
				const {
					case_id,
					case: {
						feedback,
						status,
						user: { first_name },
					},
					id,
					slot: { slot_time },
					unanswered_count,
				} = _unfinishedBookings[i];

				_data.push({
					case_id,
					first_name,
					feedback,
					id,
					reminder_date: slot_time,
					status,
					tag: "SLOT_BOOKING",
					unanswered_count,
				});
			}
		}

		if (!isEmpty(reminders)) {
			for (let i = 0; i < reminders.length; i++) {
				const {
					case_id,
					case: {
						feedback,
						status,
						user: { first_name },
					},
					id,
					reminder_date,
					tag,
					unanswered_count,
				} = reminders[i];

				_data.push({
					case_id,
					first_name,
					feedback,
					id,
					reminder_date,
					status,
					tag,
					unanswered_count,
				});
			}
		}

		const sort = _data.sort((a, b) => {
			return new Date(a.reminder_date) - new Date(b.reminder_date);
		});
		return sort;
	}, [bookings, reminders]);

	return (
		<div className="px-2">
			<Title level={3}>Today's Bookings</Title>
			<List
				style={{ maxHeight: "40rem" }}
				className="overflow-y-auto"
				loading={loading || loadingBookings}
				dataSource={data}
				renderItem={item => (
					<List.Item key={item.id} className="items-start px-2 mt-px">
						<List.Item.Meta
							title={
								<Link className="mr-4" to={`/lead-details/${item.case_id}`}>
									<Text strong className="link">
										{item.first_name}
									</Text>
								</Link>
							}
							description={moment(item.reminder_date).format("hh:mm a")}
						/>
						<div
							className="grid justify-items-end gap-1"
							style={{ maxWidth: "28rem" }}>
							<div className="flex items-center">
								<Tooltip placement="bottom" title="Status">
									<Tag color={statusTagColor[item.status]}>
										{item.status?.toUpperCase()}
									</Tag>
								</Tooltip>

								<Tooltip placement="bottom" title="Tag">
									<Tag color="geekblue">
										{item.tag ? item.tag.replaceAll("_", " ") : "USER"}
									</Tag>
								</Tooltip>
							</div>

							{item.feedback && (
								<div className="flex gap-1 mr-2">
									<Text strong>Feedback: </Text>
									<Text>{item.feedback}</Text>
								</div>
							)}
						</div>
					</List.Item>
				)}
			/>
		</div>
	);
};

export default TodaysBookings;
