import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getUrl } from '../../../constants/apiUrls';
import { fetchRequest } from '../../../helpers/fetchRequest';

// Antd
import { FormOutlined, PlusOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	message,
	Row,
	Select,
	Upload
} from 'antd/es';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getValue } from '../../../helpers';
import {
	categoryOptions,
	getAssigneesForIvr,
	getAssignesList,
	priorityMap,
	priorityOptions,
	statusOptions,
	subCategoryOptions,
	uploadFile
} from '../helpers';
import { Drawer } from 'antd';
const { TextArea } = Input;

const UpdateTicket = ({ data, updateUI, showIcon }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [openMoal, setOpenMoal] = useState(false);
	const [assignesOptions, setAssignesOptions] = useState([]);
	const [category, setCategory] = useState('');
	const [subCategory, setSubCategory] = useState('');

	const URL = useMemo(() => {
		if (!data) return '';
		return getUrl(`update-ticket/${data.key}`);
	}, [data]);

	const { userRole: doctorsList } = useSelector(state =>
		getValue(state, 'doctorsData.doctorsListData')
	);

	const updates = useMemo(() => {
		if (data.comments && data.comments.length > 0) {
			let _item = [];
			data.comments.forEach(item => _item.unshift(`- ${item.description}`));
			return _item.join("\r\n");
		} else return "";
	}, [data.comments]);

	useEffect(() => {
		if (!openMoal) return;
		setSubCategory(data.sub_category);
		setCategory(data.category);
		form.setFieldsValue({
			description: data.description,
			category: data.category,
			sub_category: data.sub_category,
			assign_to: data.current_assignment.user_id,
			due_by: moment(data.due_date),
			updates: updates,
			order_details: data.source_meta?.order_details,
			status_code: data.status_code,
			priority: `${Number(data.priority_code) - 1}`,
		});
	}, [data, form, openMoal, updates]);

	const fetchAssignedPersons = useCallback(
		async orderId => {
			let res = await getAssignesList(
				orderId,
				category,
				subCategory,
				doctorsList,
				'updating'
			);
			res.push({
				value: data.current_assignment?.user_id,
				label: data.current_assignment?.first_name,
			});
			res = [...new Map(res.map(item => [item['value'], item])).values()];
			setAssignesOptions(res);
		},
		[category, data, doctorsList, subCategory]
	);

	useEffect(() => {
		if (data.source_meta?.order_id && category && subCategory) {
			fetchAssignedPersons(data.source_meta?.order_id);
		} else if (category === 'ivr_requests') {
			const _assigness = getAssigneesForIvr(data, doctorsList);
			setAssignesOptions(_assigness);
		}
	}, [category, data, doctorsList, fetchAssignedPersons, subCategory]);

	const updateTicket = async _data => {
		setLoading(true);
		let res = {};
		const updatedData = {};
		if (_data.comment) {
			if (_data.comment.trim() === '') {
				message.info('Please fill in the comment');
				setLoading(false);
				return;
			}

			updatedData['comment'] = {
				type: category,
				description: _data.comment,
				commented_by: localStorage.getItem('users'),
			};
		}

		if (data.current_assignment.user_id !== _data.assign_to) {
			updatedData['current_assignment'] = _data.assign_to;
		}

		if (priorityMap[data.priority] !== _data.priority) {
			updatedData['priority'] = Number(_data.priority) + 1;
		}

		if (data.status_code !== _data.status_code) {
			updatedData['status_code'] = _data.status_code;
		}

		try {
			res = await fetchRequest(URL, {
				method: 'PUT',
				body: JSON.stringify({
					...updatedData,
					action_taker: localStorage.getItem('users'),
				}),
			});
		} catch (error) {
			console.error(error);
		} finally {
			if (res.hasError) {
				message.error('Failed to update ticket');
			} else {
				if (_data.upload && res.data) {
					let ticketId = res.data._id;
					await uploadFile(_data.upload.fileList, ticketId);
				}
				message.success('Ticket update successfully');
				updateUI();
				handleModalClose();
			}
			setLoading(false);
		}
	};

	const handleModalClose = () => {
		setOpenMoal(false);
		form.resetFields();
	};

	const handleFormChange = value => {
		if (value && value.category) {
			setCategory(value.category);
			form.setFieldsValue({ sub_category: '' });
		}
		if (value && value.sub_category) {
			setSubCategory(value.sub_category);
		}
	};

	const handleFakeRequest = ({ onSuccess }) => {
		return setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	return (
		<>
			<Button
				onClick={() => setOpenMoal(true)}
				type={showIcon ? 'text' : 'default'}>
				{showIcon ? <FormOutlined /> : 'Update'}
			</Button>

			<Drawer
				title={`Update Ticket #${data.key}`}
				placement="right"
				visible={openMoal}
				showDrawer={() => setOpenMoal(true)}
				onClose={handleModalClose}
				width={600}
			>
				<Row justify="center" style={{ marginTop: 16 }}>
					<Col span={16}>
						<span>Issue: </span>
						<span>
							<em>{data?.latestComment}</em>
						</span>
						<Divider />
					</Col>
				</Row>
				<Row justify="center" style={{ marginTop: 16 }}>
					<Form
						form={form}
						onFinish={updateTicket}
						layout="vertical"
						onValuesChange={handleFormChange}>
						<Form.Item
							label="Category"
							name="category"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select
								options={categoryOptions}
								style={{ width: 320 }}
								disabled={true}
							/>
						</Form.Item>

						<Form.Item
							label="Sub Category"
							name="sub_category"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select
								options={subCategoryOptions[category]}
								style={{ width: 320 }}
								disabled={true}
							/>
						</Form.Item>

						<Form.Item
							label="Assign To"
							name="assign_to"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={assignesOptions} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Priority"
							name="priority"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={priorityOptions} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Order Details"
							name="order_details"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<TextArea disabled rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Updates"
							name="updates"
						>
							<TextArea disabled rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Status"
							name="status_code"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={statusOptions} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Comment"
							name="comment"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<TextArea rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Due By"
							name="due_by"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<DatePicker
								allowClear
								disabledDate={current => current.isBefore(new Date(), 'day')}
								format={'YYYY-MM-DD hh:mm a'}
								placeholder="Due By"
								showTime={true}
								disabled={true}
								style={{ width: 320 }}
							/>
						</Form.Item>

						<Form.Item name="upload">
							<Upload
								accept="image/png, image/jpeg, application/pdf"
								customRequest={handleFakeRequest}
								listType="picture-card"
								onPreview={file => {
									if (typeof window.URL.createObjectURL === 'function') {
										const url = window.URL.createObjectURL(file.originFileObj);
										window.open(url, '_blank').focus();
									}
								}}>
								<div className="flex flex-col gap-2">
									<PlusOutlined />
									<span>Upload</span>
								</div>
							</Upload>
						</Form.Item>

						<Form.Item className="text-center">
							<Button type="primary" htmlType="submit" loading={loading}>
								Update
							</Button>
						</Form.Item>
					</Form>
				</Row>
				<Row justify="end">
					<Button key="cancel" onClick={handleModalClose}>
						Cancel
					</Button>
				</Row>
			</Drawer>
		</>
	);
};

export default UpdateTicket;
