import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import rawQuestions from "../../configs/consultCallQuestionnaire.json";
import { CONSULT_CALL_FEEDBACK_API } from "../../constants/apiUrls";
import { getValue } from "../../helpers";
import { fetchRequest } from "../../helpers/fetchRequest";
import { getConsultCallFeedback } from "../../pages/leadDetails/service";
import { markDone } from "../../pages/orderManagement/service";
import components from "./components";

// Antd
import { Button, Col, Form, message, Modal, Radio, Row, Spin } from "antd/es";
import { markReminderDone } from "../../pages/dashboard/service";

const ConsultCallFormMedRep = ({
	caseId,
	engagement,
	modalVisible,
	reminderId,
	setModalVisible,
	updateUI,
}) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [feedbackType, setFeedbackType] = useState("");

	const { feedbacks, loadingFeedback } = useSelector(state => ({
		feedbacks: getValue(state, "leaddetails.consultCallFeedback"),
		loadingFeedback: getValue(state, "leaddetails.consultCallFeedbackLoading"),
	}));

	useEffect(() => {
		if (modalVisible) getConsultCallFeedback(caseId);
	}, [caseId, modalVisible]);

	useEffect(() => {
		if (!feedbacks) return;
		form.setFieldsValue(feedbacks);
	}, [feedbacks, form, modalVisible]);

	const closeModal = () => {
		form.resetFields();
		setFeedbackType("");
		setModalVisible(false);
	};

	const handleSubmit = async data => {
		let res = {};

		try {
			setLoading(true);
			const is_answered = feedbackType === "answered" ? "true" : "false";
			const comment =
				feedbackType === "answered"
					? `answered(Prescription call): ${
							data["Additional Notes (if any)"] || ""
					  }`
					: `unanswered(prescription call):  ${
							data["Additional Notes (if any)"] || ""
					  }`;

			await _markReminderDone(data);
			const markDoneRes = await markDone(reminderId, comment, is_answered);
			if (markDoneRes.status !== 200) {
				message.error("Unable to Update Reminder");
				return;
			}
			if (feedbackType === "answered") {
				res = await fetchRequest(CONSULT_CALL_FEEDBACK_API(caseId), {
					method: "POST",
					body: JSON.stringify({ feedback: data, tag: "general" }),
				});
			}
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) message.error("Failed");
			else {
				message.success("Updated successfully");
				updateUI();
				closeModal();
			}
			setLoading(false);
		}
	};

	const _markReminderDone = async item => {
		if (!engagement.refrence_slot_id) return Promise.resolve("");

		const _data = {
			comment: `user: ${
				feedbackType === "unanswered"
					? "unanswered"
					: item["Additional Notes (if any)"] || "answered"
			}`,
			isAnswered: feedbackType === "unanswered" ? "false" : "true",
		};

		return await markReminderDone(_data, engagement.refrence_slot_id);
	};

	return (
		<Modal
			visible={modalVisible}
			title="Prescription call feedback"
			onOk={closeModal}
			onCancel={closeModal}
			footer={[
				<Button key="back" onClick={closeModal}>
					Cancel
				</Button>,
			]}>
			<Row justify="center">
				{loadingFeedback ? <Spin /> : null}

				<Radio.Group
					optionType="button"
					buttonStyle="outline"
					size="large"
					onChange={({ target }) => setFeedbackType(target.value)}
					value={feedbackType}>
					<Radio.Button value="answered">Answered</Radio.Button>
					<Radio.Button value="unanswered">Didn't answer</Radio.Button>
				</Radio.Group>
			</Row>

			<Form
				form={form}
				layout="vertical"
				name="form"
				onFinish={handleSubmit}
				requiredMark={true}>
				{feedbackType !== "" && (
					<Row justify="center" style={{ marginTop: 16 }}>
						{feedbackType === "answered" &&
							rawQuestions.map(question => (
								<Col span={24}>
									<Form.Item
										key={question.id}
										label={question.text}
										name={question.text}
										required={question.required}
										rules={question.rules}>
										{components(question)}
									</Form.Item>
								</Col>
							))}

						<div className="flex w-full justify-center">
							<Form.Item>
								<Button loading={loading} type="primary" htmlType="submit">
									Save
								</Button>
							</Form.Item>
						</div>
					</Row>
				)}
			</Form>
		</Modal>
	);
};

export default ConsultCallFormMedRep;
