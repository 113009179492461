import imageCompression from "browser-image-compression";
import Cookies from "js-cookie";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { getUrl } from "../../constants/apiUrls";
import { escalationTeamMember, getValue, opsTeamMember } from "../../helpers";
import { getAssignedPersons } from "./service";

export const subCategoryOptions = {
	order_related: [
		{ label: "Edit Order", value: "edit_order" },
		{ label: "Order Cancellation", value: "order_cancellation" },
		{ label: "Reverse Pick up", value: "reverse_pick_up" },
		{ label: "Wrong order", value: "wrong_order" },
		{ label: "Refund", value: "refund" },
	],
	delivery_related: [
		{ label: "Delivery Issues", value: "delivery_issues" },
		{ label: "Damaged Product", value: "damaged_product" },
	],
	prescription_related: [
		{ label: "Prescription Request", value: "prescription_request" },
	],
	escalation: [
		{
			label: "Client Escalation via Medrep call",
			value: "client_escalation_via_medrep_call",
		},
		{
			label: "Client Escalation via WABA",
			value: "client_escalation_via_waba",
		},
	],
	ivr_requests: [{ label: "Client Call missed", value: "client_call_missed" }],
};

export const categoryOptions = [
	{ label: "Order Related", value: "order_related" },
	{ label: "Delivery Related", value: "delivery_related" },
	{ label: "Prescription Related", value: "prescription_related" },
	{ label: "Escalation", value: "escalation" },
	{ label: "IVR Requests", value: "ivr_requests" },
];

export const priorityOptions = [
	{ label: "Low", value: "0" },
	{ label: "Medium", value: "1" },
	{ label: "High", value: "2" },
	{ label: "Urgent", value: "3" },
];

export const statusOptions = [
	{ value: "2", label: "Open" },
	{ value: "3", label: "Pending" },
	{ value: "4", label: "Resolved" },
	{ value: "5", label: "Closed" },
];

export const categoryMap = {
	order_related: "Order Related",
	delivery_related: "Delivery Related",
	prescription_related: "Prescription Related",
	escalation: "Escalation",
	ivr_requests: "IVR Requests",
};

export const subCategoryMap = {
	edit_order: "Edit Order",
	order_cancellation: "Order Cancellation",
	reverse_pick_up: "Reverse Pick up",
	wrong_order: "Wrong order",
	refund: "Refund",
	delivery_issues: "Delivery Issues",
	damaged_product: "Damaged Product",
	prescription_request: "Prescription Request",
	client_escalation_via_medrep_call: "Client Escalation via Medrep call",
	client_escalation_via_waba: "Client Escalation via WABA",
	client_call_missed: "Client Call missed",
};

export const priorityMap = {
	Low: "0",
	Medium: "1",
	High: "2",
	Urgent: "4",
};

const assignToOptions = {
	order_related: {
		refund: [
			{
				value: opsTeamMember["jaiswalneelu@traya.health"].value,
				label: opsTeamMember["jaiswalneelu@traya.health"].label,
				email: "jaiswalneelu@traya.health",
			},
		],
		order_cancellation: [
			{
				value: opsTeamMember["ganeshkamble@traya.health"].value,
				label: opsTeamMember["ganeshkamble@traya.health"].label,
				email: "ganeshkamble@traya.health",
			},
		],
		wrong_order: [
			{
				value: opsTeamMember["patilgaurang@traya.health"].value,
				label: opsTeamMember["patilgaurang@traya.health"].label,
				email: "patilgaurang@traya.health",
			},
		],
		reverse_pick_up: [
			{
				value: opsTeamMember["sindhu@tatva.health"].value,
				label: opsTeamMember["sindhu@tatva.health"].label,
				email: "sindhu@tatva.health",
			},
		],
		edit_order: [
			{
				value: opsTeamMember["sindhu@tatva.health"].value,
				label: opsTeamMember["sindhu@tatva.health"].label,
				email: "sindhu@tatva.health",
			},
		],
	},
	delivery_related: {
		delivery_issues: [
			{
				value: opsTeamMember["yadavpratibha@traya.health"].value,
				label: opsTeamMember["yadavpratibha@traya.health"].label,
				email: "yadavpratibha@traya.health",
			},
		],
		damaged_product: [
			{
				value: opsTeamMember["patilgaurang@traya.health"].value,
				label: opsTeamMember["patilgaurang@traya.health"].label,
				email: "patilgaurang@traya.health",
			},
		],
	},
	prescription_related: {
		prescription_request: [
			{
				value: opsTeamMember["deyashish@traya.health"].value,
				label: opsTeamMember["deyashish@traya.health"].label,
				email: "deyashish@traya.health",
			},
		],
	},
	escalation: {
		client_escalation_via_medrep_call: [
			{
				value: escalationTeamMember["priyankakanojia@traya.health"].value,
				label: escalationTeamMember["priyankakanojia@traya.health"].label,
				email: "priyankakanojia@traya.health",
			},
			{
				value: escalationTeamMember["khanmahenaaz@traya.health"].value,
				label: escalationTeamMember["khanmahenaaz@traya.health"].label,
				email: "khanmahenaaz@traya.health",
			},
		],
		client_escalation_via_waba: [
			{
				value: escalationTeamMember["priyankakanojia@traya.health"].value,
				label: escalationTeamMember["priyankakanojia@traya.health"].label,
				email: "priyankakanojia@traya.health",
			},
			{
				value: escalationTeamMember["khanmahenaaz@traya.health"].value,
				label: escalationTeamMember["khanmahenaaz@traya.health"].label,
				email: "khanmahenaaz@traya.health",
			},
		],
	},
};

export const getDoctorsAndAdmin = (category, doctorsList) => {
	const _list = [];
	if (doctorsList && doctorsList.length > 0) {
		doctorsList.forEach(doc => {
			if (doc && doc.user && doc.user.first_name)
				_list.push({ value: doc.user_id, label: doc.user.first_name });
		});
	}
	if (category === "escalation") {
		_list.unshift({
			value: "00000000-0000-0000-0000-000000000001",
			label: "Admin",
		});
	}
	return _list;
};

export const getAssignesList = async (
	orderId,
	category,
	subCategory,
	doctorsList,
	status = "creating"
) => {
	let assigned = [];
	const loggedInUser = localStorage.getItem("users");
	// const isSupervisor = localStorage.getItem('is!supervisor');
	if (
		(category === "order_related" && subCategory === "refund") ||
		(category === "order_related" && subCategory === "order_cancellation") ||
		(category === "order_related" && subCategory === "wrong_order") ||
		(category === "order_related" && subCategory === "reverse_pick_up") ||
		(category === "order_related" && subCategory === "edit_order") ||
		(category === "delivery_related" && subCategory === "delivery_issues") ||
		(category === "delivery_related" && subCategory === "damaged_product") ||
		(category === "escalation" &&
			subCategory === "client_escalation_via_medrep_call") ||
		(category === "escalation" &&
			subCategory === "client_escalation_via_waba") ||
		(category === "prescription_related" &&
			subCategory === "prescription_request") ||
		(category === "prescription_related" &&
			subCategory === "prescription_request")
	) {
		assigned = assignToOptions[category][subCategory];
	} else {
		const res = await getAssignedPersons(orderId, category, subCategory);
		if (res && res.data && res.data[0]) {
			const assignes = res.data || [];
			assignes.forEach(doc =>
				assigned.push({ value: doc.id, label: doc.first_name })
			);
		}
	}

	if (status === "updating" && category !== "escalation") {
		const _ops = [];
		Object.values(opsTeamMember).forEach(item => _ops.push({ ...item }));
		assigned = [...assigned, ..._ops];
	}

	if (
		category === "escalation" &&
		(loggedInUser ===
			escalationTeamMember["priyankakanojia@traya.health"].value ||
			loggedInUser === escalationTeamMember["khanmahenaaz@traya.health"].value)
	) {
		const _assigned = [];
		_assigned.push(escalationTeamMember["siddhi@tatva.health"]);
		_assigned.push(escalationTeamMember["kalyani@tatva.health"]);
		_assigned.push(escalationTeamMember["harish@traya.health"]);
		_assigned.push(escalationTeamMember["shefali@tatva.health"]);
		assigned = _assigned;
	}

	assigned = assigned.filter(item => item.value !== loggedInUser);
	assigned = [...new Map(assigned.map(item => [item["value"], item])).values()]; // unique values
	return assigned;
};

export const getAssigneesForIvr = (ticketInfo, doctorList) => {
	const assignees = [];
	// const isSupervisor = localStorage.getItem('is!supervisor');
	// if (getCurrentUserRole() === 'admin' || isSupervisor === 'true') {
	// 	if (doctorList && doctorList.length > 0) {
	// 		doctorList.forEach(doc => {
	// 			if (doc && doc.user && doc.user.first_name)
	// 				assignees.push({ value: doc.user_id, label: doc.user.first_name });
	// 		});
	// 	}
	// }
	assignees.push({
		value: ticketInfo.current_assignment?.user_id,
		label: ticketInfo.current_assignment?.first_name,
	});
	return assignees;
};

export const formatTicketData = (data, startDate, endDate) => {
	if (!Array.isArray(data) || data.length === 0) return;
	let tickets = [];

	if (startDate && startDate !== "" && endDate && endDate !== "") {
		console.log(moment(startDate).toISOString(), moment(endDate).toISOString());
		const _tickets = data;
		tickets = _tickets.filter(item => {
			return (
				moment(moment(item.createdAt).toISOString()).isBefore(
					moment(endDate).toISOString()
				) &&
				moment(moment(item.createdAt).toISOString()).isAfter(
					moment(startDate).toISOString()
				)
			);
		});
	} else {
		tickets = data;
	}

	return tickets.map(item => ({
		key: getValue(item, "_id"),
		case_id: getValue(item, "case_id"),
		created_at: moment(getValue(item, "createdAt")).format(
			"DD MMM YYYY, h:mm a"
		),
		updated_at: moment(getValue(item, "updatedAt")).format(
			"DD MMM YYYY, h:mm a"
		),
		description: getValue(item, "description"),
		status: getValue(item, "status"),
		category: getValue(item, "category"),
		sub_category: getValue(item, "sub_category"),
		priority: getValue(item, "priority"),
		ticket_id: getValue(item, "provider_meta.ref_id"),
		images: getValue(item, "images"),
		customer: {
			...getValue(item, "customer"),
			case_id: getValue(item, "case_id"),
		},
		comments: getValue(item, "comments"),
		latestComment: getValue(item, "comments[0].description"),
		history: getValue(item, "history"),
		current_assignment: getValue(item, "current_assignment"),
		author: getValue(item, "author"),
		source_meta: getValue(item, "source_meta"),
		status_code: getValue(item, "status_code"),
		priority_code: getValue(item, "priority_code"),
		provider_meta: getValue(item, "provider_meta"),
		due_date: getValue(item, "due_date"),
		firstComment: getValue(item, "provider_meta.description"),
		order_display_id: getValue(item, "source_meta.order_display_id"),
	}));
};

export const getTicketsStats = tickets => {
	if (!Array.isArray(tickets) || tickets.length === 0) return;

	let _data = groupBy(tickets, item => item.status);
	const overallStats = {
		open: _data["Open"]?.length || 0,
		pending: _data["Pending"]?.length || 0,
		resolved: _data["Resolved"]?.length || 0,
		closed: _data["Closed"]?.length || 0,
	};

	let _todaysData = tickets.filter(item => {
		return (
			(moment(item.updatedAt) > moment().startOf("day") &&
				moment(item.updatedAt) < moment()) ||
			(moment(item.createdAt) > moment().startOf("day") &&
				moment(item.createdAt) < moment())
		);
	});

	_todaysData = groupBy(_todaysData, item => item.status);
	const todayStats = {
		open: _todaysData["Open"]?.length || 0,
		pending: _todaysData["Pending"]?.length || 0,
		resolved: _todaysData["Resolved"]?.length || 0,
		closed: _todaysData["Closed"]?.length || 0,
	};

	return { overallStats, todayStats };
};

export const uploadFile = async (files, ticketId) => {
	if (!files || !ticketId) return Promise.resolve("done");

	let res = [];
	const options = {
		maxSizeMB: 5,
		useWebWorker: true,
	};

	try {
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const type = files[i].type;
			let fileData = new FormData();
			let compressedFile = null;
			if (type !== "application/pdf") {
				compressedFile = await imageCompression(file.originFileObj, options);
			} else {
				compressedFile = file.originFileObj;
			}
			fileData.append("file", compressedFile, file.name);

			res.push(
				fetch(getUrl(`ticket/${ticketId}/upload`), {
					method: "PUT",
					body: fileData,
					headers: {
						Authorization: `Bearer ${Cookies.get("token")}`,
					},
				})
			);
		}
	} catch (error) {
		console.error(error);
	} finally {
		await Promise.allSettled(res);
		return Promise.resolve("done");
	}
};
