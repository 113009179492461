import Cookies from "js-cookie";
import { baseURL } from "../../helpers";
import { message } from "antd";

const AUTHORIZATION_HEADER = {
	"Content-Type": "application/json",
	Authorization: `Bearer ${Cookies.get("token")}`,
};

export const markConsultCallFinished = async caseId => {
	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}case/${caseId}/markFinished`,
			{
				method: "PUT",
				json: true,
				headers: { ...AUTHORIZATION_HEADER },
			}
		);

		if (_res.status !== 200) {
			message.error("Unable to mark the case as Finished");
		}

		return _res;
	} catch (error) {
		message.error("Failed");
	}
};
