import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { Table, Typography } from "antd/es";
import { getQueryParams } from "../../../helpers";
import { store } from "../../../redux/store";
import actions from "../action";
const { Text } = Typography;

const MissedCallsTable = ({ tableData }) => {
	const history = useHistory();
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("pageNumber")) || 1
	);

	const setMissCallToDispose = useCallback(
		(user_id, case_id, call_id) => {
			store.dispatch({
				type: actions.SET_MISSED_CALL_TO_DISPOSE,
				payload: {
					user_id,
					case_id,
					call_id,
				},
			});
			history.push(`/lead-details/${case_id}`);
		},
		[history]
	);

	const { data, error, loading } = tableData;

	const columns = useMemo(
		() => [
			{
				title: "Name",
				dataIndex: "",
				key: "name",
				render: record => {
					let name = "";
					if (record.user.first_name) name += record.user.first_name;
					if (record.user.last_name) name += record.user.last_name;
					return (
						<div className="grid">
							<Text
								className="link"
								onClick={() =>
									setMissCallToDispose(
										record.user.id,
										record.case_id,
										record._id
									)
								}>
								{name}
							</Text>
							<Text type="secondary" className="link">
								{record.event_meta.CallFrom}
							</Text>
						</div>
					);
				},
			},
			{
				title: "Email",
				dataIndex: "user",
				key: "email",
				render: record => record?.email,
			},

			{
				title: "IVR Call",
				dataIndex: "event_timestamp",
				key: "event_timestamp",
				sorter: (a, b) =>
					new Date(a.event_timestamp) - new Date(b.event_timestamp),
				render: date => moment(date).format("DD MMM YYYY, h:mm a"),
			},
			{
				title: "Type",
				dataIndex: "event_meta",
				key: "digits",
				render: record => {
					let message = "";
					switch (record?.digits) {
						case '"1"':
							message = "Track order";
							break;
						case '"2"':
							message = "Speak to Hair Coach";
							break;
						case '"3"':
							message = "Reorder";
							break;
						default:
							message = "New Call";
					}
					return message;
				},
			},
		],
		[setMissCallToDispose]
	);

	if (error) {
		return <Text type="danger">Failed to load data</Text>;
	}
	return (
		<div className="w-full">
			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				onChange={e => setPageNo(e.current)}
				pagination={{
					current: pageNo,
					showSizeChanger: false,
					total: data.length,
				}}
			/>
		</div>
	);
};

export default MissedCallsTable;
