import { leadManagementActions } from "../actions";

export const leadmanagement = (state = {}, action) => {
	switch (action.type) {
		case leadManagementActions.GET_RECENT_LEADS_LOADING:
			return {
				...state,
				recentLeadsLoading: true,
			};
		case leadManagementActions.GET_RECENT_LEADS_SUCCESS:
			return {
				...state,
				recentLeads: action.payload,
				recentLeadsLoading: false,
			};
		case leadManagementActions.GET_RECENT_LEADS_FAILURE:
			return {
				...state,
				recentLeads: null,
				recentLeadsLoading: false,
			};
		case leadManagementActions.GET_SALES_REPS_LOADING:
			return {
				...state,
				salesRepsLoading: true,
			};
		case leadManagementActions.GET_SALES_REPS_SUCCESS:
			return {
				...state,
				salesReps: action.payload,
				salesRepsLoading: false,
			};
		case leadManagementActions.GET_SALES_REPS_FAILURE:
			return {
				...state,
				salesReps: null,
				salesRepsLoading: false,
			};

		case leadManagementActions.GET_ALL_LEADS_LOADING:
			return {
				...state,
				allLeadsLoading: true,
			};
		case leadManagementActions.GET_ALL_LEADS_SUCCESS:
			return {
				...state,
				allLeads: action.payload,
				allLeadsLoading: false,
			};
		case leadManagementActions.GET_ALL_LEADS_FAILURE:
			return {
				...state,
				allLeads: null,
				allLeadsLoading: false,
			};

		case leadManagementActions.GET_ALL_CASES_LOADING:
			return {
				...state,
				allCasesLoading: true,
			};
		case leadManagementActions.GET_ALL_CASES_SUCCESS:
			return {
				...state,
				allCases: action.payload,
				allCasesLoading: false,
			};
		case leadManagementActions.GET_ALL_CASES_FAILURE:
			return {
				...state,
				allCases: null,
				allCasesLoading: false,
			};

		case leadManagementActions.GET_RECENT_ASSIGNED_LEADS_LOADING:
			return {
				...state,
				recentAssignedLeadsLoading: true,
				recentAssignedLeads: null,
			};

		case leadManagementActions.GET_RECENT_ASSIGNED_LEADS_SUCCESS:
			return {
				...state,
				recentAssignedLeadsLoading: false,
				recentAssignedLeads: action.payload,
			};

		case leadManagementActions.GET_RECENT_ASSIGNED_LEADS_FAILURE:
			return {
				...state,
				recentAssignedLeadsLoading: false,
				recentAssignedLeads: null,
			};

		case leadManagementActions.GET_UNANSWERED_LEADS_LEADS_LOADING:
			return {
				...state,
				unansweredLeadsLoading: true,
				unansweredLeads: null,
			};

		case leadManagementActions.GET_UNANSWERED_LEADS_LEADS_SUCCESS:
			return {
				...state,
				unansweredLeadsLoading: false,
				unansweredLeads: action.payload,
			};

		case leadManagementActions.GET_UNANSWERED_LEADS_LEADS_FAILURE:
			return {
				...state,
				unansweredLeadsLoading: false,
				unansweredLeads: null,
			};

		case leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_LOADING:
			return {
				...state,
				untouchedLeadsLoading: true,
				untouchedLeads: null,
			};

		case leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_SUCCESS:
			return {
				...state,
				untouchedLeadsLoading: false,
				untouchedLeads: action.payload,
			};

		case leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_FAILURE:
			return {
				...state,
				untouchedLeadsLoading: false,
				untouchedLeads: null,
			};

		case leadManagementActions.GET_BOOKINGS_LOADING:
			return {
				...state,
				bookingsLoading: true,
			};
		case leadManagementActions.GET_BOOKINGS_SUCCESS:
			return {
				...state,
				bookings: action.payload,
				bookingsLoading: false,
			};
		case leadManagementActions.GET_BOOKINGS_FAILURE:
			return {
				...state,
				bookings: null,
				bookingsLoading: false,
			};

		default:
			return state;
	}
};
