import React, { useEffect, useState } from "react";
import { Button, Form, Input, Col, Row, Typography, Space, Select } from "antd";
import { getPrescriptionPendingShippingItems } from "../service";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { GET_WAREHOUSES } from "../../../constants/apiUrls";
const { Text } = Typography;

function DispatchSearch({
	setAllSelectedOrders,
	setSelectedKeys,
	setSearchData,
	setWarehouseData,
	paginationInfo,
	setPaginationInfo,
	search,
	setPageNo,
}) {
	const [form] = Form.useForm();
	const [searchLength, setSearchLength] = useState("");
	const [isSearchValue, setSearchValue] = useState(false);
	const [pageNumber] = useState("1");
	const [warehouses, setWarehouses] = useState([]);
	const [warehouse, setWarehouse] = useState("");

	useEffect(() => {
		let res = [];
		let arr = [];
		const init = async () => {
			try {
				res = await fetchRequest(GET_WAREHOUSES());
				console.log("WAREHOUSES", res.data);
				if (res.data.length > 0) {
					arr = res.data.map(item => {
						return { label: item.name, value: item.id };
					});
					arr.push({ label: "N/A", value: "NA" });
					setWarehouses(arr);
				}
				console.log("NEW ARRAY", arr);
			} catch (error) {
				console.warn(error.message);
			}
		};
		init();
	}, []);

	const handleSubmit = async value => {
		if (search === "searchPage") {
			const searchValue = value.search.split(",");
			setSearchLength(searchValue.length);
			setSearchData(value.search);
		} else {
			setPageNo(1);
			if (value.warehouse) {
				setWarehouseData(value.warehouse);
				setAllSelectedOrders([]);
				setSelectedKeys([]);
			} else {
				const searchValue = value.search.split(",");
				setSearchLength(searchValue.length);
				setAllSelectedOrders([]);
				setSelectedKeys([]);
				setSearchData(value.search);
			}
		}
	};

	const handleClear = () => {
		if (search === "dashboard") {
			form.resetFields();
			setSearchValue(false);
			setSearchLength("");
			setAllSelectedOrders([]);
			setWarehouse("");
			setSelectedKeys([]);
			setSearchData([]);
			getPrescriptionPendingShippingItems(pageNumber);
		} else {
			form.resetFields();
			setSearchValue(false);
			setSearchLength("");
			setPaginationInfo(0);
			setAllSelectedOrders([]);
			setSelectedKeys([]);
			setSearchData([]);
		}
	};
	const handleValue = value => {
		console.log("onchanhe", value);
		if (value.warehouse) setWarehouse(value.warehouse);
		if (value.search && value.search.trim()) setSearchValue(true);
		else setSearchValue(false);
	};

	return (
		<>
			<Form form={form} onValuesChange={handleValue} onFinish={handleSubmit}>
				<Row>
					<Col>
						<Form.Item name="search">
							<Input
								style={{ width: 600 }}
								placeholder="Search prescription with order display Id"
								disabled={warehouse}
							/>
						</Form.Item>
					</Col>
					{window.location.pathname !== "/search" && (
						<Col>
							<Form.Item name="warehouse">
								<Select
									allowClear
									dropdownStyle={{ minWidth: "6rem" }}
									options={warehouses}
									onChange={(e, o) =>
										e ? setWarehouse(e) : setWarehouse(null)
									}
									placeholder="Warehouses"
									disabled={isSearchValue}
								/>
							</Form.Item>
						</Col>
					)}
					<Col>
						<Space>
							<Button
								style={{ marginLeft: "10px" }}
								type="primary"
								htmlType="submit"
								disabled={!isSearchValue && !warehouse}>
								Search
							</Button>
							<Button
								onClick={handleClear}
								disabled={!isSearchValue && !warehouse}>
								Clear
							</Button>
						</Space>
					</Col>
				</Row>
			</Form>
			{searchLength && (
				<Text>{`Showing ${paginationInfo} out of my ${searchLength} search results`}</Text>
			)}
		</>
	);
}

export default DispatchSearch;
