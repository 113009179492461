import React, { useState } from "react";

import {
	getConsultCallFeedback,
	sendEmailSMS
} from "../../pages/leadDetails/service";
import {
	engagementFeedback,
	getEngagementsByCase,
	markDone
} from "../../pages/orderManagement/service";

// Antd
import { Button, Form, Input, message, Modal, Radio, Row } from "antd";
import { markReminderDone } from "../../pages/dashboard/service";
import ConsultCallFormMedRep from "../consultCall/ConsultCallMed";
const { TextArea } = Input;

const MarkDoneEngagement = ({
	caseId,
	engagement,
	modalVisible,
	refreshUI,
	reminderId,
	setModalVisible,
	tag,
}) => {
	const [form] = Form.useForm();
	const [feedbackType, setFeedbackType] = useState("");
	const [showFields, setShowFields] = useState({});
	const handleModal = () => {
		form.resetFields();
		setShowFields({});
		setFeedbackType("");
		setModalVisible(!modalVisible);
	};

	const onSubmit = async item => {
		if (!("adherence_reason" in item)) item["adherence_reason"] = "N/A";
		if (!("product_reaction" in item)) item["product_reaction"] = "N/A";
		const _comment =
			feedbackType === "answered"
				? `${feedbackType}(${tag}): ${item.comment}`
				: `${tag} engagement marked as unanswered`;
		const is_answered = feedbackType === "answered" ? "true" : "false";

		await _markReminderDone(item);
		if (feedbackType === "unanswered") {
			const _markDoneRes = await markDone(reminderId, _comment, is_answered);
			if (_markDoneRes.status !== 200) return;

			const res = await sendEmailSMS(reminderId);
			if (res.status !== 200) return;

			await afterReminderUpdated();
			return;
		}

		const _markDoneRes = await markDone(reminderId, _comment, is_answered);
		if (_markDoneRes.status !== 200) return;
		if (tag === "Prescription Call") {
			await afterReminderUpdated();
			return;
		}

		const res = await engagementFeedback(item, reminderId);
		if (res.status !== 200) {
			message.error("Unable to Update Reminder");
			return;
		}

		await afterReminderUpdated();
	};

	const _markReminderDone = async item => {
		if (!engagement.refrence_slot_id) return Promise.resolve("");

		const _data = {
			comment: `user: ${
				feedbackType === "unanswered" ? "unanswered" : item.comment
			}`,
			isAnswered: feedbackType === "unanswered" ? "false" : "true",
		};

		return await markReminderDone(_data, engagement.refrence_slot_id);
	};

	const afterReminderUpdated = () => {
		handleModal();
		if (tag === "Prescription Call") getConsultCallFeedback(caseId);
		if (refreshUI) refreshUI();
	};

	const handleShowFields = (name, value) => {
		setShowFields(curr => {
			const _curr = curr;
			_curr[name] = value;
			return { ..._curr };
		});
	};

	const handleFeedbackTypeChange = ({ target }) => {
		setFeedbackType(target.value);
		setShowFields({});
		form.resetFields();

		if (target.value === "unanswered") {
			handleShowFields("saveBtn", true);
			handleShowFields("mood", false);
			handleShowFields("comment", false);
		} else if (tag === "Prescription Call") {
			handleShowFields("comment", false);
			handleShowFields("mood", false);
			handleShowFields("saveBtn", true);
		} else if (target.value === "answered") {
			handleShowFields("mood", true);
			handleShowFields("comment", false);
			handleShowFields("saveBtn", false);
		}
	};

	const handleFieldChange = e => {
		const _fields = form.getFieldsValue();
		const _field = e[0];

		if ("comment" in _fields) {
			const _hasUndefinedValues = Object.values(_fields).some(value => {
				return typeof value === "undefined" || value === null;
			});
			handleShowFields("saveBtn", !_hasUndefinedValues);
		} else if (feedbackType === "answered") {
			handleShowFields("saveBtn", false);
		}

		switch (_field?.name[0]) {
			case "mood":
				handleShowFields("customer_adherence", true);
				break;

			case "customer_adherence": {
				handleShowFields("saveBtn", false);

				if ("any_product_reaction" in _fields) {
					delete _fields["any_product_reaction"];
					form.setFieldsValue({ any_product_reaction: null });
				}

				if ("product_reaction" in _fields) {
					delete _fields["product_reaction"];
					form.setFieldsValue({ product_reaction: null });
				}

				if ("adherence_reason" in _fields) {
					delete _fields["adherence_reason"];
					form.setFieldsValue({ adherence_reason: null });
				}

				if ("product_reaction" in showFields) {
					handleShowFields("product_reaction", false);
				}

				if (_field.value === "regular") {
					if ("adherence_reason" in _fields) {
						delete _fields["adherence_reason"];
						form.setFieldsValue({ adherence_reason: null });
					}

					handleShowFields("adherence_reason", false);
					handleShowFields("any_product_reaction", true);
				} else if (_field.value === "irregular") {
					handleShowFields("adherence_reason", true);
					handleShowFields("any_product_reaction", false);
				}
				break;
			}

			case "adherence_reason": {
				if ("any_product_reaction" in _fields) {
					delete _fields["any_product_reaction"];
					form.setFieldsValue({ any_product_reaction: null });
				}

				if ("product_reaction" in _fields) {
					delete _fields["product_reaction"];
					form.setFieldsValue({ product_reaction: null });
				}

				if ("product_reaction" in showFields) {
					handleShowFields("product_reaction", false);
				}

				handleShowFields("saveBtn", false);
				handleShowFields("any_product_reaction", true);
				break;
			}

			case "any_product_reaction":
				if (_field.value === "yes") {
					handleShowFields("product_reaction", true);
					handleShowFields("saveBtn", false);
				} else if (_field.value === "no") {
					if ("product_reaction" in _fields) {
						delete _fields["product_reaction"];
						form.setFieldsValue({ product_reaction: null });
					}

					handleShowFields("product_reaction", false);
					handleShowFields("scores", true);
				}
				break;

			case "product_reaction":
				handleShowFields("scores", true);
				break;

			case "hair_growth":
				if (tag === "Week #1" || tag === "Week #2") {
					handleShowFields("comment", true);
				} else {
					handleShowFields("not_ordering_reason", true);
				}
				break;

			case "not_ordering_reason":
				handleShowFields("comment", true);
				break;

			default:
				break;
		}
	};

	if (tag === "Prescription Call") {
		return (
			<ConsultCallFormMedRep
				caseId={caseId}
				engagement={engagement}
				modalVisible={modalVisible}
				reminderId={reminderId}
				setModalVisible={setModalVisible}
				updateUI={afterReminderUpdated}
			/>
		);
	} else {
		return (
			<Modal
				visible={modalVisible}
				title="Add Feedback"
				onOk={handleModal}
				onCancel={handleModal}
				width={600}
				footer={[
					<Button key="back" onClick={handleModal}>
						Cancel
					</Button>,
				]}>
				<Row justify="center">
					<Radio.Group
						optionType="button"
						buttonStyle="outline"
						size="large"
						onChange={handleFeedbackTypeChange}
						value={feedbackType}>
						<Radio.Button value="answered">Answered</Radio.Button>
						<Radio.Button value="unanswered">Didn't answer</Radio.Button>
					</Radio.Group>
				</Row>

				{feedbackType !== "" && (
					<Row justify="center" style={{ marginTop: 16 }}>
						<Form
							form={form}
							layout="vertical"
							onFieldsChange={handleFieldChange}
							onFinish={onSubmit}>
							{showFields.mood && (
								<Form.Item label="Mood" name="mood">
									<Radio.Group
										optionType="button"
										buttonStyle="outline"
										size="large">
										<Radio.Button value={1}>No change</Radio.Button>
										<Radio.Button value={5}>Some change</Radio.Button>
										<Radio.Button value={10}>Improvement</Radio.Button>
									</Radio.Group>
								</Form.Item>
							)}

							{showFields.customer_adherence && (
								<Form.Item
									label="Are they regular with their medication:"
									name="customer_adherence">
									<Radio.Group buttonStyle="outline" size="large">
										<Radio.Button value="regular">Regular</Radio.Button>
										<Radio.Button value="irregular">Irregular</Radio.Button>
									</Radio.Group>
								</Form.Item>
							)}

							{showFields.adherence_reason && (
								<Form.Item label="Reason" name="adherence_reason">
									<Radio.Group buttonStyle="outline" size="large">
										<Radio.Button value="Travel">Travel</Radio.Button>
										<Radio.Button value="Ill">Ill</Radio.Button>
										<Radio.Button value="Work">Work</Radio.Button>
										<Radio.Button value="Products">Products</Radio.Button>
										<Radio.Button value="Diet">Diet</Radio.Button>
									</Radio.Group>
								</Form.Item>
							)}

							{showFields.any_product_reaction && (
								<Form.Item
									label="Any product reaction"
									name="any_product_reaction">
									<Radio.Group buttonStyle="outline" size="large">
										<Radio.Button value="yes">Yes</Radio.Button>
										<Radio.Button value="no">No</Radio.Button>
									</Radio.Group>
								</Form.Item>
							)}

							{showFields.product_reaction && (
								<Form.Item
									label="Product reaction"
									name="product_reaction"
									rules={[
										{ required: true, message: "Please fill this in" },
										{ whitespace: true, message: "Please fill this in" },
									]}>
									<TextArea placeholder="Please write comment" rows={4} />
								</Form.Item>
							)}

							{showFields.scores && (
								<Form.Item label="Score on the following parameters">
									<Input.Group>
										<Form.Item label="Gut Health" name="gut_health">
											<Radio.Group buttonStyle="outline" size="large">
												<Radio.Button value={1}>Sad</Radio.Button>
												<Radio.Button value={5}>Indifferent</Radio.Button>
												<Radio.Button value={10}>Happy</Radio.Button>
											</Radio.Group>
										</Form.Item>

										<Form.Item label="Energy" name="energy">
											<Radio.Group buttonStyle="outline" size="large">
												<Radio.Button value={1}>Sad</Radio.Button>
												<Radio.Button value={5}>Indifferent</Radio.Button>
												<Radio.Button value={10}>Happy</Radio.Button>
											</Radio.Group>
										</Form.Item>

										<Form.Item label="Hair Loss" name="hair_loss">
											<Radio.Group buttonStyle="outline" size="large">
												<Radio.Button value={1}>Sad</Radio.Button>
												<Radio.Button value={5}>Indifferent</Radio.Button>
												<Radio.Button value={10}>Happy</Radio.Button>
											</Radio.Group>
										</Form.Item>

										<Form.Item label="Hair Growth" name="hair_growth">
											<Radio.Group buttonStyle="outline" size="large">
												<Radio.Button value={1}>Sad</Radio.Button>
												<Radio.Button value={5}>Indifferent</Radio.Button>
												<Radio.Button value={10}>Happy</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</Input.Group>
								</Form.Item>
							)}

							{showFields.not_ordering_reason && (
								<Form.Item
									label="Reason for not ordering"
									name="not_ordering_reason">
									<Radio.Group buttonStyle="outline" size="large">
										<Radio.Button value="Discontinued">
											Discontinued
										</Radio.Button>
										<Radio.Button value="Escalation">Escalation</Radio.Button>
										<Radio.Button value="Financial Issues">
											Financial Issues
										</Radio.Button>
										<Radio.Button value="No Results">No Results</Radio.Button>
										<Radio.Button value="Visited another dermat">
											Visited another dermat
										</Radio.Button>
										<Radio.Button value="Ordered">Ordered</Radio.Button>
										<Radio.Button value="Irregular">Irregular</Radio.Button>
										<Radio.Button value="Bulk">Bulk</Radio.Button>
									</Radio.Group>
								</Form.Item>
							)}

							{showFields.comment && (
								<Form.Item
									label="Comment"
									name="comment"
									rules={[
										{ required: true, message: "Please fill this in" },
										{ whitespace: true, message: "Please fill this in" },
									]}>
									<TextArea
										placeholder="Please write comment"
										rows={4}
										style={{ width: 320 }}
									/>
								</Form.Item>
							)}

							{showFields.saveBtn && (
								<Form.Item>
									<Button type="primary" htmlType="submit">
										Save
									</Button>
								</Form.Item>
							)}
						</Form>
					</Row>
				)}
			</Modal>
		);
	}
};

export default MarkDoneEngagement;
