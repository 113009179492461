import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Ant Design
import { Table, Typography } from 'antd/es';
import { useCallback } from 'react';
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
} from '../../../helpers';
import { getInwards } from '../services';
import CreateInwards from './CreateInwards';
const { Title } = Typography;

const Inwards = () => {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState([]);
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams('pageNumber')) || 1
	);

	useEffect(() => {
		let filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		if (isEmpty(queryParams)) return;

		history.push({ search: queryParams });
	}, [history, queryParams]);

	const _getInwards = useCallback(async () => {
		setLoading(true);
		const res = await getInwards();
		setLoading(false);
		setData(res);
	}, []);

	useEffect(() => {
		_getInwards();
	}, [_getInwards]);

	const updateUI = () => {
		_getInwards();
	};

	const column = [
		{
			title: 'Batch Code',
			dataIndex: 'batchCode',
			key: 'batchCode',
		},
		{
			title: 'Sku',
			dataIndex: 'skuName',
			key: 'skuName',
		},
		{
			title: 'Supplier',
			dataIndex: 'supplier',
			key: 'supplier',
		},
		{
			title: 'Batch Importer',
			dataIndex: 'batchImporterName',
			key: 'batchImporterName',
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
		},
	];

	const handleChange = (pagination, filters, sorter) => {
		setPageNo(pagination.current);
	};

	return (
		<>
			<CreateInwards updateUI={updateUI} />
			<div className="flex justify-between">
				<Title level={2}>Warehouse Inwards</Title>
			</div>
			<div className="w-full">
				<Table
					columns={column}
					dataSource={data}
					loading={loading}
					onChange={(page, filters, sorter) =>
						handleChange(page, filters, sorter)
					}
					pagination={{
						current: pageNo,
						showSizeChanger: false,
						total: data?.length || 0,
					}}
				/>
			</div>
		</>
	);
};

export default Inwards;
