import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getValue } from '../../helpers';
import { getReminders } from '../../pages/leadDetails/service';

// Ant design
import { Col, Row, Table, Tag, Typography } from 'antd';
import { CASE_BOOKINGS_API, getUrl } from '../../constants/apiUrls';
import { fetchRequest } from '../../helpers/fetchRequest';
const { Title } = Typography;
// Ends

const columns = [
	{
		title: 'Due Date',
		dataIndex: 'reminder_date',
		key: 'reminder_date',
		render: dueDate => moment(dueDate).format('DD MMM YYYY, h:mm a'),
	},
	{
		title: 'Tag',
		dataIndex: 'tag',
		key: 'tag',
		render: tag => {
			if (isEmpty(tag)) return '-';
			return (
				<Tag key={tag} color="geekblue">
					{tag.replaceAll('_', ' ').toUpperCase()}
				</Tag>
			);
		},
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: status => {
			if (isEmpty(status)) return '-';
			return (
				<Tag key={status} color="cyan">
					{status.toUpperCase()}
				</Tag>
			);
		},
	},
];

const CaseRemindersAdmin = () => {
	const { id: case_id } = useParams();

	const [bookings, setBookings] = useState([]);
	const [engagements, setEngagements] = useState([]);
	const [loadingBookings, setLoadingBookings] = useState(false);
	const [loadingEngagements, setLoadingEngagements] = useState(false);

	const { loading, reminders } = useSelector(state => ({
		loading: getValue(state, 'leaddetails.remindersLoading'),
		reminders: getValue(state, 'leaddetails.reminders'),
	}));

	useEffect(() => {
		getReminders(case_id);
	}, [case_id]);

	const getEngagementsByCase = useCallback(async () => {
		if (isEmpty(case_id)) setEngagements([]);

		let res = {};
		setLoadingEngagements(true);

		try {
			res = await fetchRequest(getUrl(`engagements/${case_id}`));
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) setEngagements([]);
			else setEngagements(res.data);
			setLoadingEngagements(false);
		}
	}, [case_id]);

	// Fetch Bookings
	const getBookings = useCallback(async () => {
		if (isEmpty(case_id)) setBookings([]);

		let res = {};
		setLoadingBookings(true);

		try {
			res = await fetchRequest(CASE_BOOKINGS_API(case_id));
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) setBookings([]);
			else setBookings(res.data);
			setLoadingBookings(false);
		}
	}, [case_id]);

	useEffect(() => {
		getBookings();
	}, [getBookings]);

	useEffect(() => {
		getEngagementsByCase();
	}, [getEngagementsByCase]);
	// Ends

	const bookingsData = useMemo(() => {
		if (isEmpty(bookings)) return [];
		return bookings.map(row => ({
			key: getValue(row, 'id'),
			reminder_date: getValue(row, 'slot.slot_time'),
			status: 'active',
			tag: 'SLOT BOOKING',
		}));
	}, [bookings]);

	const remindersData = useMemo(() => {
		if (isEmpty(reminders)) return [];
		return reminders.map(row => ({
			key: getValue(row, 'id'),
			reminder_date: getValue(row, 'reminder_date'),
			status: getValue(row, 'status'),
			tag: getValue(row, 'tag') || 'USER',
		}));
	}, [reminders]);

	const engagementsData = useMemo(() => {
		if (isEmpty(engagements)) return [];
		return engagements.map(row => ({
			key: getValue(row, 'id'),
			reminder_date: getValue(row, 'reminder_date'),
			status: getValue(row, 'status'),
			tag: getValue(row, 'tag') || 'USER',
		}));
	}, [engagements]);

	const combinedData = useMemo(() => {
		const _data = [...remindersData, ...bookingsData, ...engagementsData];
		return _data.sort((a, b) => {
			return new Date(a.reminder_date) - new Date(b.reminder_date);
		});
	}, [bookingsData, remindersData, engagementsData]);

	return (
		<>
			<Row justify="space-between">
				<Title className="ml-4 mb-2 mt-4" level={4}>
					Bookings
				</Title>
			</Row>

			<Row justify="center">
				<Col span={24}>
					<Table
						bordered
						columns={columns}
						dataSource={combinedData}
						loading={loading || loadingBookings || loadingEngagements}
						pagination={false}
						scroll={{ y: 256 }}
					/>
				</Col>
			</Row>
		</>
	);
};

export default CaseRemindersAdmin;
