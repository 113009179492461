import React, { useMemo, useState } from "react";
import { getUploadPhotoLink } from "./service";

// Ant design
import Button from "antd/es/button";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";
const { Paragraph } = Typography;

export const PhotoLinkBtn = ({ caseId }) => {
	const [generatingURL, setGeneratingURL] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	const [popupVisible, setPopupVisible] = useState(false);

	const handleGenerateURL = async () => {
		setGeneratingURL(true);
		const _res = await getUploadPhotoLink(caseId);
		setGeneratingURL(false);

		if (_res.hasError) return;
		await setImageUrl(_res.data);
		setPopupVisible(true);
	};

	const handleVisibleChange = visible => {
		if (!visible) setPopupVisible(visible);
	};

	const popoverContent = useMemo(() => {
		return (
			<Paragraph
				copyable={{ onCopy: () => setPopupVisible(false), tooltips: false }}
				style={{ marginBottom: 0 }}>
				{imageUrl}
			</Paragraph>
		);
	}, [imageUrl]);

	return (
		<Popover
			content={popoverContent}
			placement="left"
			trigger="click"
			onVisibleChange={visible => handleVisibleChange(visible)}
			visible={popupVisible}>
			<Button
				onClick={handleGenerateURL}
				disabled={generatingURL}
				loading={generatingURL}
				type="primary">
				Get Photo Link
			</Button>
		</Popover>
	);
};
