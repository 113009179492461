import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Loader, NotAllowedError } from "../../../common";
import { getValue } from "../../../helpers";
import {
	AddCommentAndReminder,
	OrderDetails,
	RecentComments,
	Reminders,
	TagHistory,
	UsersDetail,
} from "../components";
import { viewDetails } from "../service";
import ActivityLog from "./ActivityLog";
import ChatBox from "./ChatBox";
// import CaseSlotBookings from "./CaseSlotBookings";
import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row, Space, Tabs, Typography } from "antd";
import { EscalateCase } from "../../../components";
import TicketHistory from "./TicketHistory";
import FreshchatBox from './FreschatBox';

// import CaseReminder from "./CaseReminder";
const { TabPane } = Tabs;
const { Text } = Typography;

const ViewDetailsBySales = () => {
	const history = useHistory();
	const params = useParams();
	const [showAddComment, setShowAddComment] = useState(false);

	const { chats, detail, orders } = useSelector(state => ({
		chats: getValue(state, "leaddetails.chats"),
		detail: getValue(state, "leaddetails.usersDetail"),
		orders: getValue(state, "leaddetails.usersDetail.orders"),
	}));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		viewDetails(params.id);
	}, [params.id]);

	const chatListContent = () => (
		<Text type="warning">
			Chat number is available in{" "}
			<Text style={{ color: "#B45309" }}>{chats?.profileCount - 1}</Text> more
			profile, please contact admin or support for more details
		</Text>
	);

	if (!detail) return <Loader />;
	if (detail && detail.Error) return <NotAllowedError />;

	return (
		<div className="lead-details">
			<Row justify="space-between" style={{ paddingBottom: "1rem" }}>
				<Button onClick={history.goBack} type="link" icon={<LeftOutlined />}>
					Back
				</Button>

				{/* <EscalateCase escalationStatus={detail.escalation_status} /> */}
			</Row>

			<Row>
				<Col span={24}>
					<UsersDetail />
				</Col>
			</Row>

			{!isEmpty(orders) && (
				<Row>
					<Col span={24}>
						<OrderDetails />
					</Col>
				</Row>
			)}

			<Row>
				<Col
					span={12}
					style={{
						border: "1px solid #F0F0F0",
						height: 385,
						overflow: "hidden",
					}}>
					<Reminders type="Case" />
					{/* <Tabs type="card" defaultActiveKey="case_reminders">
							<TabPane key="case_reminders" tab="Case Reminders">
							</TabPane> */}

					{/* {isEmpty(orders) && (
							<TabPane key="my_reminders" tab="My Reminders">
								<Reminders type="User" />
							</TabPane>
						)}

						<TabPane key="slot_bookings" tab="Slot Bookings">
							<CaseSlotBookings />
						</TabPane> */}
					{/* </Tabs> */}
				</Col>

				<Col
					span={12}
					style={{
						border: "1px solid #F0F0F0",
						height: 385,
					}}>
					<Tabs
						defaultActiveKey="1"
						type="card"
						onChange={activeKey => setShowAddComment(activeKey === "2")}>
						<TabPane
							tab={
								chats?.profileCount > 1 ? (
									<Space>
										Chats
										<Popover content={chatListContent} title="">
											<ExclamationCircleOutlined style={{ color: "#D97706" }} />
										</Popover>
									</Space>
								) : (
									"Chats"
								)
							}
							key="1">
							<ChatBox />
						</TabPane>

						<TabPane tab="Chats from freshchats" key="6">
							<FreshchatBox />
						</TabPane>

						<TabPane tab="Recent Comments" key="2">
							<RecentComments />
						</TabPane>

						<TabPane tab="Activity Log" key="3">
							<ActivityLog />
						</TabPane>

						<TabPane tab="Tag History" key="4">
							<TagHistory />
						</TabPane>
						<TabPane tab="Ticket History" key="5">
							<TicketHistory />
						</TabPane>
					</Tabs>
				</Col>
			</Row>
			<Row>
				{showAddComment && (
					<Col offset={12} span={12}>
						<AddCommentAndReminder />
					</Col>
				)}
			</Row>
		</div>
	);
};

export default ViewDetailsBySales;
