export const assignedOrdersActions = {
  GET_MY_PATIENTS_LOADING: "GET_MY_PATIENTS_LOADING",
  GET_MY_PATIENTS_SUCCESS: "GET_MY_PATIENTS_SUCCESS",
  GET_MY_PATIENTS_FAILURE: "GET_MY_PATIENTS_FAILURE",

  GET_ASSIGNED_GHOST_ORDERS_LOADING: "GET_ASSIGNED_GHOST_ORDERS_LOADING",
  GET_ASSIGNED_GHOST_ORDERS_SUCCESS: "GET_ASSIGNED_GHOST_ORDERS_SUCCESS",
  GET_ASSIGNED_GHOST_ORDERS_FAILURE: "GET_ASSIGNED_GHOST_ORDERS_FAILURE",

  GET_NEW_OPEN_ORDERS_LOADING: "GET_NEW_OPEN_ORDERS_LOADING",
  GET_NEW_OPEN_ORDERS_SUCCESS: "GET_NEW_OPEN_ORDERS_SUCCESS",
  GET_NEW_OPEN_ORDERS_FAILURE: "GET_NEW_OPEN_ORDERS_FAILURE",

  GET_REPEAT_OPEN_ORDERS_LOADING: "GET_REPEAT_OPEN_ORDERS_LOADING",
  GET_REPEAT_OPEN_ORDERS_SUCCESS: "GET_REPEAT_OPEN_ORDERS_SUCCESS",
  GET_REPEAT_OPEN_ORDERS_FAILURE: "GET_REPEAT_OPEN_ORDERS_FAILURE",
};
