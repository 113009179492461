import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	getComments,
	getReminders,
	postFeedback,
	viewDetails,
} from "../service";
import { markReminderDone, updateReminder } from "../../dashboard/service";
import { optionsCaseStatus } from "../../../constants/options";
import { leadDespositionMapper } from "../../../managers/caseManager";
import {
	CalendarOutlined,
	CheckOutlined,
	SaveOutlined,
} from "@ant-design/icons";
import {
	Button,
	Col,
	DatePicker,
	message,
	Modal,
	Row,
	Select,
	Space,
	Tooltip,
	Typography,
} from "antd";
const { Title } = Typography;

const LeadStatus = ({ feedback = null, status = null }) => {
	const params = useParams();
	const [leadFeedback, setLeadFeedback] = useState(null);
	const [leadStatus, setLeadStatus] = useState(status);
	const [reminders, setReminders] = useState([]);
	const [reminderDate, setReminderDate] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [updatingMarkDone, setUpdatingMarkDone] = useState(false);
	const [updatingReminder, setUpdatingReminder] = useState(false);
	const [updatingStatus, setUpadtingStatus] = useState(false);

	const { orders, allReminders } = useSelector(state => ({
		orders: window.getValue(state, "leaddetails.usersDetail.orders"),
		allReminders: window.getValue(state, "leaddetails.reminders"),
	}));

	// setReminderDate
	useEffect(() => {
		const updateState = (newReminders, newReminderData) => {
			setReminders(newReminders);
			setReminderDate(newReminderData);
		};

		if (isEmpty(allReminders)) return updateState([], null);

		const _reminders = allReminders.filter(reminder => {
			return reminder.type === "system";
		});
		if (isEmpty(_reminders)) return updateState([], null);

		return updateState(_reminders, moment(_reminders[0].reminder_date));
	}, [allReminders]);
	// End of setReminderDate

	// get optionsFeedback
	const optionsFeedback = useMemo(() => {
		if (isEmpty(leadStatus)) return null;
		return leadDespositionMapper(leadStatus);
	}, [leadStatus]);
	// End of optionsFeedback

	// setLeadFeedback
	useEffect(() => {
		if (optionsFeedback === null) return setLeadFeedback(null);
		if (optionsFeedback.some(item => item.label === feedback))
			return setLeadFeedback(feedback);

		return setLeadFeedback(null);
	}, [feedback, optionsFeedback]);
	// End of setLeadFeedback

	// isValidReminders
	const isNotValidReminders = useMemo(() => {
		if (isEmpty(reminders)) return true;
		return moment(reminders[0]?.reminder_date).isAfter(moment(), "day");
	}, [reminders]);
	// End of isValidReminders

	// common Dsiable Conditions
	const disableBtn = useMemo(() => {
		if (isNotValidReminders) return true;
		if (leadStatus === "new") return true;
		if (!isEmpty(orders)) return true;
		return isEmpty(leadFeedback) || isEmpty(leadStatus);
	}, [leadFeedback, leadStatus, orders, isNotValidReminders]);
	// End of common Dsiable Conditions

	// disableRescheduleBtn
	const disableRescheduleBtn = useMemo(() => {
		if (disableBtn) return true;
		if (leadStatus === "not_connected") return true;
	}, [disableBtn, leadStatus]);
	// End of disableRescheduleBtn

	// disableUpdateStatusBtn
	const disableUpdateStatusBtn = useMemo(() => {
		if (leadStatus === "new") return true;
		if (!isEmpty(orders)) return true;
		return isEmpty(leadFeedback) || isEmpty(leadStatus);
	}, [leadFeedback, leadStatus, orders]);
	// End of disableUpdateStatusBtn

	const handleModalClose = () => {
		setShowModal(false);
		setReminderDate(moment(reminders[0]?.reminder_date));
	};

	// handleStatusChange
	const handleStatusChange = value => {
		setLeadStatus(() => value || null);
		if (value === status) setLeadFeedback(feedback);
		else setLeadFeedback(null);
	};
	// End of handleStatusChange

	const refreshUi = () => {
		getComments(params.id);
		getReminders(params.id);
		viewDetails(params.id);
	};

	// handleMarkDone
	const handleMarkDone = async () => {
		if (isEmpty(reminders)) return message.warn("No reminders");
		if (leadStatus === "not_connected") {
			handleReminder();
			return;
		}

		const _data = {
			caseFeedback: leadFeedback,
			caseStatus: leadStatus,
			comment: `system : ${leadFeedback}`,
		};

		setUpdatingMarkDone(true);
		const _res = await markReminderDone(_data, reminders[0].id);
		setUpdatingMarkDone(false);

		if (_res.status !== 200) return;
		refreshUi();
	};
	// End of handleMarkDone

	// handleReminder
	const handleReminder = async () => {
		if (isEmpty(reminders)) return message.warn("No reminders");

		const _data = {
			caseStatus: leadStatus,
			caseFeedback: leadFeedback,
			comment: leadFeedback,
			reminder_date: reminderDate,
			reminder_id: reminders[0].id,
			status: reminders[0].status,
		};

		setUpdatingReminder(true);
		const _res = await updateReminder(_data);
		setUpdatingReminder(false);

		if (_res.status !== 200) return;

		refreshUi();
		handleModalClose();
	};
	// End of handleReminder

	// handlePostFeedback
	const handlePostFeedback = async () => {
		const _data = {
			feedback: leadFeedback,
			reminder_id: reminders[0]?.id,
			status: leadStatus,
		};

		setUpadtingStatus(true);
		const _res = await postFeedback(_data, params.id);
		setUpadtingStatus(false);

		if (_res.status !== 200) return;
		refreshUi();
	};
	// End of handlePostFeedback

	return (
		<>
			<Row className="lead-info" gutter={[2, 1]} justify="space-between">
				<Col span={24}>
					<Title level={5} type="secondary">
						{reminders[0]?.tag?.replaceAll("_", " ")}
					</Title>
				</Col>

				<Col span={17}>
					<Space size={2}>
						<Select
							allowClear
							dropdownStyle={{ minWidth: 160 }}
							options={optionsCaseStatus}
							onChange={handleStatusChange}
							placeholder="Status"
							value={leadStatus}
							style={{ maxWidth: "100px" }}
						/>

						{!isEmpty(optionsFeedback) && (
							<Select
								allowClear
								dropdownStyle={{ minWidth: 240 }}
								options={optionsFeedback}
								onChange={value => setLeadFeedback(() => value || null)}
								placeholder="Feedback"
								value={leadFeedback}
								style={{ maxWidth: "120px" }}
							/>
						)}
					</Space>
				</Col>

				<Col span={7}>
					<Space size={2}>
						<Tooltip placement="bottom" title="Reschedule">
							<Button
								disabled={disableRescheduleBtn || updatingReminder}
								icon={<CalendarOutlined />}
								loading={updatingReminder}
								onClick={() => setShowModal(true)}
								type="primary"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title="Mark as Done">
							<Button
								disabled={disableBtn || updatingMarkDone}
								icon={<CheckOutlined />}
								loading={updatingMarkDone}
								onClick={handleMarkDone}
								type="primary"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title="Update">
							<Button
								danger
								disabled={disableUpdateStatusBtn || updatingStatus}
								icon={<SaveOutlined />}
								loading={updatingStatus}
								onClick={() => handlePostFeedback()}
								type="primary"
							/>
						</Tooltip>
					</Space>
				</Col>
			</Row>

			<Modal
				visible={showModal}
				title="Reminder"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,

					<Button
						disabled={
							moment(reminders[0]?.reminder_date).isSame(reminderDate) ||
							isEmpty(reminderDate)
						}
						key="submit"
						loading={updatingReminder}
						type="primary"
						onClick={handleReminder}>
						Submit
					</Button>,
				]}>
				<Row align="middle" justify="center">
					<Col>
						<DatePicker
							disabledDate={current => current.isBefore(moment(), "day")}
							format={"DD MMM, h:mm A"}
							onChange={date => setReminderDate(date)}
							showNow={false}
							showTime={true}
							value={!isEmpty(reminderDate) ? reminderDate : null}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default LeadStatus;
