import Cookies from "js-cookie";
import { reportActions } from "./actions/report";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";

// Ant Design
import message from "antd/es/message";

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const getReOrderReport = async queryParams => {
	store.dispatch({
		type: reportActions.GET_REORDER_REPORT_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}reorderReport?${queryParams}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: reportActions.GET_REORDER_REPORT_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: reportActions.GET_REORDER_REPORT_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: reportActions.GET_REORDER_REPORT_FAILURE,
			payload: error.toString(),
		});
	}
};
export const getEngagementPlanReport = async data => {
	store.dispatch({
		type: reportActions.GET_ALL_ENGAGEMENT_LOADING,
		payload: true,
	});
	try {
		let CsvData = new FormData();
		CsvData.append("orderDisplayId", data.fileData);
		const res = await fetch(
			`${baseURL.API_URL_BASE}engagementReport?${data.queryParams}`,
			{
				method: "POST",
				body: CsvData,
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: reportActions.GET_ALL_ENGAGEMENT_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: reportActions.GET_ALL_ENGAGEMENT_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: reportActions.GET_ALL_ENGAGEMENT_FAILURE,
			payload: error.toString(),
		});
	}
};
export const getPrescriptionReport = async queryParams => {
	store.dispatch({
		type: reportActions.GET_PRESCRIPTION_REPORT_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}doctorPrescriptionReport?${queryParams}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: reportActions.GET_PRESCRIPTION_REPORT_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: reportActions.GET_PRESCRIPTION_REPORT_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: reportActions.GET_PRESCRIPTION_REPORT_FAILURE,
			payload: error.toString(),
		});
	}
};
export const getReOrderData = async data => {
	store.dispatch({
		type: reportActions.GET_REORDER_DATA_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}reOrderData?startDate=${data.startDate}&endDate=${data.endDate}&assignmentId=${data.medical_rep}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: reportActions.GET_REORDER_DATA_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: reportActions.GET_REORDER_DATA_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: reportActions.GET_REORDER_DATA_FAILURE,
			payload: error.toString(),
		});
	}
};

export const downloadReport = async (queryParams, report) => {
	let res = "";
	const _query = new URLSearchParams(queryParams);
	const _meta = {};

	for (let item of _query) {
		_meta[item[0]] = item[1];
	}

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}request-report`, {
			method: "POST",
			...AUTH_HEADER,
			body: JSON.stringify({
				report: report,
				meta: _meta,
			}),
		});

		res = _res;
		if (_res.status === 200) {
			message.success(
				`Your report will be sent to your email ${localStorage.getItem(
					"alternate!email"
				)} shortly`
			);
		} else {
			message.error("Something went wrong, please contact administrator");
		}
	} catch (error) {
		console.warn(error);
		message.error("Something went wrong, please contact administrator");
	} finally {
		return res;
	}
};
