import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { generateQuery } from "../../../helpers";

// Ant Design
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Typography from "antd/es/typography";
import { downloadReport } from "../service";
const { Title } = Typography;

const OrderLinkReport = () => {
	const [form] = Form.useForm();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async items => {
		const filterItems = [];

		Object.keys(items).map(key => {
			switch (key) {
				case "startDate":
					filterItems.push({
						[key]: startDate
							? moment(startDate).startOf("day").toISOString()
							: null,
					});
					break;

				case "endDate":
					filterItems.push({
						[key]: endDate ? moment(endDate).endOf("day").toISOString() : null,
					});
					break;

				default:
					break;
			}
		});

		const _query = generateQuery(filterItems);

		setIsLoading(true);
		const _res = await downloadReport(_query, "ORDER_LINK");
		if (_res.status === 200) handleClear();
		setIsLoading(false);
	};

	const handleClear = async () => {
		await setStartDate(null);
		await setEndDate(null);
		form.resetFields();
	};

	const formInitialValues = {
		startDate: startDate ? moment(startDate) : null,
		endDate: endDate ? moment(endDate) : null,
	};

	return (
		<>
			<Title level={2}>Order Link Report</Title>

			<Form
				form={form}
				initialValues={formInitialValues}
				layout="inline"
				onFinish={onSubmit}>
				<Form.Item name="startDate">
					<DatePicker
						allowClear
						disabledDate={current =>
							endDate
								? current.isAfter(moment(endDate), "day")
								: current.isAfter(moment(), "day")
						}
						format={"YYYY-MM-DD"}
						id="start_date_input"
						onChange={e => (e ? setStartDate(e) : setStartDate(null))}
						placeholder="Start Date"
						style={{ width: 176 }}
					/>
				</Form.Item>

				<Form.Item name="endDate">
					<DatePicker
						allowClear
						disabledDate={current =>
							(startDate && current.isBefore(moment(startDate), "day")) ||
							current.isAfter(moment(), "day")
						}
						format={"YYYY-MM-DD"}
						id="end_date_input"
						onChange={e => (e ? setEndDate(e) : setEndDate(null))}
						placeholder="End Date"
						style={{ width: 176 }}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						disabled={isEmpty(startDate) || isEmpty(endDate)}
						id="generate_report_btn"
						loading={isLoading}
						type="primary"
						htmlType="submit">
						Generate Report
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default OrderLinkReport;
