import React, { useState } from "react";
import OrderCartLinkModal from "./OrderCartLinkModal";
import { Button } from "antd";

export const OrderCartLink = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button onClick={() => setShowModal(true)} type="primary">
				Send Order Link
			</Button>
			<OrderCartLinkModal isVisible={showModal} setIsVisible={setShowModal} />
		</>
	);
};
