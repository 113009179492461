import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getValue } from "../../../helpers";
import { statusTagColor } from "../../../helpers/tagColor";
import { getAllLeads } from "../service";
import { formStatusTitle } from "../utils/formStatus";
import LeadManagement from "./LeadManagement";

// Antd
import { Badge, Tag, Typography } from "antd/es";
const { Text } = Typography;
// Ends

function AllLeads() {
	const { allLeads, loading } = useSelector(state => ({
		allLeads: getValue(state, "leadmanagement.allLeads"),
		loading: getValue(state, "leadmanagement.allLeadsLoading"),
	}));

	const tableData = useMemo(() => {
		if (isEmpty(allLeads)) return { data: [], pagination: {} };
		const _data = allLeads.data.map(row => ({
			key: getValue(row, "id"),
			name: getValue(row, "user.first_name"),
			phoneno: getValue(row, "user.phone_number"),
			status: getValue(row, "status"),
			created: getValue(row, "created_at"),
			assignedto: getValue(row, "assignment.first_name"),
			formStatus: getValue(row, "form_status"),
			lastFormFillDate: getValue(row, "last_form_fill_date"),
			repLevel: getValue(row, "assignment.level"),
			gender: getValue(row, "user.gender"),
		}));

		return { data: _data, pagination: allLeads.pagination };
	}, [allLeads]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { key, phoneno }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>
					<Text type="secondary">{phoneno}</Text>
				</div>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status = "") => (
				<Tag color={statusTagColor[status]} key={status}>
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Form status",
			dataIndex: "formStatus",
			key: "formStatus",
			render: formStatus => (
				<Text style={{ textTransform: "capitalize" }}>
					{formStatusTitle[formStatus]}
				</Text>
			),
		},
		{
			title: "Last form fill date",
			dataIndex: "lastFormFillDate",
			key: "lastFormFillDate",
			align: "center",
			render: lastFormFillDate => (
				<Text>
					{isEmpty(lastFormFillDate)
						? "-"
						: moment(lastFormFillDate).format("DD MMM YYYY, h:mm a")}
				</Text>
			),
		},
		{
			title: "Created",
			dataIndex: "created",
			key: "created",
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned To",
			dataIndex: "assignedto",
			key: "assignedto",
			render: (assignedto, { repLevel }) => (
				<div className="flex items-center gap-1">
					<Text>{assignedto}</Text>
					<Badge
						count={repLevel}
						style={{ backgroundColor: "#dcfce7", color: "#166534" }}
					/>
				</div>
			),
		},
	];

	return (
		<LeadManagement
			apiCall={getAllLeads}
			columns={columns}
			data={tableData.data}
			loading={loading}
			paginationInfo={tableData.pagination}
			pageTitle="All Leads"
		/>
	);
}

export default AllLeads;
