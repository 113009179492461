import React, { useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";

import {
	leadDespositionFeedbacks,
	leadDespositionMapper,
} from "../../managers/caseManager";

// Antd
import { Button, DatePicker, Form, Modal, Row, Select } from "antd/es";

const MarkDoneSlotBooking = ({
	modalVisible,
	handleSubmit,
	setModalVisible,
	tag,
}) => {
	const [form] = Form.useForm();

	const [leadFeedback, setLeadFeedback] = useState("");
	const [leadStatus, setLeadStatus] = useState("");
	const [loading, setLoading] = useState(false);
	const [newReminderDate, setNewReminderDate] = useState(null);

	const handleModal = () => {
		form.resetFields();
		setLeadFeedback("");
		setLeadStatus("");
		setModalVisible(false);
	};

	// get optionsFeedback
	const optionsFeedback = useMemo(() => {
		if (isEmpty(leadStatus)) return null;
		const _options = leadDespositionMapper(leadStatus);

		if (leadStatus === "hot") {
			if (tag === "FOLLOW_UP_CALL_3") return _options;
			return _options.filter(option => option.value !== "No Response");
		}

		return _options;
	}, [leadStatus, tag]);
	// End of optionsFeedback

	const handleFieldChange = e => {
		const _field = e[0];
		if (_field?.name[0] === "status") {
			form.setFieldsValue({ feedback: null });
			form.setFieldsValue({ slot: null });
			setLeadFeedback("");
			setNewReminderDate(null);
		}

		if (_field?.name[0] === "feedback") {
			form.setFieldsValue({ slot: null });
			setNewReminderDate(null);
		}
	};

	// Show Calender
	const showCalender = useMemo(() => {
		if (!leadFeedback || !leadStatus) return false;

		if (leadStatus === "hot") {
			return leadFeedback === "Order Expected";
		}

		if (leadStatus === "cold") return true;

		return false;
	}, [leadFeedback, leadStatus]);
	// End

	// Show save button
	const showSaveBtn = useMemo(() => {
		if (showCalender) {
			if (leadFeedback === "Busy Call later") return true;
			if (isEmpty(newReminderDate)) return false;
			else return true;
		}
		if (isEmpty(leadFeedback) || isEmpty(leadStatus)) return false;
		return true;
	}, [leadFeedback, leadStatus, newReminderDate, showCalender]);
	// End

	const _handleSubmit = async () => {
		const _obj = {
			caseFeedback: leadFeedback,
			caseStatus: leadStatus,
			comment: `system[${leadStatus}] : ${leadFeedback}`,
		};

		if (!isEmpty(newReminderDate)) {
			_obj["next_reminder_date"] = newReminderDate.toISOString();
		}

		setLoading(true);
		const _res = await handleSubmit(_obj);
		setLoading(false);

		if (!_res.hasError) handleModal();
	};

	return (
		<Modal
			visible={modalVisible}
			title="Lead Disposition"
			onOk={handleModal}
			onCancel={handleModal}
			footer={[
				<Button key="back" onClick={handleModal}>
					Cancel
				</Button>,
			]}>
			<Row justify="center" style={{ marginTop: 16 }}>
				<Form
					form={form}
					layout="vertical"
					onFieldsChange={handleFieldChange}
					onFinish={_handleSubmit}>
					<Form.Item label="Status" name="status">
						<Select
							allowClear
							placeholder="Please Select"
							onChange={value => setLeadStatus(value || "")}
							options={leadDespositionFeedbacks}
							style={{ minWidth: 240 }}
						/>
					</Form.Item>

					{!isEmpty(optionsFeedback) && (
						<Form.Item label="Feedback" name="feedback">
							<Select
								allowClear
								options={optionsFeedback}
								onChange={value => setLeadFeedback(() => value || "")}
								placeholder="Feedback"
								value={leadFeedback}
								style={{ minWidth: 240 }}
							/>
						</Form.Item>
					)}

					{showCalender && (
						<Form.Item
							label={
								leadFeedback === "Busy Call later"
									? "2ND ATTEMPT"
									: "FOLLOW UP CALL 1"
							}
							name="slot">
							<DatePicker
								allowClear
								format={"YYYY-MM-DD hh:mm a"}
								showTime={true}
								style={{ width: 240 }}
								disabledDate={current => current.isBefore(moment(), "day")}
								onChange={date => setNewReminderDate(date)}
							/>
						</Form.Item>
					)}

					{showSaveBtn && (
						<Form.Item className="text-center">
							<Button loading={loading} type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					)}
				</Form>
			</Row>
		</Modal>
	);
};

export default MarkDoneSlotBooking;
