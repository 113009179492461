import { Col, Row, Space, Table, Tag, Tooltip, Typography } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { OrderFilters } from "../../../common";
import CsvDownload from "../../../common/CsvDownload";
import { getSubscriptionPlatform } from "../../../constants/orderSubscriptionPlatforms";
import {
	generateQuery,
	getLineItemsName,
	getQueryParams,
	getSearchParams,
	getValue,
} from "../../../helpers";
import { getMedicalReps, getOpenOrders } from "../service";
const { Text, Title } = Typography;

function OpenOrders() {
	const history = useHistory();
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [queryParams, setQueryParams] = useState(getSearchParams);

	useEffect(() => {
		getMedicalReps();
		const query = new URLSearchParams(window.location.search);
		getOpenOrders(query.toString());
	}, []);

	useEffect(() => {
		const filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });
	}, [history, queryParams]);

	const handleFilter = query => {
		getOpenOrders(query);
	};

	const { orders, loading } = useSelector(state => ({
		orders: getValue(state, "orderManagement.openOrders"),
		loading: getValue(state, "orderManagement.openOrdersLoading"),
	}));

	const data = useMemo(() => {
		if (isEmpty(orders)) return [];
		return orders.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			openOrderName: getValue(row, "case.user.first_name"),
			ghostOrderName: getValue(row, "order_meta.customer.first_name"),
			phone_Number: getValue(row, "case.user.phone_number"),
			ghost_phone_Number: getValue(row, "order_phone_number"),
			orderDisplayId: getValue(row, "order_display_id"),
			orderName: getLineItemsName(row.order_meta?.line_items),
			orderStatus: getValue(row, "status"),
			orderDate: getValue(row, "created_at"),
			count: getValue(row, "count"),
			is_subscription: getValue(row, "is_subscription_order"),
			is_combo: getValue(row, "is_combo"),
			is_bulk_order: getValue(row, "is_bulk_order"),
			bulk_order_duration: getValue(row, "bulk_order_duration"),
			order_type: getValue(row, "order_type"),
			assignedTo: getValue(row, "assignment.first_name"),
			owner: getValue(row, "case.assignment.first_name"),
			warehouse: getValue(row, "order_warehouse.warehouses.name"),
			tracking_id: getValue(row, "tracking_id"),
			tracking_url: getValue(row, "tracking_url"),
			salesRep: getValue(row, "case.salesRep.first_name"),
			warehouse_assignment_time: getValue(row, "order_warehouse.created_at"),
			order_meta: getValue(row, "order_meta"),
			sub_status: getValue(row, "sub_status"),
		}));
	}, [orders]);

	const columns = [
		{
			title: "Name",
			key: "name",
			width: 180,
			render: record => {
				if (record.orderStatus !== "ghost") {
					let subscriptionPlatform = "";
					if (record.is_subscription) {
						subscriptionPlatform = getSubscriptionPlatform(record.order_meta);
						subscriptionPlatform = subscriptionPlatform
							? `(${subscriptionPlatform})`
							: "";
					}

					return (
						<Space direction="vertical">
							<Link className="link" to={`/lead-details/${record.case_id}`}>
								{record.openOrderName}
							</Link>

							<Text type="secondary">
								{isEmpty(record.phone_Number)
									? record.ghost_phone_Number
									: record.phone_Number}
							</Text>

							<span>
								{record.is_subscription && (
									<Tooltip
										placement="bottom"
										title={`Subscription Order ${subscriptionPlatform}`}>
										<Tag color="green">S</Tag>
									</Tooltip>
								)}

								{record.is_bulk_order && (
									<Tooltip
										placement="bottom"
										title={`Bulk Order (${record.bulk_order_duration} months)`}>
										<Tag color="green">B</Tag>
									</Tooltip>
								)}
								{record.is_combo && (
									<Tooltip placement="bottom" title={`Combo Order`}>
										<Tag color="green">C</Tag>
									</Tooltip>
								)}
							</span>
						</Space>
					);
				} else {
					return <span>{record.ghostOrderName}</span>;
				}
			},
		},
		{
			title: "Order ID",
			dataIndex: "orderDisplayId",
			key: "orderDisplayId",
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},
		{
			title: "Order Name",
			dataIndex: "orderName",
			key: "orderName",
			width: 240,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "orderStatus",
			key: "orderStatus",
			width: 112,
			render: (status, record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
			defaultSortOrder: false,
			sorter: (a, b) => a.orderStatus.localeCompare(b.orderStatus),
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "orderDate",
			render: orderDate => moment(orderDate).format("DD MMM YYYY, h:mm a"),
			defaultSortOrder: false,
			sorter: (a, b) => moment(a.orderDate) - moment(b.orderDate),
		},
		{
			title: "Assigned To",
			dataIndex: "assignedTo",
			key: "assignedTo",
		},
		{
			title: "Owner",
			dataIndex: "owner",
			key: "owner",
		},
		{
			title: "Sales Rep",
			dataIndex: "salesRep",
			key: "salesRep",
		},
	];

	return (
		<>
			<Row justify="start">
				<Col>
					<Title level={2}>Open Orders</Title>
				</Col>
			</Row>

			<Row justify="end">
				<Col>
					<OrderFilters
						handleFilter={handleFilter}
						optionsStatus={optionsStatus}
						setPageNo={setPageNo}
						setQueryParams={setQueryParams}
					/>
				</Col>
			</Row>

			<Row justify="end" style={{ marginTop: 16 }}>
				<Col>
					<CsvDownload data={data} />
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						size="small"
						columns={columns}
						dataSource={data}
						loading={loading}
						pagination={{
							current: pageNo,
							pageSize: 10,
							showSizeChanger: false,
						}}
						onChange={e => setPageNo(e.current)}
					/>
				</Col>
			</Row>
		</>
	);
}

export default OpenOrders;

const optionsStatus = [
	{ label: "Ghost", value: "ghost" },
	{ label: "In Progress", value: "in_progress" },
	{ label: "Pending", value: "pending" },
	{ label: "Pending Prescription", value: "pending_prescription" },
	{ label: "Shipping Pending", value: "shipping_pending" },
	{ label: "Shipped", value: "shipped" },
];
