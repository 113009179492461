import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
	addUserToGroup,
	getGroupDetails,
	removeUserFromGroup,
} from "../service";
import { UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	Divider,
	List,
	Modal,
	Row,
	Space,
	Typography,
} from "antd";
const { Title } = Typography;

const ManageGroup = ({
	group_name,
	group_id,
	isModalVisible,
	setIsModalVisible,
}) => {
	const [notAddedUsers, setNotAddedUsers] = useState([]);
	const [addingUser, setAddingUser] = useState(false);
	const [removingUser, setRemovingUser] = useState(false);

	useEffect(() => {
		if (isModalVisible) getGroupDetails(group_id);
	}, [group_id, isModalVisible]);

	const { allUsers, groupDetails, loading } = useSelector(state => ({
		allUsers: window.getValue(state, "usersmanagement.allUsers"),
		groupDetails: window.getValue(state, "supervisorsData.groupDetails"),
		loading: window.getValue(state, "supervisorsData.groupDetailsLoading"),
	}));

	useEffect(() => {
		const init = async () => {
			const findSupervisor = await allUsers.find(user => {
				return user.user_id === groupDetails[0].user.id;
			});

			const userWithSameType = await allUsers.filter(user => {
				return user.role_id === findSupervisor?.role_id;
			});

			const nNotAddedUsers = await userWithSameType.filter(user => {
				if (user.user_id === groupDetails[0].user.id) return false;

				return !groupDetails[0].userGroup.some(
					item => item.user_id === user.user_id
				);
			});

			setNotAddedUsers([...nNotAddedUsers]);
		};

		if (isEmpty(groupDetails)) setNotAddedUsers([]);
		else init();
	}, [allUsers, groupDetails]);

	const handleModalClose = () => {
		setIsModalVisible(false);
	};

	const addUser = async (group_id, user_id) => {
		setAddingUser(true);
		await addUserToGroup(group_id, user_id);
		setAddingUser(false);
	};

	const removeUser = async (group_id, user_id) => {
		setRemovingUser(true);
		await removeUserFromGroup(group_id, user_id);
		setRemovingUser(false);
	};

	return (
		<Modal
			visible={isModalVisible}
			title={
				<Space>
					<Title level={5}>Manage Group </Title>
					<Title level={5} type="warning">
						{group_name}
					</Title>
				</Space>
			}
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}>
			<Row align="middle" justify="center">
				<Col span={20} style={{ textAlign: "center" }}>
					<Space>
						<Title level={5}>Supervisor : </Title>
						<Title level={5}>{groupDetails[0]?.user.first_name}</Title>
					</Space>
				</Col>

				<Divider orientation="center">Group Members</Divider>

				<Col span={20}>
					<List
						dataSource={groupDetails[0]?.userGroup}
						loading={addingUser || loading || removingUser}
						renderItem={item => (
							<List.Item
								actions={[
									<Button
										danger
										icon={<UserDeleteOutlined />}
										onClick={() => removeUser(group_id, item.user_id)}
										type="link"
									/>,
								]}>
								{item.user.first_name}
							</List.Item>
						)}
					/>
				</Col>

				<Divider orientation="center">Add Members</Divider>

				<Col span={20}>
					<List
						dataSource={notAddedUsers}
						loading={addingUser || removingUser}
						renderItem={item => (
							<List.Item
								actions={[
									<Button
										icon={<UserAddOutlined />}
										onClick={() => addUser(group_id, item.user_id)}
										type="link"
									/>,
								]}>
								{item.user.first_name}
							</List.Item>
						)}
					/>
				</Col>
			</Row>
		</Modal>
	);
};

export default ManageGroup;
