import { Button, List, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { generateQuery, getQueryParams, getValue } from "../../../helpers";
import { activityLog } from "../../../helpers/activityLog";
import { leadDetailsActions } from "../actions";
import { ACTIVITY_LOG_PAGESIZE } from "../constents";
import { getCaseActivityLog } from "../service";
const { Text } = Typography;

const ActivityLog = () => {
	const params = useParams();
	const dispatch = useDispatch();
	const [queryParams, setQueryParams] = useState("");
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);

	const { caseActivityLogLoading, caseActivityLog, paginationInfo } =
		useSelector(state => ({
			caseActivityLogLoading: getValue(
				state,
				"leaddetails.caseActivityLogLoading"
			),
			caseActivityLog: getValue(state, "leaddetails.activityLogData"),
			paginationInfo: getValue(state, "leaddetails.activityLogPagination"),
		}));

	useEffect(() => {
		if (!dispatch) return;
		return () => dispatch({ type: leadDetailsActions.RESET_CASE_ACTIVITY_LOG });
	}, [dispatch, params.id]);

	useEffect(() => {
		const filterItems = [
			{ pageNumber: pageNo, pageSize: ACTIVITY_LOG_PAGESIZE },
		];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		getCaseActivityLog(params.id, queryParams);
	}, [params.id, queryParams]);

	const handleFilter = () => {
		setPageNo(prePage => prePage + 1);
	};

	return (
		<List
			size="large"
			className="recent-comment"
			loading={caseActivityLogLoading}
			dataSource={caseActivityLog}
			renderItem={item => (
				<div>
					<List.Item type="flex" justify="space-between">
						<>{activityLog(item)}</>
						<Text type="secondary" className="whitespace-nowrap">
							{moment(item.created_at).format("DD MMM YYYY, h:mm a")}
						</Text>
					</List.Item>
				</div>
			)}
			loadMore={
				<Button
					disabled={paginationInfo?.page === paginationInfo?.pageCount}
					type="link"
					block
					onClick={handleFilter}>
					Load more
				</Button>
			}
		/>
	);
};

export default ActivityLog;
