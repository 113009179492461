import Cookies from "js-cookie";
import { baseURL, handleError } from "../../helpers";
import message from "antd/es/message";

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const getEngagementSlots = async date => {
	let data = "";
	let status = "";

	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}engagement_slots?request_date=${date}`,
			{
				method: "GET",
				...AUTH_HEADER,
			}
		);

		status = _res.status;
		if (_res.status === 200) data = await _res.json();
		else handleError(_res);
	} catch (error) {
		console.warn(error.message);
	} finally {
		return { data, status };
	}
};

export const updateEngagement = async (reminder_date, id) => {
	let res = "";
	try {
		res = await fetch(`${baseURL.API_URL_BASE}engagement/${id}`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ reminder_date }),
			...AUTH_HEADER,
		});
		if (res.status === 200) {
			message.success("Engagement Updated Successfully");
		} else {
			const msg = await res.json();
			message.error(msg.message);
		}
	} catch (error) {
		console.info(error.toString());
	} finally {
		return res;
	}
};
