import React, { useEffect, useState } from "react";
import { saveNotificationPreferences } from "../service";
import { Button, Modal, Row, Checkbox } from "antd";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";


function NotificationPreferences({
  isModalVisible,
  setIsModalVisible,
  userId,
  data,
}) {
	const params = useParams();
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState(false);
  const [push, setPush] = useState(false);
  const [modifiedData, setModifiedData] = useState({});

  useEffect(() => {
    const init = () => {
      if (data) {
        setSms(data.sms_disabled);
        setEmail(data.email_disabled);
        setPush(data.push_disabled);
      }
    };
    if (isModalVisible) init();
  }, [data, isModalVisible]);

  const handleModifiedData = (type, value) => {
    setModifiedData(prev => {
      const nmodifiedData = { ...prev };
      if (type in nmodifiedData) delete nmodifiedData[type];
      else nmodifiedData[type] = value;

      if ("user_id" in nmodifiedData) {
        if (Object.keys(nmodifiedData).length === 1)
          delete nmodifiedData["user_id"];
      } else {
        nmodifiedData["user_id"] = userId;
      }

      return nmodifiedData;
    });
  };

  const handleSubmit = async () => {
		const caseId = params.id;
    await saveNotificationPreferences(caseId, modifiedData);
    setIsModalVisible(false);
    setModifiedData({});
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setModifiedData({});
    setSms(false);
    setEmail(false);
    setPush(false);
  };

  const handleEmail = value => {
    setEmail(value);
    handleModifiedData("email_disabled", value);
  };

  const handleSms = value => {
    setSms(value);
    handleModifiedData("sms_disabled", value);
  };

  const handlePush = value => {
    setPush(value);
    handleModifiedData("push_disabled", value);
  };

  return (
    <Modal
      title="Notification Preferences"
      onCancel={handleModalClose}
      destroyOnClose={true}
      visible={isModalVisible}
      footer={[
        <Button key="cancel" onClick={handleModalClose}>
          Cancel
        </Button>,
        <Button
          onClick={handleSubmit}
          key="save"
          type="primary"
          disabled={isEmpty(modifiedData)}
        >
          Save
        </Button>,
      ]}
    >
      <Row justify="center">
        <Checkbox
          key="sms"
          checked={sms}
          onChange={e => handleSms(e.target.checked)}
        >
          SMS Disabled
        </Checkbox>

        <Checkbox
          key="email"
          checked={email}
          onChange={e => handleEmail(e.target.checked)}
        >
          Email Disabled
        </Checkbox>

        <Checkbox
          key="push"
          checked={push}
          onChange={e => handlePush(e.target.checked)}
        >
          Push Disabled
        </Checkbox>
      </Row>
    </Modal>
  );
}

export default NotificationPreferences;
