import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getDuplicateChatList } from "../service";
import { Button, Modal, Row, Table, Typography } from "antd";
const { Text } = Typography;

const DuplicateChatList = ({ isModalVisible, setIsModalVisible }) => {
  const history = useHistory();

  const { chatNo, duplicateChatList, duplicateChatListLoading } = useSelector(
    state => ({
      chatNo: window.getValue(
        state,
        "leaddetails.usersDetail.user.chat_phone_number"
      ),
      duplicateChatList: window.getValue(
        state,
        "leaddetails.duplicateChatList"
      ),
      duplicateChatListLoading: window.getValue(
        state,
        "leaddetails.duplicateChatListLoading"
      ),
    })
  );

  useEffect(() => {
    if (isModalVisible && !isEmpty(chatNo))
      getDuplicateChatList(encodeURIComponent(chatNo));
  }, [isModalVisible, chatNo]);

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      key: "name",
      render: (first_name, record) => (
        <Text
          className="link"
          onClick={() => {
            setIsModalVisible(false);
            history.push(`/lead-details/${record.case_id}`);
          }}
        >
          {first_name}
        </Text>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  return (
    <Modal
      visible={isModalVisible}
      title="Duplicate chat lists"
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Cancel
        </Button>,
      ]}
    >
      <Row justify="center">
        <Table
          columns={columns}
          dataSource={duplicateChatList}
          loading={duplicateChatListLoading}
          pagination={false}
        />
      </Row>
    </Modal>
  );
};

export default DuplicateChatList;
