import Cookies from 'js-cookie';
import { baseURL, getCurrentUserRole, handleError } from '../../helpers';
import { store } from '../../redux/store';
import actions from './action';

const AUTH_HEADER = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${Cookies.get('token')}`,
	},
};

export const getTickets = async () => {
	store.dispatch({
		type: actions.GET_TICKETS_LOADING,
		payload: true,
	});

	let url = '';
	if (getCurrentUserRole() === 'admin') {
		url = `${baseURL.API_URL_BASE}get-all-tickets`;
	} else {
		const repId = localStorage.getItem('users');
		url = `${baseURL.API_URL_BASE}get-tickets-acl/${repId}`;
	}

	try {
		const _res = await fetch(url, {
			method: 'GET',
			...AUTH_HEADER,
		});

		if (_res.status === 200) {
			const _data = await _res.json();
			store.dispatch({
				type: actions.GET_TICKETS_SUCCESS,
				payload: _data,
			});
		} else {
			store.dispatch({
				type: actions.GET_TICKETS_FAILURE,
				payload: null,
			});
			handleError(_res);
		}
	} catch {
		store.dispatch({
			type: actions.GET_TICKETS_FAILURE,
			payload: null,
		});
	}
};

export const getAssignedPersons = async (orderId, category, subCategory) => {
	let data = null;
	let hasError = false;
	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}get-assignees/${orderId}?category=${category}&sub_category=${subCategory}`,
			{
				method: 'GET',
				...AUTH_HEADER
			}
		);

		if (_res.status !== 200) hasError = true;
		data = await _res.json();
	} catch (error) {
		console.warn(error);
	}

	return { data, hasError };
};
