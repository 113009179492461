import React from "react";
import moment from "moment";
// import isEmpty from "lodash/isEmpty";
import { Tag, Typography } from "antd";
import { Link } from "react-router-dom";
const { Text } = Typography;

export const InternalActivityLog = ({ data }) => {
	switch (data.event_id) {
		case "INTERNAL_USER_ACTIVE_TOGGLE":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> made{" "}
					<Text strong>{data.meta.for.first_name}</Text>{" "}
					<Text className="link" strong>
						{data.meta.for.is_active ? "Active" : "Inactive"}
					</Text>{" "}
					<Text type="secondary">
						at {moment(data.createdAt).format("DD MMM, h:mm a")}
					</Text>
				</Text>
			);

		case "DEACTIVATE_USER":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> deactivated{" "}
					<Text className="link" strong>
						{data.meta.for.first_name}
					</Text>{" "}
					({data.meta.for.role})
				</Text>
			);

		case "INTERNAL_USER_LEVEL_CHANGE":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> changed{" "}
					<Text strong>{data.meta.for.first_name}</Text> to level{" "}
					<Text className="link" strong>
						{data.meta.for.level}
					</Text>
				</Text>
			);

		case "INTERNAL_USER_BREAK_CHANGE":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> changed break time
					for{" "}
					<Text className="link" strong>
						{data.meta.for.first_name}
					</Text>
				</Text>
			);

		case "INTERNAL_USER_DAY_AVAILABILITY_CHANGE":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> changed available
					days for{" "}
					<Text className="link" strong>
						{data.meta.for.first_name}
					</Text>
				</Text>
			);

		case "DISCOUNT_CODE_ADDED":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> added new discount
					code
				</Text>
			);

		case "DISCOUNT_CODE_REMOVED":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> removed a discount
					code
				</Text>
			);

		case "INTERNAL_USER_MEDICINE_ADDED":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> added new medicine{" "}
					<Text className="link" strong>
						{data.meta.medicine.display_name}
					</Text>
				</Text>
			);

		case "INTERNAL_USER_MEDICINE_REMOVED":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> removed medicine{" "}
					<Text className="link" strong>
						{data.meta.medicine.display_name}
					</Text>
				</Text>
			);

		case "INTERNAL_USER_PRODUCT_SKU_MAPPING_ADDED":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> added{" "}
					<Text className="link" strong>
						{data.meta.product.product_principal_id}
					</Text>
				</Text>
			);

		case "INTERNAL_USER_PRODUCT_SKU_MAPPING_REMOVED":
			return (
				<Text>
					<Text strong>{data.meta.author.first_name}</Text> removed{" "}
					<Text className="link" strong>
						{data.meta.product.product_principal_id}
					</Text>
				</Text>
			);
		
		case "SETTINGS_CHANGE":
				return (
					<Text>
						<Text strong>{data.meta.author.first_name}</Text> changed settings for{" "}
						<Text className="link" strong>
							{data.meta.settings.key}
						</Text>
					</Text>
				);
		default:
			return data.event_id;
	}
};
