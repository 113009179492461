import { SearchOutlined } from "@ant-design/icons";
import {
	AutoComplete,
	Button,
	Card,
	Col,
	message,
	Row,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { getGlobalSearchOrderResult } from "../service";

const { Option } = AutoComplete;
const { Meta } = Card;
const { Text, Title } = Typography;

function TransferOrderSearch({
	selectedOrderSearch,
	setSelectedOrderSearch,
	orderSearchValue,
	setOrderSearchValue,
	orderSearchData,
	setOrderSearchData,
}) {
	const [isResponse, setIsResponse] = useState(false);
	const [isCloseDropdown, setIsCloseDropdown] = useState(false);
	const [selectedKeys, setSelectedKeys] = useState([]);

	const columns = [
		{
			title: "Order Display Id",
			dataIndex: "order_display_id",
			key: "order_display_id",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: status => (
				<Tag key={status} color="geekblue">
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Order Date",
			dataIndex: "created_at",
			key: "created_at",
			render: created_at => {
				return moment(created_at).format("DD MMM YYYY, h:mm a");
			},
		},
	];

	const handleClear = () => {
		setOrderSearchValue("");
		setOrderSearchData([]);
		setSelectedOrderSearch({});
	};

	useEffect(() => {
		if (isEmpty(selectedOrderSearch)) setSelectedKeys([]);
	}, [selectedOrderSearch]);

	const handleClick = searchResult => {
		setSelectedOrderSearch({ key: searchResult.case_id, ...searchResult });
	};

	const tableData = useMemo(() => {
		if (isEmpty(selectedOrderSearch) || isEmpty(selectedOrderSearch.orders)) {
			return [];
		}

		return selectedOrderSearch.orders.map(order => {
			order["key"] = order.id;
			return order;
		});
	}, [selectedOrderSearch]);

	const handleSearch = event => {
		if (event.includes("#") || isEmpty(event)) {
			setOrderSearchValue(event);
		} else {
			setOrderSearchValue("");
			message.error("only order display Id allowed");
		}
		if (isEmpty(event)) setIsCloseDropdown(false);
	};

	const handleSubmit = async () => {
		setIsResponse(true);
		const response = await getGlobalSearchOrderResult(orderSearchValue);
		if (!isEmpty(response) && response !== "Word length is less than 4") {
			setOrderSearchData(response);
			setIsResponse(false);
			setIsCloseDropdown(true);
		} else {
			message.error("No data availaible");
			setOrderSearchData([]);
			setIsResponse(false);
		}
	};

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: selectedRowKeys => {
			setSelectedOrderSearch(curr => {
				curr["orderList"] = selectedRowKeys;
				return { ...curr };
			});
			setSelectedKeys(selectedRowKeys);
		},
	};

	return (
		<>
			<Row>
				<Col span={19}>
					<AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={handleSearch}
						placeholder="Search with order display Id"
						notFoundContent={null}
						open={isCloseDropdown}
						value={orderSearchValue}
						onSelect={() => setIsCloseDropdown(false)}
						showSearch
						style={{ width: "100%" }}
						suffixIcon={<SearchOutlined />}>
						{!isEmpty(orderSearchData) &&
							orderSearchData.map((searchResult, index) => (
								<Option key={index} value={orderSearchValue}>
									<Card onClick={() => handleClick(searchResult)}>
										<Meta
											title={`${searchResult.first_name}`}
											description={
												<Space direction="vertical">
													{!isEmpty(searchResult.order_display_id) && (
														<Text>{searchResult.order_display_id}</Text>
													)}
													<Text>
														{searchResult.phone_number} {searchResult.email}
													</Text>
												</Space>
											}
										/>
									</Card>
								</Option>
							))}
					</AutoComplete>
				</Col>
				<Col span={4}>
					<Space>
						<Button
							style={{ marginLeft: "9px" }}
							icon={<SearchOutlined />}
							type="primary"
							onClick={handleSubmit}
							disabled={isEmpty(orderSearchValue)}
							loading={isResponse}
						/>
						<Button onClick={handleClear} disabled={isEmpty(orderSearchValue)}>
							Clear
						</Button>
					</Space>
				</Col>
			</Row>
			<Row>
				<Col
					style={{
						width: "100%",
						height: "20rem",
						padding: "1rem",
						border: "2px solid #f0f0f0",
						marginTop: "1rem",
						overflow: "auto",
					}}>
					<Row>
						<Col span={14}>
							<Space>
								<Title level={5}>Name: </Title>
								<Title level={5}>
									<span
										className="link"
										level={5}
										style={{ width: "230px" }}
										onClick={() =>
											window.open(
												`/lead-details/${selectedOrderSearch?.case_id}`,
												"_blank"
											)
										}>
										{selectedOrderSearch?.first_name}
									</span>
								</Title>
							</Space>
						</Col>
						<Col span={10}>
							<Space>
								<Title level={5}>Phone:</Title>
								<Title level={5}>{selectedOrderSearch?.phone_number}</Title>
							</Space>
						</Col>
					</Row>
					<Row>
						<Col span={14}>
							<Space>
								<Title level={5}>Email: </Title>
								<Title level={5} style={{ width: "230px" }}>
									{selectedOrderSearch?.email}
								</Title>
							</Space>
						</Col>
						<Col span={10}>
							<Space>
								<Title level={5}>Gender: </Title>
								<Title level={5}>{selectedOrderSearch?.gender}</Title>
							</Space>
						</Col>
					</Row>
					<Row>
						<Col span={14}>
							<Space>
								<Title level={5}>Assigned To: </Title>
								<Title level={5}>{selectedOrderSearch?.assigned_to}</Title>
							</Space>
						</Col>
						<Col span={10}>
							<Space>
								<Title level={5}>Stage: </Title>
								<Title level={5}>{selectedOrderSearch?.stage}</Title>
							</Space>
						</Col>
					</Row>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						rowSelection={rowSelection}
					/>
				</Col>
			</Row>
		</>
	);
}
export default TransferOrderSearch;
