import groupBy from "lodash/groupBy";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";

import { weekAvailable } from "../../../../constants/options";
import { generateQuery, getQueryParams } from "../../../../helpers";
import { fetchOverdueData } from "../api";
import {
	mergeEngagementsFn,
	transformEngagements,
	transformReminders,
} from "../helpers";
import { ACTIONS, INITIAL_VALUE, reducer } from "../store";
import Engagements from "./Engagements";

import { Tabs } from "antd";
const { TabPane } = Tabs;

const OverdueEngagements = ({ medRepId }) => {
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);
	const [tag, setTag] = useState(getQueryParams("tag") || "Prescription Call");
	const [pageNo, setPageNo] = useState(Number(getQueryParams("page")) || 1);

	const { overdueEngagements, overdueReminders, loading } = state;

	useEffect(() => {
		fetchOverdueData(medRepId, dispatch);
		return () => dispatch({ type: ACTIONS.RESET });
	}, [medRepId]);

	const refreshUI = () => {
		fetchOverdueData(medRepId, dispatch);
	};

	const _overdueReminders = useMemo(() => {
		return transformReminders(overdueReminders);
	}, [overdueReminders]);

	const _overdueEngagements = useMemo(() => {
		return transformEngagements(overdueEngagements);
	}, [overdueEngagements]);

	const mergedEngagements = useMemo(() => {
		return mergeEngagementsFn(
			{
				overdueReminders: _overdueReminders,
				overdueEngagements: _overdueEngagements,
			},
			"DESC"
		);
	}, [_overdueEngagements, _overdueReminders]);

	const tableData = useMemo(() => {
		return groupBy(mergedEngagements, d => d.tag);
	}, [mergedEngagements]);

	const handleTabChange = key => {
		setTag(key);
		setPageNo(1);
	};

	useEffect(() => {
		const filterItems = [{ tag: tag, page: pageNo }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });
	}, [history, pageNo, tag]);

	const createTable = () => {
		return (
			<Engagements
				data={tableData[tag]}
				loading={loading}
				refreshUI={refreshUI}
				title=""
				pageNo={pageNo}
				setPageNo={setPageNo}
			/>
		);
	};

	return (
		<>
			<h3 className="ant-typography">Overdue Engagements</h3>
			<Tabs defaultActiveKey={tag} onChange={handleTabChange}>
				<TabPane tab="Prescription Call" key="Prescription Call">
					{createTable()}
				</TabPane>
				{weekAvailable.map(rep => (
					<TabPane tab={rep.label} key={rep.value}>
						{createTable()}
					</TabPane>
				))}
				<TabPane tab="Slot Booking" key="SLOT_BOOKING">
					{createTable()}
				</TabPane>
			</Tabs>
		</>
	);
};

export default OverdueEngagements;
