import React, { useState } from "react";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { getComments, postComment } from "../service";
import { getCurrentUserRole } from "../../../helpers";
import { Button, Form, Input, message } from "antd";
const { TextArea } = Input;

function AddCommentAndReminder() {
  const params = useParams();
  const [comment, setComment] = useState("");

  const onFinish = async () => {
    if (!isEmpty(comment.trim())) {
      const res = await postComment(params.id, comment);
      if (res.status === 200) {
        getComments(params.id);
        setComment("");
        message.success("Comment Posted Successfully");
      } else {
        message.error("Failed to post comment");
      }
    }
  };

  return (
    <>
      <Form className="comment-section" layout="inline" onFinish={onFinish}>
        <Form.Item>
          <TextArea
            onChange={e => setComment(e.target.value)}
            rows={1}
            value={comment}
          />
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Comment
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default AddCommentAndReminder;
