export const optionsFormStatus = [
	{ label: "Draft", value: "draft" },
	{ label: "Filled", value: "filled" },
	{ label: "Semi Filled", value: "semi-filled" },
	{ label: "Guest", value: "guest" },
	{ label: "Mini Lead", value: "mini-lead" },
	{ label: "Appt Lead", value: "appt-lead" },
	{ label: "Transplant Lead", value: "transplant-lead" },
];

export const optionsCaseStatus = [
	{ label: "Dropped", value: "dropped" },
	{ label: "New", value: "new" },
	{ label: "Hot", value: "hot" },
	{ label: "Cold", value: "cold" },
	{ label: "Invalid", value: "invalid" },
	{ label: "Not Connected", value: "not_connected" },
];

export const codOrderTypeOptions = [
	{ label: "Cod Approved", value: "cod_approved" },
	{ label: "Cod Pending", value: "cod_pending" },
	{ label: "Cod Rejected", value: "cod_rejected" },
	{ label: "General", value: "general" },
	{ label: "Pre Paid", value: "pre_paid" },
	{ label: "Other", value: "other" },
];

export const postSalesEngagementOptions = [
	{ label: "Prescription Call", value: "Prescription Call" },
	{ label: "Week #1", value: "Week #1" },
	{ label: "Week #2", value: "Week #2" },
	{ label: "Week #3", value: "Week #3" },
	{ label: "Week #4", value: "Week #4" },
	{ label: "Week #5", value: "Week #5" },
	{ label: "Week #6", value: "Week #6" },
	{ label: "SPE", value: "SPE" },
	{ label: "SLOT BOOKING", value: "SLOT_BOOKING" },
];

export const weekOptions = [
	{ label: "Sunday", value: 0 },
	{ label: "Monday", value: 1 },
	{ label: "Tuesday", value: 2 },
	{ label: "Wednesday", value: 3 },
	{ label: "Thursday", value: 4 },
	{ label: "Friday", value: 5 },
	{ label: "Saturday", value: 6 },
];

 
export const regularInregularOptions = [
	{ label: "Regular", value: "regular" },
	{ label: "Irregular", value: "irregular" },
];

export const startedNotStartedOptions = [
	{ label: "Started", value: "I have started" },
	{ label: "Not started", value: "I have not started" }
]

export const weekAvailable = [
	// { label: "Week 1", value: "Week #1" },
	// { label: "Week 2", value: "Week #2" },
	{ label: "Week 3", value: "Week #3" },
	{ label: "Week 4", value: "Week #4" },
	{ label: "Week 5", value: "Week #5" },
	{ label: "Week 6", value: "Week #6" },
];
