import React, { useEffect, useState } from "react";
import moment from "moment";

import { updateUser, getAllUsers } from "../service";
import DeactivateSalesRep from "./DeactivateSalesRep";
import { weekOptions } from "../../../constants/options";

// ant design
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Checkbox,
  message,
  TimePicker,
  Row,
  Col,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const EditUser = ({ modalVisible, setModalVisible, user }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  console.log("user in edit", user);
  const layout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailLayout = {
    wrapperCol: { offset: 10 },
  };

  const handleSubmit = async (data) => {
    const {
      altEmail,
      breakWeekDays,
      description,
      level,
      limechat_user_id,
      name,
      phoneNumber,
      shiftTime,
      tip,
    } = data;
    const phone_number = "+91" + phoneNumber;

    const _user = {
      first_name: name.trim(),
      alternate_email: altEmail.trim().toLowerCase(),
      phone_number: phone_number.trim(),
      level,
      meta: {
        ...user.meta,
        bio: {
          description: description.trim(),
          tip: tip.trim(),
        },
        breakWeekDays,
        shifts: shiftTime
          ? shiftTime.map((item) => {
              return {
                startTime: moment.utc(item.shift[0]._d).format("HH:mm"),
                endTime: moment.utc(item.shift[1]._d).format("HH:mm"),
              };
            })
          : [],
        limechat_user_id: limechat_user_id ? limechat_user_id.trim() : "",
      },
    };

    try {
      setLoading(true);
      const res = await updateUser(user.key, _user);
      if (res.status === 200) {
        message.success(" User Updated Successfully");
      } else if (res.status === 422) {
        message.error("phone number already exists");
      } else {
        message.error("Failed to update user");
      }
      handleModalClose();
      getAllUsers();
    } catch (err) {
      console.error(err);
      message.error("Failed to Edit User");
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    if (!modalVisible) return;
    const shifts = user.meta.shifts
      ? user.meta.shifts.map((item) => {
          return {
            shift: [
              transformTimeToMoment(item.startTime),
              transformTimeToMoment(item.endTime),
            ],
          };
        })
      : [];

    form.setFieldsValue({
      name: user?.first_name,
      phoneNumber: user?.phone_number,
      altEmail: user?.alternate_email,
      description: user?.description,
      tip: user?.tip,
      breakWeekDays: user?.breakWeekDays,
      level: user?.level,
      shiftTime: shifts,
      limechat_user_id: user.meta.limechat_user_id,
    });
  }, [form, modalVisible, user]);

  return (
    <>
      <Modal
        title="Edit User"
        centered
        visible={modalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        footer={[
          <Button key="back" onClick={handleModalClose}>
            Cancel
          </Button>,
        ]}
      >
        {allowedRoles.has(user.role) && (
          <DeactivateSalesRep userId={user.key} refreshUI={handleModalClose} />
        )}

        <Form {...layout} form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please enter your name" },
              {
                pattern: new RegExp(/^[a-zA-Z0-9\s]+$/),
                message: "Please enter valid name",
              },
              { whitespace: true, message: "Please enter valid name" },
              { max: 30, message: "Name can't be longer than 30 characters" },
            ]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phoneNumber"
            rules={[
              { required: true, message: "Please enter phone number" },
              {
                pattern: new RegExp(/^\d+$/),
                message: "Please enter valid phone number",
              },
              { len: 10, message: "Only 10 digits are allowed" },
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Alternate Email"
            name="altEmail"
            rules={[
              { required: true, message: "Please enter your alternate email" },
              {
                type: "email",
                message: "Please enter valid alternate email address",
              },
            ]}
          >
            <Input placeholder="john@gmail.com" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[
              { whitespace: true, message: "Please enter valid description" },
              {
                max: 140,
                message: "Description can't be longer than 140 characters",
              },
            ]}
          >
            <Input.TextArea placeholder="A short description about yourself" />
          </Form.Item>

          <Form.Item
            label="Tip"
            name="tip"
            rules={[
              { whitespace: true, message: "Please enter valid tip" },
              {
                max: 140,
                message: "Tip can't be longer than 140 characters",
              },
            ]}
          >
            <Input.TextArea placeholder="A tip to the user" />
          </Form.Item>

          <Form.Item label="Break Days" name="breakWeekDays">
            <Checkbox.Group options={weekOptions} />
          </Form.Item>

          <Form.Item label="Limechat Id" name="limechat_user_id">
            <Input placeholder="Limechat id" />
          </Form.Item>

          <Form.Item
            label="Level"
            name="level"
            rules={[{ required: true, message: "Select a Level" }]}
          >
            <Select
              allowClear
              placeholder="Select Level"
              options={levelOptions}
            />
          </Form.Item>

          <Form.List label="shift Time" name="shiftTime">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row style={{ paddingLeft: 40 }}>
                    <Col>
                      <Form.Item
                        {...field}
                        label={`Break ${field.name + 1}`}
                        name={[field.name, "shift"]}
                        rules={[
                          {
                            required: true,
                            message: "Please fill this",
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          size="middle"
                          use12Hours
                          format={"hh:mm A"}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <MinusCircleOutlined
                        style={{ paddingTop: 10, color: "red" }}
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Row style={{ paddingLeft: 172 }}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        console.log("fields", fields);
                        add();
                      }}
                      icon={<PlusOutlined />}
                    >
                      Add Break Timings
                    </Button>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form.List>

          <br />
          <Form.Item {...tailLayout}>
            <Button htmlType="submit" loading={loading} type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditUser;

const transformTimeToMoment = (time) => {
  const _time = time;
  const day = moment();
  const splitTime = _time.split(/:/);
  day
    .hours(parseInt(splitTime[0]))
    .minutes(parseInt(splitTime[1]))
    .seconds(0)
    .milliseconds(0)
    .add({ hours: 5, minutes: 30 });
  return day;
};

const levelOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

const allowedRoles = new Set(["sales_rep", "medical_rep"]);
