export const leadDetailsActions = {
	GET_USERS_DETAILS_DATA_LOADING: "GET_USERS_DETAILS_DATA_LOADING",
	GET_USERS_DETAILS_DATA_SUCCESS: "GET_USERS_DETAILS_DATA_SUCCESS",
	GET_USERS_DETAILS_DATA_FAILURE: "GET_USERS_DETAILS_DATA_FAILURE",

	FETCH_LEAD_DETAILS_DATA_LOADING: "FETCH_LEAD_DETAILS_DATA_LOADING",
	FETCH_LEAD_DETAILS_DATA_SUCCESS: "FETCH_LEAD_DETAILS_DATA_SUCCESS",
	FETCH_LEAD_DETAILS_DATA_FAILURE: "FETCH_LEAD_DETAILS_DATA_FAILURE",

	FETCH_COMMENTS_DATA_LOADING: "FETCH_COMMENTS_DATA_LOADING",
	FETCH_COMMENTS_DATA_SUCCESS: "FETCH_COMMENTS_DATA_SUCCESS",
	FETCH_COMMENTS_DATA_FAILURE: "FETCH_COMMENTS_DATA_FAILURE",

	GET_REMINDERS_LOADING: "GET_REMINDERS_LOADING",
	GET_REMINDERS_SUCCESS: "GET_REMINDERS_SUCCESS",
	GET_REMINDERS_FAILURE: "GET_REMINDERS_FAILURE",

	GET_ACTIVITY_LOG_LOADING: "GET_ACTIVITY_LOG_LOADING",
	GET_ACTIVITY_LOG_SUCCESS: "GET_ACTIVITY_LOG_SUCCESS",
	GET_ACTIVITY_LOG_FAILURE: "GET_ACTIVITY_LOG_FAILURE",
	RESET_CASE_ACTIVITY_LOG: "RESET_CASE_ACTIVITY_LOG",

	GET_PROFILE_LOADING: "GET_PROFILE_LOADING",
	GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
	GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",

	GET_PROFILE_TAG_LOADING: "GET_PROFILE_TAG_LOADING",
	GET_PROFILE_TAG_SUCCESS: "GET_PROFILE_TAG_SUCCESS",
	GET_PROFILE_TAG_FAILURE: "GET_PROFILE_TAG_FAILURE",

	GET_CASE_TAG_HISTORY_LOADING: "GET_CASE_TAG_HISTORY_LOADING",
	GET_CASE_TAG_HISTORY_SUCCESS: "GET_CASE_TAG_HISTORY_SUCCESS",
	GET_CASE_TAG_HISTORY_FAILURE: "GET_CASE_TAG_HISTORY_FAILURE",

	GET_CONSULT_CALL_FEEDBACK_LOADING: "GET_CONSULT_CALL_FEEDBACK_LOADING",
	GET_CONSULT_CALL_FEEDBACK_SUCCESS: "GET_CONSULT_CALL_FEEDBACK_SUCCESS",
	GET_CONSULT_CALL_FEEDBACK_FAILURE: "GET_CONSULT_CALL_FEEDBACK_FAILURE",
};
