import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useHistory } from "react-router-dom";

import {
	mergeEngagementsFn,
	transformEngagements,
	transformReminders,
} from "../helpers";

import { generateQuery, getQueryParams } from "../../../../helpers";
import { getAttemptText } from "../../../../helpers/attemptText";
import { fetchTodaysEngagements } from "../api";
import { ACTIONS, INITIAL_VALUE, reducer } from "../store";
import Engagements from "./Engagements";

const TodayEngagements = ({ medRepId }) => {
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);
	const [pageNo, setPageNo] = useState(Number(getQueryParams("tepage")) || 1);
	const [tableData, setTableData] = useState([]);

	const { engagements, reminders, loading } = state;

	useEffect(() => {
		fetchTodaysEngagements(medRepId, dispatch);
		return () => dispatch({ type: ACTIONS.RESET });
	}, [medRepId]);

	const refreshUI = () => {
		fetchTodaysEngagements(medRepId, dispatch);
	};

	const _reminders = useMemo(() => {
		const filteredReminders = reminders.filter(
			item => !item.temporary_assignment_id
		);
		return transformReminders(filteredReminders);
	}, [reminders]);

	const _engagements = useMemo(() => {
		const filteredReminders = engagements.filter(
			item => !item.temporary_user_id
		);
		return transformEngagements(filteredReminders);
	}, [engagements]);

	const mergedEngagements = useMemo(() => {
		return mergeEngagementsFn({
			reminders: _reminders,
			engagements: _engagements,
		});
	}, [_engagements, _reminders]);

	useEffect(() => {
		setTableData(mergedEngagements);
		const filterTag = localStorage.getItem("filterTag")?.split(",")[0];
		handleTagFilter({
			tag: filterTag && filterTag !== "null" ? [filterTag] : ["Priority 1"],
		});
	}, [mergedEngagements]);

	useEffect(() => {
		const filterItems = [{ tepage: pageNo }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });
	}, [history, pageNo]);

	const handleTagFilter = tagFilters => {
		localStorage.setItem("filterTag", tagFilters.tag);
		console.log();
		const filters = tagFilters.tag;
		if (!filters) {
			setTableData(mergedEngagements);
			return;
		}

		const data = mergedEngagements.filter(el => {
			const _temp = [];

			if (Array.isArray(filters)) {
				if (filters.some(val => val.includes("Attempt"))) {
					const attempt = getAttemptText(el.unanswered_count);
					_temp.push(filters.includes(attempt));
				}
				if (filters.some(val => !val.includes("Attempt"))) {
					if (filters.includes(el.priority)) {
						_temp.push(filters.includes(el.priority));
					} else {
						_temp.push(filters.includes(el.tag));
					}
				}
			} else if (filters.includes("Attempt")) {
				const attempt = getAttemptText(el.unanswered_count);
				_temp.push(filters.includes(attempt));
			} else {
				_temp.push(filters.includes(el.tag));
			}
			return _temp.every(el => el);
		});
		setTableData(data);
	};

	return (
		<Engagements
			data={tableData}
			loading={loading}
			refreshUI={refreshUI}
			pageNo={pageNo}
			setPageNo={setPageNo}
			tagFilter={true}
			handleTagFilter={handleTagFilter}
		/>
	);
};

export default TodayEngagements;
