import {
	CaretLeftOutlined,
	CaretRightOutlined,
	CheckCircleOutlined,
	DashboardOutlined,
	ExceptionOutlined,
	FilePdfOutlined,
	FlagOutlined,
	FullscreenExitOutlined,
	HomeOutlined,
	ScheduleOutlined,
	SearchOutlined,
	SettingOutlined,
	ShoppingCartOutlined,
	ShoppingOutlined,
	TagsOutlined,
	TeamOutlined,
	UsergroupAddOutlined,
	UserOutlined,
	VideoCameraOutlined,
} from "@ant-design/icons";
import { Col, Layout, Menu, message, Select, Space, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import trayaLogo from "../../assets/Traya_Logo.jpg";
import BellNotification from "../../components/notifications/BellNotification";
import {
	generateQuery,
	getCurrentUserRole,
	getValue,
	handleError,
	USER_PING_CALL,
} from "../../helpers";
import { logout } from "../../pages/login/service";
import { getMissedCalls } from "../../pages/missed_calls/service";
import CreateTicket from "../../pages/ticketing/components/CreateTicket";
import Routes from "../../Routes";
import { subscribeUser } from "../../subscription";
import GlobalSearch from "../globalSearch/components/GlobalSearch";
import { markStatus, setUserPing } from "../globalSearch/service";
import Profile from "../Profile";
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

function Sidebar() {
	const history = useHistory();
	const location = useLocation();
	const intervalRef = useRef(null);
	const [collapsed, setCollapsed] = useState(false);
	const [userRole] = useState(getCurrentUserRole());
	const [userLevel] = useState(localStorage.getItem("rep!level"));
	const [openKeys, setOpenKeys] = useState("");
	const [openSubmenu, setOpenSubmenu] = useState("");
	const [openSubmenuCopy, setOpenSubmenuCopy] = useState("");
	const [profileStatus, setProfileStatus] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [status, setStatus] = useState(
		localStorage.getItem("LOGIN!STATUS!") || "Online"
	);

	// miss call count on sidebar
	const { missedCalls } = useSelector(state => ({
		missedCalls: getValue(state, "missedCalls.data"),
	}));

	const toggle = () => setCollapsed(!collapsed);
	console.log(`${userRole} Level ${userLevel}`);

	const startInterval = useCallback(() => {
		if (shouldCallPing.has(userRole)) {
			intervalRef.current = setInterval(() => {
				setUserPing();
			}, USER_PING_CALL);
		}
	}, [userRole]);

	const stopInterval = useCallback(() => {
		clearInterval(intervalRef.current);
		intervalRef.current = null;
	}, []);

	useEffect(() => {
		startInterval();
	}, [startInterval]);

	useEffect(() => {
		subscribeUser();
	}, []);

	useEffect(() => {
		const keys = getCurrentMenu(location.pathname);
		setOpenKeys(keys.key);
		if (openSubmenu !== null) setOpenSubmenu(keys.subMenu);
		setOpenSubmenuCopy(keys.subMenu);
	}, [location.pathname]);

	useEffect(() => {
		if (collapsed) setOpenSubmenu(null);
		else setOpenSubmenu(openSubmenuCopy);
	}, [openSubmenuCopy, collapsed]);

	// api call to get miss call count on sidebar
	useEffect(() => {
		const filterItems = [
			{
				rep_id: localStorage.getItem("users"),
				startDate: moment().startOf("d").toISOString(),
				endDate: moment().endOf("d").toISOString(),
			},
		];

		let _queryParams = generateQuery(filterItems);
		getMissedCalls(_queryParams);
	}, []);

	const changeStatus = async activeStatus => {
		const response = await markStatus(activeStatus);

		if (response.status === 200) {
			localStorage.setItem("LOGIN!STATUS!", activeStatus);
			message.success(`status changed to ${activeStatus}`);
			setStatus(activeStatus);
		} else if (response.status === 400) {
			message.error(`status is already ${activeStatus}`);
		} else {
			handleError(response);
			message.error(`Internal server error`);
		}
	};

	const handleProfileStatus = async e => {
		setProfileStatus(e);
		if (e === "edit") {
			setIsModalVisible(true);
		} else {
			await logout();
			stopInterval();
		}
	};

	const handleOpenChange = key => {
		if (isEmpty(key)) setOpenSubmenu(null);
		else setOpenSubmenu(key[1]);
	};

	return (
		<Layout className="site-layout">
			<Header className="site-layout-background site-header">
				<Col>
					<div className="site-logo">
						<img
							src={trayaLogo}
							width="100"
							alt="Logo"
							style={{ justifyContent: "center" }}
						/>
						<span style={{ marginLeft: "18px" }}>
							Welcome {localStorage.getItem("userName")} ({userRole})
						</span>
						<span style={{ marginLeft: "18px" }}>
							<Select
								placeholder="Active Status"
								options={statusOption}
								style={{ width: 100 }}
								value={status}
								onChange={e => {
									changeStatus(e);
								}}
							/>
						</span>
					</div>
				</Col>

				{Notification.permission !== "granted" && (
					<Text type="danger" strong>
						Web notifications are not enabled, please enable the same
					</Text>
				)}

				<div className="mt-1 mr-2">
					<CreateTicket />
				</div>
				<Col>
					<Space size="middle">
						{userRole !== "dispatch" && <GlobalSearch />}

						<BellNotification />

						<Select
							placeholder="Profile Status"
							options={profileOption}
							style={{ width: 160 }}
							value={profileStatus}
							onSelect={handleProfileStatus}
						/>
					</Space>
				</Col>
			</Header>
			<Layout>
				<Sider trigger={null} collapsible collapsed={collapsed}>
					<div className="sidebar-container">
						<Menu
							mode="inline"
							defaultSelectedKeys={[openKeys]}
							selectedKeys={[openKeys]}
							openKeys={[openSubmenu]}
							onOpenChange={handleOpenChange}>
							{hideHomeRoute.has(userRole) && (
								<Menu.Item key="all-cases" icon={<DashboardOutlined />}>
									<Link to="/case-management/all-cases">Dashboard</Link>
								</Menu.Item>
							)}

							{showHomeRoute.has(userRole) && (
								<Menu.Item key="home" icon={<DashboardOutlined />}>
									<Link to="/home">Dashboard</Link>
								</Menu.Item>
							)}
							{showMyTickets.has(userRole) && (
								<Menu.Item key="my-tickets" icon={<TagsOutlined />}>
									<Link to="/my-tickets">My Tickets</Link>
								</Menu.Item>
							)}
							{userRole === "doctor" && (
								<Menu.Item key="sales-management">
									<Link to="/prescribed-patients">Prescribed Patients</Link>
								</Menu.Item>
							)}
							{userRole === "admin" && (
								<SubMenu
									key="case-management"
									icon={<VideoCameraOutlined />}
									title="Case Management"
									onTitleClick={() =>
										history.push(`/case-management/all-cases`)
									}>
									<Menu.Item key="sales-management">
										<Link to="/case-management/sales-management">
											Sales Management
										</Link>
									</Menu.Item>
									<Menu.Item key="new-leads">
										<Link to="/case-management/new-leads">New Leads</Link>
									</Menu.Item>
									<Menu.Item key="all-leads">
										<Link to="/case-management/all-leads">All Leads</Link>
									</Menu.Item>
									<Menu.Item key="all-cases">
										<Link to="/case-management/all-cases">All Cases</Link>
									</Menu.Item>
									<Menu.Item key="slot-bookings">
										<Link to="/case-management/slot-bookings">
											Slot Bookings
										</Link>
									</Menu.Item>
									{userLevel === "1" && (
										<Menu.Item key="transfer-customers">
											<Link to="/case-management/transfer-customers">
												Transfer Customers
											</Link>
										</Menu.Item>
									)}
								</SubMenu>
							)}

							{userRole === "admin" && (
								<SubMenu
									key="order-management"
									icon={<CheckCircleOutlined />}
									title="Order Management"
									onTitleClick={() =>
										history.push(`/order-management/all-orders`)
									}>
									{/* {userLevel === "1" && (
										<Menu.Item key="transfer-orders">
											<Link to="/order-management/transfer-orders">
												Transfer Orders
											</Link>
										</Menu.Item>
									)} */}
									<Menu.Item key="open-orders">
										<Link to="/order-management/open-orders">Open Orders</Link>
									</Menu.Item>
									<Menu.Item key="recent-orders">
										<Link to="/order-management/recent-orders">
											Recent Orders
										</Link>
									</Menu.Item>
									<Menu.Item key="ghost-orders">
										<Link to="/order-management/ghost-orders">
											Ghost Orders
										</Link>
									</Menu.Item>
									<Menu.Item key="assigned-doctors">
										<Link to="/order-management/assigned-doctors">
											Assigned Doctors
										</Link>
									</Menu.Item>

									<Menu.Item key="all-orders">
										<Link to="/order-management/all-orders">All Orders</Link>
									</Menu.Item>
								</SubMenu>
							)}

							{/* {userRole === "admin" && userLevel === "1" && (
								<Menu.Item
									key="manage-weekly-schedules"
									icon={<ScheduleOutlined />}>
									<Link to="/manage-weekly-schedules">
										Manage Weekly Schedules
									</Link>
								</Menu.Item>
							)} */}

							{/* {userRole === "admin" && userLevel === "1" && (
								<SubMenu
									key="report"
									icon={<FilePdfOutlined />}
									title="Reports"
									onTitleClick={() => history.push(`/report/orderLink-report`)}> */}
							{/* start */}

							{/* <Menu.Item key="reorder">
										<Link to="/report/reorder">ReOrder Report</Link>
									</Menu.Item> */}
							{/* <Menu.Item key="active-customer-report">
										<Link to="/report/active-customer-report">
											Active Customer Report
										</Link>
									</Menu.Item>

									<Menu.Item key="feedback-report">
										<Link to="/report/feedback-report">
											Weekly Answered Report
										</Link>
									</Menu.Item>

									<Menu.Item key="unordered-report">
										<Link to="/report/unordered-report">Unordered Report</Link>
									</Menu.Item> */}

							{/* <Menu.Item key="prescription-report">
										<Link to="/report/prescription-report">
											Prescription Report
										</Link>
									</Menu.Item>

									<Menu.Item key="engagement-plan-report">
										<Link to="/report/engagement-plan-report">
											Engagement Plan Report
										</Link>
									</Menu.Item> */}

							{/* end */}

							{/* <Menu.Item key="engagement-status-report">
										<Link to="/report/engagement-feedback-report">
											Engagement Feedback
										</Link>
									</Menu.Item>
									<Menu.Item key="engagement-report">
										<Link to="/report/engagement-report">
											Engagement Report
										</Link>
									</Menu.Item> */}

							{/* <Menu.Item key="utm_report">
										<Link to="/report/utm-report">UTM Report</Link>
									</Menu.Item> */}
							{/* <Menu.Item key="utm_aggregation_report">
										<Link to="/report/utm-aggregation-report">
											UTM Aggregation Report
										</Link>
									</Menu.Item> */}
							{/* <Menu.Item key="adherence_report">
										<Link to="/report/adherence-report">Adherence Report</Link>
									</Menu.Item>

									<Menu.Item key="sales_report">
										<Link to="/report/sales-report">Sales Report</Link>
									</Menu.Item>

									<Menu.Item key="orderLink_report">
										<Link to="/report/orderLink-report">Order Link Report</Link>
									</Menu.Item> */}
							{/* </SubMenu>
							)} */}

							{/* {userRole === "admin" && userLevel === "1" && (
								<Menu.Item key="users-management" icon={<UserOutlined />}>
									<Link to="/users-management">Users Management</Link>
								</Menu.Item>
							)} */}

							{/* supervisor menu */}
							{/* {userRole === "admin" && userLevel === "1" && (
								<Menu.Item
									key="group-management"
									icon={<UsergroupAddOutlined />}>
									<Link to="/group-management">Group Management</Link>
								</Menu.Item>
							)} */}

							{/* {userRole === "admin" && userLevel === "1" && (
								<Menu.Item key="discount-codes" icon={<TagsOutlined />}>
									<Link to="/discount-codes">Discount Codes</Link>
								</Menu.Item>
							)} */}

							{userRole === "admin" && (
								<>
									<Menu.Item key="warehouse-inwards" icon={<HomeOutlined />}>
										<Link to="/warehouse-inwards">Warehouse inwards</Link>
									</Menu.Item>
									<Menu.Item key="warehouse-skus" icon={<FullscreenExitOutlined />}>
										<Link to="/warehouse-skus">Warehouse Skus</Link>
									</Menu.Item>
								</>
							)}

							{userRole === "admin" && userLevel === "1" && (
								<SubMenu
									key="configure"
									icon={<SettingOutlined />}
									title="Configure"
									onTitleClick={() => history.push(`/configure/settings`)}>
									{/* <Menu.Item key="medicines">
										<Link to="/configure/medicines">Medicines</Link>
									</Menu.Item>
									<Menu.Item key="tags">
										<Link to="/configure/tags">Tags</Link>
									</Menu.Item>
									<Menu.Item key="products-mapping">
										<Link to="/configure/products-mapping">
											Products Mapping
										</Link>
									</Menu.Item> */}
									<Menu.Item key="settings">
										<Link to="/configure/settings">Settings</Link>
									</Menu.Item>
								</SubMenu>
							)}

							{userRole === "medical_rep" && (
								<Menu.Item key="assigned-orders" icon={<ShoppingOutlined />}>
									<Link to="/assigned-orders">My Patients</Link>
								</Menu.Item>
							)}

							{userRole === "medical_rep" && (
								<Menu.Item
									key="assigned-ghost-orders"
									icon={<CheckCircleOutlined />}>
									<Link to="/assigned-ghost-orders">Assigned Ghost Orders</Link>
								</Menu.Item>
							)}

							{userRole === "sales" && (
								<Menu.Item key="assigned-leads" icon={<VideoCameraOutlined />}>
									<Link to="/assigned-leads">Assigned Leads</Link>
								</Menu.Item>
							)}

							{(userRole === "sales" || userRole === "medical_rep" || userRole === "doctor") && (
								<Menu.Item key="escalated-cases" icon={<ExceptionOutlined />}>
									<Link to="/escalated-cases">Escalated Cases</Link>
								</Menu.Item>
							)}

							{userRole === "medical_rep" && ["1", "3"].includes(userLevel) && (
								<Menu.Item key="missed-calls" icon={<UserOutlined />}>
									<Link to="/missed-calls">
										IVR Calls {missedCalls ? `(${missedCalls.length})` : ""}
									</Link>
								</Menu.Item>
							)}

							{(userRole === "dispatch" || userRole === "admin") && (
								<>
									<Menu.Item key="search" icon={<SearchOutlined />}>
										<Link to="/search">Search</Link>
									</Menu.Item>
									<Menu.Item key="flagged-orders" icon={<FlagOutlined />}>
										<Link to="/flagged-orders">Flagged Orders</Link>
									</Menu.Item>
									<Menu.Item key="b2b-orders" icon={<ShoppingCartOutlined />}>
										<Link to="/b2b-orders">B2B Orders</Link>
									</Menu.Item>
									<Menu.Item key="hold-orders" icon={<ShoppingCartOutlined />}>
										<Link to="/hold-orders">Hold Orders</Link>
									</Menu.Item>
								</>
							)}

							{userRole === "medical_rep" && (
								<Menu.Item key="orderData" icon={<FilePdfOutlined />}>
									<Link to="/report/order-data">My ReOrders</Link>
								</Menu.Item>
							)}

							{/* supervisor menu */}
							{localStorage.getItem("is!supervisor") === "true" && (
								<Menu.Item key="myteam" icon={<TeamOutlined />}>
									<Link to="/myteam">My Team</Link>
								</Menu.Item>
							)}

							{userRole === "medical_rep" &&
								localStorage.getItem("is!supervisor") === "true" && (
									<Menu.Item
										key="manage-weekly-schedules"
										icon={<ScheduleOutlined />}>
										<Link to="/manage-weekly-schedules">
											Manage Weekly Schedules
										</Link>
									</Menu.Item>
								)}

							{userRole === "sales" && (
								<Menu.Item key="my-orders" icon={<ShoppingOutlined />}>
									<Link to="/my-orders">My Orders</Link>
								</Menu.Item>
							)}

							{/* {showMyTickets.has(userRole) && (
								<Menu.Item key="my-tickets" icon={<TagsOutlined />}>
									<Link to="/my-tickets">My Tickets</Link>
								</Menu.Item>
							)} */}
						</Menu>

						{React.createElement(
							collapsed ? CaretRightOutlined : CaretLeftOutlined,
							{
								className: "sidebar-trigger",
								onClick: toggle,
							}
						)}
					</div>
				</Sider>
				<Content
					className="site-layout-background"
					style={{
						margin: "24px 16px",
						padding: 24,
						minHeight: 280,
					}}>
					<Routes />
				</Content>
				<Profile
					isModalVisible={isModalVisible}
					setIsModalVisible={setIsModalVisible}
					setProfileStatus={setProfileStatus}
				/>
			</Layout>
		</Layout>
	);
}

export default Sidebar;

const getCurrentMenu = currentPath => {
	switch (currentPath) {
		case "/home":
			return { subMenu: "", key: "home" };
		case "/case-management/sales-management":
			return { subMenu: "case-management", key: "sales-management" };
		case "/case-management/new-leads":
			return { subMenu: "case-management", key: "new-leads" };
		case "/case-management/all-leads":
			return { subMenu: "case-management", key: "all-leads" };
		case "/case-management/all-cases":
			return { subMenu: "case-management", key: "all-cases" };
		case "/order-management/transfer-orders":
			return { subMenu: "order-management", key: "transfer-orders" };
		case "/order-management/open-orders":
			return { subMenu: "order-management", key: "open-orders" };
		case "/order-management/recent-orders":
			return { subMenu: "order-management", key: "recent-orders" };
		case "/order-management/ghost-orders":
			return { subMenu: "order-management", key: "ghost-orders" };
		case "/order-management/assigned-doctors":
			return { subMenu: "order-management", key: "assigned-doctors" };
		case "/order-management/all-orders":
			return { subMenu: "order-management", key: "all-orders" };
		case "manage-weekly-schedules":
			return { subMenu: "", key: "manage-weekly-schedules" };
		case "/report/reorder":
			return { subMenu: "report", key: "reorder" };
		case "/report/prescription-report":
			return { subMenu: "report", key: "prescription-report" };
		case "/report/engagement-plan-report":
			return { subMenu: "report", key: "engagement-plan-report" };
		case "/report/adherence-report":
			return { subMenu: "report", key: "adherence_report" };
		case "/report/sales-report":
			return { subMenu: "report", key: "sales_report" };
		case "/report/active-customer-report":
			return { subMenu: "report", key: "active-customer-report" };
		case "/report/feedback":
			return { subMenu: "report", key: "feedback" };
		case "/users-management":
			return { subMenu: "", key: "users-management" };
		case "/configure/medicines":
			return { subMenu: "configure", key: "medicines" };
		case "/configure/tags":
			return { subMenu: "configure", key: "tags" };
		case "/configure/products-mapping":
			return { subMenu: "configure", key: "products-mapping" };
		case "/configure/settings":
			return { subMenu: "configure", key: "settings" };
		case "/assigned-orders":
			return { subMenu: "", key: "assigned-orders" };
		case "/report/order-data":
			return { subMenu: "", key: "orderData" };
		case "/assigned-ghost-orders":
			return { subMenu: "", key: "assigned-ghost-orders" };
		case "/assigned-leads":
			return { subMenu: "", key: "assigned-leads" };
		case "/search":
			return { subMenu: "", key: "search" };
		case "/group-management":
			return { subMenu: "", key: "group-management" };
		case "/myteam":
			return { subMenu: "", key: "myteam" };
		case "/case-management/slot-bookings":
			return { subMenu: "case-management", key: "slot-bookings" };
		case "/case-management/transfer-customers":
			return { subMenu: "case-management", key: "transfer-customers" };
		case "/escalated-cases":
			return { subMenu: "", key: "escalated-cases" };
		case "/report/utm-report":
			return { subMenu: "report", key: "utm_report" };
		case "/report/orderLink-report":
			return { subMenu: "report", key: "orderLink_report" };
		case "/report/engagement-status-report":
			return { subMenu: "report", key: "engagement-status-report" };
		case "/report/unordered-report":
			return { subMenu: "report", key: "unordered-report" };
		case "/discount-codes":
			return { subMenu: "", key: "discount-codes" };
		case "/my-orders":
			return { subMenu: "", key: "my-orders" };
		case "/my-tickets":
			return { subMenu: "", key: "my-tickets" };
		case "/missed-calls":
			return { subMenu: "", key: "missed-calls" };
		case "/flagged-orders":
			return { subMenu: "", key: "flagged-orders" };
		case "/b2b-orders":
			return { subMenu: "", key: "b2b-orders" };
		case "/hold-orders":
			return { subMenu: "", key: "hold-orders" };
		case "/warehouse-inwards":
			return { subMenu: "", key: "warehouse-inwards" };
		case "/warehouse-skus":
			return { subMenu: "", key: "warehouse-skus" };
		default:
			return { subMenu: "", key: "" };
	}
};

const hideHomeRoute = new Set(["support", "read_only"]);
const showHomeRoute = new Set([
	"sales",
	"dispatch",
	"medical_rep",
	"doctor",
	"consult_doctor",
	"consult_rep",
]);
const shouldCallPing = new Set(["medical_rep", "sales"]);
const showMyTickets = new Set([
	"medical_rep",
	"sales",
	"admin",
	"doctor",
	"support",
]);

const statusOption = [
	{ label: "Online", value: "Online" },
	{ label: "Offline", value: "Offline" },
];

const profileOption = [
	{ label: "Edit Profile", value: "edit" },
	{ label: "Logout", value: "logout" },
];
