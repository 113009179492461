import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	Modal,
	Row,
	Select,
	Switch,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { addMedicine, getMedicines } from "../service";

const { TextArea } = Input;

const AddMedicine = ({ isModalVisible, setIsModalVisible }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (isModalVisible) getMedicines();
	}, [isModalVisible]);

	const handleModalClose = () => {
		form.resetFields();
		setIsModalVisible(false);
	};

	const onSubmit = async data => {
		const trimmedData = {};
		for (const key in data) {
			trimmedData[key] =
				typeof data[key] === "string" ? data[key].trim() : data[key];
		}
		if (!isEmpty(trimmedData)) {
			trimmedData.tax_rate = parseFloat(trimmedData.tax_rate) / 100;
		}
		// console.log(trimmedData);
		const res = await addMedicine(trimmedData);
		console.log(res);
		if (res) handleModalClose();
	};

	return (
		<Modal
			visible={isModalVisible}
			title="Add Medicine"
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}>
			<Row align="middle" justify="center">
				<Col span={18}>
					<Form
						form={form}
						layout="vertical"
						name="add medicine"
						onFinish={onSubmit}>
						<Form.Item
							label="Medicine Name"
							name="medicine"
							rules={[
								{
									required: true,
									message: "Please fill this",
								},
								{
									whitespace: true,
									message: "Please fill this",
								},
							]}>
							<Input />
						</Form.Item>

						<Form.Item
							label="Dosage"
							name="dosage"
							rules={[
								{
									required: true,
									message: "Please fill this",
								},
								{
									whitespace: true,
									message: "Please fill this",
								},
							]}>
							<Input />
						</Form.Item>

						<Form.Item
							label="Description"
							name="description"
							rules={[
								{
									required: true,
									message: "Please fill this",
								},
								{
									whitespace: true,
									message: "Please fill this",
								},
							]}>
							<TextArea autoSize />
						</Form.Item>

						<Form.Item
							label="Information"
							name="info"
							rules={[
								{
									required: true,
									message: "Please fill this",
								},
								{
									whitespace: true,
									message: "Please fill this",
								},
							]}>
							<TextArea autoSize />
						</Form.Item>

						<Form.Item
							label="Select Medicine Type"
							name="type"
							rules={[
								{
									required: true,
									message: "Please fill this",
								},
							]}>
							<Select allowClear options={optionsMedType} showSearch />
						</Form.Item>

						<Form.Item label="Group" name="group">
							<TextArea autoSize />
						</Form.Item>

						<Form.Item label="Combo Order" name="!isCombo">
							<Switch
								checkedChildren="Yes"
								defaultChecked={false}
								unCheckedChildren="No"
							/>
						</Form.Item>
						<Form.Item
							label="Tax Rate (in '%')"
							name="tax_rate"
							rules={[
								{
									required: true,
									message: "Please fill this field",
								},

								{
									pattern: new RegExp(/^[0-9]/),
									message: "Please enter valid Tax rate",
								},
							]}>
							<InputNumber max={100} min={0} style={{ width: "100%" }} />
						</Form.Item>

						<Form.Item style={{ textAlign: "center" }}>
							<Button type="primary" htmlType="submit">
								Add Medicine
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Modal>
	);
};

export default AddMedicine;

const optionsMedType = [
	{ label: "Allopathy", value: "allopathy" },
	{ label: "Ayurveda", value: "ayurveda" },
];
