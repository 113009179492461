import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";

import { assignSalesRep, getSalesReps } from "../leadManagement/service";

// Antd
import Button from "antd/es/button";
import message from "antd/es/message";
import Select from "antd/es/select";
const { Option } = Select;
// Ends

const ReAssignBookings = ({ selectedRows, updateUi }) => {
	const [isAssigning, setIsAssigning] = useState(false);
	const [dropdownData, setDropdownData] = useState(null);

	useEffect(() => {
		getSalesReps();
	}, []);

	const { salesReps, loading } = useSelector(state => ({
		salesReps: window.getValue(state, "leadmanagement.salesReps.userRole"),
		loading: window.getValue(state, "leadmanagement.salesRepsLoading"),
	}));

	const showSalesReps = useMemo(() => {
		if (isEmpty(salesReps)) return [];
		return salesReps.filter(rep => rep.user.level === 1);
	}, [salesReps]);

	const assignCases = async rows => {
		if (isEmpty(rows)) return;

		const _requests = [];
		rows.forEach(row => {
			const _res = assignSalesRep(dropdownData, row.caseId);
			_requests.push(_res);
		});

		await Promise.all(_requests)
			.then(response => {
				if (response.every(res => res.status === 200)) {
					setDropdownData(null);
					updateUi();
					message.success("Assigned Successfully");
				} else if (response.some(res => res.status === 400)) {
					setDropdownData(null);
					updateUi();
					message.warn(
						"One or more cases were found with orders and so sales reps could not be assigned. The page will refresh with the latest data."
					);
				} else {
					const filterRes = response.filter(res => {
						return res.status !== 200 && res.status !== 400;
					});

					filterRes[0]
						.json()
						.then(res => message.error(res.message))
						.catch(error => message.error(error.message));
				}
			})
			.catch(error => console.log(error.message));
	};

	const handleAssignSalesRep = async () => {
		setIsAssigning(true);
		await assignCases(selectedRows);
		setIsAssigning(false);
	};

	return (
		<div className="flex gap-1">
			<Select
				showSearch
				style={{ width: 200 }}
				placeholder="Select a person"
				loading={loading}
				optionFilterProp="children"
				onChange={selectedRowKeys => setDropdownData(selectedRowKeys)}
				value={dropdownData}>
				{!isEmpty(salesReps) &&
					showSalesReps.map(users => (
						<Option value={users.user.id} key={users.user.id}>
							{users.user.first_name}
						</Option>
					))}
			</Select>

			<Button
				disabled={isEmpty(dropdownData) || isEmpty(selectedRows)}
				loading={isAssigning}
				onClick={handleAssignSalesRep}
				type="primary">
				Assign
			</Button>
		</div>
	);
};

export default ReAssignBookings;
