import {
	Button,
	Col,
	message,
	Popconfirm,
	Row,
	Table,
	Tabs,
	Typography,
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
	getTransferCustomerCount,
	transferCustomerToMedRep,
} from "../../../leadDetails/service";
import TransferCustomerSearch from "../../../leadManagement/components/TransferCustomerV2/TransferCustomerSearch";
import TransferMedRepSearch from "../../../leadManagement/components/TransferCustomerV2/TransferMedRepSearch";
const { TabPane } = Tabs;

const { Title } = Typography;

function TransferCustomerv2() {
	const [selectedMedRepSearch, setSelectedMedRepSearch] = useState({});
	const [selectedSearch, setSelectedSearch] = useState({});
	const [searchValue, setSearchValue] = useState(undefined);
	const [medRepSearchValue, setMedRepSearchValue] = useState(undefined);
	const [isMovementCompleted, setIsMovementCompleted] = useState(false);
	const [searchData, setSearchData] = useState([]);
	const [medRepSearchData, setMedRepSearchData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tag, setTag] = useState("Transfer Customer");
	const [recentCustomer, setRecentCustomer] = useState([]);

	const columns = [
		{
			title: "From",
			dataIndex: "originalRepName",
			key: "originalRepName",
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Remaining",
			dataIndex: "remaining",
			key: "remaining",
		},
		{
			title: "To",
			dataIndex: "targetRepNames",
			key: "targetRepNames",
			render: target => (
				<ul>
					{target.map(name => (
						<li>{name}</li>
					))}
				</ul>
			),
		},
		{
			title: "Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: createdDate => (
				<>{moment(createdDate).format("DD MMM YYYY h:mm a")}</>
			),
		},
	];

	useEffect(() => {
		getRecentCustomer();
	}, recentCustomer);

	const getRecentCustomer = async () => {
		setLoading(true);
		const response = await getTransferCustomerCount();
		if (response) {
			setRecentCustomer(response);
		}
		setLoading(false);
	};

	const handleConfirm = async () => {
		const fromRepId = selectedMedRepSearch.rep_id;
		const transferList = selectedMedRepSearch.transferData;
		const targetList = selectedSearch.target;
		const toRepId = selectedSearch;

		if (isEmpty(transferList) || isEmpty(targetList)) {
			message.warning("Please choose one or more MedRep to transfer");
			return;
		}

		if (toRepId.target.includes(fromRepId)) {
			message.warning(`You can not transfer to the same med rep`);
			return;
		}
		let data = {
			source: {
				repId: fromRepId,
				transferData: transferList,
			},
			target: targetList,
		};
		setIsMovementCompleted(true);
		const _res = await transferCustomerToMedRep(data);
		setIsMovementCompleted(false);

		if (_res) {
			getRecentCustomer();
			setSelectedMedRepSearch({});
			setSearchValue("");
			setMedRepSearchValue("");
			setSearchData("");
			setMedRepSearchData("");
			setSelectedSearch({});
		}
	};
	const handleTabChange = key => {
		setTag(key);
	};

	return (
		<div>
			<Title level={2} style={{ marginBottom: 10 }}>
				{tag}
			</Title>
			<Tabs defaultActiveKey={tag} onChange={handleTabChange} type="card">
				<TabPane tab="Transfer Customer" key="Transfer Customer">
					<Row>
						<Col span={11}>
							<TransferCustomerSearch
								selectedMedRepSearch={selectedMedRepSearch}
								setSelectedMedRepSearch={setSelectedMedRepSearch}
								medRepSearchValue={medRepSearchValue}
								setMedRepSearchValue={setMedRepSearchValue}
								medRepSearchData={medRepSearchData}
								setMedRepSearchData={setMedRepSearchData}
							/>
						</Col>

						<Col span={2}></Col>
						<Col span={11}>
							<TransferMedRepSearch
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								searchData={searchData}
								setSearchData={setSearchData}
								selectedSearch={selectedSearch}
								setSelectedSearch={setSelectedSearch}
							/>
						</Col>
					</Row>
					<Row justify="center mt-16">
						<Popconfirm
							disabled={
								isEmpty(selectedMedRepSearch.transferData) ||
								isEmpty(selectedSearch.target)
							}
							title="Are you sure you want to move this Med Rep?"
							placement="topRight"
							onConfirm={handleConfirm}>
							<Button
								disabled={
									isEmpty(selectedMedRepSearch.transferData) ||
									isEmpty(selectedSearch.target)
								}
								type="primary"
								danger
								size="large"
								loading={isMovementCompleted}
								style={{
									fontSize: "1.5rem",
									padding: ".2em 1em",
									fontWeight: "bold",
									height: "max-content",
								}}>
								CONFIRM AND TRANSFER
							</Button>
						</Popconfirm>
					</Row>
				</TabPane>

				<TabPane tab="Recent Transfer" key="Recent Transfer">
					<Table
						columns={columns}
						dataSource={recentCustomer}
						pagination={{
							pageSize: 10,
						}}
						style={{ width: "100%" }}
					/>
				</TabPane>
			</Tabs>
		</div>
	);
}

export default TransferCustomerv2;
