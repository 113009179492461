import moment from "moment";
export const getLast4Month = () => {
	const monthList = [];
	for(let i = 1 ; i <= 4; i++){
		const monthName = moment().subtract(i, "month").startOf("month").format('MMMM');
		const startDate = moment().subtract(i, "month").startOf("month").format('YYYY-MM-DD');
		const endDate = moment().subtract(i, "month").endOf('month').add(1,'day').format('YYYY-MM-DD');
		monthList.push({
			monthName: monthName,
			startDate: startDate,
			endDate: endDate
		})
	}
	return monthList;
}
