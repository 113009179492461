import {
	chatActions,
	leadDetailsActions,
	updateConsultActions,
} from "../actions";

export const leaddetails = (state = {}, action) => {
	switch (action.type) {
		case leadDetailsActions.GET_USERS_DETAILS_DATA_LOADING:
			return {
				...state,
				usersDetailLoading: true,
			};
		case leadDetailsActions.GET_USERS_DETAILS_DATA_SUCCESS:
			return {
				...state,
				usersDetail: action.payload,
				usersDetailLoading: false,
			};
		case leadDetailsActions.GET_USERS_DETAILS_DATA_FAILURE:
			return {
				...state,
				usersDetail: null,
				usersDetailLoading: false,
			};

		case leadDetailsActions.FETCH_COMMENTS_DATA_LOADING:
			return {
				...state,
				commentsLoading: true,
			};
		case leadDetailsActions.FETCH_COMMENTS_DATA_SUCCESS:
			return {
				...state,
				comments: action.payload,
				commentsLoading: false,
			};
		case leadDetailsActions.FETCH_COMMENTS_DATA_FAILURE:
			return {
				...state,
				comments: null,
				commentsLoading: false,
			};

		case leadDetailsActions.GET_REMINDERS_LOADING:
			return {
				...state,
				remindersLoading: true,
			};
		case leadDetailsActions.GET_REMINDERS_SUCCESS:
			return {
				...state,
				reminders: action.payload,
				remindersLoading: false,
			};
		case leadDetailsActions.GET_REMINDERS_FAILURE:
			return {
				...state,
				reminders: null,
				remindersLoading: false,
			};
		case leadDetailsActions.GET_ACTIVITY_LOG_LOADING:
			return {
				...state,
				activityLogData: Array.isArray(state.activityLogData)
					? state.activityLogData
					: [],
				caseActivityLogLoading: true,
				activityLogPagination: {},
			};
		case leadDetailsActions.GET_ACTIVITY_LOG_SUCCESS:
			const newData = [...state.activityLogData, ...action.payload.data];
			const _data = [...new Map(newData.map(data => [data.id, data])).values()];
			return {
				...state,
				activityLogData: Array.isArray(state.activityLogData)
					? _data
					: [...action.payload.data],
				activityLogPagination: action.payload.pagination,
				caseActivityLogLoading: false,
			};
		case leadDetailsActions.GET_ACTIVITY_LOG_FAILURE:
			return {
				...state,
				activityLogData: Array.isArray(state.activityLogData)
					? state.activityLogData
					: [],
				activityLogPagination: {},
				caseActivityLogLoading: false,
			};

		case leadDetailsActions.RESET_CASE_ACTIVITY_LOG:
			return {
				...state,
				activityLogData: [],
				caseActivityLogLoading: false,
				activityLogPagination: {},
			};

		case updateConsultActions.UPDATE_CONSULT_LOADING:
			return {
				...state,
				updateConsultLoading: true,
			};
		case updateConsultActions.UPDATE_CONSULT_SUCCESS:
			return {
				...state,
				updateConsultData: action.payload,
				updateConsultLoading: false,
			};
		case updateConsultActions.UPDATE_CONSULT_FAILURE:
			return {
				...state,
				updateConsultData: null,
				updateConsultLoading: false,
			};

		case chatActions.GET_CHAT_LOADING:
			return {
				...state,
				chatsLoading: true,
			};
		case chatActions.GET_CHAT_SUCCESS:
			return {
				...state,
				chats: action.payload,
				chatsLoading: false,
			};
		case chatActions.GET_CHAT_FAILURE:
			return {
				...state,
				chats: null,
				chatsLoading: false,
			};

		case chatActions.GET_DUPLICATE_CHAT_LIST_LOADING:
			return {
				...state,
				duplicateChatListLoading: true,
			};
		case chatActions.GET_DUPLICATE_CHAT_LIST_SUCCESS:
			return {
				...state,
				duplicateChatList: action.payload,
				duplicateChatListLoading: false,
			};
		case chatActions.GET_DUPLICATE_CHAT_LIST_FAILURE:
			return {
				...state,
				duplicateChatList: null,
				duplicateChatListLoading: false,
			};

		case leadDetailsActions.GET_PROFILE_LOADING:
			return {
				...state,
				profileLoading: true,
			};
		case leadDetailsActions.GET_PROFILE_SUCCESS:
			return {
				...state,
				profileData: action.payload,
				profileLoading: false,
			};
		case leadDetailsActions.GET_PROFILE_FAILURE:
			return {
				...state,
				profileData: null,
				profileLoading: false,
			};
		case leadDetailsActions.GET_PROFILE_TAG_LOADING:
			return {
				...state,
				profileTagLoading: true,
			};
		case leadDetailsActions.GET_PROFILE_TAG_SUCCESS:
			return {
				...state,
				profileTagData: action.payload,
				profileTagLoading: false,
			};
		case leadDetailsActions.GET_PROFILE_TAG_FAILURE:
			return {
				...state,
				profileTagData: null,
				profileTagLoading: false,
			};
		case leadDetailsActions.GET_CASE_TAG_HISTORY_LOADING:
			return {
				...state,
				caseHistoryTagLoading: true,
			};
		case leadDetailsActions.GET_CASE_TAG_HISTORY_SUCCESS:
			return {
				...state,
				caseHistoryTagData: action.payload,
				caseHistoryTagLoading: false,
			};
		case leadDetailsActions.GET_CASE_TAG_HISTORY_FAILURE:
			return {
				...state,
				caseHistoryTagData: null,
				caseHistoryTagLoading: false,
			};

		case leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_LOADING:
			return {
				...state,
				consultCallFeedback: {},
				consultCallFeedbackLoading: true,
			};
		case leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_SUCCESS:
			return {
				...state,
				consultCallFeedback: action.payload,
				consultCallFeedbackLoading: false,
			};
		case leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_FAILURE:
			return {
				...state,
				consultCallFeedback: {},
				consultCallFeedbackLoading: false,
			};

		default:
			return state;
	}
};
