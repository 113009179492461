import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import CreateGroup from "./CreateGroup";
import ManageGroup from "./ManageGroup";
import { getAllUsers } from "../../usersManagment/service";
import { deleteGroup, getGroups } from "../service";
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
const { Text } = Typography;

const GroupManagment = () => {
  const [openCreateGroup, setOpenCreateGroup] = useState(false);
  const [openManageGroup, setOpenManageGroup] = useState(false);
  const [data, setData] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupId, setGroupId] = useState("");

  useEffect(() => {
    getAllUsers();
    getGroups();
  }, []);

  const { allUsers, groups, groupsLoading } = useSelector(state => ({
    allUsers: window.getValue(state, "usersmanagement.allUsers") || [],
    groups: window.getValue(state, "supervisorsData.groups"),
    groupsLoading: window.getValue(state, "supervisorsData.groupsLoading"),
  }));

  useEffect(() => {
    if (isEmpty(groups)) {
      setData([]);
    } else {
      const ndata = groups.map(group => ({
        key: window.getValue(group, "id"),
        group_name: window.getValue(group, "name"),
        supervisor_name: window.getValue(group, "user.first_name"),
        user_id: window.getValue(group, "user_id"),
      }));

      setData([...ndata]);
    }
  }, [groups]);

  const columns = [
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Supervisor Name",
      dataIndex: "supervisor_name",
      key: "supervisor_name",
    },
    {
      title: "Actions",
      key: "actions",
      render: ({ group_name, key }) => (
        <Space size={1}>
          <Tooltip title="Manage Group">
            <Button
              icon={<FileTextOutlined />}
              onClick={() => {
                setGroupId(key);
                setGroupName(group_name);
                setOpenManageGroup(true);
              }}
              type="link"
            />
          </Tooltip>

          <Tooltip title="Delete Group">
            <Popconfirm
              title={
                <Text>
                  Are you sure you want to delete{" "}
                  <Text type="danger" strong>
                    {group_name}
                  </Text>
                </Text>
              }
              placement="bottom"
              onConfirm={() => deleteGroup(key)}
            >
              <Button danger icon={<DeleteOutlined />} type="link" />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => setOpenCreateGroup(true)}>
            Create Group
          </Button>
        </Col>

        <Col span={24}>
          <Table columns={columns} dataSource={data} loading={groupsLoading} />
        </Col>
      </Row>

      <CreateGroup
        isModalVisible={openCreateGroup}
        setIsModalVisible={setOpenCreateGroup}
      />

      <ManageGroup
        group_name={groupName}
        group_id={groupId}
        isModalVisible={openManageGroup}
        setIsModalVisible={setOpenManageGroup}
      />
    </>
  );
};

export default GroupManagment;
