import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCurrentUserRole, getLineItemsName } from "../../../helpers";
import {
	Button,
	Card,
	Col,
	Empty,
	List,
	Row,
	Space,
	Table,
	Typography,
} from "antd";
const { Text } = Typography;

function AllPrescriptions({
	canCreate,
	clonedOrderIDs,
	hasWritten,
	orderId,
	prescriptionData,
	setClonedOrderIDs,
	setPrescriptionData,
	setShowUI,
}) {
	const [userRole] = useState(getCurrentUserRole());
	const [data, setData] = useState([]);

	const { prescriptions } = useSelector(state => ({
		prescriptions: window.getValue(state, "doctorsData.prescriptions"),
	}));

	useEffect(() => {
		const init = async () => {
			const ndata = await getTableData(prescriptions, orderId);
			setData([...ndata]);
		};

		if (isEmpty(prescriptions)) setData([]);
		else init();
	}, [prescriptions, orderId]);

	const columns = [
		{
			title: "Medicine",
			key: "Medicine",
			render: record => (
				<Space direction="vertical">
					<Text>{record.Medicine}</Text>
					<Text type="secondary">
						{record.showRecommendation && `(Recommended by doctor)`}
					</Text>
				</Space>
			),
		},
		{
			title: "Dosage",
			key: "Dosage",
			render: record => (
				<Space direction="vertical">
					<Text>{record.Dosage}</Text>
					<Text>{record.composition}</Text>
				</Space>
			),
		},
		{
			title: "Note",
			dataIndex: "note",
			key: "note",
		},
	];

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			{isEmpty(data) ? (
				<Row justify="center">
					<Col>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Col>
				</Row>
			) : (
				data.map(item => (
					<Card
						key={item.key}
						hoverable
						title={`${item.order_name} (${moment(item.date).format(
							"DD-MM-YYYY"
						)})`}
						extra={
							!isEmpty(item.prescriptionv2) &&
							userRole === "medical_rep" && (
								<Button
									disabled={
										!canCreate ||
										hasWritten ||
										clonedOrderIDs.includes(item.order_name)
									}
									onClick={() => {
										setPrescriptionData([
											...prescriptionData,
											...item.prescriptionv2,
										]);
										setClonedOrderIDs([...clonedOrderIDs, item.order_name]);
										setShowUI(true);
									}}
									type="default">
									{clonedOrderIDs.includes(item.order_name)
										? "Cloned"
										: "Clone"}
								</Button>
							)
						}>
						<Space direction="vertical">
							<Text type="secondary">{item.line_items}</Text>
							{!isEmpty(item.prescriptionv2) && (
								<Table
									columns={columns}
									dataSource={sortByIsRecommended(item.prescriptionv2)}
									pagination={false}
								/>
							)}
							{!isEmpty(item.prescriptionv1) && (
								<List
									dataSource={item.prescriptionv1}
									renderItem={item => <List.Item>{item}</List.Item>}
								/>
							)}
						</Space>
					</Card>
				))
			)}
		</Space>
	);
}

export default AllPrescriptions;

const sortByIsRecommended = items =>
	items.sort((a, b) => a.showRecommendation - b.showRecommendation);

const getTableData = async (prescriptions, orderId) => {
	let ndata = [];

	const filterData = await prescriptions.filter(i => i.order_id !== orderId);
	const groupData = await groupBy(filterData, i => i.order_id);

	for (let key in groupData) {
		const obj = {};

		obj["order_name"] = groupData[key][0].order.order_display_id;
		obj["date"] = groupData[key][0].order.created_at;

		groupData[key].map(item => {
			obj["key"] = item.id;
			obj["line_items"] = getLineItemsName(item.order?.order_meta?.line_items);

			switch (item.version) {
				case 1: {
					const prescription = item.prescription;
					obj["prescriptionv1"] = isEmpty(obj.prescriptionv1)
						? [prescription]
						: [...obj.prescriptionv1, prescription];
					break;
				}

				case 2: {
					const prescription = JSON.parse(item.prescription).prescription;
					obj["prescriptionv2"] = isEmpty(obj.prescriptionv2)
						? [...prescription]
						: [...obj.prescriptionv2, ...prescription];
					break;
				}

				default:
					obj = {};
					break;
			}

			return obj;
		});
		ndata = [...ndata, obj];
	}
	return await ndata.sort((a, b) => new Date(b.date) - new Date(a.date));
};
