import { Col, Row, Table, Tag, Typography } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import DoctorFilters from "../../../common/DoctorFilters";
import {
	generateQuery,
	getLineItemsName,
	getQueryParams,
	getSearchParams,
	getValue,
} from "../../../helpers";
import { getAssignedDoctors, getDoctors } from "../service";
const { Text, Title } = Typography;

function AssignedDoctors() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(getSearchParams);

	useEffect(() => {
		const filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		getDoctors();
	}, []);

	useEffect(() => {
		if (queryParams) {
			history.push({ search: queryParams });
			getAssignedDoctors(queryParams);
		}
	}, [history, queryParams]);

	const { orders, loading } = useSelector(state => ({
		orders: getValue(state, "orderManagement.ordersAssignedDoctors"),
		loading: getValue(state, "orderManagement.ordersAssignedDoctorsLoading"),
	}));

	useEffect(() => {
		if (isEmpty(orders)) setData([]);
		else {
			const ndata = orders.data.map(row => ({
				key: getValue(row, "id"),
				case_id: getValue(row, "case_id"),
				name: getValue(row, "user.first_name"),
				phone_Number: getValue(row, "user.phone_number"),
				orderDisplayId: getValue(row, "order_display_id"),
				orderName: getLineItemsName(row.order_meta?.line_items),
				orderStatus: getValue(row, "status"),
				orderDate: getValue(row, "created_at"),
				assignedDoctor: Array.prototype.map
					.call(getValue(row, "doctor"), item => ` ${item.user.first_name}`)
					.join(","),
				count: getValue(row, "count"),
				order_type: getValue(row, "order_type"),
				assignedTo: getValue(row, "assignment.first_name"),
				owner: getValue(row, "case.assignment.first_name"),
				sub_status: getValue(row, "sub_status"),
			}));
			setData(ndata);
			setPaginationInfo(orders.pagination);
		}
	}, [orders]);

	const columns = [
		{
			title: "Name",
			key: "name",
			render: ({ name, case_id, phone_Number }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${case_id}`}>
						{name}
					</Link>
					<Text type="secondary">{phone_Number}</Text>
				</div>
			),
		},
		{
			title: "Order ID",
			dataIndex: "orderDisplayId",
			key: "orderDisplayId",
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},

		{
			title: "Order Name",
			dataIndex: "orderName",
			key: "orderName",
			width: 240,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "orderStatus",
			key: "orderStatus",
			width: 112,
			render: (status = "", record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "orderDate",
			width: 144,
			render: orderDate => moment(orderDate).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned To",
			dataIndex: "assignedTo",
			key: "assignedTo",
		},
		{
			title: "Owner",
			dataIndex: "owner",
			key: "owner",
		},
		{
			title: "Assigned Doctors",
			dataIndex: "assignedDoctor",
			key: "assignedTo",
		},
	];

	return (
		<>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>Assigned Doctors</Title>
				</Col>
				<Col>
					<DoctorFilters
						setPageNo={setPageNo}
						setQueryParams={setQueryParams}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={data}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
					/>
				</Col>
			</Row>
		</>
	);
}

export default AssignedDoctors;
