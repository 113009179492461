import Cookies from "js-cookie";
import { baseURL } from "../../helpers";

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const getUploadPhotoLink = async caseId => {
	let data = "";
	let hasError = false;

	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}case/${caseId}/photoUploadLink`,
			{
				method: "GET",
				...AUTH_HEADER,
			}
		);

		if (_res.status === 200) data = await _res.json();
		else hasError = true;
	} catch (error) {
		hasError = true;
	}

	return { data, hasError };
};
