import React from "react";
import PrescriptionOrder from "../../dispatch/components/PrescriptionOrder";
import { Col, Row, Typography } from "antd";
const { Title } = Typography;

function DashboardDispatch() {
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Title level={2}>Package Management</Title>
        </Col>
        <Col span={24}>
          <PrescriptionOrder />
        </Col>
      </Row>
    </>
  );
}

export default DashboardDispatch;
