import { message } from "antd";
import Cookies from "js-cookie";
import { CONSULT_CALL_FEEDBACK_API, getUrl } from "../../constants/apiUrls";
import { baseURL, getQueryParams, handleError } from "../../helpers";
import { fetchRequest } from "../../helpers/fetchRequest";
import { store } from "../../redux/store";
import {
	chatActions,
	leadDetailsActions,
	updateConsultActions,
} from "./actions";

export const viewDetails = user_id => {
	store.dispatch({
		type: leadDetailsActions.GET_USERS_DETAILS_DATA_LOADING,
		payload: true,
	});
	let url = `${baseURL.API_URL_BASE}case/${user_id}`;
	const fromUnified = getQueryParams("is_unified_ticketing");
	if (fromUnified && fromUnified === "true")
		url += `?is_unified_ticketing=true`;
	return fetch(url, {
		method: "GET",
		json: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		handleError(res);
		return res
			.json()
			.then(response => {
				if (response) {
					store.dispatch({
						type: leadDetailsActions.GET_USERS_DETAILS_DATA_SUCCESS,
						payload: response,
					});
				} else {
					store.dispatch({
						type: leadDetailsActions.GET_USERS_DETAILS_DATA_FAILURE,
						payload: response,
					});
				}
				return response;
			})
			.catch(error => {
				store.dispatch({
					type: leadDetailsActions.GET_USERS_DETAILS_DATA_FAILURE,
					payload: error.toString(),
				});
				handleError(error);
			});
	});
};

export const getComments = async user_id => {
	store.dispatch({
		type: leadDetailsActions.FETCH_COMMENTS_DATA_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${user_id}/comments`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadDetailsActions.FETCH_COMMENTS_DATA_SUCCESS,
				payload: data,
			});
		} else {
			store.response({
				type: leadDetailsActions.FETCH_COMMENTS_DATA_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.FETCH_LEAD_DETAILS_DATA_FAILURE,
			payload: error.toString(),
		});
	}
};

export const postComment = async (user_id, comment) => {
	try {
		return await fetch(`${baseURL.API_URL_BASE}case/${user_id}/comments`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ comment_text: comment }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
	} catch (error) {
		console.info(error.toString());
	}
};

export const deleteComment = async comment_id => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}comment/${comment_id}`, {
			method: "DELETE",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Comment Deleted Successfully");
		} else {
			handleError(res);
			message.error("Failed to Delete Comment");
		}
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const callStatusUpdate = async (user_id, status) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${user_id}/status`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ newStatus: status }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			message.success("Status Updated Successfully!!!");
		} else {
			message.error("Failed to update Status");
			handleError(res);
		}

		return res;
	} catch (error) {
		console.warn(error.message);
	}
};

export const setReminder = async (case_id, reminder_date, comment) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}reminder`, {
			method: "POST",
			body: JSON.stringify({ case_id, reminder_date, comment }),
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Reminder set successfully");
		} else {
			handleError(res);
			message.error("Failed to set reminder.");
		}
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const getReminders = async case_id => {
	store.dispatch({
		type: leadDetailsActions.GET_REMINDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}case/${case_id}/reminders`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadDetailsActions.GET_REMINDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadDetailsActions.GET_REMINDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.GET_REMINDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const updateConsult = async (case_id, notes, tag) => {
	store.dispatch({
		type: updateConsultActions.UPDATE_CONSULT_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}case/${case_id}/consult_call`,
			{
				method: "PUT",
				json: true,
				body: JSON.stringify({ notes, tag }),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);

		if (res.status === 200) {
			store.dispatch({
				type: updateConsultActions.UPDATE_CONSULT_SUCCESS,
				payload: true,
			});
		} else {
			store.dispatch({
				type: updateConsultActions.UPDATE_CONSULT_FAILURE,
				payload: false,
			});
			handleError(res);
		}
		return res;
	} catch (error) {
		store.dispatch({
			type: updateConsultActions.UPDATE_CONSULT_FAILURE,
			payload: error.message,
		});
	}
};

export const shippingPending = order_id => {
	return fetch(`${baseURL.API_URL_BASE}order/${order_id}/markShippingPending`, {
		method: "PUT",
		json: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		if (res.status === 200) {
			return res
				.json()
				.then(response => {
					if (response) {
						return response;
					} else {
						return response;
					}
				})
				.catch(error => {
					handleError(error);
				});
		} else {
			handleError(res);
		}
	});
};

export const markDelivered = async (order_id, data) => {
	return fetch(`${baseURL.API_URL_BASE}order/${order_id}/markAsDelivered`, {
		method: "POST",
		json: true,
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		return res;
	});
};

export const markSlotDismiss = async booking_id => {
	return fetch(`${baseURL.API_URL_BASE}markDoctorSlotFinished/${booking_id}`, {
		method: "POST",
		json: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		return res;
	});
};

export const postFeedback = async (data, case_id) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${case_id}/feedback`, {
			method: "PUT",
			json: true,
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			message.success("Feedback submitted");
		} else if (res.status !== 422) {
			handleError(res);
			message.error("Failed to submit feedback");
		}
		return res;
	} catch (error) {
		message.info(error.toString());
	}
};

export const knowlarityCall = (
	customer_number,
	caseId,
	tag,
	order_display_id
) => {
	return fetch(`${baseURL.API_URL_BASE}call`, {
		method: "POST",
		json: true,
		body: JSON.stringify({
			phone_number: customer_number,
			case_id: caseId,
			note: { tag: tag, order_display_id: order_display_id },
		}),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		if (res.status === 200) {
			message.success(`Call is in progress ${customer_number}`);
			return res;
		} else {
			message.error(`Failed to call on this number ${customer_number}`);
			handleError(res);
			return res;
		}
	});
};

export const editProfile = async (user_id, data) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}customer/${user_id}/edit`, {
			method: "PUT",
			json: true,
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			message.success("Updated Successfully");
		} else {
			const resJson = await res.json();
			message.error(resJson.message);
		}
		return res;
	} catch (error) {
		message.error(error.message);
	}
};

export const voidOrder = async orderId => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}void-order/${orderId}`, {
			method: "PUT",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const getChats = async case_id => {
	store.dispatch({
		type: chatActions.GET_CHAT_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${case_id}/chat`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: chatActions.GET_CHAT_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: chatActions.GET_CHAT_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: chatActions.GET_CHAT_FAILURE,
			payload: error.message,
		});
	}
};

export const postChat = async (case_id, msg) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${case_id}/chat`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ message: msg }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Message sent");
		} else if (res.status === 400) {
			message.warning("Please update chat phone number and try again");
		} else {
			handleError(res);
			message.error("Failed to send message");
		}
		return res;
	} catch (error) {
		message.info(error.message);
	}
};

export const getDuplicateChatList = async phoneNumber => {
	if (phoneNumber) {
		store.dispatch({
			type: chatActions.GET_DUPLICATE_CHAT_LIST_LOADING,
			payload: true,
		});

		try {
			const res = await fetch(
				`${baseURL.API_URL_BASE}chat_duplicate_list?chat_phone_number=${phoneNumber}`,
				{
					method: "GET",
					json: true,
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get("token")}`,
					},
				}
			);
			if (res.status === 200) {
				const data = await res.json();
				store.dispatch({
					type: chatActions.GET_DUPLICATE_CHAT_LIST_SUCCESS,
					payload: data,
				});
			} else {
				store.dispatch({
					type: chatActions.GET_DUPLICATE_CHAT_LIST_FAILURE,
					payload: null,
				});
				handleError(res);
			}
		} catch (error) {
			store.dispatch({
				type: chatActions.GET_DUPLICATE_CHAT_LIST_FAILURE,
				payload: error.message,
			});
		}
	} else {
		store.dispatch({
			type: chatActions.GET_DUPLICATE_CHAT_LIST_SUCCESS,
			payload: [],
		});
	}
};

export const getCaseActivityLog = async (case_id, queryParams) => {
	store.dispatch({
		type: leadDetailsActions.GET_ACTIVITY_LOG_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}case/${case_id}/activityLog?${queryParams}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);

		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadDetailsActions.GET_ACTIVITY_LOG_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadDetailsActions.GET_ACTIVITY_LOG_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.GET_ACTIVITY_LOG_FAILURE,
			payload: error.message,
		});
	}
};

export const sendEmailSMS = async engagementId => {
	let res = {};

	try {
		res = await fetch(
			`${baseURL.API_URL_BASE}engagement/${engagementId}/not_answered`,
			{
				method: "POST",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
	} catch (error) {
		message.info(error.message);
	} finally {
		if (res.status === 200) {
			message.success("Email and SMS sent");
		} else {
			message.error("Failed to send Email and SMS");
			handleError(res);
		}

		return res;
	}
};

export const bulkOrder = async (bulkOrderId, duration) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}bulkOrder`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ order_id: bulkOrderId, duration: duration }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};
export const handleDietPlan = async (customerId, dietPlanLink) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}diet-plan`, {
			method: "PUT",
			body: JSON.stringify({
				customer_id: customerId,
				diet_plan_link: dietPlanLink,
			}),
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const sendEmailSMSConsultCall = async caseId => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}consult_call/${caseId}/not_answered`,
			{
				method: "POST",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			message.success("Email and SMS sent");
		} else {
			message.error("Failed to send Email and SMS");
			handleError(res);
		}
	} catch (error) {
		message.info(error.message);
	}
};

export const getProfileData = async userId => {
	store.dispatch({
		type: leadDetailsActions.GET_PROFILE_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}user/${userId}/profile`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadDetailsActions.GET_PROFILE_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadDetailsActions.GET_PROFILE_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.GET_PROFILE_FAILURE,
			payload: error.message,
		});
	}
};

export const profileUpdate = async (userId, data) => {
	try {
		let profileData = new FormData();
		await profileData.append("image", data.compressedFile);
		await profileData.append("first_name", data.first_name);
		await profileData.append("alternate_email", data.userAlternateEmail);

		const res = await fetch(`${baseURL.API_URL_BASE}user/${userId}/profile`, {
			method: "PUT",
			body: profileData,
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const removeProfileImage = async () => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}user/deleteProfileImage`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		message.info(error.message);
	}
};

export const getProfileTags = async () => {
	store.dispatch({
		type: leadDetailsActions.GET_PROFILE_TAG_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}getAllTags`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadDetailsActions.GET_PROFILE_TAG_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadDetailsActions.GET_PROFILE_TAG_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.GET_PROFILE_TAG_FAILURE,
			payload: error.message,
		});
	}
};

export const addProfileTag = async (caseId, tagId) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${caseId}/addTag`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ tag_id: tagId }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		message.info(error.message);
	}
};

export const deleteProfileTag = async (caseId, tagId) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}case/${caseId}/deleteTag`, {
			method: "DELETE",
			json: true,
			body: JSON.stringify({ tag_id: tagId }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		return res;
	} catch (error) {
		message.info(error.message);
	}
};

export const getCaseTagHistory = async case_id => {
	store.dispatch({
		type: leadDetailsActions.GET_CASE_TAG_HISTORY_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}case/${case_id}/caseTagDetails`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadDetailsActions.GET_CASE_TAG_HISTORY_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadDetailsActions.GET_CASE_TAG_HISTORY_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.GET_CASE_TAG_HISTORY_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getNotificationPreferences = async userId => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}notification-preferences?userId=${userId}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			return Promise.resolve(data);
		} else {
			handleError(res);
			return Promise.resolve(null);
		}
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};

export const saveNotificationPreferences = async (caseId, data) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}notification-preferences`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ caseId: caseId, notificationPreferences: data }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Updated Notification Preferences");
			return Promise.resolve(res);
		} else {
			handleError(res);
			return Promise.resolve(null);
		}
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};

export const deleteCaseImage = async imageId => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}deleteCaseImage/image/${imageId}`,
			{
				method: "DELETE",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		return Promise.resolve(res);
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};

export const orderSubscription = async (order_id, status) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}subscriptionOrder`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ order_id, status }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			message.success(
				`${
					status === "true"
						? "Order marked as subscription"
						: "Subscription removed from order"
				}`
			);
			return "pass";
		} else {
			message.error("failed to update subscription");
			return "fail";
		}
	} catch (error) {
		message.error("failed to update subscription");
		return "fail";
	}
};

export const singleOrder = async (orderId, is_single_order) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}singleOrder/${orderId}`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ is_single_order }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			message.success("Updated Successfully");
			return "pass";
		} else {
			message.error("failed to update");
			return "fail";
		}
	} catch (error) {
		message.error("failed to update");
		return "fail";
	}
};

export const deleteSlot = async booking_id => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}bookings/${booking_id}`, {
			method: "DELETE",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Slot Booking Finished");
		} else {
			handleError(res);
			message.error("Unable to update Slot Booking");
		}
		return res;
	} catch (error) {
		console.info(error.toString());
		message.error("Unable to update Slot Booking");
	}
};

export const resyncOrder = async order_id => {
	let res = "";

	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}order/${order_id}/resync`,
			{
				method: "PUT",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);

		res = _res;
	} catch (error) {
		console.warn(error);
	}

	if (res.status === 200) message.success("Order sync successful");
	else message.error("Something went wrong, please contact administrator");

	return res;
};

export const getConsultCallFeedback = async case_id => {
	store.dispatch({
		type: leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_LOADING,
	});

	try {
		const res = await fetchRequest(CONSULT_CALL_FEEDBACK_API(case_id));
		if (res.hasError) {
			store.dispatch({
				type: leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_FAILURE,
			});
			handleError(res);
		} else {
			store.dispatch({
				type: leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_SUCCESS,
				payload: res.data?.feedback,
			});
		}
	} catch (error) {
		store.dispatch({
			type: leadDetailsActions.GET_CONSULT_CALL_FEEDBACK_FAILURE,
		});
	}
};

export const transferCustomerToMedRep = async data => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}transfer-customers-to-medreps`,
			{
				method: "POST",
				json: true,
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);

		if (res.status === 200) {
			message.success("Customer Transfer Successfully !");
		} else if (res.status !== 422) {
			handleError(res);
			message.error("Failed to Customer Transfer");
		}
		return res;
	} catch (error) {
		message.info(error.toString());
	}
};

export const getTransferCustomerCount = async data => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}get-transfer-customers-count`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			return Promise.resolve(data);
		} else {
			handleError(res);
			return Promise.resolve(null);
		}
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};

export const getCaseTickets = async caseId => {
	console.log("CALLED", caseId);
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}get-case-ticket/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		console.log(res);
		if (res.status === 200) {
			const data = await res.json();
			return Promise.resolve(data);
		} else {
			handleError(res);
			return Promise.resolve(null);
		}
	} catch (error) {
		message.info(error.message.toString() || "Failed to load case tickets");
	}
};

export const getEscalationStatus = async caseId => {
	let res = {};
	try {
		res = await fetchRequest(getUrl(`get-escalations/${caseId}`));
	} catch (error) {
		console.warn(error);
	} finally {
		if (res.hasError) return {};
		else return res.data;
	}
};

export const getFreshChats = async(caseId) => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}freshchat/${caseId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			return Promise.resolve(data);
		} else {
			handleError(res);
			return Promise.resolve(null);
		}
	} catch (error) {
		message.info(error.message.toString() || "Failed to load chats");
	}
}
