import moment from "moment";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { MarkDoneModal, SetReminders } from "../../../../common";
import { getAttemptText } from "../../../../helpers/attemptText";
import Call from "../../../Call";
import MarkDoneEngagement from "../../../markDone/MarkDoneEngagement";
import { RescheduleEngagement } from "../../../RescheduleEngagement";

// Antd
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table, Tag, Tooltip, Typography } from "antd/es";
import { useEffect } from "react";
import { weekAvailable } from "../../../../constants/options";
const { Title } = Typography;

const nameCol = {
	title: "Name",
	key: "name",
	render: data => (
		<Link to={`/lead-details/${data.case_id}`} target="_blank">
			{data.name}
		</Link>
	),
};

const orderCount = {
	title: "Order Count",
	key: "order_count",
	render: count => <>{count.order_count}</>,
};

const dateCol = {
	title: "Date",
	dataIndex: "datetime",
	key: "datetime",
	defaultSortOrder: "descend",
	sorter: (a, b) =>
		new Date(a.datetime).setHours(0, 0, 0, 0) -
		new Date(b.datetime).setHours(0, 0, 0, 0),
	render: datetime => (
		<>
			<div>{moment(datetime).format("DD MMM YYYY, h:mm a")}</div>
		</>
	),
};

const Engagements = ({
	data,
	loading,
	refreshUI,
	title,
	pageNo,
	setPageNo,
	rowCount,
	handleTagFilter,
	tagFilter,
}) => {
	const [caseId, setCaseId] = useState("");
	const [comment, setComment] = useState("");
	const [currDueDate, setCurrDueDate] = useState("");
	const [currEngagement, setcurrEngagement] = useState({});
	const [currReminderDate, setCurrReminderDate] = useState("");
	const [currReminderId, setCurrReminderId] = useState("");
	const [currTag, setCurrTag] = useState("");
	const [markDoneModal, setMarkDoneModal] = useState(false);
	const [rescheduleModal, setRescheduleModal] = useState(false);
	const [userMarkDoneModak, setUserMarkDoneModak] = useState(false);
	const [userReminderModal, setUserReminderModal] = useState(false);

	const tagFilterData = [
		{
			text: "Prescription Call",
			value: "Prescription Call",
		},
		{
			text: "1st Attempt",
			value: "1st Attempt",
		},
		{
			text: "2nd Attempt",
			value: "2nd Attempt",
		},
		{
			text: "3rd Attempt",
			value: "3rd Attempt",
		},
		{
			text: "Slot Booking",
			value: "SLOT_BOOKING",
		},
	];

	useEffect(() => {
		weekAvailable.forEach(val => {
			tagFilterData.splice(1, 0, { text: val.value, value: val.value });
		});
	});

	const tagCol = useMemo(() => {
		const filterTag = localStorage.getItem("filterTag")?.split(",")[0];
		return {
			title: "Tag",
			key: "tag",
			filters: tagFilter && [
				{
					text: "Priority 1",
					value: "Priority 1",
				},
				{
					text: "Priority 2",
					value: "Priority 2",
				},
				{
					text: "Priority 3",
					value: "Priority 3",
				},
				{
					text: "Slot Booking",
					value: "SLOT_BOOKING",
				},
			],
			defaultFilteredValue:
				filterTag && filterTag !== "null" ? [filterTag] : ["Priority 1"],
			filterMode: "tree",
			filterSearch: true,
			onFilter: () => {
				return true;
			},
			render: ({
				tag,
				unanswered_count,
				reschedule_count,
				prev_engagement_completion_date,
				refrence_slot_id,
				priority,
			}) => {
				return (
					<>
						{tag ? (
							<Tag color="geekblue">
								{tag.replaceAll("_", "").toUpperCase()}
							</Tag>
						) : null}
						{priority && <Tag color="geekblue">{priority}</Tag>}
						{refrence_slot_id ? <Tag color="geekblue">SLOT BOOKING</Tag> : null}
						<Tag color="geekblue">{getAttemptText(unanswered_count)}</Tag>
						{prev_engagement_completion_date ? (
							<Tag color="geekblue">
								{moment().diff(moment(prev_engagement_completion_date), "days")}{" "}
								D
							</Tag>
						) : null}
						{reschedule_count && reschedule_count !== null && (
							<Tag color="gold">Rescheduled</Tag>
						)}
					</>
				);
			},
		};
	}, [tagFilter]);

	const callActionCol = useMemo(() => {
		return {
			title: "Action",
			key: "action",
			render: record => {
				if (record.booking_type === "engagement") {
					return (
						<>
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setCaseId(record.case_id);
										setcurrEngagement(record);
										setCurrReminderId(record.key);
										setCurrTag(record.tag);
										setMarkDoneModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Reschedule">
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setCurrDueDate(record.due_date);
										setCurrReminderDate(moment(record.reminder_date));
										setCurrReminderId(record.key);
										setRescheduleModal(true);
										setcurrEngagement(record);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: record.phone_number,
												case_id: record.case_id,
												note: {
													tag: record.tag,
													order_display_id: record.order_display_id,
												},
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				} else if (record.booking_type === "reminder") {
					return (
						<>
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setUserMarkDoneModak(true);
										setCurrReminderId(record.key);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Reschedule">
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setComment(record.comment);
										setCurrReminderDate(moment(record.reminder_date));
										setCurrReminderId(record.key);
										setUserReminderModal(true);
									}}
									type="link"></Button>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: record.phone_number,
												case_id: record.case_id,
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				}
			},
		};
	}, []);

	const engagementsColumn = useMemo(() => {
		return [dateCol, nameCol, orderCount, tagCol, callActionCol];
	}, [callActionCol, tagCol]);

	return (
		<div>
			<Title level={3}>{title}</Title>
			<Table
				columns={engagementsColumn}
				dataSource={data}
				loading={loading}
				pagination={{
					current: pageNo || 1,
					showSizeChanger: false,
					total: rowCount ? rowCount : data?.length,
				}}
				onChange={(e, filters) => {
					setPageNo && setPageNo(e.current);
					if (handleTagFilter) handleTagFilter(filters);
				}}
				rowClassName={({ data_type }) => {
					return data_type === "temporaryEngagement" ||
						data_type === "temporaryReminder"
						? "bg-success-100"
						: "";
				}}
			/>

			<RescheduleEngagement
				dueDate={currDueDate}
				engagement={currEngagement}
				isVisible={rescheduleModal}
				refreshUI={refreshUI}
				reminderDate={currReminderDate}
				reminderId={currReminderId}
				setIsVisible={setRescheduleModal}
			/>

			<MarkDoneEngagement
				caseId={caseId}
				engagement={currEngagement}
				modalVisible={markDoneModal}
				refreshUI={refreshUI}
				reminderId={currReminderId}
				setModalVisible={setMarkDoneModal}
				tag={currTag}
			/>

			<SetReminders
				currComment={comment}
				currDate={currReminderDate}
				modalVisible={userReminderModal}
				reminder_id={currReminderId}
				reminderType="user"
				status="active"
				refreshUI={refreshUI}
				setModalVisible={setUserReminderModal}
				modalType="user"
			/>

			<MarkDoneModal
				modalVisible={userMarkDoneModak}
				reminderId={currReminderId}
				reminderType="user"
				refreshUI={refreshUI}
				setModalVisible={setUserMarkDoneModak}
			/>
		</div>
	);
};

export default Engagements;
