import React, { useState, useEffect, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { escalateCase, getAssignedPersons } from "./service";
import { getComments, getEscalationStatus, viewDetails } from "../../pages/leadDetails/service";
import { message, Select, Space } from "antd";

// Ant Design
import Button from "antd/es/button";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import Row from "antd/es/row";
import { useSelector } from 'react-redux';
import { getValue } from '../../helpers';
const { TextArea } = Input;
const { Option } = Select;

export const EscalateCase = () => {
	const params = useParams();
	const [showModal, setShowModal] = useState(false);
	const [escalationStatus, setEscalationStatus] = useState(null);

	const fetchEscalationStatus = useCallback(async() => {
		const res = await getEscalationStatus(params.id)
		if (res && res[0]) {
			if(res[0].resolved_by) {
				setEscalationStatus('resolved');
			} else {
				setEscalationStatus('escalated');
			}
		} else {
			setEscalationStatus(false);
		}
	}, [params.id]);

	useEffect(() => {
		fetchEscalationStatus(params.id);
	}, [fetchEscalationStatus, params.id]);

	return (
		<>
			<Button
				disabled={escalationStatus === "escalated" ? true : false}
				id="escalate_modal_btn"
				onClick={() => setShowModal(true)}
				type="primary">
				{escalationStatus === "resolved"
					? "Finished"
					: escalationStatus === "escalated"
					? "Escalated"
					: "Escalate"}
			</Button>

			<EscalateModal
				isVisible={showModal}
				setIsVisible={setShowModal}
				escalationStatus={escalationStatus}
				refreshUi={fetchEscalationStatus}
			/>
		</>
	);
};

const EscalateModal = ({ isVisible, setIsVisible, escalationStatus, refreshUi }) => {
	const [comment, setComment] = useState("");
	const [escalatedTo, setEscalatedTo] = useState(null);
	const [assignedPersons, setAssignedPersons] = useState([]);
	const { id: case_id } = useParams();
	const [loading, setLoading] = useState(false);

	const { userDetails } = useSelector(state => ({
		userDetails: getValue(state, "leaddetails.usersDetail"),
	}));

	const handleModalClose = () => {
		setComment("");
		setEscalatedTo(null);
		setIsVisible(false);
	};

	const fetchAssignedPersons = useCallback(async () => {
		if(!isVisible) return;
		let assigned = [];
		assigned.push({ value: 'supervisor', name: 'Supervisor' });

		const res = await getAssignedPersons(userDetails.latest_order_id);
		if (res.hasError) setAssignedPersons([]);
		else if (res && res.data && res.data[0]) {
			const doctors = res.data[0].doctor || [];
			doctors.forEach(doc => assigned.push({ value: doc.user_id, name: doc.user.first_name }));
		}
		setAssignedPersons(assigned);
	}, [userDetails.latest_order_id, isVisible]);

	useEffect(() => {
		fetchAssignedPersons();
	}, [fetchAssignedPersons]);

	const handleEscalation = async () => {
		try {
			setLoading(true);
			const _res = await escalateCase(case_id, escalatedTo, comment);
			setLoading(false);

			if (_res.status === 200) {
				message.success(
					escalationStatus === "resolved"
						? "Escalation Finished Succesfully"
						: "Case escalated successfully"
				);
				handleModalClose();
				await viewDetails(case_id);
				await getComments(case_id);
				refreshUi();
			}
		} catch (err) {
			message.error(
				escalationStatus === "resolved"
					? "Failed to Finish escalation"
					: "Failed to escalate case"
			);
		}
	};

	return (
		<Modal
			visible={isVisible}
			title={
				escalationStatus === "resolved" ? "Finish Escalation" : "Escalation"
			}
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button id="escalate_cancel_btn" key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
				<Button
					disabled={isEmpty(comment.trim()) || isEmpty(escalatedTo)}
					id="escalate_btn"
					key="submit"
					type="primary"
					onClick={() => handleEscalation()}
					loading={loading}>
					{escalationStatus === "resolved" ? "Finish" : "Escalate"}
				</Button>,
			]}>
			<Row>
				<Space direction='vertical' size='small' style={{width: '100%'}}>
					<Select
						showSearch
						placeholder="Select a person"
						optionFilterProp="children"
						onChange={(val) => setEscalatedTo(val)}
						value={escalatedTo}
						style={{ width: '100%'}}
					>
						{assignedPersons.map((person) => <Option key={person.value} value={person.value}>{person.name}</Option>)}
					</Select>
					<TextArea
						id="escalate_comment_box"
						onChange={({ target }) => setComment(target.value)}
						placeholder="Please put your comment here"
						rows={4}
						value={comment}
					/>
				</Space>
			</Row>
		</Modal>
	);
};
