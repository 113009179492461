import actions from "./action";
import { message } from "antd";
import { getUrl } from "../../constants/apiUrls";
import { fetchRequest } from "../../helpers/fetchRequest";
import { store } from "../../redux/store";

export const getMissedCalls = async queryParams => {
	store.dispatch({
		type: actions.GET_MISSED_CALLS_LOADING,
		payload: true,
	});

	try {
		let { data, hasError, status } = await fetchRequest(
			getUrl(`v1.0/exotel/missed_call_data?${queryParams}`)
		);

		if (hasError || data?.err?.message) {
			store.dispatch({
				type: actions.GET_MISSED_CALLS_FAILURE,
				payload: "Something went wrong",
			});
		} else if (status === 200) {
			store.dispatch({
				type: actions.GET_MISSED_CALLS_SUCCESS,
				payload: data,
			});
		}
	} catch (err) {
		if (err)
			store.dispatch({
				type: actions.GET_MISSED_CALLS_FAILURE,
				payload: err.message,
			});
	}
};

export const dismissMissedCall = async (case_id, commentData) => {
	try {
		let { data, hasError, status } = await fetchRequest(
			getUrl(`v1.0/exotel/${case_id}/resolve_call`),
			{ method: "PUT", body: JSON.stringify(commentData) }
		);

		if (hasError || data?.err?.message) {
			message.error("Failed to resolve call");
		} else if (status === 200) {
			return data.result;
		}
	} catch (err) {
		if (err) console.error(err.message);
	}
};
