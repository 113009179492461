import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import { getChats } from "../service";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Space, Tooltip, Typography } from "antd";
const { Text } = Typography;

const ChatBox = () => {
	const params = useParams();
	const chatBody = useRef();

	const { chats, loading } = useSelector(state => ({
		chats: window.getValue(state, "leaddetails.chats.chats"),
		loading: window.getValue(state, "leaddetails.chatsLoading"),
	}));

	useEffect(() => {
		getChats(params.id);
	}, [params.id]);

	const scrollToBottom = () =>
		chatBody.current?.scrollTo({ top: chatBody.current?.scrollHeight });

	useEffect(() => {
		if (!isEmpty(chats)) scrollToBottom();
	}, [chats]);

	return (
		<Row>
			<Col
				direction="vertical"
				span={24}
				style={{
					maxHeight: 320,
					minHeight: 320,
					overflowY: "scroll",
					padding: "1rem",
					width: "100%",
				}}
				ref={chatBody}>
				{isEmpty(chats) ? (
					<Row justify="center">
						<Col>
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</Col>
					</Row>
				) : (
					chats.map(chat => (
						<>
							<Row
								justify={
									chat.chat_meta.message_type === "incoming" ? "start" : "end"
								}>
								<Space
									direction="vertical"
									size={4}
									style={{
										backgroundColor:
											chat.chat_meta.message_type === "incoming"
												? "#F1F1F1"
												: "#D1FAE5",
										borderRadius: 8,
										marginBottom: 8,
										maxWidth: 480,
										minWidth: 160,
										padding: ".5rem 1rem",
									}}>
									<Text type="warning">
										{chat.direction === "outgoing" &&
											chat.representative &&
											chat.representative.first_name}
									</Text>
									<Text>{chat.chat_text}</Text>
									<Text
										type="secondary"
										style={{
											display: "block",
											fontSize: 12,
											textAlign: "end",
										}}>
										{moment(chat.created_at).format("DD-MM-YYYY, hh:mm a")}
									</Text>
								</Space>
							</Row>
						</>
					))
				)}
			</Col>
			<Tooltip title="Refresh">
				<Button
					icon={<SyncOutlined spin={loading} />}
					onClick={() => {
						getChats(params.id);
						scrollToBottom();
					}}
					type="link"
					style={{ position: "absolute", bottom: "0.5rem", left: "0.5rem" }}
				/>
			</Tooltip>
		</Row>
	);
};

export default ChatBox;
