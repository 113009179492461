import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getMedicalReps, assignMedicalRep, getConsultReps } from "../service";
import { Badge, Button, message, Select, Space, Typography } from "antd";
const { Option } = Select;
const { Text } = Typography;

const AssignMedicalRep = ({ selectedOrders, updateUi }) => {
	const [isAssigning, setIsAssigning] = useState(false);
	const [dropdownData, setDropdownData] = useState(null);

	useEffect(() => {
		getMedicalReps();
		getConsultReps();
	}, []);

	const { consultReps, medicalReps, loading } = useSelector(state => ({
		consultReps: window.getValue(state, "orderManagement.consultReps.userRole"),
		medicalReps: window.getValue(state, "orderManagement.medicalReps.userRole"),
		loading: window.getValue(state, "orderManagement.medicalRepsLoading"),
	}));

	const handleAssignMedicalRep = async () => {
		setIsAssigning(true);
		if (!isEmpty(selectedOrders)) {
			await assignCases();
			setDropdownData(null);
			updateUi();
		}
		setIsAssigning(false);
	};

	const assignCases = async () => {
		for (let i = 0; i < selectedOrders.length; i++) {
			const { case_id, key } = selectedOrders[i];
			const _res = await assignMedicalRep(dropdownData, case_id, key);

			if (_res.status === 200) continue;
			if (_res.status === 400) {
				message.warn(
					"One or more cases could not be assigned due to no available slots. The page will refresh with the latest data."
				);
				return;
			}

			message.error("Unable to assign");
			return;
		}

		message.success("Assigned Successfully");
	};

	return (
		<>
			<Select
				allowClear
				loading={loading}
				onChange={selectedRowKeys => setDropdownData(selectedRowKeys)}
				optionFilterProp="label"
				placeholder="Select a person"
				showSearch
				style={{ width: 240 }}
				value={dropdownData}>
				{!isEmpty(medicalReps) &&
					medicalReps.map(rep => (
						<Option
							label={rep.user.first_name}
							value={rep.user.id}
							key={rep.user.id}>
							<Space align="center">
								<Text>{rep.user.first_name}</Text>
								<Badge
									count="Medical Rep"
									style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
								/>
							</Space>
						</Option>
					))}

				{!isEmpty(consultReps) &&
					consultReps.map(rep => (
						<Option
							label={rep.user.first_name}
							value={rep.user.id}
							key={rep.user.id}>
							<Space align="center">
								<Text>{rep.user.first_name}</Text>
								<Badge
									count="Consult Rep"
									style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
								/>
							</Space>
						</Option>
					))}
			</Select>

			<Button
				disabled={isEmpty(dropdownData) || isEmpty(selectedOrders)}
				loading={isAssigning}
				onClick={handleAssignMedicalRep}
				type="primary">
				Assign
			</Button>
		</>
	);
};

export default AssignMedicalRep;
