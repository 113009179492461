import { globalSearchActions } from "../actions/globalSearch";

export const globalSearch = (state = {}, action) => {
    switch(action.type) {
      case globalSearchActions.GET_GLOBAL_SEARCH_LOADING:
        return {
          ...state,
          globalSearchLoading: true
        };
      case globalSearchActions.GET_GLOBAL_SEARCH_SUCCESS:
        return {
          ...state,
          globalSearchData: action.payload,
          globalSearchLoading: false
        };
      case globalSearchActions.GET_GLOBAL_SEARCH_FAILURE:
        return {
          ...state,
          globalSearchData: null,
          globalSearchLoading: false
        };
      default:
        return state;
    }
}