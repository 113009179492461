import React, { useEffect, useState } from "react";
import moment from "moment";
import { generateQuery, getQueryParams } from "../../helpers";
import { Button, DatePicker, Form, Select, Space } from "antd";
import { codOrderTypeOptions } from "../../constants/options";

const optionsStatus = [
	{ label: "Delivered", value: "delivered" },
	{ label: "In Progress", value: "in_progress" },
	{ label: "Pending", value: "pending" },
	{ label: "Pending Prescription", value: "pending_prescription" },
	{ label: "Shipped", value: "shipped" },
	{ label: "Shipping Pending", value: "shipping_pending" },
	{ label: "Unknown", value: "unknown" },
	{ label: "Void", value: "void" },
];

const dateFormat = "YYYY-MM-DD";

const Filters = ({ setPageNo, setQueryParams }) => {
	const [form] = Form.useForm();

	const [startDate, setStartDate] = useState(getQueryParams("startDate"));
	const [endDate, setEndDate] = useState(getQueryParams("endDate"));
	const [orderType, setOrderType] = useState(getQueryParams("type"));
	const [status, setStatus] = useState(getQueryParams("status"));

	const formInitialValues = {
		startDate: startDate ? moment(startDate) : moment(),
		endDate: endDate ? moment(endDate) : moment(),
		status: status || null,
		type: orderType || null,
	};

	useEffect(() => {
		form.setFieldsValue(formInitialValues);
	}, [form, formInitialValues]);

	const onSubmit = items => {
		const filterItems = [];

		Object.entries(items).forEach(([key, value]) => {
			switch (key) {
				case "startDate":
					filterItems.push({
						[key]: startDate
							? moment(startDate).format(dateFormat)
							: moment().format(dateFormat),
					});
					break;

				case "endDate":
					filterItems.push({
						[key]: endDate
							? moment(endDate).format(dateFormat)
							: moment().format(dateFormat),
					});
					break;

				default:
					filterItems.push({ [key]: value });
			}

			return filterItems;
		});

		filterItems.push({ pageNumber: 1 });
		const queryParams = generateQuery(filterItems);
		setQueryParams(queryParams);
		setPageNo(1);
	};

	const handleClear = () => {
		form.resetFields();
		setStatus(null);
		setStartDate(moment());
		setEndDate(moment());
		setOrderType(null);
		form.submit();
	};

	return (
		<Form
			form={form}
			initialValues={formInitialValues}
			layout="inline"
			onFinish={onSubmit}>
			<Form.Item name="startDate">
				<DatePicker
					allowClear
					disabledDate={current =>
						endDate
							? current.isAfter(moment(endDate), "day")
							: current.isAfter(moment(), "day")
					}
					format={"YYYY-MM-DD"}
					onChange={e => (e ? setStartDate(e) : setStartDate(moment()))}
					placeholder="Start Date"
				/>
			</Form.Item>

			<Form.Item name="endDate">
				<DatePicker
					allowClear
					disabledDate={current =>
						(startDate && current.isBefore(moment(startDate), "day")) ||
						current.isAfter(moment(), "day")
					}
					format={"YYYY-MM-DD"}
					onChange={e => (e ? setEndDate(e) : setEndDate(moment()))}
					placeholder="End Date"
				/>
			</Form.Item>

			<Form.Item name="status">
				<Select
					allowClear
					dropdownStyle={{ minWidth: 160 }}
					options={optionsStatus}
					onChange={e => (e ? setStatus(e) : setStatus(null))}
					placeholder="Order Status"
				/>
			</Form.Item>

			<Form.Item name="type">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "14rem" }}
					options={codOrderTypeOptions}
					onChange={e => (e ? setOrderType(e) : setOrderType(null))}
					placeholder="Order Type"
					style={{ maxWidth: "8rem" }}
				/>
			</Form.Item>

			<Form.Item>
				<Space>
					<Button type="primary" htmlType="submit">
						Apply
					</Button>
					<Button htmlType="button" onClick={handleClear}>
						Clear
					</Button>
				</Space>
			</Form.Item>
		</Form>
	);
};

export default Filters;
