import Cookies from "js-cookie";
import { supervisorActions } from "./actions/supervisorActions";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";
import { message } from "antd";

export const getGroups = async () => {
  store.dispatch({
    type: supervisorActions.GET_GROUPS_LOADING,
    payload: true,
  });

  try {
    const res = await fetch(`${baseURL.API_URL_BASE}groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      store.dispatch({
        type: supervisorActions.GET_GROUPS_SUCCESS,
        payload: data,
      });
    } else {
      handleError(res);
      store.dispatch({
        type: supervisorActions.GET_GROUPS_FAILURE,
        payload: null,
      });
    }
  } catch (error) {
    store.dispatch({
      type: supervisorActions.GET_GROUPS_FAILURE,
      payload: null,
    });
  }
};

export const createGroup = async (group_name, supervisor_id) => {
  try {
    const res = await fetch(`${baseURL.API_URL_BASE}group`, {
      method: "POST",
      json: true,
      body: JSON.stringify({
        name: group_name,
        userId: supervisor_id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    if (res.status === 200) {
      message.success(`Group ${group_name} created`);
      await getGroups();
      return true;
    } else {
      const resJson = await res.json();
      message.error(resJson.message);
      return false;
    }
  } catch (error) {
    console.info(error.message);
  }
};

export const deleteGroup = async group_id => {
  try {
    const res = await fetch(`${baseURL.API_URL_BASE}group/${group_id}`, {
      method: "DELETE",
      json: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });

    if (res.status === 200) {
      message.success(`Group deleted`);
      await getGroups();
      return true;
    } else {
      const resJson = await res.json();
      message.error(resJson.message);
    }
  } catch (error) {
    message.info(error.message);
  }
};

export const getGroupDetails = async id => {
  store.dispatch({
    type: supervisorActions.GET_GROUP_DETAILS_LOADING,
    payload: true,
  });

  try {
    const res = await fetch(`${baseURL.API_URL_BASE}group/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      store.dispatch({
        type: supervisorActions.GET_GROUP_DETAILS_SUCCESS,
        payload: data,
      });
    } else {
      handleError(res);
      store.dispatch({
        type: supervisorActions.GET_GROUP_DETAILS_FAILURE,
        payload: null,
      });
    }
  } catch (error) {
    store.dispatch({
      type: supervisorActions.GET_GROUP_DETAILS_FAILURE,
      payload: null,
    });
  }
};

export const addUserToGroup = async (group_id, user_id) => {
  try {
    const res = await fetch(
      `${baseURL.API_URL_BASE}group/${group_id}/addUser`,
      {
        method: "POST",
        json: true,
        body: JSON.stringify({ userId: user_id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      message.success(`user added to group`);
      await getGroupDetails(group_id);
      return true;
    } else {
      const resJson = await res.json();
      message.error(resJson.message);
      return false;
    }
  } catch (error) {
    console.info(error.message);
  }
};

export const removeUserFromGroup = async (group_id, user_id) => {
  try {
    const res = await fetch(
      `${baseURL.API_URL_BASE}group/${group_id}/removeUser`,
      {
        method: "DELETE",
        json: true,
        body: JSON.stringify({ userId: user_id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      message.success(`user removed from group`);
      await getGroupDetails(group_id);
      return true;
    } else {
      const resJson = await res.json();
      message.error(resJson.message);
      return false;
    }
  } catch (error) {
    console.info(error.message);
  }
};

export const getMyTeam = async () => {
  store.dispatch({
    type: supervisorActions.GET_MY_TEAMS_LOADING,
    payload: true,
  });

  try {
    const res = await fetch(`${baseURL.API_URL_BASE}myTeam`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    if (res.status === 200) {
      const data = await res.json();
      store.dispatch({
        type: supervisorActions.GET_MY_TEAMS_SUCCESS,
        payload: data,
      });
    } else {
      handleError(res);
      store.dispatch({
        type: supervisorActions.GET_MY_TEAMS_FAILURE,
        payload: null,
      });
    }
  } catch (error) {
    store.dispatch({
      type: supervisorActions.GET_MY_TEAMS_FAILURE,
      payload: null,
    });
  }
};
