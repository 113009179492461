import { SyncOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Row, Space, Tooltip, Typography } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFreshChats } from '../service';
const { Text } = Typography;

const FreshchatBox = ({ caseId }) => {
	const params = useParams();
	const chatBody = useRef();
	const [chats, setChats] = useState();
	const [loading, setLoading] = useState(false);

	const _getFreshChats = useCallback(async () => {
		setLoading(true);
		const _caseId = params.id ? params.id : caseId;
		const _chats = await getFreshChats(_caseId);
		if (_chats) {
			const _reversed = _chats.reverse()
			setChats(_reversed);
		}
		setLoading(false);
	}, [caseId, params.id]);

	useEffect(() => {
		_getFreshChats();
	}, [_getFreshChats]);

	const scrollToBottom = () =>
		chatBody.current?.scrollTo({ top: chatBody.current?.scrollHeight });

	useEffect(() => {
		if (!isEmpty(chats)) scrollToBottom();
	}, [chats]);

	return (
		<Row style={{ width: '100%', height: '100%' }}>
			<Col
				direction="vertical"
				span={24}
				style={{
					// maxHeight: 250,
					// minHeight: 210,
					height: '340px',
					overflowY: 'scroll',
					padding: '1rem',
					width: '100%',
				}}
				ref={chatBody}>
				{isEmpty(chats) ? (
					<Row justify="center">
						<Col>
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</Col>
					</Row>
				) : (
					chats.map(chat => {
						return (
							<>
								<Row justify={chat.direction === 'incoming' ? 'start' : 'end'}>
									<Space
										direction="vertical"
										size={4}
										style={{
											backgroundColor:
												chat.direction === 'incoming' ? '#F1F1F1' : '#D1FAE5',
											borderRadius: 8,
											marginBottom: 8,
											maxWidth: 480,
											minWidth: 160,
											padding: '.5rem 1rem',
										}}>
										<Row justify="space-between" align='center'>
											<Text type="warning">
												{chat.direction === 'outgoing' &&
													chat.chat_meta?.actor?.type}
												{chat.direction === 'template' &&
													chat.chat_meta?.actor?.name}
											</Text>
											<Text
												type="secondary"
												style={{
													fontSize: 12,
												}}>
											{moment(chat.createdAt).format('DD-MM-YYYY, hh:mm a')}
										</Text>
										</Row>
										{chat.chat_text !== '' ? (
											<Text>{chat.chat_text}</Text>
										) : (
											<Text type="secondary">
												<i>Empty</i>
											</Text>
										)}
									</Space>
								</Row>
							</>
						);
					})
				)}
			</Col>
			<Tooltip title="Refresh">
				<Button
					icon={<SyncOutlined spin={loading} />}
					onClick={() => {
						_getFreshChats();
						scrollToBottom();
					}}
					type="link"
					style={{ position: 'absolute', bottom: '2rem', left: '0.5rem' }}
				/>
			</Tooltip>
		</Row>
	);
};

export default FreshchatBox;
