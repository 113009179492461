import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
	baseURL,
	getCurrentUserRole,
	getLineItemsName,
	getValue,
	handleError,
} from "../../../helpers";
import { Prescription } from "../../doctors/components";
import { AssignDoctor, EngagementsByOrder, ShipOrders } from "../components";

import {
	getAssignedDoctors,
	getDoctorsList,
	getPrescription,
	preAssignDoctor,
} from "../../doctors/service";

import { getEngagementsByCase } from "../../orderManagement/service";

import {
	bulkOrder,
	getComments,
	markDelivered,
	orderSubscription,
	shippingPending,
	singleOrder,
	viewDetails,
	voidOrder,
} from "../service";

import { ASSIGNED_DOCTORS } from "../../../constants/apiUrls";
import {
	codOrderTypeOptions,
	regularInregularOptions,
	startedNotStartedOptions,
} from "../../../constants/options";
import { getSubscriptionPlatform } from "../../../constants/orderSubscriptionPlatforms";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { ResyncOrder } from "./ResyncOrder";

import {
	CheckCircleOutlined,
	CheckOutlined,
	ExceptionOutlined,
	FormOutlined,
	InfoCircleOutlined,
	MessageOutlined,
	SmileOutlined,
	RocketOutlined,
	SafetyOutlined,
	ScheduleOutlined,
	ShoppingOutlined,
	UserAddOutlined,
	PlayCircleOutlined
} from "@ant-design/icons";

import {
	Button,
	Col,
	DatePicker,
	message,
	Modal,
	Popconfirm,
	Popover,
	Row,
	Select,
	Space,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
const { Text } = Typography;

const srCountCol = {
	title: "Sr No.",
	dataIndex: "count",
	key: "count",
	width: 80,
};

const orderIdCol = {
	title: "Order Id",
	key: "order_id",
	width: 200,
	render: ({
		bulk_order,
		duration,
		is_subscription,
		order_id,
		is_single_order,
		is_combo,
		order_type,
		order_meta,
	}) => {
		let subscriptionPlatform = "";
		if (is_subscription) {
			subscriptionPlatform = getSubscriptionPlatform(order_meta);
			subscriptionPlatform = subscriptionPlatform
				? `(${subscriptionPlatform})`
				: "";
		}

		return (
			<Space direction="vertical">
				<Text>{order_id}</Text>
				{order_type && (
					<Tooltip title="Order Type" placement="right">
						<Tag className="capitalize" color="green">
							{order_type.replaceAll("_", " ")}
						</Tag>
					</Tooltip>
				)}
				{is_subscription && (
					<Tag color="green">Subscription Order {subscriptionPlatform}</Tag>
				)}
				{bulk_order && <Tag color="green">Bulk Order ({duration} months)</Tag>}
				{is_single_order && <Tag color="green">Single Order</Tag>}
				{is_combo && <Tag color="green">Combo Order</Tag>}
			</Space>
		);
	},
};

const orderNamecol = {
	title: "Order Name",
	dataIndex: "order_name",
	key: "order_name",
};

const orderDatecol = {
	title: "Order Date",
	dataIndex: "order_date",
	key: "order date",
	defaultSortOrder: "descend",
	render: order_date => moment(order_date).format("DD MMM YYYY, h:mm a"),
	sorter: (a, b) => moment(a.order_date) - moment(b.order_date),
};

const statusCol = {
	title: "Status",
	dataIndex: "status",
	key: "status",
	render: (status, record) => (
		<Space direction="vertical">
			<Row>
				<Tag key={status} color="geekblue">
					{status && status.toUpperCase()} {""}
					{status === "delivered" &&
						!isEmpty(record.deliveryDate) &&
						moment(record.deliveryDate).format("DD-MMM-YYYY")}
				</Tag>
				<Popover placement="top" content={content(record)} trigger="click">
					<InfoCircleOutlined />
				</Popover>
			</Row>
			<Row align="middle">
				{record.warehouses && (
					<Tag color={"gold"}>{record.warehouses.name}</Tag>
				)}
			</Row>
		</Space>
	),
};
const content = record => {
	return (
		<div>
			<p>
				<strong>Tracking ID</strong>:{" "}
				{record.tracking_id !== null ? record.tracking_id : "None"}
			</p>
			<p>
				<strong>Tracking URL</strong>:{" "}
				{record.tracking_url !== null ? record.tracking_url : "None"}
			</p>
		</div>
	);
};

const OrderDetails = () => {
	const history = useHistory();
	const { id: case_id } = useParams();
	const [assignedDocListModal, setAssignedDocListModal] = useState(false);
	const [writePrescriptionModal, setWritePrescriptionModal] = useState(false);
	const [engagementModal, setEngagementModal] = useState(false);
	const [userRole] = useState(getCurrentUserRole());
	const [orderId, setOrderId] = useState("");
	const [orderName, setOrderName] = useState("");
	const [orderStatus, setOrderStatus] = useState("");
	const [duration, setDuration] = useState(undefined);
	const [bulkOrderId, setBulkOrderId] = useState("");
	const [orderType, setOrderType] = useState(null);
	const [showOrderTypeContent, setShowOrderTypeContent] = useState(null);
	const [showRegularInRegularContent, setShowRegularInRegularContent] =
		useState(null);
		const [showStartedNotStartedContent, setShowStartedNotStartedContent] =
		useState(null);
	const [updatingType, setUpdatingType] = useState(false);
	const [shipOrderModal, setShipOrderModal] = useState(false);
	const [userLevel] = useState(localStorage.getItem("rep!level"));

	const {
		orderDetails,
		detailsLoading,
		prescriptions,
		updatingAssignedDoctor,
		updatingPrescription,
	} = useSelector(state => ({
		orderDetails: getValue(state, "leaddetails.usersDetail.orders"),
		detailsLoading: getValue(state, "leaddetails.usersDetailLoading"),
		prescriptions: getValue(state, "doctorsData.prescriptions"),
		updatingAssignedDoctor: getValue(
			state,
			"doctorsData.updatingAssignedDoctor"
		),
		updatingPrescription: getValue(state, "doctorsData.updatingPrescription"),
	}));

	useEffect(() => {
		if (!isEmpty(orderId)) getAssignedDoctors(orderId);
		if (showDoctorsList.has(userRole)) getDoctorsList();
	}, [orderId, updatingAssignedDoctor, userRole]);

	useEffect(() => {
		getPrescription(case_id);
	}, [case_id, updatingPrescription]);

	const data = useMemo(() => {
		if (isEmpty(orderDetails)) return [];
		return orderDetails.map(row => ({
			key: getValue(row, "id"),
			order_id: getValue(row, "order_display_id"),
			order_date: getValue(row, "created_at"),
			order_name: getLineItemsName(row.order_meta?.line_items),
			line_items: getValue(row, "order_meta.line_items"),
			status: getValue(row, "status"),
			doctor: getValue(row, "doctor"),
			bulk_order: getValue(row, "is_bulk_order"),
			duration: getValue(row, "bulk_order_duration"),
			deliveryDate: getValue(row, "delivery_date"),
			is_subscription: getValue(row, "is_subscription_order"),
			is_single_order: getValue(row, "is_single_order"),
			is_combo: getValue(row, "is_combo"),
			count: getValue(row, "count"),
			order_type: getValue(row, "order_type"),
			warehouses: getValue(row, "order_warehouse.warehouses"),
			tracking_id: getValue(row, "tracking_id"),
			tracking_url: getValue(row, "tracking_url"),
			order_meta: getValue(row, "order_meta"),
			engagementRegularity: getValue(row, "engagement_regularity"),
			isStarted: getValue(row, "is_started"),
		}));
	}, [orderDetails]);

	const voidOrderStatus = async key => {
		const response = await voidOrder(key);
		const responseMessage = await response.json();

		if (response.status === 200) {
			message.success(responseMessage);
			getComments(case_id);
			viewDetails(case_id);
		} else if (response.status === 400) {
			message.error(responseMessage.message);
		} else {
			message.error(responseMessage.message);
			handleError(response);
		}
	};

	const [loading, setLoading] = useState(false);
	const Confirm = order_id => {
		MarkShippingPending(order_id);
	};

	const MarkShippingPending = async (order_id, delivery_date) => {
		const response = await shippingPending(order_id, delivery_date);
		if (response.status === "shipping_pending") {
			message.success("status changed to shipping pending");
			viewDetails(case_id);
		} else {
			message.error("Failed to change status");
		}
	};

	const DeliveryConfirm = order_id => {
		const data = { delivery_date: deliveryDate.toISOString() };
		MarkDelivered(order_id, data);
	};

	const MarkDelivered = async (order_id, data) => {
		setLoading(true);
		const response = await markDelivered(order_id, data);
		if (response.status === 200) {
			setLoading(false);
			message.success("Status changed to Delivered");
			viewDetails(case_id);
			handleCancel();
		} else {
			setLoading(false);
			message.error("Failed to change status");
		}
	};

	// const sendMessage = async msg => {
	// 	const res = await postChat(case_id, msg);
	// 	if (res.status === 200) getChats(case_id);
	// };

	const hasPrescription = order_id =>
		isEmpty(prescriptions)
			? false
			: prescriptions.some(prescription => prescription.order_id === order_id);

	const canSendToDispatch = (status, order_id) => {
		if (
			status === "delivered" ||
			status === "shipping_pending" ||
			status === "shipped" ||
			status === "void" ||
			!hasPrescription(order_id)
		)
			return false;
		else return true;
	};

	const canMarkShipped = status => {
		if (status === "delivered") return false;
		return true;
	};

	const orderTypeContent = (id, type) => (
		<>
			<Select
				placeholder="Order Type"
				options={codOrderTypeOptions}
				style={{ width: "10rem" }}
				dropdownStyle={{ width: "10rem" }}
				value={orderType}
				onChange={e => setOrderType(e)}
			/>
			<Button
				disabled={isEmpty(orderType) || orderType === type}
				loading={updatingType}
				onClick={() => handleOrderType(id)}>
				Save
			</Button>
		</>
	);

	function RegularInRegularContent({ id }) {
		const [selectValue, setSelectValue] = useState();
	
		useEffect(() => {			
			const myData = data.find(el => el.key === id);
			if (!myData || myData === {}) return;
			if(myData.engagementRegularity !== 'na') {
				setSelectValue(myData.engagementRegularity);
			}
		}, [id]);
	
		return (
			<>
				<Select
					placeholder="Regular/Irregular"
					options={regularInregularOptions}
					style={{ width: "10rem" }}
					dropdownStyle={{ width: "10rem" }}
					value={selectValue}
					onChange={e => setSelectValue(e)}
				/>
				<Button
					disabled={isEmpty(selectValue)}
					loading={updatingType}
					onClick={() => handleRegularInRegularChange(id, selectValue)}>
					Save
				</Button>
			</>
		)
	};

	function StartedNotStartedContent ({id}) {
		const [selectValue, setSelectValue] = useState();

		useEffect(() => {
			const myData = data.find(el => el.key === id);
			if (!myData || myData === {}) return;
			if(myData.isStarted === true) {
				setSelectValue('I have started');
			} else if (myData.isStarted === false) {
				setSelectValue('I have not started');
			}
		}, [id]);

		return (
			<>
				<Select
					placeholder="Started/NotStarted"
					options={startedNotStartedOptions}
					style={{ width: "10rem" }}
					dropdownStyle={{ width: "10rem" }}
					value={selectValue}
					onChange={e => setSelectValue(e)}
				/>
				<Button
					disabled={isEmpty(selectValue)}
					loading={updatingType}
					onClick={() => handleStartedNotStartedChange(id, selectValue)}>
					Save
				</Button>
			</>
		)
	};

	const handleStartedNotStartedChange = async (id, response) => {
		let _res = {};
		try {
			setUpdatingType(true);

			_res = await fetchRequest(`${baseURL.API_URL_BASE}is_order_started`, {
				method: "PUT",
				body: JSON.stringify({ order_id: id, response: response }),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError) message.error("Failed to update");
			else {
				message.success("Updated Successfully");
				setShowStartedNotStartedContent(null);
				viewDetails(case_id);
			}
			setUpdatingType(false);
		}
	};

	const handleRegularInRegularChange = async (id, response) => {
		let _res = {};
		try {
			setUpdatingType(true);

			_res = await fetchRequest(`${baseURL.API_URL_BASE}order/${id}/label/`, {
				method: "PUT",
				body: JSON.stringify({ label: response }),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError) message.error("Failed to update");
			else {
				message.success("Updated Successfully");
				setShowRegularInRegularContent(null);
				viewDetails(case_id);
			}
			setUpdatingType(false);
		}
	};

	const handleOrderType = async id => {
		let _res = {};
		try {
			setUpdatingType(true);

			_res = await fetchRequest(`${baseURL.API_URL_BASE}order-type/${id}`, {
				method: "PUT",
				body: JSON.stringify({ order_type: orderType }),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError) message.error("Failed to update order");
			else {
				message.success("Order type updated");
				setShowOrderTypeContent(null);
				viewDetails(case_id);
			}
			setUpdatingType(false);
		}
	};

	const adminLvl2Columns = [
		srCountCol,
		orderIdCol,
		orderNamecol,
		orderDatecol,
		statusCol,
	];
	const adminColumns = [
		srCountCol,
		orderIdCol,
		orderNamecol,
		orderDatecol,
		statusCol,
		{
			title: "Action",
			key: "action",
			render: record => {
				const shipMark = canMarkShipped(record.status);
				const ncanSendToDispatch = canSendToDispatch(record.status, record.key);
				const sendToDispatchTooltipText = hasPrescription(record.key)
					? "Send to dispatch"
					: "No prescription";
				const disableMarkVoid =
					record.status === "delivered" ||
					record.status === "shipped" ||
					record.status === "void" ||
					record.status === "unknown";

				const singleOrderTooltipText = record.is_single_order
					? "Unmark as Single Order"
					: "Mark as Single Order";

				const disableSingleOrderBtn =
					record.status === "delivered" || record.status === "void";

				return (
					<>
						<Tooltip title="Void Order">
							<Popconfirm
								disabled={disableMarkVoid}
								title="Are you sure you want to void this order?"
								placement="topLeft"
								onConfirm={() => voidOrderStatus(record.key)}>
								<Button
									disabled={disableMarkVoid}
									icon={<ExceptionOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>

						<Tooltip title="Assigned Doctors">
							<Button
								icon={<UserAddOutlined />}
								onClick={() => handleAssignDoctors(record)}
								type="link"></Button>
						</Tooltip>

						<Tooltip title="Manage Prescriptions">
							<Button
								icon={<FormOutlined />}
								onClick={() =>
									history.push({
										pathname: `/prescription/${record.key}`,
										state: {
											caseId: case_id,
											order: record,
										},
									})
								}
								type="link"
							/>
						</Tooltip>
						<Tooltip placement="bottom" title={sendToDispatchTooltipText}>
							<Popconfirm
								disabled={!ncanSendToDispatch}
								title="Are you sure you want to mark this order for shipping? Note: This will cause the order to be sent to dispatch."
								placement="topLeft"
								onConfirm={() => Confirm(record.key)}>
								<Button
									disabled={!ncanSendToDispatch}
									icon={<CheckOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>
						<Tooltip placement="bottom" title={"Mark as delivered"}>
							<Button
								disabled={record.status === "delivered"}
								type="link"
								icon={<ShoppingOutlined />}
								onClick={() => showModal(record)}
							/>
						</Tooltip>
						<Modal
							onCancel={handleCancel}
							title="Select Delivery Date"
							visible={isModalVisible}
							footer={null}>
							<Row justify="center">
								<Space direction="vertical" align="center">
									<Row align="middle" justify="center">
										<Col>
											<DatePicker
												size="large"
												disabledDate={current =>
													orderDate
														? current.isBefore(moment(orderDate), "day")
														: current.isBefore(moment(), "day")
												}
												format={"DD MMM YYYY"}
												onChange={date => setDeliveryDate(date)}
												showNow={false}
												showTime={false}
												value={!isEmpty(deliveryDate) ? deliveryDate : null}
											/>
										</Col>
									</Row>
									<Row align="middle" justify="center">
										<Col>
											<Popconfirm
												disabled={deliveryDate === ""}
												title="Please confirm that you want to mark this order as delivered. This will generate engagements schedules as per the delivery date selected. This process is not reversable"
												placement="bottom"
												onConfirm={() => DeliveryConfirm(order_Id)}>
												<Button
													loading={loading}
													type="primary"
													disabled={deliveryDate === ""}>
													{" "}
													Set Delivery Date
												</Button>
											</Popconfirm>
										</Col>
									</Row>
								</Space>
							</Row>
						</Modal>

						<Tooltip title="Engagements">
							<Button
								type="link"
								icon={<MessageOutlined />}
								onClick={() => {
									setOrderId(record.key);
									setOrderName(record.order_name);
									setEngagementModal(true);
								}}
							/>
						</Tooltip>

						<Tooltip placement="bottom" title={singleOrderTooltipText}>
							<Popconfirm
								disabled={disableSingleOrderBtn}
								title={
									<Text>
										Are you sure you want to{" "}
										{record.is_single_order ? (
											<Text type="danger">unmark</Text>
										) : (
											<Text type="success">mark</Text>
										)}{" "}
										this order as single order?
									</Text>
								}
								placement="topLeft"
								onConfirm={() =>
									handleSingleOrder(record.key, record.is_single_order)
								}>
								<Button
									disabled={disableSingleOrderBtn}
									icon={<SafetyOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>

						<Tooltip placement="bottom" title="Order Type">
							{/* {canNotUpdateOrderType.has(record.status) ? (
								<Button disabled type="link" icon={<CheckCircleOutlined />} />
							) : ( */}
							<Popover
								content={orderTypeContent(record.key, record.order_type)}
								placement="left"
								trigger="click"
								visible={record.key === showOrderTypeContent}
								onVisibleChange={show => {
									setShowOrderTypeContent(show ? record.key : null);
									if (orderType !== record.order_type) {
										setOrderType(record.order_type);
									}
								}}>
								<Button type="link" icon={<CheckCircleOutlined />} />
							</Popover>
							{/* )} */}
						</Tooltip>

						<ResyncOrder order_id={record.key} status={record.status} />

						<Tooltip title="Mark Shipped" placement="bottom">
							<Popconfirm
								disabled={!shipMark}
								title="Are you sure you want to mark this order shipped?"
								placement="topLeft"
								onConfirm={() => {
									setOrderId(record.key);
									setShipOrderModal(true);
								}}>
								<Button
									disabled={!shipMark}
									icon={<RocketOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>

						{/* Lime Chat Button */}
						{/* {isConsultCallDone && record.status === "shipped" && (
              <Tooltip title="Send shipping status">
                <Popconfirm
                  title="Are you sure you want to send shipping status on whatsApp"
                  placement="topRight"
                  onConfirm={() => {
                    const msg = `Your order ${record.order_id} has been shipped`;
                    sendMessage(msg);
                  }}
                >
                  <Button icon={<WhatsAppOutlined />} type="link" />
                </Popconfirm>
              </Tooltip>
            )} */}
					</>
				);
			},
		},
	];

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [deliveryDate, setDeliveryDate] = useState("");
	const [order_Id, setOrder_Id] = useState("");
	const [orderDate, setOrderDate] = useState(null);

	const showModal = record => {
		setOrder_Id(record.key);
		setOrderDate(record.order_date);
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setDeliveryDate("");
		setIsModalVisible(false);
		setOrder_Id("");
	};

	const docColumns = [
		srCountCol,
		{
			title: "Order Id",
			dataIndex: "order_id",
			key: "order_id",
		},
		orderNamecol,
		orderDatecol,
		statusCol,
		{
			title: "Actions",
			key: "actions",
			render: record => {
				const isAssigned = record.doctor.some(
					doc => doc.user_id === localStorage.getItem("users")
				);
				return (
					<Tooltip title="Manage Prescription">
						<Button
							disabled={!isAssigned}
							type="link"
							icon={<FormOutlined />}
							onClick={() => {
								setOrderId(record.key);
								setOrderStatus(record.status);
								setWritePrescriptionModal(true);
								history.push({
									state: {
										caseId: case_id,
										order: record,
									},
								});
							}}></Button>
					</Tooltip>
				);
			},
		},
	];

	const salesRepColumns = [
		srCountCol,
		orderIdCol,
		orderNamecol,
		orderDatecol,
		statusCol,
		{
			title: "Actions",
			key: "actions",
			render: record => {
				const subscriptionTooltipText = record.is_subscription
					? "Remove as Subscription Order"
					: "Mark as Subscription Order";

				const disableMarkBulk =
					record.status === "delivered" || record.status === "void";

				return (
					<>
						<Tooltip placement="bottom" title="Mark as Bulk Order">
							<Popover
								content={content}
								title="Bulk Order"
								trigger="click"
								disabled={record?.bulk_order || disableMarkBulk}
								onVisibleChange={e => handleVisibleChange(e)}>
								<Button
									disabled={record.bulk_order || disableMarkBulk}
									type="link"
									icon={<CheckCircleOutlined />}
									onClick={() => setBulkOrderId(record.key)}
								/>
							</Popover>
						</Tooltip>

						<Tooltip placement="bottom" title={subscriptionTooltipText}>
							<Popconfirm
								title={
									<Text>
										Are you sure you want to{" "}
										<Text
											strong
											type={record.is_subscription ? "danger" : "success"}>
											{record.is_subscription
												? "remove subscription for this order"
												: "mark this order as a subscription"}
										</Text>
										?
									</Text>
								}
								placement="topLeft"
								onConfirm={() =>
									handleSubscription(record.key, record.is_subscription)
								}>
								<Button icon={<ScheduleOutlined />} type="link" />
							</Popconfirm>
						</Tooltip>
					</>
				);
			},
		},
	];

	const supportColumns = [
		srCountCol,
		orderIdCol,
		orderNamecol,
		orderDatecol,
		statusCol,
		{
			title: "Actions",
			key: "actions",
			render: record => (
				<>
					<Tooltip title="Assigned Doctors">
						<Button
							icon={<UserAddOutlined />}
							onClick={() => {
								setAssignedDocListModal(true);
								setOrderId(record.key);
								setOrderStatus(record.status);
							}}
							type="link"></Button>
					</Tooltip>

					<Tooltip title="Manage Prescriptions">
						<Button
							icon={<FormOutlined />}
							onClick={() =>
								history.push({
									pathname: `/prescription/${record.key}`,
									state: {
										caseId: case_id,
										order: record,
									},
								})
							}
							type="link"
						/>
					</Tooltip>

					<Tooltip title="Engagements">
						<Button
							type="link"
							icon={<MessageOutlined />}
							onClick={() => {
								setOrderId(record.key);
								setOrderName(record.order_name);
								setEngagementModal(true);
							}}></Button>
					</Tooltip>
					{/* Lime Chat Button */}
					{/* {isConsultCallDone && record.status === "shipped" && (
            <Tooltip title="Send shipping status">
              <Popconfirm
                title="Are you sure you want to send shipping status on whatsApp"
                placement="topRight"
                onConfirm={() => {
                  const msg = `Your order ${record.order_id} has been shipped`;
                  sendMessage(msg);
                }}
              >
                <Button icon={<WhatsAppOutlined />} type="link" />
              </Popconfirm>
            </Tooltip>
          )} */}
				</>
			),
		},
	];

	const content = (
		<>
			<Select
				placeholder="Duration"
				options={statusOption}
				style={{ width: 100 }}
				value={duration}
				onChange={e => {
					setDuration(e);
				}}
			/>
			<Button disabled={!duration} onClick={() => handleBulkOrder()}>
				Save
			</Button>
		</>
	);

	const handleBulkOrder = async () => {
		const response = await bulkOrder(bulkOrderId, duration);
		if (response.status === 200) {
			message.success("Order marked as bulk order");
			setDuration(undefined);
			setBulkOrderId("");
			viewDetails(case_id);
			getEngagementsByCase(case_id);
		} else {
			message.error("failed to mark order as bulk");
			handleError(response);
		}
	};

	const handleVisibleChange = e => {
		if (e === false) setDuration(undefined);
	};

	const handleSubscription = async (id, subscriptionStatus) => {
		const res = await orderSubscription(id, String(!subscriptionStatus));
		if (res === "pass") viewDetails(case_id);
	};

	const handleSingleOrder = async (orderId, is_single_order) => {
		const res = await singleOrder(orderId, !is_single_order);
		if (res === "pass") viewDetails(case_id);
	};

	const handleAssignDoctors = async record => {
		let data = await fetchRequest(ASSIGNED_DOCTORS(record.key));
		console.log("assogned list", data.data);
		setAssignedDocListModal(true);
		setOrderId(record.key);
		setOrderStatus(record.status);
		if (isEmpty(data.data)) {
			await preAssignDoctor(record.key);
		}
	};
	const medRepColumns = [
		srCountCol,
		orderIdCol,
		orderNamecol,
		orderDatecol,
		statusCol,
		{
			title: "Actions",
			key: "actions",
			render: record => {
				const ncanSendToDispatch = canSendToDispatch(record.status, record.key);
				const sendToDispatchTooltipText = hasPrescription(record.key)
					? "Send to dispatch"
					: "No prescription";

				const subscriptionTooltipText = record.is_subscription
					? "Remove as Subscription Order"
					: "Mark as Subscription Order";

				const singleOrderTooltipText = record.is_single_order
					? "Unmark as Single Order"
					: "Mark as Single Order";

				const disableMarkBulk =
					record.status === "unknown" || record.status === "void";

				const disableSingleOrderBtn =
					record.status === "delivered" || record.status === "void";

				return (
					<>
						<Tooltip placement="bottom" title="Assign Doctors">
							<Button
								icon={<UserAddOutlined />}
								onClick={() => handleAssignDoctors(record)}
								type="link"></Button>
						</Tooltip>

						<Tooltip placement="bottom" title="Manage Prescriptions">
							<Button
								icon={<FormOutlined />}
								onClick={() =>
									history.push({
										pathname: `/prescription/${record.key}`,
										state: {
											caseId: case_id,
											order: record,
										},
									})
								}
								type="link"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title={sendToDispatchTooltipText}>
							<Popconfirm
								disabled={!ncanSendToDispatch}
								title="Are you sure you want to mark this order for shipping? Note: This will cause the order to be sent to dispatch."
								placement="topLeft"
								onConfirm={() => Confirm(record.key)}>
								<Button
									disabled={!ncanSendToDispatch}
									icon={<CheckOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>

						<Tooltip placement="bottom" title="Engagements">
							<Button
								type="link"
								icon={<MessageOutlined />}
								onClick={() => {
									setOrderId(record.key);
									setOrderName(record.order_name);
									setEngagementModal(true);
								}}></Button>
						</Tooltip>

						<Tooltip placement="bottom" title="Mark as Bulk Order">
							{record.bulk_order ? (
								<Button
									disabled={record.bulk_order}
									type="link"
									icon={<CheckCircleOutlined />}
									onClick={() => setBulkOrderId(record.key)}></Button>
							) : disableMarkBulk ? (
								<Button
									disabled={disableMarkBulk}
									type="link"
									icon={<CheckCircleOutlined />}
									onClick={() => setBulkOrderId(record.key)}></Button>
							) : (
								<Popover
									content={content}
									title="Bulk Order"
									trigger="click"
									disabled={record.bulk_order}
									onVisibleChange={e => handleVisibleChange(e)}>
									<Button
										disabled={record.bulk_order}
										type="link"
										icon={<CheckCircleOutlined />}
										onClick={() => setBulkOrderId(record.key)}></Button>
								</Popover>
							)}
						</Tooltip>

						<Tooltip placement="bottom" title={subscriptionTooltipText}>
							<Popconfirm
								title={
									<Text>
										Are you sure you want to{" "}
										<Text
											strong
											type={record.is_subscription ? "danger" : "success"}>
											{record.is_subscription
												? "remove subscription for this order"
												: "mark this order as a subscription"}
										</Text>
										?
									</Text>
								}
								placement="topLeft"
								onConfirm={() =>
									handleSubscription(record.key, record.is_subscription)
								}>
								<Button icon={<ScheduleOutlined />} type="link" />
							</Popconfirm>
						</Tooltip>

						<Tooltip placement="bottom" title={singleOrderTooltipText}>
							<Popconfirm
								disabled={disableSingleOrderBtn}
								title={
									<Text>
										Are you sure you want to{" "}
										{record.is_single_order ? (
											<Text type="danger">unmark</Text>
										) : (
											<Text type="success">mark</Text>
										)}{" "}
										this order as single order?
									</Text>
								}
								placement="topLeft"
								onConfirm={() =>
									handleSingleOrder(record.key, record.is_single_order)
								}>
								<Button
									disabled={disableSingleOrderBtn}
									icon={<SafetyOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>

						{localStorage.getItem("is!supervisor") === "true" && (
							<ResyncOrder order_id={record.key} status={record.status} />
						)}

						<Tooltip placement="bottom" title="Regular/Irregular">
							<Popover
								content={<RegularInRegularContent id={record.key} />}
								placement="left"
								trigger="click"
								visible={record.key === showRegularInRegularContent}
								onVisibleChange={show => {
									setShowRegularInRegularContent(show ? record.key : null);
								}}>
								<Button type="link" icon={<SmileOutlined />} />
							</Popover>
						</Tooltip>
						<Tooltip placement="bottom" title="Started/NotStarted">
							<Popover
								content={<StartedNotStartedContent id={record.key} />}
								placement="left"
								trigger="click"
								visible={record.key === showStartedNotStartedContent}
								onVisibleChange={show => {
									setShowStartedNotStartedContent(show ? record.key : null);
								}}>
								<Button type="link" icon={<PlayCircleOutlined />} />
							</Popover>
						</Tooltip>
						{/* Lime Chat Button */}
						{/* {isConsultCallDone && record.status === "shipped" && (
              <Tooltip title="Send shipping status">
                <Popconfirm
                title="Are you sure you want to send shipping status on whatsApp"
                placement="topRight"
                  onConfirm={() => {
                    const msg = `Your order ${record.order_id} has been shipped`;
                    sendMessage(msg);
                  }}
                  >
                  <Button icon={<WhatsAppOutlined />} type="link" />
                </Popconfirm>
              </Tooltip>
            )} */}
					</>
				);
			},
		},
	];

	const consultRepColumns = [
		srCountCol,
		orderIdCol,
		orderNamecol,
		orderDatecol,
		statusCol,
		{
			title: "Actions",
			key: "actions",
			render: record => {
				const ncanSendToDispatch = canSendToDispatch(record.status, record.key);
				const sendToDispatchTooltipText = hasPrescription(record.key)
					? "Send to dispatch"
					: "No prescription";

				const subscriptionTooltipText = record.is_subscription
					? "Remove as Subscription Order"
					: "Mark as Subscription Order";

				const singleOrderTooltipText = record.is_single_order
					? "Unmark as Single Order"
					: "Mark as Single Order";

				const disableMarkBulk =
					record.status === "unknown" || record.status === "void";

				const disableSingleOrderBtn =
					record.status === "delivered" || record.status === "void";

				return (
					<>
						<Tooltip placement="bottom" title="Assign Doctors">
							<Button
								icon={<UserAddOutlined />}
								onClick={() => {
									setAssignedDocListModal(true);
									setOrderId(record.key);
									setOrderStatus(record.status);
								}}
								type="link"></Button>
						</Tooltip>

						<Tooltip placement="bottom" title="Manage Prescriptions">
							<Button
								icon={<FormOutlined />}
								onClick={() =>
									history.push({
										pathname: `/prescription/${record.key}`,
										state: {
											caseId: case_id,
											order: record,
										},
									})
								}
								type="link"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title={sendToDispatchTooltipText}>
							<Popconfirm
								disabled={!ncanSendToDispatch}
								title="Are you sure you want to mark this order for shipping? Note: This will cause the order to be sent to dispatch."
								placement="topLeft"
								onConfirm={() => Confirm(record.key)}>
								<Button
									disabled={!ncanSendToDispatch}
									icon={<CheckOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>

						<Tooltip placement="bottom" title="Mark as Bulk Order">
							{record.bulk_order ? (
								<Button
									disabled={record.bulk_order}
									type="link"
									icon={<CheckCircleOutlined />}
									onClick={() => setBulkOrderId(record.key)}></Button>
							) : disableMarkBulk ? (
								<Button
									disabled={disableMarkBulk}
									type="link"
									icon={<CheckCircleOutlined />}
									onClick={() => setBulkOrderId(record.key)}></Button>
							) : (
								<Popover
									content={content}
									title="Bulk Order"
									trigger="click"
									disabled={record.bulk_order}
									onVisibleChange={e => handleVisibleChange(e)}>
									<Button
										disabled={record.bulk_order}
										type="link"
										icon={<CheckCircleOutlined />}
										onClick={() => setBulkOrderId(record.key)}></Button>
								</Popover>
							)}
						</Tooltip>

						<Tooltip placement="bottom" title={subscriptionTooltipText}>
							<Popconfirm
								title={
									<Text>
										Are you sure you want to{" "}
										<Text
											strong
											type={record.is_subscription ? "danger" : "success"}>
											{record.is_subscription
												? "remove subscription for this order"
												: "mark this order as a subscription"}
										</Text>
										?
									</Text>
								}
								placement="topLeft"
								onConfirm={() =>
									handleSubscription(record.key, record.is_subscription)
								}>
								<Button icon={<ScheduleOutlined />} type="link" />
							</Popconfirm>
						</Tooltip>

						<Tooltip placement="bottom" title={singleOrderTooltipText}>
							<Popconfirm
								disabled={disableSingleOrderBtn}
								title={
									<Text>
										Are you sure you want to{" "}
										{record.is_single_order ? (
											<Text type="danger">unmark</Text>
										) : (
											<Text type="success">mark</Text>
										)}{" "}
										this order as single order?
									</Text>
								}
								placement="topLeft"
								onConfirm={() =>
									handleSingleOrder(record.key, record.is_single_order)
								}>
								<Button
									disabled={disableSingleOrderBtn}
									icon={<SafetyOutlined />}
									type="link"
								/>
							</Popconfirm>
						</Tooltip>
						{/* Lime Chat Button */}
						{/* {isConsultCallDone && record.status === "shipped" && (
              <Tooltip title="Send shipping status">
                <Popconfirm
                title="Are you sure you want to send shipping status on whatsApp"
                placement="topRight"
                  onConfirm={() => {
                    const msg = `Your order ${record.order_id} has been shipped`;
                    sendMessage(msg);
                  }}
                  >
                  <Button icon={<WhatsAppOutlined />} type="link" />
                </Popconfirm>
              </Tooltip>
            )} */}
					</>
				);
			},
		},
	];

	const getColumsStructure = role => {
		switch (role) {
			case "admin":
				return userLevel === "2" ? adminLvl2Columns : adminColumns;

			case "consult_rep":
				return consultRepColumns;

			case "doctor":
				return docColumns;

			case "medical_rep":
				return medRepColumns;

			case "read_only":
				return supportColumns;

			case "sales":
				return salesRepColumns;

			case "support":
				return supportColumns;

			case "consult_doctor":
				return salesRepColumns;

			default:
				return null;
		}
	};

	return (
		<>
			<Table
				bordered
				columns={getColumsStructure(userRole)}
				dataSource={data}
				pagination={false}
				loading={detailsLoading}
				scroll={{ y: 180 }}
			/>

			<AssignDoctor
				isModalVisible={assignedDocListModal}
				orderId={orderId}
				setIsModalVisible={setAssignedDocListModal}
				orderStatus={orderStatus}
			/>

			<EngagementsByOrder
				isModalVisible={engagementModal}
				orderId={orderId}
				orderName={orderName}
				setIsModalVisible={setEngagementModal}
				caseId={case_id}
			/>

			<ShipOrders
				isModalVisible={shipOrderModal}
				orderId={orderId}
				setIsModalVisible={setShipOrderModal}
				caseId={case_id}
			/>

			{userRole === "doctor" && (
				<Prescription
					isModalVisible={writePrescriptionModal}
					setIsModalVisible={setWritePrescriptionModal}
					orderId={orderId}
					orderStatus={orderStatus}
				/>
			)}
		</>
	);
};

export default OrderDetails;

const statusOption = [
	{ label: "2", value: "2" },
	{ label: "3", value: "3" },
	{ label: "4", value: "4" },
	{ label: "5", value: "5" },
	{ label: "6", value: "6" },
	{ label: "7", value: "7" },
	{ label: "8", value: "8" },
	{ label: "9", value: "9" },
	{ label: "10", value: "10" },
];

const showDoctorsList = new Set(["admin", "consult_rep", "medical_rep"]);
const canNotUpdateOrderType = new Set(["delivered", "shipped", "void"]);
