import Cookies from "js-cookie";
import { baseURL, handleError } from "../../helpers";
import message from "antd/es/message";

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const placeOrder = async data => {
	let res = "";

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}cod-order`, {
			method: "POST",
			json: true,
			body: JSON.stringify(data),
			...AUTH_HEADER,
		});

		const _resData = await _res.json();
		if (_res.status === 200) {
			message.success("Order Placed");
		} else if (_res.status === 400) {
			message.warning("Something went wrong!");
		} else if (_res.status === 422) {
			message.error(_resData.message);
		} else {
			handleError(_res);
			message.error("Failed to Place Order");
		}
		res = _res;
	} catch (error) {
		message.info(error.message);
	} finally {
		return res;
	}
};
