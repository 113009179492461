import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { generateQuery } from "../../../helpers";

// Ant Design
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Typography from "antd/es/typography";
import { downloadReport } from "../service";
import { List, Row, Space } from "antd";
const { Title } = Typography;

const EngagementStatusReport = () => {
	const [form] = Form.useForm();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async items => {
		const filterItems = [];

		Object.keys(items).map(key => {
			switch (key) {
				case "startDate":
					filterItems.push({
						[key]: startDate
							? moment(startDate).startOf("day").toISOString()
							: null,
					});
					break;

				case "endDate":
					filterItems.push({
						[key]: endDate ? moment(endDate).endOf("day").toISOString() : null,
					});
					break;

				default:
					break;
			}
		});

		const _query = generateQuery(filterItems);

		setIsLoading(true);
		const _res = await downloadReport(_query, "ENGAGEMENT_STATUS_REPORT");
		if (_res.status === 200) handleClear();
		setIsLoading(false);
	};

	const handleClear = async () => {
		await setStartDate(null);
		await setEndDate(null);
		form.resetFields();
	};

	const formInitialValues = {
		startDate: startDate ? moment(startDate) : null,
		endDate: endDate ? moment(endDate) : null,
	};

	return (
		<Space direction="vertical">
			<Title level={2}>Engagement Feedback Report</Title>

			<Form
				form={form}
				initialValues={formInitialValues}
				layout="inline"
				onFinish={onSubmit}>
				<Form.Item name="startDate">
					<DatePicker
						allowClear
						disabledDate={current =>
							endDate && current.isAfter(moment(endDate), "day")
						}
						format={"YYYY-MM-DD"}
						id="start_date_input"
						onChange={e => (e ? setStartDate(e) : setStartDate(null))}
						placeholder="Start Date"
						style={{ width: 176 }}
					/>
				</Form.Item>

				<Form.Item name="endDate">
					<DatePicker
						allowClear
						disabledDate={current =>
							startDate && current.isBefore(moment(startDate), "day")
						}
						format={"YYYY-MM-DD"}
						id="end_date_input"
						onChange={e => (e ? setEndDate(e) : setEndDate(null))}
						placeholder="End Date"
						style={{ width: 176 }}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						disabled={isEmpty(startDate) || isEmpty(endDate)}
						id="generate_report_btn"
						loading={isLoading}
						type="primary"
						htmlType="submit">
						Generate Report
					</Button>
				</Form.Item>

				<Row>
					<Title>Notes</Title>
					<List
						dataSource={fromNotes}
						renderItem={item => (
							<List.Item>
								<Title level={5}>{item}</Title>
							</List.Item>
						)}
					/>
				</Row>
			</Form>
		</Space>
	);
};
const fromNotes = [
	`1. This report generates data that contains all the feedback given for all engagements for latest delivered orders within the date range.`,
	`2. For a given date range, there may be multiple engagements for a single order. Ex: In a typical month, 2-3 engagements of the same order may occur.`,
	`3. This report also gives a field that indicates whether an order has been placed after the concerned order's engagement. This does not mean that the order was placed after that specific engagement. Its merely an indicator that an new order has come in after the order in concern.`,
];

export default EngagementStatusReport;
