import moment from "moment";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { getValue } from "../../../helpers";
import { getAttemptText } from "../../../helpers/attemptText";

// Component
import { MarkDoneModal, SetReminders } from "../../../common";
import Call from "../../Call";
import MarkDoneEngagement from "../../markDone/MarkDoneEngagement";
import { RescheduleEngagement } from "../../RescheduleEngagement";

// Antd
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table, Tag, Tooltip, Typography } from "antd/es";
const { Title } = Typography;

const OverdueEngagements = ({ data, loading, refreshUI }) => {
	const [caseId, setCaseId] = useState("");
	const [comment, setComment] = useState("");
	const [currDueDate, setCurrDueDate] = useState("");
	const [currEngagement, setCurrEngagement] = useState({});
	const [currReminderDate, setCurrReminderDate] = useState("");
	const [currReminderId, setCurrReminderId] = useState("");
	const [currTag, setCurrTag] = useState("");
	const [markDoneModal, setMarkDoneModal] = useState(false);
	const [rescheduleModal, setRescheduleModal] = useState(false);
	const [userMarkDoneModak, setUserMarkDoneModak] = useState(false);
	const [userReminderModal, setUserReminderModal] = useState(false);

	const nameCol = {
		title: "Name",
		key: "name",
		render: data => (
			<Link to={`/lead-details/${data.case_id}`}>{data.name}</Link>
		),
	};

	const tagCol = {
		title: "Tag",
		key: "tag",
		render: ({
			tag,
			unanswered_count,
			reschedule_count,
			prev_engagement_completion_date,
		}) => {
			if (!tag) return "-";
			return (
				<>
					<Tag color="geekblue">{tag.replaceAll("_", "").toUpperCase()}</Tag>
					<Tag color="geekblue">{getAttemptText(unanswered_count)}</Tag>
					{prev_engagement_completion_date && (
						<Tag color="geekblue">
							{moment().diff(moment(prev_engagement_completion_date), "days")} D
						</Tag>
					)}
					{reschedule_count && reschedule_count !== null && (
						<Tag color="gold">Rescheduled</Tag>
					)}
				</>
			);
		},
	};

	const bookingCol = {
		title: "Booked",
		dataIndex: "booked",
		key: "booked",
		render: booked => (
			<span className="capitalize">{booked ? "Booked" : "Unbooked"}</span>
		),
	};

	const dateCol = {
		title: "Date",
		dataIndex: "datetime",
		key: "datetime",
		render: datetime => moment(datetime).format("DD MMM YYYY, h:mm a"),
	};

	const callActionCol = {
		title: "Action",
		key: "action",
		render: record => {
			if (record.booking_type === "engagement") {
				return (
					<>
						<Tooltip placement="bottom" title="Mark as Done">
							<Button
								icon={<CheckOutlined />}
								onClick={() => {
									setCaseId(record.case_id);
									setCurrEngagement(record);
									setCurrReminderId(record.key);
									setCurrTag(record.tag);
									setMarkDoneModal(true);
								}}
								type="link"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title="Reschedule">
							<Button
								icon={<EditOutlined />}
								onClick={() => {
									setCurrDueDate(record.due_date);
									setCurrEngagement(record);
									setCurrReminderDate(moment(record.reminder_date));
									setCurrReminderId(record.key);
									setRescheduleModal(true);
								}}
								type="link"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title="Call">
							<Button
								icon={
									<Call
										data={{
											phone_number: record.phone_number,
											case_id: record.case_id,
											note: {
												tag: record.tag,
												order_display_id: record.order_display_id,
											},
										}}
									/>
								}
								type="link"
							/>
						</Tooltip>
					</>
				);
			} else if (record.booking_type === "reminder") {
				return (
					<>
						<Tooltip placement="bottom" title="Mark as Done">
							<Button
								icon={<CheckOutlined />}
								onClick={() => {
									setUserMarkDoneModak(true);
									setCurrReminderId(record.key);
								}}
								type="link"
							/>
						</Tooltip>

						<Tooltip placement="bottom" title="Reschedule">
							<Button
								icon={<EditOutlined />}
								onClick={() => {
									setComment(record.comment);
									setCurrReminderDate(moment(record.reminder_date));
									setCurrReminderId(record.key);
									setUserReminderModal(true);
								}}
								type="link"></Button>
						</Tooltip>

						<Tooltip placement="bottom" title="Call">
							<Button
								icon={
									<Call
										data={{
											phone_number: record.phone_number,
											case_id: record.case_id,
										}}
									/>
								}
								type="link"
							/>
						</Tooltip>
					</>
				);
			}
		},
	};

	const consultCallColumn = [
		nameCol,
		tagCol,
		bookingCol,
		dateCol,
		callActionCol,
	];
	const otherBookingsColumn = [nameCol, tagCol, dateCol, callActionCol];
	const engagementsColumn = [dateCol, nameCol, tagCol, callActionCol];
	const columns = {
		consultCall: consultCallColumn,
		otherBookings: otherBookingsColumn,
		engagements: engagementsColumn,
	};

	const isBooked = count => {
		if (!count || count === null || count < 1) return false;
		return true;
	};

	const engagementsData = useMemo(() => {
		return data.engagements.map((row, index) => ({
			key: getValue(row, "id"),
			item1: index === 0,
			item2: index === 1,
			item3: index === 2,
			name: getValue(row, "order.case.user.first_name"),
			case_id: getValue(row, "order.case_id"),
			due_date: getValue(row, "due_date"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag"),
			phone_number: getValue(row, "order.case.user.phone_number"),
			order_display_id: getValue(row, "order.order_display_id"),
			batch: getValue(row, "batch"),
			booked: isBooked(getValue(row, "reschedule_count")),
			unanswered_count: getValue(row, "unanswered_count"),
			booking_type: "engagement",
			datetime: getValue(row, "reminder_date"),
			prev_engagement_completion_date: getValue(
				row,
				"prev_engagement_completion_date"
			),
			reschedule_count: getValue(row, "reschedule_count"),
			refrence_slot_id: getValue(row, "refrence_slot_id"),
		}));
	}, [data.engagements]);

	const remindersDate = useMemo(() => {
		if (!Array.isArray(data.reminders)) return [];
		if (data.reminders.length <= 0) return [];

		const _reminder = data.reminders.filter(reminder => {
			return !reminder.refrence_engagement_id;
		});

		return _reminder.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			comment: getValue(row, "comment"),
			due_date: getValue(row, "due_date"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag") || "user",
			phone_number: getValue(row, "case.user.phone_number"),
			order_display_id: getValue(row, "case.order_display_id"),
			batch: getValue(row, "batch"),
			booking_type: "reminder",
			datetime: getValue(row, "reminder_date"),
			name: getValue(row, "case.user.first_name"),
			reschedule_count: getValue(row, "reschedule_count"),
		}));
	}, [data.reminders]);

	const combinedData = useMemo(() => {
		const _data = [...engagementsData, ...remindersDate];

		return _data.sort((a, b) => {
			return new Date(b.reminder_date) - new Date(a.reminder_date);
		});
	}, [engagementsData, remindersDate]);

	const createTable = (columns, data) => (
		<Table
			columns={columns}
			dataSource={data}
			loading={loading}
			pagination={{ showSizeChanger: false }}
		/>
	);

	return (
		<div>
			<Title level={3}>Overdue Tasks</Title>
			{createTable(columns["engagements"], combinedData)}

			<RescheduleEngagement
				dueDate={currDueDate}
				engagement={currEngagement}
				isVisible={rescheduleModal}
				refreshUI={refreshUI}
				reminderDate={currReminderDate}
				reminderId={currReminderId}
				setIsVisible={setRescheduleModal}
			/>

			<MarkDoneEngagement
				caseId={caseId}
				engagement={currEngagement}
				modalVisible={markDoneModal}
				refreshUI={refreshUI}
				reminderId={currReminderId}
				setModalVisible={setMarkDoneModal}
				tag={currTag}
			/>

			<SetReminders
				currComment={comment}
				currDate={currReminderDate}
				modalVisible={userReminderModal}
				reminder_id={currReminderId}
				reminderType="user"
				status="active"
				refreshUI={refreshUI}
				setModalVisible={setUserReminderModal}
				modalType="user"
			/>

			<MarkDoneModal
				modalVisible={userMarkDoneModak}
				reminderId={currReminderId}
				reminderType="user"
				refreshUI={refreshUI}
				setModalVisible={setUserMarkDoneModak}
			/>
		</div>
	);
};

export default OverdueEngagements;

const sendTagToKnowlarity = new Set([
	"Prescription Call",
	"Week #1",
	"Week #2",
	"Week #3",
	"Week #4",
	"Week #5",
	"Week #6",
	"SPE",
]);
