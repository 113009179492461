import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getSalesReps } from "../../pages/leadManagement/service";
import { fetchRequest } from "../../helpers/fetchRequest";
import { UPDATE_LATEST_SALES_ASSIGN } from "../../constants/apiUrls";
import { viewDetails } from "../../pages/leadDetails/service";

// Antd
import { EditTwoTone } from "@ant-design/icons";
import { Button, message, Popover, Select, Typography } from "antd/es";
const { Option } = Select;
const { Text } = Typography;

const CaseLatestSalesRep = ({ rep }) => {
	const { id: caseId } = useParams();
	const [selectedRep, setSelectedRep] = useState(null);
	const [updating, setUpdating] = useState(false);
	const [openPopover, setOpenPopover] = useState(false);

	useEffect(() => {
		getSalesReps();
	}, []);

	const { salesReps, loading } = useSelector(state => ({
		salesReps: window.getValue(state, "leadmanagement.salesReps.userRole"),
		loading: window.getValue(state, "leadmanagement.salesRepsLoading"),
	}));

	const handleSalesChange = async () => {
		let _res = {};

		try {
			setUpdating(true);
			_res = await fetchRequest(UPDATE_LATEST_SALES_ASSIGN, {
				method: "POST",
				body: JSON.stringify({
					caseId: caseId,
					userId: selectedRep,
				}),
			});
		} catch (error) {
			console.warn(error);
		} finally {
			setUpdating(false);
			if (_res.hasError) message.error("Failed to update");
			else {
				message.success("updated successfully");
				viewDetails(caseId);
				setSelectedRep(null);
				setOpenPopover(false);
			}
		}
	};

	return (
		<Popover
			title={<Text strong>Change Sales Rep</Text>}
			trigger="click"
			visible={openPopover}
			onVisibleChange={() => setOpenPopover(curr => !curr)}
			content={
				<>
					<Select
						allowClear
						showSearch
						dropdownStyle={{ width: 200 }}
						placeholder="Select a person"
						loading={loading || updating}
						optionFilterProp="children"
						onChange={selectedRowKeys => setSelectedRep(selectedRowKeys)}
						value={selectedRep}>
						{Array.isArray(salesReps) &&
							salesReps.map(users => (
								<Option value={users.user.id} key={users.user.id}>
									{users.user.first_name}
								</Option>
							))}
					</Select>

					<Button
						disabled={updating || !selectedRep}
						onClick={handleSalesChange}
						type="primary">
						Confirm
					</Button>
				</>
			}>
			<Text className="pointer">
				{rep ? rep?.first_name : "N/A"} <EditTwoTone />
			</Text>
		</Popover>
	);
};

export default CaseLatestSalesRep;
