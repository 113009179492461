import Cookies from "js-cookie";
import { baseURL, handleError } from "../../helpers";
import message from "antd/es/message";
import { fetchRequest } from '../../helpers/fetchRequest';
import { DISCOUNT_CODES_API, getUrl } from '../../constants/apiUrls';

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const sendCartLink = async (case_id, link, total) => {
	let res = "";

	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}send_cart_link/${case_id}`,
			{
				method: "POST",
				json: true,
				body: JSON.stringify({ cart_link: link, cart_value: total }),
				...AUTH_HEADER,
			}
		);
		if (_res.status === 200) {
			message.success("Message sent");
		} else if (_res.status === 400) {
			message.warning("Please update chat phone number and try again");
		} else {
			handleError(_res);
			message.error("Failed to send message");
		}
		res = _res;
	} catch (error) {
		message.info(error.message);
	} finally {
		return res;
	}
};

export const generateCartLink = async (case_id, link) => {
	let res = "";

	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}generate_cart_link/${case_id}`,
			{
				method: "POST",
				json: true,
				body: JSON.stringify({ cart_link: link }),
				...AUTH_HEADER,
			}
		);
		if (_res.status === 200) {
			message.success("Link generated!");
		} else if (_res.status === 400) {
			message.warning("Something went wrong, please try again");
		} else {
			handleError(_res);
			message.error("Failed to generate link");
		}
		res = _res;
	} catch (error) {
		message.info(error.message);
	} finally {
		return res;
	}
};

export const getTrayaCartLink = async () => {
	try {
		const res = await fetchRequest(getUrl(`settings`));
		if(!res.hasError && res.status === 200) {
			const { data: settingsData } = res.data;
			const baseUrl = settingsData.find(item => item.key === 'ORDER_CART_BASE_URL');
			const itemsKey = settingsData.find(item => item.key === 'ORDER_CART_ITEMS_KEY');
			if (!baseUrl) {
				message.info('Base url in settings is missing');
				return;
			};
			return Promise.resolve({ baseUrl: baseUrl.value, itemsKey: itemsKey.value });
		}
	} catch (error) {
		console.error(error);
		return Promise.reject('');
	}
}

export const getCodeTypeAndValue = async (code) => {
	try {
		const res = await fetchRequest(DISCOUNT_CODES_API);
		if(!res.hasError && res.status === 200) {
			const { data } = res;
			const codeTypeAndValue = data.find(item => item.discount_code === code);
			if(!codeTypeAndValue) {
				message.info('Discount code not found');
				return;
			}
			const { type, value } = codeTypeAndValue;
			return Promise.resolve({ type, value });
		}
	} catch (error) {
		console.warn(error.message);
		return Promise.reject({ type: '', value: '' });
	}
}