import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { resyncOrder, viewDetails } from "../../service";

import { SyncOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Tooltip, Typography } from "antd";
const { Text } = Typography;

export const ResyncOrder = ({ status, order_id }) => {
	const { id: case_id } = useParams();
	const [loading, setLoading] = useState(false);

	const disableResyncOrderBtn = useMemo(() => {
		return (
			status === "delivered" ||
			status === "shipped" ||
			status === "void" ||
			status === "unknown"
		);
	}, [status]);

	const handleResyncOrder = async () => {
		setLoading(true);
		const _res = await resyncOrder(order_id);
		setLoading(false);

		if (_res.status === 200) viewDetails(case_id);
	};

	return (
		<Tooltip placement="bottom" title="Resync Order">
			<Popconfirm
				disabled={disableResyncOrderBtn || loading}
				title={
					<Text>
						This will cause the order information to be re-synced from Shopify
						and will reset the status of the order to{" "}
						<Text type="danger">IN_PROGRESS / PENDING_PRESCRIPTION</Text>. Any
						prescriptions written for this order will also be removed. Please
						confirm.
					</Text>
				}
				placement="topLeft"
				onConfirm={() => handleResyncOrder()}
				overlayStyle={{ maxWidth: 720 }}>
				<Button
					disabled={disableResyncOrderBtn}
					icon={<SyncOutlined />}
					loading={loading}
					type="link"
				/>
			</Popconfirm>
		</Tooltip>
	);
};
