import { Tag, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { getValue } from "./utility";
const { Text } = Typography;

export const activityLog = data => {
	switch (data.event_id) {
		case "DEFAULT_ORDER_PRESCRIPTION_FINISHED":
			return (
				<Text>
					{" "}
					<Text strong>{data.author_name}</Text> finished writing default
					prescription for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ORDER_DELIVERED_MANUAL":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked Order{" "}
					<Text className="link" strong>
						{data.meta.order.order_display_id}
					</Text>{" "}
					as <Text strong>Delivered</Text>{" "}
				</Text>
			);
		case "DISPATCHED_TO_WAREHOUSE":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> sent this order (
					<Text strong>{data.meta.warehouse.meta.order_display_id}</Text>) to{" "}
					<Text strong>{data.meta.warehouse.meta.warehouse.name}</Text>
				</Text>
			);

		case "TEMPORARY_REMINDER_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> assigned{" "}
					<Text strong>{data.meta.owner.first_name}'s</Text> Reminder{" "}
					<Text strong>({data.meta.reminder.tag.replace("_", " ")})</Text> to{" "}
					<Text strong>{data.meta.assignment.first_name}</Text>{" "}
				</Text>
			);

		case "MARKED_BOOKING_DONE":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> finished slot booking for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CASE_ESCALATED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}'s
						</Text>
					</Link>{" "}
					case was escalated by <Text strong>{data.author_name}</Text>
				</Text>
			);

		case "CASE_ESCALATION_FINISHED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}'s
						</Text>
					</Link>{" "}
					case escalation was finished by <Text strong>{data.author_name}</Text>
				</Text>
			);

		case "CASE_ESCALATION_RESOLVED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}'s
						</Text>
					</Link>{" "}
					case escalation was resolved by <Text strong>{data.author_name}</Text>
				</Text>
			);

		case "CASE_RESET":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					case was reset due to form fill
				</Text>
			);

		case "ORDER_FULFILLMENT_REVERT":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						{" "}
						<Text className="link" strong>
							{data.meta.order.order_display_id}
						</Text>
					</Link>{" "}
					fulfillment was reverted
				</Text>
			);
		case "ENGAGEMENT_AUTO_FINISHED":
			return (
				<Text className="flex-1">
					System marked the (<Text strong>{data.meta.engagement.tag}</Text>)
					engagement as finished for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ENGAGEMENT_UNANSWERED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked the engagement (
					<Text strong>{data.meta.engagement.tag}</Text>) as Unanswered for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CONSULT_REP_MARK_FINISHED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> has marked finished
				</Text>
			);

		case "ORDER_FULFILLMENT_SHIPPED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						{" "}
						<Text className="link" strong>
							{data.meta.order.order_display_id}
						</Text>{" "}
					</Link>{" "}
					was auto marked as fulfilled
				</Text>
			);

		case "ORDER_SYNC_RESET":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> synced the order for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CONSULT_CALL_USER_RESCHEDULED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					rescheduled consult call to
					<Text strong>
						{" "}
						{moment(data.meta.slotDetails.slot_time).format("DD MMM, h:mm a")}
					</Text>
				</Text>
			);
		case "CRM_COD_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name} </Text>
					placed a COD order for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "SLOT_BOOKED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					booked a call for
					<Text strong>
						{" "}
						{moment(data.meta.slot_time).format("DD MMM, h:mm a")}
					</Text>
				</Text>
			);

		case "ADMIN_LEAD_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> assigned{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					to <Text strong>{data.meta.sales_rep.first_name}</Text>
				</Text>
			);

		case "CUSTOMER_PROFILE_EDITED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> edited profile of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CUSTOMER_RETURNED_ORDER":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					returned order <Text strong>{data.meta.order.order_display_id}</Text>
				</Text>
			);

		case "DOCTOR_PRESCRIPTION_FINISHED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> finished writing prescription
					for{" "}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
					</Text>
				</Text>
			);

		case "MEDICAL_REP_PRESCRIPTION_FINISHED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> finished writing prescription
					for{" "}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
					</Text>
				</Text>
			);

		case "MEDICAL_REP_EDIT_PRESCRIPTION":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> edited prescription for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ORDER_SHIPPED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> shipped order for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ORDER_DELIVERED":
			return (
				<Text className="flex-1">
					Order (
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.meta.order.order_display_id})
						</Text>
					</Link>{" "}
					was <Text strong>delivered</Text>
				</Text>
			);

		case "ORDER_LINK_SENT":
			return (
				<Text className="flex-1">
					Order Link was sent by <Text strong>{data.author_name}</Text> to{" "}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
					</Text>
				</Text>
			);

		case "KNOWLARITY_CALL":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> called{" "}
					{isEmpty(data.meta.note) ? (
						<Text strong>
							{" "}
							<Link to={`/lead-details/${data.case.id}`} target="_blank">
								{data.meta.lead.first_name}
							</Link>
						</Text>
					) : (
						<Text strong>
							<Link to={`/lead-details/${data.case.id}`} target="_blank">
								{data.meta.lead.first_name}
							</Link>{" "}
							order number ({data.meta.note.order_display_id}) and engagement (
							{data.meta.note.tag})
						</Text>
					)}
				</Text>
			);

		case "MEDICAL_REP_UNASSIGNED_DOCTOR":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> unassigned{" "}
					<Text strong>{data.meta.doctor.first_name}</Text> from {""}
					<Text strong>
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
						{` (${data.meta.order.order_display_id})`}
					</Text>
				</Text>
			);

		case "MEDICAL_REP_ORDER_DOCTOR_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> assigned{" "}
					<Text strong>{data.meta.doctor.first_name}</Text> for {""}
					<Text strong>
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{" "}
							{data.case.user.first_name}
						</Link>
						{` (${data.meta.order.order_display_id})`}
					</Text>
				</Text>
			);

		case "MEDICAL_REP_DELETED_PRESCRIPTION":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> deleted prescription of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ADMIN_ORDER_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> assigned{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					to <Text strong>{data.meta.medical_rep.first_name}</Text>
				</Text>
			);

		case "CASE_COMMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> commented on{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					<br />
					<Text type="secondary">{data.meta.comment.comment_text}</Text>
				</Text>
			);

		case "SET_LEAD_REMINDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> setup a reminder for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					on -{" "}
					<Text type="secondary" style={{ fontWeight: "bold" }}>
						{moment(data.meta.reminder.reminder_date).format("DD MMM, h:mm a")}
					</Text>
				</Text>
			);

		case "MEDICAL_REP_CONSULT_CALL_FINISHED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> updated{" "}
					<Text strong>consult notes</Text> for {""}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
					<br />
					<Text type="secondary">{data.meta.case.notes}</Text>
				</Text>
			);

		case "SALES_REP_LEAD_UPDATE":
			let color = "";
			if (data.meta.case.status === "new") color = "green";
			else if (data.meta.case.status === "hot") color = "red";
			else if (data.meta.case.status === "cold") color = "blue";
			else if (data.meta.case.status === "dropped") color = "orange";
			else color = "geekblue";
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> updated status for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					to{" "}
					<Tag color={color} key={data.case.id}>
						{data.meta.case.status && data.meta.case.status.toUpperCase()}
					</Tag>
				</Text>
			);

		case "UNANSWERED_MESSAGE_SENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> sent Email and SMS to{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					<Text strong>({data.meta.engagement.tag})</Text>
				</Text>
			);

		case "ADMIN_VOID_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					order as void {`(${data.meta.order.order_display_id})`}
				</Text>
			);

		case "LIME_CHAT_MESSAGE_SENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> sent Message to{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
					<br />
					<Text type="secondary">{data.meta.message}</Text>
				</Text>
			);

		case "ORDER_MARKED_DIRECT_SHIPPING":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					order as direct shipping {`(${data.meta.orderDisplayId})`}
				</Text>
			);

		case "MEDICAL_REP_GHOST_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> converted ghost order to
					patient <Text strong>{data.case.user.first_name}</Text>{" "}
					{`(${data.meta.order.order_display_id})`}
				</Text>
			);

		case "DOCTOR_DELETED_PRESCRIPTION":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> deleted prescription of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "BULK_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					order as bulk order {`(${data.meta.order.order_display_id})`}
				</Text>
			);

		case "USER_DIET_PLAN_SET":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> sent Diet plan
					{data.meta.customerDetails?.diet_plan_link && (
						<Text strong>({data.meta.customerDetails?.diet_plan_link})</Text>
					)}
					email to{" "}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
					</Text>
				</Text>
			);
		case "REMOVED_TAG":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> removed tag{" "}
					{`(${data.meta.tagName})`} for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);
		case "ADD_TAG":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> added tag{" "}
					{`(${data.meta.tagName})`} for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);
		case "REMINDER_MARKED_DONE":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked done{" "}
					<Text strong className="whitespace-nowrap">
						{data.meta?.reminder?.tag?.replaceAll("_", " ")}
					</Text>{" "}
					for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);
		case "REMINDER_UPDATED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> updated reminder for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);
		case "ADMIN_MOVED_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> moved order from{" "}
					<Text strong>
						{" "}
						<Link
							to={`/lead-details/${data.meta.fromCaseDetails.id}`}
							target="_blank">
							{data.meta.fromUserDetails.first_name}
						</Link>
					</Text>{" "}
					({data.meta.fromOrderDetails[0].order_display_id}) to {""}
					<Link
						to={`/lead-details/${data.meta.toCaseDetails.id}`}
						target="_blank">
						<Text className="link" strong>
							{data.meta.toUserDetails
								? data.meta.toUserDetails.first_name
								: data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "MEDICAL_REP_MARK_ORDER_SHIPPING":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked order shipping for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					({data.meta.order.order_display_id})
				</Text>
			);
		case "MEDICAL_REP_UPDATED_ENGAGEMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> Updated engagement (
					<Text strong>{data.meta.engagement.tag}</Text>) for
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "MEDICAL_REP_FINISHED_ENGAGEMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> Finished engagement (
					<Text strong>{data.meta.engagement.tag}</Text>) for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CALENDLY_EVENT_CREATED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> Scheduled an appointment with{" "}
					<Text strong>{data.meta.representative.first_name}</Text>
				</Text>
			);

		case "CALENDLY_EVENT_CANCELED":
			return (
				<Text className="flex-1">
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.meta.customer.first_name}
						</Link>
					</Text>{" "}
					Cancelled an appointment with{" "}
					<Text strong>{data.meta.representative.first_name}</Text>
				</Text>
			);

		case "NOTIFICATION_PREFERENCE_UPDATE":
			const notificationPreferences = data.meta.notificationDetails;
			const enabled = [];
			const disabled = [];
			for (const [key, value] of Object.entries(notificationPreferences)) {
				if (value === true) {
					switch (key) {
						case "email_disabled":
							disabled.push("Email");
							break;
						case "push_disabled":
							disabled.push("Push");
							break;
						case "sms_disabled":
							disabled.push("SMS");
							break;
						default:
							break;
					}
				}
				if (value === false) {
					switch (key) {
						case "email_disabled":
							enabled.push("Email");
							break;
						case "push_disabled":
							enabled.push("Push");
							break;
						case "sms_disabled":
							enabled.push("SMS");
							break;
						default:
							break;
					}
				}
			}
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text>
					{!isEmpty(enabled) && (
						<Text> enabled {`(${enabled})`} notifications</Text>
					)}
					{!isEmpty(enabled) && !isEmpty(disabled) && <Text> and </Text>}
					{!isEmpty(disabled) && (
						<Text> disabled {`(${disabled})`} notifications</Text>
					)}{" "}
					for {""}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text strong>
							{" "}
							<Link to={`/lead-details/${data.case.id}`} target="_blank">
								{data.meta.case.first_name}
							</Link>
						</Text>
					</Link>
				</Text>
			);

		case "CASE_IMAGE_DELETED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> deleted image of {""}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text strong>{data.meta.case.first_name}</Text>
					</Link>
				</Text>
			);

		case "UNANSWERED_CONSULT_CALL":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> sent unanswered call (SMS &
					Email) to {""}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.meta.customer.first_name}
						</Link>
					</Text>
				</Text>
			);

		case "MARKED_SUBSCRIPTION_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked order{" "}
					<Text strong>{data.meta.order.order_display_id}</Text> as subscription
					for{" "}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
					</Text>
				</Text>
			);

		case "UNMARKED_SUBSCRIPTION_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> removed order{" "}
					<Text strong>{data.meta.order.order_display_id}</Text> from
					subscription for{" "}
					<Text strong>
						{" "}
						<Link to={`/lead-details/${data.case.id}`} target="_blank">
							{data.case.user.first_name}
						</Link>
					</Text>
				</Text>
			);

		case "MARK_SINGLE_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					order as single order {`(${data.meta.order.order_display_id})`}
				</Text>
			);

		case "UNMARK_SINGLE_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> unmarked{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					order as single order {`(${data.meta.order.order_display_id})`}
				</Text>
			);

		case "ADMIN_CONSULT_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> assigned{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					to <Text strong>{data.meta.consult_rep.first_name}</Text>
				</Text>
			);
		case "CONSULT_CALL_FINISHED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> finished prescription call with{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CONSULT_CALL_FEEDBACK":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> Updated Feedback for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CONSULT_CALL_RESCHEDULE":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> rescheduled consult call for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "TEMPORARY_ENGAGEMENT_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> assigned{" "}
					<Text strong>{data.meta.owner.first_name}'s</Text> engagement{" "}
					<Text strong>({data.meta.engagement.tag})</Text> to{" "}
					<Text strong>{data.meta.assignment.first_name}</Text>{" "}
				</Text>
			);

		case "TICKET_CREATED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> raised{" "}
					<Text strong>{data.meta.ticket.subject}</Text> ticket{" "}
					<Text strong>{data.meta.ticket.provider_ref_id}</Text> for{" "}
					<Text strong>{data.meta.order_display_id}</Text>
				</Text>
			);

		case "TICKET_UPDATED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> updated{" "}
					<Text strong>{data.meta.ticket.provider_ref_id}</Text> ticket for{" "}
					<Text strong>{data.meta.ticket.meta.order_display_id}</Text>
				</Text>
			);

		case "LIMECHAT_CHAT_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>Limechat assignment</Text> notification for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					<Text>({data.meta.limechat_user_id})</Text>
				</Text>
			);

		case "SALES_AGENT_MANUAL_ASSIGNMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.meta.assigner.first_name}</Text> manually assigned{" "}
					<Text strong>{data.meta.rep.first_name}</Text> as sales agent to{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "DOCTOR_APPT_SLOT_BOOKED":
			return (
				<Text className="flex-1">
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					booked doctor appointment slot at{" "}
					<Text strong>
						{moment(data.meta.slot_time).format("DD MMM, h:mm a")}
					</Text>{" "}
					with{" "}
					<Text strong>{getValue(data, "meta.assignedTo.first_name")}</Text>
				</Text>
			);

		case "ORDER_DIRECT_SHIPPING_PENDING":
			return (
				<Text className="flex-1">
					<Text strong>Order</Text>{" "}
					<Text className="link" strong>
						({data.meta.orderDisplayId})
					</Text>{" "}
					shipping pending
				</Text>
			);

		case "AUTO_SLOT_RESCHEDULED":
			return (
				<Text className="flex-1">
					<Text strong>Slot auto rescheduled for</Text>{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "SALES_SLOT_BOOKING_CLEARED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> cleared{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>{" "}
					slot booking
				</Text>
			);

		case "ORDER_TYPE_UPDATED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> updated{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}{" "}
						</Text>
					</Link>
					<Text>
						order{" "}
						<Text strong className="link">
							({data.meta.order.order_display_id}){" "}
						</Text>
						type to {data.meta.order.order_type}
					</Text>
				</Text>
			);

		case "MARK_BOOKING_DONE":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> marked booking done for order{" "}
					<Text className="link" strong>
						{data.case.latest_order_display_id}
					</Text>
				</Text>
			);

		case "ADMIN_DELETED_PRESCRIPTION":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> deleted prescription of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "BLACKLISTED_ORIGINAL_REP":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> blacklisted{" "}
					<Text strong>{data.meta.original_owner.first_name}</Text> of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "TENANT_CASE_COMMENT":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> comment for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "AUTO_ASSIGN_SUPERVISIOR":
			return (
				<Text className="flex-1">
					Auto Assigned supervisor for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ADMIN_RECORDING_DOWNLOAD":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> downloads admin recordiing
				</Text>
			);

		case "DOCTOR_APPOINTMENT_BOOKED":
			return (
				<Text className="flex-1">
					<Text>Doctor appointment for </Text>
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CALL_ALLOCATION":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> allocated call for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "SHOPIFY_VOID_ORDER":
			return (
				<Text className="flex-1">
					<Text strong>Shopify order </Text>
					<Text strong className="link">
						({data.meta.order.order_display_id})
					</Text>{" "}
					for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "CASE_DISQUALIFIED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> disqualified case of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "LIMECHAT_COD_APPROVED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> approved limechat cod for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "LIMECHAT_COD_REJECTED":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> rejects limechat cod for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "AUTO_INVALIDATE_CASE":
			return (
				<Text className="flex-1">
					Auto Invalidate case for{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);

		case "ADMIN_EDIT_PRESCRIPTION":
			return (
				<Text className="flex-1">
					<Text strong>{data.author_name}</Text> edited prescription of{" "}
					<Link to={`/lead-details/${data.case.id}`} target="_blank">
						<Text className="link" strong>
							{data.case.user.first_name}
						</Text>
					</Link>
				</Text>
			);
		case "PAYMENT_FAILED":
			return (
				<Text className="flex-1">
					<Text>
						Payment Amount Rs:
						<Text strong> {data.meta.razorpay.amount / 100}</Text> failed
					</Text>
				</Text>
			);
		case "PAYMENT_CAPTURED":
			return (
				<Text className="flex-1">
					Payment Amount Rs:
					<Text strong> {data.meta.razorpay.amount / 100}</Text> captured
				</Text>
			);
		case "ORDER_ACCOUNTING_UPDATED":
			return (
				<Text className="flex-1">
					Order accounting status updated to
					<Text strong> {data.meta.status}</Text>
				</Text>
			);
		case "ODA_WAREHOUSE_CHANGED":
			return (
				<Text className="flex-1">
					<Text>ODA warehouse changed by</Text>{" "}
					<Text strong>{data.author_name}</Text>
				</Text>
			);
		case "ODA_WAREHOUSE_CREATED":
			return (
				<Text className="flex-1">
					<Text>ODA warehouse created by</Text>{" "}
					<Text strong>{data.author_name}</Text>
				</Text>
			);
		case "ODA_WAREHOUSE_MARKED_SHIPPED_OR_CANCELLED":
			return (
				<Text className="flex-1">
					<Text>ODA warehouse marked shipped or cancelled by</Text>{" "}
					<Text strong>{data.author_name}</Text>
				</Text>
			);
		default:
			return data.event_id;
	}
};
