import React, { useState } from "react";
import { Button, List, Row } from "antd/es";
import { useEffect } from 'react';

import { CopyOutlined } from "@ant-design/icons";
import { Drawer } from "antd";

const Conversations = ({ data, showIcon }) => {
	const [conversations, setconversations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [dataCount, setDataCount] = useState(1);

	useEffect(() => {
		if(data && Array.isArray(data.history)) {
			setLoading(true);
			const _conversations = data.history.slice(0, 2 * dataCount);
			setconversations(_conversations);
			setLoading(false);
		}
	}, [data, dataCount]);

	const handleModalClose = () => {
		setOpenModal(false);
		setDataCount(1);
	};

	const handleClick = () => {
		setOpenModal(true);
	};

	const handleLoadMore = () => {
		const _count = dataCount + 1;
		setDataCount(_count);
	}

	return (
		<div>
			<Button onClick={handleClick} className="mt-2" type={showIcon ? "text" : 'default'}>
				{ showIcon ? <CopyOutlined /> :  "Conversations" }
			</Button>

			<Drawer
				title={`Ticket #${data.key} | ${data.customer?.phone_number}`}
				placement="right"
				visible={openModal}
				showDrawer={() => setOpenModal(true)}
				onClose={handleModalClose}
				width={600}
			>
				<List
					dataSource={conversations}
					itemLayout="horizontal"
					loading={loading}
					renderItem={item => {
						return (
							<List.Item key={item.id}>
								<List.Item.Meta
									title={item.event}
									description={item.description}
								/>
								Author: {item.action_taker?.first_name}
							</List.Item>
						);
					}}
				/>
				<Row justify="center">
					<Button
						type="link"
						onClick={handleLoadMore}
						disabled={!conversations.length || conversations.length === data.history?.length}
					>
						Load More...
					</Button> 
				</Row>
				<Row justify="end">
					<Button key="cancel" onClick={handleModalClose}>
						Cancel
					</Button>
				</Row>
			</Drawer>
		</div>
	);
};

export default Conversations;
