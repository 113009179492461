import React, { useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { SALES_TARGET_API } from "../../constants/apiUrls";
import { fetchRequest } from "../../helpers/fetchRequest";
import { distributeRevenueToDate } from "./helpers";

// Antd
import {
	Button,
	DatePicker,
	Divider,
	Form,
	InputNumber,
	message,
	Modal,
	Progress,
	Typography,
} from "antd";
const { Text } = Typography;
const { RangePicker } = DatePicker;

const Edit = ({ data, showModal, setshowModal, updateUI }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [exsistingSales, setExsistingSales] = useState([]);

	const handleSubmit = async formData => {
		setLoading(true);
		const request = [];

		const _rd = distributeRevenueToDate(formData.revenue, formData.target_date);
		_rd.forEach(item => {
			const _res = fetchRequest(SALES_TARGET_API(data.key), {
				method: "POST",
				body: JSON.stringify({
					revenue: item.revenue,
					target_date: item.target_date.toISOString(),
				}),
			});
			request.push(_res);
		});

		await Promise.all(request)
			.then(response => {
				if (response.every(res => res.status === 200)) {
					message.success("Sales target updated");
					updateUI();
					handleModalClose();
				} else {
					message.error("Failed to update sales target");
				}
			})
			.catch(error => console.warn(error.message))
			.finally(() => setLoading(false));
	};

	const handleModalClose = () => {
		setExsistingSales([]);

		form.resetFields();
		setshowModal(false);
	};

	const progressCount = useMemo(() => {
		if (isEmpty(data)) return 0.0;
		return parseFloat(
			(data.fullfilled_calls / data.target_calls) * 100
		).toFixed(2);
	}, [data]);

	return (
		<Modal
			title="Edit Rep Target"
			centered
			footer={null}
			onCancel={handleModalClose}
			visible={showModal}>
			<Form layout="vertical" form={form} onFinish={handleSubmit}>
				<Form.Item
					label="Order Target"
					name="revenue"
					rules={[
						{
							required: true,
							message: "Please fill this",
						},
					]}>
					<InputNumber
						min={0}
						style={{ width: "100%" }}
						placeholder="Order Target"
					/>
				</Form.Item>

				<Form.Item
					label="Select Date"
					name="target_date"
					rules={[
						{
							required: true,
							message: "Please fill this",
						},
					]}>
					<RangePicker style={{ width: "100%" }} size="middle" />
				</Form.Item>

				{!isEmpty(exsistingSales) && (
					<div className="flex gap-2 mb-4">
						<Text strong>Target Orders :</Text>
						<Text>{exsistingSales.revenue}</Text>
					</div>
				)}

				<Form.Item>
					<div className="flex gap-2">
						<Button htmlType="submit" loading={loading} type="primary">
							Save
						</Button>
						<Button key="back" onClick={handleModalClose}>
							Cancel
						</Button>
					</div>
				</Form.Item>
			</Form>

			<Divider />

			<div className="flex gap-2 flex-col">
				<div className="flex gap-2">
					<Text strong>Current Progress :</Text>
					<div style={{ width: "50%" }}>
						<Progress percent={isNaN(progressCount) ? 0.0 : progressCount} />
					</div>
				</div>

				<div className="flex gap-2">
					<Text strong>Completed Calls :</Text>
					<Text>{data.fullfilled_calls}</Text>
				</div>

				<div className="flex gap-2">
					<Text strong>Completed Orders :</Text>
					<Text>{data.fullfilled_orders}</Text>
				</div>
			</div>
		</Modal>
	);
};

export default Edit;
