import React, { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteComment, getComments } from "../service";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, List, Typography } from "antd";
const { Text } = Typography;

const RecentComments = () => {
  const params = useParams();

  const { commentsLoading, comments, updatingEngagement } = useSelector(
    state => ({
      commentsLoading: window.getValue(state, "leaddetails.commentsLoading"),
      comments: window.getValue(state, "leaddetails.comments"),
      updatingEngagement: window.getValue(
        state,
        "orderManagement.updatingEngagement"
      ),
    })
  );

  useEffect(() => {
    getComments(params.id);
  }, [params.id, updatingEngagement]);

  const handleDeleteComment = async comment_id => {
    const res = await deleteComment(comment_id);
    if (res.status === 200) getComments(params.id);
  };

  return (
    <List
      size="large"
      className="recent-comment"
      loading={commentsLoading}
      dataSource={comments}
      renderItem={item => (
        <List.Item type="flex" justify="space-between">
          <List.Item.Meta
            title={<Text>{item.user.first_name}</Text>}
            description={item.comment_text}
          />
          <Text type="secondary">
            {moment(item.created_at).format("DD MMM YYYY, h:mm a")}
          </Text>
          <Button
            className="delete-btn"
            danger
            type="link"
            icon={
              item.user.id === localStorage.getItem("users") &&
              item.type !== "note" ? (
                <DeleteOutlined />
              ) : null
            }
            onClick={() => handleDeleteComment(item.id)}
          />
        </List.Item>
      )}
    />
  );
};

export default RecentComments;
