export const formStatusTag = {
	null: "-",
	draft: "D",
	filled: "F",
	"semi-filled": "SF",
	guest: "G",
	"mini-lead": "ML",
	"appt-lead": "AL",
	"transplant-lead": "TL",
};

export const formStatusTitle = {
	null: "-",
	draft: "Draft",
	filled: "Filled",
	"semi-filled": "Semi Filled",
	guest: "Guest",
	"mini-lead": "Mini Lead",
	"appt-lead": "Appt Lead",
	"transplant-lead": "Transplant Lead",
};
