import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { OrderFilters } from "../../../common";
import { getSubscriptionPlatform } from "../../../constants/orderSubscriptionPlatforms";
import {
	generateQuery,
	getLineItemsName,
	getQueryParams,
	getSearchParams,
	getValue,
} from "../../../helpers";
import { getAssignedOrders, getMyPatients } from "../service";

// Antd
import { Col, Row, Table, Tag, Tooltip, Typography } from "antd";
const { Text, Title } = Typography;

function AssignedOrders() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(getSearchParams);

	useEffect(() => {
		const filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });
		const isFilter = checkIfFilter();
		isFilter ? getAssignedOrders(queryParams) : getMyPatients(queryParams);
	}, [history, queryParams]);

	const { myPatients, loading } = useSelector(state => ({
		myPatients: getValue(state, "orderManagement.myPatients"),
		loading: getValue(state, "orderManagement.myPatientsLoading"),
	}));

	useEffect(() => {
		if (isEmpty(myPatients)) setData([]);
		else {
			const ndata = myPatients.data.map(row => ({
				key: getValue(row, "id"),
				case_id: getValue(row, "case_id"),
				name: getValue(row, "case.user.first_name"),
				phoneno: getValue(row, "case.user.phone_number"),
				order_display_id: getValue(row, "order_display_id"),
				order_name: getLineItemsName(row.order_meta?.line_items),
				order_status: getValue(row, "status"),
				created: getValue(row, "created_at"),
				assigned: getValue(row, "case.caseAssignment[0].created_at"),
				count: getValue(row, "count"),
				deliveryDate: getValue(row, "delivery_date"),
				is_subscription: getValue(row, "is_subscription_order"),
				is_bulk_order: getValue(row, "is_bulk_order"),
				bulk_order_duration: getValue(row, "bulk_order_duration"),
				is_single_order: getValue(row, "is_single_order"),
				order_type: getValue(row, "order_type"),
				order_meta: getValue(row, "order_meta"),
				sub_status: getValue(row, "sub_status"),
			}));
			setData(ndata);
			setPaginationInfo(myPatients.pagination);
		}
	}, [myPatients]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (name, record) => {
				let subscriptionPlatform = "";
				if (record.is_subscription) {
					subscriptionPlatform = getSubscriptionPlatform(record.order_meta);
					subscriptionPlatform = subscriptionPlatform
						? `(${subscriptionPlatform})`
						: "";
				}

				return (
					<div className="flex flex-col">
						<Link className="link" to={`/lead-details/${record.case_id}`}>
							{name}
						</Link>

						<Text type="secondary">{record.phoneno}</Text>

						<span>
							{record.is_subscription && (
								<Tooltip
									placement="bottom"
									title={`Subscription Order ${subscriptionPlatform}`}>
									<Tag color="green">S</Tag>
								</Tooltip>
							)}

							{record.is_bulk_order && (
								<Tooltip
									placement="bottom"
									title={`Bulk Order (${record.bulk_order_duration} months)`}>
									<Tag color="green">B</Tag>
								</Tooltip>
							)}

							{record.is_single_order && (
								<Tooltip placement="bottom" title="Single Order">
									<Tag color="green">SO</Tag>
								</Tooltip>
							)}
						</span>
					</div>
				);
			},
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},
		{
			title: "Order Name",
			dataIndex: "order_name",
			key: "order_name",
			width: 240,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			width: 112,
			render: (status, record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()} {""}
						{status === "delivered" &&
							!isEmpty(record.deliveryDate) &&
							moment(record.deliveryDate).format("DD-MMM-YYYY")}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "created",
			key: "created",
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned on",
			dataIndex: "assigned",
			key: "assigned",
			render: assigned => {
				if (isEmpty(assigned)) {
					return "-";
				} else {
					return moment(assigned).format("DD MMM YYYY, h:mm a");
				}
			},
		},
	];

	return (
		<>
			<Row justify="start">
				<Col>
					<Title level={2}>My Patients</Title>
				</Col>
			</Row>

			<Row justify="end">
				<Col>
					<OrderFilters
						optionsStatus={optionsStatus}
						setPageNo={setPageNo}
						setQueryParams={setQueryParams}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={data}
						loading={loading}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
						onChange={e => setPageNo(e.current)}
					/>
				</Col>
			</Row>
		</>
	);
}

export default AssignedOrders;

const optionsStatus = [
	{ label: "Delivered", value: "delivered" },
	{ label: "In Progress", value: "in_progress" },
	{ label: "Pending", value: "pending" },
	{ label: "Pending Prescription", value: "pending_prescription" },
	{ label: "Shipped", value: "shipped" },
	{ label: "Shipping Pending", value: "shipping_pending" },
	{ label: "Unknown", value: "unknown" },
	{ label: "Void", value: "void" },
];

const checkIfFilter = () => {
	const query = new URLSearchParams(window.location.search);

	return (
		query.has("startDate") ||
		query.has("endDate") ||
		query.has("status") ||
		query.has("orderType") ||
		query.has("isBulk") ||
		query.has("isSubscription") ||
		query.has("orderSourceType")
	);
};
