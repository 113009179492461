import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import moment from "moment";
import {
	getReminders,
	postFeedback,
	setReminder,
} from "../pages/leadDetails/service";
import { updateReminder } from "../pages/dashboard/service";
import { leadDespositionMapper } from "../managers/caseManager";
import { Button, DatePicker, Input, Modal, Row, Select, Space } from "antd";
const { TextArea } = Input;

const SetReminders = ({
	currComment,
	currDate,
	modalVisible,
	reminder_id,
	reminderType,
	status,
	refreshUI,
	setModalVisible,
	modalType,
}) => {
	const params = useParams();
	const [date, setDate] = useState(null);
	const [comment, setComment] = useState("");

	useEffect(() => {
		setComment(isEmpty(currComment) ? "" : currComment);
		setDate(isEmpty(currDate) ? null : currDate);
	}, [currComment, currDate]);

	const { leadStatus } = useSelector(state => ({
		leadStatus: window.getValue(state, "leaddetails.usersDetail.status"),
	}));

	const optionsFeedback = useMemo(() => {
		if (isEmpty(leadStatus)) return null;
		return leadDespositionMapper(leadStatus);
	}, [leadStatus]);

	const onSubmit = async () => {
		if (status) {
			const data = {
				reminder_id: reminder_id,
				status: status,
				reminder_date: date,
				comment: comment,
			};

			const feedbackData = {
				feedback: comment,
				reminder_id,
			};

			const res = await updateReminder(data);
			if (res.status !== 200) return;

			if (reminderType === "system")
				await postFeedback(feedbackData, params.id);

			refreshUI();
			handleModalClose();

			return;
		}

		const res = await setReminder(params.id, date, comment);
		if (res.status === 200) {
			getReminders(params.id);
			handleModalClose();
		}
		return;
	};

	const handleModal = () => setModalVisible(false);

	const handleModalClose = () => {
		setComment(isEmpty(currComment) ? "" : currComment);
		setDate(isEmpty(currDate) ? null : currDate);
		handleModal();
	};

	const handleComment = e => {
		setComment(e);
		if (e === "Not answered") setDate(currDate);
	};

	return (
		<Modal
			visible={modalVisible}
			title="Reminder"
			onOk={handleModal}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
				<Button
					disabled={isEmpty(date) || isEmpty(comment.trim())}
					key="submit"
					type="primary"
					onClick={onSubmit}>
					Submit
				</Button>,
			]}>
			<Row align="middle" justify="center">
				<Space align="center" direction="vertical">
					<DatePicker
						disabled={comment === "Not answered"}
						disabledDate={current => current.isBefore(moment(), "day")}
						format={"DD MMM, h:mm A"}
						onChange={e => setDate(e)}
						showNow={false}
						showTime={true}
						value={!isEmpty(date) ? date : null}
					/>

					{reminderType === "system" && (
						<Select
							placeholder="Please Select"
							options={optionsFeedback}
							style={{ width: 240 }}
							value={isEmpty(comment) ? null : comment}
							onChange={handleComment}
						/>
					)}

					{modalType === "user" && (
						<TextArea
							onChange={e => setComment(e.target.value)}
							placeholder={isEmpty(comment) ? "Please write comment" : comment}
							rows={4}
							style={{ width: "20rem" }}
							value={comment}
						/>
					)}
				</Space>
			</Row>
		</Modal>
	);
};

export default SetReminders;
