import React from "react";
import { orderReportcols } from "../../helpers";

// Antd
import { Badge, Table, Typography } from "antd/es";
const { Text } = Typography;

const OrderReport = ({ data }) => {
	let reps = data.map(item => {
		return item.rep_name
			? { text: item.rep_name, value: item.rep_name }
			: { text: "NA", value: "NA" };
	});
	const columns = [
		{
			title: "Hair Expert",
			dataIndex: "rep_name",
			key: "bde_name",
			width: 192,
			render: (rep, { rep_level }) => (
				<div className="flex items-center gap-1">
					<Text strong>{rep || "NA"}</Text>
					<Badge
						count={rep_level}
						style={{ backgroundColor: "#dcfce7", color: "#166534" }}
					/>
				</div>
			),
			filters: reps,
			filterMode: "tree",
			filterSearch: true,
			onFilter: (value, record) => {
				return record.rep_name ? record.rep_name === value : !record.rep_name;
			},
		},
		{
			title: "New #",
			dataIndex: "new",
			key: "new",
		},
		{
			title: "Old #",
			dataIndex: "old",
			key: "old",
		},
		{
			title: "Revenue",
			dataIndex: "revenue",
			key: "revenue",
		},
		{
			title: "AOV",
			key: "aov",
			dataIndex: "aov",
		},
		{
			title: "Void Orders",
			dataIndex: "void",
			key: "void",
		},
		{
			title: "Void Revenue",
			key: "void_revenue",
			dataIndex: "void_revenue",
			render: value => <Text strong>{value}</Text>,
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={data}
			scroll={{ y: 272 }}
			pagination={false}
			summary={pageData => {
				const sum = pageData.reduce((a, c) => {
					orderReportcols.forEach(col => {
						if (col in a) a[col] = a[col] + Number(c[col]);
						else a[col] = Number(c[col]);
					});

					return a;
				}, {});

				return (
					<Table.Summary fixed className="bg-success-50">
						<Table.Summary.Row>
							<Table.Summary.Cell>
								<Text strong>Total</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell>
								<Text strong>{sum["new"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell>
								<Text strong>{sum["old"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell>
								<Text strong>{Number(sum["revenue"]).toFixed(2)}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell>
								<Text strong>
									{Number(sum["revenue"] / (sum["new"] + sum["old"])).toFixed(
										2
									)}
								</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell>
								<Text strong>{sum["void"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell>
								<Text strong>{Number(sum["void_revenue"]).toFixed(2)}</Text>
							</Table.Summary.Cell>
						</Table.Summary.Row>
					</Table.Summary>
				);
			}}
		/>
	);
};

export default OrderReport;
