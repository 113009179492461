import React, { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { getValue } from "../../../helpers";

// Antd
import { Table, Typography } from "antd";
const { Title } = Typography;

function RecentAssignedOrders({
	columns,
	handleFilter,
	loading,
	pageNo,
	paginationInfo,
	setPageNo,
	tableData,
	tableTitle,
}) {
	const data = useMemo(() => {
		if (isEmpty(tableData)) return [];
		return tableData.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			name: getValue(row, "case.user.first_name"),
			order_display_id: getValue(row, "order_display_id"),
			order_status: getValue(row, "status"),
			created: getValue(row, "created_at"),
			count: getValue(row, "count"),
			is_subscription: getValue(row, "is_subscription_order"),
			is_bulk_order: getValue(row, "is_bulk_order"),
			bulk_order_duration: getValue(row, "bulk_order_duration"),
			is_single_order: getValue(row, "is_single_order"),
			order_type: getValue(row, "order_type"),
			order_meta: getValue(row, "order_meta"),
		}));
	}, [tableData]);

	return (
		<Table
			bordered
			columns={columns}
			dataSource={data}
			loading={loading}
			title={() => <Title level={4}>{tableTitle}</Title>}
			pagination={{
				current: pageNo,
				pageSize: 10,
				showSizeChanger: false,
				total: paginationInfo.rowCount,
			}}
			onChange={(pagination, filters) => {
				if (handleFilter) handleFilter(filters);
				setPageNo(pagination.current);
			}}
		/>
	);
}

export default RecentAssignedOrders;
