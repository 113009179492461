import { PlusOutlined } from "@ant-design/icons";
import { Button, Table, Tag, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getLineItemsName, getValue } from "../../../helpers";
import { getQueryParams } from "../../../helpers/getQueryParams";
import { addPatient, getAssignedGhostOrders } from "../service";
const { Text, Title } = Typography;

function AssignedGhostOrders() {
	const history = useHistory();
	const [ordersList, setOrdersList] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [data, setData] = useState([]);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("current")) || 1
	);

	useEffect(() => {
		getAssignedGhostOrders(pageNo);
	}, [pageNo]);

	const { ordersData, loading } = useSelector(state => ({
		ordersData: getValue(state, "orderManagement.assignedGhostOrders"),
		loading: getValue(state, "orderManagement.assignedGhostOrdersLoading"),
	}));

	useMemo(() => {
		if (!isEmpty(ordersData)) {
			setOrdersList(ordersData.data);
			setPaginationInfo(ordersData.pagination);
		}
	}, [ordersData]);

	const setTableData = useCallback(() => {
		const tableData = ordersList.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			name: getValue(row, "order_meta.customer.first_name"),
			phoneno: getValue(row, "order_phone_number"),
			order_display_id: getValue(row, "order_display_id"),
			order_name: getLineItemsName(row.order_meta?.line_items),
			order_status: getValue(row, "status"),
			created: getValue(row, "created_at"),
			order_type: getValue(row, "order_type"),
			sub_status: getValue(row, "sub_status"),
		}));
		setData(tableData);
	}, [ordersList]);

	useEffect(() => {
		if (!isEmpty(ordersList)) {
			setTableData();
		}
	}, [ordersList, setTableData]);

	const handleAddPatient = async number => {
		const response = await addPatient(number);
		if (response) {
			history.push(`/lead-details/${response.case_id}`);
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (name, { case_id, phoneno }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${case_id}`}>
						{name}
					</Link>
					<Text type="secondary">{phoneno}</Text>
				</div>
			),
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},
		{
			title: "Order Name",
			dataIndex: "order_name",
			key: "order_name",
			width: 310,
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			render: (status = "", record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "created",
			key: "created",
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Action",
			render: record => {
				if (record.order_status === "ghost") {
					return (
						<Button
							icon={<PlusOutlined />}
							onClick={() => {
								handleAddPatient(record.key);
							}}
							type="primary">
							Add Patient
						</Button>
					);
				}
			},
		},
	];

	return (
		<>
			<Title>Assigned Ghost Orders</Title>
			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				pagination={{
					current: pageNo,
					pageSize: 10,
					showSizeChanger: false,
					total: paginationInfo.rowCount,
				}}
				onChange={e => {
					setPageNo(e.current);
					return history.push({
						search: `current=${e.current}`,
					});
				}}
			/>
		</>
	);
}

export default AssignedGhostOrders;
