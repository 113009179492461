import groupBy from "lodash/groupBy";
import React, { useEffect, useMemo, useState } from "react";

import { fetchAttemptsByEnagements } from "../../api";
import { secToHrs } from "../../transform";

// Antd
import { Table, Typography } from "antd/es";
const { Text } = Typography;

const attemptText = data => {
	if (!Array.isArray(data)) return "";

	const group = groupBy(data, item => item.unanswered_count);

	let msg = [];

	const dataSet = {};

	for (let i in group) {
		const attempt = Number(i) + 1;
		const el = group[i];

		const finished = el.reduce(
			(acc, curr) => {
				let status = curr.operation_status;
				let unansweredCount = curr.unanswered_count;

				if (status) status = status.toLowerCase();
				if (unansweredCount) unansweredCount = Number(unansweredCount);

				if (status === "completed") {
					acc["completed"] = acc["completed"] + 1;
				} else if (status === "auto_completed") {
					acc["auto_completed"] = acc["auto_completed"] + 1;
				}
				return acc;
			},
			{ completed: 0, auto_completed: 0 }
		);

		dataSet[attempt] = {
			total: el.length,
			completed: finished.completed,
			auto_completed: finished.auto_completed,
			data: el,
		};
	}

	for (let i in dataSet) {
		const el = dataSet[i];
		msg.push(`${i}: ${el.total}-${el.completed}-${el.auto_completed}`);
	}

	return msg;
};

const EngagementReport = ({ data }) => {
	const [tData, setTData] = useState([]);
	useEffect(() => {
		setTData(data);
	}, [data]);

	const reps = useMemo(() => {
		if (!Array.isArray(tData)) return [{}];

		return tData.map(item => {
			return item.medical_rep
				? { text: item.medical_rep, value: item.medical_rep }
				: { text: "NA", value: "NA" };
		});
	}, [tData]);

	const handleExpand = async (expand, record) => {
		if (!expand) return;

		const requests = [];

		const pCalls = record.prescription_calls?.engagements;
		const w1Calls = record.week1_calls?.engagements;
		const w2Calls = record.week2_calls?.engagements;
		const w3Calls = record.week3_calls?.engagements;
		const w4Calls = record.week4_calls?.engagements;
		const w5Calls = record.week5_calls?.engagements;
		const w6Calls = record.week6_calls?.engagements;

		requests.push(fetchAttemptsByEnagements(pCalls));
		requests.push(fetchAttemptsByEnagements(w1Calls));
		requests.push(fetchAttemptsByEnagements(w2Calls));
		requests.push(fetchAttemptsByEnagements(w3Calls));
		requests.push(fetchAttemptsByEnagements(w4Calls));
		requests.push(fetchAttemptsByEnagements(w5Calls));
		requests.push(fetchAttemptsByEnagements(w6Calls));

		let [
			pCallsRes,
			w1CallsRes,
			w2CallsRes,
			w3CallsRes,
			w4CallsRes,
			w5CallsRes,
			w6CallsRes,
			// activeCus
		] = await Promise.allSettled(requests);

		if (pCallsRes) pCallsRes = pCallsRes.value;
		if (w1CallsRes) w1CallsRes = w1CallsRes.value;
		if (w2CallsRes) w2CallsRes = w2CallsRes.value;
		if (w3CallsRes) w3CallsRes = w3CallsRes.value;
		if (w4CallsRes) w4CallsRes = w4CallsRes.value;
		if (w5CallsRes) w5CallsRes = w5CallsRes.value;
		if (w6CallsRes) w6CallsRes = w6CallsRes.value;

		const pCallsText = attemptText(pCallsRes);
		const w1CallsText = attemptText(w1CallsRes);
		const w2CallsText = attemptText(w2CallsRes);
		const w3CallsText = attemptText(w3CallsRes);
		const w4CallsText = attemptText(w4CallsRes);
		const w5CallsText = attemptText(w5CallsRes);
		const w6CallsText = attemptText(w6CallsRes);

		const children = {};
		children["key"] = "unanswered_count";
		children["no_expand"] = true;

		if (pCallsText) children["prescription_calls"] = pCallsText;
		if (w1CallsText) children["week1_calls"] = w1CallsText;
		if (w2CallsText) children["week2_calls"] = w2CallsText;
		if (w3CallsText) children["week3_calls"] = w3CallsText;
		if (w4CallsText) children["week4_calls"] = w4CallsText;
		if (w5CallsText) children["week5_calls"] = w5CallsText;
		if (w6CallsText) children["week6_calls"] = w6CallsText;

		record["children"] = [children];

		setTData([...tData]);
	};

	const columns = [
		{
			title: "Med Rep",
			dataIndex: "medical_rep",
			key: "rep_name",
			filters: reps,
			filterMode: "tree",
			filterSearch: true,
			onFilter: (value, record) => {
				return record.medical_rep
					? record.medical_rep === value
					: !record.medical_rep;
			},
		},
		{
			title: "New Orders",
			dataIndex: "new_orders",
			key: "new_orders",
		},
		{
			title: "Prescription Call",
			dataIndex: "prescription_calls",
			key: "prescription_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Week #1",
			dataIndex: "week1_calls",
			key: "week1_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Week #2",
			dataIndex: "week2_calls",
			key: "week2_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Week #3",
			dataIndex: "week3_calls",
			key: "week3_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Week #4",
			dataIndex: "week4_calls",
			key: "week4_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Week #5",
			dataIndex: "week5_calls",
			key: "week5_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Week #6",
			dataIndex: "week6_calls",
			key: "week6_calls",
			render: data => {
				if (Array.isArray(data)) {
					return (
						<div className="flex flex-col">
							<Text>Attempt:</Text>
							{data.map(item => (
								<Text>{item}</Text>
							))}
						</div>
					);
				} else if (typeof data === "object") {
					return `${data.total}/${data.completed}`;
				}
				return data;
			},
		},
		{
			title: "Call Duration",
			dataIndex: "call_logs",
			key: "call_logs",
			render: data => {
				if (typeof data === "object") return secToHrs(data.callhrs);
				return data;
			},
		},
		{
			title: "Active Customer",
			dataIndex: "active_customer",
			key: "active_customer",
			render: data => {
				if (Array.isArray(data)) {
					return `${data[0].active_cases}`;
				}
				return data;
			},
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={tData}
			scroll={{ y: 272 }}
			pagination={false}
			onExpand={handleExpand}
			expandable={{
				expandedRowRender: () => (
					<Text className="block text-center">
						Attempts are show in total-completed-autoCompleted order
					</Text>
				),
				rowExpandable: record => !record.no_expand,
			}}
		/>
	);
};

export default EngagementReport;
