import { SearchOutlined } from "@ant-design/icons";
import {
	AutoComplete,
	Button,
	Card,
	Checkbox,
	Col,
	List,
	message,
	Row,
	Space,
	Typography,
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
	getGlobalSearchMedRepResult,
	getGlobalSearchResult,
} from "../../service";

const { Option } = AutoComplete;
const { Meta } = Card;
const { Text, Title } = Typography;

function TransferSearch({
	searchValue,
	setSearchValue,
	searchData,
	setSearchData,
	setSelectedSearch,
	selectedSearch,
}) {
	const [isResponse, setIsResponse] = useState(false);
	const [selectedMedRep, setSelectedMedRep] = useState([]);
	const [isCloseDropdown, setIsCloseDropdown] = useState(false);

	useEffect(() => {
		if (isEmpty(selectedSearch)) {
			setSelectedMedRep([]);
			setSearchValue("");
			setSearchData([]);
		}
	}, [selectedSearch]);

	const handleClear = () => {
		setSearchValue("");
		setSearchData([]);
		setSelectedSearch({});
	};

	const handleSearch = async event => {
		setSearchValue(event);
		if (isEmpty(event)) setIsCloseDropdown(false);
	};

	const handleSubmit = async () => {
		setIsResponse(true);
		const response = await getGlobalSearchMedRepResult(searchValue);
		if (!isEmpty(response.userRole)) {
			let findRelatedItem = response.userRole.filter(user =>
				user.user.first_name.toLowerCase().includes(searchValue)
			);

			setSearchData(findRelatedItem);
			setIsResponse(false);
			setIsCloseDropdown(true);
		} else {
			message.error("No data availaible");
			setSearchData([]);
			setIsResponse(false);
		}
	};

	const onChange = (event, value) => {
		let medValue = selectedMedRep.findIndex(e => e.id == value.user_id);
		let tempMedValue = selectedMedRep;
		if (medValue < 0) {
			if (event.target.checked) {
				tempMedValue.push({
					first_name: value.user.first_name,
					id: value.user_id,
				});
				setSelectedMedRep(tempMedValue);
			}
		} else {
			if (!event.target.checked) {
				tempMedValue.splice(medValue, 1);
				setSelectedMedRep(tempMedValue);
			}
		}

		let targetArray = selectedMedRep.map(value => {
			return value.id;
		});

		setSelectedSearch(curr => {
			curr["target"] = targetArray;
			return { ...curr };
		});
	};

	return (
		<>
			<Row>
				<Col>
					<Title level={4} style={{ textAlign: "center" }}>
						To
					</Title>
				</Col>
			</Row>
			<Row>
				<Col span={19}>
					<AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={handleSearch}
						placeholder="Select Med Rep / Med Reps"
						notFoundContent={null}
						value={searchValue}
						open={isCloseDropdown}
						showSearch
						style={{ width: "100%" }}
						suffixIcon={<SearchOutlined />}>
						{!isEmpty(searchData) &&
							searchData.map((searchResult, index) => (
								<Option key={index} value={searchValue}>
									<Card>
										<Row>
											<Col span={2}>
												<Checkbox
													checked={
														selectedMedRep.findIndex(
															e => e.id == searchResult.user_id
														) < 0
															? false
															: true
													}
													onChange={e => onChange(e, searchResult)}
												/>
											</Col>
											<Col>
												<Meta
													title={`${searchResult.user.first_name}`}
													description={
														<>
															<Space direction="vertical">
																<Text>{searchResult.user.email}</Text>
															</Space>
														</>
													}
												/>
											</Col>
										</Row>
									</Card>
								</Option>
							))}
					</AutoComplete>
					{!isEmpty(searchData) && isCloseDropdown && (
						<Card
							style={{
								posistion: "absolute",
								top:
									searchData.length == 1
										? 120
										: searchData.length == 2
										? 230
										: 260,
								width: "100%",
								zIndex: 99,
							}}
							type="inner"
							title="Selection Done"
							extra={
								<Button
									type="primary"
									onClick={() => setIsCloseDropdown(false)}>
									OK
								</Button>
							}
						/>
					)}
				</Col>

				<Col span={4}>
					<Space>
						<Button
							style={{ marginLeft: "9px" }}
							icon={<SearchOutlined />}
							type="primary"
							onClick={handleSubmit}
							disabled={isEmpty(searchValue)}
							loading={isResponse}
						/>
						<Button onClick={handleClear} disabled={isEmpty(searchValue)}>
							Clear
						</Button>
					</Space>
				</Col>
			</Row>
			<Row>
				<Col
					style={{
						width: "100%",
						height: "20rem",
						padding: "1rem",
						border: "2px solid #f0f0f0",
						marginTop: "1rem",
						overflow: "auto",
					}}>
					<Row>
						<Col span={24}>
							<Title level={4}>Selected Agents</Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Space>
								<List
									dataSource={selectedMedRep}
									renderItem={(item, index) => (
										<List.Item>
											<Title level={5}>
												{`${index + 1}. ${item.first_name}`}
											</Title>
										</List.Item>
									)}
								/>
							</Space>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
export default TransferSearch;
