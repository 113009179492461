import moment from "moment";
import React from "react";

// Antd
import { Button, Card, Col, Image, Modal, Row, Typography } from "antd/es";
const { Text } = Typography;

const ViewTicket = ({
  data = {},
  data: { ticket_id, case_id },
  openModel,
  setOpenModel,
}) => {

  const handleModalClose = () => {
    setOpenModel(false);
  };

  return (
    <Modal
      visible={openModel}
      title={`Ticket #${data.key}`}
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Cancel
        </Button>,
      ]}
    >
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Text>Ticket Number</Text>
        </Col>
        <Col span={18}>
          <Text>{data.key}</Text>
        </Col>

        <Col span={6}>
          <Text>Freedesk Ref Id</Text>
        </Col>
        <Col span={18}>
          <Text>
            {data.ticket_id || 'N/A'}
          </Text>
        </Col>

        <Col span={6}>
          <Text>Created</Text>
        </Col>
        <Col span={18}>
          <Text>
            {data.created_at
              ? moment(data.created_at).format("DD MMM YYYY, h:mm a")
              : "-"}
          </Text>
        </Col>

        <Col span={6}>
          <Text>Category</Text>
        </Col>
        <Col span={18}>
          <Text>{data.category}</Text>
        </Col>

        <Col span={6}>
          <Text>Sub Category</Text>
        </Col>
        <Col span={18}>
          <Text>{data.sub_category}</Text>
        </Col>

        <Col span={6}>
          <Text>Priority</Text>
        </Col>
        <Col span={18}>
          <Text>{data.priority}</Text>
        </Col>

        <Col span={6}>
          <Text>Status</Text>
        </Col>
        <Col span={18}>
          <Text>{data.status}</Text>
        </Col>

        <Col span={6}>
          <Text>Order Details</Text>
        </Col>
        <Col span={18}>
          <Text>
            { data.source_meta?.order_details && (
                data.source_meta?.order_details.replaceAll("\r\n", ", ").replace(/,\s*$/, '')
              )
            }
          </Text>
        </Col>

        <Col span={6}>
          <Text>Assigned to</Text>
        </Col>
        <Col span={18}>
          <Text>{data.current_assignment?.first_name}</Text>
        </Col>

        <Col span={6}>
          <Text>Author</Text>
        </Col>
        <Col span={18}>
          <Text>{data.author?.first_name}</Text>
        </Col>

        <Col span={6}>
          <Text>Customer</Text>
        </Col>
        <Col span={18}>
          <Text>{data.customer?.first_name}, {data.customer?.email}</Text>
        </Col>

        <Col span={6}>
          <Text>Description</Text>
        </Col>
        <Col span={18}>
          <Text>{data.comments && data.comments[0]?.description}</Text>
        </Col>

        <Col span={6}>
          <Text>Photos</Text>
        </Col>
        <Col span={18}>
          {data && Array.isArray(data.images) && (
            <div className="justify-items-center ticket__attchments image flex" style={{ flexWrap: "wrap" }}>
              <Image.PreviewGroup>
                {data?.images?.map((image, index) => {
                  if (!image.includes(".pdf")) {
                    return (
                      <p>
                        <Image
                          key={index}
                          height={112}
                          src={image}
                          width={112}
                        />
                      </p>
                    );
                  } else return null;
                })}
                {data?.images?.map((image, index) => {
                  let name  = image.split('?')[0] || null;
                  name = name ? name.split("/") : null;
                  name = name ? name[name.length - 1]: null;
                  name = "PDF: " + name.substring(0, 10) + "..."
                  if (image.includes(".pdf")) {
                    return (
                      <p>
                        <Card style={{ width: "120px", padding: "0.5rem", margin: "0.2rem" }} bordered hoverable>
                          <a href={image} target="_blank">{name || 'View PDF'}</a>
                        </Card>
                      </p>
                    );
                  } else return null;
                })}
              </Image.PreviewGroup>
            </div>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewTicket;
