import Cookies from "js-cookie";
import { medicinesActions, productMappingActions } from "./actions";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";
import { message } from "antd";

export const getProductsMapping = async () => {
	store.dispatch({
		type: productMappingActions.GET_PRODUCT_MAPPING_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}product_mapping`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: productMappingActions.GET_PRODUCT_MAPPING_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: productMappingActions.GET_PRODUCT_MAPPING_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: productMappingActions.GET_PRODUCT_MAPPING_FAILURE,
			payload: error.message,
		});
	}
};

export const postProductMapping = async data => {
	const res = await fetch(`${baseURL.API_URL_BASE}product_mapping`, {
		method: "POST",
		json: true,
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	});
	if (res.status === 200) {
		message.success("Product Mapping Created");
		getProductsMapping();
		return true;
	} else {
		message.error("Failed to Create Product Mapping");
		return false;
	}
};

export const postComboProductMapping = async data => {
	const res = await fetch(`${baseURL.API_URL_BASE}combo_product_mapping`, {
		method: "POST",
		json: true,
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	});
	if (res.status === 200) {
		message.success("Product Mapping Created");
		getProductsMapping();
		return true;
	} else {
		message.error("Failed to Create Product Mapping");
		return false;
	}
};

export const deleteProductMapping = async id => {
	const res = await fetch(`${baseURL.API_URL_BASE}product_mapping/${id}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	});
	if (res.status === 200) {
		message.success("Product Mapping Deleted");
		getProductsMapping();
	} else {
		message.error("Failed to Delete Product Mapping");
	}
};

export const getMedicines = async () => {
	store.dispatch({
		type: medicinesActions.GET_MEDICINES_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}medicines`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: medicinesActions.GET_MEDICINES_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: medicinesActions.GET_MEDICINES_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: medicinesActions.GET_MEDICINES_FAILURE,
			payload: error.message,
		});
	}
};

export const addMedicine = async data => {
	const res = await fetch(`${baseURL.API_URL_BASE}medicine`, {
		method: "POST",
		json: true,
		body: JSON.stringify(data),
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	});
	if (res.status === 200) {
		message.success("Medicine Added");
		getMedicines();
		return true;
	} else {
		message.error("Failed to Add Medicine");
		return false;
	}
};

export const deleteMedicine = async id => {
	const res = await fetch(`${baseURL.API_URL_BASE}medicine/${id}`, {
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	});
	if (res.status === 200) {
		message.success("Medicine Deleted");
		getMedicines();
	} else {
		message.error("Failed to Delete Medicine");
	}
};

export const deleteMasterTag = async tag_id => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}deleteMastertag/${tag_id}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		return Promise.resolve(res);
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};

export const addMasterTag = async tagDetails => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}addMasterTag`, {
			method: "POST",
			body: JSON.stringify({
				tag_name: tagDetails.tag_name,
				tag_slug: tagDetails.tag_slug,
			}),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) return Promise.resolve(res);
		else {
			const errorMessage = await res.json();
			const data = {
				status: res.status,
				errorMessage: errorMessage,
			};
			return Promise.resolve(data);
		}
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};
