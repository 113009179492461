import Cookies from "js-cookie";
import { usersManagmentActions } from "./actions";
import { baseURL } from "../../helpers";
import { store } from "../../redux/store";
import { message } from "antd";
import { handleError } from "../../helpers";
import { getUrl } from "../../constants/apiUrls";
import { fetchRequest } from "../../helpers/fetchRequest";

export const getAllRoles = () => {
  store.dispatch({
    type: usersManagmentActions.GET_ALL_ROLES_LOADING,
    payload: true,
  });
  return fetch(`${baseURL.API_URL_BASE}roles`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  }).then((res) => {
    if (res.status === 200) {
      res
        .json()
        .then((response) => {
          if (response) {
            store.dispatch({
              type: usersManagmentActions.GET_ALL_ROLES_SUCCESS,
              payload: response,
            });
          } else {
            store.dispatch({
              type: usersManagmentActions.GET_ALL_ROLES_FAILURE,
              payload: response,
            });
          }
        })
        .catch((error) => {
          store.dispatch({
            type: usersManagmentActions.GET_ALL_ROLES_FAILURE,
            payload: error.toString(),
          });
          handleError(error);
        });
    } else {
      return handleError(res);
    }
  });
};

export const createNewUser = async (userInfo) => {
  try {
    const res = await fetch(`${baseURL.API_URL_BASE}role_user`, {
      method: "POST",
      json: true,
      body: JSON.stringify(userInfo),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    handleError(res);
    return res;
  } catch (error) {
    throw error;
  }
};

export const getAllUsers = () => {
  store.dispatch({
    type: usersManagmentActions.GET_ALL_USERS_LOADING,
    payload: true,
  });
  return fetch(`${baseURL.API_URL_BASE}users`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${Cookies.get("token")}`,
    },
  }).then((res) => {
    if (res.status === 200) {
      res
        .json()
        .then((response) => {
          if (response) {
            store.dispatch({
              type: usersManagmentActions.GET_ALL_USERS_SUCCESS,
              payload: response,
            });
          } else {
            store.dispatch({
              type: usersManagmentActions.GET_ALL_USERS_FAILURE,
              payload: response,
            });
          }
        })
        .catch((error) => {
          store.dispatch({
            type: usersManagmentActions.GET_ALL_USERS_FAILURE,
            payload: error.toString(),
          });
          handleError(error);
        });
    } else {
      return handleError(res);
    }
  });
};

export const updateUser = async (userid, user) => {
  try {
    const _res = await fetch(`${baseURL.API_URL_BASE}user/${userid}/edit`, {
      method: "PUT",
      json: true,
      body: JSON.stringify({ user }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    handleError(_res);
    return _res;
  } catch (error) {
    throw error;
  }
};

export const setStatus = async (status, id) => {
  try {
    const res = await fetch(
      `${baseURL.API_URL_BASE}user/${id}/toggleActiveStatus?status=${status}`,
      {
        method: "PUT",
        json: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      message.success(`status changed`);
      getAllUsers();
    } else if (res.status === 400) {
      message.error("userId or status is missing");
    } else {
      message.error("network issue");
      handleError(res);
    }
  } catch (error) {
    console.info(error.message);
  }
};

export const getInternalActivities = async () => {
  try {
    store.dispatch({
      type: usersManagmentActions.GET_INTERNAL_ACTIVITY_LOG_LOADING,
      payload: true,
    });
    const res = await fetchRequest(getUrl("internalActivityLog"));
    
    if(res.status !== 200 || res.data.err || res.hasError) {
      message.error("Something went wrong");
      store.dispatch({
        type: usersManagmentActions.GET_INTERNAL_ACTIVITY_LOG_FAILURE,
        payload: res.data.err || "Something went wrong",
      });
      handleError(res);
      return;
    }

    store.dispatch({
      type: usersManagmentActions.GET_INTERNAL_ACTIVITY_LOG_SUCCESS,
      payload: res.data,
    })

  } catch (error) {
    console.info(error.message);
  }
}
