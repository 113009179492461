import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";
import { getMyTeam } from "../supervisor/service";
import { getValue } from "../../helpers";
import isEmpty from "lodash/isEmpty";

//antd
import { Col, Row, Space, Input, Table, Typography } from "antd";
const { Search } = Input;
const { Text, Title } = Typography;
//end

const SupervisorsManageWeeklySchedules = () => {
	const [sd] = useState(moment().startOf("day").toISOString());
	const [ed] = useState(moment().endOf("day").toISOString());
	const [searchText, setSearchText] = useState("");
	const [pageNo, setPageNo] = useState(1);

	useEffect(() => {
		getMyTeam();
	}, []);

	const { myTeam, loading } = useSelector(state => ({
		myTeam: getValue(state, "supervisorsData.myTeam[0].userGroup"),
		loading: getValue(state, "supervisorsData.myTeamLoading"),
	}));

	const presentData = useMemo(() => {
		if (isEmpty(myTeam)) return [];

		return myTeam.map(item => ({
			key: getValue(item, "user.id"),
			first_name: getValue(item, "user.first_name"),
			phone_number: getValue(item, "user.phone_number"),
			email: getValue(item, "user.email"),
			alternate_email: getValue(item, "user.alternate_email"),
			is_active: getValue(item, "user.is_active"),
		}));
	}, [myTeam]);

	const tableData = useMemo(() => {
		if (isEmpty(searchText)) return presentData;

		const _searchText = searchText.toLowerCase().trim();
		return presentData.filter(row => {
			return (
				row.first_name?.toLowerCase().includes(_searchText) ||
				row.email?.toLowerCase().includes(_searchText) ||
				row.phone_number?.includes(searchText.trim())
			);
		});
	}, [presentData, searchText]);

	const handleSearch = text => {
		setSearchText(text);
		setPageNo(1);
	};

	const columns = [
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
			render: (first_name, { key }) => (
				<Link
					className="mr-4 link"
					to={`/med-rep-engagement/${key}?start=${sd}&end=${ed}`}>
					{first_name}
				</Link>
			),
		},
		{
			title: "Phone No",
			dataIndex: "phone_number",
			key: "phone_number",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Alternate Email",
			dataIndex: "alternate_email",
			key: "alternate_email",
		},
		{
			title: "Active Status",
			dataIndex: "is_active",
			key: "is_active",
			render: is_active => <Text>{is_active ? "Active" : "Inactive"}</Text>,
		},
	];

	return (
		<>
			<Row align="middle" justify="space-between">
				<Title level={2}>Manage Weekly Schedule</Title>
				<Col>
					<Space>
						<Search
							placeholder="Name, Email or Phone number"
							onSearch={handleSearch}
							onChange={({ target }) => handleSearch(target.value)}
						/>
					</Space>
				</Col>
			</Row>

			<Table
				bordered
				columns={columns}
				dataSource={tableData}
				loading={loading}
				onChange={e => setPageNo(e.current)}
				pagination={{ current: pageNo, showSizeChanger: false }}
			/>
		</>
	);
};

export default SupervisorsManageWeeklySchedules;
