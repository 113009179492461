import Cookies from "js-cookie";
import { baseURL } from "../../helpers";
import { store } from "../../redux/store";
import { globalSearchActions } from "./actions/globalSearch";

export const getGlobalSearchResult = async key => {
  store.dispatch({
    type: globalSearchActions.GET_GLOBAL_SEARCH_LOADING,
    payload: true,
  });
  try {
    const res = await fetch(
      `${baseURL.API_URL_BASE}caseSearch?key=${encodeURIComponent(key)}`,
      {
        method: "GET",
        json: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      store.dispatch({
        type: globalSearchActions.GET_GLOBAL_SEARCH_SUCCESS,
        payload: data,
      });
    } else {
      store.dispatch({
        type: globalSearchActions.GET_GLOBAL_SEARCH_FAILURE,
        payload: null,
      });
    }
  } catch (error) {
    store.dispatch({
      type: globalSearchActions.GET_GLOBAL_SEARCH_FAILURE,
      payload: error.toString(),
    });
  }
};

export const markStatus = async (status) => {
	try {
	  const res = await fetch(`${baseURL.API_URL_BASE}setLog?status=${status}`, {
		method: "PUT",
		headers: {
		  "Content-Type": "application/json",
		  Authorization: `Bearer ${Cookies.get("token")}`,
		},
	  });
	  return res;
	} catch (error) {
	  console.info(error.toString());
	}
  };

  export const setUserPing = async () => {
    try {
      const res = await fetch(`${baseURL.API_URL_BASE}user/ping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      if (res.status === 200) {
        console.log("ping stored");
      } else {
        console.log(res);
      }
    } catch (error) {
      console.info(error.toString());
    }
  };