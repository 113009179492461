import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import {
	clearOrderItems,
	editPrescription,
	getOrderItems,
	postPrescription,
} from "../../doctors/service";
import { getMedicines } from "../../configure/service";
import { getCurrentUserRole } from "../../../helpers";
import { Loader } from "../../../common";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select, Space } from "antd";
const { TextArea } = Input;

function CreatePrescription({
	data,
	isEdit,
	prescriptionId,
	setClonedOrderIDs,
	setData,
	setShowUI,
}) {
	const [form] = Form.useForm();
	const location = useLocation();
	const [caseId, setCaseId] = useState("");
	const [orderId, setOrderId] = useState("");
	const [formItems, setFormItems] = useState([]);
	const [products, setProducts] = useState([]);
	const [isMessageDisplayed, setIsMessageDisplayed] = useState(false);
	const [medicineByDocType, setMedicineByDocType] = useState([]);
	const [medicineOptions, setMedicineOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [userRole] = useState(getCurrentUserRole());

	const { orderItems, dosageLoading, medicines, medicinesLoading } =
		useSelector(state => ({
			orderItems: window.getValue(state, "doctorsData.orderItems"),
			dosageLoading: window.getValue(state, "doctorsData.dosageLoading"),
			medicines: window.getValue(state, "configure.medicines") || [],
			medicinesLoading: window.getValue(state, "configure.medicinesLoading"),
		}));

	useEffect(() => {
		getMedicines();
	}, []);

	useEffect(() => {
		if (isEmpty(location.state)) clearOrderItems();
		else {
			const norderId = location.state.order.key;
			setCaseId(location.state.caseId);
			setOrderId(norderId);
			if (!isEmpty(norderId)) getOrderItems(norderId);
		}
		setIsMessageDisplayed(false);
	}, [location.state]);

	useEffect(() => {
		const init = async () => {
			let nformItems = [];
			let nproducts = [];

			if (!isEmpty(orderItems)) nproducts = getProducts(orderItems);
			setProducts([...nproducts]);

			if (isEmpty(data)) {
				nformItems = isEmpty(nproducts) ? [] : nproducts;
			} else {
				if (isEdit) {
					nformItems = getEditItems([...data]);

					if (!isMessageDisplayed) {
						message.success({
							content: `Medicines are ready to edit`,
							duration: 1,
							onClose: setIsMessageDisplayed(true),
						});
					}
				} else if (!isEmpty(nproducts)) {
					nformItems = getCloneItems([...data], nproducts);

					if (!isMessageDisplayed) {
						message.success({
							content: `Medicines has been cloned`,
							duration: 1,
							onClose: setIsMessageDisplayed(true),
						});
					}
				}
			}

			setFormItems(isEmpty(nformItems) ? [""] : [...nformItems]);
		};

		init();
	}, [data, isEdit, isMessageDisplayed, orderItems]);

	useEffect(() => {
		form.setFieldsValue({ prescription: formItems });
	}, [form, formItems]);

	// find medicine in medicine master
	const findMedicine = name => medicines.find(med => med.display_name === name);

	// Medicine options
	useEffect(() => {
		const init = () => {
			const filterMedicines = medicines.filter(({ group }) => {
				return group !== "hair_oil" && group !== "combo";
			});

			setMedicineByDocType(() => {
				return userRole === "doctor"
					? filterMedicines.filter(med => {
							return med.type === Cookies.get("doctorType");
					  })
					: [...filterMedicines];
			});
		};

		if (!isEmpty(medicines)) init();
	}, [medicines]);

	useEffect(() => {
		if (!isEmpty(medicineByDocType))
			setMedicineOptions(() => getMedicinesList(medicineByDocType, formItems));
	}, [medicineByDocType, formItems]);

	const handleSubmit = async data => {
		setLoading(true);
		if (isEmpty(data.prescription)) {
			message.info("Nothing to save");
		} else {
			data.prescription.map(item => {
				if (item.isOrderd) item["showRecommendation"] = false;
				else {
					const isMedicineInProducts = products.some(product => {
						return product.Medicine === item.Medicine;
					});

					item["showRecommendation"] = !isMedicineInProducts;
				}

				const product = products.find(product => {
					return product.Medicine === item.Medicine;
				});

				if (product) item["quantity"] = product.quantity;

				const medicine = findMedicine(item.Medicine);
				item["description"] = medicine.description;
				item["info"] = medicine.info;
				item["key"] = medicine.id;
				item["type"] = medicine.type;

				delete item["isOrderd"];

				return item;
			});

			const res = isEdit
				? await editPrescription(caseId, prescriptionId, data)
				: await postPrescription(caseId, orderId, data);

			if (res.status === 200) {
				clearData();
				message.success("Prescription Posted Successfully");
			} else {
				message.error("Failed to post Prescription");
			}
		}
		setLoading(false);
	};

	const clearData = () => {
		form.resetFields();
		if (setClonedOrderIDs) setClonedOrderIDs([]);
		if (setData) setData([]);
		if (setShowUI) setShowUI(false);
		setIsMessageDisplayed(false);
	};

	const handleCancel = () => clearData();

	const handleFieldChange = e => {
		if (!isEmpty(e)) {
			const medicineName = e[0].value;
			const arrIndex = e[0].name[1];
			const fieldName = e[0].name[2];

			const medicine = findMedicine(medicineName);

			if (fieldName === "Medicine" && !isEmpty(medicine)) {
				form.setFields([
					{
						name: ["prescription", arrIndex, "composition"],
						value: medicine.composition,
					},
					{
						name: ["prescription", arrIndex, "Dosage"],
						value: medicine.dosage,
					},
					{
						name: ["prescription", arrIndex, "is_composition"],
						value: medicine.is_composition,
					},
				]);
			}
		}
		const nformItems = form.getFieldsValue().prescription;
		setFormItems([...nformItems]);
	};

	const getMedicinesOptions = med => {
		if (isEmpty(med)) return medicineOptions["all"];
		if (med.showRecommendation) return medicineOptions["all"];

		const _find = medicineByDocType.find(m => m.display_name === med.Medicine);
		if (isEmpty(_find) || isEmpty(_find.group)) return [];
		return medicineOptions[_find.group];
	};

	if (dosageLoading || medicinesLoading) return <Loader />;

	return (
		<Form
			name="create prescription"
			form={form}
			onFinish={handleSubmit}
			onFieldsChange={handleFieldChange}
			layout="vertical"
			style={{ width: "max-content" }}
			initialValues={{ prescription: [""] }}>
			<Form.List name="prescription">
				{(fields, { add, remove }) => (
					<>
						{fields.map(field => (
							<Space
								key={field.key}
								style={{
									display: "flex",
									marginBottom: 8,
								}}
								align="center">
								<Space direction="vertical">
									<Space>
										<Form.Item
											{...field}
											label="Medicine"
											name={[field.name, "Medicine"]}
											fieldKey={[field.fieldKey, "Medicine"]}
											rules={[
												{
													required: true,
													message: "Please Fill this",
												},
											]}>
											<Select
												options={getMedicinesOptions(formItems[field.name])}
												placeholder="Medicine"
												style={{ width: "10rem" }}
												showSearch
											/>
										</Form.Item>

										<Form.Item
											{...field}
											label="Dosage"
											name={[field.name, "Dosage"]}
											fieldKey={[field.fieldKey, "Dosage"]}
											rules={[
												{
													required: true,
													message: "Please Fill this",
												},
											]}>
											<Input placeholder="Dosage" style={{ width: "11rem" }} />
										</Form.Item>

										<Form.Item
											{...field}
											label="Note"
											name={[field.name, "note"]}
											fieldKey={[field.fieldKey, "note"]}>
											<TextArea
												autoSize
												placeholder="Note"
												style={{ width: "10rem" }}
											/>
										</Form.Item>
									</Space>

									{formItems[field.name]?.is_composition && (
										<Row>
											<Space>
												<Form.Item
													{...field}
													label="Composition"
													name={[field.name, "composition"]}
													fieldKey={[field.fieldKey, "composition"]}
													rules={[
														{
															required: true,
															message: "Please Fill this",
														},
													]}>
													<Input
														placeholder="Composition"
														style={{ width: "15rem" }}
													/>
												</Form.Item>
												<Form.Item
													{...field}
													label="Select Booster Oil"
													name={[field.name, "composition"]}
													fieldKey={[field.fieldKey, "composition"]}
													rules={[
														{
															required: true,
															message: "Please Fill this",
														},
													]}>
													<Select
														options={getBoosterOilOptions}
														placeholder="Booster Oil"
														style={{ width: "15rem" }}
														showSearch
													/>
												</Form.Item>
											</Space>
										</Row>
									)}
								</Space>

								{formItems[field.name]?.showRecommendation !== false && (
									<MinusCircleOutlined onClick={() => remove(field.name)} />
								)}
							</Space>
						))}
						<Form.Item>
							<Row justify="space-between" style={{ width: "32rem" }}>
								<Col>
									<Button
										type="dashed"
										onClick={() => add()}
										icon={<PlusOutlined />}>
										Add more
									</Button>
								</Col>
								<Col>
									<Space>
										<Button onClick={handleCancel}>Cancel</Button>
										<Button loading={loading} type="primary" htmlType="submit">
											Save
										</Button>
									</Space>
								</Col>
							</Row>
						</Form.Item>
					</>
				)}
			</Form.List>
		</Form>
	);
}

export default CreatePrescription;

const getCloneItems = (items, products) => {
	const cloneItems = items.filter(item => {
		if (products.some(product => product.Medicine === item.Medicine)) {
			delete item["isOrderd"];
			delete item["isRecommended"];
			return true;
		} else {
			return false;
		}
	});

	return cloneItems;
};

const getProducts = orderItems => {
	const _orderItems = orderItems.filter(order => order.group !== "combo");
	const items = _orderItems.map(item => ({
		composition: item.composition,
		Dosage: item.dosage,
		is_composition: item.is_composition,
		isOrderd: true,
		quantity: item.fulfillable_quantity,
		Medicine: item.display_name,
		showRecommendation: false,
	}));

	return items;
};

const getEditItems = items => {
	const editItem = items.map(item => {
		item["isOrderd"] = !item.showRecommendation;
		return item;
	});

	return editItem;
};

const getMedicinesList = (medicines, prescription) => {
	let medicinesList = [];
	if (isEmpty(prescription)) {
		medicinesList = medicines.map(medicine => ({
			label: medicine.display_name,
			value: medicine.display_name,
			group: medicine.group,
		}));
	} else {
		const filterMedicines = medicines.filter(med => {
			return !prescription.some(p => p?.Medicine === med.display_name);
		});

		medicinesList = filterMedicines.map(medicine => ({
			label: medicine.display_name,
			value: medicine.display_name,
			group: medicine.group,
		}));
	}

	const _group = groupBy(medicinesList, med => med.group);
	_group["all"] = medicinesList;

	return _group;
};

const getBoosterOilOptions = [
	{ value: "Scalp Therapy Booster Oil", label: "Scalp Therapy Booster Oil" },
	{ value: "Calm Therapy Booster Oil ", label: "Calm Therapy Booster Oil" },
	{ value: "Growth Therapy Booster Oil ", label: "Growth Therapy Booster Oil" },
	{
		value: "Dandruff Therapy Booster Oil ",
		label: "Dandruff Therapy Booster Oil",
	},
	{ value: "Base Oil", label: "Base Oil" },
];
