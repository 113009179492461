import React, { useEffect, useState } from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, List, Typography, Space } from "antd";
const { Text } = Typography;

function UserReminders({
  loading,
  reminders,
  setComment,
  setMarkDoneModal,
  setReminderDate,
  setReminderId,
  setRemindersModal,
  setReminderStatus,
  setReminderType,
  setModalType,
}) {
  const [data, setData] = useState([]);

  let reminder = [];
  let reminderData = [];

  data.map(reminders => {
    if (reminders.type === "user") {
      reminder.push(reminders);
    }
    reminderData = [...reminder];
  });

  useEffect(() => {
    if (isEmpty(reminders)) setData([]);
    else setData(reminders);
  }, [reminders]);

  return (
    <div style={{ height: 304, overflowY: "scroll" }}>
      <List
        dataSource={reminderData}
        loading={loading}
        size="large"
        renderItem={item => (
          <List.Item type="flex" justify="space-between">
            <List.Item.Meta
              title={<Text>{item.status}</Text>}
              description={item.comment}
            />
            <Space size={4}>
              <Text type="secondary">
                {moment(item.reminder_date).format("DD MMM YYYY, h:mm a")}
              </Text>
              <Button
                icon={<CheckOutlined />}
                onClick={() => {
                  setMarkDoneModal(true);
                  setReminderId(item.id);
                  setReminderType(item.type);
                }}
                type="link"
              ></Button>

              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  setComment(item.comment);
                  setReminderDate(moment(item.reminder_date));
                  setReminderId(item.id);
                  setReminderStatus(item.status);
                  setRemindersModal(true);
                  setModalType("user");
                }}
                type="link"
              ></Button>
            </Space>
          </List.Item>
        )}
      />
    </div>
  );
}

export default UserReminders;
