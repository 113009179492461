import { baseURL } from "../helpers";

export const TRAYA_CART_LINK = "https://traya.health?cart=";
export const UTM_INFO =
	"utm_medium=hair_expert&utm_source=direct&utm_campaign=";

export const RESULT_PAGE_URL = (syntheticId, cohort = null, gender) => {
	const BASEURL =
		gender === "F" ? baseURL.PREVIEW_URL.FEMALE : baseURL.PREVIEW_URL.MALE;

	return cohort
		? `${BASEURL}?id=${syntheticId}&cohort=${cohort}`
		: `${BASEURL}?id=${syntheticId}`;
};

export const RESULT_CHOLESTROL = (id, cohort = null) => {
	return cohort
		? `https://traya.health/pages/result-cholesterol?id=${id}&cohort=${cohort}`
		: `https://traya.health/pages/result-cholesterol?id=${id}`;
};
