import React, { useEffect, useState } from "react";
import { Checkbox, message, Tooltip } from "antd/es";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { baseURL, getValue } from "../../../helpers";
import { useSelector } from "react-redux";
import { viewDetails } from "../service";

const CheckboxMedRep = ({ caseId }) => {
	const [checked, setChecked] = useState(false);
	const [loading, setLoading] = useState(false);

	const {
		usersDetail: { meta },
	} = useSelector(state => ({
		usersDetail: getValue(state, "leaddetails.usersDetail"),
	}));

	useEffect(() => {
		if (!meta.blacklistedReps) return;
		setChecked(meta.blacklistedReps.includes(meta.original_owner));
	}, [meta]);

	const blackListCurrentRep = async target => {
		let res = {};
		try {
			setLoading(true);

			res = await fetchRequest(
				`${baseURL.API_URL_BASE}case/${caseId}/blacklist-rep`,
				{
					method: "PUT",
					body: JSON.stringify({ blacklist: target.checked }),
				}
			);
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) message.error("Failed to mark Change Assignee");
			else {
				message.success("Change Assignee marked successfully");
				setChecked(target.checked);
				viewDetails(caseId);
			}
			setLoading(false);
		}
	};

	return (
		<>
			<Tooltip
				title="If this check box is enabled, and if the customer places an order, then it will be reassigned to another hair coach."
				placement="top">
				<Checkbox
					checked={checked}
					disabled={loading}
					onChange={({ target }) => blackListCurrentRep(target)}>
					Change Assignee
				</Checkbox>
			</Tooltip>
		</>
	);
};

export default CheckboxMedRep;
