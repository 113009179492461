import { loginActions } from "../actions/login";

export const login = (state = {}, action) => {
  switch (action.type) {
    case loginActions.LOGIN_LOADING:
      return {
        ...state,
        loading: true,
      };
    case loginActions.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.payload,
        loading: false,
      };
    case loginActions.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loginData: null,
      };
    case loginActions.LOGOUT_LOADING:
      return {
        ...state,
        logoutData: action.payload,
        logoutLoading: true,
      };
    case loginActions.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutData: action.payload,
        logoutLoading: false,
      };
    case loginActions.LOGOUT_FAILURE:
      return {
        ...state,
        logoutData: action.payload,
        logoutLoading: false,
      };
    default:
      return state;
  }
};
