import actions from "./action";

const reducer = (state = {}, action) => {
	switch (action.type) {
		case actions.GET_SLOT_BOOKINGS_LOADING:
			return {
				...state,
				bookings: [],
				loading: true,
			};
		case actions.GET_SLOT_BOOKINGS_SUCCESS:
			return {
				...state,
				bookings: action.payload,
				loading: false,
			};
		case actions.GET_SLOT_BOOKINGS_FAILURE:
			return {
				...state,
				bookings: [],
				loading: false,
			};

		default:
			return state;
	}
};

export default reducer;
