import Cookies from "js-cookie";
import { baseURL } from "../../helpers";
import { message } from "antd";

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const escalateCase = async (caseId, escalatedTo, comment) => {
	let res = "";

	try {
		res = await fetch(`${baseURL.API_URL_BASE}escalate-case/${caseId}`, {
			method: "POST",
			body: JSON.stringify({ comment: comment, raised_for: escalatedTo }),
			...AUTH_HEADER,
		});
		if (res.status === 200) return res;
	} catch (err) {
		throw err;
	}
};

export const resolveEscalation = async (caseId, comment) => {
	let res = "";

	try {
		res = await fetch(
			`${baseURL.API_URL_BASE}resolve-escalate-case/${caseId}`,
			{
				method: "PUT",
				body: JSON.stringify({ comment: comment }),
				...AUTH_HEADER,
			}
		);
	} catch (error) {
		console.warn(error);
	}

	if (res.status === 200) message.success("Escalation resolved successfully");
	else message.error("Failed to resolve escalation");

	return res;
};

export const finishEscalation = async (case_id, comment) => {
	try {
		let res = await fetch(
			`${baseURL.API_URL_BASE}case/${case_id}/finishEscalate`,
			{
				method: "PUT",
				body: JSON.stringify({ comment_text: comment }),
				...AUTH_HEADER,
			}
		);
		if (res.status === 200) return res;
	} catch (err) {
		throw err;
	}
};

export const getEscalatedCases = async () => {
	let data = null;
	let hasError = false;

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}get-escalation-raised-info`, {
			method: "GET",
			...AUTH_HEADER,
		});

		if (_res.status !== 200) hasError = true;
		data = await _res.json();
	} catch (error) {
		console.warn(error);
	}

	return { data, hasError };
};

export const getAssignedPersons = async (orderId) => {
	let data = null;
	let hasError = false;

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}assigned_doctors/${orderId}`, {
			method: "GET",
			...AUTH_HEADER,
		});

		if (_res.status !== 200) hasError = true;
		data = await _res.json();
	} catch (error) {
		console.warn(error);
	}

	return { data, hasError };
};

export const getAssignedEscalatedCases = async () => {
	let data = null;
	let hasError = false;

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}assignedEscalatedCases`, {
			method: "GET",
			...AUTH_HEADER,
		});

		if (_res.status !== 200) hasError = true;
		data = await _res.json();
	} catch (error) {
		console.warn(error);
	}

	return { data, hasError };
}

export const getEscalationLogs = async (caseId) => {
	let data = null;
	let hasError = false;

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}get-escalation-logs/${caseId}`, {
			method: "GET",
			...AUTH_HEADER,
		});

		if (_res.status !== 200) hasError = true;
		data = await _res.json();
	} catch (error) {
		console.warn(error);
	}

	return { data, hasError };
}
