import { dispatchDashboardActions } from "../actions/index";

export const dispatchdashboard = (state = {}, action) => {
  switch (action.type) {
    case dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_LOADING:
      return {
        ...state,
        prescriptionPendingItemsLoading: true,
      };
    case dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_SUCCESS:
      return {
        ...state,
        getPrescriptionPendingItemsData: action.payload,
        prescriptionPendingItemsLoading: false,
      };
    case dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE:
      return {
        ...state,
        getPrescriptionPendingItemsData: action.payload,
        prescriptionPendingItemsLoading: false,
      };

    case dispatchDashboardActions.POST_MARK_SHIPPED_LOADING:
      return {
        ...state,
        postMarkShippedLoding: true,
      };
    case dispatchDashboardActions.POST_MARK_SHIPPED_SUCCESS:
      return {
        ...state,
        postMarkShippedData: action.payload,
        postMarkShippedLoding: false,
      };
    case dispatchDashboardActions.POST_MARK_SHIPPED_FAILURE:
      return {
        ...state,
        postMarkShippedData: action.payload,
        postMarkShippedLoding: false,
      };

    default:
      return state;
  }
};
