const generateQueryParams = items => {
	const query = new URLSearchParams(window.location.search);

	for (let key in items) {
		if (items[key]) {
			if (query.has(key)) query.set(key, items[key]);
			else query.append(key, items[key]);
		} else {
			query.delete(key);
		}
	}

	return query.toString();
};

export default generateQueryParams;
