import React, { useMemo } from "react";

// Antd
import { Button, Table } from "antd";

const TargetManagement = ({ data, loading, setCurrTarget, setshowModal }) => {
	const columns = useMemo(() => {
		return [
			{
				title: "Name",
				dataIndex: "rep_name",
				key: "rep_name",
			},
			{
				title: "Target Calls",
				key: "target",
				render: ({ fullfilled_calls, target_calls }) => {
					if (fullfilled_calls || target_calls)
						return `${fullfilled_calls} / ${target_calls}`;
					return "-";
				},
			},
			{
				title: "Target Orders",
				key: "revenue",
				render: ({ fullfilled_orders, target_orders }) => {
					if (fullfilled_orders || target_orders)
						return `${fullfilled_orders} / ${target_orders}`;
					return "-";
				},
			},
			{
				title: "Action",
				key: "action",
				render: record => (
					<Button
						type="link"
						onClick={() => {
							setshowModal(true);
							setCurrTarget(record);
						}}>
						Edit
					</Button>
				),
			},
		];
	}, []);

	return (
		<Table
			bordered
			columns={columns}
			dataSource={data}
			loading={loading}
			pagination={false}
		/>
	);
};

export default TargetManagement;
