import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { fetchRequest } from "../../helpers/fetchRequest";
import { NOTIFICATIONS_API } from "../../constants/apiUrls";

// Ant design
import { BellOutlined } from "@ant-design/icons";
import { Button, Empty, Popover, Skeleton, Typography } from "antd";
import { Link } from "react-router-dom";
const { Text, Title } = Typography;

const BellNotification = () => {
	const [loading, setLoading] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [visible, setVisible] = useState(false);

	const getNotifications = useCallback(async () => {
		if (!visible) return;

		setLoading(true);
		let res = {};

		try {
			res = await fetchRequest(NOTIFICATIONS_API);
		} catch (error) {
			console.warn(error.message);
		} finally {
			setLoading(false);
			if (res.hasError) setNotifications([]);
			else setNotifications(res.data);
		}
	}, [visible]);

	useEffect(() => {
		getNotifications();
	}, [getNotifications]);

	const notificationData = useMemo(() => {
		if (!notifications && notifications.length <= 0) return [];

		const _data = notifications.map(item => JSON.parse(item));
		_data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

		return _data;
	}, [notifications]);

	return (
		<Popover
			placement="bottomRight"
			title={<Title level={3}>Notifications</Title>}
			trigger="click"
			visible={visible}
			onVisibleChange={() => setVisible(curr => !curr)}
			overlayInnerStyle={{ width: 400, height: 480, overflowY: "auto" }}
			content={
				<Skeleton active loading={loading}>
					<div className="grid gap-2">
						{isEmpty(notificationData) ? (
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={<span>No notification</span>}
							/>
						) : (
							notificationData.map(item => (
								<Link
									to={`/lead-details/${item.message.case_id}`}
									className="grid bg-success-50 p-2"
									onClick={() => setVisible(false)}>
									<Text strong>{item.title}</Text>
									<Text>{item.message.text}</Text>
									<Text style={{ textAlign: "right" }} type="secondary">
										{moment(item.created_at).format("DD/MM/YYYY")}
									</Text>
								</Link>
							))
						)}
					</div>
				</Skeleton>
			}>
			<Button icon={<BellOutlined />} type="link" />
		</Popover>
	);
};

export default BellNotification;
