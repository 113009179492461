import actions from "./action";

export const initialState = {
	loading: false,
	data: [],
	disposeCall: {
		case_id: null,
		user_id: null,
		call_id: null,
	},
	error: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_MISSED_CALL_TO_DISPOSE:
			return {
				...state,
				disposeCall: action.payload,
			};
		case actions.GET_MISSED_CALLS_LOADING:
			return {
				...state,
				data: [],
				loading: true,
				error: null,
			};
		case actions.GET_MISSED_CALLS_SUCCESS:
			return {
				...state,
				data: action.payload,
				loading: false,
				error: null,
			};
		case actions.GET_MISSED_CALLS_FAILURE:
			return {
				...state,
				data: [],
				loading: false,
				error: action.payload,
			};
		case actions.DELETE_DISPOSED_MISS_CALL:
			return {
				...state,
				data: state.data.filter(call => call._id !== action.payload)
			}
		default:
			return state;
	}
};

export default reducer;
