export const feedOptions = [
	{ label: "Number doesn't exists", value: "Number doesn't exists" },
	{ label: "Busy Call later", value: "Busy Call later" },
	{ label: "Pitched waiting for order", value: "Pitched waiting for order" },
	{ label: "Not answered", value: "Not answered" },
	{ label: "Dead Lead", value: "Dead Lead" },
	{ label: "Price Issue", value: "Price Issue" },
	{ label: "Trust Issue", value: "Trust Issue" },
	{ label: "Product Issue", value: "Product Issue" },
	{ label: "Not looking for Treatment", value: "Not looking for Treatment" },
	{ label: "Technical Call issue", value: "Technical Call issue" },
	{ label: "Customer Now", value: "Customer Now" },
	{ label: "Treatment not possible", value: "Treatment not possible" },
	{ label: "Others (Enter in Comment)", value: "Others" },
	{
		label: "Number busy",
		value: "Number busy",
	},
	{
		label: "Out of network",
		value: "Out of network",
	},
	{
		label: "Switched off",
		value: "Switched off",
	},
	{
		label: "Unavailable",
		value: "Unavailable",
	},
	{
		label: "Demo/Dummy leads",
		value: "Demo/Dummy leads",
	},
	{
		label: "Incoming barred",
		value: "Incoming barred",
	},
	{
		label: "Incorrect number",
		value: "Incorrect number",
	},
	{
		label: "Payment link sent",
		value: "Payment link sent",
	},
	{
		label: "Already a customer",
		value: "Already a customer",
	},
	{
		label: "Not interested",
		value: "Not interested",
	},
	{
		label: "Needs to discuss with family",
		value: "Needs to discuss with family",
	},
	{
		label: "Disqualified",
		value: "disqualified",
	},
	{
		label: "Future Prospect",
		value: "Future Prospect",
	},
	{
		label: "Slot Booked For Later",
		value: "Slot Booked For Later",
	},
	{
		label: "Pitched, link not sent",
		value: "Pitched, link not sent",
	},
	{
		label: "Pitched, link sent",
		value: "Pitched, link sent",
	},
];

export const feedOptionsMarkDone = [
	{ label: "Number doesn't exists", value: "Number doesn't exists" },
	{ label: "Pitched waiting for order", value: "Pitched waiting for order" },
	{ label: "Dead Lead", value: "Dead Lead" },
	{ label: "Price Issue", value: "Price Issue" },
	{ label: "Trust Issue", value: "Trust Issue" },
	{ label: "Product Issue", value: "Product Issue" },
	{ label: "Not looking for Treatment", value: "Not looking for Treatment" },
	{ label: "Technical Call issue", value: "Technical Call issue" },
	{ label: "Customer Now", value: "Customer Now" },
	{ label: "Treatment not possible", value: "Treatment not possible" },
	{ label: "Others (Enter in Comment)", value: "Others" },
];
