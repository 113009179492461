export const reportActions = {
  GET_REORDER_REPORT_LOADING: "GET_REORDER_REPORT_LOADING",
  GET_REORDER_REPORT_SUCCESS: "GET_REORDER_REPORT_SUCCESS",
  GET_REORDER_REPORT_FAILURE: "GET_REORDER_REPORT_FAILURE",

  GET_ALL_ENGAGEMENT_LOADING: "GET_ALL_ENGAGEMENT_LOADING",
  GET_ALL_ENGAGEMENT_SUCCESS: "GET_ALL_ENGAGEMENT_SUCCESS",
  GET_ALL_ENGAGEMENT_FAILURE: "GET_ALL_ENGAGEMENT_FAILURE",

  GET_PRESCRIPTION_REPORT_LOADING: "GET_PRESCRIPTION_REPORT_LOADING",
  GET_PRESCRIPTION_REPORT_SUCCESS: "GET_PRESCRIPTION_REPORT_SUCCESS",
  GET_PRESCRIPTION_REPORT_FAILURE: "GET_PRESCRIPTION_REPORT_FAILURE",

  GET_REORDER_DATA_LOADING: "GET_REORDER_DATA_LOADING",
  GET_REORDER_DATA_SUCCESS: "GET_REORDER_DATA_SUCCESS",
  GET_REORDER_DATA_FAILURE: "GET_REORDER_DATA_FAILURE",
};
