import React from "react";
import { leadActionsReportcols } from "../../helpers";

// Antd
import { Badge, Table, Typography } from "antd/es";
const { Text } = Typography;

const LeadActionsReport = ({ data }) => {
	let reps = data.map(item => {
		return item.rep_name
			? { text: item.rep_name, value: item.rep_name }
			: { text: "NA", value: "NA" };
	});

	const columns = [
		{
			title: "Hair Expert",
			dataIndex: "rep_name",
			key: "bde_name",
			width: 192,
			render: (rep, { rep_level }) => (
				<div className="flex items-center gap-1">
					<Text strong>{rep || "NA"}</Text>
					<Badge
						count={rep_level}
						style={{ backgroundColor: "#dcfce7", color: "#166534" }}
					/>
				</div>
			),
			filters: reps,
			filterMode: "tree",
			filterSearch: true,
			onFilter: (value, record) => {
				return record.rep_name ? record.rep_name === value : !record.rep_name;
			},
		},
		{
			title: "Slot Leads",
			dataIndex: "bookedSlots",
			key: "bookedSlots",
			render: value => value.length,
		},
		{
			title: "No Bookings",
			dataIndex: "noBookings",
			key: "noBookings",
			render: value => value.length,
		},
		{
			title: "New",
			dataIndex: "new",
			key: "new",
			render: value => value.length,
		},
		{
			title: "Invalid",
			key: "invalid",
			dataIndex: "invalid",
			render: value => value.length,
		},
		{
			title: "Dropped",
			dataIndex: "dropped",
			key: "dropped",
			render: value => value.length,
		},

		{
			title: "Not Answered",
			key: "not_connected",
			dataIndex: "not_connected",
			render: value => value.length,
		},
		{
			title: "Cold",
			key: "cold",
			dataIndex: "cold",
			render: value => value.length,
		},
		{
			title: "Hot",
			key: "hot",
			dataIndex: "hot",
			render: value => value.length,
		},
		{
			title: "Total",
			dataIndex: "allLeads",
			key: "allLeads",
			render: value => <Text strong>{value.length}</Text>,
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={data}
			scroll={{ y: 272 }}
			pagination={false}
			summary={pageData => {
				const sum = pageData.reduce((a, c) => {
					leadActionsReportcols.forEach(col => {
						if (!c[col]) return;

						if (col in a) a[col] = a[col] + c[col].length;
						else a[col] = c[col].length;
					});
					return a;
				}, {});

				return (
					<Table.Summary fixed className="bg-success-50">
						<Table.Summary.Row>
							<Table.Summary.Cell>
								<Text strong>Total</Text>
							</Table.Summary.Cell>

							{leadActionsReportcols.map(col => (
								<Table.Summary.Cell key={col}>
									<Text strong>{sum[col]}</Text>
								</Table.Summary.Cell>
							))}
						</Table.Summary.Row>
					</Table.Summary>
				);
			}}
		/>
	);
};

export default LeadActionsReport;
