import React, { useCallback, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { resolveEscalation } from "./service";
import { getComments, getEscalationStatus, viewDetails } from "../../pages/leadDetails/service";

// Ant Design
import Button from "antd/es/button";
import Input from "antd/es/input";
import Modal from "antd/es/modal";
import Row from "antd/es/row";
import { getCurrentUserRole } from '../../helpers';
const { TextArea } = Input;
const repId = localStorage.getItem('users');

export const ResolveEscalation = () => {
	const userRole = getCurrentUserRole();
	const params = useParams();
	const [showModal, setShowModal] = useState(false);
	const [escalationStatus, setEscalationStatus] = useState(null);
	const [isEscalationAssigned, setIsEscalationAssigned] = useState(false);

	const fetchEscalationStatus = useCallback(async() => {
		const res = await getEscalationStatus(params.id)
		if (res && res[0]) {
			if(res[0].resolved_by) {
				setEscalationStatus('resolved');
			} else {
				setEscalationStatus('escalated');
			}
			if(repId === res[0].raised_for) setIsEscalationAssigned(true);
		} else {
			setEscalationStatus(false);
		}
	}, [params.id]);

	useEffect(() => {
		fetchEscalationStatus(params.id);
	}, [fetchEscalationStatus, params.id]);

	useEffect(() => {
		if (userRole === 'admin') {
			setIsEscalationAssigned(true);
		}
	}, [userRole]);

	if(escalationStatus === 'escalated' && isEscalationAssigned) {
		return (
			<>
				<Button
					id="resolve_escalation_modal_btn"
					onClick={() => setShowModal(true)}
					type="primary">
					Resolve
				</Button>
	
				<ResolveEscalationModal
					isVisible={showModal}
					setIsVisible={setShowModal}
					refreshUi={fetchEscalationStatus}
				/>
			</>
		);
	} else {
		return null;
	}
};

const ResolveEscalationModal = ({ isVisible, setIsVisible, refreshUi }) => {
	const [comment, setComment] = useState("");
	const { id: case_id } = useParams();
	const [loading, setLoading] = useState(false);

	const handleModalClose = () => {
		setComment("");
		setIsVisible(false);
	};

	const handleResolve = async () => {
		setLoading(true);
		const _res = await resolveEscalation(case_id, comment);
		setLoading(false);

		if (_res.status === 200) {
			handleModalClose();
			refreshUi();
			await viewDetails(case_id);
			await getComments(case_id);
		}
	};

	return (
		<Modal
			visible={isVisible}
			title="Resolve Escalation"
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button
					id="resolve_escalation_cancel_btn"
					key="back"
					onClick={handleModalClose}>
					Cancel
				</Button>,
				<Button
					disabled={isEmpty(comment.trim())}
					id="resolve_escalation_resolve_btn"
					key="submit"
					type="primary"
					onClick={() => handleResolve()}
					loading={loading}>
					Resolve
				</Button>,
			]}>
			<Row justify="center">
				<TextArea
					id="resolve_escalation_comment_box"
					onChange={({ target }) => setComment(target.value)}
					placeholder="Please put your comment here"
					rows={4}
					value={comment}
				/>
			</Row>
		</Modal>
	);
};
