import { Button, Col, Row, Space, Table, Tag, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { getLineItemsName } from "../../../helpers";
import { handleSearch, incrementPrintCount } from "../service";
import DispatchSearch from "./DispatchSearch";
import PlainPrint from "./PlainPrint";
import Print from "./Print";

const { Text } = Typography;

function Search() {
	const [searchItems, setSearchItems] = useState([]);
	const [data, setData] = useState([]);
	const [searchData, setSearchData] = useState("");
	const [pageNo, setPageNo] = useState(1);
	const [paginationInfo, setPaginationInfo] = useState(0);
	const [searchItemsLoading, setSearchItemsLoading] = useState(false);
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [isPrint, setIsPrint] = useState(false);
	const [isPlainPrint, setIsPlainPrint] = useState(false);

	useEffect(() => {
		const callSearch = async () => {
			setSearchItemsLoading(true);
			const response = await handleSearch(searchData);
			if (response.status === 200) {
				setSearchItemsLoading(false);
				setSearchItems(response.data);
				setPaginationInfo(response.data.length);
			} else {
				setSearchItemsLoading(false);
				setSearchItems("");
				setPaginationInfo(0);
			}
		};
		if (!isEmpty(searchData)) {
			callSearch();
		} else {
			setSearchItems([]);
			setPageNo(1);
		}
	}, [searchData]);

	useEffect(() => {
		if (isEmpty(searchItems)) setData([]);
		else {
			const tableData = searchItems.map(row => ({
				key: window.getValue(row, "id"),
				customer_name: window.getValue(row, "case.user.first_name"),
				created_at: window.getValue(row, "created_at"),
				order_name: getLineItemsName(row.order_meta?.line_items),
				order_number: window.getValue(row, "order_number"),
				prescription: window.getValue(row, "prescription"),
				status: window.getValue(row, "status"),
				count: window.getValue(row, "count"),
				order_display_id: window.getValue(row, "order_display_id"),
				user_id: window.getValue(row, "user_id"),
				line_items: window.getValue(row, "order_meta.line_items"),
				print_count: window.getValue(row, "print_count"),
				sub_status: window.getValue(row, "sub_status"),
			}));

			setData(tableData);
		}
	}, [searchItems]);

	const columns = [
		{
			title: "Order Display No",
			dataIndex: "order_display_id",
			key: "order_display_id",
			width: "160px",
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			width: "160px",
			render: count => (
				<Tag key={count} color="geekblue">
					{count === 1 ? "NEW" : "REPEAT"}
				</Tag>
			),
		},
		{
			title: "Order Status",
			dataIndex: "status",
			key: "status",
			width: "120px",
			align: "center",
			render: (status, record) => {
				return (
					<>
						<Tag key={status} color="geekblue">
							{status?.toUpperCase()}
						</Tag>
						{record?.sub_status && (
							<Tag color="geekblue">
								{record.sub_status.toUpperCase()} {""}
							</Tag>
						)}
					</>
				);
			},
		},
		{
			title: "Details",
			key: "details",
			render: record => {
				const prescription = record.prescription;
				let prescriptionData = [];
				let printData = [];
				let recommendedPrescriptionData = [];

				prescription.map(prescription => {
					if (prescription.version === 1) {
						const prescriptionList = prescription.prescription;
						prescriptionData.push(prescriptionList);
					} else {
						const prescriptionList = JSON.parse(
							prescription.prescription
						).prescription;
						prescriptionList.map(prescriptionList => {
							if (prescriptionList.showRecommendation === false) {
								printData.push(prescriptionList);
							} else {
								recommendedPrescriptionData.push(prescriptionList);
							}
						});
						return (prescriptionData = [
							...printData,
							...recommendedPrescriptionData,
						]);
					}
				});

				let nestedData = [];
				if (prescriptionData) {
					nestedData = prescriptionData.map(data => ({
						Medicine: data,
						Dosage: data.Dosage,
						Note: data.note,
						showRecommendation: data.showRecommendation,
					}));
				}

				const nestedcolumns = [
					{
						title: "Medicine",
						dataIndex: "Medicine",
						key: "Medicine",
						width: "490px",
						render: text => {
							if (typeof text === "object") {
								if (text.showRecommendation === true) {
									return (
										<>
											<p>{text.Medicine}</p>
											<p>(RECOMMENDED BY DOCTOR)</p>
										</>
									);
								} else {
									return <p>{text.Medicine}</p>;
								}
							} else {
								return {
									children: <p>{text}</p>,
									props: {
										colSpan: 10,
									},
								};
							}
						},
					},
					{
						title: "Dosage",
						width: "490px",
						render: record => {
							if (record.Medicine.is_composition === true) {
								return (
									<p>
										{record.Medicine.Dosage} ({record.Medicine.composition})
									</p>
								);
							} else {
								return <p>{record.Medicine.Dosage}</p>;
							}
						},
					},
					{
						title: "Note",
						dataIndex: "Note",
						key: "Note",
						width: "490px",
					},
				];

				return (
					<>
						<Text style={{ fontWeight: "bold" }}>
							{record.customer_name} / {record.order_display_id} <br />
							{record.order_name}
						</Text>
						{""}
						<Table
							columns={nestedcolumns}
							dataSource={nestedData}
							loading={searchItemsLoading}
							pagination={false}
						/>
					</>
				);
			},
		},
		{
			title: "Print Count",
			dataIndex: "print_count",
			key: "print_count",
			width: "120px",
			align: "center",
			render: print_count => {
				return (
					<Tag key={print_count} color="geekblue" style={{ fontSize: "20px" }}>
						{print_count}
					</Tag>
				);
			},
		},
	];

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	const handlePrint = async () => {
		if (!isEmpty(allSelectedOrders)) {
			allSelectedOrders.map(async data => await incrementPrintCount(data.key));
			setIsPrint(true);
		}
		return isPrint;
	};

	const handlePlainPrint = () => {
		if (!isEmpty(allSelectedOrders)) {
			setIsPlainPrint(true);
		}
	};

	return (
		<>
			{isPrint && <Print data={allSelectedOrders} setIsPrint={setIsPrint} />}
			{isPlainPrint && (
				<PlainPrint
					selectedOrder={allSelectedOrders}
					setIsPlainPrint={setIsPlainPrint}
				/>
			)}
			<Row justify="space-between">
				<Col>
					<DispatchSearch
						setAllSelectedOrders={setAllSelectedOrders}
						setSelectedKeys={setSelectedKeys}
						setSearchData={setSearchData}
						paginationInfo={paginationInfo}
						setPaginationInfo={setPaginationInfo}
						search="searchPage"
					/>
				</Col>
				<Col>
					<Space>
						<Button
							type="primary"
							onClick={handlePlainPrint}
							disabled={isEmpty(allSelectedOrders)}>
							Plain Print
						</Button>
						<Button
							type="primary"
							onClick={handlePrint}
							disabled={isEmpty(allSelectedOrders)}>
							Print
						</Button>
					</Space>
				</Col>
			</Row>
			<Table
				columns={columns}
				dataSource={data}
				loading={searchItemsLoading}
				rowSelection={rowSelection}
				pagination={{
					current: pageNo,
				}}
				onChange={e => setPageNo(e.current)}
			/>
		</>
	);
}

export default Search;
