import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { baseURL } from "../../helpers/api-config";
import { fetchRequest } from "../../helpers/fetchRequest";
import { statusTagColor } from "../../helpers/tagColor";

// Antd
import { List, Tag, Tooltip, Typography } from "antd/es";
const { Title, Text } = Typography;
// Ends

const OverdueTask = () => {
	const [tasks, setTasks] = useState([]);
	const [loading, setLoading] = useState(false);

	// fetch tasks
	const fetchTasks = useCallback(async () => {
		let _res = {};

		try {
			setLoading(true);
			_res = await fetchRequest(`${baseURL.API_URL_BASE}overdue-tasks/sales`);
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError) setTasks([]);
			else setTasks(_res.data);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchTasks();
	}, [fetchTasks]);
	// Ends

	return (
		<div className="px-2">
			<Title level={3}>Overdue Tasks</Title>

			<List
				style={{ maxHeight: "40rem" }}
				className="overflow-y-auto"
				loading={loading}
				dataSource={tasks}
				renderItem={item => (
					<List.Item key={item.id} className="items-start px-2 mt-px">
						<List.Item.Meta
							title={
								<Link className="mr-4" to={`/lead-details/${item.case_id}`}>
									<Text strong className="link">
										{item.case.user.first_name}
									</Text>
								</Link>
							}
							description={moment(item.reminder_date).format(
								"DD MMM YYYY, h:mm a"
							)}
						/>
						<div
							className="grid justify-items-end gap-1"
							style={{ maxWidth: "28rem" }}>
							<div className="flex items-center">
								<Tooltip placement="bottom" title="Tag">
									<Tag color="geekblue">
										{item.type === "user"
											? "USER"
											: item.tag?.replaceAll("_", " ").toUpperCase()}
									</Tag>
								</Tooltip>

								<Tooltip placement="bottom" title="Status">
									<Tag color={statusTagColor[item.case?.status]}>
										{item.case.status.replaceAll("_", " ").toUpperCase()}
									</Tag>
								</Tooltip>
							</div>

							{item.case?.feedback && (
								<div className="flex gap-1 mr-2">
									<Text strong>Feedback: </Text>
									<Text>{item.case.feedback}</Text>
								</div>
							)}
						</div>
					</List.Item>
				)}
			/>
		</div>
	);
};

export default OverdueTask;
