export const generateQuery = items => {
  const query = new URLSearchParams(window.location.search);
  items.map(item => {
    Object.entries(item).map(([key, value]) => {
      if (value)
        return query.has(key)
          ? query.set(key, value)
          : query.append(key, value);
      else return query.delete(key);
    });
    return query;
  });
  return query.toString();
};
