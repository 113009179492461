import React, { useState } from "react";

// Antd
import { PhoneFilled } from "@ant-design/icons";
import { fetchRequest } from "../helpers/fetchRequest";
import { CALL_API } from "../constants/apiUrls";

const Call = ({ data }) => {
	const [called, setCalled] = useState(false);

	const handleCall = async () => {
		setCalled(true);

		try {
			await fetchRequest(CALL_API, {
				method: "POST",
				body: JSON.stringify(data),
			});
		} catch (error) {
			console.warn(error.message);
		}
	};

	return (
		<>
			{called ? (
				<PhoneFilled style={{ color: "red" }} />
			) : (
				<PhoneFilled style={{ color: "green" }} onClick={() => handleCall()} />
			)}
		</>
	);
};

export default Call;
