import { message } from "antd";
import Cookies from "js-cookie";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";
import { leadManagementActions } from "./actions";

export const getRecentLeads = async queryParams => {
	store.dispatch({
		type: leadManagementActions.GET_RECENT_LEADS_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}cases?filterAssigned=true&${queryParams}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_RECENT_LEADS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_RECENT_LEADS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_RECENT_LEADS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getAllLeads = async queryParams => {
	store.dispatch({
		type: leadManagementActions.GET_ALL_LEADS_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}leads?${queryParams}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_ALL_LEADS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_ALL_LEADS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_ALL_LEADS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getAllCases = async queryParams => {
	store.dispatch({
		type: leadManagementActions.GET_ALL_CASES_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}cases?${queryParams}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_ALL_CASES_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_ALL_CASES_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_ALL_CASES_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getSalesReps = async () => {
	store.dispatch({
		type: leadManagementActions.GET_SALES_REPS_LOADING,
		payload: true,
	});
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}role_users?role=sales_rep`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_SALES_REPS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_SALES_REPS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_SALES_REPS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const assignSalesRep = async (user_id, case_id) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}assign_lead`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ user_id, case_id }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status !== 200) handleError(res);
		return res;
	} catch (error) {
		message.error(error.message);
	}
};

export const getRecentAssignedLeads = async queryParams => {
	store.dispatch({
		type: leadManagementActions.GET_RECENT_ASSIGNED_LEADS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}assigned_cases?recent=true&${queryParams}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_RECENT_ASSIGNED_LEADS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_RECENT_ASSIGNED_LEADS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_RECENT_ASSIGNED_LEADS_FAILURE,
			payload: null,
		});
	}
};

export const getUnansweredLeads = async queryParams => {
	store.dispatch({
		type: leadManagementActions.GET_UNANSWERED_LEADS_LEADS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}unanswered_leads?${queryParams}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_UNANSWERED_LEADS_LEADS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_UNANSWERED_LEADS_LEADS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_UNANSWERED_LEADS_LEADS_FAILURE,
			payload: null,
		});
	}
};

export const getUntouchedLeads = async queryParams => {
	store.dispatch({
		type: leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}untouched_leads?${queryParams}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_UNTOUCHED_LEADS_LEADS_FAILURE,
			payload: null,
		});
	}
};

export const getMyBookings = async () => {
	store.dispatch({
		type: leadManagementActions.GET_BOOKINGS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}bookings`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: leadManagementActions.GET_BOOKINGS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: leadManagementActions.GET_REMINDER_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: leadManagementActions.GET_REMINDER_FAILURE,
			payload: null,
		});
	}
};

export const getSlotBookingById = async case_Id => {
	try {
		let data = null;
		const res = await fetch(`${baseURL.API_URL_BASE}case_bookings/${case_Id}`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			data = await res.json();
		} else {
			handleError(res);
		}

		return { data };
	} catch (error) {
		console.warn(error);
	}
};
export const getGlobalSearchMedRepResult = async key => {
	try {
		console.log(key);
		const res = await fetch(
			`${baseURL.API_URL_BASE}role_users?role=medical_rep`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			return data;
		} else {
			return null;
		}
	} catch (error) {
		console.log(error.toString());
	}
};
