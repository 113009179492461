import React, { useState } from "react";
import PlaceOrderLinkModal from "./PlaceOrderLinkModal";
import { Button } from "antd";

export const PlaceOrderLink = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button onClick={() => setShowModal(true)} type="primary">
				Place COD Order
			</Button>
			<PlaceOrderLinkModal isVisible={showModal} setIsVisible={setShowModal} />
		</>
	);
};
