import {
	assignedOrdersActions,
	orderManagementActions,
	engagementActions,
} from "../actions";

export const orderManagement = (state = {}, action) => {
	switch (action.type) {
		case assignedOrdersActions.GET_MY_PATIENTS_LOADING:
			return {
				...state,
				myPatientsLoading: true,
			};
		case assignedOrdersActions.GET_MY_PATIENTS_SUCCESS:
			return {
				...state,
				myPatients: action.payload,
				myPatientsLoading: false,
			};
		case assignedOrdersActions.GET_MY_PATIENTS_FAILURE:
			return {
				...state,
				myPatients: action.payload,
				myPatientsLoading: false,
			};

		case assignedOrdersActions.GET_NEW_OPEN_ORDERS_LOADING:
			return {
				...state,
				newAssignedOrdersLoading: true,
			};
		case assignedOrdersActions.GET_NEW_OPEN_ORDERS_SUCCESS:
			return {
				...state,
				newAssignedOrders: action.payload,
				newAssignedOrdersLoading: false,
			};
		case assignedOrdersActions.GET_NEW_OPEN_ORDERS_FAILURE:
			return {
				...state,
				newAssignedOrders: action.payload,
				newAssignedOrdersLoading: false,
			};

		case assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_LOADING:
			return {
				...state,
				repeatAssignedOrdersLoading: true,
			};
		case assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_SUCCESS:
			return {
				...state,
				repeatAssignedOrders: action.payload,
				repeatAssignedOrdersLoading: false,
			};
		case assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_FAILURE:
			return {
				...state,
				repeatAssignedOrders: action.payload,
				repeatAssignedOrdersLoading: false,
			};

		case orderManagementActions.GET_ALL_ORDERS_LOADING:
			return {
				...state,
				getAllOrdersLoading: true,
			};
		case orderManagementActions.GET_ALL_ORDERS_SUCCESS:
			return {
				...state,
				getAllOrdersData: action.payload,
				getAllOrdersLoading: false,
			};
		case orderManagementActions.GET_ALL_ORDERS_FAILURE:
			return {
				...state,
				getAllOrdersData: action.payload,
				getAllOrdersLoading: false,
			};

		case orderManagementActions.GET_OPEN_ORDERS_LOADING:
			return {
				...state,
				openOrdersLoading: true,
			};
		case orderManagementActions.GET_OPEN_ORDERS_SUCCESS:
			return {
				...state,
				openOrders: action.payload,
				openOrdersLoading: false,
			};
		case orderManagementActions.GET_OPEN_ORDERS_FAILURE:
			return {
				...state,
				openOrders: action.payload,
				openOrdersLoading: false,
			};

		case orderManagementActions.GET_RECENT_ORDERS_LOADING:
			return {
				...state,
				recentOrdersLoading: true,
			};
		case orderManagementActions.GET_RECENT_ORDERS_SUCCESS:
			return {
				...state,
				recentOrders: action.payload,
				recentOrdersLoading: false,
			};
		case orderManagementActions.GET_RECENT_ORDERS_FAILURE:
			return {
				...state,
				recentOrders: action.payload,
				recentOrdersLoading: false,
			};

		case orderManagementActions.GET_MEDICAL_REPS_LOADING:
			return {
				...state,
				medicalRepsLoading: true,
			};
		case orderManagementActions.GET_MEDICAL_REPS_SUCCESS:
			return {
				...state,
				medicalReps: action.payload,
				medicalRepsLoading: false,
			};
		case orderManagementActions.GET_MEDICAL_REPS_FAILURE:
			return {
				...state,
				medicalReps: action.payload,
				medicalRepsLoading: false,
			};

		case orderManagementActions.GET_CONSULT_REPS_LOADING:
			return {
				...state,
				consultRepsLoading: true,
			};
		case orderManagementActions.GET_CONSULT_REPS_SUCCESS:
			return {
				...state,
				consultReps: action.payload,
				consultRepsLoading: false,
			};
		case orderManagementActions.GET_CONSULT_REPS_FAILURE:
			return {
				...state,
				consultReps: action.payload,
				consultRepsLoading: false,
			};

		case orderManagementActions.GET_GHOST_ORDERS_LOADING:
			return {
				...state,
				ghostOrdersLoading: true,
			};
		case orderManagementActions.GET_GHOST_ORDERS_SUCCESS:
			return {
				...state,
				ghostOrders: action.payload,
				ghostOrdersLoading: false,
			};
		case orderManagementActions.GET_GHOST_ORDERS_FAILURE:
			return {
				...state,
				ghostOrders: action.payload,
				ghostOrdersLoading: false,
			};

		case orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_LOADING:
			return {
				...state,
				ordersAssignedDoctorsLoading: true,
			};
		case orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_SUCCESS:
			return {
				...state,
				ordersAssignedDoctors: action.payload,
				ordersAssignedDoctorsLoading: false,
			};
		case orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_FAILURE:
			return {
				...state,
				ordersAssignedDoctors: action.payload,
				ordersAssignedDoctorsLoading: false,
			};

		case orderManagementActions.GET_DOCTORS_LOADING:
			return {
				...state,
				doctorsLoading: true,
			};
		case orderManagementActions.GET_DOCTORS_SUCCESS:
			return {
				...state,
				doctors: action.payload,
				doctorsLoading: false,
			};
		case orderManagementActions.GET_DOCTORS_FAILURE:
			return {
				...state,
				doctorsReps: action.payload,
				doctorsLoading: false,
			};

		case engagementActions.PENDING_ENGAGEMENTS_LOADING:
			return {
				...state,
				engagementsLoading: true,
			};
		case engagementActions.PENDING_ENGAGEMENTS_SUCCESS:
			return {
				...state,
				engagementsData: action.payload,
				engagementsLoading: false,
			};
		case engagementActions.PENDING_ENGAGEMENTS_FAILURE:
			return {
				...state,
				engagementsData: null,
				engagementsLoading: false,
			};

		case engagementActions.ENGAGEMENTS_BY_ORDER_LOADING:
			return {
				...state,
				engagementsByOrderLoading: true,
			};
		case engagementActions.ENGAGEMENTS_BY_ORDER_SUCCESS:
			return {
				...state,
				engagementsByOrderData: action.payload,
				engagementsByOrderLoading: false,
			};
		case engagementActions.ENGAGEMENTS_BY_ORDER_FAILURE:
			return {
				...state,
				engagementsByOrderData: null,
				engagementsByOrderLoading: false,
			};

		case engagementActions.ENGAGEMENTS_BY_CASE_LOADING:
			return {
				...state,
				engagementsByCaseLoading: true,
			};
		case engagementActions.ENGAGEMENTS_BY_CASE_SUCCESS:
			return {
				...state,
				engagementsByCase: action.payload,
				engagementsByCaseLoading: false,
			};
		case engagementActions.ENGAGEMENTS_BY_CASE_FAILURE:
			return {
				...state,
				engagementsByCase: null,
				engagementsByCaseLoading: false,
			};

		case engagementActions.UPDATE_ENGAGEMENT_LOADING:
			return {
				...state,
				updatingEngagement: true,
			};
		case engagementActions.UPDATE_ENGAGEMENT_SUCCESS:
			return {
				...state,
				updatingEngagement: false,
			};
		case engagementActions.UPDATE_ENGAGEMENT_FAILURE:
			return {
				...state,
				updatingEngagement: false,
			};

		case assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_LOADING:
			return {
				...state,
				assignedGhostOrdersLoading: true,
			};
		case assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_SUCCESS:
			return {
				...state,
				assignedGhostOrdersLoading: false,
				assignedGhostOrders: action.payload,
			};
		case assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_FAILURE:
			return {
				...state,
				assignedGhostOrdersLoading: false,
				assignedGhostOrders: null,
			};

		default:
			return state;
	}
};
