import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { generateQuery, getQueryParams } from "../helpers";
import { codOrderTypeOptions } from "../constants/options";
import {
	Badge,
	Button,
	DatePicker,
	Form,
	Select,
	Space,
	Typography,
} from "antd";
const { Option } = Select;
const { Text } = Typography;

const OrderFilters = ({
	clearSelection,
	handleFilter,
	optionsStatus,
	setPageNo,
	setQueryParams,
}) => {
	const [form] = Form.useForm();
	const [status, setStatus] = useState(getQueryParams("status"));
	const [startDate, setStartDate] = useState(getQueryParams("startDate"));
	const [endDate, setEndDate] = useState(getQueryParams("endDate"));
	const [assignedTo, setAssignedTo] = useState(getQueryParams("assignedTo"));
	const [orderType, setOrderType] = useState(getQueryParams("orderType"));
	const [isBulk, setIsBulk] = useState(getQueryParams("isBulk"));
	const [isCombo, setIsCombo] = useState(getQueryParams("isCombo"));
	const [isSubscription, setIsSubscription] = useState(
		getQueryParams("isSubscription")
	);
	const [orderSourceType, setOrderSourceType] = useState(
		getQueryParams("orderSourceType")
	);
	const [currPathName] = useState(window.location.pathname);

	useEffect(() => {
		form.setFieldsValue({
			startDate: startDate ? moment(startDate) : undefined,
			endDate: endDate ? moment(endDate) : undefined,
			status: status,
			orderType: orderType,
			assignedTo: assignedTo,
			isBulk,
			isSubscription,
			isCombo,
			orderSourceType,
		});
	}, []);

	const { consultReps, medicalReps } = useSelector(state => ({
		consultReps: window.getValue(state, "orderManagement.consultReps.userRole"),
		medicalReps: window.getValue(state, "orderManagement.medicalReps.userRole"),
	}));

	const optionsOrderType = [
		{ label: "New", value: "new" },
		{ label: "Repeat", value: "repeat" },
	];

	const onSubmit = async items => {
		const filterItems = [];

		Object.entries(items).map(([key, value]) => {
			if (key === "startDate") {
				filterItems.push({
					[key]: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
				});
			} else if (key === "endDate") {
				filterItems.push({
					[key]: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
				});
			} else {
				filterItems.push({ [key]: value });
			}
			filterItems.push({ pageNumber: 1 });
			return filterItems;
		});
		const queryParams = await generateQuery(filterItems);
		setQueryParams(queryParams);
		setPageNo(1);

		if (handleFilter) handleFilter(queryParams);
		if (clearSelection) clearSelection();
	};

	const handleClear = () => {
		form.resetFields();
		setStatus(undefined);
		setStartDate(undefined);
		setEndDate(undefined);
		setAssignedTo(undefined);
		setOrderType(undefined);
		setIsBulk(undefined);
		setIsCombo(undefined);
		setIsSubscription(undefined);
		setOrderSourceType(null);
		form.submit();
	};

	return (
		<Form
			className="order-filter"
			form={form}
			layout="inline"
			onFinish={onSubmit}>
			{currPathName !== "/home" && (
				<>
					<Form.Item name="startDate">
						<DatePicker
							allowClear
							disabledDate={current =>
								current.isAfter(moment(endDate ? endDate : undefined), "day")
							}
							format={"YYYY-MM-DD"}
							onChange={e => (e ? setStartDate(e) : setStartDate(undefined))}
							placeholder="Start Date"
						/>
					</Form.Item>

					<Form.Item name="endDate">
						<DatePicker
							allowClear
							disabledDate={current =>
								(startDate && current.isBefore(moment(startDate), "day")) ||
								current.isAfter(moment(), "day")
							}
							format={"YYYY-MM-DD"}
							onChange={e => (e ? setEndDate(e) : setEndDate(undefined))}
							placeholder="End Date"
						/>
					</Form.Item>
				</>
			)}

			<Form.Item name="isBulk">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					onChange={e => (e ? setIsBulk(e) : setIsBulk(undefined))}
					placeholder="Bulk Order">
					<Option value="true">True</Option>
					<Option value="false">False</Option>
				</Select>
			</Form.Item>

			<Form.Item name="isSubscription">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					onChange={e =>
						e ? setIsSubscription(e) : setIsSubscription(undefined)
					}
					placeholder="Subscription">
					<Option value="true">True</Option>
					<Option value="false">False</Option>
				</Select>
			</Form.Item>

			<Form.Item name="status">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					options={optionsStatus}
					onChange={e => (e ? setStatus(e) : setStatus(undefined))}
					placeholder="Order Status"
					style={{ maxWidth: "8rem" }}
				/>
			</Form.Item>

			<Form.Item name="orderType">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					options={optionsOrderType}
					onChange={e => (e ? setOrderType(e) : setOrderType(undefined))}
					placeholder="New/Repeat"
					style={{ maxWidth: "8rem" }}
				/>
			</Form.Item>

			<Form.Item name="orderSourceType">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "14rem" }}
					options={codOrderTypeOptions}
					onChange={e => (e ? setOrderSourceType(e) : setOrderSourceType(null))}
					placeholder="Order Type"
					style={{ maxWidth: "8rem" }}
				/>
			</Form.Item>

			{currPathName !== "/assigned-orders" && (
				<Form.Item name="assignedTo">
					<Select
						allowClear
						dropdownStyle={{ minWidth: 240 }}
						onChange={e => (e ? setAssignedTo(e) : setAssignedTo(undefined))}
						optionFilterProp="label"
						placeholder="Owner"
						showSearch
						style={{ width: 128 }}>
						{!isEmpty(medicalReps) &&
							medicalReps.map(rep => (
								<Option
									label={rep.user.first_name}
									value={rep.user.id}
									key={rep.user.id}>
									<Space align="center">
										<Text>{rep.user.first_name}</Text>
										<Badge
											count="Medical Rep"
											style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
										/>
									</Space>
								</Option>
							))}

						{!isEmpty(consultReps) &&
							consultReps.map(rep => (
								<Option
									label={rep.user.first_name}
									value={rep.user.id}
									key={rep.user.id}>
									<Space align="center">
										<Text>{rep.user.first_name}</Text>
										<Badge
											count="Consult Rep"
											style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
										/>
									</Space>
								</Option>
							))}

						<Option label="N/A" value="NA">
							<Space align="center">
								<Text>N/A</Text>
								<Badge
									count="Not Assigned"
									style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
								/>
							</Space>
						</Option>
					</Select>
				</Form.Item>
			)}
			<Form.Item name="isCombo">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					onChange={e => (e ? setIsCombo(e) : setIsCombo(undefined))}
					placeholder="Combo Order">
					<Option value="Yes">True</Option>
					<Option value="No">False</Option>
				</Select>
			</Form.Item>

			<Form.Item>
				<div className="flex gap-1 ml-2">
					<Button type="primary" htmlType="submit">
						Apply
					</Button>
					<Button htmlType="button" onClick={handleClear}>
						Clear
					</Button>
				</div>
			</Form.Item>
		</Form>
	);
};

export default OrderFilters;
