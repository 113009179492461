import moment from "moment";

export const distributeRevenueToDate = (revenue, dates) => {
	const _data = [];
	const _startDate = moment(dates[0]);
	const _endDate = moment(dates[1]);

	const _noOfDays = _endDate.diff(_startDate, "days") + 1;
	const _mod = revenue % _noOfDays;
	const _div = (revenue - _mod) / _noOfDays;

	for (let i = 0; i < _noOfDays; i++) {
		let _revenue = 0;
		let _target_date = moment(_startDate);

		if (i < _mod) {
			_revenue = _div + 1;
			_target_date = _target_date.add(i, "days");
		} else {
			_revenue = _div;
			_target_date = _target_date.add(i, "days");
		}

		_data.push({ revenue: _revenue, target_date: _target_date });
	}

	return _data;
};
