import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row, Space, Tabs, Typography } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Loader, NotAllowedError } from "../../../common";
import { viewDetails } from "../service";
import ActivityLog from "./ActivityLog";
import ChatBox from "./ChatBox";
import FreshchatBox from './FreschatBox';
import OrderDetails from "./OrderDetails";
import RecentComments from "./RecentComments";
import TagHistory from "./TagHistory";
import TicketHistory from "./TicketHistory";
import UsersDetail from "./UsersDetail";
const { TabPane } = Tabs;
const { Text } = Typography;

const ViewDetailsByReadOnly = () => {
	const history = useHistory();
	const params = useParams();

	const {
		assignDoctorLoading,
		chats,
		detail,
		orderDetails,
		assignRoleLoading,
	} = useSelector(state => ({
		assignDoctorLoading: window.getValue(
			state,
			"doctorsData.assignDoctorLoading"
		),
		chats: window.getValue(state, "leaddetails.chats"),
		detail: window.getValue(state, "leaddetails.usersDetail"),
		orderDetails: window.getValue(state, "leaddetails.usersDetail.orders"),
		assignRoleLoading: window.getValue(
			state,
			"orderManagement.assignRoleLoding"
		),
	}));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		viewDetails(params.id);
	}, [params.id, assignDoctorLoading, assignRoleLoading]);

	const chatListContent = () => (
		<Text type="warning">
			Chat number is available in{" "}
			<Text style={{ color: "#B45309" }}>{chats?.profileCount - 1}</Text> more
			profile, please contact admin or support for more details
		</Text>
	);

	if (!detail) return <Loader />;
	if (detail && detail.Error) return <NotAllowedError />;

	return (
		<div className="lead-details">
			<Row justify="space-between" style={{ paddingBottom: "1rem" }}>
				<Button onClick={history.goBack} type="link" icon={<LeftOutlined />}>
					Back
				</Button>
			</Row>

			<Row>
				<Col span={24}>
					<UsersDetail />
				</Col>
			</Row>

			<Row>
				{!isEmpty(orderDetails) && (
					<Col span={24}>
						<OrderDetails />
					</Col>
				)}
			</Row>

      <Row>
        <Col
          offset={12}
          span={12}
          style={{
            border: "1px solid #F0F0F0",
            height: 385,
          }}
        >
          <Tabs defaultActiveKey="1" type="card">
            <TabPane
              tab={
                chats?.profileCount > 1 ? (
                  <Space>
                    Chats
                    <Popover content={chatListContent} title="">
                      <ExclamationCircleOutlined style={{ color: "#D97706" }} />
                    </Popover>
                  </Space>
                ) : (
                  "Chats"
                )
              }
              key="1"
            >
              <ChatBox />
            </TabPane>
						<TabPane tab="Chats from freshchats" key="6">
							<FreshchatBox />
						</TabPane>

						<TabPane tab="Recent Comments" key="2">
							<RecentComments />
						</TabPane>

						<TabPane tab="Activity Log" key="3">
							<ActivityLog />
						</TabPane>
						<TabPane tab="Tag History" key="4">
							<TagHistory />
						</TabPane>
						<TabPane tab="Ticket History" key="5">
							<TicketHistory />
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</div>
	);
};

export default ViewDetailsByReadOnly;
