import { doctorActions, prescriptionActions } from "../actions";

export const doctorsData = (state = {}, action) => {
	switch (action.type) {
		case doctorActions.GET_DOCTORS_LIST_LOADING:
			return {
				...state,
				doctorsListLoading: true,
			};
		case doctorActions.GET_DOCTORS_LIST_SUCCESS:
			return {
				...state,
				doctorsListData: action.payload,
				doctorsListLoading: false,
			};
		case doctorActions.GET_DOCTORS_LIST_FAILURE:
			return {
				...state,
				doctorsListData: null,
				doctorsListLoading: false,
			};

		case doctorActions.UPDATE_ASSIGNED_DOCTOR_LOADING:
			return {
				...state,
				updatingAssignedDoctor: true,
			};
		case doctorActions.UPDATE_ASSIGNED_DOCTOR_SUCCESS:
			return {
				...state,
				updatingAssignedDoctor: false,
			};
		case doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE:
			return {
				...state,
				updatingAssignedDoctor: false,
			};

		case doctorActions.GET_ASSIGNED_ORDERS_LOADING:
			return {
				...state,
				assignedOrdersLoading: true,
			};
		case doctorActions.GET_ASSIGNED_ORDERS_SUCCESS:
			return {
				...state,
				assignedOrdersData: action.payload,
				assignedOrdersLoading: false,
			};
		case doctorActions.GET_ASSIGNED_ORDERS_FAILURE:
			return {
				...state,
				assignedOrdersData: null,
				assignedOrdersLoading: false,
			};

		case doctorActions.GET_PRESCRIBED_PATIENTS_LOADING:
			return {
				...state,
				prescribedPatientsLoading: true,
			};
		case doctorActions.GET_PRESCRIBED_PATIENTS_SUCCESS:
			return {
				...state,
				prescribedPatientsData: action.payload,
				prescribedPatientsLoading: false,
			};
		case doctorActions.GET_PRESCRIBED_PATIENTS_FAILURE:
			return {
				...state,
				prescribedPatientsData: null,
				prescribedPatientsLoading: false,
			};

		case prescriptionActions.UPDATE_PRESCRIPTION_LOADING:
			return {
				...state,
				updatingPrescription: true,
			};
		case prescriptionActions.UPDATE_PRESCRIPTION_SUCCESS:
			return {
				...state,
				updatingPrescription: false,
			};
		case prescriptionActions.UPDATE_PRESCRIPTION_FAILURE:
			return {
				...state,
				updatingPrescription: false,
			};

		case doctorActions.GET_ASSIGNED_DOCTORS_LOADING:
			return {
				...state,
				assignedDoctorsLoading: true,
			};
		case doctorActions.GET_ASSIGNED_DOCTORS_SUCCESS:
			return {
				...state,
				assignedDoctorsList: action.payload,
				assignedDoctorsLoading: false,
			};
		case doctorActions.GET_ASSIGNED_DOCTORS_FAILURE:
			return {
				...state,
				assignedDoctorsList: null,
				assignedDoctorsLoading: false,
			};

		case prescriptionActions.GET_PRESCRIPTION_LOADING:
			return {
				...state,
				prescriptionLoading: true,
			};
		case prescriptionActions.GET_PRESCRIPTION_SUCCESS:
			return {
				...state,
				prescriptions: action.payload,
				prescriptionLoading: false,
			};
		case prescriptionActions.GET_PRESCRIPTION_FAILURE:
			return {
				...state,
				prescriptions: null,
				prescriptionLoading: false,
			};

		case prescriptionActions.GET_ORDER_ITEMS_LOADING:
			return {
				...state,
				dosageLoading: true,
			};
		case prescriptionActions.GET_ORDER_ITEMS_SUCCESS:
			return {
				...state,
				orderItems: action.payload,
				dosageLoading: false,
			};
		case prescriptionActions.GET_ORDER_ITEMS_FAILURE:
			return {
				...state,
				orderItems: null,
				dosageLoading: false,
			};

		default:
			return state;
	}
};
