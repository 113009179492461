import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import isEmpty from "lodash/isEmpty";

// Components
import DateFilter from "./components/DateFilter";
import AggregateSummary from "./components/AggregateSummary";
import LeadActionsReport from "./components/leadActionReport";
import OrderReport from "./components/orderReport";

import { getUrl } from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { ACTIONS, INITIAL_VALUE, reducer } from "./store";
import { downloadCSV, downloadOption, formStatusMapper } from "./helpers";
import {
	activeBDEFn,
	aovOrdersFn,
	bookedSlotsFn,
	calcOrdersRevenue,
	formStatusFn,
	leadActionReportFn,
	orderReportFn,
	ordersInfoFn,
	voidOrdersFn,
	totalLeads,
	bookingsFn,
	noBookingsFn,
} from "./transform";

// Antd
import { Empty, List, Select, Typography } from "antd/es";
const { Paragraph, Text, Title } = Typography;

const SalesReport = () => {
	const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);
	const {
		loading,
		endDate,
		downloadInfo,
		downloadingCSV,
		salesReport,
		startDate,
	} = state;

	useEffect(() => {
		return () => dispatch({ type: ACTIONS.RESET });
	}, []);

	const setLoading = loading => {
		dispatch({ type: ACTIONS.LOADING, payload: loading });
	};

	const apiCall = useCallback(async (start, end) => {
		let res = {};

		try {
			setLoading(true);
			const url = getUrl(`sales-analytics?startDate=${start}&endDate=${end}`);
			res = await fetchRequest(url);
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) dispatch({ type: ACTIONS.RESET });
			else
				dispatch({
					type: ACTIONS.UPDATE,
					payload: { ...res.data, startDate: start, endDate: end },
				});
			setLoading(false);
		}
	}, []);

	// const filledLeads = useMemo(() => {
	// 	return totalFilledLeads(salesReport, startDate, endDate);
	// }, [endDate, salesReport, startDate]);

	const AllLeads = useMemo(() => {
		return totalLeads(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	const bookedSlots = useMemo(() => {
		return bookedSlotsFn(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	const ordersInfo = useMemo(() => {
		return ordersInfoFn(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	const formStatus = useMemo(() => {
		return formStatusFn(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	const voidOrders = useMemo(() => {
		const _data = voidOrdersFn(salesReport, startDate, endDate);
		return {
			orders: _data.length,
			revenue: calcOrdersRevenue(_data),
		};
	}, [endDate, salesReport, startDate]);

	const leadActionReport = useMemo(() => {
		return leadActionReportFn(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	const orderReport = useMemo(() => {
		return orderReportFn(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	const activeBDE = useMemo(() => {
		return activeBDEFn(salesReport, startDate, endDate);
	}, [endDate, salesReport, startDate]);

	// const filledORSemiFilled = useMemo(() => {
	// 	return filledORSemiFilledLeads(salesReport, startDate, endDate);
	// }, [endDate, salesReport, startDate]);

	// const slotLeads = useMemo(() => {
	// 	return slotLeadsFn(filledORSemiFilled, startDate, endDate);
	// }, [endDate, filledORSemiFilled, startDate]);

	const bookings = useMemo(() => {
		return bookingsFn(AllLeads, startDate, endDate);
	}, [endDate, AllLeads, startDate]);

	const noBookings = useMemo(() => {
		return noBookingsFn(AllLeads, startDate, endDate);
	}, [endDate, AllLeads, startDate]);

	// const freshLeads = useMemo(() => {
	// 	return filledLeads.filter(lead => {
	// 		return !Boolean(lead.slot_booking_time);
	// 	});
	// }, [filledLeads]);

	const updateState = payload => {
		dispatch({ type: ACTIONS.UPDATE, payload: payload });
	};

	const handleDownload = async e => {
		updateState({ downloadInfo: e });

		if (e === "sales_report") {
			updateState({ downloadingCSV: true });

			let _data = [...salesReport];
			_data = _data.map(item => {
				if ("rep_id" in item) delete item["rep_id"];
				return item;
			});

			await downloadCSV(e, _data, "sales_report.csv");
			updateState({ downloadingCSV: false });
		}
	};

	return (
		<>
			<div className="w-full">
				<Title level={2}>Sales Dashboard</Title>
			</div>

			<div className="flex justify-between items-center w-full">
				<DateFilter
					apiCall={apiCall}
					loading={loading}
					reset={() => dispatch({ type: ACTIONS.RESET })}
				/>

				<Select
					allowClear
					disabled={isEmpty(salesReport)}
					placeholder="Download"
					options={downloadOption}
					style={{ width: 140 }}
					value={downloadInfo}
					onSelect={handleDownload}
					loading={downloadingCSV}
				/>
			</div>

			{salesReport.length > 0 || loading ? (
				<>
					<div className="grid mt-4 gap-2 admin__sales__dashboard-grid">
						<AggregateSummary loading={loading} title="Leads">
							<Text>{AllLeads.length}</Text>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Bookings">
							<Text>{bookings.length}</Text>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="No Bookings">
							<Text>{noBookings.length}</Text>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Slot Leads">
							<Text>{bookedSlots.length}</Text>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Active Hair Expert">
							{!isEmpty(activeBDE) && (
								<List
									dataSource={Object.keys(activeBDE)}
									size="small"
									style={{ maxHeight: 172, overflowY: "auto" }}
									renderItem={item => (
										<List.Item key={item}>
											<List.Item.Meta title={`Level ${item}: `} />
											<Text>{activeBDE[item].length}</Text>
										</List.Item>
									)}
								/>
							)}
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Form Status">
							<List
								dataSource={Object.keys(formStatus)}
								size="small"
								style={{ maxHeight: 172, overflowY: "auto" }}
								renderItem={item => (
									<List.Item key={item}>
										<List.Item.Meta title={formStatusMapper[item]} />
										<Text>{formStatus[item].length}</Text>
									</List.Item>
								)}
							/>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Orders">
							<List
								dataSource={Object.keys(ordersInfo)}
								size="small"
								style={{ maxHeight: 172, overflowY: "auto" }}
								renderItem={item => (
									<List.Item key={item}>
										<List.Item.Meta
											title={<Text className="capitalize">{item}</Text>}
										/>
										<Text>{ordersInfo[item].length}</Text>
									</List.Item>
								)}
							/>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Revenue">
							<List
								dataSource={Object.keys(ordersInfo)}
								size="small"
								style={{ maxHeight: 172, overflowY: "auto" }}
								renderItem={item => (
									<List.Item key={item}>
										<List.Item.Meta
											title={<Text className="capitalize">{item}</Text>}
										/>
										<Text>{calcOrdersRevenue(ordersInfo[item])}</Text>
									</List.Item>
								)}
							/>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="AOV">
							<List
								dataSource={Object.keys(ordersInfo)}
								size="small"
								style={{ maxHeight: 172, overflowY: "auto" }}
								renderItem={item => (
									<List.Item key={item}>
										<List.Item.Meta
											title={<Text className="capitalize">{item}</Text>}
										/>
										<Text>
											{aovOrdersFn(ordersInfo[item], startDate, endDate)}
										</Text>
									</List.Item>
								)}
							/>
						</AggregateSummary>

						<AggregateSummary loading={loading} title="Void Orders">
							<List
								dataSource={Object.keys(voidOrders)}
								size="small"
								style={{ maxHeight: 172, overflowY: "auto" }}
								renderItem={item => (
									<List.Item key={item}>
										<List.Item.Meta
											title={<Text className="capitalize">{item}</Text>}
										/>
										<Text>{voidOrders[item]}</Text>
									</List.Item>
								)}
							/>
						</AggregateSummary>
					</div>
				</>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			{salesReport.length > 0 || loading ? (
				<div className="mt-4">
					<AggregateSummary
						headStyle={{ textAlign: "center" }}
						loading={loading}
						title="Lead Action Report">
						<LeadActionsReport data={leadActionReport} />
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			{salesReport.length > 0 || loading ? (
				<div className="mt-4">
					<AggregateSummary
						headStyle={{ textAlign: "center" }}
						loading={loading}
						title="Order Report">
						<OrderReport data={orderReport} />
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			<div className="mt-4">
				<Paragraph>
					<ul>
						<li>
							<Text>Please note, orders include zero value orders as well</Text>
						</li>
					</ul>
				</Paragraph>
			</div>
		</>
	);
};

export default SalesReport;
