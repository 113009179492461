import { AUTH_HEADER, handleError } from "../../helpers";
import { baseURL } from "../../helpers";

export const getPendingConsult = async queryParams => {
	try {
		let _data = [];
		const _res = await fetch(
			`${baseURL.API_URL_BASE}pending_consult?${queryParams}`,
			{
				method: "GET",
				...AUTH_HEADER,
			}
		);

		handleError(_res);
		if (_res.status === 200) _data = await _res.json();
		return _data;
	} catch (error) {
		throw error;
	}
};
