import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getEngagementsByCase } from "../../pages/orderManagement/service";
import { getEngagementSlots, updateEngagement } from "./service";
import slots from "./slots.json";

// Ant Design
import {
	Button,
	Calendar,
	Col,
	Modal,
	Row,
	Space,
	Tooltip,
	Typography,
} from "antd";
import { updateReminder } from "../../pages/dashboard/service";
const { Title } = Typography;

const TIME_FORMAT = "hh:mm a";

export const RescheduleEngagement = ({
	dueDate,
	engagement,
	isVisible,
	refreshUI,
	reminderDate,
	reminderId,
	setIsVisible,
}) => {
	const { id: case_id } = useParams();
	const [date, setDate] = useState(null);
	const [engagements, setEngagements] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedSlot, setSelectedSlot] = useState("");

	const initDate = useCallback(() => {
		setDate(reminderDate || null);
		setSelectedSlot(moment(reminderDate).format(TIME_FORMAT));
	}, [reminderDate]);

	const bookedSlot = useMemo(() => {
		if (isEmpty(reminderDate)) return "";
		return moment(reminderDate).format(TIME_FORMAT);
	}, [reminderDate]);

	const engagementSlots = useCallback(async () => {
		if (isEmpty(date)) return setEngagements([]);
		const _res = await getEngagementSlots(moment(date).format("YYYY-MM-DD"));
		if (_res.status === 200) setEngagements(_res.data);
		else setEngagements([]);
	}, [date]);

	useEffect(() => {
		initDate();
	}, [initDate]);

	useEffect(() => {
		engagementSlots();
	}, [engagementSlots]);

	const handleModalClose = () => {
		initDate();
		setIsVisible(false);
	};

	const handleDateChange = val => {
		setDate(() => val);
		if (val.isSame(moment(reminderDate), "day")) setSelectedSlot(bookedSlot);
		else setSelectedSlot("");
	};

	const onSubmit = async () => {
		const _date = moment(date).format("YYYY/MM/DD");
		const _timeStamp = moment(`${_date} ${selectedSlot}`).format();

		setLoading(true);
		const _res = await updateEngagement(_timeStamp, reminderId);
		setLoading(false);

		if (_res.status === 200) {
			await _updateReminder(_timeStamp);

			if (refreshUI) refreshUI();
			else getEngagementsByCase(case_id);
			handleModalClose();
		}
	};

	const _updateReminder = async date => {
		if (!engagement.refrence_slot_id) return;

		const data = {
			reminder_id: engagement.refrence_slot_id,
			status: "active",
			reminder_date: date,
			comment: `Rescheduled as ${engagement.tag} engagement was rescheduled"`,
		};

		await updateReminder(data);
	};

	const isInSlot = (date, from, to) => {
		return (
			moment(date, TIME_FORMAT).isBetween(
				moment(from, TIME_FORMAT),
				moment(to, TIME_FORMAT)
			) ||
			moment(date, TIME_FORMAT).isSame(moment(from, TIME_FORMAT)) ||
			moment(date, TIME_FORMAT).isSame(moment(to, TIME_FORMAT))
		);
	};

	const minDate = useMemo(() => {
		const _today = moment();

		if (moment(reminderDate).isBefore(_today, "day")) return _today;
		return moment(reminderDate);
	}, [reminderDate]);

	const disableRescheduleBtn = useMemo(() => {
		if (!date) return true;
		if (moment(date).isBefore(minDate, "day")) return true;
		return isEmpty(selectedSlot);
	}, [date, minDate, selectedSlot]);

	return (
		<Modal
			visible={isVisible}
			title="Reschedule Engagement"
			onOk={handleModalClose}
			onCancel={handleModalClose}
			width={640}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
				<Button
					disabled={disableRescheduleBtn}
					key="reshedule"
					loading={loading}
					onClick={onSubmit}
					type="primary">
					Reshedule
				</Button>,
			]}>
			<Row justify="center" gutter={[16, 1]}>
				<Col span={15}>
					<Calendar
						disabledDate={current => current.isBefore(minDate, "day")}
						fullscreen={false}
						onChange={handleDateChange}
						value={isEmpty(date) ? moment() : moment(date)}
					/>
				</Col>

				<Col span={9} style={{ height: 312, overflowY: "scroll" }}>
					<Space size={4}>
						<Space direction="vertical">
							<Title level={5} className="align-center">
								Time
							</Title>

							{slots?.map(({ from, to }, index) => (
								<Button
									onClick={() => setSelectedSlot(from)}
									key={index}
									type={
										isInSlot(selectedSlot, from, to) ? "primary" : "default"
									}>
									{from}
								</Button>
							))}
						</Space>

						<Space direction="vertical">
							<Title level={5} className="align-center">
								Bookings
							</Title>

							{slots?.map(({ from, to }, index) => {
								const _findEngagement = engagements.find(({ reminder_date }) =>
									isInSlot(moment(reminder_date).format(TIME_FORMAT), from, to)
								);

								return (
									<Tooltip placement="right" title="No of bookings">
										<Button block key={index} type="text">
											{_findEngagement ? _findEngagement.count : "-"}
										</Button>
									</Tooltip>
								);
							})}
						</Space>
					</Space>
				</Col>
			</Row>
		</Modal>
	);
};
