import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getUrl } from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { getValue } from "../../../helpers";
import moment from "moment";
import { Button, Table } from "antd";

const CallRecordings = () => {
	const { id } = useParams();
	const [callData, setCallData] = useState([]);
	const [loading, setLoading] = useState(false);

	const call_recordings = async caseId => {
		let res = {};
		setLoading(true);
		try {
			res = await fetchRequest(getUrl(`case/${caseId}/callLogs`));
			setCallData(res.data);
		} catch (e) {
			console.warn(e.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		call_recordings(id);
	}, [id]);

	const tData = useMemo(() => {
		return callData.map(data => ({
			key: getValue(data, "repDetails.id"),
			name_of_agent: getValue(data, "repDetails.first_name"),
			date_time: getValue(data, "callTime"),
			call_log_link: getValue(data, "callRecording"),
		}));
	}, [callData]);

	const columns = [
		{
			title: "Agent Name",
			dataIndex: "name_of_agent",
			key: "name_of_agent",
		},
		{
			title: "Date and Time",
			dataIndex: "date_time",
			key: "date_time",
			render: time => moment(time).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Call Recordings",
			dataIndex: "call_log_link",
			key: "call_log_link",
			render: call_log_link => (
				<Button
					disabled={!call_log_link}
					onClick={() => window.open(call_log_link, "_newtab")}>
					Download
				</Button>
			),
		},
	];

	return (
		<div className="recent-comment">
			<Table
				bordered
				columns={columns}
				pagination={false}
				dataSource={tData}
				loading={loading}
			/>
		</div>
	);
};
export default CallRecordings;
