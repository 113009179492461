import React, { useRef, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";

import { getGlobalSearchResult } from "../service";
import { getCurrentUserRole, getValue } from "../../../helpers";

// Antd
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Card, Space, Typography } from "antd";
const { Option } = AutoComplete;
const { Meta } = Card;
const { Text } = Typography;

function GlobalSearch() {
	const history = useHistory();
	const inputFocus = useRef(null);

	const [searchText, setSearchText] = useState("");

	const { searchResult } = useSelector(state => ({
		searchResult: getValue(state, "globalSearch.globalSearchData"),
	}));

	const userRole = useMemo(() => getCurrentUserRole(), []);

	const data = useMemo(() => {
		return Array.isArray(searchResult) ? searchResult : [];
	}, [searchResult]);

	const handleSearch = useCallback(
		debounce(async text => {
			getGlobalSearchResult(text);
		}, 500),
		[]
	);

	const handleSearchText = useCallback(
		text => {
			handleSearch(text);
			setSearchText(text);
		},
		[handleSearch]
	);

	return (
		<AutoComplete
			defaultActiveFirstOption={false}
			filterOption={false}
			onSearch={handleSearchText}
			placeholder={getPlaceholder(userRole)}
			notFoundContent={null}
			ref={inputFocus}
			maxLength={80}
			showSearch
			style={{ width: "22rem" }}
			suffixIcon={<SearchOutlined />}
			value={searchText}>
			{Array.isArray(data) &&
				data.map(searchResult => (
					<Option key={genrateKey()}>
						<Card
							onClick={() =>
								history.push(
									`/lead-details/${searchResult.case_id}`,
									inputFocus.current.blur()
								)
							}>
							<Meta
								title={`${searchResult.first_name}`}
								description={
									<Space direction="vertical">
										{!isEmpty(searchResult.order_display_id) && (
											<Text>{searchResult.order_display_id}</Text>
										)}

										{!hideContactDetails.has(userRole) && (
											<Space align="center">
												<Text>P: </Text>
												<Text>{searchResult.phone_number}</Text>
												<Text>/</Text>
												<Text>{searchResult.email}</Text>
											</Space>
										)}

										{!hideContactDetails.has(userRole) &&
											hasAltContact(searchResult) && (
												<Space align="center">
													<Text type="secondary">A: </Text>
													<Text type="secondary">
														{searchResult.alternate_phone}
													</Text>
													{showSeparator(searchResult) && <Text>/</Text>}
													<Text type="secondary">
														{searchResult.alternate_email}
													</Text>
												</Space>
											)}
									</Space>
								}
							/>
						</Card>
					</Option>
				))}
		</AutoComplete>
	);
}
export default GlobalSearch;

const hideContactDetails = new Set(["doctor"]);

const genrateKey = () => Math.floor(Math.random() * 1000000000);

const getPlaceholder = userRole => {
	switch (userRole) {
		case "doctor":
			return "order id";
		default:
			return "email, contact or order id";
	}
};

const hasAltContact = ({ alternate_phone, alternate_email }) => {
	return !isEmpty(alternate_phone) || !isEmpty(alternate_email);
};

const showSeparator = ({ alternate_phone, alternate_email }) => {
	return !isEmpty(alternate_phone) && !isEmpty(alternate_email);
};
