import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
} from "../../../helpers";
import { LeadFilters } from "../../../common";
import { getSalesReps } from "../service";
import AssignSalesRep from "./AssignSalesRep";
import { getConsultReps, getMedicalReps } from "../../orderManagement/service";
import { Col, Row, Table, Typography } from "antd";
import moment from "moment";
const { Title } = Typography;

function LeadManagement({
	apiCall,
	columns,
	data,
	loading,
	paginationInfo,
	pageTitle,
}) {
	const history = useHistory();
	const [allSelectedKeys, setAllSelectedKeys] = useState([]);
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [startDate, setStartDate] = useState(getQueryParams("startDate"));
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);
	const [currPathName] = useState(window.location.pathname);

	useEffect(() => {
		getSalesReps();
		getMedicalReps();
		getConsultReps();
	}, []);

	useEffect(() => {
		const filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		const filterItems = [{ startDate: startDate }];
		setQueryParams(generateQuery(filterItems));
	}, [startDate]);

	useEffect(() => {
		history.push({ search: queryParams });
		if (!isEmpty(queryParams)) apiCall(queryParams);
	}, [apiCall, history, queryParams]);

	useEffect(() => {
		if (pageNo > paginationInfo.pageCount && paginationInfo.pageCount > 1)
			setPageNo(paginationInfo.pageCount);
	}, [pageNo, history, paginationInfo]);

	useEffect(() => {
		if (!isEmpty(allSelectedKeys)) {
			let currPageKeys = [];
			let otherPageKeys = [];
			allSelectedKeys.map(row => {
				if (data.some(i => i.key === row)) {
					return (currPageKeys = [...currPageKeys, row]);
				} else {
					return (otherPageKeys = [...otherPageKeys, row]);
				}
			});
			setSelectedKeys(currPageKeys);
			setSelectionFromOtherPage(otherPageKeys);
		}
	}, [allSelectedKeys, data]);

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: selectedRowKeys => {
			setAllSelectedKeys([...selectionFromOtherPage, ...selectedRowKeys]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	const updateUi = () => {
		apiCall(queryParams);
		clearSelection();
	};

	const clearSelection = () => {
		setAllSelectedKeys([]);
		setSelectedKeys([]);
		setSelectionFromOtherPage([]);
	};

	return (
		<>
			{(currPathName === "/case-management/new-leads" ||
				currPathName === "/case-management/all-leads") && (
				<Row>
					<Col>
						<AssignSalesRep
							selectedKeys={allSelectedKeys}
							updateUi={updateUi}
						/>
					</Col>
				</Row>
			)}
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>{pageTitle}</Title>
				</Col>

				<Col>
					<LeadFilters
						clearSelection={clearSelection}
						setPageNo={setPageNo}
						setQueryParams={setQueryParams}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={data}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
						rowSelection={
							(currPathName === "/case-management/new-leads" ||
								currPathName === "/case-management/all-leads") &&
							rowSelection
						}
					/>
				</Col>
			</Row>
		</>
	);
}

export default LeadManagement;
