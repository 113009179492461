import React, { useState } from "react";
import { Button } from "antd";
import SendMessageModal from "./SendMessageModal";

export const SendMessage = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button onClick={() => setShowModal(true)} type="primary">
				Send Message
			</Button>

			<SendMessageModal isVisible={showModal} setIsVisible={setShowModal} />
		</>
	);
};
