import React from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { useHistory } from "react-router-dom";
import { forgot } from "../service";
import trayaLogo from "../../../assets/traya@2x.png";

function ForgotPassword() {
  const history = useHistory();

  const onFinish = async usersDetails => {
    const details = await forgot(usersDetails);

    if (details.status === 200) {
      message.success("Please check your email to reset your password", 1, () =>
        history.push(`/`)
      );
    } else if (details.status === 400) {
      message.error("User with this email doesn't exist");
    } else {
      message.error("Internal server issue");
    }
  };

  return (
    <Row align="middle" justify="center" style={{ minHeight: "90vh" }}>
      <Col span={6} className="form-login-container">
        <div className="ant-row ant-form-item">
          <Row align="middle" justify="center" className="logo-container">
            <img
              src={trayaLogo}
              width="60"
              alt="Logo"
              style={{ justifyContent: "center" }}
            />
          </Row>
        </div>

        <Form
          name="basic"
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email address",
              },
              {
                type: "email",
                message: "Please enter valid email address",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item className="align-center">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default ForgotPassword;
