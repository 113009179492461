import React from "react";

// Antd
import { Input } from "antd/es";
const { TextArea } = Input;

const _components = {
	textarea: TextArea,
};

const components = ({ component, id, inputProps }) => {
	if (typeof _components[component] !== "undefined") {
		return React.createElement(_components[component], {
			key: id,
			...inputProps,
		});
	}

	return React.createElement(
		() => <div>The component {component} has not been created yet.</div>,
		{ key: id }
	);
};

export default components;
