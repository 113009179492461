import { usersManagmentActions } from "../actions";

export const usersmanagement = (state = {}, action) => {
  switch (action.type) {
    case usersManagmentActions.GET_ALL_ROLES_LOADING:
      return {
        ...state,
        allRolesLoading: true,
      };
    case usersManagmentActions.GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        allRoles: action.payload,
        allRolesLoading: false,
      };
    case usersManagmentActions.GET_ALL_ROLES_FAILURE:
      return {
        ...state,
        allRoles: action.payload,
        allRolesLoading: false,
      };

    case usersManagmentActions.CREATE_NEW_USER_LOADING:
      return {
        ...state,
        createNewUserLoading: true,
      };
    case usersManagmentActions.CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        createNewUserLoading: false,
        createNewUserData: action.payload,
      };
    case usersManagmentActions.CREATE_NEW_USER_FAILURE:
      return {
        ...state,
        createNewUserLoading: false,
        createNewUserData: action.payload,
      };

    case usersManagmentActions.GET_ALL_USERS_LOADING:
      return {
        ...state,
        allUsersLoading: true,
      };
    case usersManagmentActions.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        allUsersLoading: false,
      };
    case usersManagmentActions.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        allUsers: action.payload,
        allUsersLoading: false,
      };

    case usersManagmentActions.UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: true,
      };
    case usersManagmentActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserData: action.payload,
        updateUserLoading: false,
      };
    case usersManagmentActions.UPDATE_USER_FAILURE:
      return {
        ...state,
        updateUserData: action.payload,
        updateUserLoading: false,
      };
    case usersManagmentActions.GET_INTERNAL_ACTIVITY_LOG_LOADING:
      return {
        ...state,
        loading_logs: true,
      };
    case usersManagmentActions.GET_INTERNAL_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        loading_logs: false,
        activity_logs: action.payload,
      };
    case usersManagmentActions.GET_INTERNAL_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        loading_logs: false,
        activity_logs: [],
      };

    default:
      return state;
  }
};
