export const doctorActions = {
	GET_DOCTORS_LIST_LOADING: "GET_DOCTORS_LIST_LOADING",
	GET_DOCTORS_LIST_SUCCESS: "GET_DOCTORS_LIST_SUCCESS",
	GET_DOCTORS_LIST_FAILURE: "GET_DOCTORS_LIST_FAILURE",

	GET_ASSIGNED_DOCTORS_LOADING: "GET_ASSIGNED_DOCTORS_LOADING",
	GET_ASSIGNED_DOCTORS_SUCCESS: "GET_ASSIGNED_DOCTORS_SUCCESS",
	GET_ASSIGNED_DOCTORS_FAILURE: "GET_ASSIGNED_DOCTORS_FAILURE",

	GET_ASSIGNED_ORDERS_LOADING: "GET_ASSIGNED_ORDERS_LOADING",
	GET_ASSIGNED_ORDERS_SUCCESS: "GET_ASSIGNED_ORDERS_SUCCESS",
	GET_ASSIGNED_ORDERS_FAILURE: "GET_ASSIGNED_ORDERS_FAILURE",

	GET_PRESCRIBED_PATIENTS_LOADING: "GET_PRESCRIBED_PATIENTS_LOADING",
	GET_PRESCRIBED_PATIENTS_SUCCESS: "GET_PRESCRIBED_PATIENTS_SUCCESS",
	GET_PRESCRIBED_PATIENTS_FAILURE: "GET_PRESCRIBED_PATIENTS_FAILURE",

	UPDATE_ASSIGNED_DOCTOR_LOADING: "UPDATE_ASSIGNED_DOCTOR_LOADING",
	UPDATE_ASSIGNED_DOCTOR_SUCCESS: "UPDATE_ASSIGNED_DOCTOR_SUCCESS",
	UPDATE_ASSIGNED_DOCTOR_FAILURE: "UPDATE_ASSIGNED_DOCTOR_FAILURE",
};
