import React, { useState } from "react";
import { isEmpty } from "lodash";
import TransferSearch from "./TransferSearch";
import TransferOrderSearch from "./TransferOrderSearch";
import { Button, Col, Row, Typography, Popconfirm, message, List } from "antd";
import { transferOrder } from "../service";
const { Title } = Typography;

function TransferOrder() {
	const [selectedOrderSearch, setSelectedOrderSearch] = useState({});
	const [selectedSearch, setSelectedSearch] = useState({});
	const [searchValue, setSearchValue] = useState(undefined);
	const [orderSearchValue, setOrderSearchValue] = useState(undefined);
	const [isMovementCompleted, setIsMovementCompleted] = useState(false);
	const [searchData, setSearchData] = useState([]);
	const [orderSearchData, setOrderSearchData] = useState([]);

	const handleConfirm = async () => {
		const fromCaseId = selectedOrderSearch.case_id;
		const orderList = selectedOrderSearch.orderList;
		const toCaseId = selectedSearch.case_id;

		if (isEmpty(orderList)) {
			message.warning("Please choose one or more orders to transfer");
			return;
		}

		if (fromCaseId === toCaseId) {
			message.warning(`From and To case cannot be the same`);
			return;
		}

		setIsMovementCompleted(true);
		const response = await transferOrder(fromCaseId, toCaseId, orderList);
		setIsMovementCompleted(false);

		if (response) {
			setSelectedOrderSearch({});
			setSearchValue("");
			setOrderSearchValue("");
			setSearchData([]);
			setOrderSearchData([]);
			setSelectedSearch({});
		}
	};

	return (
		<>
			<Row justify="space-between">
				<Col>
					<Title level={2}>Order Movement System</Title>
				</Col>
			</Row>
			<Row>
				<Col span={11}>
					<TransferOrderSearch
						selectedOrderSearch={selectedOrderSearch}
						setSelectedOrderSearch={setSelectedOrderSearch}
						orderSearchValue={orderSearchValue}
						setOrderSearchValue={setOrderSearchValue}
						orderSearchData={orderSearchData}
						setOrderSearchData={setOrderSearchData}
					/>
				</Col>
				<Col span={2}>
					<Title level={2} style={{ textAlign: "center" }}>
						TO
					</Title>
				</Col>
				<Col span={11}>
					<TransferSearch
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						searchData={searchData}
						setSearchData={setSearchData}
						selectedSearch={selectedSearch}
						setSelectedSearch={setSelectedSearch}
					/>
				</Col>
			</Row>
			<Row>
				<Col span={24} style={{ marginTop: "2rem" }}>
					<Title level={2}>Important Notes</Title>
				</Col>
			</Row>
			<Row>
				<List
					dataSource={fromNotes}
					renderItem={item => (
						<List.Item>
							<Title level={5}>{item}</Title>
						</List.Item>
					)}
				/>
			</Row>
			<Row justify="center">
				<Popconfirm
					disabled={
						isEmpty(selectedOrderSearch.orderList) ||
						isEmpty(selectedSearch) ||
						isMovementCompleted
					}
					title="Are you sure you want to move this order?"
					placement="topRight"
					onConfirm={handleConfirm}>
					<Button
						disabled={
							isEmpty(selectedOrderSearch.orderList) || isEmpty(selectedSearch)
						}
						type="primary"
						danger
						size="large"
						loading={isMovementCompleted}
						style={{
							fontSize: "1.5rem",
							padding: ".2em 1em",
							fontWeight: "bold",
							height: "max-content",
						}}>
						CONFIRM AND TRANSFER
					</Button>
				</Popconfirm>
			</Row>
		</>
	);
}

export default TransferOrder;

const fromNotes = [
	`1. The order from the left side search result will be transferred to the right.`,
	`2. Once the transfer is completed, the user on the left will no
          longer be availaible.`,
	`3. This is a one time operation and can't be undone.`,
	`4. This activity is logged in the system.`,
	`5. The following data will not be moved:`,
	`Phone number, Email address, Diet plan, Reminders, Tags and Tag History`,
];
