import React, { useCallback, useEffect, useState } from "react";

import { Typography, Card } from "antd";
import { fetchRequest } from "../../helpers/fetchRequest";
import { TODAY_SALES_TASK_API } from "../../constants/apiUrls";
const { Text, Title } = Typography;

const TodaysTasks = () => {
	const [loading, setLoading] = useState(false);
	const [todaysTarget, setTodaysTarget] = useState({});

	// fetch monthly target
	const getTodaysTarget = useCallback(async () => {
		let res = {};
		setLoading(true);

		try {
			res = await fetchRequest(TODAY_SALES_TASK_API);
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setTodaysTarget({});
			else setTodaysTarget(res.data);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getTodaysTarget();
	}, [getTodaysTarget]);
	// Ends

	return (
		<div>
			<Title level={3}>Today's Tasks</Title>

			<Card loading={loading}>
				<div className="grid gap-2 grid-cols-1">
					<div className="flex gap-2 items-center">
						<Text strong>Target Calls : </Text>
						<Text>{todaysTarget?.target}</Text>
					</div>

					<div className="flex gap-2 items-center">
						<Text strong>Completed Orders : </Text>
						<Text>{todaysTarget?.orders}</Text>
					</div>

					<div className="flex gap-2 items-center">
						<Text strong>Pitched : </Text>
						<Text>{todaysTarget?.pitched}</Text>
					</div>
				</div>
			</Card>
		</div>
	);
};

export default TodaysTasks;
