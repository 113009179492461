import React, { useState, useEffect } from "react";
import {
  getProfileTags,
  addProfileTag,
  viewDetails,
  deleteProfileTag,
  getCaseTagHistory,
} from "../pages/leadDetails/service";
import { Select, message } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUserRole } from "../helpers";
const { Option } = Select;

function Profile({ caseTags }) {
  const params = useParams();
  const [tags, setTags] = useState([]);
  const [userRole] = useState(getCurrentUserRole());

  const { tagsDetail, loading } = useSelector(state => ({
    tagsDetail: window.getValue(state, "leaddetails.profileTagData"),
    loading: window.getValue(state, "leaddetails.profileTagDataLoading"),
  }));

  useEffect(() => {
    getProfileTags();
  }, []);

  useEffect(() => {
    let tagName = [];
    if (caseTags) {
      caseTags.map(tags => {
        tagName.push(tags.tag.display_name);
      });
      setTags([...tagName]);
    }
  }, [caseTags]);

  const handleSelect = async tag => {
    const caseId = params.id;
    const response = await addProfileTag(caseId, tag);

    if (response.status === 200) {
      getCaseTagHistory(caseId);
      message.success("Tag is saved");
      viewDetails(params.id);
    } else {
      message.error("Failed to delete the tag");
    }
  };

  const handleDeSelect = async tagName => {
    const caseId = params.id;
    const tagDisplayName = tagsDetail.find(tag => {
      return tag.display_name === tagName;
    });

    const response = await deleteProfileTag(caseId, tagDisplayName.id);

    if (response.status === 200) {
      message.success("Tag is deleted");
      viewDetails(params.id);
    } else {
      message.error("Failed to delete the tag");
    }
  };

  return (
    <>
      <Select
        style={{ width: "100%" }}
        mode="multiple"
        placeholder="Select tags"
        onSelect={handleSelect}
        onDeselect={handleDeSelect}
        value={tags}
        disabled={hideTags.includes(userRole)}
        loading={loading}
      >
        {tagsDetail &&
          tagsDetail.map(tag => (
            <Option
              key={tag.id}
              disabled={tags.some(tagName => tagName === tag.display_name)}
              loading={loading}
            >
              {tag.display_name}
            </Option>
          ))}
      </Select>
    </>
  );
}

export default Profile;

const hideTags = ["support", "read_only"];
