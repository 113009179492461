export const prescriptionActions = {
  GET_PRESCRIPTION_LOADING: "GET_PRESCRIPTION_LOADING",
  GET_PRESCRIPTION_SUCCESS: "GET_PRESCRIPTION_SUCCESS",
  GET_PRESCRIPTION_FAILURE: "GET_PRESCRIPTION_FAILURE",

  GET_ORDER_ITEMS_LOADING: "GET_ORDER_ITEMS_LOADING",
  GET_ORDER_ITEMS_SUCCESS: "GET_ORDER_ITEMS_SUCCESS",
  GET_ORDER_ITEMS_FAILURE: "GET_ORDER_ITEMS_FAILURE",

  UPDATE_PRESCRIPTION_LOADING: "UPDATE_PRESCRIPTION_LOADING",
  UPDATE_PRESCRIPTION_SUCCESS: "UPDATE_PRESCRIPTION_SUCCESS",
  UPDATE_PRESCRIPTION_FAILURE: "UPDATE_PRESCRIPTION_FAILURE",
};
