import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { getRecentLeads } from "../service";
import LeadManagement from "./LeadManagement";
import { formStatusTitle } from "../utils/formStatus";
import { statusTagColor } from "../../../helpers/tagColor";
import { Tag, Typography } from "antd";
const { Text } = Typography;

function RecentLeads() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});

	const { recentLeads, loading } = useSelector(state => ({
		recentLeads: window.getValue(state, "leadmanagement.recentLeads"),
		loading: window.getValue(state, "leadmanagement.recentLeadsLoading"),
	}));

	useEffect(() => {
		if (isEmpty(recentLeads)) setData([]);
		else {
			setData(() =>
				recentLeads.data.map(row => ({
					key: window.getValue(row, "id"),
					name: window.getValue(row, "user.first_name"),
					phoneno: window.getValue(row, "user.phone_number"),
					status: window.getValue(row, "status"),
					created: window.getValue(row, "created_at"),
					formStatus: window.getValue(row, "form_status"),
					lastFormFillDate: window.getValue(row, "last_form_fill_date"),
				}))
			);
			setPaginationInfo(recentLeads.pagination);
		}
	}, [recentLeads]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { key, phoneno }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>
					<Text type="secondary">{phoneno}</Text>
				</div>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status = "") => (
				<Tag color={statusTagColor[status]} key={status}>
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Form status",
			dataIndex: "formStatus",
			key: "formStatus",
			render: formStatus => (
				<Text style={{ textTransform: "capitalize" }}>
					{formStatusTitle[formStatus]}
				</Text>
			),
		},
		{
			title: "Last form fill date",
			dataIndex: "lastFormFillDate",
			key: "lastFormFillDate",
			align: "center",
			render: lastFormFillDate => (
				<Text>
					{isEmpty(lastFormFillDate)
						? "-"
						: moment(lastFormFillDate).format("DD MMM YYYY, h:mm a")}
				</Text>
			),
		},
		{
			title: "Created",
			dataIndex: "created",
			key: "created",
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<LeadManagement
			apiCall={getRecentLeads}
			columns={columns}
			data={data}
			loading={loading}
			paginationInfo={paginationInfo}
			pageTitle="New Leads"
		/>
	);
}

export default RecentLeads;
