import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Ant Design
import { Table, Tag, Tooltip, Typography } from 'antd/es';
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
	getValue,
} from '../../../helpers';
import { statusTagColor } from '../../../helpers/tagColor';
import Conversations from '../../ticketing/components/Conversations';
import UpdateTicket from '../../ticketing/components/UpdateTicket';
import ViewTicket from '../../ticketing/components/ViewTicket';
import { categoryMap, subCategoryMap } from '../../ticketing/helpers';
import { getCaseTickets } from '../service';
const { Text } = Typography;

const TicketHistory = () => {
	const { id } = useParams();
	const [openModel, setOpenModel] = useState(false);
	const [viewData, setViewData] = useState({});
	const [tickets, setTickets] = useState([]);
	const [loading, setLoading] = useState(false);

	const getTickets = useCallback(async (caseId) => {
		setLoading(true);
		const data = await getCaseTickets(caseId);
		console.log(data);
		setTickets(data);
		setLoading(false);
	}, []);

	useEffect(() => {
		getTickets(id);
	}, [getTickets, id]);

	const updateTicketsUi = () => {
		getTickets(id);
	}

	const data = useMemo(() => {
		if (!Array.isArray(tickets)) return [];

		let _tickets = tickets || [];

		return _tickets.map(ticket => ({
			key: getValue(ticket, '_id'),
			case_id: getValue(ticket, 'case_id'),
			created_at: getValue(ticket, 'createdAt'),
			description: getValue(ticket, 'description'),
			order_display_id: getValue(ticket, 'meta.order_display_id'),
			status: getValue(ticket, 'status'),
			category: getValue(ticket, 'category'),
			sub_category: getValue(ticket, 'sub_category'),
			priority: getValue(ticket, 'priority'),
			ticket_id: getValue(ticket, 'provider_meta.ref_id'),
			customer: {
				...getValue(ticket, 'customer'),
				case_id: getValue(ticket, 'case_id'),
			},
			comments: getValue(ticket, 'comments'),
			history: getValue(ticket, 'history'),
			current_assignment: getValue(ticket, 'current_assignment'),
			author: getValue(ticket, 'author'),
			source_meta: getValue(ticket, 'source_meta'),
			status_code: getValue(ticket, 'status_code'),
			priority_code: getValue(ticket, 'priority_code'),
			provider_meta: getValue(ticket, 'provider_meta'),
			due_date: getValue(ticket, 'due_date'),
		}));
	}, [tickets]);

	const column = [
		{
			title: 'Ticket Number',
			dataIndex: 'key',
			key: 'key',
			ellipsis: true,
			render: (value, record) => (
				<Tooltip placement="topLeft" title={value}>
					<Text
						className="link"
						onClick={() => {
							setOpenModel(true);
							setViewData(record);
						}}>
						{value}
					</Text>
				</Tooltip>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status = '') => (
				<Tag color={statusTagColor[status]} key={status}>
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category',
			render: (value, record) => `${categoryMap[value]}, ${subCategoryMap[record.sub_category]}`
		},
		{
			title: 'Assignee',
			dataIndex: 'current_assignment',
			keys: 'current_assignment',
			render: value => value.first_name,
		},
		{
			title: 'Created Date',
			dataIndex: 'created_at',
			key: 'created_at',
			sortOrder: 'descend',
			sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
			render: value => moment(value).format('DD MMM YYYY, h:mm a'),
		},
		{
			title: 'Action',
			key: 'action',
			fixed: 'right',
			width: '14%',
			render: record => (
				<div>
					<UpdateTicket data={record} updateUI={updateTicketsUi} showIcon={true} />
					<Conversations data={record} showIcon={true} />
				</div>
			),
		},
	];

	return (
		<div style={{ maxHeight: "300px" }}>
			<div className="w-full">
				<Table
					columns={column}
					dataSource={data}
					loading={loading}
					pagination={false}
					scroll={{
						y: 220,
					}}
				/>
			</div>

			<ViewTicket
				data={viewData}
				openModel={openModel}
				setOpenModel={setOpenModel}
			/>
		</div>
	);
};

export default TicketHistory;
