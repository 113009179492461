import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import AssignEngagements from "./AssignEngagements";
import { postSalesEngagementOptions } from "../../constants/options";
import { getValue } from "../../helpers";

import { Tag, Table, Select, Typography } from "antd/es";
import { Button, Checkbox } from "antd";
const { Title, Text } = Typography;

const AdminMedRepEngagements = ({ engagements, loading }) => {
	const [filterTag, setfilterTag] = useState(null);
	const [isRescheduled, setIsRescheduled] = useState(false);
	const [filterDone, setFilterDone] = useState(false);
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);

	const data = useMemo(() => {
		if (isEmpty(engagements)) return [];
		const _engagements = engagements.engagement.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "order.case_id"),
			count: getValue(row, "order.count"),
			due_date: getValue(row, "reminder_date"),
			data_type: "engagement",
			name: getValue(row, "order.case.user.first_name"),
			order_display_id: getValue(row, "order.order_display_id"),
			phone_number: getValue(row, "order.case.user.phone_number"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag"),
			assign_to: getValue(row, "temporaryAssignment.first_name"),
			reschedule_count: getValue(row, "reschedule_count"),
		}));

		const _reminders = engagements.reminders.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			comment: getValue(row, "comment"),
			data_type: "reminder",
			due_date: getValue(row, "reminder_date"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag") || "user",
			phone_number: getValue(row, "case.user.phone_number"),
			order_display_id: getValue(row, "case.order_display_id"),
			batch: getValue(row, "batch"),
			booking_type: "reminder",
			datetime: getValue(row, "reminder_date"),
			name: getValue(row, "case.user.first_name"),
			assign_to: getValue(row, "temporaryAssignment.first_name"),
			reschedule_count: getValue(row, "reschedule_count"),
		}));

		const _data = [..._engagements, ..._reminders];
		return _data.sort((a, b) => {
			return new Date(a.reminder_date) - new Date(b.reminder_date);
		});
	}, [engagements]);

	const tableData = useMemo(() => {
		let _data = filterTag ? data.filter(({ tag }) => tag === filterTag) : data;
		if (isRescheduled) {
			return _data.filter(({ reschedule_count }) => reschedule_count > 0);
		} else {
			return _data;
		}
	}, [data, filterTag, isRescheduled]);

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	const column = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (text, { case_id, phone_number }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${case_id}`}>
						{text}
					</Link>

					<Text>{phone_number}</Text>
				</div>
			),
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			render: id => (id ? id : "-"),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: status => (
				<Tag key={status} color="cyan">
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Tag",
			key: "tag",
			render: ({ tag, reschedule_count }) => (
				<>
					<Tag key={tag} color="geekblue">
						{tag && tag.toUpperCase()}
					</Tag>
					{reschedule_count && reschedule_count !== null && (
						<Tag color="gold">Rescheduled</Tag>
					)}
				</>
			),
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			defaultSortOrder: false,
			sorter: (a, b) => a.count - b.count,
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Due Date",
			dataIndex: "due_date",
			key: "due_date",
			defaultSortOrder: "descend",
			sorter: (a, b) => moment(a.due_date) - moment(b.due_date),
			render: due_date =>
				due_date ? moment(due_date).format("DD MMM YYYY, h:mm a") : "-",
		},
		{
			title: "Temporarily Assigned to",
			dataIndex: "assign_to",
			key: "assign_to",
		},
	];

	return (
		<>
			<Title level={3}>Engagements</Title>
			<div className="flex justify-between mb-4 mt-4">
				<AssignEngagements selectedOrders={allSelectedOrders} />

				<div className="flex gap-2">
					<Select
						allowClear
						options={postSalesEngagementOptions}
						placeholder="Filter Tag"
						onChange={selectedRowKeys => setfilterTag(selectedRowKeys)}
						style={{ width: "12rem" }}
					/>
					<Checkbox
						style={{ alignSelf: "center", fontSize: "20px" }}
						onChange={e => setIsRescheduled(e.target.checked)}>
						Rescheduled
					</Checkbox>
					<Button
						type="primary"
						className="ml-1"
						onClick={() => setFilterDone(!filterDone)}>
						Apply
					</Button>
				</div>
			</div>

			<Table
				columns={column}
				dataSource={tableData}
				loading={loading}
				pagination={{
					pageSize: 10,
					showSizeChanger: false,
				}}
				rowSelection={rowSelection}
			/>
		</>
	);
};
export default AdminMedRepEngagements;
