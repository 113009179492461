import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
	getMedicalReps,
	assignMedicalRep,
	getConsultReps,
} from "../../orderManagement/service";
import { Badge, Button, message, Select, Space, Typography } from "antd";
const { Option } = Select;
const { Text } = Typography;

const AssignMedical = ({ updateUi }) => {
	const params = useParams();
	const [isAssigning, setIsAssigning] = useState(false);
	const [dropdownData, setDropdownData] = useState(null);

	useEffect(() => {
		getMedicalReps();
		getConsultReps();
	}, []);

	const { consultReps, medicalReps } = useSelector(state => ({
		consultReps: window.getValue(state, "orderManagement.consultReps.userRole"),
		medicalReps: window.getValue(state, "orderManagement.medicalReps.userRole"),
	}));

	const onChange = selectedRowKeys => setDropdownData(selectedRowKeys);

	const assignRole = () => {
		setIsAssigning(true);
		const order_id = null;

		assignMedicalRep(dropdownData, params.id, order_id)
			.then(response => {
				if (response.status === 200) {
					setDropdownData(null);
					updateUi();
					message.success("Assigned Successfully");
				} else {
					response
						.json()
						.then(res => {
							message.error(res.message);
						})
						.catch(() => message.error("Unexpected Error"));
				}
			})
			.catch(error => console.log(error.message))
			.finally(() => setIsAssigning(false));
	};

	return (
		<>
			<Select
				allowClear
				onChange={onChange}
				optionFilterProp="label"
				placeholder="Select a person"
				showSearch
				style={{ width: 240 }}
				value={dropdownData}>
				{!isEmpty(medicalReps) &&
					medicalReps.map(rep => (
						<Option
							label={rep.user.first_name}
							value={rep.user.id}
							key={rep.user.id}>
							<Space align="center">
								<Text>{rep.user.first_name}</Text>
								<Badge
									count="Medical Rep"
									style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
								/>
							</Space>
						</Option>
					))}

				{!isEmpty(consultReps) &&
					consultReps.map(rep => (
						<Option
							label={rep.user.first_name}
							value={rep.user.id}
							key={rep.user.id}>
							<Space align="center">
								<Text>{rep.user.first_name}</Text>
								<Badge
									count="Consult Rep"
									style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
								/>
							</Space>
						</Option>
					))}
			</Select>

			<Button
				disabled={isEmpty(dropdownData)}
				loading={isAssigning}
				onClick={assignRole}
				type="primary">
				Assign
			</Button>
		</>
	);
};

export default AssignMedical;
