import moment from "moment";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react";

import CreateTicket from "../../../../pages/ticketing/components/CreateTicket";
import { fetchDataByDate } from "../api";
import {
	engagementScheduleFn,
	engagementSummaryFn,
	inprogressOrdersFn,
	ordersValueFn,
	repeatOrdersFn,
	transformReminders,
} from "../helpers";
import {
	NewAssignedOrders,
	RepeatAssignedOrders,
} from "../../../../pages/orderManagement/components";
import { ACTIONS, INITIAL_VALUE, reducer } from "../store";
import AggregateSummary from "./AggregateSummary";
import DateFilter from "./DateFilter";
import EngagementsTable from "./EngagementsTable";

// antd
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import { Col, Row } from "antd";

const MedRepEngagements = ({ medRepId, showCreateTicket }) => {
	const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);
	const { assignedOrders, loading, rawEngagements, reminders } = state;

	const [sd, setSd] = useState(moment().startOf("d").toISOString());
	const [ed, setEd] = useState(moment().endOf("d").toISOString());

	useEffect(() => {
		return () => dispatch({ type: ACTIONS.RESET });
	}, []);

	useEffect(() => {
		if (!sd || !ed || !medRepId) return;
		fetchDataByDate(medRepId, sd, ed, dispatch);
	}, [ed, medRepId, sd]);

	const _remainders = useMemo(() => {
		return transformReminders(reminders);
	}, [reminders]);

	const engagementSummary = useMemo(() => {
		return engagementSummaryFn(rawEngagements);
	}, [rawEngagements]);

	const engagementSchedule = useMemo(() => {
		return engagementScheduleFn({
			engagements: rawEngagements,
			reminders: _remainders,
		});
	}, [_remainders, rawEngagements]);

	const repeatOrders = useMemo(() => {
		return repeatOrdersFn(assignedOrders);
	}, [assignedOrders]);

	const repeatOrdersCount = useMemo(() => {
		return repeatOrders.length;
	}, [repeatOrders]);

	const repeatOrdersValue = useMemo(() => {
		return ordersValueFn(repeatOrders);
	}, [repeatOrders]);

	const repeatOrdersAov = useMemo(() => {
		if (repeatOrdersValue <= 0 || repeatOrdersCount <= 0) return 0.0;
		return (repeatOrdersValue / repeatOrdersCount).toFixed(2);
	}, [repeatOrdersCount, repeatOrdersValue]);

	const repeatInProgressOrders = useMemo(() => {
		return inprogressOrdersFn(assignedOrders);
	}, [assignedOrders]);

	const setDate = useCallback((start, end) => {
		setSd(start);
		setEd(end);
	}, []);

	const resetDate = useCallback(() => {
		setSd(moment().startOf("d").toISOString());
		setEd(moment().endOf("d").toISOString());
	}, []);

	return (
		<div className="grid gap-4">
			<div className="flex justify-between">
				<DateFilter
					startDate={sd}
					endDate={ed}
					setDate={setDate}
					reset={resetDate}
				/>

				{/* {showCreateTicket && <CreateTicket />} */}
			</div>

			<EngagementsTable data={engagementSchedule} loading={loading} />

			<div className="mt-4">
				<Row gutter={[8, 8]}>
					<Col span={24}>
						<Title level={4}>Open Orders</Title>
					</Col>
					<Col span={11}>
						<NewAssignedOrders />
					</Col>
					<Col span={13}>
						<RepeatAssignedOrders />
					</Col>
				</Row>
			</div>

			<div className="flex gap-2 mt-2 items-baseline">
				<Title className="mb-0" level={3}>
					Engagement Status
				</Title>
				<Text>{engagementSummary.percentage}%</Text>
			</div>

			<div className="flex flex-col gap-2 mt-2">
				<Title className="mb-0" level={3}>
					Repeat Order Report
				</Title>

				<div className="grid gap-2 admin__post_sales__dashboard-grid">
					<AggregateSummary loading={loading} title="Orders">
						<Text>{repeatOrdersCount}</Text>
					</AggregateSummary>

					<AggregateSummary loading={loading} title="Revenue">
						{repeatOrdersValue}
					</AggregateSummary>

					<AggregateSummary loading={loading} title="AOV">
						{repeatOrdersAov}
					</AggregateSummary>

					<AggregateSummary loading={loading} title="In Progress Orders">
						<div className="flex gap-4">
							<div className="flex flex-col text-center">
								<Text strong>New</Text>
								<Text>{repeatInProgressOrders.new}</Text>
							</div>

							<div className="flex flex-col text-center">
								<Text strong>Repeat</Text>
								<Text>{repeatInProgressOrders.repeat}</Text>
							</div>
						</div>
					</AggregateSummary>
				</div>
			</div>
		</div>
	);
};

export default MedRepEngagements;
