import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { fetchRequest } from "../../helpers/fetchRequest";
import {
	getUrl,
	TEMPORARY_ASSIGN_ENGAGEMENT_API,
} from "../../constants/apiUrls";

//Antd
import { Badge, Button, Select, message, Space, Typography } from "antd";
import { getCurrentUserRole, getValue } from "../../helpers";
const { Option } = Select;
const { Text } = Typography;
//End

const TEMPORARY_ASSIGN_REMINDER_API = getUrl("v2/temporary-assign-reminder");

const AssignEngagements = ({ selectedOrders }) => {
	const { id: medRepId } = useParams();

	const [userRole] = useState(getCurrentUserRole());
	const [isAssigning, setIsAssigning] = useState(false);
	const [dropdownData, setDropdownData] = useState(null);

	const { medicalReps, medicalRepsLoading, myTeams, myTeamsLoading } =
		useSelector(state => ({
			medicalReps: getValue(state, "orderManagement.medicalReps.userRole"),
			medicalRepsLoading: getValue(state, "orderManagement.medicalRepsLoading"),
			myTeams: getValue(state, "supervisorsData.myTeam[0].userGroup"),
			myTeamsLoading: getValue(state, "supervisorsData.myTeamLoading"),
		}));

	const medReps = useMemo(() => {
		if (userRole === "admin") return medicalReps;
		if (userRole === "medical_rep") return myTeams;
	}, [medicalReps, myTeams, userRole]);

	const loading = useMemo(() => {
		if (userRole === "admin") return medicalRepsLoading;
		if (userRole === "medical_rep") return myTeamsLoading;
	}, [medicalRepsLoading, myTeamsLoading, userRole]);

	const presentData = useMemo(() => {
		if (isEmpty(medReps)) return [];
		return medReps.map(item => ({
			key: item.user.id,
			name: item.user.first_name,
		}));
	}, [medReps]);

	const medRepList = useMemo(() => {
		if (isEmpty(presentData)) return [];

		return presentData
			.filter(key => key.key !== medRepId)
			.map(item => ({
				key: item.key,
				name: item.name,
			}));
	}, [medRepId, presentData]);

	const assignCases = async data => {
		if (isEmpty(data)) return;
		const _requests = [];
		data.forEach(row => {
			if (row.data_type === "reminder") {
				const _res = fetchRequest(TEMPORARY_ASSIGN_REMINDER_API, {
					method: "PUT",
					body: JSON.stringify({ reminderId: row.key, repId: dropdownData }),
				});
				_requests.push(_res);
			} else {
				const _res = fetchRequest(TEMPORARY_ASSIGN_ENGAGEMENT_API, {
					method: "PUT",
					body: JSON.stringify({ engagementId: row.key, repId: dropdownData }),
				});
				_requests.push(_res);
			}
		});

		Promise.allSettled(_requests)
			.then(response => {
				if (response.every(res => res.value.status === 200)) {
					setDropdownData(null);
					message.success("Assigned Successfully");
				} else if (response.some(res => res.value.status === 400)) {
					setDropdownData(null);
					message.warn(
						"One or more engagements could not be assigned. The page will refresh with the latest data."
					);
				} else {
					const filterRes = response.filter(res => {
						return res.value.status !== 200 && res.value.status !== 400;
					});
					message.error(filterRes[0].value?.data?.message);
				}
			})
			.catch(error => {
				console.log(error.message);
			});
	};

	const handleAssignEngagements = async () => {
		setIsAssigning(true);
		await assignCases(selectedOrders);
		setIsAssigning(false);
	};

	return (
		<>
			<div>
				<Select
					allowClear
					loading={loading}
					onChange={selectedRowKeys => setDropdownData(selectedRowKeys)}
					optionFilterProp="label"
					placeholder="Select a person"
					showSearch
					style={{ width: 240 }}
					value={dropdownData}>
					{!isEmpty(presentData) &&
						medRepList.map(rep => (
							<Option label={rep.name} value={rep.key} key={rep.key}>
								<Space align="center">
									<Text>{rep.name}</Text>
									<Badge
										count="Medical Rep"
										style={{ backgroundColor: "#f1f1f1", color: "#313131" }}
									/>
								</Space>
							</Option>
						))}
				</Select>

				<Button
					disabled={isEmpty(dropdownData) || isEmpty(selectedOrders)}
					loading={isAssigning}
					onClick={handleAssignEngagements}
					type="primary">
					Assign
				</Button>
			</div>
		</>
	);
};
export default AssignEngagements;
