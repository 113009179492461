import { Space, Tag, Tooltip, Typography } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSubscriptionPlatform } from "../../../constants/orderSubscriptionPlatforms";
import { generateQuery, getValue } from "../../../helpers";
import { getQueryParams } from "../../../helpers/getQueryParams";
import queryParamsForApi from "../../../helpers/queryParamsForApi";
import { getRepeatOpenOrders } from "../service";
import RecentAssignedOrders from "./RecentAssignedOrders";
const { Text } = Typography;

function RepeatAssignedOrders() {
	const history = useHistory();

	const [ordersList, setOrdersList] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState([]);
	const [queryParams, setQueryParams] = useState(null);
	const [pageNo, setPageNo] = useState(parseInt(getQueryParams("rop")) || 1);
	const [status, setStatus] = useState(getQueryParams("ros"));

	const repLevel = useMemo(() => localStorage.getItem("rep!level"), []);

	useEffect(() => {
		const filterItems = [{ rop: pageNo }];
		const query = generateQuery(filterItems);
		setQueryParams(query);
		history.push({ search: query });
	}, [history, pageNo]);

	useEffect(() => {
		const filterItems = [{ ros: status }];
		setQueryParams(generateQuery(filterItems));
	}, [status]);

	useEffect(() => {
		if (!queryParams) return;
		history.push({ search: queryParams });
		const paramsForAPI = queryParamsForApi(queryForAPI);
		getRepeatOpenOrders(paramsForAPI);
	}, [history, queryParams]);

	const { assignedOrders, loading } = useSelector(state => ({
		assignedOrders: getValue(state, "orderManagement.repeatAssignedOrders"),
		loading: getValue(state, "orderManagement.repeatAssignedOrdersLoading"),
	}));

	useMemo(() => {
		if (!isEmpty(assignedOrders)) {
			setOrdersList(assignedOrders.data);
			setPaginationInfo(assignedOrders.pagination);
		}
	}, [assignedOrders]);

	const handleFilter = filters => {
		if (filters.order_status) {
			setStatus(JSON.stringify(filters.order_status));
		} else {
			setStatus("");
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text, record) => {
				let subscriptionPlatform = "";
				if (record.is_subscription) {
					subscriptionPlatform = getSubscriptionPlatform(record.order_meta);
					subscriptionPlatform = subscriptionPlatform
						? `(${subscriptionPlatform})`
						: "";
				}

				return (
					<Space direction="vertical">
						<Text
							className="link"
							onClick={() => history.push(`/lead-details/${record.case_id}`)}>
							{text}
						</Text>

						<Space direction="vertical">
							<Text>{record.order_display_id}</Text>
							<Tag className="uppercase" color="green">
								{record.order_type?.replaceAll("_", " ")}
							</Tag>

							<span>
								{record.is_subscription && (
									<Tooltip
										placement="bottom"
										title={`Subscription Order ${subscriptionPlatform}`}>
										<Tag color="green">S</Tag>
									</Tooltip>
								)}

								{record.is_bulk_order && (
									<Tooltip
										placement="bottom"
										title={`Bulk Order (${record.bulk_order_duration} months)`}>
										<Tag color="green">B</Tag>
									</Tooltip>
								)}
								{record.is_single_order && (
									<Tooltip placement="bottom" title="Single Order">
										<Tag color="green">SO</Tag>
									</Tooltip>
								)}
							</span>
						</Space>
					</Space>
				);
			},
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			defaultSortOrder: false,
			sorter: (a, b) => a.order_status.localeCompare(b.order_status),
			render: status => {
				return (
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
				);
			},
			filters: repLevel === "1" ? optionsStatus : null,
			defaultFilteredValue: status ? JSON.parse(status) : null,
		},
		{
			title: "Order Date",
			dataIndex: "created",
			key: "created",
			defaultSortOrder: false,
			sorter: (a, b) => moment(a.created) - moment(b.created),
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<RecentAssignedOrders
			columns={columns}
			handleFilter={handleFilter}
			loading={loading}
			pageNo={pageNo}
			paginationInfo={paginationInfo}
			setPageNo={setPageNo}
			tableData={ordersList}
			tableTitle="Repeat Orders"
		/>
	);
}

export default RepeatAssignedOrders;

const queryForAPI = {
	rop: "pageNumber",
	ros: "orderStatus",
};

const optionsStatus = [
	{ text: "Delivered", value: "delivered" },
	{ text: "Ghost", value: "ghost" },
	{ text: "In Progress", value: "in_progress" },
	{ text: "Pending", value: "pending" },
	{ text: "Pending Prescription", value: "pending_prescription" },
	{ text: "Shipped", value: "shipped" },
	{ text: "Shipping Pending", value: "shipping_pending" },
	{ text: "Unknown", value: "unknown" },
	{ text: "Void", value: "void" },
];
