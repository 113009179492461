import React, { useEffect, useMemo, useState } from "react";
import { ENGAGEMENT_FEEDBACK } from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import isEmpty from "lodash/isEmpty";
import { getValue } from "../../../helpers";
import { Table } from "antd";
import groupBy from "lodash/groupBy";

function EngagementFeedback({ orderId }) {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		engagement_feedbacks(orderId);
	}, [orderId]);

	const engagement_feedbacks = async id => {
		setLoading(true);
		let res = {};
		try {
			res = await fetchRequest(ENGAGEMENT_FEEDBACK(id));
			let _data = groupBy(res.data, item => item.tag);
			engagementsTypes.forEach(tag => {
				if (!_data[tag]) res.data.push({ tag: tag });
			});
			setData(res.data);
		} catch (error) {
			console.warn(error.message);
		}
		setLoading(false);
	};

	const tData = useMemo(() => {
		if (isEmpty(data)) return [];
		return data.map(item => ({
			adherence_reason: getValue(item, "adherence_reason"),
			any_product_reaction: getValue(item, "any_product_reaction"),
			customer_adherence: getValue(item, "customer_adherence"),
			energy: getValue(item, "energy"),
			gut_health: getValue(item, "gut_health"),
			hair_growth: getValue(item, "hair_growth"),
			hair_loss: getValue(item, "hair_loss"),
			mood: getValue(item, "mood"),
			product_reaction: getValue(item, "product_reaction"),
			not_ordering_reason: getValue(item, "not_ordering_reason"),
			tag: getValue(item, "tag"),
			comment: getValue(item, "comment"),
		}));
	}, [data]);

	const columns = useMemo(() => {
		const _col = [
			{
				title: "Engagements",
				dataIndex: "tag",
				key: "tag",
				width: "90px",
				defaultSortOrder: "ascend",
				sorter: (a, b) => a.tag.localeCompare(b.tag),
			},
			{
				title: `Product Reaction Yes/No`,
				key: "any_product_reaction",
				dataIndex: "any_product_reaction",
			},
			{
				title: "Regular / Irregular",
				key: "customer_adherence",
				dataIndex: "customer_adherence",
			},
			{
				title: "Irregular",
				key: "adherence_reason",
				dataIndex: "adherence_reason",
			},
			{ title: "Energy 1/5/10", key: "energy", dataIndex: "energy" },
			{
				title: "Gut Health 1/5/10",
				key: "gut_health",
				dataIndex: "gut_health",
			},
			{
				title: "Hair Growth 1/5/10",
				key: "hair_growth",
				dataIndex: "hair_growth",
			},
			{
				title: "Hair Fall 1/5/10",
				key: "hair_loss",
				dataIndex: "hair_loss",
			},
			{
				title: "Mood -No Change(1), Some Change(5), Improvement(10)",
				key: "mood",
				dataIndex: "mood",
			},
			{
				title: "Product Reaction",
				key: "product_reaction",
				dataIndex: "product_reaction",
			},
			{
				title: "Reason for not ordering",
				key: "not_ordering_reason",
				dataIndex: "not_ordering_reason",
			},
		];
		return _col;
	}, []);
	return (
		<>
			<Table
				bordered
				columns={columns}
				dataSource={tData}
				loading={loading}
				pagination={false}
			/>
		</>
	);
}

export default EngagementFeedback;

const engagementsTypes = [
	"Week #1",
	"Week #2",
	"Week #3",
	"Week #4",
	"Week #5",
	"Week #6",
];
