import React, { useEffect, useState } from "react";

import { getValue } from "../../../helpers";
import { updateUser } from "../../usersManagment/service";
import { weekOptions } from "../../../constants/options";

// Antd
import { EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, message, Modal } from "antd/es";

const layout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 },
	},
};
const tailLayout = {
	wrapperCol: { offset: 10 },
};

const Edit = ({ data, refreshUI }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const handleModalClose = () => {
		setShowModal(false);
	};

	useEffect(() => {
		form.setFieldsValue({
			breakWeekDays: getValue(data, "user_meta.breakWeekDays") || [],
		});
	}, [data, form]);

	const handleSubmit = async formData => {
		const meta = getValue(data, "user_meta") || {};
		meta["breakWeekDays"] = formData.breakWeekDays;

		try {
			setLoading(true);

			const res = await updateUser(data.key, { meta: meta });
			if (res.status === 200) {
				message.success(" User Updated Successfully");
				refreshUI();
				setShowModal(false);
			} else {
				message.error("Failed to update user");
			}
		} catch (error) {
			console.warn(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<Button
				icon={<EditOutlined />}
				onClick={() => setShowModal(true)}
				type="link"
			/>

			<Modal
				visible={showModal}
				title="Edit user"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,
				]}>
				<Form {...layout} form={form} onFinish={handleSubmit}>
					<Form.Item
						style={{ alignItems: "baseline" }}
						label="Break Days"
						name="breakWeekDays">
						<Checkbox.Group
							className="grid grid-cols-3"
							options={weekOptions}
						/>
					</Form.Item>

					<Form.Item {...tailLayout}>
						<Button htmlType="submit" loading={loading} type="primary">
							Save
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default Edit;
