import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import AggregateSummary from "./components/AggregateSummary";
import { Parser } from "json2csv";
import {
	totalNewOrdersFn,
	totalRepeatOrdersFn,
	totalRevenueFn,
	postSalesAssignmentStatusFn,
	postSalesOrderLinkStatusFn,
	postSalesEngagementStatusFn,
	postSalesEscalationFn,
	postSalesKnowlarityCallsFn,
	secToHrs,
	postSalesCodOrdersFn,
} from "./transform";
import {
	Button,
	Col,
	DatePicker,
	Empty,
	Form,
	message,
	Select,
	Space,
	Row,
	Table,
	Typography,
	Modal,
	Tag,
} from "antd";
import {
	engagementReport,
	postSalesEscalationReport,
	postSalesReport,
} from "./api";
import { EyeOutlined } from "@ant-design/icons";
const { Title } = Typography;

export default function SalesReportContainer() {
	const [form] = Form.useForm();
	const [postSalesReportData, setpostSalesReportData] = useState({});
	const [engagementCallData, setEngagementCallData] = useState([]);
	const [assignedSalesTableData, setAssignedSalesTableData] = useState([]);
	const [postSalesOrderLinkData, setPostSalesOrderLinkData] = useState([]);
	const [assignedOrderLinkTableData, setAssignedOrderLinkTableData] = useState(
		[]
	);
	const [postSalesEscalationData, setPostSalesEscalationData] = useState([]);
	const [assignedEscalationTableData, setAssignedEscalationTableData] =
		useState([]);

	const [engagementTableData, setengagementTableData] = useState([]);
	const [totalNewOrders, setTotalNewOrders] = useState(0);
	const [totalRepeatOrders, setTotalRepeatOrders] = useState(0);
	const [totalRevenue, setTotalRevenue] = useState(0);
	const [startDate, setStartDate] = useState(
		moment().subtract(1, "days").startOf("day")
	);
	const [endDate, setEndDate] = useState(
		moment().subtract(1, "days").endOf("day")
	);

	const [isApiCall, setIsApiCall] = useState(false);
	const [loading, setLoading] = useState(false);
	const [csvDownloadLoading, setcsvDownloadLoading] = useState(false);
	const [csvDownloadStatus, setcsvDownloadStatus] = useState(undefined);
	const [assignedCases, setAssignedCases] = useState([]);
	const [assignedCasesTableData, setAssignedCasesTableData] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [pageNo, setPageNo] = useState(1);

	useEffect(() => {
		const apiCall = async (startDate, endDate) => {
			setLoading(true);
			const result = await postSalesReport(
				startDate.toISOString(),
				endDate.toISOString()
			);
			const totalOrders = result.data.order_analytics.filter(data => {
				return (
					data.count > 0 &&
					data.status !== "void" &&
					parseFloat(data.order_value) !== 0.0
				);
			});

			if (result.status === 200) {
				setpostSalesReportData(() => ({
					orders: totalOrders || [],
					knowlarityLogs: result.data?.knowlarity_logs || [],
					codOrders: result.data?.cod_orders || [],
				}));

				setPostSalesOrderLinkData(result.data?.order_link_analytics || []);
				setengagementTableData(totalOrders.remindersData);
				setIsApiCall(false);
			} else setpostSalesReportData([]);

			// const result_engagement = await engagementReport(
			// 	startDate.toISOString(),
			// 	endDate.toISOString()
			// );

			// console.log(`Eng-Result`, result_engagement);
			// if (result_engagement.status === 200) {
			// 	setEngagementCallData(result_engagement.data);
			// 	setIsApiCall(false);
			// } else setengagementTableData([]);

			const result_escalation = await postSalesEscalationReport(
				startDate.toISOString(),
				endDate.toISOString()
			);

			if (result_escalation.status === 200) {
				setPostSalesEscalationData(
					postSalesEscalationFn(result_escalation.data)
				);
				setIsApiCall(false);
			} else setAssignedEscalationTableData([]);

			setLoading(false);
		};

		if (startDate && endDate && isApiCall) apiCall(startDate, endDate);
	}, [endDate, isApiCall, startDate]);

	useEffect(() => {
		console.log(`Trigger effect`, postSalesReportData?.orders);

		setTotalNewOrders(totalNewOrdersFn(postSalesReportData?.orders));
		setTotalRepeatOrders(totalRepeatOrdersFn(postSalesReportData?.orders));
		setTotalRevenue(totalRevenueFn(postSalesReportData?.orders));
	}, [postSalesReportData]);

	useEffect(() => {
		const _postSalesStatus = postSalesAssignmentStatusFn(
			postSalesReportData?.orders,
			startDate,
			endDate
		);

		const _postSalesCalls = postSalesKnowlarityCallsFn(
			postSalesReportData.knowlarityLogs,
			startDate,
			endDate
		);

		const _codOrders = postSalesCodOrdersFn(postSalesReportData.codOrders);

		_postSalesCalls.forEach(({ rep_id, logs }) => {
			_postSalesStatus.map(item => {
				if (item.medrep_id === rep_id) item["callLogs"] = { ...logs };
				return item;
			});
		});

		Object.entries(_codOrders).forEach(([key, values]) => {
			_postSalesStatus.map(item => {
				if (item.medrep_id === key) item["codOrdersCount"] = values.length;
			});
		});

		setAssignedSalesTableData(_postSalesStatus);
	}, [postSalesReportData, startDate, endDate]);

	useEffect(() => {
		setAssignedOrderLinkTableData(
			postSalesOrderLinkStatusFn(postSalesOrderLinkData)
		);
	}, [postSalesOrderLinkData]);

	useEffect(() => {
		setAssignedEscalationTableData(postSalesEscalationData);
	}, [postSalesEscalationData]);

	useEffect(() => {
		if (isEmpty(assignedCases)) setAssignedCasesTableData([]);
		else {
			const ndata = assignedCases.map(data => ({
				customer_name: data.customer_name,
				customer_email: data.customer_email,
				case_id: data.case_id,
				count: data.count,
				order_display_id: data.order_display_id ? data.order_display_id : "-",
				order_date: data.order_date
					? moment(data.order_date).format("YYYY-MM-DD HH:mm ")
					: "-",
			}));
			setAssignedCasesTableData(ndata);
		}
	}, [assignedCases]);

	// useEffect(() => {
	// 	console.log(`Trigger effect engagementCallData`, engagementCallData);
	// 	setengagementTableData(postSalesEngagementStatusFn(engagementCallData));
	// }, [engagementCallData]);

	const handleSubmit = () => {
		if (isEmpty(startDate)) {
			message.error("please select start Date");
			return;
		}

		if (isEmpty(endDate)) {
			message.error("please select end Date");
			return;
		}

		if (startDate.isAfter(endDate, "day")) {
			message.error("Start date can not be after end date");
			return;
		}

		setIsApiCall(true);
	};

	const handleClear = () => {
		form.resetFields();
		setpostSalesReportData([]);
		setengagementTableData([]);
		setIsApiCall(false);
		setStartDate(undefined);
		setEndDate(undefined);
		setcsvDownloadStatus(undefined);
	};

	const handleDownload = async e => {
		setcsvDownloadStatus(e);
		if (e === "post_sales_report") {
			setcsvDownloadLoading(true);
			await downloadCSV(
				e,
				postSalesReportData?.orders,
				"post_sales_report.csv"
			);
			setcsvDownloadLoading(false);
		}
	};
	const handleInfo = assigned_cases => {
		setAssignedCases(assigned_cases);
		setPageNo(1);
		setModalVisible(true);
	};

	const handleModalClose = () => {
		setModalVisible(false);
		setAssignedCasesTableData([]);
		setAssignedCases([]);
	};
	const engagementTableColums = [
		{
			title: "Medical Rep",
			key: "medical_rep",
			dataIndex: "medical_rep",
		},
		{
			title: "Total Calls",
			key: "total_calls",
			dataIndex: "total_calls",
		},
		{
			title: "Prescription",
			key: "prescription_calls",
			dataIndex: "prescription_calls",
		},
		{
			title: "Week #1",
			key: "week1_calls",
			dataIndex: "week1_calls",
		},
		{
			title: "Week #2",
			key: "week2_calls",
			dataIndex: "week2_calls",
		},
		{
			title: "Week #3",
			key: "week3_calls",
			dataIndex: "week3_calls",
		},
		{
			title: "Week #4",
			key: "week4_calls",
			dataIndex: "week4_calls",
		},
		{
			title: "Week #5",
			key: "week5_calls",
			dataIndex: "week5_calls",
		},
		{
			title: "Week #6",
			key: "week6_calls",
			dataIndex: "week6_calls",
		},
		{
			title: "SPE",
			key: "spe_calls",
			dataIndex: "spe_calls",
		},
	];

	const assignedMedicalRepColumns = [
		{
			title: "Medical Rep",
			key: "medical_rep",
			dataIndex: "medical_rep",
		},

		{
			title: "Total Orders",
			dataIndex: "total_orders",
			key: "total_orders",
		},
		{
			title: "New Orders",
			dataIndex: "new_orders",
			key: "new_orders",
		},
		{
			title: "Repeat Orders",
			dataIndex: "repeat_orders",
			key: "repeat_orders",
		},
		{
			title: "Total Revenue",
			dataIndex: "revenue",
			key: "revenue",
		},
		{
			title: "AOV",
			dataIndex: "averageOrderValue",
			key: "averageOrderValue",
		},
		{
			title: "COD orders count",
			dataIndex: "codOrdersCount",
			key: "codOrdersCount",
		},
		{
			title: "Call Duration (Avg - hh:mm)",
			dataIndex: "callLogs",
			key: "average_call_duration",
			render: data => (data ? secToHrs(data.avgCallHrs) : "-"),
		},
		{
			title: "Action",
			key: "info",
			render: record => (
				<Button
					type="primary"
					icon={<EyeOutlined />}
					onClick={() => handleInfo(record.assignedOrders)}
				/>
			),
		},
	];

	const OrderDetailsColumn = [
		{
			title: "Rep",
			dataIndex: "rep_name",
			key: "rep_name",
		},
		{
			title: "Total Order Links Sent",
			dataIndex: "total_order_link_sent",
			key: "total_order_link_sent",
		},
		{
			title: "Total Orders on Same Day",
			dataIndex: "orders_same_day",
			key: "orders_same_day",
		},
		{
			title: "Total Orders After",
			dataIndex: "orders_day_after",
			key: "orders_day_after",
		},
		{
			title: "Total People Not Ordered",
			dataIndex: "not_ordered",
			key: "not_ordered",
		},
	];

	const assignedCasesColumn = [
		{
			title: "Name",
			key: "customer_name",
			render: record => {
				return (
					<span
						className="link"
						onClick={() =>
							window.open(`/lead-details/${record.case_id}`, "_blank")
						}>
						{record.customer_name}
					</span>
				);
			},
		},
		{
			title: "Email",
			dataIndex: "customer_email",
			key: "customer_email",
		},
		{
			title: "Order Display Id",
			dataIndex: "order_display_id",
			key: "order_display_id",
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Date",
			dataIndex: "order_date",
			key: "order_date",
		},
	];

	const assignedEscalationColumn = [
		{
			title: "Name",
			dataIndex: "rep_name",
			key: "rep_name",
		},

		{
			title: "Total number of escalations",
			dataIndex: "total_escalations",
			key: "total_escalations",
		},
	];

	return (
		<>
			<Row>
				<Col span="24">
					<Title level={2}>Post Sales Dashboard</Title>
				</Col>
			</Row>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Form form={form} layout="inline" onFinish={handleSubmit}>
						<Form.Item name="startDate">
							<DatePicker
								allowClear
								defaultValue={startDate}
								format="YYYY-MM-DD"
								onChange={e =>
									e
										? setStartDate(moment(e).startOf("day"))
										: setStartDate(undefined)
								}
								disabledDate={current =>
									current.isBefore(moment().subtract(30, "days"), "day")
								}
								placeholder="Start Date"
							/>
						</Form.Item>

						<Form.Item name="endDate">
							<DatePicker
								allowClear
								defaultValue={endDate}
								format={"YYYY-MM-DD"}
								onChange={e =>
									e ? setEndDate(moment(e).endOf("day")) : setEndDate(undefined)
								}
								placeholder="End Date"
							/>
						</Form.Item>

						<Form.Item>
							<Space>
								<Button type="primary" htmlType="submit" loading={loading}>
									Apply
								</Button>

								<Button htmlType="button" onClick={handleClear}>
									Clear
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Col>
				<Col>
					<Select
						disabled={
							isEmpty(postSalesReportData?.orders) &&
							isEmpty(engagementTableData)
						}
						placeholder="Download"
						options={downloadOption}
						style={{ width: 140 }}
						value={csvDownloadStatus}
						onSelect={handleDownload}
						loading={csvDownloadLoading}
					/>
				</Col>
			</Row>
			{isEmpty(postSalesReportData?.orders) ? (
				<Row className="top-margin" justify="center">
					<Col>
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<span>
									To generate a report, click on the Apply Button above
								</span>
							}
						/>
					</Col>
				</Row>
			) : (
				<Row className="top-margin" justify="space-between">
					<Col>
						<Space>
							<Col>
								<AggregateSummary
									loading={loading}
									title="Total Orders"
									subText="Total number of orders within the date range"
									value={
										postSalesReportData?.orders &&
										postSalesReportData?.orders.length
									}
								/>
							</Col>
							<Col>
								<AggregateSummary
									loading={loading}
									title="Total New Orders"
									subText="Total new orders within the date range"
									value={totalNewOrders && totalNewOrders.length}
								/>
							</Col>
							<Col>
								<AggregateSummary
									loading={loading}
									title="Total Repeat Orders"
									subText="Total new orders within the date range"
									value={totalRepeatOrders && totalRepeatOrders.length}
								/>
							</Col>
							<Col>
								<AggregateSummary
									loading={loading}
									title=""
									subText="Total Revenue from orders within date range, considering repeat orders"
									value={totalRevenue && totalRevenue.toFixed(2)}
								/>
							</Col>
						</Space>
					</Col>
				</Row>
			)}
			{isEmpty(postSalesReportData?.orders) ? (
				<Row className="top-margin" justify="center">
					<Col>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Col>
				</Row>
			) : (
				<>
					<Row className="top-margin" justify="space-between">
						<Col span={12}>
							<Title level={3}>Order Link Report</Title>

							<Table
								columns={OrderDetailsColumn}
								dataSource={assignedOrderLinkTableData}
								loading={loading}
								scroll={{ y: 240 }}
								pagination={false}
							/>
						</Col>
						<Col span={12}>
							<Title level={3}>Total number of Escalations</Title>

							<Table
								columns={assignedEscalationColumn}
								dataSource={assignedEscalationTableData}
								loading={loading}
								scroll={{ y: 240 }}
								pagination={false}
							/>
						</Col>
					</Row>
					<Row className="top-margin" justify="space-between">
						<Col span={24}>
							<Title level={3}>Post Sales Assignment</Title>
							<small>Revenue numbers are for repeat orders only</small>
							<Table
								columns={assignedMedicalRepColumns}
								dataSource={assignedSalesTableData}
								loading={loading}
								scroll={{ y: 240 }}
								pagination={false}
							/>
						</Col>
					</Row>
				</>
			)}
			{isEmpty(engagementCallData) ? (
				<Row className="top-margin" justify="center">
					<Col>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Col>
				</Row>
			) : (
				<Row className="top-margin">
					<Col span="24">
						<Title level={3}>Post Sales Call Schedule</Title>
						<small>This is total data independent of date range.</small>
						<Table
							columns={engagementTableColums}
							dataSource={engagementTableData}
							loading={loading}
							pagination={false}
							scroll={{ y: 320 }}
						/>
					</Col>
				</Row>
			)}
			<Modal
				visible={modalVisible}
				title="Assigned Cases Details"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				width={720}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,
				]}>
				<Row>
					<Col span="24">
						<Table
							columns={assignedCasesColumn}
							dataSource={assignedCasesTableData}
							loading={loading}
							pagination={{
								current: pageNo,
								showSizeChanger: false,
							}}
							onChange={e => setPageNo(e.current)}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
}

const downloadOption = [
	{ label: "Post Sales Report", value: "post_sales_report" },
];

const downloadCSV = async (status, data, csvName) => {
	try {
		let opts = { includeEmptyRows: true };
		const parser = new Parser(opts);
		const csvString = parser.parse(data);
		console.log(csvString);
		// let csvString = '';
		const url = window.URL.createObjectURL(new Blob([csvString]));
		let anchorTag = document.createElement("a");
		anchorTag.href = url;
		anchorTag.target = "_Blank";
		anchorTag.download = csvName;
		document.body.appendChild(anchorTag);
		anchorTag.click();
		return Promise.resolve("success");
	} catch (error) {
		console.log(error);
		message.error("download error");
	}
};
