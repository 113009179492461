import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Ant Design
import { Table, Typography } from 'antd/es';
import { useCallback } from 'react';
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
} from '../../../helpers';
import { getWarehouseSkus } from '../services';
import CreateSkus from './CreateSkus';
const { Title } = Typography;

const Skus = () => {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState([]);
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams('pageNumber')) || 1
	);

	useEffect(() => {
		let filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		if (isEmpty(queryParams)) return;

		history.push({ search: queryParams });
	}, [history, queryParams]);

	const _getSkus = useCallback(async () => {
		setLoading(true);
		const res = await getWarehouseSkus();
		setLoading(false);
		setData(res);
	}, []);

	useEffect(() => {
		_getSkus();
	}, [_getSkus]);

	const updateUI = () => {
		_getSkus();
	};

	const column = [
		{
			title: 'Warehouse',
			dataIndex: 'warehouseName',
			key: 'warehouseName',
		},
		{
			title: 'Sku',
			dataIndex: 'skuName',
			key: 'skuName',
		},
		{
			title: 'Available',
			dataIndex: 'available',
			key: 'available',
		},
		{
			title: 'On hold',
			dataIndex: 'onHold',
			key: 'onHold',
		},
		{
			title: 'Buffer',
			dataIndex: 'buffer',
			key: 'buffer',
		},
		{
			title: 'Reject',
			dataIndex: 'reject',
			key: 'reject',
		},
		{
			title: 'RTO',
			dataIndex: 'rto',
			key: 'rto',
		},
	];

	const handleChange = pagination => {
		setPageNo(pagination.current);
	};

	return (
		<>
			<CreateSkus updateUI={updateUI} />
			<div className="flex justify-between">
				<Title level={2}>Warehouse Skus</Title>
			</div>
			<div className="w-full">
				<Table
					columns={column}
					dataSource={data}
					loading={loading}
					onChange={(page, filters, sorter) =>
						handleChange(page, filters, sorter)
					}
					pagination={{
						current: pageNo,
						showSizeChanger: false,
						total: data?.length || 0,
					}}
				/>
			</div>
		</>
	);
};

export default Skus;
