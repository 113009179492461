import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getGlobalSearchResult } from "../../../common/globalSearch/service";
import { getValue } from "../../../helpers";
import debounce from "lodash/debounce";

// Antd
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Card, message, Space, Typography } from "antd/es";
const { Option } = AutoComplete;
const { Meta } = Card;
const { Text } = Typography;

function GlobalSearch({ setCaseInfo, searchText, setSearchText, setEdit }) {
	const { searchResult } = useSelector(state => ({
		searchResult: getValue(state, "globalSearch.globalSearchData") || "",
	}));

	const data = useMemo(() => {
		return Array.isArray(searchResult) ? searchResult : [];
	}, [searchResult]);

	const handleSearch = useCallback(
		debounce(async text => {
			getGlobalSearchResult(text);
		}, 500),
		[]
	);

	const handleSearchText = text => {
		setEdit(true);
		setSearchText(text);
		if (searchText.startsWith("#")) {
			handleSearch(text);
			return;
		}

		if (searchText.length > 0) {
			message.warn("only order Id allowed");
		}
		handleSearch("");
	};

	const handleSelect = data => {
		setSearchText(data.order_display_id);
		setCaseInfo(data);
	};

	return (
		<AutoComplete
			defaultActiveFirstOption={false}
			filterOption={false}
			maxLength={80}
			notFoundContent={null}
			onSearch={handleSearchText}
			placeholder="order id"
			showSearch
			style={{ width: 320 }}
			suffixIcon={<SearchOutlined />}
			value={searchText}>
			{Array.isArray(data) &&
				data.map(item => (
					<Option key={genrateKey()}>
						<Card onClick={() => handleSelect(item)}>
							<Meta
								title={`${item.first_name}`}
								description={
									<Space direction="vertical">
										{item.order_display_id && (
											<Text>{item.order_display_id}</Text>
										)}

										<div className="flex flex-col gap-1">
											<Text>{item.phone_number}</Text>
											<Text>{item.email}</Text>
										</div>

										{hasAltContact(item) && (
											<div className="flex flex-col gap-1">
												<Text type="secondary">{item.alternate_phone}</Text>
												<Text type="secondary">{item.alternate_email}</Text>
											</div>
										)}
									</Space>
								}
							/>
						</Card>
					</Option>
				))}
		</AutoComplete>
	);
}
export default GlobalSearch;

const genrateKey = () => Math.floor(Math.random() * 1000000000);

const hasAltContact = ({ alternate_phone, alternate_email }) => {
	return alternate_phone || alternate_email;
};
