import React, { useEffect, useState } from "react";
import { Button, message, Form, Space, Select } from "antd";
import { isEmpty } from "lodash";
import { getLast4Month } from "../helpers";
const { Option } = Select;

const OrderDataFilter = ({
  apicall,
  setPageNo,
  setData,
  setReOrderDetails,
  setCount,
  count,
}) => {
  const [form] = Form.useForm();
  const [last4Month] = useState(getLast4Month());
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const countData = [];

  for (let i = 1; i <= 20; i++) {
    countData.push(i);
  }

  const handleClear = () => {
    form.resetFields();
    setPageNo(1);
    setData("");
    setReOrderDetails("");
    setCount("");
    apicall();
  };

  useEffect(() => {
    form.setFieldsValue({
      month: selectedMonth ? selectedMonth : last4Month[0].monthName,
    });
  }, [selectedMonth]);

  const onSubmit = async items => {
    if (!isEmpty(items.month)) {
      const assignment_id = localStorage.getItem("users");
      const currentMonthDetails = last4Month.find(date => {
        return date.monthName === items.month;
      });
      const data = {
        startDate: currentMonthDetails.startDate,
        endDate: currentMonthDetails.endDate,
        medical_rep: assignment_id,
      };
      await apicall(data);
      setPageNo(1);
    } else message.error("please select month");
  };

  return (
    <Form form={form} layout="inline" onFinish={onSubmit}>
      <>
        <Form.Item name="month">
          <Select
            dropdownStyle={{ minWidth: "6rem" }}
            allowClear
            placeholder="Month"
            onChange={month => setSelectedMonth(month)}
            value={selectedMonth}
          >
            {last4Month &&
              last4Month.map(month => (
                <Option value={month.monthName} key={month.monthName}>
                  {month.monthName}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="count">
          <Select
            dropdownStyle={{ minWidth: "4rem" }}
            allowClear
            placeholder="Count"
            onChange={currentCount => setCount(currentCount)}
            value={count}
          >
            {countData.map(count => (
              <Option value={count} key={count}>
                {count}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Generate Report
          </Button>
          <Button htmlType="button" onClick={handleClear}>
            Clear
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default OrderDataFilter;
