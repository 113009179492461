import React from "react";
import xlsx from "json-as-xlsx";
import { isEmpty } from "lodash";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const ExcelDownload = ({ excelData }) => {
	const exportExcel = () => {
		const data = [
			{
				sheet: "Tickets",
				columns: [
					{ label: "Ticket Id", value: "key" },
					{ label: "Customer Name", value: "customer.first_name" },
					{ label: "Customer Email", value: "customer.email" },
					{ label: "Order Id",  value: "source_meta.order_display_id" },
					{ label: "Status", value: "status" },
					{ label: "Category", value: "category" },
					{ label: "Sub Category", value: "sub_category" },
					{ label: "Priority", value: "priority" },
					{ label: "Created By", value: "author.first_name" },
					{ label: "Assignee", value: "current_assignment.first_name" },
					{ label: "First Comment", value: "provider_meta.description" },
					{ label: "Comment", value: "latestComment" },
					{ label: "Created Date", value: "created_at" },
					{ label: "Last Updated", value: "updated_at" },
				],
				content: excelData,
			},
		];

		let settings = {
			fileName: "Tickets",
			extraLength: 3,
			writeMode: "writeFile",
			writeOptions: {},
		};

		xlsx(data, settings);
	};

	return (
		<Button
			type="primary"
			icon={<DownloadOutlined />}
			onClick={exportExcel}
			disabled={isEmpty(excelData)}>
			Download
		</Button>
	);
};

export default ExcelDownload;
