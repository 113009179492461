import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { getUntouchedLeads } from "../service";
import { getQueryParams, getSearchParams, getValue } from "../../../helpers";
import generateQueryParams from "../../../helpers/generateQueryPrams";
import queryParamsForApi from "../../../helpers/queryParamsForApi";
import { statusTagColor } from "../../../helpers/tagColor";

import { Col, Row, Space, Table, Tag, Typography } from "antd";
const { Title } = Typography;

const renameQueryForAPI = {
	ut_formStatus: "formStatus",
	ut_pageNumber: "pageNumber",
};

const UntouchedLeads = () => {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("ut_pageNumber") || 1)
	);

	useEffect(() => {
		const filterItems = { ut_pageNumber: pageNo };
		setQueryParams(generateQueryParams(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });

		const paramsForAPI = queryParamsForApi(renameQueryForAPI);
		getUntouchedLeads(paramsForAPI);
	}, [history, queryParams]);

	const { loading, untouchedLeads } = useSelector(state => ({
		loading: getValue(state, "leadmanagement.untouchedLeadsLoading"),
		untouchedLeads: getValue(state, "leadmanagement.untouchedLeads"),
	}));

	useEffect(() => {
		if (isEmpty(untouchedLeads)) {
			setData([]);
			return;
		}

		setData(() =>
			untouchedLeads.data.map(row => ({
				key: getValue(row, "id"),
				name: getValue(row, "user.first_name"),
				status: getValue(row, "status"),
				slot_time: getValue(row, "slotBookings[0].slot.slot_time"),
				lastFormFillDate: getValue(row, "last_form_fill_date"),
			}))
		);
		setPaginationInfo(untouchedLeads.pagination);
	}, [untouchedLeads]);

	const columns = [
		{
			title: "Name",
			key: "name",
			render: ({ key, name, status }) => (
				<Space direction="vertical">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>

					<Space>
						{status && (
							<Tag color={statusTagColor[status]} key={key}>
								{status.toUpperCase()}
							</Tag>
						)}
					</Space>
				</Space>
			),
		},
		{
			title: "Last form fill date",
			dataIndex: "lastFormFillDate",
			key: "lastFormFillDate",
			render: lastFormFillDate =>
				isEmpty(lastFormFillDate)
					? "-"
					: moment(lastFormFillDate).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Slot Booking",
			dataIndex: "slot_time",
			key: "slot_time",
			render: slot_time =>
				isEmpty(slot_time)
					? "-"
					: moment(slot_time).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<>
			<Row justify="space-between">
				<Col>
					<Title level={4}>Untouched Leads</Title>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						bordered
						columns={columns}
						dataSource={data}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default UntouchedLeads;
