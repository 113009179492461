import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import trayaLogo from "../../../assets/traya@2x.png";
import { login } from "../service";
import LoginWithGoogle from "./LoginWithGoogle";

function Login() {
	const history = useHistory();
	const { loading } = useSelector(state => ({
		loading: window.getValue(state, "login.loading"),
	}));

	useEffect(() => {
		if (window.location.search) {
			history.push({
				search: null,
			});
		}
	}, [history]);

	const onFinish = usersDetails => {
		login(usersDetails);
	};

	const onFinishFailed = () => {
		message.error("Failed to login");
	};

	return (
		<>
			<Row align="middle" justify="center" style={{ minHeight: "90vh" }}>
				<Col span={6} className="form-login-container">
					<div className="ant-row ant-form-item">
						<Row align="middle" justify="center" className="logo-container">
							<img
								src={trayaLogo}
								width="60"
								alt="Logo"
								style={{ justifyContent: "center" }}
							/>
						</Row>
					</div>

					<Form
						name="basic"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						initialValues={{
							remember: true,
						}}>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: "Please enter your email address",
								},
								{
									type: "email",
									message: "Please enter valid email address",
								},
							]}>
							<Input placeholder="Email" />
						</Form.Item>

						<Form.Item
							name="password"
							rules={[
								{
									required: true,
									message: "Please enter your password",
								},
							]}>
							<Input.Password placeholder="Password" />
						</Form.Item>

						<Form.Item>
							<Button loading={loading} type="primary" htmlType="submit">
								Login
							</Button>
							<Button
								type="link"
								onClick={() => {
									history.push(`/forgot-password`);
								}}>
								Forgot Password
							</Button>
						</Form.Item>

						<Divider>OR</Divider>

						<Col span={24}>
							<Row justify="center">
								<Form.Item>
									<LoginWithGoogle />
								</Form.Item>
							</Row>
						</Col>
					</Form>
				</Col>
			</Row>
		</>
	);
}

export default Login;
