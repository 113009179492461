import { DeleteOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	Empty,
	Image,
	message,
	Popconfirm,
	Row,
	Typography,
} from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader } from "../../../common";
import { getCurrentUserRole, handleError } from "../../../helpers";
import { ConsultCall } from "../components";
import { deleteCaseImage, viewDetails } from "../service";
const { Text } = Typography;

const BasicInfo = () => {
	const params = useParams();
	const [userRole] = useState(() => getCurrentUserRole());

	const { usersDetail, loading } = useSelector(state => ({
		usersDetail: window.getValue(state, "leaddetails.usersDetail"),
		loading: window.getValue(state, "leaddetails.usersDetailLoading"),
	}));

	const sortedImages = useMemo(() => {
		if (isEmpty(usersDetail)) return [];
		return usersDetail.images?.sort((a, b) => {
			return new Date(b.created_at) - new Date(a.created_at);
		});
	}, [usersDetail]);

	const deleteImage = async imageId => {
		const response = await deleteCaseImage(imageId);
		const caseId = params.id;
		if (response.status === 200) {
			message.success("Image deleted successfully");
			viewDetails(caseId);
		} else {
			message.error("Failed to delete image");
			handleError(response);
		}
	};

	if (loading) return <Loader />;

	return (
		<>
			<div
				style={{
					position: "sticky",
					top: 0,
					left: 0,
					zIndex: 10,
					backgroundColor: "#FFF",
					width: "100%",
				}}>
				{showConsultCall.has(userRole) && <ConsultCall />}
				{/* {showLeadStatus.has(userRole) && (
					<LeadStatus
						feedback={usersDetail?.feedback}
						status={usersDetail?.status}
					/>
				)} */}
			</div>

			<Row style={{ padding: 24 }}>
				<Col span={24} className="lead-image">
					{isEmpty(sortedImages) ? (
						<Row justify="center">
							<Col>
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</Col>
						</Row>
					) : (
						<Row
							gutter={[8, 8]}
							style={{ display: "flex", justifyContent: "center", zIndex: 1 }}>
							<Image.PreviewGroup>
								{sortedImages.map(res => (
									<Col key={res.id}>
										<Col style={{ padding: "0px" }}>
											<Popconfirm
												title="Are you sure you want to delete ?"
												placement="topLeft"
												onConfirm={() => deleteImage(res.id)}>
												{canDeleteImage.has(userRole) && (
													<Button
														icon={<DeleteOutlined />}
														type="link"
														danger
													/>
												)}
											</Popconfirm>
										</Col>

										<Col style={{ maxHeight: 148, maxWidth: 148 }}>
											<Image height={120} src={res.image} width={120} />
										</Col>

										<Col>
											<Text style={{ textAlign: "center", fontSize: 12 }}>
												{moment(res.created_at).format("DD MMM YYYY, h:mm a")}
											</Text>
										</Col>
									</Col>
								))}
							</Image.PreviewGroup>
						</Row>
					)}
				</Col>
			</Row>
		</>
	);
};

export default BasicInfo;

const canDeleteImage = new Set(["admin"]);
const showConsultCall = new Set([
	"consult_rep",
	"admin",
	"medical_rep",
	"sales",
	"support",
	"consult_doctor",
]);
// const showLeadStatus = new Set(["sales"]);
