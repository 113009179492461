import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { MarkDoneModal, SetReminders } from "../../common";
import { getValue } from "../../helpers";
import {
	getComments,
	getReminders,
	viewDetails,
} from "../../pages/leadDetails/service";

// Components
import { RescheduleEngagement } from "../../components";
import Call from "../../components/Call";
import MarkDoneEngagement from "../../components/markDone/MarkDoneEngagement";

// Antd
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Tag, Tooltip, Typography } from "antd";
import { getEngagementsByCase } from "../../pages/orderManagement/service";
const { Title, Text } = Typography;

const MedrepDetailPageBookings = () => {
	const { id: case_id } = useParams();
	const [comment, setComment] = useState("");
	const [currDueDate, setCurrDueDate] = useState("");
	const [currEngagement, setCurrEngagement] = useState({});
	const [currReminderDate, setCurrReminderDate] = useState("");
	const [currReminderId, setCurrReminderId] = useState("");
	const [currTag, setCurrTag] = useState("");
	const [markDoneModal, setMarkDoneModal] = useState(false);
	const [rescheduleModal, setRescheduleModal] = useState(false);
	const [userMarkDoneModak, setUserMarkDoneModak] = useState(false);
	const [userReminderModal, setUserReminderModal] = useState(false);

	useEffect(() => {
		getReminders(case_id);
	}, [case_id]);

	const { loadingeng, loadingRem, engagements, reminders } = useSelector(
		state => ({
			loadingEng: getValue(state, "orderManagement.engagementsByCaseLoading"),
			engagements: getValue(state, "orderManagement.engagementsByCase"),
			loadingRem: getValue(state, "leaddetails.remindersLoading"),
			reminders: getValue(state, "leaddetails.reminders"),
		})
	);

	const engagementsData = useMemo(() => {
		if (!Array.isArray(engagements)) return [];
		if (engagements.length <= 0) return [];

		return engagements.map((row, index) => ({
			key: getValue(row, "id"),
			item1: index === 0,
			item2: index === 1,
			item3: index === 2,
			case_id: getValue(row, "order.case_id"),
			due_date: getValue(row, "due_date"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag"),
			phone_number: getValue(row, "order.case.user.phone_number"),
			order_display_id: getValue(row, "order.order_display_id"),
			batch: getValue(row, "batch"),
			booking_type: "engagement",
			userId: getValue(row, "user_id"),
			temporaryUserId: getValue(row, "temporary_user_id"),
			refrence_slot_id: getValue(row, "refrence_slot_id"),
		}));
	}, [engagements]);

	const remindersDate = useMemo(() => {
		if (!Array.isArray(reminders)) return [];
		if (reminders.length <= 0) return [];

		const _reminder = reminders.filter(reminder => {
			return !reminder.refrence_engagement_id;
		});

		return _reminder.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			comment: getValue(row, "comment"),
			due_date: getValue(row, "due_date"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag") || "user",
			phone_number: getValue(row, "case.user.phone_number"),
			order_display_id: getValue(row, "case.order_display_id"),
			batch: getValue(row, "batch"),
			booking_type: "reminder",
			userId: getValue(row, "user_id"),
			temporaryUserId: getValue(row, "temporary_assignment_id"),
		}));
	}, [reminders]);

	const combinedData = useMemo(() => {
		if (isEmpty(engagementsData) && isEmpty(remindersDate)) return [];
		const _data = [...engagementsData, ...remindersDate];

		return _data.sort((a, b) => {
			return new Date(a.reminder_date) - new Date(b.reminder_date);
		});
	}, [engagementsData, remindersDate]);

	const isValidEngagement = ({ userId, temporaryUserId }) => {
		const _userId = localStorage.getItem("users");
		if (userId === _userId) return true;
		if (!temporaryUserId) return false;
		return temporaryUserId === _userId;
	};

	const refreshUI = () => {
		viewDetails(case_id);
		getReminders(case_id);
		getComments(case_id);
	};

	const refreshEngagementUI = () => {
		getEngagementsByCase(case_id);
	};

	const column = [
		{
			title: "Due Date",
			dataIndex: "reminder_date",
			key: "reminder_date",
			defaultSortOrder: false,
			sorter: (a, b) => moment(a.reminder_date) - moment(b.reminder_date),
			render: date => (
				<Text style={{ fontSize: "12px" }}>
					{moment(date).format("DD MMM YYYY, h:mm a")}
				</Text>
			),
		},
		{
			title: "Tag",
			key: "tag",
			defaultSortOrder: false,
			sorter: (a, b) => a.tag.localeCompare(b.tag),
			render: ({ key, batch, tag, refrence_slot_id }) => (
				<Row key={key} gutter={[4, 4]}>
					{typeof tag === "string" ? (
						<Tag color="geekblue">
							<Row>{tag.replaceAll("_", " ").toUpperCase()}</Row>
							<Row> {batch && `(Batch ${batch})`}</Row>
						</Tag>
					) : (
						"-"
					)}
					{refrence_slot_id ? <Tag color="geekblue">SLOT BOOKING</Tag> : null}
				</Row>
			),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 96,
			render: status => {
				if (isEmpty(status)) return "-";
				return (
					<Tag key={status} color="cyan">
						{status && status.toUpperCase()}
					</Tag>
				);
			},
		},
		{
			title: "Actions",
			key: "actions",
			width: 130,
			render: record => {
				if (record.booking_type === "engagement") {
					return (
						<>
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									disabled={
										moment(record.reminder_date).isAfter(moment(), "day") ||
										!isValidEngagement(record)
									}
									icon={<CheckOutlined />}
									onClick={() => {
										setCurrReminderId(record.key);
										setCurrEngagement(record);
										setCurrTag(record.tag);
										setMarkDoneModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Reschedule">
								<Button
									disabled={
										moment(record.reminder_date).isBefore(moment(), "day") ||
										(!record.item1 && !record.item2 && !record.item3) ||
										!isValidEngagement(record)
									}
									icon={<EditOutlined />}
									onClick={() => {
										setCurrDueDate(record.due_date);
										setCurrEngagement(record);
										setCurrReminderDate(moment(record.reminder_date));
										setCurrReminderId(record.key);
										setRescheduleModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: record.phone_number,
												case_id: record.case_id,
												note: {
													tag: record.tag,
													order_display_id: record.order_display_id,
												},
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				} else if (record.booking_type === "reminder") {
					return (
						<>
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setUserMarkDoneModak(true);
										setCurrReminderId(record.key);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Reschedule">
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setComment(record.comment);
										setCurrReminderDate(moment(record.reminder_date));
										setCurrReminderId(record.key);
										setUserReminderModal(true);
									}}
									type="link"></Button>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: record.phone_number,
												case_id: record.case_id,
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				}
			},
		},
	];

	return (
		<>
			<Row>
				<Title className="ml-4 mb-2 mt-4" level={4}>
					Bookings
				</Title>

				<Col span={24}>
					<Table
						bordered
						columns={column}
						dataSource={combinedData}
						loading={loadingeng || loadingRem}
						pagination={false}
						scroll={{ y: 272 }}
						rowClassName={({ temporaryUserId }) =>
							temporaryUserId === localStorage.getItem("users") &&
							"bg-success-100"
						}
					/>
				</Col>
			</Row>

			<RescheduleEngagement
				dueDate={currDueDate}
				engagement={currEngagement}
				isVisible={rescheduleModal}
				reminderDate={currReminderDate}
				reminderId={currReminderId}
				setIsVisible={setRescheduleModal}
			/>

			<MarkDoneEngagement
				caseId={case_id}
				engagement={currEngagement}
				modalVisible={markDoneModal}
				refreshUI={refreshEngagementUI}
				reminderId={currReminderId}
				setModalVisible={setMarkDoneModal}
				tag={currTag}
			/>

			<SetReminders
				currComment={comment}
				currDate={currReminderDate}
				modalVisible={userReminderModal}
				reminder_id={currReminderId}
				reminderType="user"
				status="active"
				refreshUI={refreshUI}
				setModalVisible={setUserReminderModal}
				modalType="user"
			/>

			<MarkDoneModal
				modalVisible={userMarkDoneModak}
				reminderId={currReminderId}
				reminderType="user"
				refreshUI={refreshUI}
				setModalVisible={setUserMarkDoneModak}
			/>
		</>
	);
};

export default MedrepDetailPageBookings;
