import React, { useEffect, useState } from "react";
import moment from "moment";

export default function Print({ data, setIsPrint, setPrescriptionDataPrint }) {
	useEffect(() => {
		let printarray = [];
		const printContents = document.getElementById("printContent").innerHTML;
		data.map((item, index) => {
			const printdata = document.getElementById(`ddtt-${index}`).innerHTML;
			console.log("ptint", item.customer_name, index, printdata);
			printarray.push(printdata);
		});
		console.log("array", printarray);
		if (setPrescriptionDataPrint && printarray.length > 0) {
			setPrescriptionDataPrint(printarray);
		} else {
			const newWin = window.open();
			newWin.document.write(printContents);
			newWin.print();
			newWin.close();
		}
		if (setIsPrint) setIsPrint(false);
	}, [data]);

	return (
		<div id="printContent">
			{data.map((data, index) => {
				const prescription = data.prescription;
				let prescriptionData = [];
				let printData = [];
				let recommendedPrescriptionData = [];

				prescription.map(prescription => {
					if (prescription.version === 1) {
						const prescriptionList = prescription.prescription;
						prescriptionData.push(prescriptionList);
					} else {
						const prescriptionList = JSON.parse(
							prescription.prescription
						).prescription;
						prescriptionList.map(prescriptionList => {
							if (prescriptionList.showRecommendation === false) {
								printData.push(prescriptionList);
							} else {
								recommendedPrescriptionData.push(prescriptionList);
							}
						});
						return (prescriptionData = [
							...printData,
							...recommendedPrescriptionData,
						]);
					}
				});

				let countTotal = 0;
				let notBreak = true;
				const prescriptionLength = prescriptionData.length;

				return (
					<>
						<div
							id={`ddtt-${index}`}
							style={{
								pageBreakAfter: "always",
							}}>
							<div>
								<div
									style={{
										fontFamily: "Nunito Sans, sans-serif",
										color: "black",
										letterSpacing: "0.3px",
										textAlign: "right",
									}}>
									<span style={{ fontSize: "10px" }}>
										{data.order_display_id}
									</span>
									<hr
										style={{
											textAlign: "right",
											width: "200px",
											marginRight: 0,
										}}
									/>
								</div>
								<div
									style={{
										fontFamily: "Nunito Sans, sans-serif",
										color: "black",
										letterSpacing: "0.3px",
										textAlign: "right",
									}}>
									<span style={{ fontSize: "10px" }}>{data.customer_name}</span>
									<hr
										style={{
											textAlign: "right",
											width: "200px",
											marginRight: 0,
										}}
									/>
								</div>
								<div
									style={{
										marginBottom: "20px",
										fontFamily: "Nunito Sans, sans-serif",
										color: "black",
										letterSpacing: "0.3px",
										textAlign: "right",
									}}>
									<span style={{ fontSize: "10px" }}>
										{moment(data.created_at).format("DD-MMM-YYYY")}
									</span>
									<hr
										style={{
											textAlign: "right",
											width: "200px",
											marginRight: 0,
										}}
									/>
								</div>
							</div>
							{prescriptionData.map((data, i) => {
								const quantity = data.quantity;

								countTotal++;
								let breakPage = false;

								if (countTotal > 6) {
									breakPage = true;
									countTotal = 0;
								}

								let isRecommended = "";

								if (data.showRecommendation === true) {
									isRecommended = "RECOMMENDED BY DOCTOR";
								}

								if (typeof data !== "string") {
									let isComposition = false;
									if (data.is_composition === true) {
										isComposition = true;
									}

									if (prescriptionLength === i + 1) {
										if (prescriptionLength % 7 === 0) {
											notBreak = false;
										}
									}

									return (
										<>
											<div>
												{isComposition ? (
													<>
														<p
															style={{
																fontSize: "10px",
																fontFamily: "Nunito Sans, sans-serif",
																letterSpacing: "0.3px",
																fontWeight: "bold",
																color: "#58595b",
																margin: 0,
																display: "flex",
															}}>
															<span style={{ flex: "1", marginRight: "8px" }}>
																{data.Medicine}{" "}
																<span
																	style={{
																		color: "black",
																		letterSpacing: "0.3px",
																		fontSize: "8px",
																		fontWeight: "0px",
																	}}>
																	X {data.composition}{" "}
																	{!isRecommended && (
																		<> x {quantity ? quantity : 1}</>
																	)}
																</span>
															</span>
															<span
																style={{
																	flex: "1",
																	marginLeft: "8px",
																	textAlign: "right",
																	float: "right",
																}}>
																<span>{data.Dosage}</span>{" "}
																{isRecommended && (
																	<span style={{ float: "right" }}>
																		({isRecommended})
																	</span>
																)}
															</span>
														</p>
													</>
												) : (
													<p
														style={{
															fontSize: "10px",
															fontFamily: "Nunito Sans, sans-serif",
															letterSpacing: "0.3px",
															fontWeight: "bold",
															color: "#58595b",
															margin: 0,
															display: "flex",
															justifyContent: "space-between",
														}}>
														<span>
															{data.Medicine}{" "}
															<span
																style={{
																	color: "black",
																	fontSize: "8px",
																	fontWeight: "0px",
																}}>
																{!isRecommended && (
																	<> x {quantity ? quantity : 1}</>
																)}
															</span>
														</span>
														<span style={{ float: "right" }}>
															<span>{data.Dosage}</span>
															{isRecommended && (
																<span style={{ float: "right" }}>
																	{" "}
																	({isRecommended})
																</span>
															)}
														</span>
													</p>
												)}
												<p
													style={{
														color: "black",
														letterSpacing: "0.3px",
														fontSize: "8px",
														marginTop: "12px",
														display: "flex",
														justifyContent: "space-between",
													}}>
													<span>{data.description}</span>
													<span style={{ float: "right" }}>{data.info}</span>
												</p>
												<hr />
												{breakPage && notBreak && (
													<>
														<div
															style={{
																pageBreakBefore: "always",
																paddingTop: "80px",
															}}
														/>
													</>
												)}
											</div>
										</>
									);
								} else {
									return (
										<>
											<p style={{ color: "black", letterSpacing: "0.3px" }}>
												{data}
											</p>
											<hr style={{ border: "0.5px solid black" }} />
										</>
									);
								}
							})}
						</div>
					</>
				);
			})}
		</div>
	);
}
