import { assignedLeadsActions } from '../actions';

export const assignedleads = (state = {}, action) => {
  switch (action.type) {
    case assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_LOADING:
      return {
        ...state,
        loading: true
      };
    case assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_SUCCESS:
      return {
        ...state,
        assignedLeadsData: action.payload,
        loading: false
      };
    case assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_FAILURE:
      return {
        ...state,
        assignedLeadsData: null,
        loading: false
      };
    default:
      return state;
  }
}