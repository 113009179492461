import { Col, Row, Space, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getSubscriptionPlatform } from "../../constants/orderSubscriptionPlatforms";
import {
	generateQuery,
	getLineItemsName,
	getQueryParams,
	getSearchParams,
	getValue,
} from "../../helpers";
import { getAssignedOrders, getMyPatients } from "../orderManagement/service";
import Filters from "./Filters";
const { TabPane } = Tabs;
const { Title, Text } = Typography;

const PendingCalls = () => {
	const history = useHistory();
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [queryParams, setQueryParams] = useState(() => getSearchParams());
	const [showPendingCalls, setShowPendingCalls] = useState(() => {
		return getQueryParams("pending") || "true";
	});

	useEffect(() => {
		const filterItems = [{ pending: showPendingCalls, pageNumber: pageNo }];
		setQueryParams(() => generateQuery(filterItems));
	}, [pageNo, showPendingCalls]);

	useEffect(() => {
		if (isEmpty(queryParams)) return;

		history.push({ search: queryParams });
		const isFilter = checkIfFilter();
		isFilter ? getAssignedOrders(queryParams) : getMyPatients(queryParams);
	}, [history, queryParams]);

	const { myPatients, loading } = useSelector(state => ({
		myPatients: getValue(state, "orderManagement.myPatients"),
		loading: getValue(state, "orderManagement.myPatientsLoading"),
	}));

	const tableData = useMemo(() => {
		if (isEmpty(myPatients)) return { data: [], rowCount: 0 };

		const _data = myPatients.data.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case_id"),
			name: getValue(row, "case.user.first_name"),
			phoneno: getValue(row, "case.user.phone_number"),
			order_display_id: getValue(row, "order_display_id"),
			order_name: getLineItemsName(row.order_meta?.line_items),
			order_status: getValue(row, "status"),
			created: getValue(row, "created_at"),
			assigned: getValue(row, "case.caseAssignment[0].created_at"),
			count: getValue(row, "count"),
			deliveryDate: getValue(row, "delivery_date"),
			is_subscription: getValue(row, "is_subscription_order"),
			is_bulk_order: getValue(row, "is_bulk_order"),
			bulk_order_duration: getValue(row, "bulk_order_duration"),
			is_single_order: getValue(row, "is_single_order"),
			order_meta: getValue(row, "order_meta"),
			sub_status: getValue(row, "sub_status"),
		}));

		return { data: _data, rowCount: myPatients.pagination.rowCount };
	}, [myPatients]);

	const handleTabChange = e => {
		setPageNo(1);
		setShowPendingCalls(e === "pending_calls" ? "true" : "false");
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (name, record) => {
				let subscriptionPlatform = "";
				if (record.is_subscription) {
					subscriptionPlatform = getSubscriptionPlatform(record.order_meta);
					subscriptionPlatform = subscriptionPlatform
						? `(${subscriptionPlatform})`
						: "";
				}

				return (
					<Space direction="vertical">
						<Link className="link" to={`/lead-details/${record.case_id}`}>
							{name}
						</Link>

						<Text type="secondary">{record.phoneno}</Text>

						<span>
							{record.is_subscription && (
								<Tooltip
									placement="bottom"
									title={`Subscription Order ${subscriptionPlatform}`}>
									<Tag color="green">S</Tag>
								</Tooltip>
							)}

							{record.is_bulk_order && (
								<Tooltip
									placement="bottom"
									title={`Bulk Order (${record.bulk_order_duration} months)`}>
									<Tag color="green">B</Tag>
								</Tooltip>
							)}

							{record.is_single_order && (
								<Tooltip placement="bottom" title="Single Order">
									<Tag color="green">SO</Tag>
								</Tooltip>
							)}
						</span>
					</Space>
				);
			},
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
		},
		{
			title: "Order Name",
			dataIndex: "order_name",
			key: "order_name",
			width: 240,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				const isNew = count <= 1;
				return (
					<Tag color={isNew ? "gold" : "green"} key={key}>
						{isNew ? "NEW" : `REPEAT ORDER ${count}`}
					</Tag>
				);
			},
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			width: 112,
			render: (status = "", record) => (
				<>
					<Tag key={record.key} color="geekblue">
						{status.toUpperCase()} {""}
						{status === "delivered" &&
							!isEmpty(record.deliveryDate) &&
							moment(record.deliveryDate).format("DD-MMM-YYYY")}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "created",
			key: "created",
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned on",
			dataIndex: "assigned",
			key: "assigned",
			render: assigned => {
				if (isEmpty(assigned)) return "-";
				return moment(assigned).format("DD MMM YYYY, h:mm a");
			},
		},
	];

	return (
		<>
			<Row justify="space-between">
				<Col>
					<Title level={2}>
						{showPendingCalls === "true"
							? "Pending Calls"
							: "Pending Resolutions"}
					</Title>
				</Col>
			</Row>

			<Row justify="end">
				<Col>
					<Filters setPageNo={setPageNo} setQueryParams={setQueryParams} />
				</Col>
			</Row>

			<Row style={{ marginTop: 16 }}>
				<Col span={24}>
					<Tabs
						defaultActiveKey={
							showPendingCalls === "true"
								? "pending_calls"
								: "pending_resolutions"
						}
						size="large"
						type="card"
						onChange={handleTabChange}>
						<TabPane key="pending_calls" tab="Pending Calls">
							<TableComponent
								columns={columns}
								loading={loading}
								pageNo={pageNo}
								tableData={tableData}
								setPageNo={setPageNo}
							/>
						</TabPane>

						<TabPane key="pending_resolutions" tab="Pending Resolutions">
							<TableComponent
								columns={columns}
								loading={loading}
								pageNo={pageNo}
								tableData={tableData}
								setPageNo={setPageNo}
							/>
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</>
	);
};

export default PendingCalls;

const TableComponent = ({ columns, loading, pageNo, tableData, setPageNo }) => (
	<Table
		columns={columns}
		dataSource={tableData.data}
		loading={loading}
		pagination={{
			current: pageNo,
			showSizeChanger: false,
			total: tableData.rowCount,
		}}
		onChange={e => setPageNo(e.current)}
	/>
);

const checkIfFilter = () => {
	const query = new URLSearchParams(window.location.search);

	return (
		query.has("startDate") ||
		query.has("endDate") ||
		query.has("status") ||
		query.has("orderType") ||
		query.has("isBulk") ||
		query.has("isSubscription")
	);
};
