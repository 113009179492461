import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { getMyTeam } from "../service";
import { getValue } from "../../../helpers";
import Edit from "./Edit";

// Antd
import { Col, Row, Table, Typography } from "antd";
const { Text, Title } = Typography;

// column
const columns = [
	{
		title: "Name",
		dataIndex: "name",
		key: "name",
		render: (name, { key, phone_number }) => (
			<div className="flex flex-col">
				<Link to={`todays-engagement/${key}`}>{name}</Link>
				<Text type="secondary">{phone_number}</Text>
			</div>
		),
	},
	{
		title: "Email",
		dataIndex: "email",
		key: "email",
	},
	{
		title: "Created",
		dataIndex: "created_at",
		key: "created_at",
		render: created_at => moment(created_at).format("DD-MMM-YYYY"),
	},
	{
		title: "Actions",
		key: "created_at",
		render: record => <Edit refreshUI={getMyTeam} data={record} />,
	},
];

const MyTeam = () => {
	const { myTeam, loading } = useSelector(state => ({
		myTeam: getValue(state, "supervisorsData.myTeam"),
		loading: getValue(state, "supervisorsData.myTeamLoading"),
	}));

	// fetch my-team data
	useEffect(() => {
		getMyTeam();
	}, []);

	const data = useMemo(() => {
		if (!Array.isArray(myTeam)) return [];

		return myTeam[0].userGroup?.map(row => ({
			key: getValue(row, "user.id"),
			created_at: getValue(row, "user.created_at"),
			email: getValue(row, "user.email"),
			name: getValue(row, "user.first_name"),
			phone_number: getValue(row, "user.phone_number"),
			user_meta: getValue(row, "user.meta"),
		}));
	}, [myTeam]);

	return (
		<Row justify="center">
			<Col span={24}>
				<Title level={2}>{myTeam[0]?.name}</Title>
				<Table columns={columns} dataSource={data} loading={loading} />
			</Col>
		</Row>
	);
};

export default MyTeam;
