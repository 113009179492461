export const usersManagmentActions = {
  GET_ALL_ROLES_LOADING: 'GET_ALL_ROLES_LOADING',
  GET_ALL_ROLES_SUCCESS: 'GET_ALL_ROLES_SUCCESS',
  GET_ALL_ROLES_FAILURE: 'GET_ALL_ROLES_FAILURE',

  CREATE_NEW_USER_LOADING: 'CREATE_NEW_USER_LOADING',
  CREATE_NEW_USER_SUCCESS: 'CREATE_NEW_USER_SUCCESS',
  CREATE_NEW_USER_FAILURE: 'CREATE_NEW_USER_FAILURE',

  GET_ALL_USERS_LOADING: 'GET_ALL_USERS_LOADING',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',

  UPDATE_USER_LOADING: 'UPDATE_USER_LOADING',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  GET_INTERNAL_ACTIVITY_LOG_SUCCESS: 'GET_INTERNAL_ACTIVITY_LOG_SUCCESS',
  GET_INTERNAL_ACTIVITY_LOG_FAILURE: 'GET_INTERNAL_ACTIVITY_LOG_FAILURE',
  GET_INTERNAL_ACTIVITY_LOG_LOADING: 'GET_INTERNAL_ACTIVITY_LOG_LOADING',
}
