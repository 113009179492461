import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import AggregateSummary from "./components/AggregateSummary";
import { Parser } from "json2csv";
import { salesReport } from "./api";
import {
	totalLeadsAssigned,
	totalLeadsNotAssigned,
	totalDraftLeads,
	totalFilledLeads,
	totalFormBasedNewOrders,
	totalDirectNewOrders,
	feedbacKCount,
	totalNumberOfOrders,
	assignedSalesLeadStatus,
	totalOrganicOrders,
	totalSemiFilledLeads,
	knowlarityCallsFn,
	secToHrs,
	bookingsCountFn,
} from "./transform";
import {
	Badge,
	Button,
	Col,
	DatePicker,
	Form,
	Row,
	message,
	Empty,
	Modal,
	Space,
	Select,
	Table,
	Typography,
	Tooltip,
} from "antd/es";
import { EyeOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;

export default function SalesReportContainer() {
	const [form] = Form.useForm();
	const [salesReportData, setSalesReportData] = useState([]);
	const [salesAnalytics, setSalesAnalytics] = useState({});
	const [leadsAssigned, setLeadsAssigned] = useState("");
	const [leadsNotAssigned, setLeadsNotAssigned] = useState("");
	const [draftLeads, setDraftLeads] = useState("");
	const [filledLeads, setFilledLeads] = useState("");
	const [semiFilledLeads, setSemiFilledLeads] = useState("");
	// const [newFormFills, setNewFormFills] = useState("");
	// const [repeatFormFills, setRepeatFormFills] = useState("");
	const [formBasedNewOrders, setFormBasedNewOrders] = useState("");
	const [directNewOrders, setDirectNewOrders] = useState("");
	const [feedback, setFeedback] = useState([]);
	const [totalOrders, setTotalOrders] = useState("");
	const [assignedSalesStatus, setAssignedSalesStatus] = useState([]);
	const [nonVoidOrder, setNonVoidOrder] = useState([]);
	const [startDate, setStartDate] = useState(
		moment().subtract(1, "days").startOf("day")
	);
	const [endDate, setEndDate] = useState(
		moment().subtract(1, "days").endOf("day")
	);

	// TODO: Do not check in
	// const [startDate, setStartDate] = useState(
	// 	moment("2021-09-01").startOf("day")
	// );
	// const [endDate, setEndDate] = useState(moment("2021-09-08").endOf("day"));
	const [isApiCall, setIsApiCall] = useState(false);
	const [organicOrders, setOrganicOrders] = useState([]);
	// const [followCallData, setFollowCallData] = useState([]);
	const [followCallReport, setFollowCallReport] = useState([]);
	const [loading, setLoading] = useState(false);
	const [csvDownloadLoading, setcsvDownloadLoading] = useState(false);
	const [csvDownloadStatus, setcsvDownloadStatus] = useState(undefined);
	const [assignedCases, setAssignedCases] = useState([]);
	const [assignedCasesTableData, setAssignedCasesTableData] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [pageNo, setPageNo] = useState(1);

	useEffect(() => {
		const apiCall = async (startDate, endDate) => {
			setLoading(true);
			const result = await salesReport(
				startDate.toISOString(),
				endDate.toISOString()
			);

			if (result.status === 200) {
				setSalesReportData(result.data.salesReport);
				setSalesAnalytics({
					knowlaritydata: result.data.knowlarity_logs,
					bookings: result.data.bookings,
				});
				setFollowCallReport(result.data.remindersData);
				setNonVoidOrder(() => {
					return result.data.salesReport?.filter(item => {
						return (
							item.order_count > 0 &&
							item.order_status !== "void" &&
							parseFloat(item.order_value) !== 0.0
						);
					});
				});

				setIsApiCall(false);
			} else setSalesReportData([]);

			setLoading(false);
		};

		if (startDate && endDate && isApiCall) apiCall(startDate, endDate);
	}, [isApiCall]);

	useEffect(() => {
		console.log(`Trigger effect`, salesReportData);
		setLeadsAssigned(totalLeadsAssigned(salesReportData, startDate, endDate));
		setLeadsNotAssigned(
			totalLeadsNotAssigned(salesReportData, startDate, endDate)
		);
		setDraftLeads(totalDraftLeads(salesReportData, startDate, endDate));
		setFilledLeads(totalFilledLeads(salesReportData, startDate, endDate));
		setSemiFilledLeads(
			totalSemiFilledLeads(salesReportData, startDate, endDate)
		);
		// setNewFormFills(totalNewFormFills(salesReportData));
		// setRepeatFormFills(totalRepeatFormFills(salesReportData));
		const x = assignedSalesLeadStatus(salesReportData, startDate, endDate);
		setAssignedSalesStatus(x);
	}, [salesReportData]);

	useEffect(() => {
		if (isEmpty(nonVoidOrder)) return;
		setDirectNewOrders(totalDirectNewOrders(nonVoidOrder, startDate, endDate));
		setOrganicOrders(totalOrganicOrders(nonVoidOrder, startDate, endDate));
		setTotalOrders(totalNumberOfOrders(nonVoidOrder, startDate, endDate));
		setFormBasedNewOrders(
			totalFormBasedNewOrders(nonVoidOrder, startDate, endDate)
		);
	}, [nonVoidOrder]);

	useEffect(() => {
		if (!isEmpty(totalOrders)) setFeedback(feedbacKCount(totalOrders));
		else setFeedback([]);
	}, [totalOrders]);

	// useEffect(() => {
	// 	if (!isEmpty(followCallReport))
	// 		setFollowCallData(totalFollowCallData(followCallReport));
	// 	else setFollowCallData([]);
	// }, [followCallReport]);

	const salesAnalyticsLogs = useMemo(() => {
		if (salesAnalytics && startDate && endDate) {
			const _cl = knowlarityCallsFn(
				salesAnalytics.knowlaritydata,
				startDate,
				endDate
			);
			const _ub = bookingsCountFn(salesAnalytics.bookings);

			return {
				call_logs: _cl,
				bookings: _ub,
			};
		} else {
			return {
				call_logs: {},
				bookings: {},
			};
		}
	}, [salesAnalytics, startDate, endDate]);

	const assignedSalesTableData = useMemo(() => {
		if (isEmpty(assignedSalesStatus)) return [];

		return assignedSalesStatus.map(data => ({
			key: data.key,
			sales_rep: data.sales_rep,
			assigned: data.total_assigned,
			hot: data.hot,
			cold: data.cold,
			invalid: data.invalid,
			unanswered: data.unanswered,
			notconnected: data.notconnected,
			dropped: data.dropped,
			new_orders: data.new_orders,
			hno: data.hno,
			old_conversions: data.old_conversions,
			aov: data.aov === null ? 0 : data.aov.toFixed(2),
			assigned_cases: data.assigned_cases,
			totalOrderValue:
				data.totalOrderValue === null ? 0 : data.totalOrderValue.toFixed(2),
			no_slot_bookings: data.no_slot_bookings,
			call_logs: salesAnalyticsLogs.call_logs[data.key]?.avgCallHrs,
			bookings: salesAnalyticsLogs.bookings[data.key],
			repLevel: data.rep_level,
		}));
	}, [assignedSalesStatus, salesAnalyticsLogs]);

	const feedbackOrdersTableData = useMemo(() => {
		if (isEmpty(feedback)) return [];
		return feedback.map((data, index) => ({
			key: index,
			feedback_name: data[0],
			order_count: data[1],
		}));
	}, [feedback]);

	// useEffect(() => {
	// 	if (isEmpty(followCallData)) setFollowCallTableData([]);
	// 	else {
	// 		const ndata = followCallData.map(data => ({
	// 			sales_rep: data.sales_rep,
	// 			first_call: data.first_call,
	// 			first_call_done: data.first_call_done,
	// 			follow_up_call1: data.follow_up_call1,
	// 			follow_up_call1_done: data.follow_up_call1_done,
	// 			follow_up_call2: data.follow_up_call2,
	// 			follow_up_call2_done: data.follow_up_call2_done,
	// 			follow_up_call3: data.follow_up_call3,
	// 			follow_up_call3_done: data.follow_up_call3_done,
	// 		}));
	// 		setFollowCallTableData(ndata);
	// 	}
	// }, [followCallData]);

	useEffect(() => {
		if (isEmpty(assignedCases)) setAssignedCasesTableData([]);
		else {
			const ndata = assignedCases.map(data => ({
				customer_name: data.customer_name,
				customer_email: data.customer_email,
				case_id: data.case_id,
				order_display_id: data.order_display_id ? data.order_display_id : "-",
				order_date: data.order_date ? data.order_date : "-",
			}));
			setAssignedCasesTableData(ndata);
		}
	}, [assignedCases]);

	const feedbackOrdersColumn = [
		{
			title: "Feedback Name",
			key: "feedback_name",
			dataIndex: "feedback_name",
		},
		{
			title: "Order Count",
			dataIndex: "order_count",
			key: "order_count",
		},
	];

	const assignedSalesColumn = [
		{
			title: "Sales Rep",
			dataIndex: "sales_rep",
			key: "sales_rep",
			render: (rep, { repLevel }) => (
				<div className="flex items-center gap-1">
					<Text>{rep}</Text>
					<Badge
						count={repLevel}
						style={{ backgroundColor: "#dcfce7", color: "#166534" }}
					/>
				</div>
			),
		},
		{
			title: "Assigned Leads",
			key: "assigned_leads",
			children: [
				{
					title: "Slot Booked",
					dataIndex: "bookings",
					key: "bookings",
					render: bookings => {
						let _count = "0/0";
						if (!isEmpty(bookings)) {
							const _unfinished = bookings.filter(booking => {
								return booking.is_finished === false;
							});

							_count = `${_unfinished.length}/${bookings.length}`;
						}

						return (
							<Tooltip placement="right" title="Unfinished / Total">
								<span>{_count}</span>
							</Tooltip>
						);
					},
				},
				{
					title: "No Bookings",
					dataIndex: "no_slot_bookings",
					key: "no_bookings",
					render: bookings => bookings ?? 0,
				},
			],
		},
		{
			title: "Assigned",
			dataIndex: "assigned",
			key: "assigned",
		},
		{
			title: "HNo.",
			key: "hno",
			dataIndex: "hno",
		},
		{
			title: "Hot",
			dataIndex: "hot",
			key: "hot",
		},

		{
			title: "Untouched",
			key: "unanswered",
			dataIndex: "unanswered",
		},
		{
			title: "N/Connected",
			key: "notconnected",
			dataIndex: "notconnected",
		},
		{
			title: "New#",
			key: "new_orders",
			dataIndex: "new_orders",
		},
		{
			title: "Old#",
			key: "old_conversions",
			dataIndex: "old_conversions",
		},
		{
			title: "₹",
			key: "totalOrderValue",
			dataIndex: "totalOrderValue",
		},
		{
			title: "AOV",
			key: "aov",
			dataIndex: "aov",
		},
		{
			title: "Call Duration (Avg - hh:mm)",
			dataIndex: "call_logs",
			key: "average_call_duration",
			render: call_logs => {
				return call_logs ? secToHrs(call_logs) : "-";
			},
		},
		{
			title: "Action",
			key: "info",
			render: record => (
				<Button
					type="primary"
					icon={<EyeOutlined />}
					onClick={() => handleInfo(record.assigned_cases)}
				/>
			),
		},
	];

	const assignedCasesColumn = [
		{
			title: "Name",
			key: "customer_name",
			render: record => {
				return (
					<span
						className="link"
						onClick={() =>
							window.open(`/lead-details/${record.case_id}`, "_blank")
						}>
						{record.customer_name}
					</span>
				);
			},
		},
		{
			title: "Email",
			dataIndex: "customer_email",
			key: "customer_email",
		},
		{
			title: "Order Display Id",
			dataIndex: "order_display_id",
			key: "order_display_id",
		},
		{
			title: "Order Date",
			dataIndex: "order_date",
			key: "order_date",
		},
	];

	const handleSubmit = () => {
		if (isEmpty(startDate)) {
			message.error("please select start Date");
			return;
		}

		if (isEmpty(endDate)) {
			message.error("please select end Date");
			return;
		}

		if (startDate.isAfter(endDate, "day")) {
			message.error("Start date can not be after end date");
			return;
		}

		setIsApiCall(true);
	};

	const handleClear = () => {
		form.resetFields();
		setSalesReportData([]);
		setFollowCallReport([]);
		setIsApiCall(false);
		setStartDate(undefined);
		setEndDate(undefined);
		setcsvDownloadStatus(undefined);
	};

	const handleDownload = async e => {
		setcsvDownloadStatus(e);

		if (e === "sales_report") {
			setcsvDownloadLoading(true);
			let _data = [...salesReportData];
			_data = _data.map(item => {
				if ("slot_bookings" in item) delete item["slot_bookings"];
				if ("rep_id" in item) delete item["rep_id"];
				return item;
			});

			await downloadCSV(e, _data, "sales_report.csv");
			setcsvDownloadLoading(false);
		}
		if (e === "reminder_data") {
			setcsvDownloadLoading(true);
			await downloadCSV(e, followCallReport, "reminder_report.csv");
			setcsvDownloadLoading(false);
		}

		// if (e === "call_logs") {
		// 	setcsvDownloadLoading(true);
		// 	const _data = [];
		// 	Object.entries(salesAnalyticsLogs.call_logs).forEach(([key, value]) => {
		// 		_data.push({
		// 			rep_id: key,
		// 			average_call_duration: value?.avgCallHrs,
		// 		});
		// 	});

		// 	await downloadCSV(e, _data, "call_logs.csv");
		// 	setcsvDownloadLoading(false);
		// }
	};

	const handleInfo = assigned_cases => {
		setAssignedCases(assigned_cases);
		setPageNo(1);
		setModalVisible(true);
	};

	const handleModalClose = () => {
		setModalVisible(false);
		setAssignedCasesTableData([]);
		setAssignedCases([]);
	};

	return (
		<>
			<Row>
				<Col span="24">
					<Title level={2}>Sales Dashboard</Title>
				</Col>
			</Row>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Form form={form} layout="inline" onFinish={handleSubmit}>
						<Form.Item name="startDate">
							<DatePicker
								allowClear
								defaultValue={startDate}
								format="YYYY-MM-DD"
								onChange={e =>
									e
										? setStartDate(moment(e).startOf("day"))
										: setStartDate(undefined)
								}
								disabledDate={current =>
									current.isBefore(moment().subtract(30, "days"), "day")
								}
								placeholder="Start Date"
							/>
						</Form.Item>

						<Form.Item name="endDate">
							<DatePicker
								allowClear
								defaultValue={endDate}
								format={"YYYY-MM-DD"}
								onChange={e =>
									e ? setEndDate(moment(e).endOf("day")) : setEndDate(undefined)
								}
								placeholder="End Date"
							/>
						</Form.Item>

						<Form.Item>
							<Space>
								<Button type="primary" htmlType="submit" loading={loading}>
									Apply
								</Button>

								<Button htmlType="button" onClick={handleClear}>
									Clear
								</Button>
							</Space>
						</Form.Item>
					</Form>
				</Col>
				<Col>
					<Select
						allowClear
						disabled={isEmpty(salesReportData) && isEmpty(followCallReport)}
						placeholder="Download"
						options={downloadOption}
						style={{ width: 140 }}
						value={csvDownloadStatus}
						onSelect={handleDownload}
						loading={csvDownloadLoading}
					/>
				</Col>
			</Row>
			{isEmpty(salesReportData) ? (
				<Row className="top-margin" justify="center">
					<Col>
						<Empty
							image={Empty.PRESENTED_IMAGE_SIMPLE}
							description={
								<span>
									To generate a report, click on the Apply Button above
								</span>
							}
						/>
					</Col>
				</Row>
			) : (
				<div className="grid mt-4 gap-2 admin__sales__dashboard-grid">
					<AggregateSummary
						loading={loading}
						title="Total Assigned *"
						subText="Total users assigned between the selected dates"
						value={leadsAssigned && leadsAssigned.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Total Not Assigned"
						subText="Total users not assigned between the selected dates"
						value={leadsNotAssigned && leadsNotAssigned.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Lead Assigned Orders"
						subText="Orders where sales rep is assigned"
						value={formBasedNewOrders && formBasedNewOrders.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Orders Without Form"
						subText="Orders where there is no form data."
						value={directNewOrders && directNewOrders.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Orders Without Sales"
						subText="Orders placed directly without assigning of sales rep"
						value={organicOrders && organicOrders.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Number of New Orders"
						subText="Total New Orders within the selected date range"
						value={totalOrders && totalOrders.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Total Draft Forms"
						subText="Total Number of people who have draft forms within the selected date range"
						value={draftLeads && draftLeads.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Total Filled Forms"
						subText="Total Number of people who have filled forms within the selected date range"
						value={filledLeads && filledLeads.length}
					/>

					<AggregateSummary
						loading={loading}
						title="Total Semi Filled Forms"
						subText="Total Number of people who have semi-filled forms within the selected date range"
						value={semiFilledLeads && semiFilledLeads.length}
					/>
					{/* <Col>
						<AggregateSummary
							loading={loading}
							title="New Form Leads"
							value={newFormFills && newFormFills.length}
						/>
					</Col>
					<Col>
						<AggregateSummary
							loading={loading}
							title="Repeat Form Leads"
							value={repeatFormFills && repeatFormFills.length}
						/>
					</Col> */}
				</div>
			)}
			{isEmpty(salesReportData) ? (
				<Row className="top-margin" justify="center">
					<Col>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Col>
				</Row>
			) : (
				<div className="grid gap-4 mt-4">
					<div className="w-full">
						<Title level={3}>Sales Assignment</Title>
						<Table
							columns={assignedSalesColumn}
							dataSource={assignedSalesTableData}
							loading={loading}
							scroll={{ y: 240 }}
							pagination={false}
						/>
					</div>

					<div className="w-full">
						<Title level={3}>Feedback Orders</Title>
						<Table
							columns={feedbackOrdersColumn}
							dataSource={feedbackOrdersTableData}
							loading={loading}
							pagination={false}
							scroll={{ y: 240 }}
						/>
					</div>
				</div>
			)}
			{/* {isEmpty(salesReportData) ? (
				<Row className="top-margin" justify="center">
					<Col>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Col>
				</Row>
			) : (
				<Row className="top-margin">
					<Col span="24">
						<Title level={3}>Sales Call Schedule</Title>
						<Table
							columns={followCallColumn}
							dataSource={followCallTableData}
							loading={loading}
							pagination={false}
							scroll={{ y: 320 }}
						/>
					</Col>
				</Row>
			)} */}
			<Modal
				visible={modalVisible}
				title="Assigned Cases Details"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				width={720}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,
				]}>
				<Row>
					<Col span="24">
						<Table
							columns={assignedCasesColumn}
							dataSource={assignedCasesTableData}
							loading={loading}
							pagination={{
								current: pageNo,
								showSizeChanger: false,
							}}
							onChange={e => setPageNo(e.current)}
						/>
					</Col>
				</Row>
			</Modal>
		</>
	);
}

const downloadOption = [{ label: "Sales Report", value: "sales_report" }];
// Call logs download option
// { label: "Call Logs", value: "call_logs" },
// Call Reminder report download option
// { label: "Reminder Report", value: "reminder_data" },

const downloadCSV = async (status, data, csvName) => {
	try {
		let opts = { includeEmptyRows: true };
		const parser = new Parser(opts);
		const csvString = parser.parse(data);
		console.log(csvString);
		// let csvString = '';
		const url = window.URL.createObjectURL(new Blob([csvString]));
		let anchorTag = document.createElement("a");
		anchorTag.href = url;
		anchorTag.target = "_Blank";
		anchorTag.download = csvName;
		document.body.appendChild(anchorTag);
		anchorTag.click();
		return Promise.resolve("success");
	} catch (error) {
		console.log(error);
		message.error("download error");
	}
};
