import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React from 'react';

const CsvDownload = ({ data, extraData, type }) => {
	const exportCSV = () => {
		try {
			let csvRow = [];
			let rowName = [
				[
					encodeURIComponent('Order Id'),
					encodeURIComponent('Order Date'),
					encodeURIComponent('Pincode'),
					encodeURIComponent('Phone Number'),
					encodeURIComponent('Order Amount'),
					encodeURIComponent('Order Type'),
					encodeURIComponent('Mark Shipped'),
					encodeURIComponent('Shipping Partner'),
				],
			];

			if (type === 'b2b') {
				for (let item = 0; item < data.length; item++) {
					rowName.push([
						encodeURIComponent(data[item].order_display_id),
						encodeURIComponent(
							moment(data[item].created_at).format('DD/MM/YYYY')
						),
						encodeURIComponent(data[item].order_meta?.shipping_address?.zip),
						encodeURIComponent(data[item].phone_number),
						encodeURIComponent(data[item].order_meta?.total_price),
						encodeURIComponent(
							data[item].order_type === 'pre_paid' ? 'PREPAID' : 'COD'
						),
						encodeURIComponent(
							extraData && extraData[data[item].orderId]
								? extraData[data[item].orderId]?.processed
								: ''
						),
						encodeURIComponent(
							extraData && extraData[data[item].orderId]
								? extraData[data[item].orderId]?.shippingPartner
								: ''
						),
					]);
				}
			} else {
				for (let item = 0; item < data.length; item++) {
					rowName.push([
						encodeURIComponent(data[item].order_display_id),
						encodeURIComponent(
							moment(data[item].createdAt).format('DD/MM/YYYY')
						),
						encodeURIComponent(data[item].order_meta?.shipping_address?.zip),
						encodeURIComponent(data[item].phone_number),
						encodeURIComponent(data[item].order_meta?.total_price),
						encodeURIComponent(
							data[item].order_type === 'pre_paid' ? 'PREPAID' : 'COD'
						),
						encodeURIComponent(
							extraData && extraData[data[item].orderId]
								? extraData[data[item].orderId]?.markshipped
								: ''
						),
						encodeURIComponent(
							extraData && extraData[data[item].orderId]
								? extraData[data[item].orderId]?.shippingPartner
								: ''
						),
					]);
				}
			}

			for (let newitem = 0; newitem < rowName.length; newitem++) {
				csvRow.push(rowName[newitem].join(','));
			}

			let csvString = csvRow.join('%0A');
			let anchorTag = document.createElement('a');
			anchorTag.href = 'data:attachment/csv,' + csvString;
			anchorTag.target = '_Blank';
			anchorTag.download = `${type}-orders.csv`;
			document.body.appendChild(anchorTag);
			anchorTag.click();
		} catch (error) {
			message.error('download error');
		}
	};

	return (
		<Button
			type="primary"
			icon={<DownloadOutlined />}
			onClick={exportCSV}
			disabled={isEmpty(data)}>
			Download Orders
		</Button>
	);
};

export default CsvDownload;
