import groupBy from "lodash/groupBy";
import moment from "moment/moment";
import { getValue } from "../../../helpers";

export const mergeEngagementsFn = (data, sort = "ASC") => {
	let _data = [];

	// get values of all keys
	const values = Object.values(data);

	// combine all arrays
	for (let i = 0; i < values.length; i++) {
		const value = values[i];
		_data = _data.concat(value);
	}

	// sort array before returning
	if (sort === "DESC") {
		_data.sort((a, b) => {
			return new Date(b.reminder_date) - new Date(a.reminder_date);
		});
	} else {
		_data.sort((a, b) => {
			return new Date(a.reminder_date) - new Date(b.reminder_date);
		});
	}
	return _data;
};

export const transformEngagements = data => {
	if (!Array.isArray(data)) return [];
	if (data.length <= 0) return [];
	return data.map(row => ({
		key: getValue(row, "id"),
		batch: getValue(row, "batch"),
		booking_type: "engagement",
		case_id: getValue(row, "order.case_id"),
		data_type: "engagement",
		datetime: getValue(row, "reminder_date"),
		due_date: getValue(row, "due_date"),
		name: getValue(row, "order.case.user.first_name"),
		order_display_id: getValue(row, "order.order_display_id"),
		order_count: getValue(row, "order.count"),
		phone_number: getValue(row, "order.case.user.phone_number"),
		prev_engagement_completion_date: getValue(
			row,
			"prev_engagement_completion_date"
		),
		reminder_date: getValue(row, "reminder_date"),
		reschedule_count: getValue(row, "reschedule_count"),
		status: getValue(row, "status"),
		tag: getValue(row, "tag"),
		unanswered_count: getValue(row, "unanswered_count"),
		refrence_slot_id: getValue(row, "refrence_slot_id"),
		priority: getPriority(row),
	}));
};

export const transformReminders = data => {
	if (!Array.isArray(data)) return [];
	if (data.length <= 0) return [];

	const _data = data.filter(row => {
		return !row.refrence_engagement_id;
	});

	return _data.map(row => ({
		key: getValue(row, "id"),
		batch: getValue(row, "batch"),
		booking_type: "reminder",
		case_id: getValue(row, "case_id"),
		comment: getValue(row, "comment"),
		data_type: "reminder",
		datetime: getValue(row, "reminder_date"),
		due_date: getValue(row, "due_date"),
		name: getValue(row, "case.user.first_name"),
		order_display_id: getValue(row, "case.order_display_id"),
		phone_number: getValue(row, "case.user.phone_number"),
		reminder_date: getValue(row, "reminder_date"),
		reschedule_count: getValue(row, "reschedule_count"),
		status: getValue(row, "status"),
		tag: getValue(row, "tag") || "user",
		priority: getPriority(row),
		unanswered_count: getValue(row, "unanswered_count"),
	}));
};

export const transformTempEngagements = data => {
	if (!Array.isArray(data)) return [];
	if (data.length <= 0) return [];

	return data.map(row => ({
		key: getValue(row, "id"),
		batch: getValue(row, "batch"),
		booking_type: "engagement",
		case_id: getValue(row, "order.case_id"),
		data_type: "temporaryEngagement",
		datetime: getValue(row, "reminder_date"),
		due_date: getValue(row, "due_date"),
		name: getValue(row, "order.case.user.first_name"),
		order_display_id: getValue(row, "order.order_display_id"),
		phone_number: getValue(row, "order.case.user.phone_number"),
		prev_engagement_completion_date: getValue(
			row,
			"prev_engagement_completion_date"
		),
		reminder_date: getValue(row, "reminder_date"),
		reschedule_count: getValue(row, "reschedule_count"),
		status: getValue(row, "status"),
		tag: getValue(row, "tag"),
		unanswered_count: getValue(row, "unanswered_count"),
	}));
};

export const transformtempReminders = data => {
	if (!Array.isArray(data)) return [];
	if (data.length <= 0) return [];

	return data.map(row => ({
		key: getValue(row, "id"),
		batch: getValue(row, "batch"),
		booking_type: "reminder",
		case_id: getValue(row, "case_id"),
		comment: getValue(row, "comment"),
		data_type: "temporaryReminder",
		datetime: getValue(row, "reminder_date"),
		due_date: getValue(row, "due_date"),
		name: getValue(row, "case.user.first_name"),
		order_display_id: getValue(row, "case.order_display_id"),
		phone_number: getValue(row, "case.user.phone_number"),
		reminder_date: getValue(row, "reminder_date"),
		reschedule_count: getValue(row, "reschedule_count"),
		status: getValue(row, "status"),
		tag: getValue(row, "tag") || "user",
		unanswered_count: getValue(row, "unanswered_count"),
	}));
};

const tags = [
	{ key: "Prescription Call", value: "Prescription Call" },
	{ key: "Week #1", value: "Week 1" },
	{ key: "Week #2", value: "Week 2" },
	{ key: "Week #3", value: "Week 3" },
	{ key: "Week #4", value: "Week 4" },
	{ key: "Week #5", value: "Week 5" },
	{ key: "Week #6", value: "Week 6" },
];

const attempt = count => `attempt_${count}`;
const nextAttempt = count => attempt(count + 1);
const finishedEngagements = new Set(["auto_completed", "completed"]);

const engagementSchedules = (engagement, data) => {
	if (typeof engagement !== "string") return;

	const default_data = {
		name: engagement,
		key: `engagementSchedules_${engagement.replaceAll(" ", "_")}`,
		calls: 0,
		finished: 0,
		unfinished: 0,
		attempt_1: 0,
		attempt_2: 0,
		attempt_3: 0,
	};

	if (!Array.isArray(data)) return default_data;
	default_data["calls"] = data.length;

	return data.reduce((acc, curr) => {
		const unansweredCount = Number(curr.unanswered_count);

		if (finishedEngagements.has(curr.operation_status)) {
			const _nextAttempt = nextAttempt(unansweredCount);
			if (typeof acc[_nextAttempt] === "number") {
				acc[_nextAttempt] = acc[_nextAttempt] + 1;
			} else {
				acc[_nextAttempt] = 1;
			}

			acc["finished"] = acc["finished"] + 1;
		} else {
			const _attempt = attempt(unansweredCount);

			if (typeof acc[_attempt] === "number") acc[_attempt] = acc[_attempt] + 1;
			else acc[_attempt] = 1;

			acc["unfinished"] = acc["unfinished"] + 1;
		}

		return acc;
	}, default_data);
};

const reminderSchedules = (tag, data) => {
	const default_data = {
		name: tag,
		key: `engagementSchedules_${tag}`,
		calls: 0,
		finished: 0,
		unfinished: 0,
		attempt_1: 0,
		attempt_2: 0,
		attempt_3: 0,
	};

	if (!Array.isArray(data)) return default_data;
	default_data["calls"] = data.length;

	return data.reduce((acc, curr) => {
		const unansweredCount = Number(curr.unanswered_count);

		if (curr.status === "active") {
			const _attempt = attempt(unansweredCount);

			if (typeof acc[_attempt] === "number") acc[_attempt] = acc[_attempt] + 1;
			else acc[_attempt] = 1;

			acc["unfinished"] = acc["unfinished"] + 1;
		} else {
			const _nextAttempt = nextAttempt(unansweredCount);
			if (typeof acc[_nextAttempt] === "number") {
				acc[_nextAttempt] = acc[_nextAttempt] + 1;
			} else {
				acc[_nextAttempt] = 1;
			}

			acc["finished"] = acc["finished"] + 1;
		}

		return acc;
	}, default_data);
};

export const engagementScheduleFn = ({ engagements, reminders }) => {
	if (!Array.isArray(engagements)) engagements = [];
	if (!Array.isArray(reminders)) reminders = [];

	const _groupEngagements = groupBy(engagements, row => row.engagement_tag);
	let finalData = [];

	for (let i = 0; i < tags.length; i++) {
		const tag = tags[i];

		const _data = _groupEngagements[tag.key];
		finalData = finalData.concat([engagementSchedules(tag.value, _data)]);
	}

	finalData = finalData.concat([reminderSchedules("Slot Bookings", reminders)]);

	return finalData;
};

export const engagementSummaryFn = data => {
	const default_data = {
		percentage: 0.0,
		total: 0,
	};

	if (!Array.isArray(data)) return default_data;
	if (data.length <= 0) return default_data;

	const _finished = data.reduce((acc, curr) => {
		if (curr.is_finished) return (acc = acc + 1);
		return acc;
	}, 0);

	const total = data.length;
	const _percentage = parseFloat((_finished / total) * 100).toFixed(2);

	return {
		percentage: isNaN(_percentage) ? 0.0 : _percentage,
		total,
	};
};

export const repeatOrdersFn = orders => {
	if (!Array.isArray(orders)) return [];
	return orders.filter(order => order.status !== "void" && order.count > 1);
};

export const ordersValueFn = orders => {
	if (!Array.isArray(orders)) return 0.0;
	return orders.reduce((acc, curr) => {
		if (curr.order_meta && curr.order_meta.total_price) {
			acc = acc + Number(curr.order_meta.total_price);
		}
		return acc;
	}, 0.0);
};

export const inprogressOrdersFn = orders => {
	const default_data = {
		new: 0,
		repeat: 0,
	};

	if (!Array.isArray(orders)) return default_data;

	const inprogressOrders = orders.filter(item => item.status === "in_progress");
	return inprogressOrders.reduce((acc, curr) => {
		if (curr.count === 1) acc["new"] = acc.new + 1;
		else if (curr.count > 1) acc["repeat"] = acc.repeat + 1;
		return acc;
	}, default_data);
};

const isTodaysEngagement = data => {
	return moment(data.reminder_date).isSame(moment(), "d");
};

const isOverdueEngagement = data => {
	return moment(data.reminder_date).isBefore(moment(), "d");
};

const handleWeek1 = data => {
	const orderCount = getValue(data, "order.count");

	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);

	if (orderCount === 1 || orderCount === 2) {
		if (_isTodaysEngagement) return "Priority 1";
		else if (_isOverdueEngagement) return "Priority 2";
	}

	return "Priority 3";
};

const handleWeek2 = data => {
	const orderCount = Number(getValue(data, "order.count"));

	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);

	if (orderCount === 1 || orderCount === 2) {
		if (_isTodaysEngagement) return "Priority 1";
		else if (_isOverdueEngagement) return "Priority 2";
	}
	return "Priority 3";
};

const handleWeek3 = data => {
	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);

	if (_isTodaysEngagement) return "Priority 1";
	else if (_isOverdueEngagement) return "Priority 2";
	else return "Priority 3";
};

const handleWeek4 = data => {
	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);

	if (_isTodaysEngagement) return "Priority 1";
	else if (_isOverdueEngagement) return "Priority 2";
	else return "Priority 3";
};

const handleSlotBookingPriority = data => {
	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);

	if (_isTodaysEngagement) return "Priority 1";
	else if (_isOverdueEngagement) return "Priority 2";
	else return "Priority 3";
};

const handlePrescriptionCallPriority = data => {
	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);

	if (_isTodaysEngagement) return "Priority 1";
	else if (_isOverdueEngagement) return "Priority 1";
	else return "Priority 3";
};
const handleConcernCallPriority = data => {
	const _isTodaysEngagement = isTodaysEngagement(data);
	const _isOverdueEngagement = isOverdueEngagement(data);
	if (_isTodaysEngagement) return "Priority 1";
	else if (_isOverdueEngagement) return "Priority 1";
	else return "Priority 3";
};

const priorityMapper = {
	"Week #1": handleWeek1,
	"Week #2": handleWeek2,
	"Week #3": handleWeek3,
	"Week #4": handleWeek4,
	SLOT_BOOKING: handleSlotBookingPriority,
	"Prescription Call": handlePrescriptionCallPriority,
	"Concern Call": handleConcernCallPriority,
};

const getPriority = data => {
	const _handler = priorityMapper[data.tag];
	if (!_handler) return "Priority 3";
	return _handler(data);
};
