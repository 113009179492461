import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getValue } from "../../../../helpers";
import { getEngagementsByOrder } from "../../../orderManagement/service";

import { Tag } from "antd";

export const UserStatus = () => {
	const [status, setStatus] = useState("");
	const [color, setColor] = useState("");

	const { usersDetail, engagements } = useSelector(state => ({
		usersDetail: getValue(state, "leaddetails.usersDetail"),
		loading: getValue(state, "orderManagement.engagementsByOrderLoading"),
		engagements: getValue(state, "orderManagement.engagementsByOrderData"),
	}));

	const { latest_order_count, latest_order_date } = usersDetail;

	useEffect(() => {
		if (usersDetail) {
			const orderId = usersDetail.latest_order_id;
			if (usersDetail.latest_order_id) getEngagementsByOrder(orderId);
		}
	}, [usersDetail]);

	const _status = useCallback(() => {
		if (!engagements || engagements.length < 1) {
			setStatus("New");
			setColor("green");
			return;
		}

		const _engagementDue = engagements.map(val => {
			if (moment().isAfter(val.due_date)) {
				return 1; // means past engagement
			} else {
				return 0; // means future engagement
			}
		});

		if (latest_order_count >= 1) {
			if (_engagementDue.every(val => val === 0)) {
				setStatus("New");
				setColor("green");
				return;
			} else if (!_engagementDue.every(val => val === 1)) {
				setStatus("Active");
				setColor("cyan");
				return;
			} else if (moment().diff(moment(latest_order_date), "days") > 15) {
				setStatus("Inactive");
				setColor("red");
				return;
			}
		}
	}, [engagements, latest_order_count, latest_order_date]);

	useEffect(() => {
		_status();
		return () => {
			setStatus("");
		};
	}, [_status]);

	if (status) {
		return <Tag color={color}>{status.toUpperCase()}</Tag>;
	} else {
		return null;
	}
};
