import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { DateFilter } from "../../../components/DateFilter";
import { generateQuery, getQueryParams, getValue } from "../../../helpers";
import { getMissedCalls } from "../service";
import MissedCallsTable from "./MissedCallsTable";

import { Typography } from "antd/es";
const { Text } = Typography;

const repId = localStorage.getItem("users");
const repLevel = Number(localStorage.getItem("rep!level")) || null;

const MissedCalls = () => {
	const history = useHistory();

	const { tableData } = useSelector(state => ({
		tableData: getValue(state, "missedCalls"),
	}));

	const [startDate] = useState(() => {
		return getQueryParams("startDate")
			? moment(getQueryParams("startDate"))
			: moment();
	});

	const [endDate] = useState(() => {
		return getQueryParams("endDate")
			? moment(getQueryParams("endDate"))
			: moment();
	});

	const handleMissedCalls = useCallback(
		(sd, ed) => {
			if (!sd || !ed || !repId) return;

			const searchParams = {
				startDate: moment(sd).startOf("d").toISOString(),
				endDate: moment(ed).endOf("d").toISOString(),
			};

			const filterItems = [
				{
					rep_id: repId,
					...searchParams,
				},
			];

			const _queryParams = generateQuery(filterItems);
			history.push({ search: generateQuery([searchParams]) });

			getMissedCalls(_queryParams);
		},
		[history]
	);

	useEffect(() => {
		handleMissedCalls(startDate, endDate);
	}, [endDate, handleMissedCalls, startDate]);

	const handleDateFilter = (start, end) => {
		handleMissedCalls(start, end);
	};

	const handleStartDisableDate = (currDate, toDate) => {
		return (
			currDate.isBefore(moment().subtract(60, "d"), "d") ||
			currDate.isAfter(moment(toDate))
		);
	};

	const handleEndDisableDate = (currDate, toDate) => {
		return (
			currDate.isAfter(moment().endOf("d")) || currDate.isBefore(moment(toDate))
		);
	};

	if (repLevel && [1, 3].includes(repLevel)) {
		return (
			<div className="flex flex-col gap-4">
				<h2 className="ant-typography">IVR Calls</h2>

				<DateFilter
					startDate={startDate}
					endDate={endDate}
					handleSubmit={handleDateFilter}
					startDisableDate={handleStartDisableDate}
					endDisableDate={handleEndDisableDate}
				/>
				<MissedCallsTable tableData={tableData} />
			</div>
		);
	} else {
		return <Text type="danger">You're not allowed to access this page</Text>;
	}
};

export default MissedCalls;
