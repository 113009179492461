import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useParams } from "react-router-dom";
import { getChats, postChat } from "../../pages/leadDetails/service";
import { Button, Input, Modal, Row, Select, Space } from "antd";
const { TextArea } = Input;

const SendMessageModal = ({ isVisible, setIsVisible }) => {
	const { id: case_id } = useParams();
	const [text, setText] = useState("");
	const [sendingMessage, setSendingMessage] = useState(false);
	const [showTextBox, setShowTextBox] = useState(false);
	const [dropdownValue, setDropdownValue] = useState(null);

	const handleModalClose = () => {
		setText("");
		setIsVisible(false);
		setShowTextBox(false);
		setDropdownValue(null);
	};

	const sendOrderLink = async () => {
		setSendingMessage(true);
		const res = await postChat(case_id, text);
		setSendingMessage(false);

		if (res.status === 200) {
			getChats(case_id);
			handleModalClose();
		}
	};
	const handleSelect = e => {
		if (e === "other") {
			setShowTextBox(true);
		} else {
			setShowTextBox(false);
			setText(e);
		}
		setDropdownValue(e);
	};

	return (
		<Modal
			visible={isVisible}
			title="Send Message"
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
				<Button
					disabled={isEmpty(text.trim())}
					key="submit"
					type="primary"
					onClick={sendOrderLink}
					loading={sendingMessage}>
					Send
				</Button>,
			]}>
			<Row justify="center">
				<Select
					placeholder="Select message templates"
					value={dropdownValue}
					options={templateOptions}
					onSelect={handleSelect}
					style={{ width: 360 }}
				/>
			</Row>
			{showTextBox && (
				<Row justify="center" style={{ marginTop: 5 }}>
					<TextArea
						onChange={({ target }) => setText(target.value)}
						placeholder="Please put your message here"
						rows={4}
						value={text}
						style={{ width: 360 }}
					/>
				</Row>
			)}
		</Modal>
	);
};

export default SendMessageModal;

const templateOptions = [
	{ label: "I Missed Cx Call", value: "213" },
	{ label: "Cx Ph Number is Invalid", value: "215" },
	{ label: "Cx Not Answering", value: "222" },
	{ label: "QR code for Prepaid Payment", value: "221" },
	{ label: "Answer to Doctor Escalation", value: "214" },
	{ label: "Reminder to Place Order", value: "217" },
	{ label: "Upload Scalp Pic", value: "218" },
	{ label: "Cx has not answered for 15+ Days", value: "212" },
	{ label: "Cx has not answered since prescription call", value: "219" },
	{ label: "Delay in Delivery", value: "224" },
	{ label: "Cx Pincode not servicible", value: "220" },
	{ label: "New address Request", value: "216" },
	{ label: "Other", value: "other" },
];
