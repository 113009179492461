import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { MarkDoneModal, SetReminders } from "../../../common";
import Call from "../../../components/Call";
import {
	generateQuery,
	getCurrentUserRole,
	getQueryParams,
	getValue,
} from "../../../helpers";
import { getTodaysReminder } from "../service";

// Antd
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Table, Tabs, Tag, Tooltip } from "antd";
const { TabPane } = Tabs;

const repLevel = localStorage.getItem("rep!level");

function TodaysReminder() {
	const history = useHistory();
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("currentt2")) || 1
	);
	const [tag, setTag] = useState(getQueryParams("tag") || "FIRST_CALL");
	const [userRole] = useState(getCurrentUserRole());

	const [comment, setComment] = useState("");
	const [currReminderDate, setCurrReminderDate] = useState("");
	const [currReminderId, setCurrReminderId] = useState("");
	const [userReminderModal, setUserReminderModal] = useState(false);
	const [userMarkDoneModak, setUserMarkDoneModak] = useState(false);

	useEffect(() => {
		const filterItems = [{ currentt2: pageNo, tag }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });

		let queryParams = "";

		if (userRole === "sales") {
			if (tag === "Today_Custom_Reminders") {
				queryParams = `pageNumber=${pageNo}&type=user`;
			} else {
				queryParams = `pageNumber=${pageNo}&tag=${tag}`;
			}
		} else if (userRole === "medical_rep") {
			queryParams = `pageNumber=${pageNo}`;
		}

		getTodaysReminder(queryParams);
	}, [history, pageNo, tag, userRole]);

	const refreshUI = () => {
		getTodaysReminder(`pageNumber=${pageNo}`);
	};

	const { loading, todaysReminders } = useSelector(state => ({
		loading: getValue(state, "salesDashboard.todaysReminderLoading"),
		todaysReminders: getValue(state, "salesDashboard.todaysReminders"),
	}));

	const tableData = useMemo(() => {
		if (isEmpty(todaysReminders)) return { data: [], rowCount: 0 };

		const _data = todaysReminders.data.map(row => ({
			key: getValue(row, "id"),
			case_id: getValue(row, "case.id"),
			name: getValue(row, "case.user.first_name"),
			phoneno: getValue(row, "case.user.phone_number"),
			email: getValue(row, "case.user.email"),
			status: getValue(row, "status"),
			tag: getValue(row, "tag"),
			time: getValue(row, "reminder_date"),
		}));

		return { data: _data, rowCount: todaysReminders.pagination.rowCount };
	}, [todaysReminders]);

	const column = useMemo(() => {
		return [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (name, { case_id }) => (
					<Link className="link" to={`/lead-details/${case_id}`}>
						{name}
					</Link>
				),
			},
			{
				title: "Phone No",
				dataIndex: "phoneno",
				key: "phoneno",
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email",
			},
			{
				title: "Tag",
				dataIndex: "tag",
				key: "tag",
				render: tag => {
					if (isEmpty(tag)) return "-";
					return (
						<Tag key={tag} color="geekblue">
							{tag.replaceAll("_", " ").toUpperCase()}
						</Tag>
					);
				},
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: (status = "") => (
					<Tag key={status} color="cyan">
						{status.toUpperCase()}
					</Tag>
				),
			},
			{
				title: "Time",
				dataIndex: "time",
				key: "time",
				defaultSortOrder: false,
				render: time => moment(time).format("DD MMM YYYY, h:mm a"),
				sorter: (a, b) => moment(a.time) - moment(b.time),
			},
			{
				title: "Action",
				key: "action",
				hidden: repLevel !== "1",
				render: record => {
					return (
						<>
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setUserMarkDoneModak(true);
										setCurrReminderId(record.key);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Reschedule">
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setComment(record.comment);
										setCurrReminderDate(moment(record.reminder_date));
										setCurrReminderId(record.key);
										setUserReminderModal(true);
									}}
									type="link"></Button>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: record.phone_number,
												case_id: record.case_id,
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				},
			},
		];
	}, []);

	const tableColumns = useMemo(() => {
		return column.filter(c => !c.hidden);
	}, [column]);

	const handleTabChange = key => {
		setPageNo(1);
		setTag(key);
	};

	const createTable = item => (
		<Table
			columns={tableColumns}
			dataSource={item}
			loading={loading}
			onChange={e => setPageNo(e.current)}
			pagination={{
				current: pageNo,
				showSizeChanger: false,
				total: tableData.rowCount,
			}}
		/>
	);

	return (
		<>
			{userRole === "sales" && (
				<Tabs type="card" defaultActiveKey={tag} onChange={handleTabChange}>
					<TabPane tab="First Call" key="FIRST_CALL">
						{createTable(tableData.data)}
					</TabPane>

					<TabPane tab="Follow up call 1" key="FOLLOW_UP_CALL_1">
						{createTable(tableData.data)}
					</TabPane>

					<TabPane tab="Follow up call 2" key="FOLLOW_UP_CALL_2">
						{createTable(tableData.data)}
					</TabPane>

					<TabPane tab="Follow up call 3" key="FOLLOW_UP_CALL_3">
						{createTable(tableData.data)}
					</TabPane>

					<TabPane tab="Other Bookings" key="Today_Custom_Reminders">
						{createTable(tableData.data)}
					</TabPane>
				</Tabs>
			)}

			{userRole === "medical_rep" && createTable(tableData.data)}

			<SetReminders
				currComment={comment}
				currDate={currReminderDate}
				modalVisible={userReminderModal}
				reminder_id={currReminderId}
				reminderType="user"
				status="active"
				refreshUI={refreshUI}
				setModalVisible={setUserReminderModal}
				modalType="user"
			/>

			<MarkDoneModal
				modalVisible={userMarkDoneModak}
				reminderId={currReminderId}
				reminderType="user"
				refreshUI={refreshUI}
				setModalVisible={setUserMarkDoneModak}
			/>
		</>
	);
}

export default TodaysReminder;
