import React, { useState } from "react";
import moment from "moment";
import { DISCOUNT_CODE_API } from "../../constants/apiUrls";
import { fetchRequest } from "../../helpers/fetchRequest";

// Ant design
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Table, Typography } from "antd";
const { Text } = Typography;

const DiscountCodesTable = ({ data, loading, refreshUI }) => {
	const [deletingCode, setDeletingCode] = useState(false);

	const deleteDiscountCode = async id => {
		let res = {};
		setDeletingCode(true);

		try {
			res = await fetchRequest(DISCOUNT_CODE_API(id), { method: "DELETE" });
		} catch (error) {
			console.log(error.message);
		} finally {
			if (res.hasError) message.error("Failed to delete");
			else refreshUI();
			setDeletingCode(false);
		}
	};

	const columns = [
		{
			title: "Code",
			dataIndex: "discount_code",
			key: "discount_code",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: type => <div className="capitalize">{type}</div>,
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
		},
		{
			title: "Created At",
			dataIndex: "created_at",
			key: "created_at",
			render: created_at => moment(created_at).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Action",
			key: "id",
			render: ({ discount_code, id }) => (
				<Popconfirm
					title={
						<Text>
							Are you sure to delete <Text type="danger">{discount_code}</Text>?
						</Text>
					}
					placement="topRight"
					onConfirm={() => deleteDiscountCode(id)}>
					<Button
						danger
						icon={<DeleteOutlined />}
						loading={deletingCode}
						type="link"
					/>
				</Popconfirm>
			),
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={data}
			loading={loading}
			rowKey={row => row.id}
		/>
	);
};

export default DiscountCodesTable;
