import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Table, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteMedicine, getMedicines } from "../service";
import AddMedicine from "./AddMedicine";
const { Text, Title } = Typography;

const Medicines = () => {
	const [data, setData] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);

	useEffect(() => {
		getMedicines();
	}, []);

	const { medicines, loading } = useSelector(state => ({
		medicines: window.getValue(state, "configure.medicines"),
		loading: window.getValue(state, "configure.medicinesLoading"),
	}));
	useEffect(() => {
		if (isEmpty(medicines)) setData([]);
		else {
			const ndata = medicines.map(medicine => ({
				key: window.getValue(medicine, "id"),
				description: window.getValue(medicine, "description"),
				dosage: window.getValue(medicine, "dosage"),
				tax_rate: window.getValue(medicine, "tax_rate"),
				group: window.getValue(medicine, "group"),
				info: window.getValue(medicine, "info"),
				name: window.getValue(medicine, "display_name"),
				type: window.getValue(medicine, "type"),
			}));
			setData(ndata);
		}
	}, [medicines]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Description / Information",
			key: "description",
			width: "55%",
			render: ({ description, info }) => (
				<Space direction="vertical">
					<Space>
						<Text strong style={{ display: "block", width: "max-content" }}>
							Description :
						</Text>
						<Text>{isEmpty(description) ? "..." : description}</Text>
					</Space>
					<Space>
						<Text strong style={{ display: "block", width: "max-content" }}>
							Information :
						</Text>
						<Text>{isEmpty(info) ? "..." : info}</Text>
					</Space>
				</Space>
			),
		},
		{
			title: "Tax (%)",
			dataIndex: "tax_rate",
			key: "tax_rate",
			width: 196,
			render: tax_rate => (isEmpty(tax_rate) ? "0%" : tax_rate * 100),
		},
		{
			title: "Dosage",
			dataIndex: "dosage",
			key: "dosage",
			width: 196,
			render: dosage => (isEmpty(dosage) ? "..." : dosage),
		},
		{
			title: "Group",
			dataIndex: "group",
			key: "group",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Action",
			key: "action",
			render: record => (
				console.log("record", record),
				(
					<Popconfirm
						disabled={record?.group}
						title={
							<Text>
								Are you sure to delete <Text type="danger">{record.name}</Text>{" "}
								?
							</Text>
						}
						placement="topRight"
						onConfirm={() => deleteMedicine(record.key)}>
						<Button
							disabled={record?.group}
							icon={<DeleteOutlined />}
							type="link"
							danger
						/>
					</Popconfirm>
				)
			),
		},
	];

	return (
		<>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>Medicines</Title>
				</Col>
				<Col>
					<Button onClick={() => setIsModalVisible(true)} type="primary">
						Add New Medicine
					</Button>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={data}
						loading={loading}
						pagination={{
							showSizeChanger: false,
						}}
					/>
				</Col>
			</Row>

			<AddMedicine
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
			/>
		</>
	);
};

export default Medicines;
