import Cookies from 'js-cookie';
import { Parser } from 'json2csv';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';

import { getUrl } from '../../../constants/apiUrls';
import { fetchRequest } from '../../../helpers/fetchRequest';
import UploadFile from '../UploadFile';

// antd
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Tooltip, Typography, Upload } from 'antd/es';
const { Text } = Typography;

const NonServicableCombinations = () => {
	const [fileData, setFileData] = useState({});
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const beforeUpload = file => {
		if (file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
			message.error(`only csv file allowed`);
			return true;
		}
		return false;
	};

	const downloadCSV = async (status, data, csvName) => {
		try {
			let opts = { includeEmptyRows: true };
			const parser = new Parser(opts);
			console.log(data);
			const csvString = parser.parse(data);
			const url = window.URL.createObjectURL(new Blob([csvString]));
			let anchorTag = document.createElement('a');
			anchorTag.href = url;
			anchorTag.target = '_Blank';
			anchorTag.download = csvName;
			document.body.appendChild(anchorTag);
			anchorTag.click();
			return Promise.resolve('success');
		} catch (error) {
			console.log(error);
			message.error('download error');
		}
	};

	const downloadSample = async e => {
		const _fileName = `non-serviceable-combinations.csv`;
		let res = {};

		try {
			res = await fetchRequest(getUrl('non-servicable-combinations'));
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) {
				message.error('Failed to download');
			} else {
				if (Array.isArray(res.data)) {
					const codes = res.data.reduce((acc, curr) => {
						if (curr) acc.push({ Combinations: curr });
						return acc;
					}, []);
					await downloadCSV(e, codes, _fileName);
				}
			}
		}
	};

	const upload = async () => {
		let res = {};

		try {
			setLoading(true);
			let file = new FormData();
			file.append('file', fileData);

			res = await fetch(getUrl('non-servicable-combinations'), {
				method: 'PUT',
				body: file,
				headers: {
					Authorization: `Bearer ${Cookies.get('token')}`,
				},
			});
		} catch (e) {
			console.warn(e.message);
		} finally {
			setLoading(false);
			if (res.status === 200) {
				message.success('updated successfully', 1, () => {
					setShowModal(false);
					window.location.reload();
				});
			} else if (res.status === 400) {
				const data = await res.json();
				message.error(data.message);
			} else {
				message.error('Fail to update');
			}
		}
	};

	const handleFileUpload = info => {
		if (info.fileList.length !== 0) {
			setFileData(info.file);
		}
	};

	const resetFile = () => {
		setFileData({});
	};

	return (
		<div className="flex justify-between gap-2">
			<Text strong>Non Serviceable Combinations</Text>

			<div className="flex gap-1">
				<Button onClick={downloadSample}>Download</Button>
				<Tooltip open={true} title="On updating the existing data will be lost">
					<Button type="primary" onClick={() => setShowModal(true)}>
						Update
					</Button>
				</Tooltip>
			</div>

			<UploadFile
				title="Update non serviceable combinations"
				modalVisible={showModal}
				setModalVisible={setShowModal}>
				<div className="grid gap-1 justify-items-center">
					<Upload
						accept=".csv"
						className="text-center"
						name="file"
						beforeUpload={file => beforeUpload(file)}
						onChange={handleFileUpload}
						maxCount={1}
						onRemove={resetFile}>
						<Button icon={<UploadOutlined />}>Add File</Button>
					</Upload>
					<Button
						className="w-max mt-2"
						disabled={isEmpty(fileData)}
						loading={loading}
						onClick={upload}
						type="primary">
						Upload
					</Button>
				</div>
			</UploadFile>
		</div>
	);
};

export default NonServicableCombinations;
