import React, { useEffect } from "react";

export default function PlainPrint({ selectedOrder, setIsPlainPrint }) {
  useEffect(() => {
    const printContents = document.getElementById("printContent").innerHTML;
    const newWin = window.open();
    newWin.document.write(printContents);
    newWin.print();
    newWin.close();
    setIsPlainPrint(false);
  }, [selectedOrder, setIsPlainPrint]);

  return (
    <div id="printContent">
      <table
        style={{
          border: "1px solid #E5E7EB",
          borderCollapse: "collapse",
          margin: "auto",
        }}
      >
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #E5E7EB",
                borderCollapse: "collapse",
                padding: "10px",
                color: "#A9A9A9",
              }}
            >
              Order Display No
            </th>
            <th
              style={{
                border: "1px solid #E5E7EB",
                borderCollapse: "collapse",
                padding: "10px",
                color: "#A9A9A9",
              }}
            >
              New / Repeat
            </th>
            <th
              style={{
                border: "1px solid #E5E7EB",
                borderCollapse: "collapse",
                padding: "10px",
                color: "#A9A9A9",
              }}
            >
              Details
            </th>
          </tr>
        </thead>
        {selectedOrder.map(data => {
          const prescription = data.prescription;
          let prescriptionData = [];
          let printData = [];
          let recommendedPrescriptionData = [];

          prescription.map(prescription => {
            if (prescription.version === 1) {
              const prescriptionList = prescription.prescription;
              prescriptionData.push(prescriptionList);
            } else {
              const prescriptionList = JSON.parse(
                prescription.prescription
              ).prescription;
              prescriptionList.map(prescriptionList => {
                if (prescriptionList.showRecommendation === false) {
                  printData.push(prescriptionList);
                } else {
                  recommendedPrescriptionData.push(prescriptionList);
                }
              });
              return (prescriptionData = [
                ...printData,
                ...recommendedPrescriptionData,
              ]);
            }
          });
          return (
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid #E5E7EB",
                    borderCollapse: "collapse",
                    padding: "10px",
                    color: "#A9A9A9",
                  }}
                >
                  {data.order_display_id}
                </td>
                <td
                  style={{
                    border: "1px solid #E5E7EB",
                    borderCollapse: "collapse",
                    padding: "10px",
                    color: "#A9A9A9",
                  }}
                >
                  {data.count > 1 ? "REPEAT" : "NEW"}
                </td>
                <td
                  style={{
                    border: "1px solid #E5E7EB",
                    borderCollapse: "collapse",
                    color: "#A9A9A9",
                    padding: "8px",
                  }}
                >
                  <>
                    <h5>
                      {`${data.customer_name} / ${data.order_display_id} \n ${data.order_name}`}
                      ;
                    </h5>
                    <ul style={{ listStyleType: "none" }}>
                      {prescriptionData.map(prescriptionDetail => {
                        let isComposition = false;
                        if (prescriptionDetail.is_composition === true) {
                          isComposition = true;
                        }
                        const isRecommended =
                          prescriptionDetail.showRecommendation;
                        if (typeof prescriptionDetail === "string") {
                          return <p>{prescriptionDetail}</p>;
                        } else {
                          if (prescriptionDetail.note === undefined) {
                            if (isRecommended) {
                              return isComposition ? (
                                <li>
                                  {`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage} (${prescriptionDetail.composition}), (RECOMMENDED BY DOCTOR)`}
                                </li>
                              ) : (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage}, (RECOMMENDED BY DOCTOR)`}</li>
                              );
                            } else {
                              return isComposition ? (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage} (${prescriptionDetail.composition})`}</li>
                              ) : (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage}`}</li>
                              );
                            }
                          } else {
                            if (isRecommended) {
                              return isComposition ? (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage} (${prescriptionDetail.composition}), (notes:${prescriptionDetail.note}), (RECOMMENDED BY DOCTOR)`}</li>
                              ) : (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage}, (notes:${prescriptionDetail.note}), (RECOMMENDED BY DOCTOR)`}</li>
                              );
                            } else {
                              return isComposition ? (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage} (${prescriptionDetail.composition}), (notes:${prescriptionDetail.note})`}</li>
                              ) : (
                                <li>{`${prescriptionDetail.Medicine}, ${prescriptionDetail.Dosage}, (notes:${prescriptionDetail.note})`}</li>
                              );
                            }
                          }
                        }
                      })}
                    </ul>
                  </>
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
}
