import { message } from "antd";
import Cookies from "js-cookie";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";
import {
	assignedOrdersActions,
	engagementActions,
	orderManagementActions,
} from "./actions";

export const getMyPatients = async queryParams => {
	store.dispatch({
		type: assignedOrdersActions.GET_MY_PATIENTS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}patients?${queryParams}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: assignedOrdersActions.GET_MY_PATIENTS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: assignedOrdersActions.GET_MY_PATIENTS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: assignedOrdersActions.GET_MY_PATIENTS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getAssignedOrders = async queryParams => {
	store.dispatch({
		type: assignedOrdersActions.GET_MY_PATIENTS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}assigned_orders?${queryParams}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: assignedOrdersActions.GET_MY_PATIENTS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: assignedOrdersActions.GET_MY_PATIENTS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: assignedOrdersActions.GET_MY_PATIENTS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getNewOpenOrders = async params => {
	store.dispatch({
		type: assignedOrdersActions.GET_NEW_OPEN_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}open_orders?flag=new&${params}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: assignedOrdersActions.GET_NEW_OPEN_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: assignedOrdersActions.GET_NEW_OPEN_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: assignedOrdersActions.GET_NEW_OPEN_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getRepeatOpenOrders = async params => {
	store.dispatch({
		type: assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}open_orders?flag=repeat&${params}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: assignedOrdersActions.GET_REPEAT_OPEN_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getAllOrders = async queryParams => {
	store.dispatch({
		type: orderManagementActions.GET_ALL_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}orders?${queryParams}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_ALL_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_ALL_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_ALL_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getOpenOrders = async queryParams => {
	store.dispatch({
		type: orderManagementActions.GET_OPEN_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}admin_open_orders?${queryParams}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_OPEN_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_OPEN_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_OPEN_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getRecentOrders = async pageNo => {
	store.dispatch({
		type: orderManagementActions.GET_RECENT_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}orders?filterAssigned=true&pageNumber=${pageNo}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_RECENT_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_RECENT_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_RECENT_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getGhostOrders = async pageNo => {
	store.dispatch({
		type: orderManagementActions.GET_GHOST_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}orders?status=ghost&pageNumber=${pageNo}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_GHOST_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_GHOST_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_GHOST_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getMedicalReps = async () => {
	store.dispatch({
		type: orderManagementActions.GET_MEDICAL_REPS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}role_users?role=medical_rep`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_MEDICAL_REPS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_MEDICAL_REPS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_MEDICAL_REPS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getConsultReps = async () => {
	store.dispatch({
		type: orderManagementActions.GET_CONSULT_REPS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}role_users?role=consult_rep`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_CONSULT_REPS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_CONSULT_REPS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_CONSULT_REPS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const assignMedicalRep = async (user_id, case_id, order_id) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}assign_order`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ case_id, user_id, order_id }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res !== 200) handleError(res);
		return res;
	} catch (error) {
		message.error(error.message);
	}
};

export const addPatient = number => {
	return fetch(`${baseURL.API_URL_BASE}order/${number}/syncOrder`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	})
		.then(res => {
			if (res.status === 200) {
				return res
					.json()
					.then(response => {
						if (response) {
							return response;
						}
					})
					.catch(error => {
						message.error(
							`Unable to create or assign patient, please contact administrator`
						);
						handleError(error);
					});
			} else {
				message.error(
					`Unable to create or assign patient, please contact administrator`
				);
				return handleError(res);
			}
		})
		.catch(error => {
			message.error(
				`Unable to create or assign patient, please contact administrator`
			);
			handleError(error);
		});
};

export const getPendingEngagements = async queryParams => {
	store.dispatch({
		type: engagementActions.PENDING_ENGAGEMENTS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}engagements?${queryParams}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: engagementActions.PENDING_ENGAGEMENTS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: engagementActions.PENDING_ENGAGEMENTS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: engagementActions.PENDING_ENGAGEMENTS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getEngagementsByOrder = order_id => {
	store.dispatch({
		type: engagementActions.ENGAGEMENTS_BY_ORDER_LOADING,
		payload: true,
	});
	return fetch(`${baseURL.API_URL_BASE}order/${order_id}/engagements`, {
		method: "GET",
		json: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	})
		.then(res => {
			if (res.status === 200) {
				res
					.json()
					.then(response => {
						store.dispatch({
							type: engagementActions.ENGAGEMENTS_BY_ORDER_SUCCESS,
							payload: response,
						});
						return response;
					})
					.catch(error => {
						store.dispatch({
							type: engagementActions.ENGAGEMENTS_BY_ORDER_FAILURE,
							payload: error.toString(),
						});
						handleError(error);
					});
			} else {
				store.dispatch({
					type: engagementActions.ENGAGEMENTS_BY_ORDER_FAILURE,
					payload: res,
				});
				handleError(res);
			}
			return res;
		})
		.catch(error => {
			store.dispatch({
				type: engagementActions.ENGAGEMENTS_BY_ORDER_FAILURE,
				payload: error.toString(),
			});
			handleError(error);
		});
};

export const getEngagementsByCase = async case_id => {
	store.dispatch({
		type: engagementActions.ENGAGEMENTS_BY_CASE_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}engagements/${case_id}`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: engagementActions.ENGAGEMENTS_BY_CASE_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: engagementActions.ENGAGEMENTS_BY_CASE_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: engagementActions.ENGAGEMENTS_BY_CASE_FAILURE,
			payload: error.toString(),
		});
	}
};

export const updateEngagement = async (reminder_date, id) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}engagement/${id}`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ reminder_date }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Reminder Updated Successfully!!!");
		} else {
			const msg = await res.json();
			message.error(msg.message);
		}
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const markDone = async (reminder_id, comment, is_answered) => {
	let res = {};

	try {
		res = await fetch(
			`${baseURL.API_URL_BASE}markEngagementDone/${reminder_id}`,
			{
				method: "PUT",
				json: true,
				body: JSON.stringify({ comment, is_answered }),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			message.success("Reminder Updated Successfully!!!");
		} else {
			const _data = await res.json();
			message.error(_data.message);
		}
	} catch (err) {
		console.warn(err.message);
	} finally {
		return res;
	}
};

export const getAssignedGhostOrders = async pageNo => {
	store.dispatch({
		type: assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}assigned_orders?status=ghost&pageNumber=${pageNo}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: assignedOrdersActions.GET_ASSIGNED_GHOST_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getGlobalSearchResult = async key => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}caseSearch?key=${encodeURIComponent(
				key
			)}&moreInfo=true`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			return data;
		} else {
			return null;
		}
	} catch (error) {
		console.log(error.toString());
	}
};

export const getGlobalSearchOrderResult = async key => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}caseSearch?key=${encodeURIComponent(
				key
			)}&moreInfo=true`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			return data;
		} else {
			return null;
		}
	} catch (error) {
		console.log(error.toString());
	}
};

export const transferOrder = async (fromCaseId, toCaseId, orderList) => {
	let response = true;
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}move-order`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ toCaseId, fromCaseId, orderList }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Order movement completed!");
		} else if (res.status === 500) {
			message.error("Unable to move orders, please contact administrator");
		} else {
			const resJson = await res.json();
			message.error(resJson.message);
			response = false;
		}
	} catch (error) {
		message.error("Unable to move orders, please contact administrator");
		console.log(error.message);
	}
	return response;
};

export const getAssignedDoctors = async pageNo => {
	store.dispatch({
		type: orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}assigned-doctors?${pageNo}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_ORDERS_ASSIGNED_DOCTORS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getDoctors = async () => {
	store.dispatch({
		type: orderManagementActions.GET_DOCTORS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}role_users?role=doctor`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: orderManagementActions.GET_DOCTORS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: orderManagementActions.GET_DOCTORS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: orderManagementActions.GET_DOCTORS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const engagementFeedback = async (data, id) => {
	let res = {};
	try {
		res = await fetch(`${baseURL.API_URL_BASE}engagement-feedback/${id}`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ ...data }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
	} catch (err) {
		console.warn(err.message);
	} finally {
		return res;
	}
};

export const getCallRecording = async id => {
	let res = {};
	try {
		res = await fetch(`${baseURL.API_URL_BASE}call-recording/${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
	} catch (error) {
		console.warn(error.message);
	} finally {
		return res;
	}
};
