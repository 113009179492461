const invalidStatuses = new Set(['void', 'unknown']);

export const getValidOrderCount = orders => {
	if (!Array.isArray(orders)) return 0;
	if (orders.length < 1) return 0;

	let _orders = [];
	_orders = orders.filter(item => invalidStatuses.has(item.status));
	return orders.length - _orders.length;
};
