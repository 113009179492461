import React, { useEffect, useMemo, useState } from "react";
import { ALL_ENGAGEMENT_FEEDBACK } from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import isEmpty from "lodash/isEmpty";
import { getValue } from "../../../helpers";
import { Row, Space, Table, Typography } from "antd";
import groupBy from "lodash/groupBy";
const { Text } = Typography;

function EngagementFeedbackByCaseID({ caseID }) {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		engagement_feedbacks(caseID);
	}, [caseID]);

	const engagement_feedbacks = async id => {
		setLoading(true);
		let res = {};
		try {
			res = await fetchRequest(ALL_ENGAGEMENT_FEEDBACK(id));
			let sortedArray =
				res.data && res.data.length > 0
					? res.data.sort(
							(a, b) => new Date(b.updated_at) - new Date(a.updated_at)
					  )
					: [];
			let _res = {};
			let groupedData = groupBy(sortedArray, item => item.order_display_id);

			Object.keys(groupedData).forEach(key => {
				const _data = groupedData[key];
				const _obj = {};

				const groupbytag = groupBy(_data, item => item.tag);
				engagementsTypes.forEach(tag => {
					if (!groupbytag[tag])
						sortedArray.push({ order_display_id: key, tag: tag });
					_obj[tag] = groupbytag[tag] || [{ order_display_id: key, tag: tag }];
				});
				_res[key] = _obj;
			});

			setData(sortedArray);
		} catch (error) {
			console.warn(error.message);
		}
		setLoading(false);
	};

	const tData = useMemo(() => {
		if (isEmpty(data)) return [];

		let groupedData = groupBy(data, item => item.order_display_id);

		return groupedData;
	}, [data]);

	const columns = useMemo(() => {
		const _col = [
			{
				title: "Order ID",
				dataIndex: "order_display_id",
				key: "order_display_id",
			},
			{
				title: `Engagmenets`,
				key: "tag",
				dataIndex: "tag",
				defaultSortOrder: "ascend",
				sorter: (a, b) => a.tag.localeCompare(b.tag),
			},
			{
				title: `Product Reaction Yes/No`,
				key: "any_product_reaction",
				dataIndex: "any_product_reaction",
			},
			{
				title: "Regular / Irregular",
				key: "customer_adherence",
				dataIndex: "customer_adherence",
			},
			{
				title: "Irregular",
				key: "adherence_reason",
				dataIndex: "adherence_reason",
			},
			{ title: "Energy 1/5/10", key: "energy", dataIndex: "energy" },
			{
				title: "Gut Health 1/5/10",
				key: "gut_health",
				dataIndex: "gut_health",
			},
			{
				title: "Hair Growth 1/5/10",
				key: "hair_growth",
				dataIndex: "hair_growth",
			},
			{
				title: "Hair Fall 1/5/10",
				key: "hair_loss",
				dataIndex: "hair_loss",
			},
			{
				title: "Mood -No Change(1), Some Change(5), Improvement(10)",
				key: "mood",
				dataIndex: "mood",
			},
			{
				title: "Product Reaction",
				key: "product_reaction",
				dataIndex: "product_reaction",
			},
			{
				title: "Reason for not ordering",
				key: "not_ordering_reason",
				dataIndex: "not_ordering_reason",
			},
		];
		return _col;
	}, [data]);
	return (
		<div>
			{Object.keys(tData).map(orderId => {
				return (
					<Table
						bordered
						columns={columns}
						size={"middle"}
						title={() => (
							<Space
								direction="horizontal"
								style={{ width: "100%", justifyContent: "center" }}>
								<Row align="middle">
									<Text
										strong
										style={{ textAlign: "center" }}>{`${orderId}`}</Text>
								</Row>
							</Space>
						)}
						dataSource={tData[orderId].map((d, i) => ({ key: i, ...d }))}
						loading={loading}
						pagination={false}
					/>
				);
			})}
		</div>
	);
}

export default EngagementFeedbackByCaseID;

const engagementsTypes = [
	"Week #1",
	"Week #2",
	"Week #3",
	"Week #4",
	"Week #5",
	"Week #6",
];
