export const INITIAL_VALUE = {
	assignedOrders: [],
	engagements: [],
	loading: false,
	overdueEngagements: [],
	overdueReminders: [],
	rawEngagements: [],
	reminders: [],
	tempEngagements: [],
	tempReminders: [],
};

export const ACTIONS = {
	LOADING: "LOADING",
	RESET: "RESET",
	UPDATE: "UPDATE",
};

export const reducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case ACTIONS.LOADING:
			return {
				...state,
				loading: payload,
			};

		case ACTIONS.UPDATE: {
			return {
				...state,
				...payload,
			};
		}

		case ACTIONS.RESET:
			return INITIAL_VALUE;

		default:
			return state;
	}
};
