import {
	Button,
	Col,
	message,
	Row,
	Select,
	Space,
	Table,
	Tag,
	Typography
} from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	ALLOCATE_ORDER_WAREHOUSES,
	GET_ACTIVE_WAREHOUSES,
	MY_BOOKINGS_API,
	POST_ORDER_WAREHOUSES,
	UNASSIGN_ORDER_WAREHOUSES
} from "../../../constants/apiUrls";
import { getLineItemsName } from "../../../helpers";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { getQueryParams } from "../../../helpers/getQueryParams";
import {
	getPrescriptionPendingShippingItems,
	handleDispatchSearchResult,
	incrementPrintCount,
	markShipped
} from "../service";
import DispatchSearch from "./DispatchSearch";
import PickupList from "./PickupList";
import PlainPrint from "./PlainPrint";
import Print from "./Print";

const { Text } = Typography;

function PrescriptionOrder() {
	const history = useHistory();
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [shippingItems, setShippingItems] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [isPrint, setIsPrint] = useState(false);
	const [isPrintData, setIsPrintData] = useState(false);
	const [prescriptionDataPrint, setPrescriptionDataPrint] = useState([]);
	const [isPlainPrint, setIsPlainPrint] = useState(false);
	const [isPickupList, setIsPickupList] = useState(false);
	const [warehouses, setWarehouses] = useState([]);
	const [warehouse, setWarehouse] = useState({});
	const [data, setData] = useState([]);
	const [searchData, setSearchData] = useState("");
	const [warehouseData, setWarehouseData] = useState("");

	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("currentt2")) || 1
	);

	useEffect(() => {
		if (warehouseData) {
			handleDispatchSearchResult(searchData, pageNo, warehouseData);
		} else if (searchData) {
			handleDispatchSearchResult(searchData, pageNo);
		} else getPrescriptionPendingShippingItems(pageNo);
	}, [searchData, warehouseData, pageNo]);

	useEffect(() => {
		const query = new URLSearchParams(window.location.search);
		if (query.has("currentt2")) {
			query.set("currentt2", pageNo);
		} else {
			query.append("currentt2", pageNo);
		}
		history.push({
			search: query.toString(),
		});
	}, [history, pageNo]);

	useEffect(() => {
		if (pageNo > paginationInfo.pageCount && paginationInfo.pageCount >= 1) {
			setPageNo(paginationInfo.pageCount);
		}
	}, [pageNo, paginationInfo]);

	// Get Active Warehouses
	useEffect(() => {
		let res = [];
		let arr = [];
		const init = async () => {
			try {
				res = await fetchRequest(GET_ACTIVE_WAREHOUSES());
				console.log("WAREHOUSES", res.data);
				if (res.data.length > 0) {
					arr = res.data.map(item => {
						return { label: item.name, value: item.id };
					});
					setWarehouses(arr);
				}
				console.log("NEW ARRAY", arr);
			} catch (error) {
				console.warn(error.message);
			}
		};
		init();
	}, []);

	useEffect(() => {
		if (!isEmpty(allSelectedOrders)) {
			let currPageData = [];
			let currPageKeys = [];
			let otherPageData = [];
			allSelectedOrders.map(row => {
				if (data.some(i => i.key === row.key)) {
					currPageData = [...currPageData, row];
					currPageKeys = [...currPageKeys, row.key];
					return currPageKeys;
				} else {
					return (otherPageData = [...otherPageData, row]);
				}
			});
			setSelectedKeys(currPageKeys);
			setSelectionFromOtherPage(otherPageData);
		}
	}, [allSelectedOrders, data]);

	const {
		getPrescriptionPendingItemsData,
		prescriptionPendingItemsLoading,
		postMarkShippedLoding,
	} = useSelector(state => ({
		getPrescriptionPendingItemsData:
			window.getValue(
				state,
				"dispatchdashboard.getPrescriptionPendingItemsData"
			) || null,
		prescriptionPendingItemsLoading: window.getValue(
			state,
			"dispatchdashboard.prescriptionPendingItemsLoading"
		),
		postMarkShippedLoding: window.getValue(
			state,
			"dispatchdashboard.postMarkShippedLoding"
		),
	}));
	useMemo(() => {
		if (!isEmpty(getPrescriptionPendingItemsData)) {
			setShippingItems(getPrescriptionPendingItemsData.data);
			setPaginationInfo(getPrescriptionPendingItemsData.pagination);
		} else {
			const number =
				getPrescriptionPendingItemsData &&
				getPrescriptionPendingItemsData.pagination;
		}
	}, [getPrescriptionPendingItemsData]);

	useEffect(() => {
		if (isEmpty(shippingItems)) setData([]);
		else {
			const tableData = shippingItems.map(row => ({
				key: window.getValue(row, "id"),
				created_at: window.getValue(row, "created_at"),
				customer_name: window.getValue(row, "case.user.first_name"),
				order_name: getLineItemsName(row.order_meta?.line_items),
				order_number: window.getValue(row, "order_number"),
				prescription: window.getValue(row, "prescription"),
				status: window.getValue(row, "status"),
				count: window.getValue(row, "count"),
				order_display_id: window.getValue(row, "order_display_id"),
				user_id: window.getValue(row, "user_id"),
				age: window.getValue(row, "case.user.age"),
				line_items: window.getValue(row, "order_meta.line_items"),
				print_count: window.getValue(row, "print_count"),
				warehouse: window.getValue(row, "order_meta.warehouses"),
			}));

			setData(tableData);
		}
	}, [shippingItems]);

	const postDataToOrderWareHouse = async (
		order_id,
		meta,
		htmlData,
		warehouse
	) => {
		let _res = {};
		try {
			if (meta.warehouse) {
				message.error(`${meta.customer_name} is already dispatched`);
				return;
			}
			_res = await fetchRequest(POST_ORDER_WAREHOUSES(order_id), {
				method: "POST",
				body: JSON.stringify({
					meta: {
						...meta,
						warehouse: { id: warehouse.value, name: warehouse.label },
					},
					htmlData: htmlData,
					status: `dispatched`,
					warehouse: { id: warehouse.value, name: warehouse.label },
				}),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError) message.error("Failed to update order warehouses");
			else {
				return _res;
			}
		}
	};

	const allocateWarehouse = async data => {
		let _res = {};
		try {
			_res = await fetchRequest(ALLOCATE_ORDER_WAREHOUSES(data.key), {
				method: "POST",
				body: JSON.stringify({
					order_id: data.key,
					order_number: data.order_number,
					warehouse: { id: warehouse.value, name: warehouse.label },
				}),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError)
				message.error(
					`Failed to allocate inventory order, Please contact ${warehouse.label} support`
				);
			else {
				return _res;
			}
		}
	};

	const unassignWarehouse = async data => {
		let _res = {};
		try {
			if (!data.warehouse) {
				message.error(`${data.customer_name} cannot be unassigned`);
				return;
			}

			_res = await fetchRequest(UNASSIGN_ORDER_WAREHOUSES(data.key), {
				method: "POST",
				body: JSON.stringify({
					order_id: data.key,
					order_number: data.order_number,
					warehouse: data.warehouse.meta.warehouse.name.toLowerCase(),
				}),
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (_res.hasError)
				message.error(
					`Failed to unassign order, Please contact ${data.warehouse.meta.warehouse.name} support`
				);
			else {
				return _res;
			}
		}
	};

	useEffect(() => {
		console.log("Data Useeffect", prescriptionDataPrint);
		if (prescriptionDataPrint !== "") {
			handleMarkShipped(prescriptionDataPrint);
		}
	}, [prescriptionDataPrint]);

	const handleMarkShipped = htmlData => {
		let responses = [];
		let apiCalls = [];
		console.log(warehouse, allSelectedOrders);
		console.log("Data", prescriptionDataPrint);

		if (!isEmpty(allSelectedOrders)) {
			allSelectedOrders.map((data, index) => {
				console.log(
					"API Data",
					data.key,
					data.customer_name,
					data,
					prescriptionDataPrint[index],
					warehouse
				);

				return apiCalls.push(
					postDataToOrderWareHouse(
						data.key,
						data,
						prescriptionDataPrint[index],
						warehouse
					)
				);
			});
			Promise.all(apiCalls).then(response => {
				console.log("responseee", response.length);
				if (response[0] && response[0].status === 200) {
					setAllSelectedOrders([]);
					setSelectedKeys([]);
					setSelectionFromOtherPage([]);
					window.location.reload();
				}
			});
		}

		// if (!isEmpty(allSelectedOrders)) {
		// 	allSelectedOrders.map(data => {
		// 		return responses.push(markShipped(data.key));
		// 	});
		// 	Promise.all(responses).then(response => {
		// 		if (response[0].status === "shipped") {
		// 			if (searchData) handleDispatchSearchResult(searchData, pageNo);
		// 			else getPrescriptionPendingShippingItems(pageNo);
		// 			setAllSelectedOrders([]);
		// 			setSelectedKeys([]);
		// 			setSelectionFromOtherPage([]);
		// 			message.success("Successfully Marked Shipped");
		// 		} else {
		// 			message.error("Not able to Marked Shipped");
		// 		}
		// 	});
		// }
	};

	const handleMarkUnassign = () => {
		let apiCalls = [];

		if (!isEmpty(allSelectedOrders)) {
			allSelectedOrders.map((data, index) => {
				return apiCalls.push(unassignWarehouse(data));
			});

			Promise.all(apiCalls).then(response => {
				if (response[0] && response[0].status === 200) {
					setAllSelectedOrders([]);
					setSelectedKeys([]);
					setSelectionFromOtherPage([]);
					message.success("Unassigned Successfully");
					window.location.reload();
				}
			});
		}
	};

	const handleAllocate = () => {
		let apiCalls = [];

		if (!isEmpty(allSelectedOrders)) {
			allSelectedOrders.map((data, index) => {
				return apiCalls.push(allocateWarehouse(data));
			});

			Promise.all(apiCalls).then(response => {
				if (response[0] && response[0].status === 200) {
					setAllSelectedOrders([]);
					setSelectedKeys([]);
					setSelectionFromOtherPage([]);
					message.success("Allocated Successfully");
					window.location.reload();
				}
			});
		}
	};

	const handlePrint = async () => {
		if (!isEmpty(allSelectedOrders)) {
			allSelectedOrders.map(async data => await incrementPrintCount(data.key));
			setIsPrint(true);
		}
		return isPrint;
	};

	const handlePickupList = () => {
		if (!isEmpty(allSelectedOrders)) {
			setIsPickupList(true);
		}
		return isPickupList;
	};

	const handlePlainPrint = () => {
		if (!isEmpty(allSelectedOrders)) {
			setIsPlainPrint(true);
		}
	};

	const columns = [
		{
			title: "Order Display No",
			key: "order_display_id",
			width: "160px",
			render: record => {
				if (record.warehouse) {
					return (
						<>
							{record.order_display_id}
							<br />
							{
								record.warehouse.name && 
								<Tag color={"gold"}>{record.warehouse.name}</Tag>
							}
						</>
					);
				}
				return record.order_display_id;
			},
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			width: "160px",
			render: count => {
				return count === 1 ? (
					<Tag key={count} color="geekblue">
						NEW
					</Tag>
				) : (
					<Tag key={count} color="geekblue">
						REPEAT
					</Tag>
				);
			},
		},
		{
			title: "Details",
			key: "details",
			render: record => {
				const prescription = record.prescription;
				let prescriptionData = [];
				let printData = [];
				let recommendedPrescriptionData = [];

				prescription.map(prescription => {
					if (prescription.version === 1) {
						const prescriptionList = prescription.prescription;
						prescriptionData.push(prescriptionList);
					} else {
						const prescriptionList = JSON.parse(
							prescription.prescription
						).prescription;
						prescriptionList.map(prescriptionList => {
							if (prescriptionList.showRecommendation === false) {
								printData.push(prescriptionList);
							} else {
								recommendedPrescriptionData.push(prescriptionList);
							}
						});
						return (prescriptionData = [
							...printData,
							...recommendedPrescriptionData,
						]);
					}
				});

				let nestedData = [];
				if (prescriptionData) {
					nestedData = prescriptionData.map(data => ({
						Medicine: data,
						Dosage: data.Dosage,
						Note: data.note,
						showRecommendation: data.showRecommendation,
					}));
				}

				const nestedcolumns = [
					{
						title: "Medicine",
						dataIndex: "Medicine",
						key: "Medicine",
						width: "490px",
						render: text => {
							if (typeof text === "object") {
								if (text.showRecommendation === true) {
									return (
										<>
											<p>{text.Medicine}</p>
											<p>(RECOMMENDED BY DOCTOR)</p>
										</>
									);
								} else {
									return <p>{text.Medicine}</p>;
								}
							} else {
								return {
									children: <p>{text}</p>,
									props: {
										colSpan: 10,
									},
								};
							}
						},
					},
					{
						title: "Dosage",
						width: "490px",
						render: record => {
							if (record.Medicine.is_composition === true) {
								return (
									<p>
										{record.Medicine.Dosage} ({record.Medicine.composition})
									</p>
								);
							} else {
								return <p>{record.Medicine.Dosage}</p>;
							}
						},
					},
					{
						title: "Note",
						dataIndex: "Note",
						key: "Note",
						width: "490px",
					},
				];

				return (
					<>
						<Text style={{ fontWeight: "bold" }}>
							{record.customer_name} / {record.order_display_id} <br />
							{record.order_name}
						</Text>
						{""}
						<Table
							columns={nestedcolumns}
							dataSource={nestedData}
							loading={prescriptionPendingItemsLoading}
							pagination={false}
						/>
					</>
				);
			},
		},
		{
			title: "Print Count",
			dataIndex: "print_count",
			key: "print_count",
			width: "120px",
			align: "center",
			render: print_count => {
				return (
					<Tag key={print_count} color="geekblue" style={{ fontSize: "20px" }}>
						{print_count}
					</Tag>
				);
			},
		},
	];

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectionFromOtherPage, ...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	return (
		<>
			{isPrint && <Print data={allSelectedOrders} setIsPrint={setIsPrint} />}
			{isPrintData && (
				<Print
					data={allSelectedOrders}
					setIsPrint={setIsPrintData}
					setPrescriptionDataPrint={setPrescriptionDataPrint}
				/>
			)}

			{isPlainPrint && (
				<PlainPrint
					selectedOrder={allSelectedOrders}
					setIsPlainPrint={setIsPlainPrint}
				/>
			)}
			{isPickupList && (
				<PickupList
					data={allSelectedOrders}
					setIsPickupList={setIsPickupList}
				/>
			)}
			<>
				<Row justify="space-between">
					<Col>
						<DispatchSearch
							setAllSelectedOrders={setAllSelectedOrders}
							setSelectedKeys={setSelectedKeys}
							setSearchData={setSearchData}
							setWarehouseData={setWarehouseData}
							paginationInfo={paginationInfo.rowCount}
							setPageNo={setPageNo}
							search="dashboard"
						/>
					</Col>
					<Col>
						<Space>
							<Button
								type="primary"
								onClick={handlePickupList}
								disabled={isEmpty(allSelectedOrders)}>
								Pickup List
							</Button>
							<Button
								type="primary"
								onClick={handlePlainPrint}
								disabled={isEmpty(allSelectedOrders)}>
								Plain Print
							</Button>
							<Button
								type="primary"
								onClick={handlePrint}
								disabled={isEmpty(allSelectedOrders)}>
								Print
							</Button>
							<Select
								allowClear
								dropdownStyle={{ minWidth: "6rem" }}
								options={warehouses}
								onChange={(e, o) => (o ? setWarehouse(o) : setWarehouse(null))}
								placeholder="Warehouses"
							/>
							<Button
								type="primary"
								loading={postMarkShippedLoding}
								onClick={() => handleAllocate()}
								disabled={isEmpty(allSelectedOrders) || isEmpty(warehouse)}>
								Allocate
							</Button>
							<Button
								type="primary"
								loading={postMarkShippedLoding}
								onClick={() => setIsPrintData(true)}
								disabled={isEmpty(allSelectedOrders) || isEmpty(warehouse)}>
								Mark Shipped
							</Button>
							<Button
								type="primary"
								loading={postMarkShippedLoding}
								onClick={() => handleMarkUnassign()}
								disabled={isEmpty(allSelectedOrders)}>
								Unassign
							</Button>
						</Space>
					</Col>
				</Row>
				<Table
					columns={columns}
					dataSource={data}
					loading={prescriptionPendingItemsLoading}
					rowSelection={rowSelection}
					pagination={{
						current: pageNo,
						defaultPageSize: 20,
						showSizeChanger: false,
						total: paginationInfo.rowCount,
					}}
					onChange={e => setPageNo(e.current)}
				/>
			</>
		</>
	);
}

export default PrescriptionOrder;
