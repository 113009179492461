import { reportActions } from "../actions/report";

export const reports = (state = {}, action) => {
  switch (action.type) {
    case reportActions.GET_REORDER_REPORT_LOADING:
      return {
        ...state,
        reportLoading: true,
      };
    case reportActions.GET_REORDER_REPORT_SUCCESS:
      return {
        ...state,
        reOrderReports: action.payload,
        reportLoading: false,
      };
    case reportActions.GET_REORDER_REPORT_FAILURE:
      return {
        ...state,
        reOrderReports: null,
        reportLoading: false,
      };

    case reportActions.GET_PRESCRIPTION_REPORT_LOADING:
      return {
        ...state,
        prescriptionReportLoading: true,
      };
    case reportActions.GET_PRESCRIPTION_REPORT_SUCCESS:
      return {
        ...state,
        prescriptionReports: action.payload,
        prescriptionReportLoading: false,
      };
    case reportActions.GET_PRESCRIPTION_REPORT_FAILURE:
      return {
        ...state,
        prescriptionReports: null,
        prescriptionReportLoading: false,
      };

    case reportActions.GET_ALL_ENGAGEMENT_LOADING:
      return {
        ...state,
        engagementLoading: true,
      };
    case reportActions.GET_ALL_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        engagementData: action.payload,
        engagementLoading: false,
      };
    case reportActions.GET_ALL_ENGAGEMENT_FAILURE:
      return {
        ...state,
        engagementData: null,
        engagementLoading: false,
      };

    case reportActions.GET_REORDER_DATA_LOADING:
      return {
        ...state,
        reOrderDataLoading: true,
      };
    case reportActions.GET_REORDER_DATA_SUCCESS:
      return {
        ...state,
        reOrderData: action.payload,
        reOrderDataLoading: false,
      };
    case reportActions.GET_REORDER_DATA_FAILURE:
      return {
        ...state,
        reOrderData: null,
        reOrderDataLoading: false,
      };
    default:
      return state;
  }
};
