import { adminDashboardActions } from "../actions";

export const admindashboard = (state = {}, action) => {
  switch (action.type) {
    case adminDashboardActions.GET_ACTIVITY_LOG_LOADING:
      return {
        ...state,
        loading: true
      };
    case adminDashboardActions.GET_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLogData: action.payload,
        loading: false
      };
    case adminDashboardActions.GET_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        activityLogData: null,
        loading: false
      };
    default:
      return state;
  }
}