import React from "react";
import "./assets/styles/App.less";
import Cookies from "js-cookie";
import Sidebar from "./common/sidebar/Sidebar";
import Auth from "./common/sidebar/Auth";

function App() {
	return <>{Cookies.get("token") ? <Sidebar /> : <Auth />}</>;
}

export default App;
