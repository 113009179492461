import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
	getValue
} from "../../../helpers";
import { getDoctorsList } from '../../doctors/service';
import { categoryMap, formatTicketData, getTicketsStats, subCategoryMap } from '../helpers';
import { getTickets } from "../service";
import Conversations from "./Conversations";
import UpdateTicket from "./UpdateTicket";
import ViewTicket from "./ViewTicket";

// Ant Design
import { DatePicker } from 'antd';
import { Card, Col, Row, Table, Tabs, Tag, Tooltip, Typography } from "antd/es";
import ExcelDownload from "./ExcelDownload";
const { RangePicker } = DatePicker;
const { Paragraph, Text, Title } = Typography;

const Tickets = () => {
	const history = useHistory();
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [openModel, setOpenModel] = useState(false);
	const [viewData, setViewData] = useState({});
	const [tab, setTab] = useState(getQueryParams('tab') || 'assigned_tickets');
	const [sortByCreatedAt, setSortByCreatedAt] = useState('descend');
	const [sortByUpdatedAt, setSortByUpdatedAt] = useState('');
	const [sd, setSd] = useState(getQueryParams('sd') ? moment(getQueryParams('sd')) : null);
	const [ed, setEd] = useState(getQueryParams('ed') ? moment(getQueryParams('ed')) : null);
	const [filters, setFilters] = useState({
		priority: getQueryParams('priority')?.split(',') || null,
		category: getQueryParams('category')?.split(',') || null,
		status: getQueryParams('status')?.split(',') || null,
		sub_category: getQueryParams('sub_category')?.split(',') || null,
	});
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("pageNumber")) || 1
	);

	const { tickets, pagination, loading } = useSelector(state => ({
		tickets: getValue(state, "ticketing.tickets") || [],
		pagination: getValue(state, "ticketing.tickets.pagination") || {},
		loading: getValue(state, "ticketing.loading") || false,
	}));

	useEffect(() => {
		let filterItems = [{ pageNumber: pageNo, ...filters, tab: tab, sd, ed }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo, filters, tab, sd, ed]);

	useEffect(() => {
		if (isEmpty(queryParams)) return;

		history.push({ search: queryParams });
	}, [history, queryParams]);

	useEffect(() => {
		getTickets()
	}, []);
	const updateUI = () => {
		getTickets();
	};

	useEffect(() => {
		getDoctorsList();
	}, []);

	const data = useMemo(() => {
		if (!Array.isArray(tickets)) return [];

		let _tickets = formatTicketData(tickets, sd, ed) || [];
		if (tab === 'assigned_tickets') {
			_tickets =  _tickets.filter(item => item.current_assignment?.user_id === localStorage.getItem('users'))
		}
		return _tickets;
	}, [ed, sd, tab, tickets]);

	const ticketStats = useMemo(() => {
		if (!Array.isArray(tickets)) return {};
		return getTicketsStats(tickets);
	}, [tickets])

	const column = [
		{
			title: "Ticket Number",
			dataIndex: "key",
			key: "key",
			width: "100",
			ellipsis: true,
			render: (value, record) => (
				<Tooltip placement="topLeft" title={value}>
					<Text
						className="link"
						onClick={() => {
							setOpenModel(true);
							setViewData(record);
						}}>
						{value}
					</Text>
				</Tooltip>
			),
		},
		{
			title: "Name",
			dataIndex: "customer",
			key: "name",
			width: "100",
			ellipsis: true,
			render: (record) => (
				<div className="grid">
					<Tooltip placement="topLeft" title={record.first_name}>
						<Link className="link" to={`/lead-details/${record.case_id}?is_unified_ticketing=true`}>
							{ record.first_name.length > 10  ? record.first_name.substring(0, 10) + '...' : record.first_name }
						</Link>
					</Tooltip>
				</div>
			)
		},
		{
			title: "Email",
			dataIndex: "customer",
			key: "email",
			width: "100",
			render: value => (
				<Tooltip placement="topLeft" title={value.email}>
					{value.email}
				</Tooltip>
			),
			ellipsis: true
		},
		{
			title: "Order Id",
			dataIndex: "order_display_id",
			key: "order_display_id",
			width: "100",
			render: value => (
				<Tooltip placement="topLeft" title={value}>
					{value}
				</Tooltip>
			),
			ellipsis: true
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: "100",
			filters: [
				{ text: 'Open', value: 'Open' },
				{ text: 'Pending', value: 'Pending' },
				{ text: 'Closed', value: 'Closed' },
				{ text: 'Resolved', value: 'Resolved' }
			],
			defaultFilteredValue: filters.status || null,
			onFilter: (value, record) => record.status.startsWith(value),
			render: (status = "") => (
				<Tag color={statusTagColor[status]} key={status}>
					{status && status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			width: "200",
			filters: [
				{ text: 'Order Related', value: 'order_related' },
				{ text: 'Delivery Related', value: 'delivery_related' },
				{ text: 'Prescription Related', value: 'prescription_related' },
				{ text: 'Escalation', value: 'escalation' },
				{ text: 'IVR Requests', value: 'ivr_requests' },
			],
			defaultFilteredValue: filters.category || null,
			onFilter: (value, record) => record.category.startsWith(value),
			render: value => categoryMap[value],
		},
		{
			title: "Sub Category",
			dataIndex: "sub_category",
			key: "sub_category",
			width: '200',
			filters: [
				{ text: 'Edit Order', value: 'edit_order'},
				{ text: 'Order Cancellation', value: 'order_cancellation'},
				{ text: 'Reverse Pick up', value: 'reverse_pick_up'},
				{ text: 'Wrong order', value: 'wrong_order'},
				{ text: 'Refund', value: 'refund'},
				{ text: 'Delivery Issues', value: 'delivery_issues'},
				{ text: 'Damaged Product', value: 'damaged_product'},
				{ text: 'Prescription Request', value: 'prescription_request'},
				{ text: 'Client Escalation via Medrep call', value: 'client_escalation_via_medrep_call'},
				{ text: 'Client Escalation via WABA', value: 'client_escalation_via_waba'},
				{ text: 'Client Call missed', value: 'client_call_missed'}
			],
			defaultFilteredValue: filters.sub_category || null,
			onFilter: (value, record) => record.sub_category.startsWith(value),
			render: value => (
				<Tooltip placement="topLeft" title={subCategoryMap[value]}>
					{subCategoryMap[value]}
				</Tooltip>
			),
			ellipsis: true
		},
		{
			title: "First Comment",
			dataIndex: "firstComment",
			key: "firstComment",
			width: "120",
			render: value => (
				<Tooltip placement="topLeft" title={value}>
					{value}
				</Tooltip>
			),
			ellipsis: true,
		},
		{
			title: "Comment",
			dataIndex: "latestComment",
			key: "latestComment",
			width: "120",
			render: value => (
				<Tooltip placement="topLeft" title={value}>
					{value}
				</Tooltip>
			),
			ellipsis: true,
		},
		{
			title: "Priority",
			dataIndex: "priority",
			key: "priority",
			width: "120",
			filters: [
				{ text: 'Low', value: '1' },
				{ text: 'Medium', value: '2' },
				{ text: 'High', value: '3' },
				{ text: 'Urgent', value: '4' }
			],
			defaultFilteredValue: filters.priority || null,
			onFilter: (value, record) => record.priority_code.startsWith(value),
			render: value => value,
		},
		{
			title: "Created By",
			dataIndex: "author",
			keys: "author",
			width: "140",
			render: (value) => value.first_name
		},
		{
			title: "Assignee",
			dataIndex: "current_assignment",
			keys: "current_assignment",
			width: "140",
			render: (value) => value.first_name
		},
		{
			title: "Created Date",
			dataIndex: "created_at",
			key: "created_at",
			width: "120",
			sortOrder: sortByCreatedAt,
			sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
			render: value => value
		},
		{
			title: "Last Updated",
			dataIndex: "updated_at",
			key: "updated_at",
			sortOrder: sortByUpdatedAt,
			sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
			width: "120",
			render: value => value,
		},
		{
			title: "Action",
			key: "action",
			fixed: "right",
			width: "100",
			render: record => (
				<div>
					<UpdateTicket data={record} updateUI={updateUI} showIcon={true}/>
					<Conversations data={record} showIcon={true}/>
				</div>
			),
		},
	];

	const handleChange = (pagination, filters, sorter) => {
		setPageNo(pagination.current);
		setFilters(filters);
		console.log(sorter);
		if (sorter.field === 'created_at') {
			setSortByCreatedAt(sorter.order);
			setSortByUpdatedAt('');
		}
		if (sorter.field === 'updated_at') {
			setSortByCreatedAt('');
			setSortByUpdatedAt(sorter.order);
		}
	}

	const handleRangeChange = (range) => {
		if(range) {
			setSd(moment(moment(range[0]).startOf('day')).toISOString());
			setEd(moment(moment(range[1]).endOf('day')).toISOString());
		} else {
			setSd('');
			setEd('');
		}
	}

	const MyTickets = () => {
		return (
			<>
			<div className="w-full">
				<Table
					columns={column}
					dataSource={data}
					loading={loading}
					onChange={(page, filters, sorter) => handleChange(page, filters, sorter)}
					pagination={{
						current: pageNo,
						showSizeChanger: false,
						total: pagination.rowCount,
					}}
					scroll={{
						x: 2000,
					}}
				/>
			</div>

			<div className="mt-4">
				<Paragraph>
					<ul>
						<li>
							<Text>
								Customer Name may not be visible due to order movement. Kindly
								use the order number to find the profile of the customer in such
								cases
							</Text>
						</li>
					</ul>
				</Paragraph>
			</div>

			<ViewTicket
				data={viewData}
				openModel={openModel}
				setOpenModel={setOpenModel}
			/>
		</>
		)
	};

	return (
		<>
			<div className="flex justify-between">
				<Title level={2}>Tickets</Title>
			</div>
			<Row justify="space-between">
				<Col className="flex mb-2">
					<Card title="Overall" style={{ width: 300, height: 200 }}>
						<div><strong>Open: </strong>{ticketStats?.overallStats?.open}</div>
						<div><strong>Pending: </strong>{ticketStats?.overallStats?.pending}</div>
						<div><strong>Resolved: </strong>{ticketStats?.overallStats?.resolved}</div>
						<div><strong>Closed: </strong>{ticketStats?.overallStats?.closed}</div>
					</Card>
					<Card title="Today's" style={{ width: 300, height: 200, marginLeft: '1rem' }}>
						<div><strong>Open: </strong>{ticketStats?.todayStats?.open}</div>
						<div><strong>Pending: </strong>{ticketStats?.todayStats?.pending}</div>
						<div><strong>Resolved: </strong>{ticketStats?.todayStats?.resolved}</div>
						<div><strong>Closed: </strong>{ticketStats?.todayStats?.closed}</div>
					</Card>
				</Col>
				<Col className="mb-2"><ExcelDownload excelData={data} /></Col>
			</Row>
			<Row justify="end" align="bottom">
				<Col className="mt-2">
					<RangePicker
						defaultValue={[sd, ed]}
						disabledDate={(e => e.isAfter(moment()))}
						onChange={handleRangeChange}
						showToday={true}
					/>
				</Col>
			</Row>
			<Tabs defaultActiveKey={tab} onChange={setTab}>
				<Tabs.TabPane tab="Assigned Tickets" key="assigned_tickets">
					<MyTickets />
				</Tabs.TabPane>
				<Tabs.TabPane tab="All Tickets" key="all_tickets">
					<MyTickets />
				</Tabs.TabPane>
			</Tabs>
		</>
	);
};

export default Tickets;

const statusTagColor = {
	Open: "green",
	Pending: "orange",
	Resolved: "blue",
	Closed: "gray",
};
