import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getMedicines, postProductMapping } from "../service";
import { Button, Form, Input, Modal, Row, Select, Switch } from "antd";
const { Option } = Select;

const AddMapping = ({ isModalVisible, setIsModalVisible }) => {
	const [form] = Form.useForm();
	const [medicinesToShow, setMedicinesToShow] = useState([]);

	useEffect(() => {
		if (isModalVisible) getMedicines();
	}, [isModalVisible]);

	const { medicines } = useSelector(state => ({
		medicines: window.getValue(state, "configure.medicines"),
	}));

	const handleModalClose = () => {
		form.resetFields();
		setIsModalVisible(false);
	};

	const onSubmit = async data => {
		const res = await postProductMapping(data);
		if (res) handleModalClose();
	};

	useEffect(() => {
		if (isEmpty(medicines)) setMedicinesToShow([]);
		else {
			const filteredMedicines = medicines?.filter(med => isEmpty(med.group));
			setMedicinesToShow([...filteredMedicines]);
		}
	}, [medicines]);

	return (
		<Modal
			visible={isModalVisible}
			title="Product Mapping"
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}>
			<Row align="middle" justify="center">
				<Form
					form={form}
					layout="vertical"
					name="product_mapping"
					onFinish={onSubmit}>
					<Form.Item
						label="Product ID"
						name="productId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Medicine"
						name="medicineId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Select
							showSearch
							style={{ width: 200 }}
							optionFilterProp="children">
							{!isEmpty(medicinesToShow) &&
								medicinesToShow.map(medicine => (
									<Option value={medicine.id} key={medicine.id}>
										{medicine.display_name}
									</Option>
								))}
						</Select>
					</Form.Item>
					<Form.Item
						label="Product Principal ID"
						name="principalId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input />
					</Form.Item>
					<Form.Item
						label="Price"
						name="price"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item label="Direct Shipping" name="isDirectShipping">
						<Switch
							checkedChildren="Yes"
							defaultChecked={false}
							unCheckedChildren="No"
						/>
					</Form.Item>

					<Form.Item style={{ textAlign: "center" }}>
						<Button type="primary" htmlType="submit">
							Create Product Mapping
						</Button>
					</Form.Item>
				</Form>
			</Row>
		</Modal>
	);
};

export default AddMapping;
