import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Popconfirm, message, Row, Table, Typography } from "antd";
import { getProfileTags } from "../../leadDetails/service";
import { deleteMasterTag } from "../service";
import isEmpty from "lodash/isEmpty";
import { DeleteOutlined } from "@ant-design/icons";
import { handleError } from "../../../helpers";
import AddTag from "./AddTag";
const { Text, Title } = Typography;

const Tags = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);

  const { tagsDetail, loading } = useSelector(state => ({
    tagsDetail: window.getValue(state, "leaddetails.profileTagData"),
    loading: window.getValue(state, "leaddetails.profileTagDataLoading"),
  }));

  useEffect(() => {
    getProfileTags();
  }, []);

  useEffect(() => {
    if (isEmpty(tagsDetail)) setData([]);
    else {
      const nData = tagsDetail.map(tag => ({
        key: window.getValue(tag, "id"),
        name: window.getValue(tag, "display_name"),
        slug: window.getValue(tag, "slug"),
      }));
      setData(nData);
    }
  }, [tagsDetail]);

  const deleteTag = async tag_id => {
    const response = await deleteMasterTag(tag_id);
    if (response.status === 200) {
      message.success("Tag deleted successfully");
      getProfileTags();
    } else {
      message.error("Failed to deleted tag");
      handleError(response);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: "Action",
      key: "action",
      render: record => (
        <Popconfirm
          title={
            <Text>
              Are you sure to delete <Text type="danger">{record.name}</Text> ?
            </Text>
          }
          placement="topRight"
          onConfirm={() => deleteTag(record.key)}
        >
          <Button icon={<DeleteOutlined />} type="link" danger />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Row className="top-margin" justify="space-between">
        <Col>
          <Title level={2}>Tags</Title>
        </Col>
        <Col>
          <Button onClick={() => setIsModalVisible(true)} type="primary">
            Add New Tag
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              showSizeChanger: false,
            }}
          />
        </Col>
      </Row>
      <AddTag
        apiCall={getProfileTags}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
};

export default Tags;
