import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getReminders, getComments, viewDetails } from "../service";
import { MarkDoneModal, SetReminders } from "../../../common";
import { CaseReminder, UserReminders } from ".";
import { getValue } from "../../../helpers";

const Reminders = ({ type }) => {
	const { id: caseId } = useParams();
	const [comment, setComment] = useState("");
	const [markDoneModal, setMarkDoneModal] = useState(false);
	const [reminderDate, setReminderDate] = useState("");
	const [reminderId, setReminderId] = useState({});
	const [remindersModal, setRemindersModal] = useState(false);
	const [reminderStatus, setReminderStatus] = useState("");
	const [reminderType, setReminderType] = useState("");
	const [modalType, setModalType] = useState("");

	useEffect(() => {
		getReminders(caseId);
	}, [caseId]);

	const refreshUI = () => {
		viewDetails(caseId);
		getReminders(caseId);
		getComments(caseId);
	};

	const { loading, reminders } = useSelector(state => ({
		loading: getValue(state, "leaddetails.remindersLoading"),
		reminders: getValue(state, "leaddetails.reminders"),
	}));

	return (
		<>
			{type === "Case" && (
				<CaseReminder loading={loading} reminders={reminders} />
			)}

			{type === "User" && (
				<UserReminders
					loading={loading}
					reminders={reminders}
					setComment={setComment}
					setMarkDoneModal={setMarkDoneModal}
					setReminderDate={setReminderDate}
					setReminderId={setReminderId}
					setRemindersModal={setRemindersModal}
					setReminderStatus={setReminderStatus}
					setReminderType={setReminderType}
					setModalType={setModalType}
				/>
			)}

			<SetReminders
				currComment={comment}
				currDate={reminderDate}
				modalVisible={remindersModal}
				reminder_id={reminderId}
				reminderType={reminderType}
				status={reminderStatus}
				refreshUI={refreshUI}
				setModalVisible={setRemindersModal}
				modalType={modalType}
			/>

			<MarkDoneModal
				modalVisible={markDoneModal}
				reminderId={reminderId}
				reminderType={reminderType}
				refreshUI={refreshUI}
				setModalVisible={setMarkDoneModal}
			/>
		</>
	);
};

export default Reminders;
