import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useParams } from "react-router-dom";
// import { useSelector } from "react-redux";

import { MarkDoneModal, SetReminders } from "../../../common";
import { getCurrentUserRole, getValue } from "../../../helpers";
import {
	CASE_BOOKINGS_API,
	CASE_DOCTOR_BOOKINGS_API,
	LEAD_DISPOSITION_SLOT,
	MY_CONSULT_DOCTOR_BOOKINGS_API,
} from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { leadDespositionMapper } from "../../../managers/caseManager";
import { updateReminder } from "../../dashboard/service";
import {
	getComments,
	getReminders,
	markSlotDismiss,
	postFeedback,
	viewDetails,
} from "../service";

// Components
import Call from "../../../components/Call";
import MarkDoneReminder from "../../../components/markDone/MarkDoneReminder";
import MarkDoneSlotBooking from "../../../components/markDone/MarkDoneSlotBooking";

// Antd
import {
	CheckOutlined,
	EditOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";
import {
	Button,
	Col,
	DatePicker,
	Form,
	message,
	Modal,
	Popconfirm,
	Row,
	Select,
	Table,
	Tag,
	Tooltip,
	Typography,
} from "antd";
const { Option } = Select;
const { Title } = Typography;

const CaseReminder = ({ loading, reminders }) => {
	const { id: case_id } = useParams();
	const [form] = Form.useForm();
	const [userRole] = useState(getCurrentUserRole());
	const [comment, setComment] = useState("");
	const [currReminderDate, setCurrReminderDate] = useState("");
	const [currReminderId, setCurrReminderId] = useState("");
	const [currStatus, setCurrStatus] = useState("");
	const [leadFeedback, setLeadFeedback] = useState("");
	const [leadStatus, setLeadStatus] = useState("");
	const [markDoneModal, setMarkDoneModal] = useState(false);
	const [rescheduleModal, setRescheduleModal] = useState(false);
	const [reminderDate, setReminderDate] = useState(null);
	const [updatingReminder, setUpdatingReminder] = useState(false);
	const [updateStatusModal, setUpdateStatusModal] = useState(false);
	const [updatingStatus, setUpdatingStatus] = useState(false);
	const [userReminderModal, setUserReminderModal] = useState(false);
	const [userMarkDoneModal, setUserMarkDoneModal] = useState(false);
	const [slotMarkDoneModal, setslotMarkDoneModal] = useState(false);
	const [bookings, setBookings] = useState([]);
	const [doctorbookings, setDoctorBookings] = useState([]);

	const [loadingBookings, setLoadingBookings] = useState(false);
	const [currTag, setCurrTag] = useState("");
	console.log("role", userRole);

	useEffect(() => {
		setReminderDate(moment(currReminderDate));
	}, [currReminderDate]);

	// const { orders } = useSelector(state => ({
	// 	orders: getValue(state, "leaddetails.usersDetail.orders"),
	// }));

	// Fetch Doctor Bookings
	const getDoctorBookings = useCallback(async () => {
		if (isEmpty(case_id)) setDoctorBookings([]);

		let res = {};
		setLoadingBookings(true);

		try {
			res = await fetchRequest(CASE_DOCTOR_BOOKINGS_API(case_id));
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) setDoctorBookings([]);
			else setDoctorBookings(res.data);
			setLoadingBookings(false);
		}
	}, [case_id]);

	useEffect(() => {
		getDoctorBookings();
	}, [getDoctorBookings]);
	// Ends

	const doctorBookingsData = useMemo(() => {
		if (isEmpty(doctorbookings)) return [];
		return doctorbookings.map(row => ({
			key: getValue(row, "id"),
			reminder_date: getValue(row, "slot.slot_time"),
			status: "active",
			tag: "DOCTOR SLOT BOOKING",
			type: "doctor_booking",
			phone: getValue(row, "case.user.phone_number"),
		}));
	}, [doctorbookings]);

	// Fetch Bookings
	const getBookings = useCallback(async () => {
		if (isEmpty(case_id)) setBookings([]);

		let res = {};
		setLoadingBookings(true);

		try {
			res = await fetchRequest(CASE_BOOKINGS_API(case_id));
		} catch (error) {
			console.warn(error);
		} finally {
			if (res.hasError) setBookings([]);
			else setBookings(res.data);
			setLoadingBookings(false);
		}
	}, [case_id]);

	useEffect(() => {
		if (userRole !== "consult_doctor") getBookings();
	}, [getBookings, userRole]);
	// Ends

	const bookingsData = useMemo(() => {
		if (isEmpty(bookings)) return [];
		return bookings.map(row => ({
			key: getValue(row, "id"),
			reminder_date: getValue(row, "slot.slot_time"),
			status: "active",
			tag: "SLOT BOOKING",
			type: "booking",
			phone: getValue(row, "case.user.phone_number"),
		}));
	}, [bookings]);

	const data = useMemo(() => {
		if (isEmpty(reminders) || userRole === "consult_doctor") return [];

		const _filteredSystemReminders = reminders.filter(({ tag, type }) => {
			return tag !== "CUSTOMER_SCHEDULE_REMINDER" && type === "system";
		});

		const _systemReminders = _filteredSystemReminders.map((row, index) => ({
			key: getValue(row, "id"),
			item1: index === 0,
			comment: getValue(row, "comment"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			reschedule_count: getValue(row, "reschedule_count"),
			tag: getValue(row, "tag"),
			type: getValue(row, "type"),
			phone: getValue(row, "case.user.phone_number"),
		}));

		const _filteredCaseRemiders = reminders.filter(({ type }) => {
			return type === "user";
		});

		const _userReminders = _filteredCaseRemiders.map(row => ({
			key: getValue(row, "id"),
			comment: getValue(row, "comment"),
			reminder_date: getValue(row, "reminder_date"),
			status: getValue(row, "status"),
			reschedule_count: getValue(row, "reschedule_count"),
			tag: getValue(row, "tag") || "user",
			type: getValue(row, "type"),
			phone: getValue(row, "case.user.phone_number"),
		}));

		return [..._systemReminders, ..._userReminders];
	}, [reminders, userRole]);

	const combinedData = useMemo(() => {
		const _data = [...data, ...bookingsData, ...doctorBookingsData];
		return _data.sort((a, b) => {
			return new Date(a.reminder_date) - new Date(b.reminder_date);
		});
	}, [data, bookingsData, doctorBookingsData]);

	const refreshUI = () => {
		viewDetails(case_id);
		getReminders(case_id);
		getComments(case_id);
	};

	const handleModalClose = () => {
		setRescheduleModal(false);
		setReminderDate(moment(currReminderDate));
	};

	const handleUpdateStatusModal = () => {
		setUpdateStatusModal(false);
		setLeadFeedback("");
		setLeadStatus("");
		form.resetFields();
	};

	// handleReminder
	const handleReminder = async (info = {}) => {
		if (isEmpty(reminders)) return message.warn("No reminders");

		const _data = {
			...info,
			reminder_date: reminderDate,
			reminder_id: currReminderId,
			status: currStatus,
		};

		setUpdatingReminder(true);
		const _res = await updateReminder(_data);
		setUpdatingReminder(false);

		if (_res.status !== 200) return;

		refreshUI();
		setMarkDoneModal(false);
		handleModalClose();
	};
	// End of handleReminder

	// Handle updatestatus
	const handleUpdateStatus = async () => {
		const _data = {
			feedback: leadFeedback,
			reminder_id: data[0]?.key,
			status: leadStatus,
		};

		setUpdatingStatus(true);
		const _res = await postFeedback(_data, case_id);
		setUpdatingStatus(false);

		if (_res.status === 200) {
			refreshUI();
			handleUpdateStatusModal();
		}
	};
	// End

	const markSlotDone = async data => {
		data["case_id"] = case_id;
		const _updateStatus = await fetchRequest(
			LEAD_DISPOSITION_SLOT(currReminderId),
			{
				method: "POST",
				body: JSON.stringify(data),
			}
		);
		if (_updateStatus.status === 200) {
			message.success("Slot Booking updated successfully");
			refreshUI();
			getBookings();
			return { hasError: false };
		} else {
			message.error(_updateStatus.data?.message);
			return { hasError: true };
		}
	};

	const handleFieldChange = e => {
		const _field = e[0];
		if (_field?.name[0] === "status") {
			form.setFieldsValue({ feedback: null });
			setLeadFeedback("");
		}
	};
	const handleDismissSlotBooking = async id => {
		console.log("datasss", id);
		const _res = await markSlotDismiss(id);

		if (_res.status === 200) {
			message.success("Slot booking updated");
			getDoctorBookings();
			refreshUI();
		} else {
			console.log("error", _res);
			message.error("Operation Failed");
		}
	};

	const columns = [
		{
			title: "Due Date",
			dataIndex: "reminder_date",
			key: "reminder_date",
			render: dueDate => moment(dueDate).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Tag",
			key: "tag",
			width: 180,
			render: ({ tag }) => {
				if (isEmpty(tag)) return "-";

				return (
					<div className="flex">
						<Tag key={tag} color="geekblue">
							{tag.replaceAll("_", " ").toUpperCase()}
						</Tag>
					</div>
				);
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			width: 100,
			render: status => {
				if (isEmpty(status)) return "-";
				return (
					<Tag key={status} color="cyan">
						{status.toUpperCase()}
					</Tag>
				);
			},
		},
		{
			title: "Actions",
			key: "actions",
			width: "160px",
			render: ({ key, comment, phone, reminder_date, status, type, tag }) => {
				if (type === "system") {
					return (
						<div className="flex item-center">
							<Tooltip placement="bottom" title="Mark as Done">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setCurrStatus(status);
										setCurrReminderId(key);
										setCurrTag(tag);
										setCurrReminderDate(moment(reminder_date));
										setMarkDoneModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							{/*	<Tooltip placement="bottom" title="Reschedule">
								<Button
									disabled={
										moment(reminder_date).isAfter(moment(), "day") || !item1
									}
									icon={<EditOutlined />}
									onClick={() => {
										setCurrReminderDate(moment(reminder_date));
										setCurrReminderId(key);
										setCurrStatus(status);
										setRescheduleModal(true);
									}}
									type="link"
								/>
								</Tooltip> */}

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: phone,
												case_id: case_id,
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</div>
					);
				} else if (type === "booking") {
					return (
						<>
							<Tooltip placement="bottom" title="Finished">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setCurrStatus(status);
										setCurrReminderId(key);
										setCurrReminderDate(moment(reminder_date));
										setslotMarkDoneModal(true);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: phone,
												case_id: case_id,
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				} else if (type === "doctor_booking") {
					return (
						<Tooltip placement="bottom" title="Dismiss">
							<Popconfirm
								title="Would you like to dismiss this booking?"
								onConfirm={() => handleDismissSlotBooking(key)}
								okText="Yes"
								cancelText="No">
								<Button icon={<CheckCircleOutlined />} type="link" />
							</Popconfirm>
						</Tooltip>
					);
				} else if (type === "user") {
					return (
						<>
							<Tooltip placement="bottom" title="">
								<Button
									icon={<CheckOutlined />}
									onClick={() => {
										setUserMarkDoneModal(true);
										setCurrReminderId(key);
									}}
									type="link"
								/>
							</Tooltip>

							<Tooltip placement="bottom" title="">
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setComment(comment);
										setCurrReminderDate(moment(reminder_date));
										setCurrReminderId(key);
										setUserReminderModal(true);
									}}
									type="link"></Button>
							</Tooltip>

							<Tooltip placement="bottom" title="Call">
								<Button
									icon={
										<Call
											data={{
												phone_number: phone,
												case_id: case_id,
											}}
										/>
									}
									type="link"
								/>
							</Tooltip>
						</>
					);
				}
			},
		},
	];

	return (
		<>
			<Row justify="space-between">
				<Title className="ml-4 mb-2 mt-4" level={4}>
					Bookings
				</Title>

				{/* 		{isEmpty(orders) && (
					<Tooltip placement="bottom" title="Update">
						<Button
							className="mr-4 mb-2 mt-4"
							danger
							icon={<SaveOutlined />}
							type="primary"
							onClick={() => setUpdateStatusModal(true)}
						/>
					</Tooltip>
				)} */}
			</Row>

			<Row justify="center">
				<Col span={24}>
					<Table
						bordered
						columns={columns}
						dataSource={combinedData}
						loading={loading || loadingBookings}
						pagination={false}
						scroll={{ y: 256 }}
					/>
				</Col>
			</Row>

			<MarkDoneReminder
				modalVisible={markDoneModal}
				reminderId={currReminderId}
				tag={currTag}
				handleReminder={handleReminder}
				refreshUI={refreshUI}
				setModalVisible={setMarkDoneModal}
			/>

			<MarkDoneSlotBooking
				modalVisible={slotMarkDoneModal}
				handleSubmit={markSlotDone}
				setModalVisible={setslotMarkDoneModal}
			/>

			<Modal
				visible={rescheduleModal}
				title="Reminder"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,

					<Button
						disabled={
							moment(currReminderDate).isSame(reminderDate) ||
							isEmpty(reminderDate)
						}
						key="submit"
						type="primary"
						loading={updatingReminder}
						onClick={() => handleReminder()}>
						Submit
					</Button>,
				]}>
				<Row align="middle" justify="center">
					<Col>
						<DatePicker
							disabledDate={current => current.isBefore(moment(), "day")}
							format={"DD MMM, h:mm A"}
							onChange={date => setReminderDate(date)}
							showNow={false}
							showTime={true}
							value={!isEmpty(reminderDate) ? reminderDate : null}
						/>
					</Col>
				</Row>
			</Modal>

			<Modal
				visible={updateStatusModal}
				title="Update Status"
				onOk={handleUpdateStatusModal}
				onCancel={handleUpdateStatusModal}
				footer={[
					<Button key="back" onClick={handleUpdateStatusModal}>
						Cancel
					</Button>,
				]}>
				<Row justify="center" style={{ marginTop: 16 }}>
					<Form
						form={form}
						layout="vertical"
						onFieldsChange={handleFieldChange}
						onFinish={handleUpdateStatus}>
						<Form.Item label="status" name="status">
							<Select
								allowClear
								placeholder="Status"
								onChange={selected => setLeadStatus(selected)}
								style={{ width: 240 }}>
								<Option value="hot" key="hot">
									Hot
								</Option>

								<Option value="cold" key="cold">
									Cold
								</Option>
							</Select>
						</Form.Item>

						<Form.Item label="Feedback" name="feedback">
							<Select
								allowClear
								placeholder="Feedback"
								options={leadDespositionMapper(leadStatus)}
								onChange={value => setLeadFeedback(value || "")}
								style={{ width: 240 }}
								value={leadFeedback}
							/>
						</Form.Item>

						<Form.Item>
							<Button
								disabled={isEmpty(leadFeedback) || isEmpty(leadStatus)}
								loading={updatingStatus}
								type="primary"
								htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</Modal>

			<MarkDoneModal
				modalVisible={userMarkDoneModal}
				reminderId={currReminderId}
				reminderType="user"
				refreshUI={refreshUI}
				setModalVisible={setUserMarkDoneModal}
			/>

			<SetReminders
				currComment={comment}
				currDate={currReminderDate}
				modalVisible={userReminderModal}
				reminder_id={currReminderId}
				reminderType="user"
				status="active"
				refreshUI={refreshUI}
				setModalVisible={setUserReminderModal}
				modalType="user"
			/>
		</>
	);
};

export default CaseReminder;
