// API_URL_BASE: "http://localhost:5000/",
export const baseURL = {
	API_URL_BASE: "https://api.dev.hav-g.in/",
	PREVIEW_URL: {
		FEMALE: "https://traya.health/pages/female-result/",
		MALE: "https://traya.health/pages/result4/",
		FORM_URL: "https://form.dev.hav-g.in/",
	},
};

export const USER_PING_CALL = 60000;
export const PUBLIC_VAPID_KEY =
	"BCho54cnMquwR-3qp3lJTZUKf9jFikDTWCa7XXYYAIelmLdsCXRpTGBE2NmH5SadfdwOOzBSSD0yeg2NnoU66JA";

export const GOOGLE_OAUTH_CLIENT_ID =
	"95993965258-rklc6lesppdcqda08sli02fk4rv3h296.apps.googleusercontent.com";

export const opsTeamMember = {
	"jaiswalneelu@traya.health": {
		// value: "91ce29b3-5e45-4411-b0e0-7c0ef6954599", // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Neelu",
	},
	"ganeshkamble@traya.health": {
		// value: "18ef9561-3d85-4f6e-a166-74bba0df828e", // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Ganesh Kamble",
	},
	"sindhu@tatva.health": {
		// value: "eb4012d2-408b-4e22-b979-4c5af9dc2aab", // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Sindhu",
	},
	"patilgaurang@traya.health": {
		// value: "1105394b-d5b8-459f-b5bd-721a77cc3a1c", // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Gaurang",
	},
	"deyashish@traya.health": {
		// value: "1c911188-5dcd-404e-9f28-b38213491796", // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Ashish",
	},
	"yadavpratibha@traya.health": {
		// value: "873ed717-13d0-45b6-80dc-d572dffb0be2", // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Pratibha",
	},
};

export const escalationTeamMember = {
	"priyankakanojia@traya.health": {
		// value: '3d095db2-c84a-4d27-9e2c-c3b37d7793ab', // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Priyanka kanojia",
	},
	"khanmahenaaz@traya.health": {
		// value: 'fbbdd445-3df2-48ee-8726-4e96856ce78c', // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Khan Mahenaaz",
	},
	"siddhi@tatva.health": {
		// value: '8906e2cb-11c6-4f8b-8def-54d7c40deeab', //prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Siddhi",
	},
	"kalyani@tatva.health": {
		// value: '1c290664-1ea9-43bc-81f1-554d26eee917', //prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Kalyani",
	},
	"harish@traya.health": {
		// value: 'c5fc686a-8f31-4419-8151-fd8e5c1ee7ee', //prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Harish",
	},
	"shefali@tatva.health": {
		// value: '51e339f9-4d49-4af1-9c0d-f5662e43c1d1', // prod
		value: "4d2b3116-81f8-4901-8a63-144e154b051f", //dev
		label: "Shefali",
	},
};
