import React from "react";
import moment from "moment";
import { isEmpty } from "lodash";
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const CsvDownload = ({ data }) => {
	const exportCSV = () => {
		try {
			let csvRow = [];
			let rowName = [
				[
					encodeURIComponent("Name of Customer"),
					encodeURIComponent("Phone number of customer"),
					encodeURIComponent("Order Display ID"),
					encodeURIComponent("Order Type"),
					encodeURIComponent("Order Details"),
					"Count",
					"Status",
					encodeURIComponent("Order Date"),
					encodeURIComponent("Medical Rep Name"),
					"Warehouse",
					encodeURIComponent("Tracking ID"),
					encodeURIComponent("Tracking URL"),
					encodeURIComponent("Warehouse Assignment Time"),
				],
			];

			for (let item = 0; item < data.length; item++) {
				if (data[item].orderStatus === "ghost") {
					rowName.push([
						encodeURIComponent(data[item].ghostOrderName),
						data[item].ghost_phone_Number,
						encodeURIComponent(data[item].orderDisplayId),
						encodeURIComponent(data[item].order_type),
						encodeURIComponent(data[item].orderName?.replaceAll(",", " ")),
						data[item].count,
						data[item].orderStatus,
						moment(data[item].orderDate).format("YYYY-MM-DD"),
						encodeURIComponent(data[item].assignedTo),
						encodeURIComponent(data[item].warehouse),
						encodeURIComponent(data[item].tracking_id),
						encodeURIComponent(data[item].tracking_url),
					]);
				} else {
					rowName.push([
						encodeURIComponent(data[item].openOrderName),
						data[item].phone_Number,
						encodeURIComponent(data[item].orderDisplayId),
						encodeURIComponent(data[item].order_type),
						encodeURIComponent(data[item].orderName?.replaceAll(",", " ")),
						data[item].count,
						data[item].orderStatus,
						moment(data[item].orderDate).format("YYYY-MM-DD"),
						encodeURIComponent(data[item].assignedTo),
						encodeURIComponent(data[item].warehouse),
						encodeURIComponent(data[item].tracking_id),
						encodeURIComponent(data[item].tracking_url),
						encodeURIComponent(
							data[item].warehouse_assignment_time &&
								moment(data[item].warehouse_assignment_time).format(
									"DD MMM YYYY h:mm a"
								)
						),
					]);
				}
			}

			for (let newitem = 0; newitem < rowName.length; newitem++) {
				csvRow.push(rowName[newitem].join(","));
			}

			let csvString = csvRow.join("%0A");
			let anchorTag = document.createElement("a");
			anchorTag.href = "data:attachment/csv," + csvString;
			anchorTag.target = "_Blank";
			anchorTag.download = "open-orders.csv";
			document.body.appendChild(anchorTag);
			anchorTag.click();
		} catch (error) {
			message.error("download error");
		}
	};

	return (
		<Button
			type="primary"
			icon={<DownloadOutlined />}
			onClick={exportCSV}
			disabled={isEmpty(data)}>
			Download
		</Button>
	);
};

export default CsvDownload;
