import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getUrl } from '../../../constants/apiUrls';
import { fetchRequest } from '../../../helpers/fetchRequest';
import {
	categoryMap,
	categoryOptions,
	getAssignesList,
	priorityOptions,
	subCategoryMap,
	subCategoryOptions,
	uploadFile
} from '../helpers';
import GlobalSearch from './Search';

// Antd
import { PlusOutlined } from '@ant-design/icons';
import { Drawer } from "antd";
import {
	Button,
	DatePicker,
	Divider,
	Form,
	Input,
	message,
	Row,
	Select,
	Typography,
	Upload
} from 'antd/es';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getValue } from '../../../helpers';
import { getDoctorsList } from '../../doctors/service';
const { TextArea } = Input;
const { Text } = Typography;

const authorId = localStorage.getItem('users');

const CreateTicket = ({ updateUI }) => {
	const location = useLocation();
	const [form] = Form.useForm();
	const [caseInfo, setCaseInfo] = useState({});
	const [loading, setLoading] = useState(false);
	const [openMoal, setOpenMoal] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [assignesOptions, setAssignesOptions] = useState([]);
	const [priorityDisabled, setPriorityDisabled] = useState(false);
	const [category, setCategory] = useState('');
	const [subCategory, setSubCategory] = useState('');
	const [edit, setEdit] = useState(false);
	const [visible, setVisible] = useState(false);

	const { usersDetail } = useSelector(state => ({
		usersDetail: getValue(state, 'leaddetails.usersDetail'),
	}));

	const URL = useMemo(() => {
		return getUrl('create-ticket');
	}, []);

	useEffect(() => {
		getDoctorsList();
	}, []);

	const { userRole: doctorsList } = useSelector(state =>
		getValue(state, 'doctorsData.doctorsListData')
	);

	const fetchAssignedPersons = useCallback(
		async orderId => {
			const res = await getAssignesList(
				orderId,
				category,
				subCategory,
				doctorsList
			);
			setAssignesOptions(res);
		},
		[category, doctorsList, subCategory]
	);

	useEffect(() => {
		if (caseInfo.order_id) {
			fetchAssignedPersons(caseInfo.order_id);
		}
	}, [fetchAssignedPersons, caseInfo.order_id]);

	useEffect(() => {
		if (!openMoal) return;
		form.setFieldsValue({ due_by: moment().add(1, 'd') });
	}, [form, openMoal]);

	const updateDescription = useCallback(
		info => {
			if (!info) return;

			let description = '';

			if (info.first_name) {
				description = `Customer Name: ${info.first_name}`;
			}
			if (info.order_display_id) {
				description = description + `\r\nOrder ID: ${info.order_display_id}`;
			}
			if (info.ordered_date) {
				description =
					description +
					`\r\nOrder Date: ${moment(info.ordered_date).format(
						'DD MMM YYYY, h:mm a'
					)}`;
			}

			if (info.order_value) {
				description = description + `\r\nOrder Amount: ${info.order_value}`;
			}

			if (description) description = description + '\r\n';

			if (searchText === info.order_display_id) {
				form.setFieldsValue({ order_details: description });
			} else {
				form.setFieldsValue({ order_details: '' });
			}
		},
		[form, searchText]
	);

	useEffect(() => {
		if (!edit &&
			openMoal && (
				location.pathname.includes('lead-details')
				|| location.pathname.includes('next-call')
			) &&
			usersDetail
		) {
			const {
				email,
				first_name,
				phone_number,
				alternate_email,
				alternate_phone,
				gender,
			} = usersDetail.user;
			let latestOrder = {};

			if (usersDetail.orders) {
				const _orders = usersDetail.orders;
				if (_orders.length > 0) {
					latestOrder = _orders[_orders.length - 1];
				} else {
					return;
				}
			} else {
				return;
			}

			const _caseInfo = {
				ordered_date: usersDetail.latest_order_date,
				user_id: usersDetail.user_id,
				case_id: usersDetail.id,
				email: email,
				phone_number: phone_number,
				first_name: first_name,
				alternate_email: alternate_email,
				alternate_phone: alternate_phone,
				gender: gender,
				order_display_id: latestOrder.order_display_id,
				order_id: latestOrder.id,
				status: latestOrder.status,
				order_value: latestOrder?.order_meta?.total_price,
			};
			setCaseInfo(_caseInfo);
			updateDescription(_caseInfo);
			setSearchText(latestOrder.order_display_id);
		}
	}, [edit, location.pathname, openMoal, updateDescription, usersDetail]);

	useEffect(() => {
		updateDescription(caseInfo);
	}, [caseInfo, updateDescription]);

	const handleFormChange = value => {
		if (value && value.category) {
			setCategory(value.category);
			form.setFieldsValue({ sub_category: '' });
			form.setFieldsValue({ assign_to: '' });
		}
		if (value && value.sub_category) {
			setSubCategory(value.sub_category);
			form.setFieldsValue({ assign_to: '' });
		}
	};

	useEffect(() => {
		if (category === 'escalation') {
			form.setFieldsValue({ priority: '0' });
		} else if (category === 'ivr_requests') {
			form.setFieldsValue({ priority: '3' });
			setPriorityDisabled(true);
		} else {
			form.setFieldsValue({ priority: '' });
			setPriorityDisabled(false);
		}
	}, [category, form]);

	const createTicket = async data => {
		if (!caseInfo.case_id) {
			message.info('Please enter an order ID');
			return;
		}

		if (data.description.trim() === '') {
			message.info('Please fill in the description');
			return;
		}

		if (moment(data.due_by).isBefore(moment())) {
			message.info('Please select a future date in Due By');
			return;
		}

		setLoading(true);
		let res = {};

		try {
			res = await fetchRequest(URL, {
				method: 'POST',
				body: JSON.stringify({
					case_id: caseInfo.case_id,
					provider: null,
					current_assignment: data.assign_to,
					author_id: authorId,
					action_taker: authorId,
					priority: Number(data.priority) + 1,
					category: data.category,
					sub_category: data.sub_category,
					source: 'crm',
					source_meta: {
						title: 'create_ticket',
						order_display_id: caseInfo.order_display_id,
						order_id: caseInfo.order_id,
						order_details: data.order_details,
					},
					provider_meta: {
						subject: `${caseInfo.order_display_id} : ${caseInfo.first_name} : ${
							categoryMap[data.category]
						} -> ${subCategoryMap[data.sub_category]}`,
						description: data.description,
						due_date: data.due_by.toISOString(),
					},
					comment: {
						type: category,
						description: data.description,
						commented_by: authorId,
					},
					due_date: data.due_by.toISOString(),
				}),
			});
		} catch (error) {
			console.error(error);
		} finally {
			if (res.hasError) {
				message.error('Failed to create ticket');
			} else {
				if (data.upload && res.data) {
					let ticketId = null;
					if (Array.isArray(res.data)) {
						ticketId = res.data[0]._id;
					}
					await uploadFile(data.upload.fileList, ticketId);
				}
				message.success('Ticket created successfully');
				if (updateUI) updateUI();
				handleModalClose();
			}
			setLoading(false);
		}
	};

	const handleModalClose = () => {
		setOpenMoal(false);
		setCaseInfo({});
		setSearchText('');
		setEdit(false);
		form.resetFields();
	};

	const handleFakeRequest = ({ onSuccess }) => {
		return setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	return (
		<>
			<Button type="primary" onClick={() => setOpenMoal(true)}>
				Create Ticket
			</Button>

			<Drawer
				title="Create Ticket"
				placement="right"
				visible={openMoal}
				showDrawer={() => setOpenMoal(true)}
				onClose={handleModalClose}
				width={600}
			>
				<Row justify="center" className="mt-4">
					<div className="mb-8 flex flex-col gap-1">
						<Text>Order ID</Text>
						<GlobalSearch
							searchText={searchText}
							setCaseInfo={setCaseInfo}
							setSearchText={setSearchText}
							setEdit={setEdit}
						/>
						<Divider />
					</div>

					<Form
						form={form}
						layout="vertical"
						onFinish={createTicket}
						onValuesChange={handleFormChange}>
						<Form.Item
							label="Category"
							name="category"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={categoryOptions} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Sub Category"
							name="sub_category"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select
								options={subCategoryOptions[category]}
								style={{ width: 320 }}
							/>
						</Form.Item>

						<Form.Item
							label="Assign To"
							name="assign_to"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select
								options={assignesOptions}
								style={{ width: 320 }}
								disabled={!caseInfo.order_id || !category || !subCategory}
							/>
						</Form.Item>

						<Form.Item
							label="Priority"
							name="priority"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select
								options={priorityOptions}
								style={{ width: 320 }}
								disabled={priorityDisabled}
							/>
						</Form.Item>

						<Form.Item
							label="Order Details"
							name="order_details"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<TextArea disabled rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Description"
							name="description"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<TextArea rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Due By"
							name="due_by"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<DatePicker
								disabled
								allowClear
								disabledDate={current => current.isBefore(new Date(), 'day')}
								format={'YYYY-MM-DD hh:mm a'}
								placeholder="Due By"
								showTime={true}
								style={{ width: 320 }}
							/>
						</Form.Item>

						<Form.Item name="upload">
							<Upload
								accept="image/png, image/jpeg, application/pdf"
								customRequest={handleFakeRequest}
								listType="picture-card"
								onPreview={file => {
									if (typeof window.URL.createObjectURL === 'function') {
										const url = window.URL.createObjectURL(file.originFileObj);
										window.open(url, '_blank').focus();
									}
								}}>
								<div className="flex flex-col gap-2">
									<PlusOutlined />
									<span>Upload</span>
								</div>
							</Upload>
						</Form.Item>

						<Form.Item className="text-center">
							<Button type="primary" htmlType="submit" loading={loading}>
								Create
							</Button>
						</Form.Item>
					</Form>
				</Row>
				<Row justify="end">
					<Button key="cancel" onClick={handleModalClose}>
						Cancel
					</Button>
				</Row>
			</Drawer>
		</>
	);
};

export default CreateTicket;
