import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ConsultCallForm from "../../../components/consultCall/ConsultCall";
import { CONSULT_CALL_RESCHEDULE_API } from "../../../constants/apiUrls";
import { getValue } from "../../../helpers";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { getCurrentUserRole } from "../../../helpers/getCurrentUserRole";
import { markConsultCallFinished } from "../../consultRep/service";
import { getDoctorsList } from "../../doctors/service";

import {
	getComments,
	getConsultCallFeedback,
	sendEmailSMSConsultCall,
	updateConsult,
	viewDetails,
} from "../service";

import {
	CheckSquareTwoTone,
	CommentOutlined,
	ReloadOutlined,
} from "@ant-design/icons";
import {
	Button,
	Col,
	message,
	Popconfirm,
	Row,
	Select,
	Space,
	Tooltip,
	Typography,
} from "antd";
const { Option } = Select;
const { Text } = Typography;

const ConsultCall = () => {
	const { id: case_id } = useParams();
	const [orderType, setOrderType] = useState("");
	const [userRole] = useState(getCurrentUserRole);
	const [visible, setVisible] = useState(false);
	const [markingFinished, setMarkingFinished] = useState(false);
	const [rescheduling, setRescheduling] = useState(false);

	const {
		latestOrderType,
		usersDetail: {
			consult_call_due_date,
			consult_call_finished,
			consult_call_tag,
			consult_call_version,
			notes,
			unanswered_count,
		},
	} = useSelector(state => ({
		latestOrderType: getValue(
			state,
			"leaddetails.usersDetail.orders[0].order_type"
		),
		usersDetail: getValue(state, "leaddetails.usersDetail"),
	}));

	console.log(latestOrderType);

	useEffect(() => {
		switch (consult_call_tag) {
			case "general":
				setOrderType("prescription_order");
				break;

			case "direct":
				setOrderType("direct_order");
				break;

			default:
				break;
		}
	}, [consult_call_tag]);

	const handleConsultCall = () => {
		if (!showConsultCallActionBtn.has(userRole)) {
			setVisible(true);
			return;
		}

		switch (orderType) {
			case "prescription_order":
				setVisible(true);
				break;

			case "direct_order":
				handleUpdateConsult();
				break;

			default:
				break;
		}
	};

	const handleUpdateConsult = async () => {
		if (orderType === "direct_order") {
			const res = await updateConsult(case_id, "direct order", "direct");
			if (res.status === 200) {
				message.success("consult call maked as done successfully");
				getDoctorsList();
				viewDetails(case_id);
			} else {
				message.error("Unable to mark consult call as done ");
			}
		}
	};

	// const sendMessage = async () => {
	// 	const msg = `One of our executive had got in touch with you. Thank you for your time, Have a great day.`;
	// 	const res = await postChat(case_id, msg);
	// 	if (res.status === 200) getChats(case_id);
	// };

	const handleNotAnsweredConsultCall = async () => {
		await sendEmailSMSConsultCall(case_id);
	};

	const handleMarkFinished = async () => {
		setMarkingFinished(true);
		const _res = await markConsultCallFinished(case_id);
		setMarkingFinished(false);

		if (_res?.status === 200) viewDetails(case_id);
	};

	const handleConsultCallReschedule = async () => {
		let res = {};

		try {
			setRescheduling(true);
			res = await fetchRequest(CONSULT_CALL_RESCHEDULE_API(case_id), {
				method: "PUT",
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			setRescheduling(false);
			if (res.status === 400) message.error(res.data?.message);
			else if (res.hasError) message.error("Failed to reschedule");
			else {
				message.success("Consult call rescheduled");
				viewDetails(case_id);
			}
		}
	};

	const showConsultCallFinishBtn = useMemo(() => {
		if (userRole === "consult_rep") return true;
		if (userRole === "medical_rep")
			return localStorage.getItem("rep!level") === "3";

		return false;
	}, [userRole]);

	const disableConsultRescheduleBtn = useMemo(() => {
		return rescheduling || consult_call_finished || unanswered_count >= 3;
	}, [rescheduling, consult_call_finished, unanswered_count]);

	const updateUI = () => {
		getComments(case_id);
		viewDetails(case_id);
		getConsultCallFeedback(case_id);
		getDoctorsList();
	};

	return (
		<>
			<Row gutter={[2, 1]} className="lead-info" justify="space-between">
				<Col span={24} style={{ marginBottom: 12, textAlign: "end" }}>
					<Space>
						{allowedToEdit.has(userRole) && (
							<Space size={2}>
								{showConsultCallActionBtn.has(userRole) && (
									<Select
										dropdownStyle={{ minWidth: "9rem" }}
										onChange={e => setOrderType(e)}
										value={orderType}>
										<Option value="prescription_order">
											Prescription Order
										</Option>
										<Option value="direct_order">Direct Order</Option>
									</Select>
								)}

								<Button onClick={handleConsultCall} type="primary">
									Update
								</Button>

								{showConsultCallFinishBtn &&
									showFinishedBtn.has(latestOrderType) && (
										<Tooltip
											placement="bottom"
											disabled={!consult_call_finished || markingFinished}
											title="Finished">
											<Popconfirm
												title="Are you sure? Please check that doctors are assigned (if needed) to this case."
												placement="topRight"
												disabled={!consult_call_finished}
												onConfirm={() => handleMarkFinished()}>
												<Button
													disabled={!consult_call_finished || markingFinished}
													loading={markingFinished}
													icon={<CheckSquareTwoTone twoToneColor="#52c41a" />}
													type="link"
												/>
											</Popconfirm>
										</Tooltip>
									)}

								{showConsultCallActionBtn.has(userRole) && (
									<Tooltip placement="bottom" title="Reschedule">
										<Popconfirm
											disabled={disableConsultRescheduleBtn}
											title="Are you sure you want to Reschedule Consult Call?"
											placement="topRight"
											onConfirm={() => handleConsultCallReschedule()}>
											<Button
												disabled={disableConsultRescheduleBtn}
												icon={<ReloadOutlined />}
												type="link"
											/>
										</Popconfirm>
									</Tooltip>
								)}

								{showConsultCallActionBtn.has(userRole) && (
									<Tooltip
										placement="bottom"
										disabled={consult_call_finished}
										title="Unanswered call">
										<Popconfirm
											title="Are you sure you want to send Email and SMS?"
											placement="topRight"
											disabled={consult_call_finished}
											onConfirm={() => handleNotAnsweredConsultCall()}>
											<Button
												danger
												disabled={consult_call_finished}
												icon={<CommentOutlined />}
												type="link"
											/>
										</Popconfirm>
									</Tooltip>
								)}
							</Space>
						)}
						{/* Lime Chat Button */}
						{/* <Tooltip title="Send To WhatsApp">
                  <Popconfirm
                    disabled={!consult_call_finished}
                    title="Are you sure you want to send message on whatsApp"
                    placement="topRight"
                    onConfirm={sendMessage}
                  >
                    <Button icon={<WhatsAppOutlined />} type="link" />
                  </Popconfirm>
                </Tooltip> */}
					</Space>
				</Col>

				<Col span={24}>
					<span>
						{consult_call_finished ? (
							<>
								<Text strong>Consult call : </Text>
								<Text type="success">Done</Text>
							</>
						) : (
							<>
								<Text strong>Consult call : </Text>
								<Text type="warning">
									Pending
									{consult_call_due_date &&
										` ( Due ${moment(consult_call_due_date).format(
											"DD MMM YYYY hh:mm a"
										)} )`}
								</Text>
							</>
						)}
					</span>
				</Col>

				{!consult_call_finished && (
					<Col span={24}>
						<Text strong>Attempted Count : </Text>
						<Text type="secondary">{unanswered_count || 0}</Text>
					</Col>
				)}

				{consult_call_version === "v1" && notes && (
					<Col span={24}>
						<Text strong>Note : </Text>
						<Text type="secondary">{notes}</Text>
					</Col>
				)}
			</Row>

			{allowedToEdit.has(userRole) && (
				<ConsultCallForm
					modalVisible={visible}
					setModalVisible={setVisible}
					updateUI={updateUI}
				/>
			)}
			{/* {allowedToEdit.has(userRole) && (
				<Modal
					title="Add Note"
					visible={visible}
					onOk={handleModal}
					onCancel={handleModal}
					footer={[
						<Button key="back" onClick={handleModal}>
							Cancel
						</Button>,
						<Button
							disabled={isEmpty(note.trim())}
							loading={updateConsultLoading}
							key="submit"
							type="primary"
							onClick={handleUpdateConsult}>
							Submit
						</Button>,
					]}>
					<TextArea
						onChange={e => setNote(e.target.value)}
						rows={4}
						value={note}
					/>
				</Modal>
			)} */}
		</>
	);
};

export default ConsultCall;

const allowedToEdit = new Set([
	"consult_rep",
	"sales",
	"consult_doctor",
	"medical_rep"
]);
const showConsultCallActionBtn = new Set(["consult_rep", "medical_rep"]);
const showFinishedBtn = new Set(["cod_pending", "cod_rejected"]);
