import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Antd
import { Button, DatePicker, Form } from "antd/es";

export const DateFilter = ({
	startDate,
	endDate,
	handleSubmit,
	isLoading,
	startDisableDate,
	endDisableDate,
}) => {
	const [form] = Form.useForm();

	const [sd, setSd] = useState("");
	const [ed, setEd] = useState("");

	const _handleSubmit = () => {
		handleSubmit(sd, ed);
	};

	useEffect(() => {
		setSd(startDate);
		setEd(endDate);
	}, [endDate, startDate]);

	useEffect(() => {
		form.setFieldsValue({
			startDate: sd ? moment(sd) : moment(),
			endDate: ed ? moment(ed) : moment(),
		});
	}, [ed, form, sd]);

	return (
		<div>
			<Form form={form} layout="inline" onFinish={_handleSubmit}>
				<Form.Item name="startDate">
					<DatePicker
						allowClear
						format="YYYY-MM-DD"
						onChange={e => (e ? setSd(e) : setSd(moment()))}
						disabledDate={c => startDisableDate(c, ed)}
						placeholder="Start Date"
					/>
				</Form.Item>

				<Form.Item name="endDate">
					<DatePicker
						allowClear
						format="YYYY-MM-DD"
						onChange={e => (e ? setEd(e) : setEd(moment()))}
						placeholder="End Date"
						disabledDate={c => endDisableDate(c, sd)}
					/>
				</Form.Item>

				<Form.Item>
					<div className="flex gap-1">
						<Button
							type="primary"
							htmlType="submit"
							disabled={!sd || !ed}
							loading={isLoading}>
							Submit
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};

DateFilter.defaultProps = {
	isLoading: false,
	startDisableDate: () => {},
	endDisableDate: () => {},
};

DateFilter.propTypes = {
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	startDisableDate: PropTypes.func,
	endDisableDate: PropTypes.func,
	isLoading: PropTypes.bool,
};
