import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { List, Typography } from "antd";
import { getCaseTagHistory } from "../service";
const { Text } = Typography;

const ActivityLog = () => {
  const params = useParams();

  const { caseHistoryTagLoading, caseHistoryTagData } = useSelector(state => ({
    caseHistoryTagLoading: window.getValue(
      state,
      "leaddetails.caseHistoryTagLoading"
    ),
    caseHistoryTagData: window.getValue(
      state,
      "leaddetails.caseHistoryTagData"
    ),
  }));

  useEffect(() => {
    getCaseTagHistory(params.id);
  }, [params.id]);

  return (
    <List
      size="large"
      className="recent-comment"
      loading={caseHistoryTagLoading}
      dataSource={caseHistoryTagData}
      renderItem={item => (
        <List.Item type="flex" justify="space-between">
          <Text style={{ maxWidth: "90%" }}>
            <Text strong>{item.first_name}</Text> added Tag{" "}
            <Text strong>{item.slug}</Text>
          </Text>
          <Text type="secondary">
            {moment(item.created_at).format("DD MMM YYYY, h:mm a")}
          </Text>
        </List.Item>
      )}
    />
  );
};

export default ActivityLog;
