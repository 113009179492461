export const filterOptions = [
	{
		label: 'All',
		value: '',
	},
	{
		label: 'Non serviceable pin codes',
		value: 'nonServicable',
	},
	{
		label: 'ODA',
		value: 'oadOrders',
	},
	{
		label: 'Orders above 5000rs',
		value: 'greaterThan5000',
	},
	{
		label: 'Blocked customers',
		value: 'blockedCustomer',
	},
	{
		label: 'Duplicate Customers',
		value: 'duplicateCustomer',
	},
];

export const shippingServiceOptions = [
	{
		label: 'IThink',
		value: 'ithink',
	},
	{
		label: 'Others',
		value: 'others',
	},
];
