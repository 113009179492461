import { message } from "antd";
import Cookies from "js-cookie";
import { LOGIN_WITH_GOOGLE } from "../../constants/apiUrls";
import { baseURL, getValue } from "../../helpers";
import { store } from "../../redux/store";
import { loginActions } from "./actions/login";

const redirectAfterLogout = () => {
	Cookies.remove("token");
	localStorage.removeItem("userName");
	localStorage.removeItem("users");
	localStorage.removeItem("role");
	localStorage.removeItem("is!supervisor");
	localStorage.removeItem("alternate!email");
	localStorage.removeItem("rep!level");
	window.location.pathname = "/";
};

export const login = async usersDetails => {
	store.dispatch({
		type: loginActions.LOGIN_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}login`, {
			method: "POST",
			body: JSON.stringify(usersDetails),
			json: true,
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (res.status === 200) {
			const data = await res.json();

			store.dispatch({
				type: loginActions.LOGIN_SUCCESS,
				payload: data,
			});

			Cookies.set("token", data.token);
			localStorage.setItem("users", data.user.id);
			localStorage.setItem("role", data.user.roles[0]);
			await userDetails();
			message.success(
				"Successfully Login",
				1,
				() => (window.location.href = "/home")
			);
		} else {
			store.dispatch({
				type: loginActions.LOGIN_FAILURE,
				payload: null,
			});
			message.error("Failed to Login");
		}
	} catch (error) {
		store.dispatch({
			type: loginActions.LOGIN_FAILURE,
			payload: null,
		});
		message.error("Failed to Login");
	}
};

export const logout = async () => {
	store.dispatch({
		type: loginActions.LOGOUT_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}logout`, {
			method: "DELETE",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (res.status === 200) {
			store.dispatch({
				type: loginActions.LOGIN_SUCCESS,
				payload: null,
			});
			localStorage.removeItem("LOGIN!STATUS!");
			message.success("Logout Successfully", 1, () => redirectAfterLogout());
		} else {
			store.response({
				type: loginActions.LOGIN_FAILURE,
				payload: null,
			});
			message.error("Something went wrong", 1, () => redirectAfterLogout());
		}
	} catch (error) {
		store.dispatch({
			type: loginActions.LOGIN_FAILURE,
			payload: null,
		});
		message.error("Something went wrong", 1, () => redirectAfterLogout());
	}
};

export const forgot = async email => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}forgot-password`, {
			method: "POST",
			body: JSON.stringify(email),
			json: true,
			headers: {
				"Content-Type": "application/json",
			},
		});

		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const resetPassword = async (password, token) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}reset-password/${token}`, {
			method: "PUT",
			json: true,
			body: JSON.stringify(password),
			headers: {
				"Content-Type": "application/json",
			},
		});

		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const userDetails = async () => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}profile`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			localStorage.setItem("alternate!email", data.alternate_email);
			localStorage.setItem("userName", data.first_name);
			localStorage.setItem("is!supervisor", data.is_supervisor);
			localStorage.setItem("rep!level", data.level);
			if (data.type) Cookies.set("doctorType", data.type);
		}
	} catch (error) {
		console.error(error.message);
	}
};

export const googleLogin = async googleData => {
	store.dispatch({
		type: loginActions.LOGIN_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(LOGIN_WITH_GOOGLE, {
			method: "POST",
			body: JSON.stringify({ token: googleData.tokenId }),
			headers: {
				"Content-Type": "application/json",
			},
		});

		if (res.status === 200) {
			const data = await res.json();
			console.log("DATA ", data);

			store.dispatch({
				type: loginActions.LOGIN_SUCCESS,
				payload: data,
			});

			Cookies.set("token", data.token);
			localStorage.setItem("users", getValue(data, "user.id"));
			localStorage.setItem("role", getValue(data, "user.roles[0]"));
			await userDetails();
			message.success(
				"Successfully Login",
				1,
				() => (window.location.href = "/home")
			);
		} else {
			store.dispatch({
				type: loginActions.LOGIN_FAILURE,
				payload: null,
			});
			message.error("Failed to Login");
		}
	} catch (error) {
		store.dispatch({
			type: loginActions.LOGIN_FAILURE,
			payload: null,
		});
		message.error("Failed to Login");
	}
};
