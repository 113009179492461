import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { deleteProductMapping, getProductsMapping } from "../service";
import AddMapping from "./AddMapping";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Table, Typography } from "antd";
import AddCombo from "./AddCombo";
const { Text, Title } = Typography;

const ProductMapping = () => {
	const [data, setData] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isComboModalVisible, setIsComboModalVisible] = useState(false);

	useEffect(() => {
		getProductsMapping();
	}, []);

	const { productsMapping, loading } = useSelector(state => ({
		productsMapping: window.getValue(state, "configure.productsMapping"),
		loading: window.getValue(state, "configure.productMappingLoading"),
	}));

	useEffect(() => {
		if (isEmpty(productsMapping)) setData([]);
		else {
			const ndata = productsMapping.map(row => ({
				key: window.getValue(row, "id"),
				is_direct_shipping: window.getValue(row, "is_direct_shipping"),
				medicine_name: window.getValue(row, "medicine.display_name"),
				medicine_type: window.getValue(row, "medicine.type"),
				product_id: window.getValue(row, "product_id"),
				price: window.getValue(row, "product_price"),
				principalId: window.getValue(row, "product_principal_id"),
			}));
			setData(ndata);
		}
	}, [productsMapping]);

	const columns = [
		{
			title: "Product Id",
			dataIndex: "product_id",
			key: "product_id",
		},
		{
			title: "Principal ID",
			dataIndex: "principalId",
			key: "principalId",
		},
		{
			title: "Medicine Name",
			dataIndex: "medicine_name",
			key: "medicine_name",
		},
		{
			title: "Medicine Type",
			dataIndex: "medicine_type",
			key: "medicine_type",
		},
		{
			title: "Order Type",
			dataIndex: "is_direct_shipping",
			key: "is_direct_shipping",
			render: is_direct_shipping =>
				is_direct_shipping ? "Direct Order" : "Prescription Order",
		},
		{
			title: "Price",
			dataIndex: "price",
			key: "price",
		},
		{
			title: "Action",
			key: "action",
			render: record => (
				<Popconfirm
					title={
						<Text>
							Are you sure to delete{" "}
							<Text type="danger">{record.product_id}</Text> ?
						</Text>
					}
					placement="topRight"
					onConfirm={() => deleteProductMapping(record.key)}>
					<Button icon={<DeleteOutlined />} type="link" danger />
				</Popconfirm>
			),
		},
	];

	return (
		<>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>Products Mapping</Title>
				</Col>
				<Col>
					<Space direction="horizontal">
						<Button onClick={() => setIsComboModalVisible(true)} type="primary">
							Add New Combo
						</Button>
						<Button onClick={() => setIsModalVisible(true)} type="primary">
							Add New Product
						</Button>
					</Space>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={data}
						loading={loading}
						pagination={{
							showSizeChanger: false,
						}}
					/>
				</Col>
			</Row>

			<AddMapping
				isModalVisible={isModalVisible}
				setIsModalVisible={setIsModalVisible}
			/>
			<AddCombo
				isModalVisible={isComboModalVisible}
				setIsModalVisible={setIsComboModalVisible}
			/>
		</>
	);
};

export default ProductMapping;
