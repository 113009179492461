import { baseURL } from "../helpers/api-config";

// Function to generate url
export const getUrl = url => baseURL.API_URL_BASE + url;
// End

export const TODAY_SALES_TASK_API = getUrl("today_sales_task");
export const CONSULT_CALL_API = getUrl("pending_consult");
export const CALL_API = getUrl("call");
export const DISCOUNT_CODES_API = getUrl("discount-codes");
export const WEB_PUSH_SUBSCRIBE_API = getUrl("web-push/subscribe");
export const NOTIFICATIONS_API = getUrl("web-push/notifications");
export const LOGIN_WITH_GOOGLE = getUrl("login-google");
export const TEMPORARY_ASSIGN_ENGAGEMENT_API = getUrl(
	"v2/temporary-assign-engagement"
);

export const MONTHLY_TARGET_API = (start, end) => {
	return getUrl(`monthly_target?startDate=${start}&endDate=${end}`);
};
export const ALL_SALES_TARGET_API = (start, end) => {
	return getUrl(`sales-target?startDate=${start}&endDate=${end}`);
};
export const SALES_TARGET_API = repId => getUrl(`sales-target/${repId}`);
export const EXISTING_SALES_TARGET_API = (repId, date) => {
	return getUrl(`sales-target/${repId}?targetDate=${date}`);
};
export const MED_REP_ENGAGEMENTS_API = (id, start, end) => {
	return getUrl(`medrep_engagements/${id}?start=${start}&end=${end}' `);
};
export const MED_REP_CONSULT_CALL_API = (id, start, end) => {
	return getUrl(`medrep_pending_consult/${id}?start=${start}&end=${end}`);
};
export const KNOWLARITY_CALLS_API = (id, start, end) => {
	return getUrl(`knowlarity_calls/${id}?start=${start}&end=${end}`);
};
export const RAW_ENGAGEMENTS_API = (id, start, end) => {
	return getUrl(`raw_engagements/${id}?start=${start}&end=${end}`);
};
export const ASSIGNED_ORDER_API = (id, searchQuery) => {
	return getUrl(`medrep_orders/${id}?${searchQuery}`);
};
export const ESCALATED_CASES_API = (id, start, end) => {
	return getUrl(`escalated_cases/${id}?start=${start}&end=${end}`);
};
export const REP_REMINDERS_API = (id, start, end) => {
	return getUrl(`rep_reminders/${id}?start=${start}&end=${end}`);
};
export const COD_ORDERS_API = (id, start, end) => {
	return getUrl(`cod-orders/${id}?startDate=${start}&endDate=${end}`);
};
export const CASE_BOOKINGS_API = case_id => {
	return getUrl(`case_bookings/${case_id}`);
};
export const CASE_DOCTOR_BOOKINGS_API = case_id => {
	return getUrl(`case_doctor_bookings/${case_id}`);
};
export const MY_BOOKINGS_API = (start, end) => {
	return getUrl(`my-bookings?startDate=${start}&endDate=${end}`);
};
export const MY_CONSULT_DOCTOR_BOOKINGS_API = query => {
	return getUrl(`my-consult-doctor-bookings?${query}`);
};
export const DISCOUNT_CODE_API = id => {
	if (id) return getUrl(`discount-code/${id}`);
	return getUrl("discount-code");
};
export const V2_BOOKING_API = id => {
	return getUrl(`v2/booking/${id}`);
};
export const CONSULT_CALL_FEEDBACK_API = caseId => {
	return getUrl(`case/${caseId}/v2/consult_call`);
};
export const CONSULT_CALL_RESCHEDULE_API = caseId => {
	return getUrl(`case/${caseId}/v2/consult_call/reschedule`);
};
export const MEDREP_TEMPORARY_ENGAGEMENTS_API = (id, start, end) => {
	if (!start || !end) {
		return getUrl(`v2/medrep_temporary_engagements/${id}`);
	}
	return getUrl(
		`v2/medrep_temporary_engagements/${id}?startDate=${start}&endDate=${end}`
	);
};
export const DEACTIVATE_SALES_API = salesId => {
	return getUrl(`user/${salesId}/deactivate`);
};

export const MARK_SHIPPED_API = orderId => {
	return getUrl(`order/${orderId}/markShipped`);
};

export const ENGAGEMENT_FEEDBACK = orderId => {
	return getUrl(`engagement-feedback/${orderId}`);
};
export const ALL_ENGAGEMENT_FEEDBACK = caseId => {
	return getUrl(`all-engagement-feedback/${caseId}`);
};
export const GET_ACTIVE_WAREHOUSES = () => {
	return getUrl(`getActiveWarehouses`);
};

export const GET_WAREHOUSES = () => {
	return getUrl(`getWarehouses`);
};

export const POST_ORDER_WAREHOUSES = orderId => {
	return getUrl(`order/${orderId}/toWarehouse`);
};
export const UNASSIGN_ORDER_WAREHOUSES = orderId => {
	return getUrl(`order/${orderId}/unassign-warehouse`);
};

export const MY_ORDERS = query => {
	return getUrl(`my-orders?${query}`);
};

export const UPDATE_LATEST_SALES_ASSIGN = getUrl("update-salesrep-assignments");

export const LEAD_DISPOSITION_SLOT = id => {
	return getUrl(`lead-disposition-slot/${id}`);
};

export const BULK_PRESCRIPTION = orderId => {
	return getUrl(`bulk_prescription/${orderId}`);
};

export const ASSIGNED_DOCTORS = orderId => {
	return getUrl(`assigned_doctors/${orderId}`);
};
export const ALLOCATE_ORDER_WAREHOUSES = orderId => {
	return getUrl(`order/${orderId}/allocateInventory`);
};
export const FRESHDESK_BASE_URL = 'https://adarsh-dev.freshdesk.com/a/tickets'
