import React, { useCallback, useState } from 'react';

// Antd
import { Button, Form, Input, message, Modal, Row, Select } from 'antd/es';
import { useEffect } from 'react';
import { getUrl } from '../../../constants/apiUrls';
import { fetchRequest } from '../../../helpers/fetchRequest';
import { batchImportersOptions } from '../helper';
import { getInwardSuppliers, getSkus } from '../services';

const CreateInwards = ({ updateUI }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [openMoal, setOpenMoal] = useState(false);
	const [skus, setSkus] = useState([]);
	const [suppliers, setSuppliers] = useState([]);

	const fetchSkus = useCallback(async () => {
		let res = await getSkus();
		res = res.map(item => ({
			value: item.id,
			label: item.medicine.display_name,
		}));
		setSkus(res);
	}, []);

	const fetchSuppliers = useCallback(async () => {
		let res = await getInwardSuppliers();
		res = res.map(item => ({ value: item.id, label: item.code }));
		setSuppliers(res);
	}, []);

	useEffect(() => {
		fetchSkus();
		fetchSuppliers();
	}, [fetchSkus, fetchSuppliers]);

	const createInward = async data => {
		setLoading(true);
		let res = {};

		try {
			res = await fetchRequest(getUrl('warehouse-inward'), {
				method: 'POST',
				body: JSON.stringify({
					batch_code: data.batch_code,
					sku_id: data.sku_id,
					supplier_id: data.supplier_id,
					batch_importer_id: data.batch_importer,
					quantity: data.quantity,
				}),
			});
		} catch (error) {
			console.error(error);
		} finally {
			if (res.hasError) {
				message.error('Failed to create inward');
			} else {
				message.success('Inward created successfully');
				if (updateUI) updateUI();
				handleModalClose();
			}
			setLoading(false);
		}
	};

	const handleModalClose = () => {
		setOpenMoal(false);
		form.resetFields();
	};

	return (
		<>
			<Button type="primary" onClick={() => setOpenMoal(true)} className="mb-4">
				Create Inward
			</Button>

			<Modal
				visible={openMoal}
				title="Create Inward"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,
				]}>
				<Row justify="center" className="mt-4">
					<Form form={form} layout="vertical" onFinish={createInward}>
						<Form.Item
							label="Batch Code"
							name="batch_code"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Input rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Skus"
							name="sku_id"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={skus} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Supplier"
							name="supplier_id"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={suppliers} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Batch Importer"
							name="batch_importer"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={batchImportersOptions} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Quantity"
							name="quantity"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Input rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item className="text-center">
							<Button type="primary" htmlType="submit" loading={loading}>
								Create
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</Modal>
		</>
	);
};

export default CreateInwards;
