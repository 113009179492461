import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { getCurrentUserRole, getValue } from "../../../helpers";
import {
	getCallRecording,
	getEngagementsByOrder,
} from "../../orderManagement/service";
import EngagementFeedback from "./EngagementFeedback";

// Ant Design
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Space, Table, Tabs, Tag } from "antd";
const { TabPane } = Tabs;

function EngagementsByOrder({
	isModalVisible,
	orderId,
	orderName,
	setIsModalVisible,
	caseId,
}) {
	const [defaultTab, setDefaultTab] = useState("engagement");
	useEffect(() => {
		if (isModalVisible) getEngagementsByOrder(orderId);
	}, [orderId, isModalVisible]);

	const { loading, engagements } = useSelector(state => ({
		loading: getValue(state, "orderManagement.engagementsByOrderLoading"),
		engagements: getValue(state, "orderManagement.engagementsByOrderData"),
	}));

	const data = useMemo(() => {
		if (isEmpty(engagements)) return [];
		return engagements.map(engagement => ({
			key: getValue(engagement, "id"),
			actual_date: getValue(engagement, "actual_date"),
			callLogs: getValue(engagement, "callLogs"),
			due_date: getValue(engagement, "reminder_date"),
			notes: getValue(engagement, "notes"),
			status: getValue(engagement, "status"),
			tag: getValue(engagement, "tag"),
		}));
	}, [engagements]);

	const handleDownloadCallRecording = async id => {
		const res = await getCallRecording(id);
		if (res.status === 200) {
			const _link = await res.json();
			window.open(_link?.recording, "_newtab");
		}
	};

	const columns = useMemo(() => {
		const _col = [
			{
				title: "Due Date",
				dataIndex: "due_date",
				key: "due_date",
				width: "144px",
				render: due_date =>
					due_date ? moment(due_date).format("DD MMM YYYY, h:mm a") : "-",
			},
			{
				title: "Actual Date",
				dataIndex: "actual_date",
				key: "actual_date",
				width: "144px",
				render: actual_date =>
					actual_date ? moment(actual_date).format("DD MMM YYYY, h:mm a") : "-",
			},
			{
				title: "Status",
				dataIndex: "status",
				key: "status",
				width: "96px",
				render: (status = "") => (
					<Tag color="geekblue">{status.toUpperCase()}</Tag>
				),
			},
			{
				title: "Tag",
				dataIndex: "tag",
				key: "tag",
				width: "160px",
				render: (tag = "") => <Tag color="geekblue">{tag.toUpperCase()}</Tag>,
			},
			{
				title: "Notes",
				dataIndex: "notes",
				key: "notes",
				render: notes => (isEmpty(notes) ? "-" : notes),
			},
		];

		if (getCurrentUserRole() !== "admin") return _col;

		const _callRecordingCol = {
			title: "Call Recordings",
			dataIndex: "callLogs",
			key: "callLogs",
			render: callLogs => {
				if (isEmpty(callLogs)) return "-";
				return (
					<Row style={{ whiteSpace: "nowrap" }}>
						<Space direction="vertical" align="center">
							{callLogs.map(item => (
								<Space key={item.id}>
									<Button
										icon={<DownloadOutlined />}
										onClick={() => handleDownloadCallRecording(item.id)}
										type="link">
										{moment(item.start_time).format("DD MMM YYYY, h:mm a")}
									</Button>
								</Space>
							))}
						</Space>
					</Row>
				);
			},
		};

		return [..._col, _callRecordingCol];
	}, []);

	const handleModal = () => {
		setDefaultTab("engagement");
		setIsModalVisible(curr => !curr);
	};

	return (
		<Modal
			// title={`${orderName}`}
			onCancel={handleModal}
			onOk={handleModal}
			visible={isModalVisible}
			width={1024}
			footer={[
				<Button type="primary" key="back" onClick={handleModal}>
					Close
				</Button>,
			]}>
			<Tabs type="card" defaultActiveKey={defaultTab}>
				<TabPane
					className="pr-2 overflow-y-auto"
					key="engagement"
					tab="Engagement"
					style={{ height: 500 }}>
					<Table
						bordered
						columns={columns}
						dataSource={data}
						loading={loading}
						pagination={false}
					/>
				</TabPane>
				{getCurrentUserRole() === "admin" && (
					<TabPane
						className="pr-2 overflow-y-auto"
						key="feedback"
						tab="Feedback"
						style={{ height: 500 }}>
						<EngagementFeedback orderId={orderId} />
					</TabPane>
				)}
			</Tabs>
		</Modal>
	);
}

export default EngagementsByOrder;
