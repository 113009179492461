import React, { useEffect, useState } from "react";
import { find, isEmpty, orderBy } from "lodash";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { clearOrderItems, deletePrescription } from "../service";
import { CreatePrescription } from "../../leadDetails/components";
import {
	Button,
	Card,
	Col,
	Empty,
	Modal,
	Popconfirm,
	Row,
	Space,
	Table,
	Typography,
} from "antd";
const { Text } = Typography;

function Prescription({
	isModalVisible,
	setIsModalVisible,
	orderId,
	orderStatus,
}) {
	const params = useParams();
	const history = useHistory();
	const [prescriptionData, setPrescriptionData] = useState([]);
	const [copyData, setCopyData] = useState([]);
	const [canEdit, setCanEdit] = useState(false);
	const [canWrite, setCanWrite] = useState(false);
	const [data, setData] = useState([]);
	const [showWriteUI, setShowWriteUi] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setCanWrite(() => orderStatus === "pending_prescription");
		setCanEdit(
			() =>
				orderStatus === "shipping_pending" ||
				orderStatus === "pending_prescription"
		);
		setShowWriteUi(() => orderStatus === "pending_prescription");
	}, [orderStatus]);

	const { prescriptions, prescriptionLoading } = useSelector(state => ({
		prescriptions: window.getValue(state, "doctorsData.prescriptions"),
		prescriptionLoading: window.getValue(
			state,
			"doctorsData.prescriptionLoading"
		),
	}));

	useEffect(() => {
		if (isEmpty(prescriptions)) {
			if (canWrite) {
				setShowWriteUi(true);
				setPrescriptionData([]);
			} else {
				setShowWriteUi(false);
				setPrescriptionData([]);
			}
		} else {
			const currPrescription = {};
			const prescription = find(prescriptions, i => i.order_id === orderId);
			if (isEmpty(prescription)) {
				if (canWrite) {
					setShowWriteUi(true);
					const sortedP = orderBy(prescriptions, i => i.created_at, ["desc"]);
					currPrescription["id"] = sortedP[0].id;
					currPrescription["order_id"] = sortedP[0].order.order_display_id;

					if (sortedP[0].version === 2) {
						currPrescription["prescriptionV2"] = JSON.parse(
							sortedP[0].prescription
						).prescription;
					} else {
						currPrescription["prescriptionV1"] = sortedP[0].prescription;
					}
				} else {
					setShowWriteUi(false);
				}
			} else {
				setShowWriteUi(false);
				currPrescription["id"] = prescription.id;
				currPrescription["order_id"] = prescription.order.order_display_id;
				if (prescription.version === 2) {
					currPrescription["prescriptionV2"] = JSON.parse(
						prescription.prescription
					).prescription;
				}
				if (prescription.version === 1) {
					currPrescription["prescriptionV1"] = prescription.prescription;
				}
			}
			setPrescriptionData(currPrescription);
		}
	}, [canWrite, orderId, prescriptions]);

	useEffect(() => {
		if (isEmpty(prescriptionData)) setData([]);
		else {
			const ndata = prescriptionData.prescriptionV2.map(p => ({
				key: window.getValue(p, "Medicine"),
				composition: window.getValue(p, "composition"),
				Dosage: window.getValue(p, "Dosage"),
				is_composition: window.getValue(p, "is_composition"),
				showRecommendation: window.getValue(p, "showRecommendation"),
				Medicine: window.getValue(p, "Medicine"),
				note: window.getValue(p, "note"),
			}));
			setData(sortByIsRecommended(ndata));
		}
	}, [prescriptionData.prescriptionV2]);

	const columns = [
		{
			title: "Medicine",
			key: "Medicine",
			render: record => {
				return (
					<Space direction="vertical">
						<Text>{record.Medicine}</Text>
						<Text type="secondary">
							{record.showRecommendation && `(Recommended by doctor)`}
						</Text>
					</Space>
				);
			},
		},
		{
			title: "Dosage",
			key: "Dosage",
			render: record => (
				<Space direction="vertical">
					<Text>{record.Dosage}</Text>
					<Text>{record.composition}</Text>
				</Space>
			),
		},
		{
			title: "Note",
			dataIndex: "note",
			key: "note",
		},
	];

	const handleDeletePrescription = async (caseId, itemId) => {
		setLoading(true);
		await deletePrescription(caseId, itemId);
		setLoading(false);
	};

	const handleModal = () => {
		if (isEdit) setIsEdit(false);
		setCopyData([]);
		clearOrderItems();
		setIsModalVisible(!isModalVisible);
		history.goBack();
	};

	return (
		<Modal
			title="Manage Prescription"
			onCancel={handleModal}
			onOk={handleModal}
			visible={isModalVisible}
			width={640}
			footer={[
				<Button type="primary" key="back" onClick={handleModal}>
					Close
				</Button>,
			]}>
			{showWriteUI ? (
				<Space direction="vertical" style={{ width: "100%" }}>
					<Row>
						<Col span={24}>
							<Card
								bordered
								hoverable
								loading={prescriptionLoading}
								title={"Write prescription"}>
								<CreatePrescription
									data={copyData}
									isEdit={false}
									orderId={orderId}
									setData={setCopyData}
								/>
							</Card>
						</Col>
					</Row>
					{!isEmpty(prescriptionData) && (
						<Row>
							<Col span={24}>
								<Card
									bordered
									hoverable
									loading={prescriptionLoading}
									extra={
										!isEmpty(data) && (
											<Button
												disabled={!canWrite || !isEmpty(copyData)}
												onClick={() => setCopyData(data)}>
												Clone
											</Button>
										)
									}
									title={`Last prescription for ${prescriptionData.order_id}`}>
									{!isEmpty(data) && (
										<Table
											columns={columns}
											dataSource={data}
											pagination={false}
										/>
									)}
									{!isEmpty(prescriptionData.prescriptionV1) && (
										<Text>{prescriptionData.prescriptionV1}</Text>
									)}
								</Card>
							</Col>
						</Row>
					)}
				</Space>
			) : !isEmpty(prescriptionData) ? (
				<Row>
					<Col span={24}>
						{isEdit ? (
							<Card
								bordered
								hoverable
								loading={prescriptionLoading}
								title={`Edit prescription ${prescriptionData.order_id}`}>
								<CreatePrescription
									data={copyData}
									isEdit={true}
									prescriptionId={prescriptionData.id}
									setData={setCopyData}
									setShowUI={setIsEdit}
								/>
							</Card>
						) : (
							<Card
								bordered
								hoverable
								loading={prescriptionLoading}
								extra={
									<Space>
										<Popconfirm
											disabled={!canEdit}
											title="Are you sure to delete this prescription ?"
											placement="topRight"
											onConfirm={() =>
												handleDeletePrescription(params.id, prescriptionData.id)
											}>
											<Button disabled={!canEdit} danger loading={loading}>
												Delete
											</Button>
										</Popconfirm>
										<Button
											disabled={!canEdit}
											onClick={() => {
												setIsEdit(true);
												setCopyData(data);
											}}>
											Edit
										</Button>
									</Space>
								}
								title={`Prescription of ${prescriptionData.order_id}`}>
								{!isEmpty(data) && (
									<Table
										columns={columns}
										dataSource={data}
										pagination={false}
									/>
								)}
								{!isEmpty(prescriptionData.prescriptionV1) && (
									<Text>{prescriptionData.prescriptionV1}</Text>
								)}
							</Card>
						)}
					</Col>
				</Row>
			) : (
				<Row justify="center">
					<Col>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</Col>
				</Row>
			)}
		</Modal>
	);
}

export default Prescription;

const sortByIsRecommended = items =>
	items.sort((a, b) => a.showRecommendation - b.showRecommendation);
