import {
	Button,
	DatePicker,
	Form,
	message,
	Row,
	Select,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BULK_PRESCRIPTION } from "../../../constants/apiUrls";
import {
	generateQuery,
	getLineItemsName,
	getSearchParams,
	getValue,
} from "../../../helpers";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { getQueryParams } from "../../../helpers/getQueryParams";
import CreateTicket from "../../ticketing/components/CreateTicket";
import { getAssignedPatients } from "../service";
const { Title } = Typography;

function DoctorDashboard() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [patientsList, setPatientsList] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [pageNo, setPageNo] = useState(parseInt(getQueryParams("pageNo")) || 1);
	const [apiLoading, setApiLoading] = useState(false);

	const { loading, assignedOrders } = useSelector(state => ({
		loading: getValue(state, "doctorsData.assignedOrdersLoading"),
		assignedOrders: getValue(state, "doctorsData.assignedOrdersData"),
	}));

	useMemo(() => {
		if (!isEmpty(assignedOrders)) {
			setPatientsList(assignedOrders.data);
			setPaginationInfo(assignedOrders.pagination);
		}
	}, [assignedOrders]);
	useEffect(() => {
		const filterItems = [{ pageNo: pageNo }];

		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });
		getAssignedPatients(queryParams);
	}, [history, queryParams]);

	useEffect(() => {
		if (patientsList) {
			const data = patientsList.map(row => ({
				key: window.getValue(row, "id"),
				case_id: window.getValue(row, "order.case.id"),
				name: window.getValue(row, "order.case.user.first_name"),
				order_id: window.getValue(row, "order.id"),
				order_display_id: window.getValue(row, "order.order_display_id"),
				order_name: getLineItemsName(row.order?.order_meta?.line_items),
				order_status: window.getValue(row, "order.status"),
				order_date: window.getValue(row, "order.created_at"),
				assigned_date: window.getValue(row, "created_at"),
				count: window.getValue(row, "order.count"),
				sub_status: window.getValue(row, "sub_status"),
			}));

			setData(data);
		}
	}, [patientsList]);

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectionFromOtherPage, ...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};
	useEffect(() => {
		if (!isEmpty(allSelectedOrders)) {
			let currPageData = [];
			let currPageKeys = [];
			let otherPageData = [];
			allSelectedOrders.map(row => {
				if (data.some(i => i.key === row.key)) {
					currPageData = [...currPageData, row];
					currPageKeys = [...currPageKeys, row.key];
					return currPageKeys;
				} else {
					return (otherPageData = [...otherPageData, row]);
				}
			});
			setSelectedKeys(currPageKeys);
			setSelectionFromOtherPage(otherPageData);
		}
	}, [allSelectedOrders, data]);

	const clearSelection = () => {
		setAllSelectedOrders([]);
		setSelectedKeys([]);
		setSelectionFromOtherPage([]);
		history.push({ search: "" });
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text, record) => {
				return (
					<span
						className="link"
						onClick={() =>
							history.push(`/lead-details/${record.case_id}?current=${pageNo}`)
						}>
						{text}
					</span>
				);
			},
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			width: 96,
		},
		{
			title: "Order Name",
			dataIndex: "order_name",
			key: "order_name",
			width: 310,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			render: (status, record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "order_date",
			key: "order_date",
			render: order_date => moment(order_date).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned on",
			dataIndex: "assigned_date",
			key: "assigned_date",
			render: assigned_date =>
				moment(assigned_date).format("DD MMM YYYY, h:mm a"),
		},
	];

	const bulkPrescriptionAPI = async data => {
		let _res = {};
		try {
			setApiLoading(true);
			_res = await fetchRequest(BULK_PRESCRIPTION(data.order_id), {
				method: "POST",
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			return { response: _res, order_id: data.order_display_id };
		}
	};
	const bulkPrescription = async () => {
		let apiCalls = [];

		if (!isEmpty(allSelectedOrders)) {
			allSelectedOrders.map(data => {
				return apiCalls.push(bulkPrescriptionAPI(data));
			});

			Promise.all(apiCalls).then(response => {
				response.map(item => {
					if (item.response.status === 200) {
						message.success(
							`Sucessfully updated prescription for ${item.order_id}`
						);
					} else {
						message.error(`Bulk presciption failed for ${item.order_id}`);
					}
				});
				setApiLoading(false);
				setAllSelectedOrders([]);
				setSelectedKeys([]);
				setSelectionFromOtherPage([]);
				setTimeout(() => {
					window.location.reload();
				}, 1000);
				console.log("api calls response", response);
			});
		}
	};

	return (
		<>
			<Row justify="space-between" align="middle">
				<Title className="top-margin" level={2}>
					Assigned Orders
				</Title>

				<div className="flex gap-1">
					<Button
						type="primary"
						loading={apiLoading}
						onClick={() => bulkPrescription()}
						disabled={isEmpty(allSelectedOrders)}>
						Write Bulk Prescriptions
					</Button>

					{/* <CreateTicket /> */}
				</div>
			</Row>

			<Filters
				clearSelection={clearSelection}
				setPageNo={setPageNo}
				setQueryParams={setQueryParams}
			/>

			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				rowSelection={rowSelection}
				pagination={{
					current: pageNo,
					pageSize: 10,
					showSizeChanger: false,
					total: paginationInfo.rowCount,
				}}
				onChange={e => {
					setPageNo(e.current);
				}}
			/>
		</>
	);
}

export default DoctorDashboard;

const Filters = ({ clearSelection, setPageNo, setQueryParams }) => {
	const [form] = Form.useForm();
	const [constipation, setConstipation] = useState(
		getQueryParams("constipation")
	);
	const [stage, setStage] = useState(getQueryParams("stage"));
	const [startDate, setStartDate] = useState(getQueryParams("startDate"));
	const [endDate, setEndDate] = useState(getQueryParams("endDate"));
	const [sleep, setSleep] = useState(getQueryParams("sleep"));
	const [deficiency, setDeficiency] = useState(getQueryParams("deficiency"));

	const onSubmit = async items => {
		const filterItems = [];

		Object.entries(items).forEach(([key, value]) => {
			switch (key) {
				case "startDate":
					filterItems.push({
						[key]: startDate ? moment(startDate).toISOString() : null,
					});
					break;

				case "endDate":
					filterItems.push({
						[key]: endDate ? moment(endDate).toISOString() : null,
					});
					break;

				default:
					filterItems.push({ [key]: value });
					break;
			}

			if (clearSelection) clearSelection();
		});
		const _query = await generateQuery(filterItems);
		setQueryParams(_query);
		setPageNo(1);
	};

	const handleClear = async () => {
		clearSelection();
		form.resetFields();
		setStartDate(null);
		setEndDate(null);
		setStage(null);
		setConstipation(null);
		setSleep(null);
		setDeficiency(null);
		form.submit();
	};

	const formInitialValues = {
		startDate: startDate ? moment(startDate) : null,
		endDate: endDate ? moment(endDate) : null,
		stage: stage,
		constipation: constipation,
		sleep: sleep,
		deficiency: deficiency,
	};

	return (
		<Form
			className="order-filter"
			form={form}
			initialValues={formInitialValues}
			layout="inline"
			onFinish={onSubmit}>
			<Form.Item name="startDate">
				<DatePicker
					allowClear
					format={"YYYY-MM-DD"}
					id="booking_start_date_input"
					onChange={e => (e ? setStartDate(e) : setStartDate(null))}
					placeholder="Start Date"
				/>
			</Form.Item>

			<Form.Item name="endDate">
				<DatePicker
					allowClear
					disabledDate={current =>
						startDate ? current.isBefore(moment(startDate), "day") : true
					}
					format={"YYYY-MM-DD "}
					id="booking_end_date_input"
					onChange={e => (e ? setEndDate(e) : setEndDate(null))}
					placeholder="End Date"
				/>
			</Form.Item>

			<Form.Item name="stage">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					id="is_finished_input"
					options={stageOptions}
					onChange={e => (e ? setStage(e) : setStage(null))}
					placeholder="Stage"
				/>
			</Form.Item>

			<Form.Item name="constipation">
				<Select
					allowClear
					options={constipationOptions}
					onChange={e => (e ? setConstipation(e) : setConstipation(undefined))}
					placeholder="Constipation"
				/>
			</Form.Item>

			<Form.Item name="sleep">
				<Select
					allowClear
					options={sleepOptions}
					onChange={e => (e ? setSleep(e) : setSleep(undefined))}
					placeholder="Sleep"
					style={{ width: "14rem" }}
				/>
			</Form.Item>

			<Form.Item name="deficiency">
				<Select
					allowClear
					options={deficiencyOptions}
					onChange={e => (e ? setDeficiency(e) : setDeficiency(undefined))}
					placeholder="Deficiency"
				/>
			</Form.Item>

			<Form.Item>
				<div className="flex gap-1">
					<Button id="filter_apply_btn" type="primary" htmlType="submit">
						Apply
					</Button>

					<Button
						id="filter_clear_btn"
						htmlType="button"
						onClick={() => handleClear()}>
						Clear
					</Button>
				</div>
			</Form.Item>
		</Form>
	);
};

const stageOptions = [
	{ label: "Stage-1", value: "Stage-1" },
	{ label: "Stage-2", value: "Stage-2" },
	{ label: "Stage-3", value: "Stage-3" },
	{ label: "Stage-4", value: "Stage-4" },
	{ label: "Stage-5", value: "Stage-5" },
	{ label: "Stage-6", value: "Stage-6" },
	{ label: "Coin Size Patch", value: "Coin Size Patch" },
	{
		label: "Heavy Hair Fall Across The Head",
		value: "Heavy Hair Fall Across The Head",
	},
];
const sleepOptions = [
	{
		label: "Very peacefully for 6 to 8 hours",
		value: "Very peacefully for 6 to 8 hours",
	},
	{
		label: "Disturbed sleep, I wake up at least one time during the night",
		value: "Disturbed sleep, I wake up at least one time during the night",
	},
	{
		label: "Have difficulty falling asleep",
		value: "Have difficulty falling asleep",
	},
];

const deficiencyOptions = [
	{ label: "No", value: "No" },
	{ label: "Yes", value: "Yes" },
	{ label: "Not Sure", value: "Not Sure" },
];

const constipationOptions = [
	{
		label: "No/Rarely",
		value: "No/Rarely",
	},
	{
		label: "Yes",
		value: "Yes",
	},
	{
		label: "Unsatisfactory bowel movements",
		value: "Unsatisfactory bowel movements",
	},
	{
		label: "Suffering from IBS (irritable bowel syndrome) /dysentery",
		value: "Suffering from IBS (irritable bowel syndrome) /dysentery",
	},
];
