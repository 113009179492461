import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getMedicines, postComboProductMapping } from "../service";
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Select,
	Space,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const AddCombo = ({ isModalVisible, setIsModalVisible }) => {
	const [form] = Form.useForm();
	const [medicinesToShow, setMedicinesToShow] = useState([]);
	const [comboMedicinesToShow, setComboMedicinesToShow] = useState([]);

	useEffect(() => {
		if (isModalVisible) getMedicines();
	}, [isModalVisible]);

	const { medicines } = useSelector(state => ({
		medicines: window.getValue(state, "configure.medicines"),
	}));

	const handleModalClose = () => {
		form.resetFields();
		setIsModalVisible(false);
	};

	const onSubmit = async data => {
		data.comboItems.map((item, index) => {
			let found = {};
			found = medicinesToShow.find(e => e.id === item.variant_id);
			if (found) {
				data.comboItems[index] = {
					...data.comboItems[index],
					name: found.display_name,
				};
			}
		});
		const res = await postComboProductMapping(data);
		if (res) handleModalClose();
	};

	useEffect(() => {
		if (isEmpty(medicines)) setMedicinesToShow([]);
		else {
			const filteredMedicines = medicines?.filter(med => isEmpty(med.group));
			const filteredComboMedicines = medicines?.filter(
				med => med.group === "combo"
			);
			setComboMedicinesToShow([...filteredComboMedicines]);
			setMedicinesToShow([...filteredMedicines]);
		}
	}, [medicines]);

	return (
		<Modal
			width={900}
			visible={isModalVisible}
			title="Product Mapping"
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}>
			<Row align="middle" justify="center">
				<Form
					form={form}
					layout="vertical"
					name="product_mapping"
					onFinish={onSubmit}>
					<Form.Item
						label="Product ID"
						name="productId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item
						label="Combo Name"
						name="medicineId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Select
							showSearch
							style={{ width: 200 }}
							optionFilterProp="children">
							{!isEmpty(comboMedicinesToShow) &&
								comboMedicinesToShow.map(medicine => (
									<Option value={medicine.id} key={medicine.id}>
										{medicine.display_name}
									</Option>
								))}
						</Select>
					</Form.Item>

					<Form.Item
						label="Product Principal ID"
						name="principalId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input />
					</Form.Item>
					<br />

					<Form.List label="shift Time" name="comboItems">
						{(fields, { add, remove }) => (
							<Space direction="vertical">
								{fields.map(field => (
									<Row>
										<Divider>{`Combo Item ${field.name + 1}`}</Divider>
										<Space direction="horizontal">
											<Col>
												<Form.Item
													label="Medicine"
													name={[field.name, "variant_id"]}
													rules={[
														{
															required: true,
															message: "Please fill this",
														},
													]}>
													<Select
														showSearch
														style={{ width: 200 }}
														optionFilterProp="children">
														{!isEmpty(medicinesToShow) &&
															medicinesToShow.map(medicine => (
																<Option value={medicine.id} key={medicine.id}>
																	{medicine.display_name}
																</Option>
															))}
													</Select>
												</Form.Item>
											</Col>
											<Col>
												<Form.Item
													label="Quantity"
													name={[field.name, "quantity"]}
													rules={[
														{
															pattern: "^[0-9]+$",
															message: "Please enter a number",
														},
														{
															required: true,
															message: "Please fill this",
														},
													]}>
													<Input />
												</Form.Item>
											</Col>
											<Col>
												<MinusCircleOutlined
													style={{ paddingTop: 12, color: "red" }}
													onClick={() => remove(field.name)}
												/>
											</Col>
										</Space>
									</Row>
								))}

								<Form.Item>
									<Row>
										<Button
											type="dashed"
											onClick={() => {
												console.log("fields", fields);
												add();
											}}
											icon={<PlusOutlined />}>
											Add Combo Items
										</Button>
									</Row>
								</Form.Item>
							</Space>
						)}
					</Form.List>

					<Form.Item
						label="Price"
						name="price"
						rules={[
							{
								pattern: "^[0-9]+$",
								message: "Please enter a number",
							},
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input />
					</Form.Item>

					<Form.Item style={{ textAlign: "center" }}>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
					</Form.Item>
				</Form>
			</Row>
		</Modal>
	);
};

export default AddCombo;
