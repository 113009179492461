import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import { getEscalatedCases } from "./service";
import { generateQuery, getCurrentUserRole, getQueryParams, getValue } from "../../helpers";

// Ant Design
import Col from "antd/es/col";
import Row from "antd/es/row";
import Table from "antd/es/table";
import Typography from "antd/es/typography";
import { Tabs } from 'antd/es';
const { Title } = Typography;

export const EscalatedCases = ({ columns, title }) => {
	const userRole = getCurrentUserRole();
	const [tab, setTab] = useState('escalated_by_you');
	const history = useHistory();
	const [escalatedData, setEscalatedData] = useState(null);
	const [assignedEscalatedData, setAssignedEscalatedData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [pageNo, setPageNo] = useState(parseInt(getQueryParams("et")) || 1);

	const fetchEscalatedCases = useCallback(async () => {
		setLoading(true);
		const _res = await getEscalatedCases();
		setLoading(false);

		if (_res.hasError) setEscalatedData(null);
		else if (_res.data) {
			setEscalatedData(_res.data.raisedBy);
			setAssignedEscalatedData(_res.data.raisedFor);
		}
	}, []);

	useEffect(() => {
		fetchEscalatedCases();
	}, [fetchEscalatedCases, tab]);

	useEffect(() => {
		const filterItems = [{ et: pageNo }];
		const searchParams = generateQuery(filterItems);
		history.push({ search: searchParams });
	}, [history, pageNo]);

	const escalations = useMemo(() => {
		if (isEmpty(escalatedData)) return [];
		return escalatedData.map(item => ({
			key: getValue(item, "case_id"),
			escalatedAt: getValue(item, "createdAt"),
			assignedTo: getValue(item, "raised_for_info.first_name"),
			email: getValue(item, "case_info.email"),
			name: getValue(item, "case_info.first_name"),
			phoneNo: getValue(item, "case_info.phone_number"),
			status: 'Escalated'
		}));
	}, [escalatedData]);

	const assignedEscalations = useMemo(() => {
		if (isEmpty(assignedEscalatedData)) return [];
		return assignedEscalatedData.map(item => ({
			key: getValue(item, "case_id"),
			escalatedAt: getValue(item, "createdAt"),
			assignedTo: getValue(item, "raised_for_info.first_name"),
			email: getValue(item, "case_info.email"),
			name: getValue(item, "case_info.first_name"),
			phoneNo: getValue(item, "case_info.phone_number"),
			status: 'Escalated'
		}));
	}, [assignedEscalatedData]);

	return (
		<Row>
			<Col>
				<Title level={2}>{title}</Title>
			</Col>
			<Col span={24}>
			{
				userRole === "admin" ? (
					<Table
						columns={columns}
						dataSource={assignedEscalations}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							pageSize: 10,
						}}
					/>
				) : (
					userRole === "medical_rep" ? (
						<Tabs defaultActiveKey={tab} onChange={setTab}>
							<Tabs.TabPane tab={`Escalated (${escalations.length || 0})`} key="escalated_by_you">
								<Table
									columns={columns}
									dataSource={escalations}
									loading={loading}
									onChange={e => setPageNo(e.current)}
									pagination={{
										current: pageNo,
										showSizeChanger: false,
										pageSize: 10,
									}}
								/>
							</Tabs.TabPane>
							<Tabs.TabPane tab={`Assigned Escalations (${assignedEscalations.length || 0})`} key="escalated_for_you">
								<Table
									columns={columns}
									dataSource={assignedEscalations}
									loading={loading}
									onChange={e => setPageNo(e.current)}
									pagination={{
										current: pageNo,
										showSizeChanger: false,
										pageSize: 10,
									}}
								/>
							</Tabs.TabPane>
						</Tabs>
					) : (
						<Table
							columns={columns}
							dataSource={assignedEscalations}
							loading={loading}
							onChange={e => setPageNo(e.current)}
							pagination={{
								current: pageNo,
								showSizeChanger: false,
								pageSize: 10,
							}}
						/>
					)
				)}
			</Col>
		</Row>
	);
};
