import React from "react";

// Antd
import { Table, Typography } from "antd/es";
const { Text, Title } = Typography;

const COLUMNS = [
	{
		title: "Engagements",
		dataIndex: "name",
		key: "name",
	},
	{
		title: "Calls",
		dataIndex: "calls",
		key: "calls",
	},
	{
		title: "Finished",
		dataIndex: "finished",
		key: "finished",
	},
	{
		title: "Unfinished",
		dataIndex: "unfinished",
		key: "unfinished",
	},
	{
		title: "1st Attempt",
		dataIndex: "attempt_1",
		key: "attempt_1",
	},
	{
		title: "2nd Attempt",
		dataIndex: "attempt_2",
		key: "attempt_2",
	},
	{
		title: "3rd Attempt",
		dataIndex: "attempt_3",
		key: "attempt_3",
	},
];

const EngagementsTable = ({ loading, data }) => {
	return (
		<Table
			title={() => (
				<Title className="mb-0" level={3}>
					Engagement Schedule
				</Title>
			)}
			columns={COLUMNS}
			dataSource={data}
			loading={loading}
			pagination={false}
			summary={pageData => {
				const sum = pageData.reduce((a, c) => {
					for (let key in c) {
						const el = c[key];
						if (typeof el !== "number") continue;

						if (a[key]) a[key] = a[key] + el;
						else a[key] = el;
					}
					return a;
				}, {});

				return (
					<Table.Summary fixed>
						<Table.Summary.Row>
							<Table.Summary.Cell index={0}>
								<Text strong>Total</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell index={1}>
								<Text strong>{sum["calls"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell index={2}>
								<Text strong>{sum["finished"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell index={3}>
								<Text strong>{sum["unfinished"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell index={4}>
								<Text strong>{sum["attempt_1"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell index={5}>
								<Text strong>{sum["attempt_2"]}</Text>
							</Table.Summary.Cell>

							<Table.Summary.Cell index={6}>
								<Text strong>{sum["attempt_3"]}</Text>
							</Table.Summary.Cell>
						</Table.Summary.Row>
					</Table.Summary>
				);
			}}
		/>
	);
};

export default EngagementsTable;
