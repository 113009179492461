import React, { useState } from "react";
import { SetReminders } from "../../../common";
import { getCurrentUserRole } from "../../../helpers";
import { Button } from "antd";

const SetReminder = () => {
	const [modalVisible, setModalVisible] = useState(false);
	const [userRole] = useState(() => getCurrentUserRole());

	return (
		<>
			{showRoles.has(userRole) && (
				<Button block type="primary" onClick={() => setModalVisible(true)}>
					Set Reminder
				</Button>
			)}

			<SetReminders
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				modalType="user"
			/>
		</>
	);
};

export default SetReminder;

const showRoles = new Set(["medical_rep", "sales"]);
