import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Col, Row, Typography, Table, Space, Card } from "antd";
import { useHistory } from "react-router-dom";
import OrderDataFilter from "../../../common/OrderDataFilter";
import { getQueryParams } from "../../../helpers";
import { getReOrderData } from "../service";
const { Title } = Typography;

function OrderData() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
  const [reOrderDetails, setReOrderDetails] = useState({});
  const [count, setCount] = useState("");

  const { reOrderData, loading } = useSelector(state => ({
    reOrderData: window.getValue(state, "reports.reOrderData"),
    loading: window.getValue(state, "reports.reOrderDataLoading"),
  }));

  useEffect(() => {
    if (isEmpty(reOrderData)) {
      setData([]);
      setPageNo(1);
    } else {
      let reportData;
      reportData = reOrderData.finalData.filter(data => !isEmpty(data));
      reportData = reOrderData.finalData.filter(
        data => data.has_ordered === false
      );
      const reordered = reOrderData.finalData.filter(
        data => data.has_ordered === true
      );

      setReOrderDetails({
        total: reOrderData.total,
        reordered: reordered.length,
        remaining: reportData.length,
      });

      if (count) {
        reportData = reportData.filter(data => data.count === count);
      }

      const ndata = reportData.map(row => ({
        latest_order_date: window.getValue(row, "latest_order_date"),
        rep_name: window.getValue(row, "rep_name"),
        order_display_id: window.getValue(row, "latest_order_no"),
        name: window.getValue(row, "customer_name"),
        phone_number: window.getValue(row, "customer_phone"),
        email: window.getValue(row, "customer_email"),
        count: window.getValue(row, "count"),
        case_id: window.getValue(row, "case_id"),
        due_date: window.getValue(row, "due_date"),
      }));
      setData(ndata);
    }
  }, [reOrderData]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.has("pageNumber")) {
      query.set("pageNumber", pageNo);
    } else {
      query.append("pageNumber", pageNo);
      getReOrderData();
      setReOrderDetails("");
    }
    history.push({
      search: query.toString(),
    });
  }, [history, pageNo]);

  const columns = [
    {
      title: "Customer Name",
      key: "name",
      render: record => {
        return (
          <span
            className="link"
            onClick={() => history.push(`/lead-details/${record.case_id}`)}
          >
            {record.name}
          </span>
        );
      },
    },
    {
      title: "Customer Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Customer Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Last Order Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Last Order Week #4 End Date",
      dataIndex: "due_date",
      key: "due_date",
    },
    {
      title: "Medical Rep",
      dataIndex: "rep_name",
      key: "rep_name",
    },
  ];

  return (
    <>
      <Row className="top-margin" justify="end">
        <Col>
          <OrderDataFilter
            apicall={getReOrderData}
            setPageNo={setPageNo}
            setData={setData}
            setReOrderDetails={setReOrderDetails}
            setCount={setCount}
            count={count}
          />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <Title level={2}>My ReOrders</Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col>
          <Space>
            <Card
              title="TOTAL ENGAGEMENTS ENDING"
              bordered={false}
              style={{ backgroundColor: "#1A6AA8" }}
              className="order-data-card-body"
              headStyle={{ fontSize: "16px", fontWeight: "bold" }}
            >
              {reOrderDetails.total}
            </Card>
            <Card
              title="TOTAL REORDERED"
              bordered={false}
              style={{ backgroundColor: "#52c41a" }}
              className="order-data-card-body"
              headStyle={{ fontSize: "16px", fontWeight: "bold" }}
            >
              {reOrderDetails.reordered}
            </Card>
            <Card
              title="REMAINING"
              bordered={false}
              style={{ backgroundColor: "#AFEEEE" }}
              className="order-data-card-body"
              headStyle={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {reOrderDetails.remaining}
            </Card>
          </Space>
        </Col>
      </Row>
      <Table
        className="top-margin"
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={e => setPageNo(e.current)}
        pagination={{
          current: pageNo,
          showSizeChanger: false,
        }}
      />
    </>
  );
}

export default OrderData;
