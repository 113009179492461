import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getSalesReps, assignSalesRep } from "../service";
import { Button, message, Space, Select } from "antd";
const { Option } = Select;

const AssignSalesRep = ({ selectedKeys, updateUi }) => {
	const [isAssigning, setIsAssigning] = useState(false);
	const [dropdownData, setDropdownData] = useState(null);

	useEffect(() => {
		getSalesReps();
	}, []);

	const { salesReps, loading } = useSelector(state => ({
		salesReps: window.getValue(state, "leadmanagement.salesReps.userRole"),
		loading: window.getValue(state, "leadmanagement.salesRepsLoading"),
	}));

	const handleAssignSalesRep = async () => {
		setIsAssigning(true);

		const requests = [];

		if (!isEmpty(selectedKeys)) {
			selectedKeys.map(data => {
				const res = assignSalesRep(dropdownData, data);
				return requests.push(res);
			});

			Promise.all(requests)
				.then(response => {
					if (response.every(res => res.status === 200)) {
						setDropdownData(null);
						updateUi();
						message.success("Assigned Successfully");
					} else if (response.some(res => res.status === 400)) {
						setDropdownData(null);
						updateUi();
						message.warn(
							"One or more cases were found with orders and so sales reps could not be assigned. The page will refresh with the latest data."
						);
					} else {
						const filterRes = response.filter(res => {
							return res.status !== 200 && res.status !== 400;
						});

						filterRes[0]
							.json()
							.then(res => message.error(res.message))
							.catch(error => message.error(error.message));
					}
				})
				.catch(error => console.log(error.message))
				.finally(() => setIsAssigning(false));
		}
	};

	return (
		<Space size={0}>
			<Select
				showSearch
				style={{ width: 200 }}
				placeholder="Select a person"
				loading={loading}
				optionFilterProp="children"
				onChange={selectedRowKeys => setDropdownData(selectedRowKeys)}
				value={dropdownData}>
				{!isEmpty(salesReps) &&
					salesReps.map(users => (
						<Option value={users.user.id} key={users.user.id}>
							{users.user.first_name}
						</Option>
					))}
			</Select>

			<Button
				disabled={isEmpty(dropdownData) || isEmpty(selectedKeys)}
				loading={isAssigning}
				onClick={handleAssignSalesRep}
				type="primary">
				Assign
			</Button>
		</Space>
	);
};

export default AssignSalesRep;
