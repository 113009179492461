import React, { useState } from "react";
import { isEmpty } from "lodash";
import { getGlobalSearchResult } from "../service";
import { SearchOutlined } from "@ant-design/icons";
import {
	Space,
	Typography,
	Row,
	Col,
	Button,
	message,
	AutoComplete,
	Card,
} from "antd";

const { Option } = AutoComplete;
const { Meta } = Card;
const { Text, Title } = Typography;

function TransferSearch({
	searchValue,
	setSearchValue,
	searchData,
	setSearchData,
	selectedSearch,
	setSelectedSearch,
}) {
	const [isResponse, setIsResponse] = useState(false);
	const [isCloseDropdown, setIsCloseDropdown] = useState(false);

	const handleClear = () => {
		setSearchValue("");
		setSearchData([]);
		setSelectedSearch({});
	};

	const handleSearch = async event => {
		setSearchValue(event);
		if (isEmpty(event)) setIsCloseDropdown(false);
	};

	const handleClick = searchResult => {
		setSelectedSearch({ key: searchResult.case_id, ...searchResult });
	};

	const handleSubmit = async () => {
		setIsResponse(true);
		const response = await getGlobalSearchResult(searchValue);
		if (!isEmpty(response) && response !== "Word length is less than 4") {
			setSearchData(response);
			setIsResponse(false);
			setIsCloseDropdown(true);
		} else {
			message.error("No data availaible");
			setSearchData([]);
			setIsResponse(false);
		}
	};

	return (
		<>
			<Row>
				<Col span={19}>
					<AutoComplete
						defaultActiveFirstOption={false}
						filterOption={false}
						onSearch={handleSearch}
						placeholder="Search with name, phone, email or order display Id"
						notFoundContent={null}
						value={searchValue}
						open={isCloseDropdown}
						onSelect={() => setIsCloseDropdown(false)}
						showSearch
						style={{ width: "100%" }}
						suffixIcon={<SearchOutlined />}>
						{!isEmpty(searchData) &&
							searchData.map((searchResult, index) => (
								<Option key={index} value={searchValue}>
									<Card onClick={() => handleClick(searchResult)}>
										<Meta
											title={`${searchResult.first_name}`}
											description={
												<Space direction="vertical">
													{!isEmpty(searchResult.order_display_id) && (
														<Text>{searchResult.order_display_id}</Text>
													)}
													<Text>
														{searchResult.phone_number} {searchResult.email}
													</Text>
												</Space>
											}
										/>
									</Card>
								</Option>
							))}
					</AutoComplete>
				</Col>
				<Col span={4}>
					<Space>
						<Button
							style={{ marginLeft: "9px" }}
							icon={<SearchOutlined />}
							type="primary"
							onClick={handleSubmit}
							disabled={isEmpty(searchValue)}
							loading={isResponse}
						/>
						<Button onClick={handleClear} disabled={isEmpty(searchValue)}>
							Clear
						</Button>
					</Space>
				</Col>
			</Row>
			<Row>
				<Col
					style={{
						width: "100%",
						height: "20rem",
						padding: "1rem",
						border: "2px solid #f0f0f0",
						marginTop: "1rem",
					}}>
					<Row>
						<Col span={14}>
							<Space>
								<Title level={5}>Name: </Title>
								<Title level={5}>
									<span
										className="link"
										level={5}
										style={{ width: "230px" }}
										onClick={() =>
											window.open(
												`/lead-details/${selectedSearch?.case_id}`,
												"_blank"
											)
										}>
										{selectedSearch?.first_name}
									</span>
								</Title>
							</Space>
						</Col>
						<Col span={10}>
							<Space>
								<Title level={5}>Phone:</Title>
								<Title level={5}>{selectedSearch?.phone_number}</Title>
							</Space>
						</Col>
					</Row>
					<Row>
						<Col span={14}>
							<Space>
								<Title level={5}>Email: </Title>
								<Title level={5} style={{ width: "230px" }}>
									{selectedSearch?.email}
								</Title>
							</Space>
						</Col>
						<Col span={10}>
							<Space>
								<Title level={5}>Gender: </Title>
								<Title level={5}>{selectedSearch?.gender}</Title>
							</Space>
						</Col>
					</Row>
					<Row>
						<Col span={14}>
							<Space>
								<Title level={5}>Assigned To: </Title>
								<Title level={5}>{selectedSearch?.assigned_to}</Title>
							</Space>
						</Col>
						<Col span={10}>
							<Space>
								<Title level={5}>Stage: </Title>
								<Title level={5}>{selectedSearch?.stage}</Title>
							</Space>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}
export default TransferSearch;
