import {
	Button,
	Col,
	Radio,
	Row,
	Select,
	Space,
	Table,
	Typography,
} from 'antd/es';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { generateQuery, getQueryParams } from '../../../helpers';
import { getOadOrders, processOrders } from '../services';
import CsvDownload from './CSVDownload';
import { DateFilter } from './DateFilter';

const { Title } = Typography;

const B2BOrders = () => {
	const history = useHistory();
	const [orders, setOrders] = useState([]);
	const [selectedOrders, setSelectedOrders] = useState({});
	const [sd, setSd] = useState('');
	const [ed, setEd] = useState('');
	const [loading, setLoading] = useState(false);
	const [filters, setFilters] = useState({
		page: parseInt(getQueryParams('page')) || 1,
		oda_type: getQueryParams('oda_type') || null,
	});
	useEffect(() => {
		const filterItems = [{ ...filters }];
		history.push({ search: generateQuery(filterItems) });
	}, [filters, history]);

	useEffect(() => {
		if (getQueryParams('sd')) setSd(moment(getQueryParams('sd')));
		if (getQueryParams('ed')) setEd(moment(getQueryParams('ed')));
	}, []);

	const handleTableChange = (page, filter, sorter) => {
		const _filters = {
			...filters,
			page: page.current,
		};
		setFilters(_filters);
	};

	// const handleFilterChange = value => {
	// 	const _filters = {
	// 		...filters,
	// 		oda_type: value,
	// 	};
	// 	setFilters(_filters);
	// };

	const handleMarkShipped = (record, response) => {
		const _orders = {
			...selectedOrders,
			[record.orderId]: {
				processed: response,
			},
		};
		setSelectedOrders(_orders);
	};

	const handleShippingPartnerSelect = (record, response) => {
		const _orders = {
			...selectedOrders,
			[record.orderId]: {
				...selectedOrders[record.orderId],
				shippingPartner: response,
			},
		};
		setSelectedOrders(_orders);
	};

	const handleProcessOrders = async () => {
		await processOrders(selectedOrders, 'b2b');
		await _getB2BOrders(sd, ed);
	};

	const columns = [
		{
			title: 'Order Id',
			dataIndex: 'order_display_id',
			key: 'order_display_id',
		},
		{
			title: 'Order Date',
			dataIndex: 'createdAt',
			key: 'created_at',
			sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
			render: value => moment(value).format('DD-MM-YYYY'),
		},
		{
			title: 'Phone Number',
			dataIndex: 'phone_number',
			key: 'phone_number',
		},
		{
			title: 'Pin Code',
			dataIndex: 'order_meta',
			key: 'pin_code',
			render: value => value?.shipping_address?.zip || null,
		},
		{
			title: 'Order amount',
			dataIndex: 'order_meta',
			key: 'order_amount',
			render: value => value?.total_price || null,
		},
		{
			title: 'Order type',
			dataIndex: 'order_type',
			key: 'order_type',
			render: value => {
				if (value === 'pre_paid') return 'PREPAID';
				else return 'COD';
			},
		},
		{
			title: 'Processed',
			render: (_, record) => {
				return (
					<Radio.Group
						onChange={e => handleMarkShipped(record, e.target.value)}
						value={
							selectedOrders[record.orderId] &&
							selectedOrders[record.orderId].processed
						}>
						<Space direction="vertical">
							<Radio value={true}>Yes</Radio>
							<Radio value={false}>No</Radio>
						</Space>
					</Radio.Group>
				);
			},
		},
		{
			title: 'Shipping Partner',
			render: (_, record) => {
				return (
					<Select
						onChange={e => handleShippingPartnerSelect(record, e)}
						disabled={
							!(
								selectedOrders[record.orderId] &&
								selectedOrders[record.orderId].processed
							)
						}
						style={{ width: '100%' }}
						placeholder="Select"
						options={[
							{ label: 'Shipped', value: 'shipped' },
							{ label: 'Cancelled', value: 'cancelled' },
						]}
					/>
				);
			},
		},
	];

	const _getB2BOrders = useCallback(async (sd, ed) => {
		let _sd = null;
		let _ed = null;
		setLoading(true);
		if (!(!sd || sd === '' || ed === '' || !ed)) {
			_sd = moment(sd).toISOString();
			_ed = moment(ed).toISOString();
		}
		const res = await getOadOrders('b2b', null, _sd, _ed);
		setOrders(res);
		setLoading(false);
	}, []);

	const handleDateFilter = async (st, et) => {
		await _getB2BOrders(st, et);
	};

	useEffect(() => {
		_getB2BOrders(sd, ed);
	}, [_getB2BOrders, ed, sd]);

	return (
		<div>
			<Row>
				<Col span={19}>
					<Title level={3}>B2B Orders</Title>
				</Col>
				<Col span={4}>
					<CsvDownload data={orders} type="b2b" extraData={selectedOrders} />
				</Col>
			</Row>
			<Row justify="space-between">
				<DateFilter
					startDate={sd}
					endDate={ed}
					setStartDate={setSd}
					setEndDate={setEd}
					handleSubmit={handleDateFilter}
				/>
			</Row>
			<Row className="mt-4">
				<Table
					columns={columns}
					loading={loading}
					onChange={(page, filter, sort) =>
						handleTableChange(page, filter, sort)
					}
					dataSource={orders || []}
					style={{ width: '100%' }}
				/>
			</Row>
			<Row justify="end">
				<Button
					type="primary"
					onClick={handleProcessOrders}
					disabled={isEmpty(selectedOrders)}>
					Process Orders
				</Button>
			</Row>
		</div>
	);
};

export default B2BOrders;
