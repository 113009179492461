import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import {
  getSearchParams,
  generateQuery,
  getQueryParams,
  getValue,
} from "../../helpers";
import queryParamsForApi from "../../helpers/queryParamsForApi";
import { getPendingConsult } from "./service";

// And Design
import { Col, Row, Table, Tag, Typography } from "antd";
const { Title, Text } = Typography;

export const BookedConsultCall = () => {
  const history = useHistory();
  const [pageNo, setPageNo] = useState(parseInt(getQueryParams("bct")) || 1);
  const [queryParams, setQueryParams] = useState(getSearchParams);
  const [apiRes, setApiRes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const filterItems = [{ bcps: 5, bct: pageNo }];
    setQueryParams(() => generateQuery(filterItems));
  }, [pageNo]);

  const fetchPendingCalls = useCallback(async () => {
    history.push({ search: queryParams });

    if (isEmpty(queryParams)) setApiRes([]);
    else {
      setLoading(true);
      const paramsForAPI = queryParamsForApi(renameQueryForAPI);

      try {
        const _res = await getPendingConsult(paramsForAPI);
        setApiRes(_res);
      } catch (error) {
        console.warn(error);
      } finally {
        setLoading(false);
      }
    }
  }, [history, queryParams]);

  useEffect(() => {
    fetchPendingCalls();
  }, [fetchPendingCalls]);

  const myBookings = useMemo(() => {
    if (isEmpty(apiRes)) return { data: [], rowCount: 0 };
    const _data = apiRes.data?.map((item) => ({
      key: getValue(item, "id"),
      name: getValue(item, "user.first_name"),
      phoneno: getValue(item, "user.phone_number"),
      email: getValue(item, "user.email"),
      tag: "consult_booking",
      time: getValue(item, "consult_call_due_date"),
    }));

    return { data: _data, rowCount: apiRes.pagination?.rowCount };
  }, [apiRes]);

  const column = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, { key, phoneno }) => (
        <div className="flex flex-col">
          <Link className="link" to={`/lead-details/${key}`}>
            {name}
          </Link>
          <Text type="secondary">{phoneno}</Text>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Tag",
      dataIndex: "tag",
      key: "tag",
      render: (tag) => {
        if (isEmpty(tag)) return "-";
        return (
          <Tag key={tag} color="geekblue">
            {tag.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      defaultSortOrder: false,
      render: (time) => moment(time).format("DD MMM YYYY, h:mm a"),
    },
  ];

  return (
    <Row>
      <Col>
        <Title level={4}>Consult Call Bookings</Title>
      </Col>

      <Col span={24}>
        <Table
          columns={column}
          dataSource={myBookings.data}
          loading={loading}
          onChange={(e) => setPageNo(e.current)}
          pagination={{
            current: pageNo,
            showSizeChanger: false,
            pageSize: 5,
            total: myBookings.rowCount,
          }}
        />
      </Col>
    </Row>
  );
};

const renameQueryForAPI = {
  bct: "pageNumber",
  bcps: "pageSize",
};
