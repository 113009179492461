export const getCurrentUserRole = () => {
	switch (localStorage.getItem("role")) {
		case "00000000-0000-0000-0000-000000000001":
			return "admin";
		case "00000000-0000-0000-0000-000000000002":
			return "medical_rep";
		case "00000000-0000-0000-0000-000000000003":
			return "sales";
		case "00000000-0000-0000-0000-000000000004":
			return "doctor";
		case "00000000-0000-0000-0000-000000000006":
			return "dispatch";
		case "00000000-0000-0000-0000-000000000007":
			return "supervisor";
		case "00000000-0000-0000-0000-000000000008":
			return "support";
		case "00000000-0000-0000-0000-000000000009":
			return "read_only";
		case "00000000-0000-0000-0000-000000000011":
			return "consult_rep";
		case "00000000-0000-0000-0000-000000000012":
			return "consult_doctor";
		default:
			return null;
	}
};
