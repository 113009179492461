import Cookies from "js-cookie";
import { doctorActions, prescriptionActions } from "./actions";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";
import { message } from "antd";

export const getAssignedPatients = async query => {
	store.dispatch({
		type: doctorActions.GET_ASSIGNED_ORDERS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}assigned_patients?${query}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: doctorActions.GET_ASSIGNED_ORDERS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: doctorActions.GET_ASSIGNED_ORDERS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: doctorActions.GET_ASSIGNED_ORDERS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getPrescribedPatients = async query => {
	store.dispatch({
		type: doctorActions.GET_PRESCRIBED_PATIENTS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}prescribed_patients?${query}`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: doctorActions.GET_PRESCRIBED_PATIENTS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: doctorActions.GET_PRESCRIBED_PATIENTS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: doctorActions.GET_PRESCRIBED_PATIENTS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const getDoctorsList = () => {
	store.dispatch({
		type: doctorActions.GET_DOCTORS_LIST_LOADING,
		payload: true,
	});

	return fetch(`${baseURL.API_URL_BASE}role_doctors?is_unified_ticketing=true`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		if (res.status === 200) {
			res
				.json()
				.then(response => {
					if (response) {
						store.dispatch({
							type: doctorActions.GET_DOCTORS_LIST_SUCCESS,
							payload: response,
						});
					} else {
						store.dispatch({
							type: doctorActions.GET_DOCTORS_LIST_FAILURE,
							payload: response,
						});
					}
				})
				.catch(error => {
					store.dispatch({
						type: doctorActions.GET_DOCTORS_LIST_FAILURE,
						payload: error.toString(),
					});
					handleError(error);
				});
		} else {
			return handleError(res);
		}
	});
};

export const assignDoctor = async (order_id, user_id) => {
	store.dispatch({
		type: doctorActions.UPDATE_ASSIGNED_DOCTOR_LOADING,
		payload: true,
	});

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}assign_doctor`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ order_id, user_id }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (_res.status === 200) {
			store.dispatch({
				type: doctorActions.UPDATE_ASSIGNED_DOCTOR_SUCCESS,
				payload: false,
			});
			message.success("Assigned Successfully");
		} else {
			store.dispatch({
				type: doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE,
				payload: false,
			});
			message.error("Failed");
			handleError(_res);
		}

		return _res;
	} catch (error) {
		store.dispatch({
			type: doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE,
			payload: false,
		});
		console.warn(error.message);
	}
};

export const unAssignDoctor = async (order_id, doctor_id) => {
	store.dispatch({
		type: doctorActions.UPDATE_ASSIGNED_DOCTOR_LOADING,
		payload: true,
	});

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}unassign_doctor`, {
			method: "DELETE",
			json: true,
			body: JSON.stringify({ order_id, doctor_id }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (_res.status === 200) {
			store.dispatch({
				type: doctorActions.UPDATE_ASSIGNED_DOCTOR_SUCCESS,
				payload: false,
			});
			message.success("Unassigned Successfully");
		} else {
			const _data = await _res.json();
			message.error(_data.message);

			store.dispatch({
				type: doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE,
				payload: false,
			});
			handleError(_res);
		}

		return _res;
	} catch (error) {
		store.dispatch({
			type: doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE,
			payload: false,
		});
		console.warn(error.message);
	}
};

export const preAssignDoctor = async order_id => {
	store.dispatch({
		type: doctorActions.UPDATE_ASSIGNED_DOCTOR_LOADING,
		payload: true,
	});

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}auto_assign_doctor`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ order_id }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});

		if (_res.status === 200) {
			store.dispatch({
				type: doctorActions.UPDATE_ASSIGNED_DOCTOR_SUCCESS,
				payload: false,
			});
			message.success("Doctors Auto Assigned Successfully");
		} else {
			store.dispatch({
				type: doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE,
				payload: false,
			});
			message.error("Failed");
			handleError(_res);
		}

		return _res;
	} catch (error) {
		store.dispatch({
			type: doctorActions.UPDATE_ASSIGNED_DOCTOR_FAILURE,
			payload: false,
		});
		console.warn(error.message);
	}
};

export const postPrescription = async (caseId, order_id, prescription) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}prescription/${order_id}`, {
			method: "POST",
			json: true,
			body: JSON.stringify({ version: "2", prescription }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) getPrescription(caseId);
		else handleError(res);
		return res;
	} catch (error) {
		message.error(error.message);
	}
};

export const editPrescription = async (caseId, id, prescription) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}prescription/${id}`, {
			method: "PUT",
			json: true,
			body: JSON.stringify({ version: "2", prescription }),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) await getPrescription(caseId);
		else handleError(res);
		return res;
	} catch (error) {
		message.error(error.message);
	}
};

export const getAssignedDoctors = orderId => {
	store.dispatch({
		type: doctorActions.GET_ASSIGNED_DOCTORS_LOADING,
		payload: true,
	});

	return fetch(`${baseURL.API_URL_BASE}assigned_doctors/${orderId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		if (res.status === 200) {
			res
				.json()
				.then(response => {
					if (response) {
						store.dispatch({
							type: doctorActions.GET_ASSIGNED_DOCTORS_SUCCESS,
							payload: response,
						});
					} else {
						store.dispatch({
							type: doctorActions.GET_ASSIGNED_DOCTORS_FAILURE,
							payload: response,
						});
					}
				})
				.catch(error => {
					store.dispatch({
						type: doctorActions.GET_ASSIGNED_DOCTORS_FAILURE,
						payload: error.toString(),
					});
					handleError(error);
				});
		} else {
			return handleError(res);
		}
	});
};

export const getPrescription = async case_id => {
	store.dispatch({
		type: prescriptionActions.GET_PRESCRIPTION_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}prescription/${case_id}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: prescriptionActions.GET_PRESCRIPTION_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: prescriptionActions.GET_PRESCRIPTION_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: prescriptionActions.GET_PRESCRIPTION_FAILURE,
			payload: error.message,
		});
	}
};

export const deletePrescription = async (caseId, prescription_id) => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}prescription/${prescription_id}`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			message.success("Prescription deleted Successfully");
			await getPrescription(caseId);
		} else {
			message.error("Failed to delete Prescription");
			handleError(res);
		}
	} catch (error) {
		message.error(error.message);
	}
};

export const getOrderItems = async order_id => {
	store.dispatch({
		type: prescriptionActions.GET_ORDER_ITEMS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}v2/dosage/${order_id}`, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: prescriptionActions.GET_ORDER_ITEMS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: prescriptionActions.GET_ORDER_ITEMS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: prescriptionActions.GET_ORDER_ITEMS_FAILURE,
			payload: error.message,
		});
	}
};

export const clearOrderItems = () => {
	store.dispatch({
		type: prescriptionActions.GET_ORDER_ITEMS_SUCCESS,
		payload: null,
	});
};
