import React, { useEffect, useState } from "react";
import { groupBy, isEmpty } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CreatePrescription } from ".";
import { deletePrescription } from "../../doctors/service";
import { Loader } from "../../../common";
import { getCurrentUserRole } from "../../../helpers";
import {
	Button,
	Card,
	Col,
	Empty,
	List,
	Popconfirm,
	Row,
	Space,
	Table,
	Typography,
} from "antd";
const { Text } = Typography;

function PrescriptionStatus() {
	const location = useLocation();
	const [caseId] = useState(location.state.caseId);
	const [orderId] = useState(location.state.order.key);
	const [orderStatus] = useState(location.state.order.status);
	const [data, setData] = useState([]);
	const [showEditUI, setShowEditUI] = useState(false);
	const [prescriptionData, setPrescriptionData] = useState([]);
	const [prescriptionId, setPrescriptionId] = useState("");
	const [loading, setLoading] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState("");
	const [userRole] = useState(getCurrentUserRole());
	const [canNotEdit] = useState(["delivered", "shipped", "void"]);

	const { prescriptions, prescriptionLoading } = useSelector(state => ({
		prescriptions: window.getValue(state, "doctorsData.prescriptions"),
		prescriptionLoading: window.getValue(
			state,
			"doctorsData.prescriptionLoading"
		),
	}));

	useEffect(() => {
		const init = async () => {
			const ndata = await getTableData(prescriptions, orderId);
			setData([...ndata]);
		};

		if (isEmpty(prescriptions)) setData([]);
		else init();
	}, [prescriptions, orderId]);

	const handleDeletePrescription = async (caseId, itemId) => {
		setLoading(true);
		setDeleteItemId(itemId);
		await deletePrescription(caseId, itemId);
		setLoading(false);
	};

	const columns = [
		{
			title: "Medicine",
			key: "Medicine",
			width: "40%",
			render: record => (
				<Space direction="vertical">
					<Text>{record.Medicine}</Text>
					<Text type="secondary">
						{record.showRecommendation && `(Recommended by doctor)`}
					</Text>
				</Space>
			),
		},
		{
			title: "Dosage",
			key: "Dosage",
			width: "30%",
			render: record => (
				<Space direction="vertical">
					<Text>{record.Dosage}</Text>
					<Text>{record.composition}</Text>
				</Space>
			),
		},
		{
			title: "Note",
			dataIndex: "note",
			key: "note",
			width: "30%",
		},
	];

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			{!isEmpty(data) ? (
				data.map(item => (
					<Card
						bordered
						hoverable
						key={item.key}
						title={item.user_name}
						extra={
							!isEmpty(item.prescriptionv2) &&
							!showEditUI &&
							rolesCanEdit.has(userRole) && (
								<Space>
									<Popconfirm
										disabled={canNotEdit.includes(orderStatus)}
										title="Are you sure to delete this prescription ?"
										placement="topRight"
										onConfirm={() => handleDeletePrescription(caseId, item.id)}>
										<Button
											danger
											disabled={canNotEdit.includes(orderStatus)}
											loading={loading && deleteItemId === item.id}>
											Delete
										</Button>
									</Popconfirm>
									<Button
										disabled={canNotEdit.includes(orderStatus)}
										onClick={() => {
											setPrescriptionData(item.prescriptionv2);
											setPrescriptionId(item.id);
											setShowEditUI(true);
										}}
										type="default">
										Edit
									</Button>
								</Space>
							)
						}>
						{showEditUI && item.id === prescriptionId ? (
							<CreatePrescription
								data={prescriptionData}
								isEdit={true}
								prescriptionId={prescriptionId}
								setData={setPrescriptionData}
								setShowUI={setShowEditUI}
							/>
						) : (
							!isEmpty(item.prescriptionv2) && (
								<Table
									loading={prescriptionLoading}
									columns={columns}
									dataSource={sortByIsRecommended(item.prescriptionv2)}
									pagination={false}
								/>
							)
						)}
						{!isEmpty(item.prescriptionv1) && (
							<List
								dataSource={item.prescriptionv1}
								renderItem={item => <List.Item>{item}</List.Item>}
								style={{ marginBottom: "2rem" }}
							/>
						)}
					</Card>
				))
			) : (
				<>
					{prescriptionLoading ? (
						<Loader />
					) : (
						<Row justify="center">
							<Col>
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							</Col>
						</Row>
					)}
				</>
			)}
		</Space>
	);
}

export default PrescriptionStatus;

const rolesCanEdit = new Set(["consult_rep", "medical_rep", "admin"]);

const sortByIsRecommended = items =>
	items.sort((a, b) => a.showRecommendation - b.showRecommendation);

const getTableData = async (prescriptions, orderId) => {
	let ndata = [];

	const filterData = await prescriptions.filter(i => i.order_id === orderId);
	const groupData = await groupBy(filterData, i => i.user_id);

	for (let key in groupData) {
		const item = groupData[key];
		const obj = {};

		obj["key"] = key;
		obj["user_name"] = item[0].user.first_name;
		obj["date"] = item[0].updated_at;
		obj["id"] = item[0].id;

		switch (item[0].version) {
			case 1: {
				const prescription = item[0].prescription;
				obj["prescriptionv1"] = [prescription];
				break;
			}

			case 2: {
				const prescription = JSON.parse(item[0].prescription).prescription;
				obj["prescriptionv2"] = [...prescription];
				break;
			}

			default:
				obj = {};
				break;
		}
		ndata = [...ndata, obj];
	}
	return await ndata.sort((a, b) => moment(b.date) - moment(a.date));
};
