import React from "react";
import { useParams } from "react-router-dom";
import MedRepEngagements from "../components/dashboard/medrep/MedRepEngagements";

const TodaysEngagementByMedRep = () => {
	const { id } = useParams();
	return <MedRepEngagements medRepId={id} showCreateTicket={false} />;
};

export default TodaysEngagementByMedRep;
