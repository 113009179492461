import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { MY_ORDERS } from "../../constants/apiUrls";
import { generateQuery, getQueryParams, getValue } from "../../helpers";
import { fetchRequest } from "../../helpers/fetchRequest";

// Antd
import { Col, Row, Table, Tag, Typography } from "antd/es";
import Filters from "./Filters";
const { Text, Title } = Typography;

const SalesMyOrders = () => {
	const history = useHistory();
	const [orders, setOrders] = useState({});
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(null);
	const [loading, setLoading] = useState(false);
	const [pageNo, setPageNo] = useState(
		Number(getQueryParams("pageNumber")) || 1
	);

	const fetchMyOrders = useCallback(async query => {
		let res = {};

		try {
			setLoading(true);
			res = await fetchRequest(MY_ORDERS(query));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) {
				setOrders([]);
				setPaginationInfo({});
			} else {
				setOrders(res.data.data);
				setPaginationInfo(res.data.pagination);
			}
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		if (!queryParams) return;
		fetchMyOrders(queryParams);
	}, [fetchMyOrders, queryParams]);

	useEffect(() => {
		const filterItems = [
			{
				pageNumber: pageNo,
				startDate: getQueryParams("startDate") || moment().format("YYYY-MM-DD"),
				endDate: getQueryParams("endDate") || moment().format("YYYY-MM-DD"),
			},
		];
		const searchParams = generateQuery(filterItems);
		setQueryParams(searchParams);
	}, [history, pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });
	}, [history, queryParams]);

	const tableData = useMemo(() => {
		if (!Array.isArray(orders)) return [];

		return orders.map(item => ({
			key: getValue(item, "id"),
			caseId: getValue(item, "case_id"),
			deliveryDate: getValue(item, "delivery_date"),
			name: getValue(item, "user.first_name"),
			phoneNo: getValue(item, "user.phone_number"),
			orderDisplayId: getValue(item, "order_display_id"),
			createdAt: getValue(item, "created_at"),
			status: getValue(item, "status"),
			hairCoach: getValue(item, "assignment.first_name"),
		}));
	}, [orders]);

	const columns = [
		{
			title: "Name",
			key: "name",
			render: ({ caseId, name, phoneNo }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${caseId}`}>
						{name}
					</Link>
					<Text type="secondary">{phoneNo}</Text>
				</div>
			),
		},
		{
			title: "Order ID",
			dataIndex: "orderDisplayId",
			key: "orderDisplayId",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status, { deliveryDate }) => (
				<Tag key={status} color="geekblue">
					{status && status.toUpperCase()} {""}
					{status === "delivered" &&
						deliveryDate &&
						moment(deliveryDate).format("DD-MMM-YYYY")}
				</Tag>
			),
		},
		{
			title: "Order Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: createdAt => moment(createdAt).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Hair Coach",
			dataIndex: "hairCoach",
			key: "hairCoach",
		},
	];

	return (
		<>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>My Orders</Title>
				</Col>

				<Col>
					<Filters setPageNo={setPageNo} setQueryParams={setQueryParams} />
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						columns={columns}
						dataSource={tableData}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

export default SalesMyOrders;
