import { Col, Divider, Row, Typography } from "antd/es";
import React from "react";
import BlockedCustomers from "./blocked-customers";
import NonServicableCombinations from "./non-servicable-combinations";
import ServiceablePincodes from "./serviceable-pincodes";
import SettingsTable from "./settings-table";

const { Title } = Typography;

const SettingsPage = () => {
	return (
		<div className="flex flex-col gap-2">
			<Title level={2}>Settings</Title>

			<Row gutter={[8, 8]} align="middle" justify="center">
				<Col span={12}>
					<ServiceablePincodes />
				</Col>
			</Row>
			<Row gutter={[8, 8]} align="middle" justify="center">
				<Col span={12}>
					<NonServicableCombinations />
				</Col>
			</Row>
			<Row gutter={[8, 8]} align="middle" justify="center">
				<Col span={12}>
					<BlockedCustomers />
				</Col>
			</Row>

			{/* <Row gutter={[8, 8]} align="middle" justify="center">
        <Divider />
        <Col span={24}>
          <SettingsTable />
        </Col>
      </Row> */}
		</div>
	);
};

export default SettingsPage;
