import { Col, Row, Table, Tag, Typography } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getLineItemsName, getQueryParams, getValue } from "../../../helpers";
import { getRecentOrders } from "../service";
import AssignMedicalRep from "./AssignMedicalRep";
const { Text, Title } = Typography;

function RecentOrders() {
	const history = useHistory();
	const [paginationInfo, setPaginationInfo] = useState({});
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [data, setData] = useState([]);
	const [pageNo, setPageNo] = useState(
		parseInt(getQueryParams("current")) || 1
	);

	useEffect(() => {
		getRecentOrders(pageNo);
	}, [pageNo]);

	useEffect(() => {
		if (pageNo > paginationInfo.pageCount && paginationInfo.pageCount > 1) {
			history.push({
				search: `current=${paginationInfo.pageCount}`,
			});
			setPageNo(paginationInfo.pageCount);
		}
	}, [pageNo, history, paginationInfo]);

	const { recentOrders, loading } = useSelector(state => ({
		recentOrders: getValue(state, "orderManagement.recentOrders"),
		loading: getValue(state, "orderManagement.recentOrdersLoading"),
	}));

	useEffect(() => {
		if (isEmpty(recentOrders)) setData([]);
		else {
			const ndata = recentOrders.data.map(row => ({
				key: getValue(row, "id"),
				case_id: getValue(row, "case_id"),
				name: getValue(row, "case.user.first_name"),
				phone_Number: getValue(row, "case.user.phone_number"),
				orderDisplayId: getValue(row, "order_display_id"),
				orderName: getLineItemsName(row.order_meta?.line_items),
				orderStatus: getValue(row, "status"),
				orderDate: getValue(row, "created_at"),
				count: getValue(row, "count"),
				order_type: getValue(row, "order_type"),
				sub_status: getValue(row, "sub_status"),
			}));
			setData(ndata);
			setPaginationInfo(recentOrders.pagination);
		}
	}, [recentOrders]);

	useEffect(() => {
		if (!isEmpty(allSelectedOrders)) {
			let currPageData = [];
			let currPageKeys = [];
			let otherPageData = [];
			allSelectedOrders.map(row => {
				if (data.some(i => i.key === row.key)) {
					currPageData = [...currPageData, row];
					currPageKeys = [...currPageKeys, row.key];
					return currPageKeys;
				} else {
					return (otherPageData = [...otherPageData, row]);
				}
			});
			setSelectedKeys(currPageKeys);
			setSelectionFromOtherPage(otherPageData);
		}
	}, [allSelectedOrders, data]);

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectionFromOtherPage, ...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	const updateUi = () => {
		getRecentOrders(pageNo);
		setAllSelectedOrders([]);
		setSelectedKeys([]);
		setSelectionFromOtherPage([]);
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: 180,
			render: (name, { case_id, phone_Number }) => {
				return (
					<div className="flex flex-col">
						<Link className="link" to={`/lead-details/${case_id}`}>
							{name}
						</Link>
						<Text type="secondary">{phone_Number}</Text>
					</div>
				);
			},
		},
		{
			title: "Order ID",
			dataIndex: "orderDisplayId",
			key: "orderDisplayId",
			width: 96,
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},
		{
			title: "Order Name",
			dataIndex: "orderName",
			key: "orderName",
			width: 240,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "orderStatus",
			key: "orderStatus",
			width: 112,
			render: (status, record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "orderDate",
			render: orderDate => moment(orderDate).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<>
			<Row>
				<Col>
					<AssignMedicalRep
						selectedOrders={allSelectedOrders}
						updateUi={updateUi}
					/>
				</Col>
			</Row>
			<Row className="top-margin" justify="space-between">
				<Col>
					<Title level={2}>Recent Orders</Title>
				</Col>
			</Row>
			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				rowSelection={rowSelection}
				pagination={{
					current: pageNo,
					pageSize: 10,
					showSizeChanger: false,
					total: paginationInfo.rowCount,
				}}
				onChange={e => {
					setPageNo(e.current);
					return history.push({
						search: `current=${e.current}`,
					});
				}}
			/>
		</>
	);
}

export default RecentOrders;
