import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { resetPassword } from "../service";
import { useHistory, useParams } from "react-router-dom";
import { baseURL } from "../../../helpers";
import trayaLogo from "../../../assets/traya@2x.png";

function ResetPassword() {
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      json: true,
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(
      `${baseURL.API_URL_BASE}verifyResetToken/${params.token}`,
      requestOptions
    )
      .then(response => {
        if (response.status === 200) {
        } else {
          message.error(
            "Password reset link no longer valid, please try again or contact administrator",
            1,
            () => history.push(`/`)
          );
        }
      })
      .catch(error => {
        console.log(error.toString());
      });
  }, [params.token, history]);

  const onFinish = async password => {
    const details = await resetPassword(password, params.token);

    if (details.status === 200) {
      message.success(
        "Password changed, please use the new password to login",
        1,
        () => history.push(`/`)
      );
    } else if (details.status === 400) {
      message.error(
        "Password reset link no longer valid, please try again or contact administrator"
      );
    } else {
      message.error("Network error");
    }
  };

  return (
    <Row align="middle" justify="center" style={{ minHeight: "90vh" }}>
      <Col span={6} className="form-login-container">
        <div className="ant-row ant-form-item">
          <Row align="middle" justify="center" className="logo-container">
            <img
              src={trayaLogo}
              width="60"
              alt="Logo"
              style={{ justifyContent: "center" }}
            />
          </Row>
        </div>

        <Form
          name="basic"
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please enter your password" },
              {
                pattern: new RegExp(/^\S+$/),
                message: "Enter password without space",
              },
            ]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <Form.Item className="align-center">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default ResetPassword;
