import { Button, DatePicker, Form, Row, Table, Tag, Typography } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { generateQuery, getLineItemsName } from "../../../helpers";
import { getQueryParams } from "../../../helpers/getQueryParams";
import { getPrescribedPatients } from "../service";
const { Title, Text } = Typography;

function PrescribedPatients() {
	const history = useHistory();
	const [form] = Form.useForm();
	const [data, setData] = useState([]);
	const [patientsList, setPatientsList] = useState([]);

	const [paginationInfo, setPaginationInfo] = useState({});
	const [startDate, setStartDate] = useState(
		getQueryParams("startDate") || moment().startOf("M")
	);
	const [endDate, setEndDate] = useState(
		getQueryParams("endDate") || moment().endOf("M")
	);
	const [query, setQuery] = useState("");
	const [pageNo, setPageNo] = useState(parseInt(getQueryParams("pageNo")) || 1);

	useEffect(() => {
		let queryItems = [];

		if (startDate && endDate && pageNo) {
			queryItems.push({ startDate: moment(startDate).toISOString() });
			queryItems.push({ endDate: moment(endDate).toISOString() });
			queryItems.push({ pageNo: pageNo });

			const _query = generateQuery(queryItems);
			history.push({ search: _query });
			getPrescribedPatients(_query);
		}
	}, [pageNo, query]);

	const { loading, prescribedPatients } = useSelector(state => ({
		loading: window.getValue(state, "doctorsData.prescribedPatientsLoading"),
		prescribedPatients: window.getValue(
			state,
			"doctorsData.prescribedPatientsData"
		),
	}));

	useMemo(() => {
		if (!isEmpty(prescribedPatients)) {
			setPatientsList(prescribedPatients.data);
			setPaginationInfo(prescribedPatients.pagination);
		}
	}, [prescribedPatients]);

	const onSubmit = async items => {
		const filterItems = [];

		Object.keys(items).map(key => {
			switch (key) {
				case "startDate":
					filterItems.push({
						[key]: startDate
							? moment(startDate).startOf("day").toISOString()
							: null,
					});
					break;

				case "endDate":
					filterItems.push({
						[key]: endDate ? moment(endDate).endOf("day").toISOString() : null,
					});
					break;

				default:
					break;
			}
		});
		filterItems.push({ pageNo: pageNo });
		const _query = generateQuery(filterItems);
		history.push({ search: _query });
		setQuery(_query);
		return;
	};
	const formInitialValues = {
		startDate: startDate ? moment(startDate) : moment().startOf("M"),
		endDate: endDate ? moment(endDate) : moment().endOf("M"),
	};

	useEffect(() => {
		if (patientsList) {
			const data = patientsList.map(row => ({
				key: window.getValue(row, "id"),
				case_id: window.getValue(row, "order.case.id"),
				name: window.getValue(row, "order.case.user.first_name"),
				order_display_id: window.getValue(row, "order.order_display_id"),
				order_name: getLineItemsName(row.order?.order_meta?.line_items),
				order_status: window.getValue(row, "order.status"),
				order_date: window.getValue(row, "order.created_at"),
				assigned_date: window.getValue(row, "created_at"),
				count: window.getValue(row, "order.count"),
				sub_status: window.getValue(row, "sub_status"),
			}));

			setData(data);
		}
	}, [patientsList]);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text, record) => {
				return (
					<span
						className="link"
						onClick={() =>
							history.push(`/lead-details/${record.case_id}?current=${pageNo}`)
						}>
						{text}
					</span>
				);
			},
		},
		{
			title: "Order ID",
			dataIndex: "order_display_id",
			key: "order_display_id",
			width: 96,
		},
		{
			title: "Order Name",
			dataIndex: "order_name",
			key: "order_name",
			width: 310,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "order_status",
			key: "order_status",
			render: (status, record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "order_date",
			key: "order_date",
			render: order_date => moment(order_date).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned on",
			dataIndex: "assigned_date",
			key: "assigned_date",
			render: assigned_date =>
				moment(assigned_date).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<>
			<Title className="top-margin" level={2}>
				Prescribed Patients
			</Title>

			<Row align="middle" justify="space-between">
				<Form
					form={form}
					initialValues={formInitialValues}
					layout="inline"
					onFinish={onSubmit}>
					<Form.Item name="startDate">
						<DatePicker
							allowClear
							disabledDate={current =>
								endDate
									? current.isAfter(moment(endDate), "day")
									: current.isAfter(moment(), "day")
							}
							format={"YYYY-MM-DD"}
							id="start_date_input"
							onChange={e => (e ? setStartDate(e) : setStartDate(null))}
							placeholder="Start Date"
							style={{ width: 176 }}
						/>
					</Form.Item>

					<Form.Item name="endDate">
						<DatePicker
							allowClear
							disabledDate={current =>
								(startDate && current.isBefore(moment(startDate), "day")) ||
								current.isAfter(moment(), "day")
							}
							format={"YYYY-MM-DD"}
							id="end_date_input"
							onChange={e => (e ? setEndDate(e) : setEndDate(null))}
							placeholder="End Date"
							style={{ width: 176 }}
						/>
					</Form.Item>
					<Form.Item>
						<Button
							id="generate_report_btn"
							loading={loading}
							type="primary"
							htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
				<Button
					style={{
						textAlign: "center",
						background: "#4472c4",
						color: "white",
						fontSize: 18,
						fontWeight: "bold",
						width: "15%",
						height: 40,
					}}
					loading={loading}>
					<Text strong style={{ color: "white" }}>
						Count : {paginationInfo.rowCount}
					</Text>
				</Button>
			</Row>
			<br />
			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				pagination={{
					current: pageNo,
					pageSize: 10,
					showSizeChanger: false,
					total: paginationInfo.rowCount,
				}}
				onChange={e => {
					setPageNo(e.current);
				}}
			/>
		</>
	);
}

export default PrescribedPatients;
