import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import TodaysReminder from "./TodaysReminder";
import UnansweredLeads from "../../leadManagement/components/UnansweredLeads";
import UntouchedLeads from "../../leadManagement/components/UntouchedLeads";
import SlotBookings from "../../leadManagement/components/SlotBookings";
import TodaysTasks from "../../../components/dashboard/TodaysTasks";
import MonthlyTarget from "../../../components/dashboard/MonthlyTarget";
import OverdueTask from "../../../components/dashboard/OverdueTask";
import TodaysBookings from "../../../components/dashboard/salesRep/TodaysBookings";
import CreateTicket from "../../ticketing/components/CreateTicket";

// Ant Design
import { Col, Row, Tabs, Typography } from "antd";
const { TabPane } = Tabs;
const { Title } = Typography;

const DashboardSales = () => {
	const history = useHistory();
	const [currTab, setCurrTab] = useState("home");

	const handleTabChange = key => {
		history.push({ search: "" });
		setCurrTab(key);
	};

	return (
		<Tabs onChange={handleTabChange} type="card">
			<TabPane tab="Home" key="home">
				<div className="flex justify-end mb-4">
					{/* <CreateTicket /> */}
				</div>

				<div className="grid grid-cols-1 gap-2">
					<div className="grid gap-2 grid-cols-2">
						<TodaysTasks />
						<MonthlyTarget />
					</div>

					<div className="grid gap-2 grid-cols-2 mt-4">
						<TodaysBookings tab={currTab} />
						<OverdueTask />
					</div>
				</div>
			</TabPane>

			<TabPane tab="Leads" key="leads">
				<SlotBookings />

				<Row gutter={[8, 8]} style={{ marginTop: 16 }}>
					<Col span={12}>
						<UntouchedLeads />
					</Col>

					<Col span={12}>
						<UnansweredLeads />
					</Col>
				</Row>

				<Row style={{ marginTop: 16 }}>
					<Col span={24}>
						<Title level={4}>Today's Reminders</Title>
						<TodaysReminder />
					</Col>
				</Row>
			</TabPane>
		</Tabs>
	);
};

export default DashboardSales;
