import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../common/Loader";
import { useSelector } from "react-redux";
import { getValue } from "../../helpers";
import { getConsultCallFeedback } from "../../pages/leadDetails/service";
import rawQuestions from "../../configs/consultCallQuestionnaire.json";

import { Empty, Typography } from "antd";
const { Text, Title } = Typography;

const ConsultCallFormResponse = () => {
	const { id: case_id } = useParams();

	useEffect(() => {
		getConsultCallFeedback(case_id);
	}, [case_id]);

	const { feedbacks, loading } = useSelector(state => ({
		feedbacks: getValue(state, "leaddetails.consultCallFeedback"),
		loading: getValue(state, "leaddetails.consultCallFeedbackLoading"),
	}));

	if (loading) return <Loader />;

	if (!feedbacks)
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={<span>No Data</span>}
			/>
		);

	return rawQuestions.map(({ id, text }) => (
		<React.Fragment key={id}>
			<Title level={5}>{text}</Title>
			<Text>{feedbacks[text]}</Text>
		</React.Fragment>
	));
};

export default ConsultCallFormResponse;
