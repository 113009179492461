import {
	ASSIGNED_ORDER_API,
	getUrl,
	MEDREP_TEMPORARY_ENGAGEMENTS_API,
	MED_REP_ENGAGEMENTS_API,
	RAW_ENGAGEMENTS_API,
	REP_REMINDERS_API,
} from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { ACTIONS } from "./store";

export const fetchDataByDate = async (medrep, sd, ed, dispatch) => {
	dispatch({ type: ACTIONS.LOADING, payload: true });

	let [reminders, assignedOrders, rawEngagements] = await Promise.allSettled([
		fetchRequest(getUrl(`raw_reminders/${medrep}?start=${sd}&end=${ed}`)),
		fetchRequest(ASSIGNED_ORDER_API(medrep, `startDate=${sd}&endDate=${ed}`)),
		fetchRequest(RAW_ENGAGEMENTS_API(medrep, sd, ed)),
	]);

	reminders = reminders.value ? reminders.value.data : [];
	assignedOrders = assignedOrders.value ? assignedOrders.value.data : [];
	rawEngagements = rawEngagements.value ? rawEngagements.value.data : [];

	dispatch({
		type: ACTIONS.UPDATE,
		payload: { reminders, assignedOrders, rawEngagements, loading: false },
	});
};

export const fetchTempData = async (medrep, dispatch) => {
	dispatch({ type: ACTIONS.LOADING, payload: true });

	let [tempEngagements, tempReminders] = await Promise.allSettled([
		fetchRequest(MEDREP_TEMPORARY_ENGAGEMENTS_API(medrep)),
		fetchRequest(getUrl(`v2/medrep_temporary_reminders/${medrep}`)),
	]);

	tempEngagements = tempEngagements.value ? tempEngagements.value.data : [];
	tempReminders = tempReminders.value ? tempReminders.value.data : [];

	dispatch({
		type: ACTIONS.UPDATE,
		payload: { tempEngagements, tempReminders, loading: false },
	});
};

export const fetchOverdueData = async (medrep, dispatch) => {
	const { default: moment } = await import("moment");

	if (!moment) return;

	const _ed = moment().subtract(1, "day").endOf("day").toISOString();
	const _sd = moment().subtract(14, "days").startOf("day").toISOString();

	dispatch({ type: ACTIONS.LOADING, payload: true });

	let [overdueEngagements, overdueReminders] = await Promise.allSettled([
		fetchRequest(MED_REP_ENGAGEMENTS_API(medrep, _sd, _ed)),
		fetchRequest(REP_REMINDERS_API(medrep, _sd, _ed)),
	]);

	overdueEngagements = overdueEngagements.value
		? overdueEngagements.value.data
		: [];
	overdueReminders = overdueReminders.value ? overdueReminders.value.data : [];

	dispatch({
		type: ACTIONS.UPDATE,
		payload: { overdueEngagements, overdueReminders, loading: false },
	});
};

export const fetchTodaysEngagements = async (medrep, dispatch) => {
	const { default: moment } = await import("moment");

	if (!moment) return;

	const _ed = moment().endOf("day").toISOString();
	// const _sd = moment().startOf("day").toISOString();
	const _sd = moment().subtract(14, "days").startOf("day").toISOString();

	dispatch({ type: ACTIONS.LOADING, payload: true });

	let [engagements, reminders] = await Promise.allSettled([
		fetchRequest(MED_REP_ENGAGEMENTS_API(medrep, _sd, _ed)),
		fetchRequest(REP_REMINDERS_API(medrep, _sd, _ed)),
	]);

	engagements = engagements.value ? engagements.value.data : [];
	reminders = reminders.value ? reminders.value.data : [];

	dispatch({
		type: ACTIONS.UPDATE,
		payload: { engagements, reminders, loading: false },
	});
};
