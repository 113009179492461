const queryParamsForApi = obj => {
	const query = new URLSearchParams(window.location.search);
	const newQuery = new URLSearchParams();

	for (let key in obj) {
		if (query.has(key)) newQuery.set(obj[key], query.get(key));
	}

	return newQuery.toString();
};

export default queryParamsForApi;
