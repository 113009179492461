import moment from "moment";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";

const isBooked = count => {
	if (!count || count === null || count < 1) return false;
	return true;
};

export const mergeEngagements = (consultCalls, engagements) => {
	consultCalls = consultCalls.filter(item => {
		return item.consult_call_finished === false;
	});

	const _consultCalls = consultCalls?.map(item => ({
		key: item.id,
		case_id: item.id,
		datetime: item.consult_call_due_date,
		name: item.user?.first_name,
		phone: item.user?.phone_number,
		tag: "consult call",
		booked: item.is_consult_booked,
		is_finished: item.consult_call_finished,
	}));

	const _engagements = engagements.map(item => ({
		key: item.id,
		case_id: item.order?.case_id,
		datetime: item.reminder_date,
		name: item.order?.case?.user?.first_name,
		phone: item.order?.case?.user?.phone_number,
		tag: item.tag,
		booked: isBooked(item.reschedule_count),
		is_finished: item.is_finished,
		order_display_id: item.order?.order_display_id,
		unanswered_count: item.unanswered_count,
	}));

	let _data = [..._consultCalls, ..._engagements];
	_data = _data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

	return _data;
};

export const mergeRawEngagements = (consultCalls, engagements) => {
	const _consultCalls = consultCalls?.map(item => ({
		key: item.id,
		tag: "consult call",
		booked: item.is_consult_booked,
		is_finished: item.consult_call_finished,
	}));

	const _engagements = engagements.map(item => ({
		key: item.engagement_id,
		tag: item.engagement_tag,
		booked: isBooked(item.reschedule_count),
		is_finished: item.operation_status?.toLowerCase() === "completed",
		engagement_order_id: item.engagement_order_id,
		latest_order_id: item.latest_order_id,
	}));

	let _data = [..._consultCalls, ..._engagements];
	_data = _data.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

	return _data;
};

export const engagementTableData = (data, startTime, endTime, bnu, tag) => {
	let _data = [...data];
	// let _fd = [];

	if (startTime || endTime) {
		const _startTime = startTime || moment().startOf("day");
		const _endTime = endTime || moment().endOf("day");

		_data = _data.filter(item => {
			return moment(item.datetime).isBetween(
				_startTime,
				_endTime,
				undefined,
				"[]"
			);
		});
	}

	if (bnu) {
		const _isBooked = bnu === "booked";
		_data = _data.filter(item => {
			return item.booked === _isBooked;
		});
	}

	if (tag) {
		_data = _data.filter(item => {
			return item.tag?.toLowerCase() === tag.toLowerCase();
		});
	}

	return groupBy(_data, d => d.tag);
	// if (_data["consult call"]) _fd.push(..._data["consult call"]);
	// if (_data["Prescription Call"]) _fd.push(..._data["Prescription Call"]);
	// if (_data["Week #4"]) _fd.push(..._data["Week #4"]);
	// if (_data["Week #3"]) _fd.push(..._data["Week #3"]);
	// if (_data["Week #1"]) _fd.push(..._data["Week #1"]);
	// if (_data["Week #2"]) _fd.push(..._data["Week #2"]);
	// if (_data["Week #5"]) _fd.push(..._data["Week #5"]);
	// if (_data["Week #6"]) _fd.push(..._data["Week #6"]);
	// if (_data["SPE"]) _fd.push(..._data["SPE"]);

	// return _fd;
};

export const countNewRepeatOrders = orders => {
	if (isEmpty(orders)) return { repeat: 0, inprogress: { new: 0, repeat: 0 } };

	const repeatCount = orders.reduce((acc, curr) => {
		if (curr.status !== "void" && curr.count > 1) return (acc += 1);
		return acc;
	}, 0);

	const inprogressOrders = orders.filter(item => item.status === "in_progress");
	const inprogressCounts = inprogressOrders.reduce(
		(acc, curr) => {
			if (curr.count === 1) acc["new"] = acc.new + 1;
			else if (curr.count > 1) acc["repeat"] = acc.repeat + 1;
			return acc;
		},
		{ new: 0, repeat: 0 }
	);

	return { repeat: repeatCount, inprogress: inprogressCounts };
};

export const countCallInfos = (data, startTime, endTime) => {
	if (isEmpty(data)) return { callhrs: 0, connected: 0, attempted: 0 };
	const startDate = moment(startTime);
	const endDate = moment(endTime);
	const _count = data.reduce(
		(acc, curr) => {
			acc["attempted"] = acc.attempted + 1;

			if (
				!isEmpty(curr.call_duration) &&
				parseInt(curr.call_duration) > 0 &&
				curr.call_recording_available === "yes"
			) {
				acc["connected"] = acc.connected + 1;
				acc["callhrs"] = acc.callhrs + parseInt(curr.call_duration);
			}

			return acc;
		},
		{ callhrs: 0, connected: 0, attempted: 0 }
	);

	let diff = endDate.diff(startDate, "days");
	diff++; // This is to make the start date inclusive
	let avgCalculated = _count.callhrs;
	console.log(startDate, endDate, diff);
	if (diff > 0) avgCalculated = _count.callhrs / diff;
	return {
		callhrs: isNaN(avgCalculated) ? 0 : avgCalculated,
		connected: _count.connected,
		attempted: _count.attempted,
	};
};

export const secToHrs = (sec = 0) => {
	const _date = new Date(null);
	_date.setSeconds(sec);
	return _date.toISOString().slice(11, 16);
};

export const countEngagementsInfo = data => {
	const _data = groupBy(data, d => d.tag);
	const count = {};
	const _fd = [];

	for (let key in _data) {
		const _count = _data[key].reduce(
			(acc, curr) => {
				acc["total"] = acc.total + 1;
				if (curr.booked) acc["booked"] = acc.booked + 1;
				if (curr.is_finished) acc["done"] = acc.done + 1;

				return acc;
			},
			{ total: 0, booked: 0, done: 0 }
		);
		_count["key"] = key;
		count[key] = { ..._count };
	}

	if (count["consult call"]) _fd.push(count["consult call"]);
	if (count["Prescription Call"]) _fd.push(count["Prescription Call"]);
	if (count["Week #1"]) _fd.push(count["Week #1"]);
	if (count["Week #2"]) _fd.push(count["Week #2"]);
	if (count["Week #3"]) _fd.push(count["Week #3"]);
	if (count["Week #4"]) _fd.push(count["Week #4"]);
	if (count["Week #5"]) _fd.push(count["Week #5"]);
	if (count["Week #6"]) _fd.push(count["Week #6"]);
	if (count["SPE"]) _fd.push(count["SPE"]);

	return _fd;
};

export const calcEngagementSummary = data => {
	const _finished = data.reduce((acc, curr) => {
		if (curr.is_finished) return (acc = acc + 1);
		return acc;
	}, 0);

	const _newOrder = data.reduce((acc, curr) => {
		if (curr.engagement_order_id && curr.latest_order_id) {
			if (curr.engagement_order_id !== curr.latest_order_id) {
				return (acc = acc + 1);
			}
		}
		return acc;
	}, 0);

	const total = data.length;
	const _percentage = parseFloat((_finished / total) * 100).toFixed(2);

	return {
		percentage: isNaN(_percentage) ? 0.0 : _percentage,
		new_order: _newOrder,
		total,
	};
};

export const transformEscalatedCases = data => {
	if (isEmpty(data)) return [];
	return data.map(item => ({
		key: item.id,
		name: item.user?.first_name,
		datetime: item.escalated_at,
		escalation_status: item.escalation_status,
	}));
};

export const remindersTableData = data => {
	if (isEmpty(data)) return [];
	return data.map(item => ({
		key: item.id,
		case_id: item.case_id,
		datetime: item.reminder_date,
		name: item.case?.user?.first_name,
		phone: item.case?.user?.phone_number,
		tag: item.tag,
	}));
};

export const repLvl3Timeline = [
	{
		key: "consult call",
		tab: "Consult Call",
		col: "consultCall",
	},
	{
		key: "other_bookings",
		tab: "Other Bookings",
		col: "otherBookings",
	},
];

export const otherRepsTimeline = [
	{
		key: "consult call",
		tab: "Consult Call",
		col: "consultCall",
	},
	{
		key: "Prescription Call",
		tab: "Prescription Call",
		col: "engagements",
	},
	{
		key: "Week #1",
		tab: "Week 1",
		col: "engagements",
	},
	{
		key: "Week #2",
		tab: "Week 2",
		col: "engagements",
	},
	{
		key: "Week #3",
		tab: "Week 3",
		col: "engagements",
	},
	{
		key: "Week #4",
		tab: "Week 4",
		col: "engagements",
	},
	{
		key: "Week #5",
		tab: "Week 5",
		col: "engagements",
	},
	{
		key: "Week #6",
		tab: "Week 6",
		col: "engagements",
	},
	{
		key: "SPE",
		tab: "Special Engagements",
		col: "engagements",
	},
	{
		key: "other_bookings",
		tab: "Other Bookings",
		col: "otherBookings",
	},
];

export const getTimelineConfig = level => {
	return level === "3" ? repLvl3Timeline : otherRepsTimeline;
};
