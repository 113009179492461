import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
	getValue,
} from "../../helpers";
import queryParamsForApi from "../../helpers/queryParamsForApi";
import { statusTagColor } from "../../helpers/tagColor";
import { getSalesReps } from "../leadManagement/service";
import {
	formStatusTag,
	formStatusTitle,
} from "../leadManagement/utils/formStatus";
import { downloadCsv, getSlotBookings } from "./service";
import ReAssignBookings from "./ReAssignBookings";

// Ant Design
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import Badge from "antd/es/badge";
import Button from "antd/es/button";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Table from "antd/es/table";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import Typography from "antd/es/typography";
const { Option } = Select;
const { Text, Title } = Typography;

export const SlotBookings = () => {
	const history = useHistory();
	const [downloadingCsv, setDownloadingCsv] = useState(false);
	const [pageNo, setPageNo] = useState(parseInt(getQueryParams("sbt")) || 1);
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [allSelectedrows, setAllSelectedRows] = useState([]);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);

	const { slotBookings, loading } = useSelector(state => ({
		slotBookings: getValue(state, "slotBookings.bookings"),
		loading: getValue(state, "slotBookings.loading"),
	}));

	useEffect(() => {
		const filterItems = [
			{ sbt: pageNo, isUserBooked: "true", isRescheduled: "false" },
		];

		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	const slotBookingsAPICall = useCallback(() => {
		const paramsForAPI = queryParamsForApi(renameQueryForAPI);
		getSlotBookings(paramsForAPI);
	}, []);

	useEffect(() => {
		if (isEmpty(queryParams)) return;

		history.push({ search: queryParams });
		slotBookingsAPICall();
	}, [history, queryParams, slotBookingsAPICall]);

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (_, selectedRows) => {
			setAllSelectedRows([...selectionFromOtherPage, ...selectedRows]);
		},
	};

	const tableData = useMemo(() => {
		if (isEmpty(slotBookings)) return { data: [], rowCount: 0, pageCount: 0 };
		const _data = slotBookings.data?.map(item => ({
			key: getValue(item, "id"),
			bookingTime: getValue(item, "updated_at"),
			caseId: getValue(item, "case.id"),
			email: getValue(item, "case.user.email"),
			isFinished: getValue(item, "is_finished"),
			name: getValue(item, "case.user.first_name"),
			phoneNo: getValue(item, "case.user.phone_number"),
			rep: getValue(item, "user.first_name"),
			repLevel: getValue(item, "user.level"),
			slotTime: getValue(item, "slot.slot_time"),
			status: getValue(item, "case.status"),
			formStatus: getValue(item, "case.form_status"),
			feedback: getValue(item, "case.feedback"),
			isUserBooked: getValue(item, "is_user_booked"),
			isResheduled: getValue(item, "is_rescheduled"),
		}));

		return {
			data: _data,
			rowCount: slotBookings.pagination?.rowCount,
			pageCount: slotBookings.pagination?.pageCount,
		};
	}, [slotBookings]);

	useEffect(() => {
		if (pageNo > tableData.pageCount && tableData.pageCount > 0) {
			setPageNo(tableData.pageCount);
		}
	}, [pageNo, tableData]);

	useEffect(() => {
		if (isEmpty(allSelectedrows)) {
			setSelectedKeys([]);
			setSelectionFromOtherPage([]);
		} else {
			let _currPageKeys = [];
			let _otherPagesData = [];

			allSelectedrows.forEach(row => {
				if (tableData.data?.some(i => i.key === row.key)) {
					_currPageKeys = [..._currPageKeys, row.key];
				} else {
					_otherPagesData = [..._otherPagesData, row];
				}
			});

			setSelectedKeys(_currPageKeys);
			setSelectionFromOtherPage(_otherPagesData);
		}
	}, [allSelectedrows, tableData]);

	const handleDownloadCsv = async () => {
		setDownloadingCsv(true);
		const _query = queryParamsForApi(renameQueryForAPI);
		await downloadCsv(_query);
		setDownloadingCsv(false);
	};

	const updateUi = () => {
		slotBookingsAPICall();
		clearSelection();
	};

	const clearSelection = () => {
		setAllSelectedRows([]);
		setSelectedKeys([]);
		setSelectionFromOtherPage([]);
	};

	const column = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { caseId, formStatus, status, key }) => (
				<div className="grid gap-1">
					<Link className="link" to={`/lead-details/${caseId}`}>
						{name}
					</Link>

					<div className="flex">
						{status && (
							<Tag color={statusTagColor[status]} key={key}>
								{status.toUpperCase()}
							</Tag>
						)}

						<Tooltip placement="bottom" title={formStatusTitle[formStatus]}>
							<Tag color="magenta">{formStatusTag[formStatus]}</Tag>
						</Tooltip>
					</div>
				</div>
			),
		},
		{
			title: "Phone No",
			dataIndex: "phoneNo",
			key: "phoneNo",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Feedback",
			dataIndex: "feedback",
			key: "feedback",
			render: feedback =>
				feedback ? feedback : <div style={{ textAlign: "center" }}>-</div>,
		},
		{
			title: "Booking Time",
			dataIndex: "bookingTime",
			key: "bookingTime",
			render: bookingTime => {
				if (isEmpty(bookingTime)) return "-";
				return moment(bookingTime).format("DD MMM YYYY, h:mm a");
			},
		},
		{
			title: "Slot Time",
			dataIndex: "slotTime",
			key: "slotTime",
			render: slotTime => {
				if (isEmpty(slotTime)) return "-";
				return moment(slotTime).format("DD MMM YYYY, h:mm a");
			},
		},

		{
			title: "Is Finished",
			dataIndex: "isFinished",
			key: "isFinished",
			render: isFinished => (isFinished ? "Yes" : "No"),
		},
		{
			title: "Assigned To",
			dataIndex: "rep",
			key: "rep",
			render: (rep, { repLevel }) => (
				<div className="flex items-center gap-1">
					<Text>{rep}</Text>
					<Badge
						count={repLevel}
						style={{ backgroundColor: "#dcfce7", color: "#166534" }}
					/>
				</div>
			),
		},
	];

	return (
		<>
			<div className="mb-2">
				<ReAssignBookings selectedRows={allSelectedrows} updateUi={updateUi} />
			</div>

			<div className="flex justify-between">
				<Title level={2}>Slot Bookings</Title>

				<Button
					disabled={isEmpty(tableData.data)}
					id="download_csv_btn"
					icon={<DownloadOutlined />}
					loading={downloadingCsv}
					onClick={() => handleDownloadCsv()}
					type="primary">
					Download
				</Button>
			</div>
			<div className="flex justify-end">
				<Filters
					clearSelection={clearSelection}
					setPageNo={setPageNo}
					setQueryParams={setQueryParams}
				/>
			</div>

			<div className="w-full">
				<Table
					columns={column}
					dataSource={tableData.data}
					loading={loading}
					onChange={e => setPageNo(e.current)}
					pagination={{
						current: pageNo,
						showSizeChanger: false,
						total: tableData.rowCount,
					}}
					rowSelection={rowSelection}
				/>
			</div>
		</>
	);
};

const Filters = ({ clearSelection, setPageNo, setQueryParams }) => {
	const [form] = Form.useForm();
	const [assignedTo, setAssignedTo] = useState(getQueryParams("assignedTo"));
	const [isFinished, setIsFinished] = useState(getQueryParams("isFinished"));
	const [bsd, setBsd] = useState(parseInt(getQueryParams("bsd")));
	const [bed, setBed] = useState(parseInt(getQueryParams("bed")));
	const [ssd, setSsd] = useState(getQueryParams("ssd"));
	const [sed, setSed] = useState(getQueryParams("sed"));
	const [repLevel, setRepLevel] = useState(getQueryParams("repLevel"));
	const [category, setCategory] = useState(getQueryParams("category"));

	useEffect(() => {
		getSalesReps();
	}, []);

	const { salesReps, salesRepsLoading } = useSelector(state => ({
		salesReps: getValue(state, "leadmanagement.salesReps.userRole"),
		salesRepsLoading: getValue(state, "leadmanagement.salesRepsLoading"),
	}));

	const onSubmit = async items => {
		const filterItems = [];

		Object.entries(items).forEach(([key, value]) => {
			switch (key) {
				case "bsd":
					filterItems.push({ [key]: bsd ? moment.utc(bsd).valueOf() : null });
					break;

				case "bed":
					filterItems.push({ [key]: bed ? moment.utc(bed).valueOf() : null });
					break;

				case "ssd":
					filterItems.push({ [key]: ssd ? moment(ssd).toISOString() : null });
					break;

				case "sed":
					filterItems.push({ [key]: sed ? moment(sed).toISOString() : null });
					break;

				default:
					filterItems.push({ [key]: value });
					break;
			}

			if (clearSelection) clearSelection();
		});
		const _query = await generateQuery(filterItems);
		setQueryParams(_query);
		setPageNo(1);
	};

	const handleClear = async () => {
		clearSelection();
		form.resetFields();
		setBsd(null);
		setBed(null);
		setSsd(null);
		setSed(null);
		setIsFinished(null);
		setAssignedTo(null);
		setRepLevel(null);
		setCategory(null);
		form.submit();
	};

	const formInitialValues = {
		bsd: bsd ? moment(bsd) : null,
		bed: bed ? moment(bed) : null,
		ssd: ssd ? moment(ssd) : null,
		sed: sed ? moment(sed) : null,
		isFinished: isFinished,
		assignedTo: assignedTo,
		repLevel: repLevel,
		category: category,
	};

	return (
		<Form
			className="order-filter"
			form={form}
			initialValues={formInitialValues}
			layout="inline"
			onFinish={onSubmit}>
			<Form.Item name="bsd">
				<DatePicker
					allowClear
					disabledDate={current =>
						bed
							? current.isAfter(moment(bed), "day")
							: current.isAfter(moment(), "day")
					}
					format={"YYYY-MM-DD hh:mm a"}
					id="booking_start_date_input"
					onChange={e => (e ? setBsd(e) : setBsd(null))}
					placeholder="Booking Start Date"
					showTime={true}
					style={{ width: 176 }}
				/>
			</Form.Item>

			<Form.Item name="bed">
				<DatePicker
					allowClear
					disabledDate={current =>
						(bsd && current.isBefore(moment(bsd), "day")) ||
						current.isAfter(moment(), "day")
					}
					format={"YYYY-MM-DD hh:mm a"}
					id="booking_end_date_input"
					onChange={e => (e ? setBed(e) : setBed(null))}
					placeholder="Booking End Date"
					showTime={true}
					style={{ width: 176 }}
				/>
			</Form.Item>

			<Form.Item name="ssd">
				<DatePicker
					allowClear
					disabledDate={current => sed && current.isAfter(moment(sed), "day")}
					format={"YYYY-MM-DD hh:mm a"}
					id="slot_start_date_input"
					onChange={e => (e ? setSsd(e) : setSsd(null))}
					placeholder="Slot Start Date"
					showTime={true}
					style={{ width: 176 }}
				/>
			</Form.Item>

			<Form.Item name="sed">
				<DatePicker
					allowClear
					disabledDate={current => ssd && current.isBefore(moment(ssd), "day")}
					format={"YYYY-MM-DD hh:mm a"}
					id="slot_end_date_input"
					onChange={e => (e ? setSed(e) : setSed(null))}
					placeholder="Slot End Date"
					showTime={true}
					style={{ width: 176 }}
				/>
			</Form.Item>

			<Form.Item name="isFinished">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "10rem" }}
					id="is_finished_input"
					options={isFinishedOptions}
					onChange={e => (e ? setIsFinished(e) : setIsFinished(null))}
					placeholder="Is Finished"
				/>
			</Form.Item>

			<Form.Item name="assignedTo">
				<Select
					allowClear
					dropdownStyle={{ minWidth: "12rem" }}
					id="assign_to_input"
					loading={salesRepsLoading}
					onChange={e => (e ? setAssignedTo(e) : setAssignedTo(null))}
					placeholder="Assigned To">
					{salesReps &&
						salesReps.map(users => (
							<Option value={users.user.id} key={users.user.id}>
								{users.user.first_name}
							</Option>
						))}
				</Select>
			</Form.Item>

			<Form.Item name="repLevel">
				<Select
					allowClear
					options={levelOptions}
					onChange={e => (e ? setRepLevel(e) : setRepLevel(undefined))}
					placeholder="Level"
					style={{ width: "5rem" }}
				/>
			</Form.Item>

			<Form.Item name="category">
				<Select
					allowClear
					options={categoryOptions}
					onChange={e => (e ? setCategory(e) : setCategory(undefined))}
					placeholder="Form Type"
					style={{ width: "8rem" }}
				/>
			</Form.Item>

			<Form.Item>
				<div className="flex gap-1">
					<Button id="filter_apply_btn" type="primary" htmlType="submit">
						Apply
					</Button>

					<Button
						id="filter_clear_btn"
						htmlType="button"
						onClick={() => handleClear()}>
						Clear
					</Button>
				</div>
			</Form.Item>
		</Form>
	);
};

const isFinishedOptions = [
	{ label: "Yes", value: "true" },
	{ label: "No", value: "false" },
];
const levelOptions = [
	{ label: "1", value: 1 },
	{ label: "2", value: 2 },
	{ label: "3", value: 3 },
];

const renameQueryForAPI = {
	isFinished: "isFinished",
	assignedTo: "assignedTo",
	ssd: "slotStartDate",
	sed: "slotEndDate",
	bsd: "bookingStartDate",
	bed: "bookingEndDate",
	sbt: "pageNumber",
	repLevel: "repLevel",
	isRescheduled: "isRescheduled",
	isUserBooked: "isUserBooked",
	category: "category",
};

const categoryOptions = [
	{ label: "Hair", value: "hair" },
	{ label: "Cholesterol", value: "cholesterol" },
];
