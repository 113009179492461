import Cookies from "js-cookie";
import { baseURL } from "../../helpers";
import { store } from "../../redux/store";
import { dispatchDashboardActions } from "./actions";
import { handleError } from "../../helpers";

export const getPrescriptionPendingShippingItems = async pageNo => {
	store.dispatch({
		type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}pendingShippingItems?pageSize=20&pageNumber=${pageNo}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const markShipped = item_id => {
	store.dispatch({
		type: dispatchDashboardActions.POST_MARK_SHIPPED_LOADING,
		payload: true,
	});
	return fetch(`${baseURL.API_URL_BASE}order/${item_id}/markShipped`, {
		method: "POST",
		json: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		if (res.status === 200) {
			return res
				.json()
				.then(response => {
					if (response) {
						store.dispatch({
							type: dispatchDashboardActions.POST_MARK_SHIPPED_SUCCESS,
							payload: response,
						});
					} else {
						store.dispatch({
							type: dispatchDashboardActions.POST_MARK_SHIPPED_FAILURE,
							payload: response,
						});
					}
					return response;
				})
				.catch(error => {
					store.dispatch({
						type: dispatchDashboardActions.POST_MARK_SHIPPED_FAILURE,
						payload: error.toString(),
					});
					handleError(error);
				});
		} else {
			return handleError(res);
		}
	});
};
export const handleDispatchSearchResult = async (key, pageNo, warehouse) => {
	store.dispatch({
		type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_LOADING,
		payload: true,
	});
	try {
		let url = warehouse
			? `${
					baseURL.API_URL_BASE
			  }pendingShippingItems?warehouse=${encodeURIComponent(
					warehouse
			  )}&pageNumber=${pageNo}`
			: `${
					baseURL.API_URL_BASE
			  }pendingShippingItems?searchKey=${encodeURIComponent(
					key
			  )}&pageNumber=${pageNo}`;

		const res = await fetch(url, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			let data = await res.json();
			console.log("data", data);
			store.dispatch({
				type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE,
				payload: null,
			});
		}
	} catch (error) {
		store.dispatch({
			type: dispatchDashboardActions.GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE,
			payload: error.toString(),
		});
	}
};

export const incrementPrintCount = async orderId => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}order/${orderId}/printCount`,
			{
				method: "PUT",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		return res;
	} catch (error) {
		console.log(error.toString());
		handleError(error);
	}
};

export const handleSearch = async key => {
	try {
		const res = await fetch(
			`${
				baseURL.API_URL_BASE
			}dispatchSearchItems?searchKey=${encodeURIComponent(key)}`,
			{
				method: "GET",
				json: true,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			let data = {
				status: res.status,
				data: await res.json(),
			};
			return data;
		} else {
			return res;
		}
	} catch (error) {
		console.log(error.toString());
	}
};
