import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "./index.less";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getValue } from "./helpers";
import ErrorBoundary from "./common/ErrorBoundary";

window.getValue = getValue;

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<Router>
				<ErrorBoundary>
					<App />
				</ErrorBoundary>
			</Router>
		</React.StrictMode>
	</Provider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
