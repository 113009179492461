import React, { useCallback, useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getValue } from "../../helpers";
import { getProductsMapping } from "../../pages/configure/service";
import { getChats } from "../../pages/leadDetails/service";
import { UTM_INFO } from "../../constants";
import { generateCartLink, getCodeTypeAndValue, getTrayaCartLink, sendCartLink } from "./service";
import { Loader } from "../../common";
import { fetchRequest } from "../../helpers/fetchRequest";
import { DISCOUNT_CODES_API } from "../../constants/apiUrls";

// And Design
import {
	Button,
	Form,
	InputNumber,
	Modal,
	Row,
	Select,
	Space,
	Typography,
} from "antd";
import { getValidOrderCount } from '../../helpers/getValidOrderCount';
const { Paragraph, Text, Title } = Typography;

const OrderCartLinkModal = ({ isVisible, setIsVisible }) => {
	const { id: case_id } = useParams();
	const [form] = Form.useForm();
	const [generatedLink, setGeneratedLink] = useState("");
	const [sendingLink, setSendingLink] = useState(false);
	const [generatingLink, setGeneratingLink] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [loadingDiscountCodes, setLoadingDiscountCodes] = useState(false);
	const [discountCodes, setDiscountCodes] = useState([]);
	const [selectedCode, setSelectedCode] = useState("");

	useEffect(() => {
		getProductsMapping();
	}, []);

	const { productsMapping, loading, userDetails } = useSelector(state => ({
		productsMapping: getValue(state, "configure.productsMapping"),
		loading: getValue(state, "configure.productMappingLoading"),
		userDetails: getValue(state, "leaddetails.usersDetail"),
	}));

	// Fetch Discount Codes
	const getDiscountCodes = useCallback(async () => {
		setLoadingDiscountCodes(true);
		let res = {};

		try {
			res = await fetchRequest(DISCOUNT_CODES_API);
		} catch (error) {
			console.log(error.message);
		} finally {
			if (res.hasError) setDiscountCodes([]);
			else setDiscountCodes(res.data);
			setLoadingDiscountCodes(false);
		}
	}, []);

	useEffect(() => {
		getDiscountCodes();
	}, [getDiscountCodes]);
	// Ends

	const discountCodeOptions = useMemo(() => {
		if (isEmpty(discountCodes)) return [];
		return discountCodes.map(row => ({
			label: row.discount_code,
			value: row.discount_code,
		}));
	}, [discountCodes]);

	const products = useMemo(() => {
		if (isEmpty(productsMapping)) return [];

		return productsMapping.filter(product => {
			return (
				!isEmpty(product.product_principal_id) &&
				product.medicine?.medicine_slug !== "scalp_oil"
			);
		});
	}, [productsMapping]);

	const handleModalClose = () => {
		form.resetFields();
		setGeneratedLink("");
		setSelectedCode("");
		setTotalPrice(0);
		setIsVisible(false);
	};

	const handleSubmit = async data => {
		const { baseUrl, itemsKey } = await getTrayaCartLink();
		let orderCount = getValidOrderCount(userDetails.orders);
		if (!baseUrl && baseUrl === '') return; 
		const _arr = [];

		let customerName = userDetails?.user.first_name;
		customerName = customerName.split(" ").join("_").toLocaleLowerCase();

		for (let key in data) {
			if (!data[key]) continue;
			_arr.push(`${key}:${data[key]}`);
		}

		if (isEmpty(_arr)) {
			setGeneratedLink("");
			return;
		}

		const _query = _arr.join(",");
		const _repName = window.localStorage
			.getItem("userName")
			?.replaceAll(" ", "_")
			?.toLocaleLowerCase();

		let _url = `${baseUrl}?${itemsKey}=${_query}&${UTM_INFO}${_repName}&customer_name=${customerName}`;
		
		if (!isEmpty(selectedCode)) {
			_url = _url + `&discount=${selectedCode}`;
		}
	
		if(orderCount) {
			_url = _url + `&order_count=${orderCount}`
		} else {
			_url = _url + '&order_count=0'
		}

		if (!isEmpty(selectedCode)) {
			const { type, value } = await getCodeTypeAndValue(selectedCode);
			if (type !== '' && value !== '') {
				_url = _url + `&discount_value=${value}&discount_type=${type}`
			}
		}

		await generateOrderLink(_url);
		setGeneratedLink(_url);
	};

	const sendOrderLink = async () => {
		setSendingLink(true);
		const res = await sendCartLink(case_id, generatedLink, totalPrice);
		setSendingLink(false);

		if (res.status === 200) {
			getChats(case_id);
			handleModalClose();
		}
	};

	const generateOrderLink = async url => {
		setGeneratingLink(true);
		if (case_id) await generateCartLink(case_id, url);
		setGeneratingLink(false);
	};

	const handleFieldChange = () => {
		setGeneratedLink("");
		const _fields = form.getFieldsValue();
		let _totalPrice = 0;

		for (let key in _fields) {
			if (!_fields[key]) continue;

			const _price = products.find(product => {
				return product.product_principal_id === key;
			})?.product_price;

			_totalPrice = _totalPrice + _price * _fields[key];
		}

		setTotalPrice(_totalPrice);
	};

	return (
		<Modal
			title="Order Cart Link"
			centered
			visible={isVisible}
			onOk={handleModalClose}
			onCancel={handleModalClose}
			width={840}
			footer={[
				<Button
					id="close_order_cart_link_btn"
					key="back"
					onClick={handleModalClose}>
					Close
				</Button>,
				<Button
					disabled={isEmpty(generatedLink)}
					id="send_order_cart_link_btn"
					key="submit"
					loading={sendingLink}
					onClick={() => sendOrderLink()}
					type="primary">
					Send
				</Button>,
			]}>
			<>
				{!isEmpty(generatedLink) && (
					<Row align="middle" justify="center" style={{ marginBottom: 16 }}>
						<Paragraph
							copyable={{ tooltips: false }}
							ellipsis={true}
							strong
							style={{ width: 240 }}
							type="success">
							{generatedLink}
						</Paragraph>
					</Row>
				)}

				{loading ? (
					<Loader />
				) : (
					<Form
						name="order form link"
						form={form}
						onValuesChange={handleFieldChange}
						onFinish={handleSubmit}
						layout="horizontal">
						<div className="order_link_form">
							{products.map(product => (
								<Form.Item
									labelCol={{ span: 12 }}
									wrapperCol={{ span: 12 }}
									key={product.id}
									label={product.medicine.display_name}>
									<Space align="center">
										<Form.Item name={product.product_principal_id}>
											<InputNumber
												id={`${product.product_principal_id}_quantity_input`}
												placeholder="Quantity"
												max={35}
												min={1}
												style={{ width: 80 }}
											/>
										</Form.Item>

										<Text style={{ display: "block", marginBottom: 24 }}>
											x {product.product_price}
										</Text>
									</Space>
								</Form.Item>
							))}
						</div>

						<Row justify="center" style={{ marginBottom: 10 }}>
							<Title level={3}>Total value: {totalPrice}</Title>
						</Row>

						<Row className="mb-4" justify="space-between">
							<Title level={3}>Discount</Title>
							<Select
								allowClear
								size="large"
								loading={loadingDiscountCodes}
								options={discountCodeOptions}
								onChange={value => {
									setGeneratedLink("");
									setSelectedCode(value ? value : "");
								}}
								placeholder="Discount Code"
								value={isEmpty(selectedCode) ? null : selectedCode}
							/>
						</Row>

						<Form.Item wrapperCol={{ span: 24 }}>
							<Row justify="center">
								<Space direction="vertical" align="center">
									<Button
										id="generate_order_cart_link_btn"
										type="primary"
										loading={generatingLink}
										htmlType="submit">
										Generate
									</Button>
								</Space>
							</Row>
						</Form.Item>
					</Form>
				)}
			</>
		</Modal>
	);
};

export default OrderCartLinkModal;
