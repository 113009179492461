import Cookies from "js-cookie";
import { message } from "antd";

export const handleError = error => {
  if (error.status === 401) {
    Cookies.remove("token");
    localStorage.removeItem("users");
    message.error(
      "User is not authenticated",
      1,
      () => (window.location.pathname = "/")
    );
  }
};
