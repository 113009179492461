import React, { useEffect } from "react";
import { groupBy } from "lodash";

function PickupList({ data, setIsPickupList }) {
  useEffect(() => {
    const printContents = document.getElementById("printContent").innerHTML;
    const newWin = window.open();
    newWin.document.write(printContents);
    newWin.print();
    newWin.close();
    setIsPickupList(false);
  }, [data]);

  let prescriptionData = [];
  let printData = [];

  data.map(data => {
    const prescription = data.prescription;
    prescription.map(prescription => {
      const prescriptionList = JSON.parse(prescription.prescription)
        .prescription;
      prescriptionList.map(prescriptionList => {
        if (
          prescriptionList.showRecommendation === false ||
          prescriptionList.isRecommended === false
        ) {
          printData.push(prescriptionList);
        }
      });
      return (prescriptionData = [...printData]);
    });
  });

  let pickupList = {};

  const group = groupBy(prescriptionData, e => e.Medicine);

  for (let key in group) {
    const medicineArray = group[key];
    const medicineName = medicineArray[0].Medicine;
    const count = medicineArray.reduce((acc, med) => {
      let _c = med.quantity;
      if (!_c)
        _c = 1;
      return acc + Number(_c);
    }, 0);
    pickupList[medicineName] = count;
  }

  return (
    <div id="printContent">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <table
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            wodth: "80px",
            margin: "10px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "20px",
                }}
              >
                Product Name
              </th>
              <th
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  padding: "10px",
                  fontSize: "20px",
                }}
              >
                Count
              </th>
            </tr>
          </thead>
          {Object.entries(pickupList).map(([key, value]) => (
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "10px",
                    fontSize: "20px",
                  }}
                >
                  {key}
                </td>
                <td
                  style={{
                    border: "1px solid black",
                    borderCollapse: "collapse",
                    padding: "10px",
                    fontSize: "20px",
                  }}
                >
                  {value}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
}

export default PickupList;
