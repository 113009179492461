import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Loader, NotAllowedError } from "../../common";
import {
	AddCommentAndReminder,
	OrderDetails,
	RecentComments, TagHistory, UsersDetail
} from "../leadDetails/components";
import ActivityLog from "../leadDetails/components/ActivityLog";
import ChatBox from "../leadDetails/components/ChatBox";
import { viewDetails } from "../leadDetails/service";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Row, Button, Popover, Space, Tabs, Typography } from "antd";
import EscalationLogs from '../leadDetails/components/EscalationLogs';
import FreshchatBox from '../leadDetails/components/FreschatBox';

const { TabPane } = Tabs;
const { Text } = Typography;

const ViewDetailsByConsultRep = () => {
	const history = useHistory();
	const params = useParams();
	const [showAddComment, setShowAddComment] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		viewDetails(params.id);
	}, [params.id]);

	const { chats, detail } = useSelector(state => ({
		chats: window.getValue(state, "leaddetails.chats"),
		detail: window.getValue(state, "leaddetails.usersDetail"),
	}));

	const chatListContent = () => (
		<Text type="warning">
			Chat number is available in{" "}
			<Text style={{ color: "#B45309" }}>{chats?.profileCount - 1}</Text> more
			profile, please contact admin or support for more details
		</Text>
	);

	if (!detail) return <Loader />;
	if (detail && detail.Error) return <NotAllowedError />;

	return (
		<div className="lead-details">
			<Row justify="space-between" style={{ paddingBottom: "1rem" }}>
				<Button onClick={history.goBack} type="link" icon={<LeftOutlined />}>
					Back
				</Button>
			</Row>

			<Row>
				<Col span={24}>
					<UsersDetail />
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<OrderDetails />
				</Col>
			</Row>

			<Row>
				<Col
					span={12}
					offset={12}
					style={{
						border: "1px solid #F0F0F0",
						height: 385,
					}}>
					<Tabs
						defaultActiveKey="1"
						type="card"
						onChange={activeKey => setShowAddComment(activeKey === "2")}>
						<TabPane
							tab={
								chats?.profileCount > 1 ? (
									<Space>
										Chats
										<Popover content={chatListContent} title="">
											<ExclamationCircleOutlined style={{ color: "#D97706" }} />
										</Popover>
									</Space>
								) : (
									"Chats"
								)
							}
							key="1">
							<ChatBox />
						</TabPane>
						<TabPane tab="Chats from freshchats" key="6">
							<FreshchatBox />
						</TabPane>
						<TabPane tab="Recent Comments" key="2">
							<RecentComments />
						</TabPane>
						<TabPane tab="Activity Log" key="3">
							<ActivityLog />
						</TabPane>
						<TabPane tab="Tag History" key="4">
							<TagHistory />
						</TabPane>
						<TabPane tab="Escalation History" key="5">
							<EscalationLogs />
						</TabPane>
					</Tabs>
				</Col>
			</Row>
			<Row>
				{showAddComment && (
					<Col offset={12} span={12}>
						<AddCommentAndReminder />
					</Col>
				)}
			</Row>
		</div>
	);
};

export default ViewDetailsByConsultRep;

const showProfileWithChatNo = ["admin", "support"];
