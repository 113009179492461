import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { fetchRequest } from "../../../helpers/fetchRequest";
import {
	ASSIGNED_ORDER_API,
	COD_ORDERS_API,
	ESCALATED_CASES_API,
	KNOWLARITY_CALLS_API,
	MED_REP_CONSULT_CALL_API,
	MED_REP_ENGAGEMENTS_API,
	RAW_ENGAGEMENTS_API,
	MEDREP_TEMPORARY_ENGAGEMENTS_API,
	REP_REMINDERS_API,
} from "../../../constants/apiUrls";

import {
	mergeEngagements,
	engagementTableData,
	countNewRepeatOrders,
	countCallInfos,
	secToHrs,
	countEngagementsInfo,
	calcEngagementSummary,
	mergeRawEngagements,
	transformEscalatedCases,
	remindersTableData,
	getTimelineConfig,
} from "./helpers";

// Components
import TodaysEngagementsTimeline from "./TodaysEngagementsTimeline";

// Antd
import { Button, Card, DatePicker, Select, Table, Typography } from "antd";
import OverdueEngagements from "./OverdueEngagements";
import { baseURL } from "../../../helpers";
import CreateTicket from "../../../pages/ticketing/components/CreateTicket";
const { Option } = Select;
const { Text, Title } = Typography;

const repLevel = localStorage.getItem("rep!level");

const MedRepEngagements = ({ medRepId, showCreateTicket }) => {
	const getUrl = url => baseURL.API_URL_BASE + url;
	const MEDREP_TEMPORARY_REMINDERS_API = (id, start, end) => {
		if (!start || !end) {
			return getUrl(`v2/medrep_temporary_reminders/${id}`);
		}
		return getUrl(
			`v2/medrep_temporary_reminders/${id}?startDate=${start}&endDate=${end}`
		);
	};

	const [assignedOrders, setAssignedOrders] = useState([]);
	const [consultCalls, setConsultCalls] = useState([]);
	const [bnu, setBnu] = useState(null);
	const [endTime, setEndTime] = useState(null);
	const [engagements, setEngagements] = useState([]);
	const [temporaryEngagements, setTemporaryEngagements] = useState([]);
	const [loadingTempEngagements, setLoadingTempEngagements] = useState(false);
	const [temporaryReminders, setTemporaryReminders] = useState([]);
	const [loadingTempReminders, setLoadingTempReminders] = useState(false);
	const [overdueEngagements, setOverdueEngagements] = useState([]);
	const [overdueReminders, setOverdueReminders] = useState([]);
	const [escalatedCases, setEscalatedCases] = useState([]);
	const [knowlarityCalls, setKnowlarityCalls] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingAssignedOrders, setLoadingAssignedOrders] = useState(false);
	const [loadingEscalatedCases, setLoadingEscalatedCases] = useState(false);
	const [loadingKnowlarityCalls, setLoadingKnowlarityCalls] = useState(false);
	const [loadingRawEngagements, setLoadingRawEngagements] = useState(false);
	const [loadingCodOrders, setLoadingCodOrders] = useState(false);
	const [loadingReminders, setLoadingReminders] = useState(false);
	const [startTime, setStartTime] = useState(null);
	const [rawEngagements, setRawEngagements] = useState([]);
	const [reminders, setReminders] = useState([]);
	const [codOrders, setCodOrders] = useState([]);
	const [tag, setTag] = useState(null);
	const [sd, setSD] = useState(
		moment().subtract(7, "days").startOf("day").toISOString()
	);
	const [ed, setED] = useState(moment().endOf("day").toISOString());

	// LoggedIn user
	const currentUser = useMemo(() => localStorage.getItem("users"), []);

	// Fetch Overdue engagments
	const getOverdueEngagements = useCallback(async () => {
		if (!medRepId || !currentUser) return;

		let res = {};
		setLoading(true);

		try {
			const _ed = moment().subtract(1, "day").endOf("day").toISOString();
			const _sd = moment().subtract(14, "days").startOf("day").toISOString();
			res = await fetchRequest(MED_REP_ENGAGEMENTS_API(medRepId, _sd, _ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setOverdueEngagements([]);
			else {
				const _data = res.data.filter(d => {
					if (!d.temporary_user_id) return true;
					return d.temporary_user_id === currentUser;
				});
				setOverdueEngagements(_data);
			}
			setLoading(false);
		}
	}, [medRepId, currentUser]);

	useEffect(() => {
		getOverdueEngagements();
	}, [getOverdueEngagements]);
	// Ends

	// Fetch Overdue Reminders
	const getOverdueReminders = useCallback(async () => {
		let res = {};
		setLoadingReminders(true);

		try {
			const _ed = moment().subtract(1, "day").endOf("day").toISOString();
			const _sd = moment().subtract(14, "days").startOf("day").toISOString();
			res = await fetchRequest(REP_REMINDERS_API(medRepId, _sd, _ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setOverdueReminders([]);
			else setOverdueReminders(res.data);
			setLoadingReminders(false);
		}
	}, [medRepId]);

	useEffect(() => {
		getOverdueReminders();
	}, [getOverdueReminders]);
	// End

	// Fetch Today's engagements
	const getTodaysEngagements = useCallback(async () => {
		let res = {};
		setLoading(true);

		try {
			const _sd = moment().startOf("day").toISOString();
			const _ed = moment().endOf("day").toISOString();
			res = await fetchRequest(MED_REP_ENGAGEMENTS_API(medRepId, _sd, _ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setEngagements([]);
			else setEngagements(res.data);
			setLoading(false);
		}
	}, [medRepId]);

	useEffect(() => {
		getTodaysEngagements();
	}, [getTodaysEngagements]);
	// Ends

	//Fetch Temporary-Engagements
	const getTemporaryEngagements = useCallback(async () => {
		let res = {};
		setLoadingTempEngagements(true);
		try {
			res = await fetchRequest(MEDREP_TEMPORARY_ENGAGEMENTS_API(medRepId));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setTemporaryEngagements([]);
			else setTemporaryEngagements(res.data);
			setLoadingTempEngagements(false);
		}
	}, [medRepId]);
	useEffect(() => {
		getTemporaryEngagements();
	}, [getTemporaryEngagements]);
	//Ends

	//Fetch Temporary-Reminders
	const getTemporaryReminders = useCallback(async () => {
		let res = {};
		setLoadingTempReminders(true);
		try {
			res = await fetchRequest(MEDREP_TEMPORARY_REMINDERS_API(medRepId));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setTemporaryReminders([]);
			else setTemporaryReminders(res.data);
			setLoadingTempReminders(false);
		}
	}, [medRepId]);
	useEffect(() => {
		getTemporaryReminders();
	}, [getTemporaryReminders]);
	//Ends

	// Fetch consult calls
	const getConsultCalls = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoading(true);

		try {
			res = await fetchRequest(MED_REP_CONSULT_CALL_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setConsultCalls([]);
			else setConsultCalls(res.data);
			setLoading(false);
		}
	}, [medRepId, sd, ed]);

	// Ends

	// Fetch Reminders
	const getReminders = useCallback(async () => {
		let res = {};
		setLoadingReminders(true);

		try {
			const _sd = moment().startOf("day").toISOString();
			const _ed = moment().endOf("day").toISOString();
			res = await fetchRequest(REP_REMINDERS_API(medRepId, _sd, _ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setReminders([]);
			else setReminders(res.data);
			setLoadingReminders(false);
		}
	}, [medRepId]);

	useEffect(() => {
		getReminders();
	}, [getReminders]);
	// End

	// Fetch Assigned Orders
	const getAssignedOrders = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoadingAssignedOrders(true);

		try {
			const _searchQuery = `startDate=${sd}&endDate=${ed}`;
			res = await fetchRequest(ASSIGNED_ORDER_API(medRepId, _searchQuery));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setAssignedOrders([]);
			else setAssignedOrders(res.data);
			setLoadingAssignedOrders(false);
		}
	}, [medRepId, sd, ed]);

	// Ends

	// Fetch Knowlarity Calls
	const getKnowlarityCalls = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoadingKnowlarityCalls(true);

		try {
			res = await fetchRequest(KNOWLARITY_CALLS_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setKnowlarityCalls([]);
			else setKnowlarityCalls(res.data);
			setLoadingKnowlarityCalls(false);
		}
	}, [medRepId, sd, ed]);

	// Ends

	// Fetch Row Engagements
	const getRawEngagements = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoadingRawEngagements(true);

		try {
			res = await fetchRequest(RAW_ENGAGEMENTS_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setRawEngagements([]);
			else setRawEngagements(res.data);
			setLoadingRawEngagements(false);
		}
	}, [medRepId, sd, ed]);

	// Ends

	// Fetch Escalated cases
	const getEscalatedCases = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoadingEscalatedCases(true);

		try {
			res = await fetchRequest(ESCALATED_CASES_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setEscalatedCases([]);
			else setEscalatedCases(res.data);
			setLoadingEscalatedCases(false);
		}
	}, [medRepId, sd, ed]);

	// End

	// get COD Orders
	const getCodOrders = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoadingCodOrders(true);

		try {
			res = await fetchRequest(COD_ORDERS_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setCodOrders([]);
			else setCodOrders(res.data);
			setLoadingCodOrders(false);
		}
	}, [medRepId, sd, ed]);
	// Ends

	const mergedRawEngagements = useMemo(() => {
		if (repLevel === "3") return mergeRawEngagements([...consultCalls], []);
		return mergeRawEngagements([...consultCalls], [...rawEngagements]);
	}, [consultCalls, rawEngagements]);

	const newRepeatOrders = useMemo(() => {
		return countNewRepeatOrders(assignedOrders);
	}, [assignedOrders]);

	const callCounts = useMemo(() => {
		return countCallInfos(knowlarityCalls, sd, ed);
	}, [ed, knowlarityCalls, sd]);

	const engagementsCount = useMemo(() => {
		return countEngagementsInfo(mergedRawEngagements);
	}, [mergedRawEngagements]);

	const engagementSummary = useMemo(() => {
		return calcEngagementSummary(rawEngagements);
	}, [rawEngagements]);

	const escalatedCasesData = useMemo(() => {
		return transformEscalatedCases(escalatedCases);
	}, [escalatedCases]);

	const refreshTodaysEngagements = () => {
		getTodaysEngagements();
		getTemporaryEngagements();
		getReminders();
	};

	const refreshOverdueEngagements = () => {
		getOverdueEngagements();
		getReminders();
	};

	const applyHandler = () => {
		getConsultCalls();
		getAssignedOrders();
		getKnowlarityCalls();
		getRawEngagements();
		getEscalatedCases();
		getCodOrders();
	};

	return (
		<>
			<div className="flex justify-between">
				<div className="flex gap-2">
					<DatePicker
						allowClear
						disabledDate={current => current.isAfter(moment(ed), "day")}
						format={"YYYY-MM-DD"}
						onChange={e =>
							e
								? setSD(e.startOf("day").toISOString())
								: setSD(
										moment().subtract(7, "days").startOf("day").toISOString()
								  )
						}
						placeholder="Start Date"
						value={moment(sd)}
					/>

					<DatePicker
						allowClear
						disabledDate={current => current.isBefore(moment(sd), "day")}
						format={"YYYY-MM-DD"}
						onChange={e =>
							e
								? setED(e.endOf("day").toISOString())
								: setED(moment().endOf("day").toISOString())
						}
						placeholder="End Date"
						value={moment(ed)}
					/>

					<Button type="primary" onClick={applyHandler}>
						Apply
					</Button>
				</div>

				{/* {showCreateTicket && <CreateTicket />} */}
			</div>

			<div
				className={`grid mt-4 ${
					repLevel === "3" ? "grid-cols-2" : "grid-cols-3"
				} gap-2`}>
				<Card
					loading={loadingRawEngagements}
					title={<Title level={4}>Schedule</Title>}>
					<Schedule data={engagementsCount} />
				</Card>

				{repLevel !== "3" && (
					<Card
						loading={loadingEscalatedCases}
						title={<Title level={4}>Escalated Cases</Title>}>
						<EscalatedCases data={escalatedCasesData} />
					</Card>
				)}

				<div className="flex flex-col gap-2">
					<Card
						loading={loadingKnowlarityCalls}
						title={<Title level={4}>Call Data</Title>}>
						<CallInfo counts={callCounts} />
					</Card>

					<Card
						loading={loadingCodOrders}
						title={<Title level={4}>Cod orders count</Title>}>
						<div className="flex gap-4 text-center">
							<Text className="capitalize" strong>
								Total Cod Orders
							</Text>
							<Text>{codOrders?.length}</Text>
						</div>
					</Card>

					{repLevel !== "3" && (
						<Card
							loading={loadingRawEngagements}
							title={<Title level={4}>Engagement Summary</Title>}>
							<EngagementSummary data={engagementSummary} />
						</Card>
					)}

					<div className="flex gap-2">
						<Card
							className="flex-1"
							loading={loadingAssignedOrders}
							title={<Title level={4}>In-Progress Orders</Title>}>
							<NewRepeatCount counts={newRepeatOrders?.inprogress} />
						</Card>

						<Card
							className="flex-1"
							loading={loadingAssignedOrders}
							title={<Title level={4}>Repeat Orders</Title>}>
							<div className="flex flex-col w-max text-center">
								<Text strong>Count</Text>
								<Text>{newRepeatOrders?.repeat}</Text>
							</div>
						</Card>
					</div>
				</div>
			</div>

			<div className="w-full flex mt-4 justify-end">
				<Filters
					setStartTime={setStartTime}
					setEndTime={setEndTime}
					setBnu={setBnu}
					setTag={setTag}
				/>
			</div>

			<TodaysEngagementsTimeline
				data={{
					engagements,
					reminders,
					temporaryEngagements,
					temporaryReminders,
				}}
				loading={
					loading ||
					loadingReminders ||
					loadingTempEngagements ||
					loadingTempReminders
				}
				refreshUI={refreshTodaysEngagements}
			/>

			<OverdueEngagements
				data={{ engagements: overdueEngagements, reminders: overdueReminders }}
				loading={loading || loadingReminders}
				refreshUI={refreshOverdueEngagements}
			/>
		</>
	);
};

export default MedRepEngagements;

const Filters = ({ setBnu }) => (
	<div className="flex gap-2">
		{/* <div className="flex gap-1">
			<TimePicker
				use12Hours
				format="h:mm a"
				placeholder="Start Time"
				onChange={time => setStartTime(time)}
			/>

			<TimePicker
				use12Hours
				format="h:mm a"
				placeholder="End Time"
				onChange={time => setEndTime(time)}
			/>
		</div> */}

		{/* <Select
			allowClear
			placeholder="Select Tag"
			dropdownStyle={{ minWidth: "10rem" }}
			onChange={item => setTag(item)}>
			<Option value="consult call">Consult Call</Option>
			<Option value="prescription call">Prescription Call</Option>
			<Option value="week #1">Week #1</Option>
			<Option value="week #2">Week #2</Option>
			<Option value="week #3">Week #3</Option>
			<Option value="week #4">Week #4</Option>
			<Option value="week #5">Week #5</Option>
			<Option value="week #6">Week #6</Option>
			<Option value="spe">SPE</Option>
		</Select> */}

		{/* <Select
			allowClear
			placeholder="Select Booking"
			dropdownStyle={{ minWidth: "6rem" }}
			onChange={item => setBnu(item)}>
			<Option value="booked">Booked</Option>
			<Option value="unbooked">Unbooked</Option>
		</Select> */}
	</div>
);

const EscalatedCases = ({ data = [] }) => {
	const getClasses = status => {
		if (status === "resolved") return "text-success";
		else if (status === "escalated") return "text-danger";
		else return "";
	};

	const columns = useMemo(() => {
		return [
			{
				title: "Name",
				key: "name",
				render: ({ escalation_status, name, key }) => (
					<Link
						className={getClasses(escalation_status)}
						to={`/lead-details/${key}`}>
						{name}
					</Link>
				),
			},
			{
				title: "Escalated At",
				dataIndex: "datetime",
				key: "escalated_at",
				render: datetime => moment(datetime).format("DD MMM YYYY, h:mm a"),
			},
		];
	}, []);

	return (
		<Table
			columns={columns}
			scroll={{ y: 448 }}
			dataSource={data}
			pagination={false}
		/>
	);
};

const Schedule = ({ data = [] }) => {
	const columns = useMemo(() => {
		return [
			{
				title: "Tag",
				dataIndex: "key",
				key: "tag",
			},
			{
				title: "Total",
				dataIndex: "total",
				key: "total",
			},
			{
				title: "Booked",
				dataIndex: "booked",
				key: "booked",
			},
			{
				title: "Done",
				dataIndex: "done",
				key: "done",
			},
		];
	}, []);

	return (
		<Table
			columns={columns}
			scroll={{ y: 448 }}
			dataSource={data}
			pagination={false}
		/>
	);
};

const CallInfo = ({ counts = {} }) => (
	<div className="flex flex-col">
		<div className="flex gap-4">
			<Text className="capitalize" strong>
				call hours
			</Text>
			<Text>{secToHrs(counts.callhrs)} (Average hh:mm)</Text>
		</div>

		<div className="flex gap-4 text-center">
			<Text className="capitalize" strong>
				connected
			</Text>
			<Text>{counts.connected}</Text>
		</div>

		<div className="flex gap-4 text-center">
			<Text className="capitalize" strong>
				attempted
			</Text>
			<Text>{counts.attempted}</Text>
		</div>
	</div>
);

const NewRepeatCount = ({ counts = {} }) => (
	<div className="flex gap-4">
		<div className="flex flex-col text-center">
			<Text strong>New</Text>
			<Text>{counts.new}</Text>
		</div>

		<div className="flex flex-col text-center">
			<Text strong>Repeat</Text>
			<Text>{counts.repeat}</Text>
		</div>
	</div>
);

const EngagementSummary = ({ data }) => (
	<div className="flex gap-4">
		<Text>{`${data.percentage}% `}</Text>
		<Text>{`${data.total} calls`}</Text>
		<Text>{`${data.new_order} orders`}</Text>
	</div>
);
