import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../../pages/login/components/Login";
import Forgot from "../../pages/login/components/Forgot";
import Reset from "../../pages/login/components/Reset";

const Auth = () => {
	return (
		<Switch>
			<Route exact path="/" component={Login} />
			<Route exact path="/forgot-password" component={Forgot} />
			<Route exact path="/reset-password/:token" component={Reset} />
			<Redirect to="/" />
		</Switch>
	);
};

export default Auth;
