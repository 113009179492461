import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchRequest } from "../../helpers/fetchRequest";
import isEmpty from "lodash/isEmpty";
import { Parser } from "json2csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import moment from "moment";

import {
	MED_REP_ENGAGEMENTS_API,
	REP_REMINDERS_API,
} from "../../constants/apiUrls";
import { getValue } from "../../helpers";

function Downolod({ medRepId, startData, endDate, medRepName }) {
	const [overdueEngagements, setOverdueEngagements] = useState([]);
	const [overdueReminders, setOverdueReminders] = useState([]);
	const [loadingReminders, setLoadingReminders] = useState(false);
	const [csvDownloadLoading, setCsvDownloadLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	//fetch overdue engagements

	const getOverdueEngagements = useCallback(async () => {
		let res = {};
		setLoading(true);

		try {
			res = await fetchRequest(
				MED_REP_ENGAGEMENTS_API(medRepId, startData, endDate)
			);
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setOverdueEngagements([]);
			else setOverdueEngagements(res.data);
			setLoading(false);
		}
	}, [endDate, medRepId, startData]);

	useEffect(() => {
		getOverdueEngagements();
	}, [getOverdueEngagements]);

	//fetch overdue reminders
	const getOverdueReminders = useCallback(async () => {
		let res = {};
		setLoadingReminders(true);

		try {
			res = await fetchRequest(REP_REMINDERS_API(medRepId, startData, endDate));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setOverdueReminders([]);
			else setOverdueReminders(res.data);
			setLoadingReminders(false);
		}
	}, [endDate, medRepId, startData]);

	useEffect(() => {
		getOverdueReminders();
	}, [getOverdueReminders]);

	const data = useMemo(() => {
		if (isEmpty(overdueEngagements) && isEmpty(overdueReminders)) return [];
		const EngagementData = overdueEngagements.map(item => ({
			Name: getValue(item, "order.case.user.first_name"),
			Email: getValue(item, "order.case.user.email"),
			"Engagement Tag": getValue(item, "tag"),
			"Phone Number": getValue(item, "order.case.user.phone_number").toString(),
			"Date of Engagement": moment(getValue(item, "reminder_date")).format(
				"DD-MM-YYYY HH:MM"
			),
			"Order Id": getValue(item, "order.order_display_id"),
			"Hair Coach": getValue(item, "user.first_name"),
		}));

		const ReminderData = overdueReminders.map(item => ({
			Name: getValue(item, "case.user.first_name"),
			Email: getValue(item, "case.user.email"),
			"Engagement Tag": getValue(item, "tag"),
			"Phone Number": String(getValue(item, "case.user.phone_number")),
			"Date of Engagement": moment(getValue(item, "reminder_date")).format(
				"DD-MM-YYYY HH:MM"
			),
			"Order Id": item.case.orders[0].order_display_id,
			"Hair Coach": getValue(item, "user.first_name"),
		}));

		return [...EngagementData, ...ReminderData];
	}, [overdueEngagements, overdueReminders]);

	const downloadCSV = async (status, data, csvName) => {
		try {
			let opts = { includeEmptyRows: true };
			const parser = new Parser(opts);
			const csvString = parser.parse(data);
			const url = window.URL.createObjectURL(new Blob([csvString]));
			let anchorTag = document.createElement("a");
			anchorTag.href = url;
			anchorTag.target = "_Blank";
			anchorTag.download = csvName;
			document.body.appendChild(anchorTag);
			anchorTag.click();
			return Promise.resolve("success");
		} catch (error) {
			console.log(error);
			message.error("download error");
		}
	};

	const handleDownload = async e => {
		if (typeof medRepName !== "string") return;

		const _fileName = `engagements-${medRepName
			.toLowerCase()
			.replaceAll(" ", "_")}.csv`;

		setCsvDownloadLoading(true);
		await downloadCSV(e, data, _fileName);
		setCsvDownloadLoading(false);
	};
	return (
		<Button
			type="primary"
			className="ml-4"
			icon={<DownloadOutlined />}
			onClick={handleDownload}
			loading={csvDownloadLoading}>
			Download
		</Button>
	);
}

export default Downolod;
