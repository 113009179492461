import { Button, Modal } from "antd/es";
import React from "react";

const UploadFile = ({ children, title, modalVisible, setModalVisible }) => {
	const handleModalClose = () => {
		setModalVisible(false);
	};

	return (
		<Modal
			visible={modalVisible}
			title={title}
			onOk={handleModalClose}
			onCancel={handleModalClose}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}>
			{children}
		</Modal>
	);
};

export default UploadFile;
