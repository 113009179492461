import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { generateQuery, getQueryParams } from "../helpers";
import { Button, DatePicker, Form, Select, Space } from "antd";
const { Option } = Select;

const DoctorFilters = ({ setPageNo, setQueryParams }) => {
  const [form] = Form.useForm();
  const [assignedDoctor, setAssignedDoctor] = useState(
    getQueryParams("assignedDoctor")
  );

  const { doctors } = useSelector(state => ({
    doctors: window.getValue(state, "orderManagement.doctors.userRole"),
  }));

  useEffect(() => {
    form.setFieldsValue({
      assignedDoctor: assignedDoctor,
    });
  });

  const onSubmit = async items => {
    const filterItems = [];
    Object.entries(items).map(([key, value]) => {
      filterItems.push({ [key]: value });
      filterItems.push({ pageNumber: 1 });
      return filterItems;
    });
    const queryParams = generateQuery(filterItems);
    setQueryParams(queryParams);
    setPageNo(1);
  };

  const handleClear = () => {
    form.resetFields();
    setAssignedDoctor(undefined);
    form.submit();
  };

  return (
    <Form form={form} layout="inline" onFinish={onSubmit}>
      <Form.Item name="assignedDoctor">
        <Select
          allowClear
          dropdownStyle={{ minWidth: "10rem" }}
          onChange={e =>
            e ? setAssignedDoctor(e) : setAssignedDoctor(undefined)
          }
          placeholder="Doctor"
        >
          {doctors &&
            doctors.map(users => (
              <Option value={users.user.id} key={users.user.id}>
                {users.user.first_name}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
          <Button htmlType="button" onClick={handleClear}>
            Clear
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default DoctorFilters;
