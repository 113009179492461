import React, { useState } from "react";
import { getCurrentUserRole } from "../helpers";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Typography } from "antd";
const { Text } = Typography;

export default function NotAllowedError() {
  const history = useHistory();
  const [userRole] = useState(getCurrentUserRole());

  return (
    <Row justify="center">
      <Col>
        <Text type="danger">
          {userRole === "admin" ||
          userRole === "support" ||
          userRole === "read_only"
            ? `This case doesn't exist.`
            : `You are not allowed to access this case.`}
        </Text>
        <Button type="link" onClick={() => history.goBack()}>
          Go Back
        </Button>
      </Col>
    </Row>
  );
}
