import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import { SyncOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Row, Space, Tooltip, Typography } from "antd";
import { getEscalationLogs } from '../../../components/Escalation/service';
const { Text } = Typography;
const repId = localStorage.getItem("users");

const EscalationLogs = (logs) => {
	const params = useParams();
	const chatBody = useRef();
	const [escalationLogs, setEscalationLogs] = useState(logs);
	const [loading, setLoading] = useState(false);

	const fetchEscalationLogs = useCallback(async (caseId) => {
		setLoading(true);
		const res = await getEscalationLogs(caseId);
		setLoading(false);
		if (res.data) setEscalationLogs(res.data);
		else setEscalationLogs([]);
	}, []);

	useEffect(() => {
		if(params.id) {
			fetchEscalationLogs(params.id);
		}
	}, [params.id, fetchEscalationLogs]);

	const scrollToBottom = () =>
		chatBody.current?.scrollTo({ top: chatBody.current?.scrollHeight });

	useEffect(() => {
		if (!isEmpty(escalationLogs)) scrollToBottom();
	}, [escalationLogs]);

	return (
		<Row>
			<Col
				direction="vertical"
				span={24}
				style={{
					maxHeight: 320,
					minHeight: 320,
					overflowY: "scroll",
					padding: "1rem",
					width: "100%",
				}}
				ref={chatBody}>
				{isEmpty(escalationLogs) ? (
					<Row justify="center">
						<Col>
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</Col>
					</Row>
				) : (
					escalationLogs.map(chat => (
						<>
							<Row
								justify={
									!chat.meta?.resolvedBy
										? chat.meta?.raisedBy === repId ? 'end' : "start"
										: chat.meta?.resolvedBy === repId ? 'end' : "start"
									}
								>
								<Space
									direction="vertical"
									size={4}
									style={{
										backgroundColor:
											!chat.meta.resolvedBy
												? chat.meta?.raisedBy === repId ? "#D1FAE5" : '#F1F1F1' 
												: chat.meta?.resolvedBy === repId ? "#D1FAE5" : '#F1F1F1',
										borderRadius: 8,
										marginBottom: 8,
										maxWidth: 480,
										minWidth: 160,
										padding: ".5rem 1rem",
									}}>
									<Text>{chat.comment}</Text>
									<Text
										type="secondary"
										style={{
											display: "block",
											fontSize: 12,
											textAlign: "end",
										}}>
										{moment(chat.created_at).format("DD-MM-YYYY, hh:mm a")}
									</Text>
								</Space>
							</Row>
						</>
					))
				)}
			</Col>
			<Tooltip title="Refresh">
				<Button
					icon={<SyncOutlined spin={loading} />}
					onClick={() => {
						fetchEscalationLogs(params.id);
						scrollToBottom();
					}}
					type="link"
					style={{ position: "absolute", bottom: "0.5rem", left: "0.5rem" }}
				/>
			</Tooltip>
		</Row>
	);
};

export default EscalationLogs;