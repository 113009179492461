import React, { useState } from "react";
import { useSelector } from "react-redux";
import SalesReportContainer from "../../../pages/analytics/salesReport";
import PostSalesReportContainer from "../../../pages/analytics/postSalesReport";
import { EscalatedCasesForAdmin } from "../../../components";
import { Col, Row, Tabs } from "antd";
import SalesReport from "../../analytics/salesReportNew";
import PostSalesReport from "../../analytics/postSalesReportV2/PostSalesReport";
import CreateTicket from "../../ticketing/components/CreateTicket";
const { TabPane } = Tabs;

function DashboardAdmin() {
	const { loading, activityLogData } = useSelector(state => ({
		loading: window.getValue(state, "admindashboard.loading"),
		activityLogData: window.getValue(state, "admindashboard.activityLogData"),
	}));
	const [userLevel] = useState(localStorage.getItem("rep!level"));

	return (
		<Row justify="end" gutter={[8, 8]}>
			{/* <CreateTicket /> */}

			<Col span="24">
				{userLevel === "1" ? (
					<Tabs type="card" defaultActiveKey="escalated_cases">
						<TabPane key="escalated_cases" tab="Escalations">
							<EscalatedCasesForAdmin />
						</TabPane>

						<TabPane key="sales" tab="Sales Dashboard">
							<SalesReportContainer />
						</TabPane>

						<TabPane key="post_sales" tab="Post Sales Dashboard">
							<PostSalesReportContainer />
						</TabPane>

						<TabPane key="sales_dashboard" tab="Sales Dashboard (v2)">
							<SalesReport />
						</TabPane>

						<TabPane key="post_sales_v2" tab="Post Sales Dashboard (v2)">
							<PostSalesReport />
						</TabPane>

						{/*	<TabPane key="activity_log" tab="Activity Log">
					<List
						bordered
						className="scrollable"
						size="large"
						header={<Title level={4}>Recent Activity</Title>}
						loading={loading}
						dataSource={activityLogData}
						renderItem={item => (
							<List.Item type="flex" justify="space-between">
								{activityLog(item)}
								<Text style={{ minWidth: 112 }}>
									{moment(item.created_at).format("DD MMM YYYY, h:mm a")}
								</Text>
							</List.Item>
						)}
					/>
						</TabPane> */}
					</Tabs>
				) : (
					<EscalatedCasesForAdmin />
				)}
			</Col>
		</Row>
	);
}

export default DashboardAdmin;
