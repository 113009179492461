import isEmpty from "lodash/isEmpty";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader } from "../../common";
import { TRAYA_CART_LINK, UTM_INFO } from "../../constants";
import { DISCOUNT_CODES_API } from "../../constants/apiUrls";
import { getValue } from "../../helpers";
import { fetchRequest } from "../../helpers/fetchRequest";
import { getProductsMapping } from "../../pages/configure/service";
import { getChats } from "../../pages/leadDetails/service";
import { placeOrder } from "./service";

// And Design
import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	Row,
	Select,
	Space,
	Typography,
} from "antd";

const { Paragraph, Text, Title } = Typography;

const PlaceOrderLinkModal = ({ isVisible, setIsVisible }) => {
	const { id: case_id } = useParams();
	const [form] = Form.useForm();
	const [generatedLink, setGeneratedLink] = useState("");
	const [sendingLink, setSendingLink] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [loadingDiscountCodes, setLoadingDiscountCodes] = useState(false);
	const [discountCodes, setDiscountCodes] = useState([]);
	const [selectedCode, setSelectedCode] = useState("");
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getProductsMapping();
	}, []);

	const { productsMapping, loading, usersDetail } = useSelector(state => ({
		productsMapping: getValue(state, "configure.productsMapping"),
		loading: getValue(state, "configure.productMappingLoading"),
		usersDetail: getValue(state, "leaddetails.usersDetail"),
	}));

	const order = usersDetail.orders[0] ? usersDetail.orders[0] : null;
	const user = usersDetail.user;

	useEffect(() => {
		if (!isVisible) return;

		form.setFieldsValue({
			name: user.first_name,
			email: user.email,
			phone: user.phone_number,
			address1: order?.order_meta.billing_address.address1,
			address2: order?.order_meta.billing_address.address2,
			city: order?.order_meta.billing_address.city,
			province: order?.order_meta.billing_address.province,
			zip: order?.order_meta.billing_address.zip,
			country: order?.order_meta.billing_address.country,
		});
	}, [order, isVisible, user]);

	// Fetch Discount Codes
	const getDiscountCodes = useCallback(async () => {
		setLoadingDiscountCodes(true);
		let res = {};

		try {
			res = await fetchRequest(DISCOUNT_CODES_API);
		} catch (error) {
		} finally {
			if (res.hasError) setDiscountCodes([]);
			else setDiscountCodes(res.data);
			setLoadingDiscountCodes(false);
		}
	}, []);

	useEffect(() => {
		getDiscountCodes();
	}, [getDiscountCodes]);
	// Ends

	const discountCodeOptions = useMemo(() => {
		if (isEmpty(discountCodes)) return [];
		return discountCodes.map(row => ({
			label: row.discount_code,
			value: row.discount_code,
		}));
	}, [discountCodes]);

	const products = useMemo(() => {
		if (isEmpty(productsMapping)) return [];

		return productsMapping.filter(product => {
			return (
				!isEmpty(product.product_principal_id) &&
				product.medicine?.medicine_slug !== "scalp_oil" &&
				product.is_combo == false
			);
		});
	}, [productsMapping]);

	const handleModalClose = () => {
		form.resetFields();
		setGeneratedLink("");
		setSelectedCode("");
		setTotalPrice(0);
		setIsVisible(false);
	};

	const handleSubmit = async data => {
		const _arr = [];
		const line_items = [];
		let discount_code = [];
		let billing_address = {};
		let final_data = {};
		let orders = {};

		if (totalPrice === 0) {
			message.error("Please select atleast a product to place an order!");
			return;
		}

		for (let key in data) {
			if (!data[key]) continue;
			_arr.push(`${key}:${data[key]}`);
		}
		for (let key in data) {
			if (!data[key] || isNaN(key)) continue;
			line_items.push({ variant_id: key, quantity: data[key] });
		}

		let discountObj = discountCodes.filter(discount => {
			return discount.discount_code === selectedCode;
		});

		discount_code.push({
			code: discountObj[0]?.discount_code,
			amount: discountObj[0]?.value.replace("%", ""),
			type: discountObj[0]?.type,
		});

		billing_address = {
			first_name: "",
			last_name: data.name,
			phone: data.phone,
			address1: data.address1,
			address2: data.address2,
			country: data.country,
			city: data.city,
			province: data.province,
			zip: data.zip,
		};

		orders = {
			line_items: line_items,
			email: data.email.split("+").join(""),
			phone: data.phone,
			billing_address: billing_address,
			shipping_address: billing_address,
			financial_status: "pending",
			discount_codes: discount_code,
		};

		final_data = { case_id: case_id, order: orders };
		setButtonLoading(true);
		const res = await placeOrder(final_data);

		if (res.status === 200) {
			message.success("Order Placed Successfully");
			setButtonLoading(false);
			handleModalClose();
		}
		if (!res.ok) {
			setButtonLoading(false);
		}
	};

	const handleFieldChange = () => {
		setGeneratedLink("");
		const _fields = form.getFieldsValue();

		let _totalPrice = 0;

		for (let key in _fields) {
			if (!_fields[key] || isNaN(_fields[key])) continue;

			const _price = products.find(product => {
				return product.product_principal_id === key;
			})?.product_price;

			if (_price === undefined) continue;

			_totalPrice = _totalPrice + _price * _fields[key];
		}

		setTotalPrice(_totalPrice);
	};

	return (
		<Modal
			title="Place COD Order"
			centered
			visible={isVisible}
			onOk={handleModalClose}
			onCancel={handleModalClose}
			width={840}
			footer={[
				<Button
					id="close_order_cart_link_btn"
					key="back"
					onClick={handleModalClose}>
					Close
				</Button>,
			]}>
			<>
				{!isEmpty(generatedLink) && (
					<Row align="middle" justify="center" style={{ marginBottom: 16 }}>
						<Paragraph
							copyable={{ tooltips: false }}
							ellipsis={true}
							strong
							style={{ width: 240 }}
							type="success">
							{generatedLink}
						</Paragraph>
					</Row>
				)}

				{loading ? (
					<Loader />
				) : (
					<Form
						name="order form link"
						form={form}
						onValuesChange={handleFieldChange}
						onFinish={handleSubmit}
						layout="horizontal">
						<div className="order_link_form">
							{products.map(product => (
								<Form.Item
									labelCol={{ span: 12 }}
									wrapperCol={{ span: 12 }}
									key={product.id}
									label={product.medicine.display_name}>
									<Space align="center">
										<Form.Item name={product.product_principal_id}>
											<InputNumber
												id={`${product.product_principal_id}_quantity_input`}
												placeholder="Quantity"
												max={35}
												min={1}
												style={{ width: 80 }}
											/>
										</Form.Item>

										<Text style={{ display: "block", marginBottom: 24 }}>
											x {product.product_price}
										</Text>
									</Space>
								</Form.Item>
							))}
						</div>
						<Row justify="center" style={{ marginBottom: 10 }}>
							<Title level={5}>Total value: {totalPrice}</Title>
						</Row>

						<Row className="mb-4" justify="space-between">
							<Title level={3}>Discount</Title>
							<Select
								size="large"
								allowClear
								loading={loadingDiscountCodes}
								options={discountCodeOptions}
								onChange={value => {
									setGeneratedLink("");
									setSelectedCode(value ? value : "");
								}}
								placeholder="Discount Code"
								value={isEmpty(selectedCode) ? null : selectedCode}
							/>
						</Row>
						<Divider>
							<Title level={3}>User Details</Title>
						</Divider>

						<div className="order_link_form">
							<Form.Item
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="name"
								label="Name">
								<Input
									placeholder="Name"
									disabled={true}
									style={{ width: 200 }}
								/>
							</Form.Item>
							<Form.Item
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="email"
								label="Email"
								rules={[
									{
										required: true,
										message: "Please fill this in!",
									},
									{
										type: "email",
										message: "Please enter valid email address",
									},
								]}>
								<Input placeholder="Email" style={{ width: 200 }} />
							</Form.Item>
							<Form.Item
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="phone"
								label="Phone No.">
								<Input
									placeholder="Phone"
									disabled={true}
									style={{ width: 200 }}
								/>
							</Form.Item>
							<Form.Item
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="address1"
								label="Address Line 1"
								rules={[
									{ required: true, message: "Please fill this in!" },
									{ whitespace: true, message: "Please fill this in!" },
									{
										max: 255,
										message: "Address can't be longer than 255 characters",
									},
								]}>
								<Input.TextArea placeholder="Address" style={{ width: 200 }} />
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: "Please fill this in!" },
									{ whitespace: true, message: "Please fill this in!" },
									{
										max: 255,
										message: "Address can't be longer than 255 characters",
									},
								]}
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="address2"
								label="Address Line 2">
								<Input.TextArea
									placeholder="Address Line 2"
									style={{ width: 200 }}
								/>
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: "Please fill this in!" },
									{ whitespace: true, message: "Please fill this in!" },
									{
										pattern: new RegExp(/^[a-zA-Z\s]+$/),
										message: "Please enter valid country",
									},
								]}
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="country"
								label="Country">
								<Input placeholder="Country" style={{ width: 200 }} />
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: "Please fill this in!" },
									{ whitespace: true, message: "Please fill this in!" },
									{
										pattern: new RegExp(/^[a-zA-Z\s]+$/),
										message: "Please enter valid city",
									},
								]}
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="city"
								label="City">
								<Input placeholder="City" style={{ width: 200 }} />
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: "Please fill this in!" },
									{ whitespace: true, message: "Please fill this in!" },
									{
										pattern: new RegExp(/^[a-zA-Z\s]+$/),
										message: "Please enter valid province",
									},
								]}
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="province"
								label="Province">
								<Input placeholder="Province" style={{ width: 200 }} />
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: "Please fill this in!" },
									{ whitespace: true, message: "Please fill this in!" },
									{
										pattern: new RegExp(/^[A-Z0-9\s]+$/),
										message: "Please enter valid zip code",
									},
								]}
								labelCol={{ span: 7 }}
								wrapperCol={{ span: 10 }}
								name="zip"
								label="Zip Code">
								<Input placeholder="Zip Code" style={{ width: 200 }} />
							</Form.Item>
						</div>
						<Row justify="center" style={{ marginBottom: 15 }}>
							<Text strong style={{ color: "red" }}>
								** Please ensure that the phone number and email is correct
								before placing the order **
							</Text>
						</Row>
						<Form.Item wrapperCol={{ span: 24 }}>
							<Row justify="center">
								<Space direction="vertical" align="center">
									<Button
										loading={buttonLoading}
										id="generate_order_cart_link_btn"
										type="primary"
										htmlType="submit">
										Generate
									</Button>
								</Space>
							</Row>
						</Form.Item>
					</Form>
				)}
			</>
		</Modal>
	);
};

export default PlaceOrderLinkModal;
