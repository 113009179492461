import React, { useMemo, useState } from "react";
import { baseURL } from "../../helpers/api-config";

// Ant design
import Button from "antd/es/button";
import Popover from "antd/es/popover";
import Typography from "antd/es/typography";
const { Paragraph } = Typography;

export const RescheduleSlotBtn = ({ caseId }) => {
	const [popupVisible, setPopupVisible] = useState(false);

	const popoverContent = useMemo(() => {
		return (
			<Paragraph
				copyable={{ onCopy: () => setPopupVisible(false), tooltips: false }}
				style={{ marginBottom: 0 }}>
				{`${baseURL.FORM_URL}pages/reschedule-slot/${caseId}`}
			</Paragraph>
		);
	}, [caseId]);

	return (
		<Popover
			content={popoverContent}
			placement="left"
			trigger="click"
			onVisibleChange={visible => setPopupVisible(visible)}
			visible={popupVisible}>
			<Button onClick={() => setPopupVisible(true)} type="primary">
				Booking Link
			</Button>
		</Popover>
	);
};
