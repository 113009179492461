import React, { useState } from "react";
import moment from "moment";

// Antd
import { Button, DatePicker, Form } from "antd/es";

const DateFilter = ({ startDate, endDate, setDate, reset }) => {
	const [form] = Form.useForm();

	const [sd, setSd] = useState(moment(startDate));
	const [ed, setEd] = useState(moment(endDate));

	const handleSubmit = () => {
		setDate(sd.toISOString(), ed.toISOString());
	};

	const handleClear = () => {
		form.resetFields();
		reset();
		setSd(moment().startOf("d"));
		setEd(moment().endOf("d"));
	};

	return (
		<div>
			<Form form={form} layout="inline" onFinish={handleSubmit}>
				<Form.Item name="startDate">
					<DatePicker
						allowClear
						defaultValue={sd}
						format="YYYY-MM-DD"
						onChange={e =>
							e ? setSd(moment(e).startOf("d")) : setSd(moment().startOf("d"))
						}
						disabledDate={c => c.isBefore(moment().subtract(30, "d"), "d")}
						placeholder="Start Date"
					/>
				</Form.Item>

				<Form.Item name="endDate">
					<DatePicker
						allowClear
						defaultValue={ed}
						format={"YYYY-MM-DD"}
						onChange={e =>
							e ? setEd(moment(e).endOf("d")) : setEd(moment().endOf("d"))
						}
						placeholder="End Date"
					/>
				</Form.Item>

				<Form.Item>
					<div className="flex gap-1">
						<Button type="primary" htmlType="submit">
							Apply
						</Button>

						<Button htmlType="button" onClick={handleClear}>
							Clear
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);
};

export default DateFilter;
