import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useReducer } from "react";
import { ACTIONS, INITIAL_VALUE, reducer } from "./store";

// Components
import AggregateSummary from "./components/AggregateSummary";
import DateFilter from "./components/DateFilter";
import EngagementOrderReport from "./components/engagementOrderReport";
import EngagementReport from "./components/engagementReport";
import RescheduledEngagements from "./components/rescheduledEngagements";
import ScheduleCompleteNotOrdered from "./components/scheduleCompleteNotOrdered";

import { apiCall } from "./api";
import { downloadCSV, downloadOption } from "./helpers";

import {
	engagementOrderReportFn,
	engagementOrdersFn,
	engagementReportFn,
	newOrdersFn,
	ordersFn,
	repeatOrdersFn,
	rescheduledEngagementsDataFn,
	rescheduledEngagementsFn,
	secToHrs,
	totalRevenueFn,
	week6DataFn,
	week6InfoFn,
} from "./transform";

// Antd
import { Button, Empty, message, Select, Typography } from "antd/es";
const { Paragraph, Text, Title } = Typography;

const PostSalesReport = () => {
	const [state, dispatch] = useReducer(reducer, INITIAL_VALUE);
	const {
		downloadingCSV,
		engagement_analytics,
		escalation_analytics,
		knowlarity_logs,
		assignment_summery,
		loading,
		order_analytics,
		outside_orders,
	} = state;

	useEffect(() => {
		return () => dispatch({ type: ACTIONS.RESET });
	}, []);

	const setLoading = loading => {
		dispatch({ type: ACTIONS.LOADING, payload: loading });
	};

	const updateState = payload => {
		dispatch({ type: ACTIONS.UPDATE, payload: payload });
	};

	const handleApiCall = async (sd, ed) => {
		setLoading(true);
		await apiCall(sd, ed, dispatch);
		setLoading(false);
	};

	const totalOrders = useMemo(() => {
		return ordersFn(order_analytics);
	}, [order_analytics]);

	const newOrders = useMemo(() => {
		return newOrdersFn(totalOrders, "count");
	}, [totalOrders]);

	const repeatOrders = useMemo(() => {
		return repeatOrdersFn(totalOrders, "count");
	}, [totalOrders]);

	const repeatOrdersRevenue = useMemo(() => {
		return totalRevenueFn(repeatOrders);
	}, [repeatOrders]);

	const week6Data = useMemo(() => {
		return week6DataFn(engagement_analytics);
	}, [engagement_analytics]);

	const week6Info = useMemo(() => {
		return week6InfoFn(week6Data);
	}, [week6Data]);

	const engagementReport = useMemo(() => {
		return engagementReportFn(
			engagement_analytics,
			knowlarity_logs,
			assignment_summery
		);
	}, [engagement_analytics, knowlarity_logs, assignment_summery]);

	const engagementOrders = useMemo(() => {
		return engagementOrdersFn(engagement_analytics);
	}, [engagement_analytics]);

	const engagementOrderReport = useMemo(() => {
		return engagementOrderReportFn(engagementOrders, outside_orders);
	}, [engagementOrders, outside_orders]);

	const rescheduledEngagements = useMemo(() => {
		return rescheduledEngagementsDataFn(engagement_analytics);
	}, [engagement_analytics]);

	const rescheduledEngagementsInfo = useMemo(() => {
		return rescheduledEngagementsFn(
			rescheduledEngagements,
			escalation_analytics
		);
	}, [rescheduledEngagements, escalation_analytics]);

	const formatAndDownloadCSV = (csvData, csvName) => {
		let formattedData = [];

		switch (csvName) {
			case "schedule_complete_not_ordered.csv":
				formattedData = csvData.map(data => {
					return {
						"Med Rep": data.rep_name,
						"Week 6 Total": data.week6,
						Answered: data.answered,
						"Auto Complete": data.auto_complete,
					};
				});
				break;

			case "engagement_number.csv":
				formattedData = csvData.map(data => {
					return {
						"Med Rep": data.medical_rep,
						"New Orders": data.new_orders,
						"Prescription Call": `${data.prescription_calls.total}/${data.prescription_calls.completed}`,
						"Week #1": `${data.week1_calls.total}/${data.week1_calls.completed}`,
						"Week #2": `${data.week2_calls.total}/${data.week2_calls.completed}`,
						"Week #3": `${data.week3_calls.total}/${data.week3_calls.completed}`,
						"Week #4": `${data.week4_calls.total}/${data.week4_calls.completed}`,
						"Week #5": `${data.week5_calls.total}/${data.week5_calls.completed}`,
						"Week #6": `${data.week6_calls.total}/${data.week6_calls.completed}`,
						"Call Duration":
							typeof data.call_logs === "object"
								? secToHrs(data.callhrs)
								: data.call_logs,
						"Active Customer": `${data.active_customer[0].active_cases}`,
					};
				});
				break;

			case "orders_from_engagement.csv":
				formattedData = csvData.map(data => {
					return {
						"Med Rep": data.medical_rep,
						"Prescription Call": `${data.prescription_calls.count}/${data.prescription_calls.revenue}`,
						"Week #1": `${data.week1_calls.count}/${data.week1_calls.revenue}`,
						"Week #2": `${data.week2_calls.count}/${data.week2_calls.revenue}`,
						"Week #3": `${data.week3_calls.count}/${data.week3_calls.revenue}`,
						"Week #4": `${data.week4_calls.count}/${data.week4_calls.revenue}`,
						"Week #5": `${data.week5_calls.count}/${data.week5_calls.revenue}`,
						"Week #6": `${data.week6_calls.count}/${data.week6_calls.revenue}`,
						"Outside Orders": `${data.outside_orders.count}/${data.outside_orders.revenue}`,
						Total: `${data.total.count}/${data.total.revenue}`,
					};
				});
				break;

			case "slot_bookings.csv":
				formattedData = csvData.map(data => {
					return {
						"Med Rep": data.medical_rep,
						"Prescription Call": `${data.prescription_calls.total}/${data.prescription_calls.completed}`,
						"Week #1": `${data.week1_calls.total}/${data.week1_calls.completed}`,
						"Week #2": `${data.week2_calls.total}/${data.week2_calls.completed}`,
						"Week #3": `${data.week3_calls.total}/${data.week3_calls.completed}`,
						"Week #4": `${data.week4_calls.total}/${data.week4_calls.completed}`,
						"Week #5": `${data.week5_calls.total}/${data.week5_calls.completed}`,
						"Week #6": `${data.week6_calls.total}/${data.week6_calls.completed}`,
						Escalations: data.escalations,
					};
				});
				break;

			default:
				break;
		}

		return Promise.resolve(downloadCSV(formattedData, csvName));
	};

	const handleDownload = async (info, data) => {
		updateState({ downloadInfo: info });
		updateState({ downloadingCSV: true });

		switch (info) {
			case "post_sales_orders":
				await downloadCSV(order_analytics, "post_sales_orders.csv");
				break;

			case "schedule_complete_not_ordered":
				formatAndDownloadCSV(data, "schedule_complete_not_ordered.csv");
				break;

			case "engagement_analytics":
				formatAndDownloadCSV(data, "engagement_number.csv");
				break;

			case "engagement_orders":
				formatAndDownloadCSV(data, "orders_from_engagement.csv");
				break;

			case "slot_bookings":
				formatAndDownloadCSV(data, "slot_bookings.csv");
				break;

			default:
				message.info("Wrong option");
				break;
		}

		updateState({ downloadingCSV: false });
	};

	return (
		<>
			<div className="w-full">
				<Title level={2}>Post Sales Dashboard</Title>
			</div>

			<div className="flex justify-between items-center w-full">
				<DateFilter
					apiCall={handleApiCall}
					loading={loading}
					reset={() => dispatch({ type: ACTIONS.RESET })}
				/>

				<Select
					allowClear
					disabled={isEmpty(order_analytics)}
					loading={downloadingCSV}
					onSelect={handleDownload}
					options={downloadOption}
					placeholder="Download"
					style={{ width: 160 }}
				/>
			</div>

			{order_analytics.length > 0 ? (
				<div className="grid mt-4 gap-2 admin__post_sales__dashboard-grid">
					<AggregateSummary loading={loading} title="Total Orders">
						<Text>{totalOrders.length}</Text>
					</AggregateSummary>

					<AggregateSummary loading={loading} title="New Orders">
						<Text>{newOrders.length}</Text>
					</AggregateSummary>

					<AggregateSummary loading={loading} title="Repeat Orders">
						<Text>{repeatOrders.length}</Text>
					</AggregateSummary>

					<AggregateSummary loading={loading} title="Repeat Revenue">
						<Text>{repeatOrdersRevenue}</Text>
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			{engagementReport.length > 0 ? (
				<div className="mt-4">
					<AggregateSummary
						headStyle={{ textAlign: "center" }}
						loading={loading}
						title="Engagement Number"
						extra={
							<Button
								disabled={isEmpty(engagementReport)}
								onClick={() =>
									handleDownload("engagement_analytics", engagementReport)
								}>
								Download
							</Button>
						}>
						<EngagementReport data={engagementReport} />
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			{engagementReport.length > 0 ? (
				<div className="mt-4">
					<AggregateSummary
						headStyle={{ textAlign: "center" }}
						loading={loading}
						title={"Schedule Complete, Not Ordered"}
						extra={
							<Button
								disabled={isEmpty(week6Info)}
								onClick={() =>
									handleDownload("schedule_complete_not_ordered", week6Info)
								}>
								Download
							</Button>
						}>
						<ScheduleCompleteNotOrdered data={week6Info} />
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			{engagementOrderReport.length > 0 ? (
				<div className="mt-4">
					<AggregateSummary
						headStyle={{ textAlign: "center" }}
						loading={loading}
						title="Orders From Engagement"
						extra={
							<Button
								disabled={isEmpty(engagementOrderReport)}
								onClick={() =>
									handleDownload("engagement_orders", engagementOrderReport)
								}>
								Download
							</Button>
						}>
						<EngagementOrderReport data={engagementOrderReport} />
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			{rescheduledEngagements.length > 0 ? (
				<div className="mt-4">
					<AggregateSummary
						headStyle={{ textAlign: "center" }}
						loading={loading}
						title="Slot bookings"
						extra={
							<Button
								disabled={isEmpty(rescheduledEngagementsInfo)}
								onClick={() =>
									handleDownload("slot_bookings", rescheduledEngagementsInfo)
								}>
								Download
							</Button>
						}>
						<RescheduledEngagements data={rescheduledEngagementsInfo} />
					</AggregateSummary>
				</div>
			) : (
				<div className="top-margin w-full">
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			)}

			<Paragraph>
				<ul>
					<li>
						<Text>
							Please note, we do not show zero value orders for New/Repeat
							Orders in this dashboard
						</Text>
					</li>
				</ul>
			</Paragraph>
		</>
	);
};

export default PostSalesReport;
