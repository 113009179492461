import React, { useState } from "react";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { DEACTIVATE_SALES_API } from "../../../constants/apiUrls";
import { getAllUsers } from "../service";

//antd
import { Button, message, Popconfirm, Modal } from "antd";
import Text from "antd/lib/typography/Text";

function DeactivateSalesRep({ userId, refreshUI }) {
	const [loading, setLoading] = useState(false);

	const handleDeactivate = async () => {
		let res = {};
		setLoading(true);

		try {
			res = await fetchRequest(DEACTIVATE_SALES_API(userId), {
				method: "PUT",
			});
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) {
				if (typeof res.data.message === "string") {
					message.error(`${res.data.message}`);
				} else {
					Modal.error({
						title: "Deactivation of this rep failed due to",
						content: (
							<>
								{Number(res.data.message.activeEngagementCaseCount) > 0 && (
									<p>
										<Text strong>
											No of active Engagement Cases:{" "}
											{res.data.message.activeEngagementCaseCount}
										</Text>
									</p>
								)}
								{Number(res.data.message.escalatedCasesCount) > 0 && (
									<p>
										<Text strong>
											No of escalated cases:{" "}
											{res.data.message.escalatedCasesCount}
										</Text>
									</p>
								)}
								{Number(res.data.message.slotCasesCount) > 0 && (
									<p>
										<Text strong>
											No of slot Cases: {res.data.message.slotCasesCount}
										</Text>
									</p>
								)}
								{Number(res.data.message.temporaryAssignedCases) > 0 && (
									<p>
										<Text strong>
											No of temorary assigned cases:{" "}
											{res.data.message.temporaryAssignedCases}
										</Text>
									</p>
								)}
								{Number(res.data.message.openOrderCasesCount) > 0 && (
									<p>
										<Text strong>
											No of open order cases:{" "}
											{res.data.message.openOrderCasesCount}
										</Text>
									</p>
								)}
							</>
						),
						onOk() {},
					});
				}
			} else {
				message.success("Deactivate successfully");
				getAllUsers();
				refreshUI();
			}

			setLoading(false);
		}
	};

	return (
		<div className="flex justify-end mb-4">
			<Popconfirm
				title={<Text>Are you sure you want to deactivate this rep?</Text>}
				placement="topRight"
				onConfirm={handleDeactivate}>
				<Button type="primary" loading={loading} danger>
					Deactivate
				</Button>
			</Popconfirm>
		</div>
	);
}
export default DeactivateSalesRep;
