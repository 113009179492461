import { salesDashboardActions } from "../actions";

export const salesDashboard = (state = {}, action) => {
	switch (action.type) {
		case salesDashboardActions.GET_REMINDER_LOADING:
			return {
				...state,
				todaysReminderLoading: true,
			};
		case salesDashboardActions.GET_REMINDER_SUCCESS:
			return {
				...state,
				todaysReminders: action.payload,
				todaysReminderLoading: false,
			};
		case salesDashboardActions.GET_REMINDER_FAILURE:
			return {
				...state,
				todaysReminders: null,
				todaysReminderLoading: false,
			};

		case salesDashboardActions.DELETE_REMINDER_LOADING:
			return {
				...state,
				deleteReminderLoading: true,
			};
		case salesDashboardActions.DELETE_REMINDER_SUCCESS:
			return {
				...state,
				deleteReminderStatus: action.payload,
				deleteReminderLoading: false,
			};
		case salesDashboardActions.DELETE_REMINDER_FAILURE:
			return {
				...state,
				deleteReminderStatus: null,
				deleteReminderLoading: false,
			};

		default:
			return state;
	}
};
