import React, { useCallback, useState } from 'react';

// Antd
import { Button, Form, Input, message, Modal, Row, Select } from 'antd/es';
import { useEffect } from 'react';
import { getUrl } from '../../../constants/apiUrls';
import { fetchRequest } from '../../../helpers/fetchRequest';
import { getSkus, getWarehouses } from '../services';

const CreateSkus = ({ updateUI }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [openMoal, setOpenMoal] = useState(false);
	const [skus, setSkus] = useState([]);
	const [warehouses, setWarehouses] = useState([]);

	const fetchSkus = useCallback(async () => {
		let res = await getSkus();
		res = res.map(item => ({
			value: item.id,
			label: item.medicine.display_name,
		}));
		setSkus(res);
	}, []);

	const fetchWarehouses = useCallback(async () => {
		let res = await getWarehouses();
		res = res.map(item => ({ value: item.id, label: item.name }));
		setWarehouses(res);
	}, []);

	useEffect(() => {
		fetchSkus();
		fetchWarehouses();
	}, [fetchSkus, fetchWarehouses]);

	const createInward = async data => {
		setLoading(true);
		let res = {};

		try {
			res = await fetchRequest(getUrl('warehouse-skus'), {
				method: 'POST',
				body: JSON.stringify({
					warehouse_id: data.warehouse_id,
					sku_id: data.sku_id,
					available: data.available,
					on_hold: data.on_hold,
					buffer: data.buffer,
					reject: data.reject,
				}),
			});
		} catch (error) {
			console.error(error);
		} finally {
			if (res.hasError) {
				message.error('Failed to create warehouse skus');
			} else {
				message.success('Warehouse sku created successfully');
				if (updateUI) updateUI();
				handleModalClose();
			}
			setLoading(false);
		}
	};

	const handleModalClose = () => {
		setOpenMoal(false);
		form.resetFields();
	};

	return (
		<>
			<Button type="primary" onClick={() => setOpenMoal(true)} className="mb-4">
				Create Warehouse skus
			</Button>

			<Modal
				visible={openMoal}
				title="Create Warehouse skus"
				onOk={handleModalClose}
				onCancel={handleModalClose}
				footer={[
					<Button key="back" onClick={handleModalClose}>
						Cancel
					</Button>,
				]}>
				<Row justify="center" className="mt-4">
					<Form form={form} layout="vertical" onFinish={createInward}>
						<Form.Item
							label="Warehouse"
							name="warehouse_id"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={warehouses} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Skus"
							name="sku_id"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Select options={skus} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Available"
							name="available"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Input rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Buffer"
							name="buffer"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Input rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="On Hold"
							name="on_hold"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Input rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item
							label="Reject"
							name="reject"
							rules={[{ required: true, message: 'Please fill this' }]}>
							<Input rows={4} style={{ width: 320 }} />
						</Form.Item>

						<Form.Item className="text-center">
							<Button type="primary" htmlType="submit" loading={loading}>
								Create
							</Button>
						</Form.Item>
					</Form>
				</Row>
			</Modal>
		</>
	);
};

export default CreateSkus;
