export const supervisorActions = {
  GET_GROUPS_LOADING: "GET_GROUPS_LOADING",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAILURE: "GET_GROUPS_FAILURE",

  GET_GROUP_DETAILS_LOADING: "GET_GROUP_DETAILS_LOADING",
  GET_GROUP_DETAILS_SUCCESS: "GET_GROUP_DETAILS_SUCCESS",
  GET_GROUP_DETAILS_FAILURE: "GET_GROUP_DETAILS_FAILURE",

  GET_MY_TEAMS_LOADING: "GET_MY_TEAMS_LOADING",
  GET_MY_TEAMS_SUCCESS: "GET_MY_TEAMS_SUCCESS",
  GET_MY_TEAMS_FAILURE: "GET_MY_TEAMS_FAILURE",
};
