import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import Download from "./Downolod";

import {
	getUrl,
	MEDREP_TEMPORARY_ENGAGEMENTS_API,
	MED_REP_ENGAGEMENTS_API,
	REP_REMINDERS_API,
} from "../../constants/apiUrls";
import AdminMedRepTemporaryEngagements from "./AdminMedRepTemporaryEngagements";
import AdminMedRepEngagements from "./AdminMedRepEngagements";
import { fetchRequest } from "../../helpers/fetchRequest";
import { getValue } from "../../helpers";

import { Typography, DatePicker, Button } from "antd/es";
import { getMyTeam } from "../supervisor/service";
import { getMedicalReps } from "../orderManagement/service";
const { Title } = Typography;

const MEDREP_TEMPORARY_REMINDERS_API = (id, start, end) => {
	if (!start || !end) {
		return getUrl(`v2/medrep_temporary_reminders/${id}`);
	}
	return getUrl(
		`v2/medrep_temporary_reminders/${id}?startDate=${start}&endDate=${end}`
	);
};

const ManageMedRepEngagements = ({ role }) => {
	const { id: medRepId } = useParams();

	const [loading, setLoading] = useState(false);
	const [engagements, setEngagements] = useState([]);
	const [temporaryEngagements, setTemporaryEngagements] = useState([]);
	const [loadingTempEngagements, setLoadingTempEngagements] = useState(false);
	const [temporaryReminders, setTemporaryReminders] = useState([]);
	const [loadingTempReminders, setLoadingTempReminders] = useState(false);
	const [sd, setSD] = useState(moment().startOf("day").toISOString());
	const [ed, setED] = useState(moment().endOf("day").toISOString());
	const [startDate, setStartDate] = useState(sd);
	const [endDate, setEndDate] = useState(ed);
	const [reminders, setReminders] = useState([]);
	const [loadingReminders, setLoadingReminders] = useState(false);

	useEffect(() => {
		if (role === "admin") getMedicalReps();
		if (role === "medical_rep") getMyTeam();
	}, [role]);

	const { medicalReps, myTeams } = useSelector(state => ({
		medicalReps: getValue(state, "orderManagement.medicalReps.userRole"),
		myTeams: getValue(state, "supervisorsData.myTeam[0].userGroup"),
	}));

	const medReps = useMemo(() => {
		if (role === "admin") return medicalReps;
		if (role === "medical_rep") return myTeams;
	}, [medicalReps, myTeams, role]);

	//Fetch Temporary-Engagements
	const getTemporaryEngagements = useCallback(async () => {
		let res = {};
		setLoadingTempEngagements(true);

		try {
			res = await fetchRequest(
				MEDREP_TEMPORARY_ENGAGEMENTS_API(medRepId, sd, ed)
			);
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setTemporaryEngagements([]);
			else setTemporaryEngagements(res.data);
			setLoadingTempEngagements(false);
		}
	}, [ed, medRepId, sd]);

	useEffect(() => {
		getTemporaryEngagements();
	}, [getTemporaryEngagements]);
	//Ends

	//Fetch Temporary-Reminders
	const getTemporaryReminders = useCallback(async () => {
		let res = {};
		setLoadingTempReminders(true);

		try {
			res = await fetchRequest(
				MEDREP_TEMPORARY_REMINDERS_API(medRepId, sd, ed)
			);
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setTemporaryReminders([]);
			else setTemporaryReminders(res.data);
			setLoadingTempReminders(false);
		}
	}, [ed, medRepId, sd]);

	useEffect(() => {
		getTemporaryReminders();
	}, [getTemporaryReminders]);
	//Ends

	// Fetch Todays Engagements
	const getTodaysEngagements = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoading(true);

		try {
			res = await fetchRequest(MED_REP_ENGAGEMENTS_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setEngagements([]);
			else setEngagements(res.data);
			setLoading(false);
		}
	}, [medRepId, sd, ed]);

	useEffect(() => {
		getTodaysEngagements();
	}, [getTodaysEngagements]);
	//End

	// Fetch Reminders
	const getReminders = useCallback(async () => {
		if (!medRepId || !sd || !ed) return;

		let res = {};
		setLoadingReminders(true);

		try {
			res = await fetchRequest(REP_REMINDERS_API(medRepId, sd, ed));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setReminders([]);
			else setReminders(res.data);
			setLoadingReminders(false);
		}
	}, [medRepId, sd, ed]);

	useEffect(() => {
		getReminders();
	}, [getReminders]);

	// End

	const updateDate = (startDate, endDate) => {
		setSD(startDate);
		setED(endDate);
	};

	const medRepName = useMemo(() => {
		if (isEmpty(medReps)) return "";
		const _medrep = medReps.find(rep => rep.user.id === medRepId);
		return _medrep.user.first_name;
	}, [medRepId, medReps]);

	return (
		<>
			<div className="flex items-center justify-between mb-4 mt-4">
				<div>
					<Title level={3}>{medRepName}</Title>
				</div>

				<div>
					<DatePicker
						allowClear
						disabledDate={current => current.isAfter(moment(ed), "day")}
						format={"YYYY-MM-DD"}
						onChange={e =>
							e
								? setStartDate(e.startOf("day").toISOString())
								: setStartDate(moment().startOf("day").toISOString())
						}
						placeholder="Start Date"
						value={moment(startDate)}
					/>

					<DatePicker
						allowClear
						disabledDate={current => current.isBefore(moment(sd), "day")}
						format={"YYYY-MM-DD"}
						onChange={e =>
							e
								? setEndDate(e.endOf("day").toISOString())
								: setEndDate(moment().endOf("day").toISOString())
						}
						placeholder="End Date"
						value={moment(endDate)}
					/>

					<Button
						type="primary"
						className="ml-1"
						onClick={() => updateDate(startDate, endDate)}>
						Submit
					</Button>
					<Download
						medRepId={medRepId}
						startData={startDate}
						endDate={endDate}
						medRepName={medRepName}
					/>
				</div>
			</div>

			<AdminMedRepEngagements
				engagements={{ engagement: engagements, reminders: reminders }}
				loading={loading || loadingReminders}
			/>

			<AdminMedRepTemporaryEngagements
				engagements={temporaryEngagements}
				reminders={temporaryReminders}
				loading={loadingTempEngagements || loadingTempReminders}
			/>
		</>
	);
};

export default ManageMedRepEngagements;
