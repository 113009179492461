import Cookies from "js-cookie";
import { baseURL } from "../../helpers";
import { store } from "../../redux/store";
import { message } from "antd";
import { salesDashboardActions, adminDashboardActions } from "./actions";
import { handleError } from "../../helpers";

export const getTodaysReminder = async queryParams => {
	store.dispatch({
		type: salesDashboardActions.GET_REMINDER_LOADING,
		payload: true,
	});

	try {
		const res = await fetch(`${baseURL.API_URL_BASE}reminders?${queryParams}`, {
			method: "GET",
			json: true,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			const data = await res.json();
			store.dispatch({
				type: salesDashboardActions.GET_REMINDER_SUCCESS,
				payload: data,
			});
		} else {
			store.dispatch({
				type: salesDashboardActions.GET_REMINDER_FAILURE,
				payload: null,
			});
			handleError(res);
		}
	} catch (error) {
		store.dispatch({
			type: salesDashboardActions.GET_REMINDER_FAILURE,
			payload: error.toString(),
		});
	}
};

export const updateReminder = async data => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}reminder/${data.reminder_id}`,
			{
				method: "PUT",
				json: true,
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			message.success("Reminder updated successfully");
		} else {
			const resJson = await res.json();
			message.error(resJson.message);
			handleError(res);
		}
		return res;
	} catch (error) {
		console.info(error.toString());
	}
};

export const deleteReminder = reminder_id => {
	store.dispatch({
		type: salesDashboardActions.DELETE_REMINDER_LOADING,
		payload: true,
	});
	return fetch(`${baseURL.API_URL_BASE}reminder/${reminder_id}`, {
		method: "DELETE",
		json: true,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	})
		.then(response => {
			if (response.status === 200) {
				store.dispatch({
					type: salesDashboardActions.DELETE_REMINDER_SUCCESS,
					payload: true,
				});
				message.success("Record Deleted Successfully!!!");
			} else {
				store.dispatch({
					type: salesDashboardActions.DELETE_REMINDER_FAILURE,
					payload: false,
				});
				handleError(response);
				message.error("Failed to Delete Record");
			}
			return response;
		})
		.catch(error => {
			store.dispatch({
				type: salesDashboardActions.UPDATE_REMINDER_FAILURE,
				payload: error.toString(),
			});
			handleError(error);
			message.success("Failed to Delete Record");
		});
};

export const getActivityLog = () => {
	store.dispatch({
		type: adminDashboardActions.GET_ACTIVITY_LOG_LOADING,
		payload: true,
	});
	return fetch(`${baseURL.API_URL_BASE}activity_log`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${Cookies.get("token")}`,
		},
	}).then(res => {
		if (res.status === 200) {
			res
				.json()
				.then(response => {
					if (response) {
						store.dispatch({
							type: adminDashboardActions.GET_ACTIVITY_LOG_SUCCESS,
							payload: response,
						});
					} else {
						store.dispatch({
							type: adminDashboardActions.GET_ACTIVITY_LOG_FAILURE,
							payload: response,
						});
					}
					return response;
				})
				.catch(error => {
					store.dispatch({
						type: adminDashboardActions.GET_ACTIVITY_LOG_FAILURE,
						payload: error.toString(),
					});
					handleError(error);
				});
		} else {
			return handleError(res);
		}
	});
};

export const markReminderDone = async (data, reminder_id) => {
	try {
		const res = await fetch(
			`${baseURL.API_URL_BASE}markReminderDone/${reminder_id}`,
			{
				method: "PUT",
				json: true,
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		);
		if (res.status === 200) {
			message.success("Successfully updated");
		} else {
			const resJson = await res.json();
			message.error(resJson.message);
			handleError(res);
		}
		return res;
	} catch (error) {
		message.info(error.toString());
	}
};

export const leadDisposition = async (data, id) => {
	try {
		const res = await fetch(`${baseURL.API_URL_BASE}lead-disposition/${id}`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		});
		if (res.status === 200) {
			message.success("Successfully updated");
		} else {
			const resJson = await res.json();
			message.error(resJson.message);
			handleError(res);
		}
		return res;
	} catch (error) {
		message.info(error.toString());
	}
};
