import { medicinesActions, productMappingActions } from "../actions";

export const configure = (state = {}, action) => {
  switch (action.type) {
    case medicinesActions.GET_MEDICINES_LOADING:
      return {
        ...state,
        medicinesLoading: true,
      };
    case medicinesActions.GET_MEDICINES_SUCCESS:
      return {
        ...state,
        medicines: action.payload,
        medicinesLoading: false,
      };
    case medicinesActions.GET_MEDICINES_FAILURE:
      return {
        ...state,
        medicines: null,
        medicinesLoading: false,
      };

    case productMappingActions.GET_PRODUCT_MAPPING_LOADING:
      return {
        ...state,
        productMappingLoading: true,
      };
    case productMappingActions.GET_PRODUCT_MAPPING_SUCCESS:
      return {
        ...state,
        productsMapping: action.payload,
        productMappingLoading: false,
      };
    case productMappingActions.GET_PRODUCT_MAPPING_FAILURE:
      return {
        ...state,
        productsMapping: null,
        productMappingLoading: false,
      };
    default:
      return state;
  }
};
