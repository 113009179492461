import React, { useEffect, useMemo, useState } from "react";
import differenceBy from "lodash/differenceBy";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { assignDoctor, unAssignDoctor } from "../../doctors/service";
import { getCurrentUserRole } from "../../../helpers";
import { Button, Col, Empty, Modal, Row, Typography } from "antd";
const { Text } = Typography;

function AssignDoctor({
	isModalVisible,
	orderId,
	setIsModalVisible,
	orderStatus,
}) {
	const [disableBtn, setDisableBtn] = useState(false);
	const [userRole] = useState(getCurrentUserRole());
	const [loading, setLoading] = useState(false);
	const [assigningDocId, setAssigningDocId] = useState("");

	const { doctorsListData, assignedDoctorsList } = useSelector(state => ({
		doctorsListData: window.getValue(
			state,
			"doctorsData.doctorsListData.userRole"
		),
		assignedDoctorsList: window.getValue(
			state,
			"doctorsData.assignedDoctorsList[0].doctor"
		),
	}));

	useEffect(() => {
		setDisableBtn(
			orderStatus === "delivered" ||
				orderStatus === "shipped" ||
				orderStatus === "shipping_pending" ||
				orderStatus === "void"
		);
	}, [orderStatus]);

	const availableDoctors = useMemo(() => {
		return differenceBy(doctorsListData, assignedDoctorsList, e => e.user.id);
	}, [assignedDoctorsList, doctorsListData]);

	const handleModal = () => {
		setIsModalVisible(!isModalVisible);
	};

	const handleAssignDoctor = async user_id => {
		setLoading(true);
		setAssigningDocId(user_id);
		await assignDoctor(orderId, user_id);
		setLoading(false);
	};

	const handleUnAssignDoctor = async user_id => {
		setLoading(true);
		setAssigningDocId(user_id);
		await unAssignDoctor(orderId, user_id);
		setLoading(false);
	};

	return (
		<Modal
			title="Doctors"
			onCancel={handleModal}
			onOk={handleModal}
			visible={isModalVisible}
			footer={[
				<Button type="primary" key="back" onClick={handleModal}>
					Close
				</Button>,
			]}>
			{isEmpty(assignedDoctorsList)
				? (!canAssign.has(userRole) || disableBtn) && (
						<Row justify="center">
							<Col>
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									description={<span>No doctor assigned</span>}
								/>
							</Col>
						</Row>
				  )
				: assignedDoctorsList.map(item => (
						<p
							key={item.user.id}
							style={{
								display: "flex",
								justifyContent: "space-between",
								paddingRight: 10,
							}}>
							<Text>{`${item.user.first_name}`}</Text>
							{canAssign.has(userRole) && (
								<Button
									danger
									disabled={disableBtn || loading}
									loading={loading && assigningDocId === item.user_id}
									onClick={() => handleUnAssignDoctor(item.user.id)}>
									Unassign
								</Button>
							)}
						</p>
				  ))}

			{!isEmpty(doctorsListData) &&
				!disableBtn &&
				availableDoctors.map(item => (
					<p
						key={item.user.id}
						style={{
							display: "flex",
							justifyContent: "space-between",
							paddingRight: 10,
						}}>
						<Text>{`${item.user.first_name}`}</Text>
						<Button
							disabled={loading}
							type="primary"
							loading={loading && assigningDocId === item.user_id}
							onClick={() => handleAssignDoctor(item.user_id)}>
							Assign
						</Button>
					</p>
				))}
		</Modal>
	);
}

export default AssignDoctor;

const canAssign = new Set(["admin", "consult_rep", "medical_rep"]);
