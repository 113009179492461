export const leadManagementActions = {
	GET_RECENT_LEADS_LOADING: "GET_RECENT_LEADS_LOADING",
	GET_RECENT_LEADS_SUCCESS: "GET_RECENT_LEADS_SUCCESS",
	GET_RECENT_LEADS_FAILURE: "GET_RECENT_LEADS_FAILURE",

	GET_RECENT_ASSIGNED_LEADS_LOADING: "GET_RECENT_ASSIGNED_LEADS_LOADING",
	GET_RECENT_ASSIGNED_LEADS_SUCCESS: "GET_RECENT_ASSIGNED_LEADS_SUCCESS",
	GET_RECENT_ASSIGNED_LEADS_FAILURE: "GET_RECENT_ASSIGNED_LEADS_FAILURE",

	GET_UNANSWERED_LEADS_LEADS_LOADING: "GET_UNANSWERED_LEADS_LEADS_LOADING",
	GET_UNANSWERED_LEADS_LEADS_SUCCESS: "GET_UNANSWERED_LEADS_LEADS_SUCCESS",
	GET_UNANSWERED_LEADS_LEADS_FAILURE: "GET_UNANSWERED_LEADS_LEADS_FAILURE",

	GET_UNTOUCHED_LEADS_LEADS_LOADING: "GET_UNTOUCHED_LEADS_LEADS_LOADING",
	GET_UNTOUCHED_LEADS_LEADS_SUCCESS: "GET_UNTOUCHED_LEADS_LEADS_SUCCESS",
	GET_UNTOUCHED_LEADS_LEADS_FAILURE: "GET_UNTOUCHED_LEADS_LEADS_FAILURE",

	GET_ALL_LEADS_LOADING: "GET_ALL_LEADS_LOADING",
	GET_ALL_LEADS_SUCCESS: "GET_ALL_LEADS_SUCCESS",
	GET_ALL_LEADS_FAILURE: "GET_ALL_LEADS_FAILURE",

	GET_ALL_CASES_LOADING: "GET_ALL_CASES_LOADING",
	GET_ALL_CASES_SUCCESS: "GET_ALL_CASES_SUCCESS",
	GET_ALL_CASES_FAILURE: "GET_ALL_CASES_FAILURE",

	GET_SALES_REPS_LOADING: "GET_SALES_REPS_LOADING",
	GET_SALES_REPS_SUCCESS: "GET_SALES_REPS_SUCCESS",
	GET_SALES_REPS_FAILURE: "GET_SALES_REPS_FAILURE",

	GET_BOOKINGS_LOADING: "GET_BOOKINGS_LOADING",
	GET_BOOKINGS_SUCCESS: "GET_BOOKINGS_SUCCESS",
	GET_BOOKINGS_FAILURE: "GET_BOOKINGS_FAILURE",
};
