import React, { useEffect, useState } from "react";
import moment from "moment";
import { groupBy, orderBy, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentUserRole, handleError } from "../../../helpers";
import {
	BasicInfo,
	EditBasicInfo,
	NotificationPreferences,
} from "../components";
import { CustomerTag, Loader } from "../../../common";
import {
	knowlarityCall,
	handleDietPlan,
	viewDetails,
	getNotificationPreferences,
} from "../service";
import {
	OrderCartLink,
	PhotoLinkBtn,
	RescheduleSlotBtn,
	SendMessage,
} from "../../../components";
import dietPlanCustomer from "../../../helpers/dietPlanCustomer.json";
import { statusTagColor } from "../../../helpers/tagColor";
import { PhoneFilled, BellOutlined } from "@ant-design/icons";
import {
	Button,
	Col,
	message,
	Row,
	Space,
	Select,
	Tabs,
	Tag,
	Typography,
	Tooltip,
} from "antd";
import {
	formStatusTag,
	formStatusTitle,
} from "../../leadManagement/utils/formStatus";
import { PlaceOrderLink } from "../../../components/PlaceOrderLink/PlaceOrderLink";
import ConsultCallFormResponse from "../../../components/consultCall/ConsultCallFormResponse";
import { RESULT_PAGE_URL, RESULT_CHOLESTROL } from "../../../constants";
import CaseLatestSalesRep from "../../../components/caseLatestSalesRep";
import MedrepDetailPageBookings from "../../../components/bookings/MedrepDetailPageBookings";
import { UserStatus } from "./UserStatus";
const { TabPane } = Tabs;
const { Text, Title } = Typography;

const repLevel = localStorage.getItem("rep!level");

const UsersDetail = () => {
	const params = useParams();
	const [userRole] = useState(() => getCurrentUserRole());
	const [callStatus, setCallStatus] = useState(true);
	const [alternateCallStatus, setAlternateCallStatus] = useState(true);
	const [isEdit, setIsEdit] = useState(false);
	const [groupedRes, setGroupedRes] = useState([]);
	const [dietPlan, setDietPlan] = useState([]);
	const [isDietPlan, setIsDietPlan] = useState(true);
	const [isNotificationPreferences, setIsNotificationPreferences] =
		useState(false);
	const [notificationData, setNotificationData] = useState("");
	const [isSalesRepIsOrder, setIsSalesRepIsOrder] = useState(false);
	const [loadingSendBtn, setLoadingSendBtn] = useState(false);

	const { usersDetail, loading } = useSelector(state => ({
		usersDetail: window.getValue(state, "leaddetails.usersDetail"),
		loading: window.getValue(state, "leaddetails.usersDetailLoading"),
	}));

	const {
		assignment,
		feedback,
		responses,
		user,
		orders,
		caseTags,
		form_status,
	} = usersDetail;

	useEffect(() => {
		setIsSalesRepIsOrder(() => userRole === "sales" && !isEmpty(orders));
	}, [userRole, orders]);

	useEffect(() => {
		const group = groupBy(responses, e => e.synthetic_id);
		const orderGroup = orderBy(group, e => e[e.length - 1].created_at, [
			"desc",
		]);
		setGroupedRes(orderGroup);

		if (user) {
			const findDietPlan = dietPlanCustomer.customerDietPlan.find(
				diet => diet.value === user.diet_plan_link
			);

			if (findDietPlan) {
				let arr = [];
				arr.push(findDietPlan.value);
				setDietPlan(arr);
				setIsDietPlan(true);
			} else {
				setDietPlan([]);
			}
		}
	}, [responses, user]);

	let phone_number = "";

	const handleCall = async e => {
		if (e === "call") {
			phone_number = user.phone_number;
		} else {
			phone_number = user.alternate_phone;
		}
		const caseId = params.id;
		const response = await knowlarityCall(phone_number, caseId);
		if (response.status === 200) {
			if (e === "call") {
				setCallStatus(false);
			} else {
				setAlternateCallStatus(false);
			}
		} else {
			if (e === "call") {
				setCallStatus(true);
			} else {
				setAlternateCallStatus(true);
			}
		}
	};

	const handleCustomerDietPlan = async () => {
		setLoadingSendBtn(true);
		let customerId = user?.id;
		const response = await handleDietPlan(customerId, dietPlan);
		if (response.status === 200) {
			message.success("Diet plan set");
			customerId = "";
			setIsDietPlan(true);
			viewDetails(params.id);
		} else {
			handleError(response);
			message.error("Failed to set diet plan");
		}
		setLoadingSendBtn(false);
	};

	const handleDietPlanChange = dietLink => {
		setDietPlan(dietLink);
	};

	const handleNotificationPreferences = async userId => {
		const response = await getNotificationPreferences(userId);
		setNotificationData(response);
		setIsNotificationPreferences(true);
	};

	const redirectToResultPage = (value, gender) => {
		const cohort = value.find(i => i.question_id === "cohort");
		if (value[0]?.source === "cholesterol") {
			window.open(RESULT_CHOLESTROL(value[0]?.synthetic_id, cohort?.response));
		} else {
			window.open(
				RESULT_PAGE_URL(value[0]?.synthetic_id, cohort?.response, gender)
			);
		}
	};

	return (
		<>
			<div className="lead-info">
				<Row justify="space-between">
					<Col span={24}>
						<Space size={36} align="start">
							<Space direction="vertical">
								<Space size={4}>
									<Title level={4} style={{ marginBottom: 0, maxWidth: 420 }}>
										{user?.first_name}
									</Title>
									{repLevel === "1" ? (
										<UserStatus />
									) : (
										<>
											<Tag color={statusTagColor[usersDetail?.status]}>
												{usersDetail?.status?.toUpperCase()}
											</Tag>
											<Tooltip
												placement="bottom"
												title={formStatusTitle[form_status]}>
												<Tag color="magenta">{formStatusTag[form_status]}</Tag>
											</Tooltip>
										</>
									)}
								</Space>

								<Space>
									<Text strong>Gender : </Text>
									<Text>{user?.gender}</Text>
								</Space>
							</Space>

							{!hideContactDetails.has(userRole) && (
								<>
									<Space direction="vertical">
										<Space>
											<Text strong>Ph : </Text>
											<Text>{user?.phone_number}</Text>
											{!hideCallIcon.has(userRole) &&
												(callStatus ? (
													<PhoneFilled
														style={{ color: "green" }}
														onClick={() => handleCall("call")}
													/>
												) : (
													<PhoneFilled style={{ color: "red" }} />
												))}
										</Space>

										<Space>
											<Text strong>Alt. Ph : </Text>
											<Text>{user?.alternate_phone}</Text>
											{user?.alternate_phone &&
												!hideContactDetails.has(userRole) &&
												(alternateCallStatus ? (
													<PhoneFilled
														style={{ color: "green" }}
														onClick={() => {
															handleCall("alternateCall");
														}}
													/>
												) : (
													<PhoneFilled style={{ color: "red" }} />
												))}
										</Space>
									</Space>

									<Space>
										<Text strong>Chat Ph. : </Text>
										<Text> {user?.chat_phone_number}</Text>
									</Space>

									<Space direction="vertical">
										<Space>
											<Text strong>Email : </Text>
											<Text>{user?.email}</Text>
										</Space>

										<Space>
											<Text strong>Alt. Email : </Text>
											<Text>{user?.alternate_email}</Text>
										</Space>
									</Space>
								</>
							)}
						</Space>
					</Col>
				</Row>

				<Row justify="space-between" align="bottom">
					<Space>
						<Space>
							<Text strong>Sales Rep : </Text>
							{userRole === "admin" && !isEmpty(orders) ? (
								<CaseLatestSalesRep rep={assignment?.latest_sales_rep} />
							) : (
								<Text>
									{isEmpty(assignment?.latest_sales_rep)
										? "N/A"
										: assignment?.latest_sales_rep?.first_name}
								</Text>
							)}
						</Space>

						{!isEmpty(orders) && (
							<Space>
								<Text strong>Med Rep : </Text>
								<Text>
									{isEmpty(assignment) ? "N/A" : assignment?.first_name}
								</Text>
							</Space>
						)}

						{feedback && showFeedbak.has(userRole) && (
							<Space>
								<Text strong>Feedback : </Text>
								<Text>{feedback}</Text>
							</Space>
						)}
					</Space>

					<Space align="center" style={{ marginLeft: "auto" }}>
						{!hideDietPlan.has(userRole) && (
							<>
								<Space size={0}>
									<Select
										mode="multiple"
										dropdownStyle={{ minWidth: "16rem" }}
										style={{ width: "20rem" }}
										placeholder="Diet Plan"
										options={dietPlanCustomer.customerDietPlan}
										disabled={!showDietPlanSaveBtn.has(userRole)}
										onChange={handleDietPlanChange}
										value={dietPlan}
									/>

									{showDietPlanSaveBtn.has(userRole) && (
										<Button
											disabled={isEmpty(dietPlan)}
											style={{ marginLeft: "8px" }}
											loading={loadingSendBtn}
											onClick={handleCustomerDietPlan}
											type="primary">
											Send
										</Button>
									)}
								</Space>
							</>
						)}

						{/* {!isSalesRepIsOrder && <SetReminder />} */}
						{showSendOrderLinkBtn.has(userRole) && <OrderCartLink />}
						{showPlaceOrderLinkBtn.has(userRole) && <PlaceOrderLink />}
						{showSendMessageBtn.has(userRole) && <SendMessage />}
						{showGetPhotoLinkBtn.has(userRole) && (
							<PhotoLinkBtn caseId={params.id} />
						)}
						{showRescheduleSlotBtn.has(userRole) && (
							<RescheduleSlotBtn caseId={params.id} />
						)}

						{!hideEditBtn.has(userRole) && (
							<Button onClick={() => setIsEdit(true)}>Edit</Button>
						)}

						{showNotificationPreferences.has(userRole) && (
							<Button
								onClick={() =>
									handleNotificationPreferences(usersDetail.user_id)
								}
								icon={<BellOutlined />}></Button>
						)}
					</Space>
				</Row>

				{!isSalesRepIsOrder && (
					<Row>
						<Col span={8} style={{ marginTop: "10px" }}>
							<CustomerTag caseTags={caseTags} />
						</Col>
					</Row>
				)}
			</div>

			{userRole === "medical_rep" ? (
				<Row style={{ height: "auto" }}>
					<Col span={11}>
						<Row>
							<Space direction="vertical">
								<Col
									style={{
										border: "1px solid #F0F0F0",
										height: 400,
										overflowY: "auto",
										overflowX: "hidden",
									}}>
									<BasicInfo />
								</Col>
								<MedrepDetailPageBookings />
							</Space>
						</Row>
					</Col>

					<Col
						span={13}
						style={{ border: "1px solid #F0F0F0" }}
						className="lead-details-tabs">
						<Tabs type="card" defaultActiveKey="consult_call">
							<TabPane
								className="pr-2 overflow-y-auto"
								key="consult_call"
								tab="Consult notes"
								style={{ maxHeight: "700px" }}
							>
								<ConsultCallFormResponse />
							</TabPane>

							{loading ? (
								<Loader />
							) : (
								groupedRes &&
								Object.entries(groupedRes).map(([key, value]) => (
									<TabPane
										className="pr-2 overflow-y-auto"
										key={key}
										tab={moment(value[value.length - 1].created_at).format(
											"DD MMM YYYY, h:mm a"
										)}
										style={{ height: "auto" }}>
										<Row className="geturl-container" justify="end">
											<Space>
												{user?.gender === "F" && (
													<Button
														onClick={() => redirectToResultPage(value, "F")}
														type="primary">
														ResultF
													</Button>
												)}
												<Button
													onClick={() => redirectToResultPage(value, "M")}
													type="primary">
													Result4
												</Button>
											</Space>
										</Row>

										{value
											.sort((a, b) => {
												return new Date(a.created_at) - new Date(b.created_at);
											})
											.map(res => (
												<React.Fragment key={res.id}>
													<Title level={5}>{res.question}</Title>
													<Text>{res.response}</Text>
												</React.Fragment>
											))}
									</TabPane>
								))
							)}
						</Tabs>
					</Col>
				</Row>
			) : (
				<Row style={{ height: 600 }}>
					<Col
						span={8}
						style={{
							border: "1px solid #F0F0F0",
							height: 600,
							overflowY: "auto",
							overflowX: "hidden",
						}}>
						<BasicInfo />
					</Col>

					<Col
						span={16}
						style={{ border: "1px solid #F0F0F0" }}
						className="lead-details-tabs">
						<Tabs type="card" defaultActiveKey="consult_call">
							<TabPane
								className="pr-2 overflow-y-auto"
								key="consult_call"
								tab="Consult notes"
								style={{ height: 500 }}>
								<ConsultCallFormResponse />
							</TabPane>

							{loading ? (
								<Loader />
							) : (
								groupedRes &&
								Object.entries(groupedRes).map(([key, value]) => (
									<TabPane
										className="pr-2 overflow-y-auto"
										key={key}
										tab={moment(value[value.length - 1].created_at).format(
											"DD MMM YYYY, h:mm a"
										)}
										style={{ height: 500 }}>
										<Row className="geturl-container" justify="end">
											<Space>
												{user?.gender === "F" && (
													<Button
														onClick={() => redirectToResultPage(value, "F")}
														type="primary">
														ResultF
													</Button>
												)}
												<Button
													onClick={() => redirectToResultPage(value, "M")}
													type="primary">
													Result4
												</Button>
											</Space>
										</Row>

										{value
											.sort((a, b) => {
												return new Date(a.created_at) - new Date(b.created_at);
											})
											.map(res => (
												<React.Fragment key={res.id}>
													<Title level={5}>{res.question}</Title>
													<Text>{res.response}</Text>
												</React.Fragment>
											))}
									</TabPane>
								))
							)}
						</Tabs>
					</Col>
				</Row>
			)}

			<EditBasicInfo
				isModalVisible={isEdit}
				setIsModalVisible={setIsEdit}
				user={user}
				user_id={usersDetail.user_id}
			/>

			<NotificationPreferences
				isModalVisible={isNotificationPreferences}
				setIsModalVisible={setIsNotificationPreferences}
				userId={usersDetail.user_id}
				data={notificationData}
			/>
		</>
	);
};

export default UsersDetail;

const showRescheduleSlotBtn = new Set([
	"admin",
	"medical_rep",
	"sales",
	"consult_doctor",
	"support",
]);
const showFeedbak = new Set([
	"admin",
	"sales",
	"consult_doctor",
	"support",
	"read_only",
]);
const showSendOrderLinkBtn = new Set([
	"admin",
	"medical_rep",
	"sales",
	"consult_doctor",
	"support",
]);
const showPlaceOrderLinkBtn = new Set(["admin", "medical_rep"]);
const showSendMessageBtn = new Set([
	"admin",
	"medical_rep",
	"sales",
	"consult_doctor",
	"support",
]);
const showGetPhotoLinkBtn = new Set([
	"admin",
	"consult_rep",
	"medical_rep",
	"sales",
	"consult_doctor",
	"support",
]);
const hideEditBtn = new Set(["doctor", "read_only"]);
const hideContactDetails = new Set(["doctor"]);
const hideCallIcon = new Set(["support", "read_only"]);
const showDietPlanSaveBtn = new Set(["medical_rep", "consult_rep"]);
const hideDietPlan = new Set(["sales"]);
const showNotificationPreferences = new Set(["admin"]);
