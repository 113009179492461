import { supervisorActions } from "../actions/supervisorActions";

export const supervisorsData = (state = {}, action) => {
  switch (action.type) {
    case supervisorActions.GET_GROUPS_LOADING:
      return {
        ...state,
        groupsLoading: true,
      };
    case supervisorActions.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        groupsLoading: false,
      };
    case supervisorActions.GET_GROUPS_FAILURE:
      return {
        ...state,
        groups: action.payload,
        groupsLoading: false,
      };

    case supervisorActions.GET_GROUP_DETAILS_LOADING:
      return {
        ...state,
        groupDetailsLoading: true,
      };
    case supervisorActions.GET_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        groupDetails: action.payload,
        groupDetailsLoading: false,
      };
    case supervisorActions.GET_GROUP_DETAILS_FAILURE:
      return {
        ...state,
        groupDetails: action.payload,
        groupDetailsLoading: false,
      };

    case supervisorActions.GET_MY_TEAMS_LOADING:
      return {
        ...state,
        myTeamLoading: true,
      };
    case supervisorActions.GET_MY_TEAMS_SUCCESS:
      return {
        ...state,
        myTeam: action.payload,
        myTeamLoading: false,
      };
    case supervisorActions.GET_MY_TEAMS_FAILURE:
      return {
        ...state,
        myTeam: action.payload,
        myTeamLoading: false,
      };

    default:
      return state;
  }
};
