export const orderManagementActions = {
	GET_ALL_ORDERS_LOADING: "GET_ALL_ORDERS_LOADING",
	GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
	GET_ALL_ORDERS_FAILURE: "GET_ALL_ORDERS_FAILURE",

	GET_OPEN_ORDERS_LOADING: "GET_OPEN_ORDERS_LOADING",
	GET_OPEN_ORDERS_SUCCESS: "GET_OPEN_ORDERS_SUCCESS",
	GET_OPEN_ORDERS_FAILURE: "GET_OPEN_ORDERS_FAILURE",

	GET_RECENT_ORDERS_LOADING: "GET_RECENT_ORDERS_LOADING",
	GET_RECENT_ORDERS_SUCCESS: "GET_RECENT_ORDERS_SUCCESS",
	GET_RECENT_ORDERS_FAILURE: "GET_RECENT_ORDERS_FAILURE",

	GET_MEDICAL_REPS_LOADING: "GET_MEDICAL_REPS_LOADING",
	GET_MEDICAL_REPS_SUCCESS: " GET_MEDICAL_REPS_SUCCESS",
	GET_MEDICAL_REPS_FAILURE: "GET_MEDICAL_REPS_FAILURE",

	GET_CONSULT_REPS_LOADING: "GET_CONSULT_REPS_LOADING",
	GET_CONSULT_REPS_SUCCESS: " GET_CONSULT_REPS_SUCCESS",
	GET_CONSULT_REPS_FAILURE: "GET_CONSULT_REPS_FAILURE",

	GET_GHOST_ORDERS_LOADING: "GET_GHOST_ORDERS_LOADING",
	GET_GHOST_ORDERS_SUCCESS: "GET_GHOST_ORDERS_SUCCESS",
	GET_GHOST_ORDERS_FAILURE: "GET_GHOST_ORDERS_FAILURE",

	GET_ORDERS_ASSIGNED_DOCTORS_LOADING: "GET_ORDERS_ASSIGNED_DOCTORS_LOADING",
	GET_ORDERS_ASSIGNED_DOCTORS_SUCCESS: "GET_ORDERS_ASSIGNED_DOCTORS_SUCCESS",
	GET_ORDERS_ASSIGNED_DOCTORS_FAILURE: "GET_ORDERS_ASSIGNED_DOCTORS_FAILURE",

	GET_DOCTORS_LOADING: "GET_DOCTORS_LOADING",
	GET_DOCTORS_SUCCESS: "GET_DOCTORS_SUCCESS",
	GET_DOCTORS_FAILURE: "GET_DOCTORS_FAILURE",
};
