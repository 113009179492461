import { message } from 'antd';
import Cookies from 'js-cookie';
import { baseURL, handleError } from '../../helpers';

const AUTH_HEADER = {
	headers: {
		'Content-Type': 'application/json',
		Authorization: `Bearer ${Cookies.get('token')}`,
	},
};

export const getInwards = async () => {
	let url = `${baseURL.API_URL_BASE}get-warehouse-inwards`;

	try {
		const _res = await fetch(url, {
			method: 'GET',
			...AUTH_HEADER,
		});

		if (_res.status === 200) {
			const _data = await _res.json();
			if (_data) {
				return Promise.resolve(_data);
			}
		} else {
			handleError(_res);
		}
	} catch {
		message.error('Something went wrong! Failed to fetch data');
	}
};

export const getWarehouseSkus = async () => {
	let url = `${baseURL.API_URL_BASE}get-warehouse-skus`;

	try {
		const _res = await fetch(url, {
			method: 'GET',
			...AUTH_HEADER,
		});

		if (_res.status === 200) {
			const _data = await _res.json();
			if (_data) {
				return Promise.resolve(_data);
			}
		} else {
			handleError(_res);
		}
	} catch {
		message.error('Something went wrong! Failed to fetch data');
	}
};

export const getSkus = async () => {
	let url = `${baseURL.API_URL_BASE}product_mapping`;

	try {
		const _res = await fetch(url, {
			method: 'GET',
			...AUTH_HEADER,
		});

		if (_res.status === 200) {
			const _data = await _res.json();
			if (_data) {
				return Promise.resolve(_data);
			}
		} else {
			handleError(_res);
		}
	} catch {
		message.error('Something went wrong! Failed to fetch data');
	}
};

export const getWarehouses = async () => {
	let url = `${baseURL.API_URL_BASE}get-all-warehouses`;

	try {
		const _res = await fetch(url, {
			method: 'GET',
			...AUTH_HEADER,
		});

		if (_res.status === 200) {
			const _data = await _res.json();
			if (_data) {
				return Promise.resolve(_data);
			}
		} else {
			handleError(_res);
		}
	} catch {
		message.error('Something went wrong! Failed to fetch data');
	}
};

export const getInwardSuppliers = async () => {
	let url = `${baseURL.API_URL_BASE}get-all-suppliers`;

	try {
		const _res = await fetch(url, {
			method: 'GET',
			...AUTH_HEADER,
		});

		if (_res.status === 200) {
			const _data = await _res.json();
			if (_data) {
				return Promise.resolve(_data);
			}
		} else {
			handleError(_res);
		}
	} catch {
		message.error('Something went wrong! Failed to fetch data');
	}
};
