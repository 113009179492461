import { ExclamationCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row, Space, Tabs, Typography } from "antd";
import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Loader, NotAllowedError } from "../../../common";
import { getQueryParams } from "../../../helpers/getQueryParams";
import {
	AddCommentAndReminder,
	OrderDetails,
	RecentComments,
	TagHistory,
	UsersDetail,
} from "../components";
import { viewDetails } from "../service";
import ActivityLog from "./ActivityLog";
import ChatBox from "./ChatBox";
import TicketHistory from "./TicketHistory";
import FreshchatBox from './FreschatBox';

const { TabPane } = Tabs;
const { Text } = Typography;

const ViewDetailsByDoctor = () => {
	const history = useHistory();
	const params = useParams();
	const location = useLocation();
	const [showAddComment, setShowAddComment] = useState(false);
	const [orderData] = useState(location.state);
	const [pageNo] = useState(parseInt(getQueryParams("current")) || 1);

	const { chats, detail } = useSelector(state => ({
		chats: window.getValue(state, "leaddetails.chats"),
		detail: window.getValue(state, "leaddetails.usersDetail"),
	}));

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		viewDetails(params.id);
	}, [params.id]);

	const chatListContent = () => (
		<Text type="warning">
			Chat number is available in{" "}
			<Text style={{ color: "#B45309" }}>{chats?.profileCount - 1}</Text> more
			profile, please contact admin or support for more details
		</Text>
	);

	if (!detail) return <Loader />;
	if (detail && detail.Error) return <NotAllowedError />;

	return (
		<div className="lead-details">
			<Row justify="space-between" style={{ paddingBottom: "1rem" }}>
				<Button onClick={history.goBack} type="link" icon={<LeftOutlined />}>
					Back
				</Button>
				{/* <Space>
					<ResolveEscalation />
				</Space> */}
			</Row>
			<Row>
				<Col span={24}>
					<UsersDetail />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<OrderDetails
						orderData={isEmpty(orderData) ? null : orderData.order_id}
					/>
				</Col>
			</Row>
			<Row>
				<Col
					offset={12}
					span={12}
					style={{
						border: "1px solid #F0F0F0",
						height: 385,
					}}>
					<Tabs
						defaultActiveKey="1"
						type="card"
						onChange={activeKey => setShowAddComment(activeKey === "2")}>
						<TabPane
							tab={
								chats?.profileCount > 1 ? (
									<Space>
										Chats
										<Popover content={chatListContent} title="">
											<ExclamationCircleOutlined style={{ color: "#D97706" }} />
										</Popover>
									</Space>
								) : (
									"Chats"
								)
							}
							key="1">
							<ChatBox />
						</TabPane>
						<TabPane tab="Chats from freshchats" key="6">
							<FreshchatBox />
						</TabPane>
						<TabPane tab="Recent Comments" key="2">
							<RecentComments />
						</TabPane>
						<TabPane tab="Activity Log" key="3">
							<ActivityLog />
						</TabPane>
						<TabPane tab="Tag History" key="4">
							<TagHistory />
						</TabPane>
						<TabPane tab="Ticket History" key="5">
							<TicketHistory />
						</TabPane>
					</Tabs>
				</Col>
			</Row>
			{showAddComment && (
				<Row>
					<Col offset={12} span={12}>
						<AddCommentAndReminder />
					</Col>
				</Row>
			)}
		</div>
	);
};

export default ViewDetailsByDoctor;
