import Cookies from "js-cookie";
import { baseURL, handleError } from "../../helpers";
import { store } from "../../redux/store";
import actions from "./action";

// Ant Design
import message from "antd/es/message";

const AUTH_HEADER = {
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${Cookies.get("token")}`,
	},
};

export const getSlotBookings = async queryParams => {
	store.dispatch({
		type: actions.GET_SLOT_BOOKINGS_LOADING,
		payload: true,
	});

	try {
		const _res = await fetch(
			`${baseURL.API_URL_BASE}all_bookings?${queryParams}`,
			{
				method: "GET",
				...AUTH_HEADER,
			}
		);

		if (_res.status === 200) {
			const _data = await _res.json();
			store.dispatch({
				type: actions.GET_SLOT_BOOKINGS_SUCCESS,
				payload: _data,
			});
		} else {
			store.dispatch({
				type: actions.GET_SLOT_BOOKINGS_FAILURE,
				payload: null,
			});
			handleError(_res);
		}
	} catch {
		store.dispatch({
			type: actions.GET_SLOT_BOOKINGS_FAILURE,
			payload: null,
		});
	}
};

export const downloadCsv = async queryParams => {
	const _query = new URLSearchParams(queryParams);
	const _meta = {};

	for (let item of _query) {
		_meta[item[0]] = item[1];
	}

	try {
		const _res = await fetch(`${baseURL.API_URL_BASE}request-report`, {
			method: "POST",
			...AUTH_HEADER,
			body: JSON.stringify({
				report: "SLOT_BOOKINGS",
				meta: _meta,
			}),
		});

		if (_res.status === 200) {
			message.success(
				`Your report will be sent to your email ${localStorage.getItem(
					"alternate!email"
				)} shortly`
			);
		} else {
			message.error("Something went wrong, please contact administrator");
		}
	} catch (error) {
		console.warn(error);
		message.error("Something went wrong, please contact administrator");
	}
};
