import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { fetchRequest } from "../helpers/fetchRequest";
import { ALL_SALES_TARGET_API } from "../constants/apiUrls";
import { getSalesReps } from "./leadManagement/service";

// Components
import Edit from "../components/salesManagement/Edit";
import TargetManagement from "../components/salesManagement/TargetManagement";

const SalesManagement = () => {
	const [currTarget, setCurrTarget] = useState({});
	const [loadingTarget, setLoadingTarget] = useState(false);
	const [showModal, setshowModal] = useState(false);
	const [targets, setTargets] = useState([]);

	useEffect(() => {
		getSalesReps();
	}, []);

	const { salesReps, loading } = useSelector(state => ({
		salesReps: window.getValue(state, "leadmanagement.salesReps.userRole"),
		loading: window.getValue(state, "leadmanagement.salesRepsLoading"),
	}));

	const getSalesTarget = useCallback(async () => {
		let res = {};
		setLoadingTarget(true);

		try {
			const startDate = moment().startOf("month").toISOString();
			const endDate = moment().endOf("month").toISOString();

			res = await fetchRequest(ALL_SALES_TARGET_API(startDate, endDate));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setTargets([]);
			else setTargets(res.data);
			setLoadingTarget(false);
		}
	}, []);

	useEffect(() => {
		getSalesTarget();
	}, [getSalesTarget]);

	const tableData = useMemo(() => {
		if (isEmpty(salesReps)) return [];
		const _data = salesReps.map(rep => {
			const target = targets.find(target => {
				return target.hair_coach_id === rep.user_id;
			});

			return {
				key: rep.user_id,
				rep_name: rep.user.first_name,
				...target,
			};
		});

		return _data.sort((a, b) => {
			return a.hair_coach_id?.localeCompare(b.hair_coach_id);
		});
	}, [salesReps, targets]);

	return (
		<>
			<TargetManagement
				data={tableData}
				loading={loading || loadingTarget}
				setCurrTarget={setCurrTarget}
				setshowModal={setshowModal}
			/>

			<Edit
				data={currTarget}
				showModal={showModal}
				setshowModal={setshowModal}
				updateUI={getSalesTarget}
			/>
		</>
	);
};
export default SalesManagement;
