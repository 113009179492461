import React from "react";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { Link } from "react-router-dom";
import { EscalatedCases } from "../../components";

// ant design
import { Tag, Typography } from "antd/es";
const { Text } = Typography;

export const EscalatedCasesForReps = () => {
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { key, phoneNo }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>

					<Text>{phoneNo}</Text>
				</div>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status = "", { key }) => (
				<Tag key={key} color={status === "resolved" ? "green" : "red"}>
					{status.toUpperCase()}
				</Tag>
			),
		},
		{
			title: "Escalation Date",
			dataIndex: "escalatedAt",
			key: "escalatedAt",
			render: escalatedAt => {
				if (isEmpty(escalatedAt)) return "-";
				return moment(escalatedAt).format("DD MMM YYYY, h:mm a");
			},
		},
	];

	return <EscalatedCases columns={columns} title="Escalated Cases" />;
};
