import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { MY_CONSULT_DOCTOR_BOOKINGS_API } from "../../../constants/apiUrls";
import { generateQuery, getQueryParams, getValue } from "../../../helpers";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { markSlotDismiss } from "../../leadDetails/service";
import { DateFilter } from "../../../components/DateFilter";

// Ant Design
import { CheckCircleOutlined } from "@ant-design/icons";
import { message, Popconfirm, Table, Tag, Tooltip } from "antd";

import Button from "antd/es/button";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import Text from "antd/lib/typography/Text";
const { Title } = Typography;

const DashboardConsultDoctor = () => {
	const history = useHistory();

	const [startDate] = useState(() => {
		return getQueryParams("startDate")
			? moment(getQueryParams("startDate"))
			: moment();
	});
	const [endDate] = useState(() => {
		return getQueryParams("endDate")
			? moment(getQueryParams("endDate"))
			: moment();
	});

	const [isLoading, setIsLoading] = useState(false);
	const [bookings, setBookings] = useState([]);

	const getMyBookings = useCallback(
		async (sd, ed) => {
			let res = {};

			try {
				if (!sd || !ed) return;

				const filterItems = [
					{ startDate: moment(sd).startOf("d").toISOString() },
					{ endDate: moment(ed).endOf("d").toISOString() },
				];

				const _query = generateQuery(filterItems);
				history.push({ search: _query });

				setIsLoading(true);
				res = await fetchRequest(MY_CONSULT_DOCTOR_BOOKINGS_API(_query));
			} catch (error) {
				console.warn(error.message);
			} finally {
				if (res.hasError) setBookings([]);
				else setBookings(res.data);
				setIsLoading(false);
			}
		},
		[history]
	);

	const tData = useMemo(() => {
		if (isEmpty(bookings)) return [];

		return bookings.map(item => ({
			key: getValue(item, "id"),
			case_id: getValue(item, "case.id"),
			name: getValue(item, "case.user.first_name"),
			phoneno: getValue(item, "case.user.phone_number"),
			email: getValue(item, "case.user.email"),
			status: getValue(item, "case.status"),
			tag: "slot_booking",
			slot_time: getValue(item, "slot.slot_time"),
			booking_time: getValue(item, "created_at"),
			payment_status: getValue(item, "payment_status"),
		}));
	}, [bookings]);

	const column = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { case_id, phoneno }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${case_id}`}>
						{name}
					</Link>

					<Text>{phoneno}</Text>
				</div>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (email, record) => (
				<div className="flex flex-col items-start">
					<Text>{email}</Text>
					{record.payment_status && (
						<Tag color={record.payment_status === "paid" ? "green" : "default"}>
							{record.payment_status.replaceAll("_", " ").toUpperCase()}
						</Tag>
					)}
				</div>
			),
		},
		{
			title: "Booking Time",
			dataIndex: "booking_time",
			key: "booking_time",
			render: time => moment(time).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Slot Time",
			dataIndex: "slot_time",
			key: "slot_time",
			defaultSortOrder: false,
			render: time => moment(time).format("DD MMM YYYY, h:mm a"),
			sorter: (a, b) => moment(a.time) - moment(b.time),
		},
		{
			title: "Actions",
			key: "actions",
			render: ({ key }) => (
				<Tooltip placement="bottom" title="Dismiss">
					<Popconfirm
						title="Would you like to dismiss this booking?"
						onConfirm={() => handleDismissSlotBooking(key)}
						okText="Yes"
						cancelText="No">
						<Button icon={<CheckCircleOutlined />} type="link" />
					</Popconfirm>
				</Tooltip>
			),
		},
	];

	const handleDismissSlotBooking = async id => {
		console.log("datasss", id);
		const _res = await markSlotDismiss(id);

		if (_res.status === 200) {
			message.success("Slot booking updated");
			getMyBookings();
		} else {
			console.log("error", _res);
			message.error("Operation Failed");
		}
	};

	useEffect(() => {
		getMyBookings(startDate, endDate);
	}, [endDate, getMyBookings, startDate]);

	const handleDateFilter = (start, end) => {
		getMyBookings(start, end);
	};

	const startDisableDate = (currDate, toDate) => {
		return currDate.isAfter(moment(toDate));
	};

	const endDisableDate = (currDate, toDate) => {
		return currDate.isBefore(moment(toDate));
	};

	return (
		<>
			<Title level={2}>My Appointments</Title>

			<Space direction="vertical">
				<DateFilter
					startDate={startDate}
					endDate={endDate}
					handleSubmit={handleDateFilter}
					isLoading={isLoading}
					startDisableDate={startDisableDate}
					endDisableDate={endDisableDate}
				/>
			</Space>
			<Table dataSource={tData} columns={column} />
		</>
	);
};

export default DashboardConsultDoctor;
