import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import {
	generateQuery,
	getQueryParams,
	getSearchParams,
} from "../../../helpers";
import { fetchAssignedLeadsData } from "../service";
import { LeadFilters } from "../../../common";
import { formStatusTitle } from "../../leadManagement/utils/formStatus";
import { statusTagColor } from "../../../helpers/tagColor";
import { Col, Row, Table, Tag, Typography } from "antd";
const { Text, Title } = Typography;

function AssignedLeads() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [queryParams, setQueryParams] = useState(getSearchParams);

	useEffect(() => {
		const filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });
		fetchAssignedLeadsData(queryParams);
	}, [history, queryParams]);

	const { loading, assignedLeadsData } = useSelector(state => ({
		loading: window.getValue(state, "assignedleads.loading"),
		assignedLeadsData:
			window.getValue(state, "assignedleads.assignedLeadsData") || null,
	}));

	useEffect(() => {
		if (isEmpty(assignedLeadsData)) setData([]);
		else {
			setData(() =>
				assignedLeadsData.data.map(row => ({
					key: window.getValue(row, "id"),
					name: window.getValue(row, "user.first_name"),
					phoneno: window.getValue(row, "user.phone_number"),
					email: window.getValue(row, "user.email"),
					status: window.getValue(row, "status"),
					assigned: window.getValue(row, "caseAssignment[0].created_at"),
					created: window.getValue(row, "created_at"),
					formStatus: window.getValue(row, "form_status"),
					lastFormFillDate: window.getValue(row, "last_form_fill_date"),
				}))
			);
			setPaginationInfo(assignedLeadsData.pagination);
		}
	}, [assignedLeadsData]);

	const leadsColumn = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (name, { key, phoneno }) => (
				<div className="flex flex-col">
					<Link className="link" to={`/lead-details/${key}`}>
						{name}
					</Link>
					<Text type="secondary">{phoneno}</Text>
				</div>
			),
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (status = "") => (
				<Tag color={statusTagColor[status]} key={status}>
					{status && status.toUpperCase()}
				</Tag>
			),
		},

		{
			title: "Last form fill date",
			dataIndex: "lastFormFillDate",
			key: "lastFormFillDate",
			align: "center",
			render: lastFormFillDate => (
				<Text>
					{isEmpty(lastFormFillDate)
						? "-"
						: moment(lastFormFillDate).format("DD MMM YYYY, h:mm a")}
				</Text>
			),
		},
		{
			title: "Assigned on",
			dataIndex: "assigned",
			key: "assigned",
			render: assigned => moment(assigned).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Created on",
			dataIndex: "created",
			key: "created",
			render: created => moment(created).format("DD MMM YYYY, h:mm a"),
		},
	];

	return (
		<>
			<Row justify="space-between">
				<Col>
					<Title level={2}>Assigned Leads</Title>
				</Col>
				<Col>
					<LeadFilters setPageNo={setPageNo} setQueryParams={setQueryParams} />
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Table
						columns={leadsColumn}
						dataSource={data}
						loading={loading}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
						onChange={e => setPageNo(e.current)}
					/>
				</Col>
			</Row>
		</>
	);
}

export default AssignedLeads;
