import Cookies from "js-cookie";
import { assignedLeadsActions } from "./actions";
import { baseURL } from "../../helpers";
import { store } from "../../redux/store";
import { handleError } from "../../helpers";

export const fetchAssignedLeadsData = async queryParams => {
  store.dispatch({
    type: assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_LOADING,
    payload: true,
  });
  try {
    const res = await fetch(
      `${baseURL.API_URL_BASE}assigned_cases?${queryParams}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      const data = await res.json();
      store.dispatch({
        type: assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_SUCCESS,
        payload: data,
      });
    } else {
      store.dispatch({
        type: assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_FAILURE,
        payload: null,
      });
      handleError(res);
    }
  } catch (error) {
    store.dispatch({
      type: assignedLeadsActions.FETCH_ASSIGNED_LEADS_DATA_FAILURE,
      payload: error.toString(),
    });
  }
};
