import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AllPrescriptions, CreatePrescription, PrescriptionStatus } from ".";
import { clearOrderItems, getPrescription } from "../../doctors/service";
import { getCurrentUserRole } from "../../../helpers";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";
const { Text, Title } = Typography;

function ManagePrescriptions() {
	const history = useHistory();
	const location = useLocation();
	const [caseId] = useState(location.state.caseId);
	const [order] = useState(location.state.order);
	const [prescriptionData, setPrescriptionData] = useState([]);
	const [showCreateUI, setShowCreateUI] = useState(false);
	const [hasWritten, setHasWritten] = useState(false);
	const [clonedOrderIDs, setClonedOrderIDs] = useState([]);
	const userRole = getCurrentUserRole();
	const [canCreate] = useState(
		() =>
			order.status !== "delivered" &&
			order.status !== "shipped" &&
			order.status !== "shipping_pending" &&
			order.status !== "void"
	);

	const { prescriptions } = useSelector(state => ({
		prescriptions: window.getValue(state, "doctorsData.prescriptions"),
	}));

	useEffect(() => {
		if (!isEmpty(caseId)) getPrescription(caseId);
	}, [caseId]);

	useEffect(() => {
		clearOrderItems();
	}, []);

	useMemo(() => {
		if (isEmpty(prescriptions)) setHasWritten(false);
		else {
			const prescription = prescriptions.filter(i => i.order_id === order.key);

			setHasWritten(
				prescription.some(i => i.user_id === localStorage.getItem("users"))
			);
		}
	}, [order.key, prescriptions]);

	return (
		<>
			<Row justify="start">
				<Col span={24}>
					<Button onClick={history.goBack} type="link" icon={<LeftOutlined />}>
						Back to Detail Page
					</Button>
				</Col>
			</Row>
			<Row>
				<Col
					span={12}
					style={{
						maxHeight: "40rem",
						overflowY: "scroll",
						padding: "1rem",
					}}>
					<Row justify="space-between">
						<Col>
							<Title level={4}>Prescriptions for {order.order_id}</Title>
						</Col>
						{canWritePrescription.has(userRole) && (
							<Col>
								<Button
									disabled={!canCreate || hasWritten}
									type="primary"
									onClick={() => setShowCreateUI(true)}>
									Create
								</Button>
							</Col>
						)}
					</Row>
					<Row>
						<Col>
							<Text type="secondary">{order.order_name}</Text>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							{showCreateUI && (
								<Card bordered>
									<CreatePrescription
										caseId={caseId}
										data={prescriptionData}
										isEdit={false}
										setClonedOrderIDs={setClonedOrderIDs}
										setData={setPrescriptionData}
										setShowUI={setShowCreateUI}
									/>
								</Card>
							)}
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<PrescriptionStatus />
						</Col>
					</Row>
				</Col>
				<Col
					span={12}
					style={{
						maxHeight: "40rem",
						overflowY: "scroll",
						padding: "1rem",
					}}>
					<Row>
						<Col span={24}>
							<Title level={4}>Past Prescriptions</Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<AllPrescriptions
								canCreate={canCreate}
								clonedOrderIDs={clonedOrderIDs}
								hasWritten={hasWritten}
								orderId={order.key}
								prescriptions={prescriptions}
								prescriptionData={prescriptionData}
								setClonedOrderIDs={setClonedOrderIDs}
								setPrescriptionData={setPrescriptionData}
								setShowUI={setShowCreateUI}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
}

export default ManagePrescriptions;

const canWritePrescription = new Set(["consult_rep", "medical_rep", "admin"]);
