import Text from "antd/es/typography/Text";
import React, { useCallback, useEffect, useState } from "react";
import { getUrl } from "../../../../constants/apiUrls";
import { fetchRequest } from "../../../../helpers/fetchRequest";
import AggregateSummary from "./AggregateSummary";

const AssignmentSummary = ({ medRepId }) => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	const fetchAssignmentSummary = useCallback(async () => {
		setLoading(true);
		const res = await fetchRequest(getUrl(`assignment-summary/${medRepId}`));
		setLoading(false);

		if (res.hasError) setData({});
		else setData(res.data);
	}, [medRepId]);

	useEffect(() => {
		fetchAssignmentSummary();
	}, [fetchAssignmentSummary]);

	return (
		<div className="grid gap-2 grid-cols-6">
			<AggregateSummary loading={loading} title="My Patients">
				<Text>{data.cases}</Text>
			</AggregateSummary>

			<AggregateSummary loading={loading} title="Active">
				<Text>{data.active_cases}</Text>
			</AggregateSummary>

			<AggregateSummary loading={loading} title="InActive">
				<Text>{data.inactive_cases}</Text>
			</AggregateSummary>

			<AggregateSummary loading={loading} title="Drop Off">
				<Text>{data.drop_off}</Text>
			</AggregateSummary>

			<AggregateSummary loading={loading} title="Not Ordered">
				<Text>{data.not_ordered}</Text>
			</AggregateSummary>

			<AggregateSummary loading={loading} title="New Customer">
				<Text>{data.new_cases}</Text>
			</AggregateSummary>
		</div>
	);
};

export default AssignmentSummary;
