import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { message } from "antd";
import { isEmpty } from "lodash";
import {
	getProfileData,
	profileUpdate,
	removeProfileImage,
} from "../pages/leadDetails/service";
import { useSelector } from "react-redux";
import { handleError } from "../helpers";

import {
	Button,
	Form,
	Input,
	Image,
	Modal,
	Upload,
	Space,
	Col,
	Row,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";

function Profile({ isModalVisible, setIsModalVisible, setProfileStatus }) {
	const [form] = Form.useForm();
	const [isEdited, setIsEdited] = useState(false);
	const [isImage, setIsImage] = useState(false);
	const [isImageJPEG, setIsImageJPEG] = useState(false);
	const [profileImageUrl, setProfileImageUrl] = useState("");
	const [isProfileImage, setIsProfileImage] = useState(false);
	const [userId] = useState(localStorage.getItem("users"));

	const { userProfileData } = useSelector(state => ({
		userProfileData: window.getValue(state, "leaddetails.profileData"),
	}));

	useEffect(() => {
		if (!isEmpty(userProfileData)) {
			try {
				form.setFieldsValue({
					first_name: userProfileData.userName,
					userAlternateEmail: userProfileData.userAlternateEmail,
				});
				setProfileImageUrl(userProfileData.profileImage);
			} catch (error) {
				console.log(error.toString());
			}
		}

		if (userProfileData.profileImage) {
			setIsProfileImage(true);
		} else {
			setIsProfileImage(false);
		}

		setIsEdited(false);
		setIsImage(false);
	}, [form, userProfileData]);

	useEffect(() => {
		if (isModalVisible) getProfileData(userId);
	}, [userId, isModalVisible]);

	const layout = {
		labelCol: {
			sm: { span: 6 },
		},
		wrapperCol: {
			sm: { span: 16 },
		},
	};

	const handleValue = e => {
		const fieldName = Object.keys(e)[0];
		if (fieldName === "first_name") {
			if (
				e.first_name.trim() !== userProfileData.userName &&
				isImageJPEG === false &&
				e.first_name.trim().match(/^[a-zA-Z\s]+$/)
			) {
				setIsEdited(true);
			} else {
				setIsEdited(false);
			}
		}

		if (fieldName === "userAlternateEmail") {
			if (
				e.userAlternateEmail.trim() !== userProfileData.userAlternateEmail &&
				isImageJPEG === false &&
				e.userAlternateEmail.toLowerCase().trim()
			) {
				setIsEdited(true);
			} else {
				setIsEdited(false);
			}
		}

		if (fieldName === "upload") {
			if (e.upload.file.type === "image/jpeg") {
				setIsEdited(true);
				setIsImageJPEG(false);
			} else {
				setIsEdited(false);
				setIsImageJPEG(true);
			}
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setIsImage(false);
		setIsEdited(false);
		setProfileStatus(null);
		form.resetFields();
	};

	const handleClear = () => {
		setIsImage(false);
		const name = form.getFieldsValue();
		const first_name = name.first_name;
		form.resetFields();
		form.setFieldsValue({
			first_name: first_name,
		});
		if (first_name !== userProfileData.userName) {
			setIsEdited(true);
		} else {
			setIsEdited(false);
		}
		setIsImageJPEG(false);
	};

	const handleSubmit = async items => {
		const image = items.upload?.file;
		const first_name = items.first_name.trim();
		const userAlternateEmail = items.userAlternateEmail.toLowerCase().trim();

		if (image) {
			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 960,
				useWebWorker: true,
			};
			const compressedFile = await imageCompression(image, options);
			const data = { compressedFile, first_name, userAlternateEmail };
			const response = await profileUpdate(userId, data);
			if (response.status === 200) {
				message.success("profile updated successfully");
				localStorage.setItem("userName", first_name);
				handleCancel();
			} else {
				message.error("failed to update the profile");
			}
		} else {
			const data = { first_name, userAlternateEmail };
			const response = await profileUpdate(userId, data);
			if (response.status === 200) {
				message.success("profile updated successfully");
				localStorage.setItem("userName", first_name);
				handleCancel();
			} else {
				message.error("failed to update the profile");
			}
		}
	};

	const handleRemoveProfileImage = async () => {
		const response = await removeProfileImage();
		if (response.status === 200) {
			message.success("Removed Profile Image");
			handleCancel();
		} else {
			message.error("Failed to remove profile Image");
			handleError(response);
		}
	};

	const BeforeUpload = file => {
		if (file.type !== "image/jpeg") {
			message.error(`only Jpeg image is allowed`);
			return true;
		}
		return false;
	};

	return (
		<>
			<Modal
				title="Profile"
				centered
				visible={isModalVisible}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						Cancel
					</Button>,
				]}>
				<Form
					{...layout}
					name="global_state"
					form={form}
					onValuesChange={handleValue}
					onFinish={handleSubmit}>
					<Row justify="center">
						<Col span={24}>
							<Form.Item
								name="first_name"
								label="Name"
								rules={[
									{ required: true, message: "Please enter your name" },
									{
										pattern: new RegExp(/^[a-zA-Z\s]+$/),
										message: "Please enter valid name",
									},
									{ whitespace: true, message: "Please enter valid name" },
									{
										max: 30,
										message: "Name can't be longer than 30 characters",
									},
								]}>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24}>
							<Form.Item
								label="Alternate Email"
								name="userAlternateEmail"
								rules={[
									{
										required: true,
										message: "Please enter your alternate email",
									},
									{
										type: "email",
										message: "Please enter valid alternate email address",
									},
								]}>
								<Input placeholder="john@gmail.com" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="image" label="Profile Image">
								<Image src={profileImageUrl} width={120} />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Row justify="center">
								<Form.Item name="upload">
									<Upload
										accept="image/jpeg"
										beforeUpload={file => BeforeUpload(file)}
										listType="picture"
										onChange={() => setIsImage(true)}
										onRemove={() => setIsImage(true)}
										className="upload-list-inline"
										disabled={isImage}>
										<Button icon={<UploadOutlined />} disabled={isImage}>
											Upload Profile Picture
										</Button>
									</Upload>
								</Form.Item>
							</Row>
						</Col>
						<Col span={24}>
							<Row justify="center">
								<Form.Item>
									<Space>
										<Button
											disabled={!isProfileImage}
											onClick={handleRemoveProfileImage}>
											Remove Profile Image
										</Button>
										<Button disabled={!isImage} onClick={handleClear}>
											Clear Image
										</Button>
										<Button
											type="primary"
											htmlType="submit"
											disabled={!isEdited}>
											Submit
										</Button>
									</Space>
								</Form.Item>
							</Row>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
}

export default Profile;
