import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { getAllUsers, setStatus, getInternalActivities} from "../service";
import { getValue } from "../../../helpers";
import CreateNewUser from "./CreateNewUser";

// Ant Design
import {
  Button,
  Col,
  Checkbox,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
  Tabs,
  List,
} from "antd";

import EditUser from "./EditUser";
import { InternalActivityLog } from "../../../helpers/internalActivityLog";

const { Search } = Input;
const { Text, Title } = Typography;
const { TabPane } = Tabs;

const UsersManagment = () => {
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [userEditModalVisible, setUserEditModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [tab, setTab] = useState("user_management");

  useEffect(() => {
    if(tab === "user_management")  {
      getAllUsers();
    } else if(tab === "activity_log") {
      getInternalActivities();
    }
  }, [tab]);

  const { allUsers, loading, allRoles, allActivities, loadingActivities } = useSelector((state) => ({
    allUsers: getValue(state, "usersmanagement.allUsers"),
    loading: getValue(state, "usersmanagement.allUsersLoading"),
    allRoles: getValue(state, "usersmanagement.allRoles"),
    allActivities: getValue(state, "usersmanagement.activity_logs"),
    loadingActivities: getValue(state, "usersmanagement.loading_logs"),
  }));

  const data = useMemo(() => {
    if (isEmpty(allUsers)) return [];

    return allUsers.map((row) => ({
      key: getValue(row, "user.id"),
      first_name: getValue(row, "user.first_name"),
      email: getValue(row, "user.email"),
      phone_number: getValue(row, "user.phone_number").substring(3),
      role: getValue(row, "role.name"),
      created: getValue(row, "user.created_at"),
      is_active: getValue(row, "user.is_active"),
      doctor_type: getValue(row, "user.doctorType[0].type"),
      breakWeekDays: getValue(row, "user.meta.breakWeekDays"),
      alternate_email: getValue(row, "user.alternate_email"),
      meta: getValue(row, "user.meta"),
      tip: getValue(row, "user.meta.bio.tip"),
      description: getValue(row, "user.meta.bio.description"),
      level: getValue(row, "user.level"),
      roleId: getValue(row, "role.id"),
    }));
  }, [allUsers]);

  useEffect(() => {
    if (isEmpty(searchText)) setTableData(data);
    else
      setTableData(() =>
        data.filter(
          (row) =>
            row.first_name
              ?.toLowerCase()
              .includes(searchText.toLowerCase().trim()) ||
            row.email
              ?.toLowerCase()
              .includes(searchText.toLowerCase().trim()) ||
            row.phone_number?.includes(searchText.trim())
        )
      );
  }, [data, searchText]);

  useEffect(() => {
    if (allRoles && !isEmpty(allRoles))
      setRoles(
        allRoles.map((item) => {
          return { text: item.name, value: item.name };
        })
      );
  }, [allRoles]);

  const handleStatus = (status, id) => setStatus(status, id);

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Phone No",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Alternate Email",
      dataIndex: "alternate_email",
      key: "alternate_email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",

      render: (role, record) => (
        <Space direction="vertical">
          <Text>{role}</Text>
          {record.doctor_type !== "" && (
            <Text type="success" style={{ textTransform: "capitalize" }}>
              {record.doctor_type}
            </Text>
          )}
        </Space>
      ),
      filters: roles,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => {
        return record.role === value;
      },
    },
    {
      title: "Active Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active, record) => (
        <Checkbox
          checked={is_active}
          onChange={({ target }) => handleStatus(target.checked, record.key)}
        >
          {is_active ? "Active" : "Inactive"}
        </Checkbox>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        //console.log("record", record);
        return (
          <>
            <Button
              onClick={() => {
                setCurrentUser(() => record);
                setUserEditModalVisible(true);
              }}
              type="link"
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const handleSearch = (text) => {
    setSearchText(text);
    setPageNo(1);
  };

  return (
    <>
      <Tabs defaultActiveKey={tab} onChange={setTab}>
        <TabPane tab="Users Management" key="user_management">
          <Form form={form} component={false}>
            <Row justify="end">
              <Col>
                <Search
                  allowClear
                  placeholder="Name, Email or Phone number"
                  onSearch={handleSearch}
                  onChange={({ target }) => handleSearch(target.value)}
                  maxLength={80}
                />
              </Col>
              <Col>
                <Button type="primary" onClick={() => setModalVisible(true)}>
                  Add New User
                </Button>
              </Col>
            </Row>
            <Table
              bordered
              columns={mergedColumns}
              dataSource={tableData}
              loading={loading}
              onChange={(e) => setPageNo(e.current)}
              pagination={{ current: pageNo, showSizeChanger: false }}
            />
          </Form>
        </TabPane>
        <TabPane tab="Activity Log" key="activity_log">
          <List
            header={null}
            footer={null}
            bordered
            loading={loadingActivities}
            dataSource={allActivities}
            renderItem={item => (
              <List.Item key={item._id}>
                <InternalActivityLog data={item} />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>

      <CreateNewUser
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />

      <EditUser
        modalVisible={userEditModalVisible}
        setModalVisible={setUserEditModalVisible}
        user={currentUser}
      />
    </>
  );
};

export default UsersManagment;
