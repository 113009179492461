import React, { useEffect } from "react";

import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import { googleLogin } from "../service";

import { GoogleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { GOOGLE_OAUTH_CLIENT_ID } from "../../../helpers";

const LoginWithGoogle = () => {
	useEffect(() => {
		const initClient = () => {
			gapi.client.init({
				clientId: GOOGLE_OAUTH_CLIENT_ID,
				scope: "",
			});
		};
		gapi.load("client:auth2", initClient);
	});

	const handleSuccess = async google_response => {
		console.log(google_response);
		await googleLogin(google_response);
		// console.log(google_response);
	};

	const handleFailure = google_response => {
		console.log(google_response);
	};

	return (
		<GoogleLogin
			clientId={GOOGLE_OAUTH_CLIENT_ID}
			cookiePolicy="single_host_origin"
			isSignedIn={false}
			onFailure={handleFailure}
			onSuccess={handleSuccess}
			render={renderProps => (
				<Button
					disabled={renderProps.disabled}
					onClick={renderProps.onClick}
					icon={<GoogleOutlined />}>
					Sign in with Google
				</Button>
			)}
		/>
	);
};

export default LoginWithGoogle;
