import React, { useState } from "react";

import { MARK_SHIPPED_API } from "../../../constants/apiUrls";
import { fetchRequest } from "../../../helpers/fetchRequest";
import { getCaseActivityLog, viewDetails } from "../service";

// Antd
import { Button, Form, Input, message, Modal } from "antd";

function ShipOrders({ isModalVisible, setIsModalVisible, orderId, caseId }) {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	const onModalClose = () => {
		setIsModalVisible(false);
		form.resetFields();
	};

	const handleSubmit = async ({ trackingId, trackingUrl }) => {
		let res = {};
		try {
			setLoading(true);
			res = await fetchRequest(MARK_SHIPPED_API(orderId), {
				method: "POST",
				body: JSON.stringify({
					trackingId: trackingId.trim(),
					trackingUrl: trackingUrl.trim(),
				}),
			});
			if (res.status === 200) {
				message.success("Order Shipped");
			} else {
				message.error("Order not shipped");
			}
			viewDetails(caseId);
			getCaseActivityLog(caseId);
			setIsModalVisible(false);
		} catch (e) {
			message.error("Order not shipped");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			<Modal
				title="Mark Shipped"
				onCancel={onModalClose}
				onOk={onModalClose}
				visible={isModalVisible}
				width={560}
				footer={[
					<Button type="primary" key="back" onClick={onModalClose}>
						Close
					</Button>,
				]}>
				<Form layout="vertical" form={form} onFinish={handleSubmit}>
					<Form.Item
						label="Tracking URL"
						name="trackingUrl"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input placeholder="Enter URL" />
					</Form.Item>
					<Form.Item
						label="Traking ID"
						name="trackingId"
						rules={[
							{
								required: true,
								message: "Please fill this",
							},
						]}>
						<Input placeholder="Enter Order Id" />
					</Form.Item>
					<Form.Item wrapperCol={{ offset: 10, span: 16 }}>
						<Button type="primary" htmlType="submit" loading={loading}>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
}

export default ShipOrders;
