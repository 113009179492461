export const dispatchDashboardActions = {
  GET_PRESCRIPTION_SHIPPING_ITEMS_LOADING:
    "GET_PRESCRIPTION_SHIPPING_ITEMS_LOADING",
  GET_PRESCRIPTION_SHIPPING_ITEMS_SUCCESS:
    "GET_PRESCRIPTION_SHIPPING_ITEMS_SUCCESS",
  GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE:
    "GET_PRESCRIPTION_SHIPPING_ITEMS_FAILURE",

  POST_MARK_SHIPPED_LOADING: "POST_MARK_SHIPPED_LOADING",
  POST_MARK_SHIPPED_SUCCESS: "POST_MARK_SHIPPED_SUCCESS",
  POST_MARK_SHIPPED_FAILURE: "POST_MARK_SHIPPED_FAILURE",
};
