import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { createGroup } from "../service";
import { Button, Form, Input, Modal, Radio, Row, Select } from "antd";
const { Option } = Select;

const CreateGroup = ({ isModalVisible, setIsModalVisible }) => {
  const [form] = Form.useForm();
  const [creatingGroup, setCreatingGroup] = useState(false);
  const [role, setRole] = useState("00000000-0000-0000-0000-000000000002");
  const [filteredUsers, setFilteredUser] = useState([]);

  const { allUsers, allUsersLoading } = useSelector(state => ({
    allUsers: window.getValue(state, "usersmanagement.allUsers"),
    allUsersLoading: window.getValue(state, "usersmanagement.allUsersLoading"),
  }));

  useEffect(() => {
    const init = async () => {
      const nfilteredUsers = await allUsers.filter(user => {
        return user.role_id === role;
      });

      setFilteredUser([...nfilteredUsers]);
    };

    if (isEmpty(allUsers)) setFilteredUser([]);
    else init();
  }, [allUsers, role]);

  const handleModalClose = () => {
    form.resetFields();
    setRole("00000000-0000-0000-0000-000000000002");
    setIsModalVisible(false);
  };

  const onSubmit = async ({ group_name, supervisor_id }) => {
    setCreatingGroup(true);
    const res = await createGroup(group_name, supervisor_id);
    if (res) handleModalClose();
    setCreatingGroup(false);
  };

  const handleRoleChange = e => {
    setRole(e.target.value);
    form.setFieldsValue({ supervisor_id: "" });
  };

  return (
    <Modal
      visible={isModalVisible}
      title="Create Group"
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={[
        <Button key="back" onClick={handleModalClose}>
          Cancel
        </Button>,
      ]}
    >
      <Row align="middle" justify="center">
        <Form
          form={form}
          initialValues={{ role: "00000000-0000-0000-0000-000000000002" }}
          layout="vertical"
          name="create_group"
          onFinish={onSubmit}
        >
          <Form.Item
            label="Group Name"
            name="group_name"
            rules={[
              {
                required: true,
                message: "Please fill this",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Please fill this",
              },
            ]}
          >
            <Radio.Group onChange={handleRoleChange}>
              <Radio value="00000000-0000-0000-0000-000000000002">
                Medical Rep
              </Radio>
              <Radio value="00000000-0000-0000-0000-000000000003">
                Sales Rep
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Supervisor"
            name="supervisor_id"
            rules={[
              {
                required: true,
                message: "Please fill this",
              },
            ]}
          >
            <Select
              allowClear
              loading={allUsersLoading}
              showSearch
              style={{ width: 200 }}
              optionFilterProp="children"
            >
              {filteredUsers.map(user => (
                <Option value={user.user_id} key={user.user_id}>
                  {user.user.first_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" loading={creatingGroup} htmlType="submit">
              Create Group
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  );
};

export default CreateGroup;
