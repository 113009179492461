export const engagementActions = {
  PENDING_ENGAGEMENTS_LOADING: "PENDING_ENGAGEMENTS_LOADING",
  PENDING_ENGAGEMENTS_SUCCESS: "PENDING_ENGAGEMENTS_SUCCESS",
  PENDING_ENGAGEMENTS_FAILURE: "PENDING_ENGAGEMENTS_FAILURE",

  ENGAGEMENTS_BY_ORDER_LOADING: "ENGAGEMENTS_BY_ORDER_LOADING",
  ENGAGEMENTS_BY_ORDER_SUCCESS: "ENGAGEMENTS_BY_ORDER_SUCCESS",
  ENGAGEMENTS_BY_ORDER_FAILURE: "ENGAGEMENTS_BY_ORDER_FAILURE",

  ENGAGEMENTS_BY_CASE_LOADING: "ENGAGEMENTS_BY_CASE_LOADING",
  ENGAGEMENTS_BY_CASE_SUCCESS: "ENGAGEMENTS_BY_CASE_SUCCESS",
  ENGAGEMENTS_BY_CASE_FAILURE: "ENGAGEMENTS_BY_CASE_FAILURE",

  UPDATE_ENGAGEMENT_LOADING: "UPDATE_ENGAGEMENT_LOADING",
  UPDATE_ENGAGEMENT_SUCCESS: "UPDATE_ENGAGEMENT_SUCCESS",
  UPDATE_ENGAGEMENT_FAILURE: "UPDATE_ENGAGEMENT_FAILURE",
};
