import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { MONTHLY_TARGET_API } from "../../constants/apiUrls";
import { fetchRequest } from "../../helpers/fetchRequest";

import { Card, Progress, Typography } from "antd";
const { Text, Title } = Typography;

const MonthlyTarget = () => {
	const [loading, setLoading] = useState(false);
	const [monthlyTarget, setMonthlyTarget] = useState({});

	// fetch monthly target
	const getMonthlyTarget = useCallback(async () => {
		let res = {};
		setLoading(true);

		try {
			const start = moment().startOf("isoWeek").toISOString();
			const end = moment().endOf("isoWeek").toISOString();

			res = await fetchRequest(MONTHLY_TARGET_API(start, end));
		} catch (error) {
			console.warn(error.message);
		} finally {
			if (res.hasError) setMonthlyTarget({});
			else setMonthlyTarget(res.data);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		getMonthlyTarget();
	}, [getMonthlyTarget]);
	// Ends

	const progressCount = useMemo(() => {
		if (isEmpty(monthlyTarget)) return 0.0;
		return parseFloat(
			(monthlyTarget.fullfilled_calls / monthlyTarget.target_calls) * 100
		).toFixed(2);
	}, [monthlyTarget]);

	return (
		<div>
			<Title level={3}>
				Weekly Target ({moment().startOf("isoWeek").format("DD-MMM")} -{" "}
				{moment().endOf("isoWeek").format("DD-MMM")})
			</Title>

			<Card loading={loading}>
				<div className="grid gap-2 grid-cols-2">
					<Card title={<Title level={5}>Calls</Title>}>
						<div className="flex mb-2 gap-2 items-center">
							<Text strong>Target : </Text>
							<Text>{monthlyTarget?.target_calls}</Text>
						</div>

						<div className="flex gap-2 items-center">
							<Text strong>Completed : </Text>
							<Text>{monthlyTarget?.fullfilled_calls}</Text>
						</div>
					</Card>

					<Card title={<Title level={5}>Orders</Title>}>
						<div className="flex mb-2 gap-2 items-center">
							<Text strong>Target : </Text>
							<Text>{monthlyTarget?.target_orders}</Text>
						</div>

						<div className="flex gap-2 items-center">
							<Text strong>Completed : </Text>
							<Text>{monthlyTarget?.fullfilled_orders}</Text>
						</div>
					</Card>
				</div>

				<div className="mt-4">
					<Progress percent={isNaN(progressCount) ? 0.0 : progressCount} />
				</div>
			</Card>
		</div>
	);
};

export default MonthlyTarget;
