export const leadDespositionMapper = key => feedbackMap[key] || [];

export const leadDespositionFeedbacks = [
	{
		label: "Cold",
		value: "cold",
	},
	{
		label: "Dropped",
		value: "dropped",
	},
	{
		label: "Hot",
		value: "hot",
	},
	{
		label: "Not Connected",
		value: "not_connected",
	},
];

const feedbackMap = {
	cold: [
		{
			label: "Busy Call Later",
			value: "Busy Call later",
		},
		{
			label: "Language Barrier",
			value: "Language Barrier",
		},
		{
			label: "Needs Time",
			value: "Needs Time",
		},
		{
			label: "Trust Issue",
			value: "Trust Issue",
		},
		{
			label: "Doctor Consult",
			value: "Doctor Consult",
		},
	],

	dropped: [
		{
			label: "Not interested",
			value: "Not interested",
		},
		{
			label: "Treatment not possible",
			value: "Treatment not possible",
		},
		{
			label: "Future Prospect",
			value: "Future Prospect",
		},
		{
			label: "Price Issue",
			value: "Price Issue",
		},
		{
			label: "Trust Issue",
			value: "Trust Issue",
		},
		{
			label: "Existing customer",
			value: "Existing customer",
		},
		{
			label: "AA Advance Case",
			value: "AA Advance Case",
		},
	],

	hot: [
		{
			label: "Order Expected",
			value: "Order Expected",
		},
		{
			label: "No Response",
			value: "No Response",
		},
	],

	not_connected: [
		{
			label: "Not answered",
			value: "Not answered",
		},
		{
			label: "Number busy",
			value: "Number busy",
		},
		{
			label: "Switched off",
			value: "Switched off",
		},
		{
			label: "Out of network",
			value: "Out of network",
		},
		{
			label: "Wrong Number",
			value: "Wrong Number",
		},
		{
			label: "Number Invalid",
			value: "Number Invalid",
		},
	],
};
