import { Button, Form, Input, Modal, Radio, Row, Select } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCurrentUserRole } from "../helpers";
import { leadDespositionMapper } from "../managers/caseManager";
import { markReminderDone } from "../pages/dashboard/service";
import { postFeedback } from "../pages/leadDetails/service";
const { TextArea } = Input;

const MarkDoneModal = ({
	modalVisible,
	reminderId,
	reminderType,
	refreshUI,
	setModalVisible,
}) => {
	const params = useParams();
	const [form] = Form.useForm();
	const [comment, setComment] = useState("");
	const [userRole] = useState(() => getCurrentUserRole());
	const [feedbackType, setFeedbackType] = useState("");
	const [showFields, setShowFields] = useState({});

	const { leadStatus } = useSelector(state => ({
		leadStatus: window.getValue(state, "leaddetails.usersDetail.status"),
	}));

	const optionsFeedback = useMemo(() => {
		if (isEmpty(leadStatus)) return null;
		return leadDespositionMapper(leadStatus);
	}, [leadStatus]);

	// Calling API on saving the comments

	const onSubmit = async () => {
		if (userRole === "sales" && reminderType === "system") {
			const _data = {
				feedback: feedbackType === "unanswered" ? "unanswered" : comment,
				reminder_id: reminderId,
			};

			const _markDoneRes = await _markReminderDone();
			if (_markDoneRes.status !== 200) return;

			const _postFeedbackRes = await postFeedback(_data, params.id);
			if (_postFeedbackRes.status !== 200) return;
		} else {
			const _markDoneRes = await _markReminderDone();
			if (_markDoneRes.status !== 200) return;
		}

		refreshUI();
		handleModalClose();
	};

	const _markReminderDone = async () => {
		const _data = {
			comment:
				reminderType +
				" : " +
				(feedbackType === "unanswered" ? "unanswered" : comment),
			isAnswered: feedbackType === "unanswered" ? "false" : "true",
		};

		return await markReminderDone(_data, reminderId);
	};

	const handleModal = () => setModalVisible(false);

	const handleModalClose = () => {
		setComment("");
		handleModal();
	};

	const handleShowFields = (name, value) => {
		setShowFields(curr => {
			const _curr = curr;
			_curr[name] = value;
			return { ..._curr };
		});
	};

	const handleFeedbackTypeChange = ({ target }) => {
		setFeedbackType(target.value);
		setShowFields({});
		form.resetFields();

		if (target.value === "unanswered") {
			handleShowFields("saveBtn", true);
			handleShowFields("comment", false);
		} else if (target.value === "answered") {
			handleShowFields("comment", true);
			handleShowFields("saveBtn", true);
		}
	};

	return (
		<Modal
			visible={modalVisible}
			title="Mark Done"
			onOk={handleModal}
			onCancel={handleModalClose}
			width={600}
			footer={[
				<Button key="back" onClick={handleModalClose}>
					Cancel
				</Button>,
			]}>
			<Row justify="center">
				<Radio.Group
					optionType="button"
					buttonStyle="outline"
					size="large"
					onChange={handleFeedbackTypeChange}
					value={feedbackType}>
					<Radio.Button value="answered">Answered</Radio.Button>
					<Radio.Button value="unanswered">Didn't answer</Radio.Button>
				</Radio.Group>
			</Row>

			<Row justify="center" style={{ marginTop: 16 }}>
				{reminderType === "system" && (
					<Select
						placeholder="Please Select"
						options={optionsFeedback}
						style={{ width: 240 }}
						value={isEmpty(comment) ? null : comment}
						onChange={e => setComment(e)}
					/>
				)}

				<Form layout="vertical" onFinish={onSubmit}>
					{showFields.comment && (
						<Form.Item
							label="Comment"
							name="comment"
							rules={[
								{ required: true, message: "Please fill this in" },
								{ whitespace: true, message: "Please fill this in" },
							]}>
							<TextArea
								onChange={e => setComment(e.target.value)}
								placeholder={"Please write comment"}
								rows={4}
								style={{ width: 320 }}
							/>
						</Form.Item>
					)}

					{showFields.saveBtn && (
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					)}
				</Form>
			</Row>
		</Modal>
	);
};

export default MarkDoneModal;
