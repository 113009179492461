import { Col, Row, Table, Tag, Tooltip, Typography } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { OrderFilters } from "../../../common";
import { getSubscriptionPlatform } from "../../../constants/orderSubscriptionPlatforms";
import {
	generateQuery,
	getLineItemsName,
	getQueryParams,
	getSearchParams,
	getValue,
} from "../../../helpers";
import { getAllOrders, getMedicalReps } from "../service";
import AssignMedicalRep from "./AssignMedicalRep";
const { Text, Title } = Typography;

function AllOrders() {
	const history = useHistory();
	const [allSelectedOrders, setAllSelectedOrders] = useState([]);
	const [data, setData] = useState([]);
	const [pageNo, setPageNo] = useState(getQueryParams("pageNumber") || 1);
	const [paginationInfo, setPaginationInfo] = useState({});
	const [queryParams, setQueryParams] = useState(getSearchParams);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [selectionFromOtherPage, setSelectionFromOtherPage] = useState([]);

	useEffect(() => {
		getMedicalReps();
	}, []);

	useEffect(() => {
		const filterItems = [{ pageNumber: pageNo }];
		setQueryParams(generateQuery(filterItems));
	}, [pageNo]);

	useEffect(() => {
		history.push({ search: queryParams });
		getAllOrders(queryParams);
	}, [history, queryParams]);

	const { orders, loading } = useSelector(state => ({
		orders: getValue(state, "orderManagement.getAllOrdersData"),
		loading: getValue(state, "orderManagement.getAllOrdersLoading"),
	}));

	useEffect(() => {
		if (isEmpty(orders)) setData([]);
		else {
			const ndata = orders.data.map(row => ({
				key: getValue(row, "id"),
				case_id: getValue(row, "case_id"),
				allOrderName: getValue(row, "case.user.first_name"),
				ghostOrderName: getValue(row, "order_meta.customer.first_name"),
				phone_Number: getValue(row, "case.user.phone_number"),
				ghost_phone_Number: getValue(row, "order_phone_number"),
				orderDisplayId: getValue(row, "order_display_id"),
				orderName: getLineItemsName(row.order_meta?.line_items),
				orderStatus: getValue(row, "status"),
				orderDate: getValue(row, "created_at"),
				count: getValue(row, "count"),
				deliveryDate: getValue(row, "delivery_date"),
				is_subscription: getValue(row, "is_subscription_order"),
				is_bulk_order: getValue(row, "is_bulk_order"),
				is_combo: getValue(row, "is_combo"),
				bulk_order_duration: getValue(row, "bulk_order_duration"),
				is_single_order: getValue(row, "is_single_order"),
				order_type: getValue(row, "order_type"),
				assignedTo: getValue(row, "assignment.first_name"),
				owner: getValue(row, "case.assignment.first_name"),
				salesRep: getValue(row, "case.salesRep.first_name"),
				order_meta: getValue(row, "order_meta"),
				sub_status: getValue(row, "sub_status"),
			}));
			setData(ndata);
			setPaginationInfo(orders.pagination);
		}
	}, [orders]);

	useEffect(() => {
		if (!isEmpty(allSelectedOrders)) {
			let currPageData = [];
			let currPageKeys = [];
			let otherPageData = [];
			allSelectedOrders.map(row => {
				if (data.some(i => i.key === row.key)) {
					currPageData = [...currPageData, row];
					currPageKeys = [...currPageKeys, row.key];
					return currPageKeys;
				} else {
					return (otherPageData = [...otherPageData, row]);
				}
			});
			setSelectedKeys(currPageKeys);
			setSelectionFromOtherPage(otherPageData);
		}
	}, [allSelectedOrders, data]);

	const updateUi = () => {
		getAllOrders(queryParams);
		clearSelection();
	};

	const clearSelection = () => {
		setAllSelectedOrders([]);
		setSelectedKeys([]);
		setSelectionFromOtherPage([]);
	};

	const rowSelection = {
		selectedRowKeys: selectedKeys,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllSelectedOrders([...selectionFromOtherPage, ...selectedRows]);
			setSelectedKeys(selectedRowKeys);
		},
	};

	const columns = [
		{
			title: "Name",
			key: "name",
			render: record => {
				if (record.orderStatus !== "ghost") {
					let subscriptionPlatform = "";
					if (record.is_subscription) {
						subscriptionPlatform = getSubscriptionPlatform(record.order_meta);
						subscriptionPlatform = subscriptionPlatform
							? `(${subscriptionPlatform})`
							: "";
					}

					return (
						<div className="flex flex-col">
							<Link className="link" to={`/lead-details/${record.case_id}`}>
								{record.allOrderName}
							</Link>

							<Text type="secondary">
								{isEmpty(record.phone_Number)
									? record.ghost_phone_Number
									: record.phone_Number}
							</Text>

							<span>
								{record.is_subscription && (
									<Tooltip
										placement="bottom"
										title={`Subscription Order ${subscriptionPlatform}`}>
										<Tag color="green">S</Tag>
									</Tooltip>
								)}

								{record.is_bulk_order && (
									<Tooltip
										placement="bottom"
										title={`Bulk Order (${record.bulk_order_duration} months)`}>
										<Tag color="green">B</Tag>
									</Tooltip>
								)}

								{record.is_single_order && (
									<Tooltip placement="bottom" title="Single Order">
										<Tag color="green">SO</Tag>
									</Tooltip>
								)}
								{record.is_combo && (
									<Tooltip placement="bottom" title="Combo Order">
										<Tag color="green">C</Tag>
									</Tooltip>
								)}
							</span>
						</div>
					);
				} else {
					return <Text>{record.ghostOrderName}</Text>;
				}
			},
		},
		{
			title: "Order ID",
			dataIndex: "orderDisplayId",
			key: "orderDisplayId",
			render: (orderDisplayId, record) => (
				<div className="grid justify-start gap-1">
					<Text>{orderDisplayId}</Text>
					<Tag className="uppercase" color="green">
						{record.order_type?.replaceAll("_", " ")}
					</Tag>
				</div>
			),
		},
		{
			title: "Order Name",
			dataIndex: "orderName",
			key: "orderName",
			width: 240,
		},
		{
			title: "New / Repeat",
			dataIndex: "count",
			key: "count",
			render: (count, key) => {
				if (count <= 1)
					return (
						<Tag color="gold" key={key}>
							NEW
						</Tag>
					);
				else
					return (
						<Tag color="green" key={key}>
							REPEAT ORDER {count}
						</Tag>
					);
			},
		},
		{
			title: "Order Status",
			dataIndex: "orderStatus",
			key: "orderStatus",
			width: 112,
			render: (status, record) => (
				<>
					<Tag key={status} color="geekblue">
						{status && status.toUpperCase()} {""}
						{status === "delivered" &&
							!isEmpty(record.deliveryDate) &&
							moment(record.deliveryDate).format("DD-MMM-YYYY")}
					</Tag>
					{record?.sub_status && (
						<Tag color="geekblue">
							{record.sub_status.toUpperCase()} {""}
						</Tag>
					)}
				</>
			),
		},
		{
			title: "Order Date",
			dataIndex: "orderDate",
			key: "orderDate",
			width: 144,
			render: orderDate => moment(orderDate).format("DD MMM YYYY, h:mm a"),
		},
		{
			title: "Assigned To",
			dataIndex: "assignedTo",
			key: "assignedTo",
		},
		{
			title: "Owner",
			dataIndex: "owner",
			key: "owner",
		},
		{
			title: "Sales Rep",
			dataIndex: "salesRep",
			key: "salesRep",
		},
	];

	return (
		<>
			<Row>
				<Col>
					<AssignMedicalRep
						selectedOrders={allSelectedOrders}
						updateUi={updateUi}
					/>
				</Col>
			</Row>

			<Row className="top-margin">
				<Col span={24}>
					<Title level={2}>All Orders</Title>
				</Col>
			</Row>

			<Row justify="end">
				<Col>
					<OrderFilters
						optionsStatus={optionsStatus}
						setPageNo={setPageNo}
						setQueryParams={setQueryParams}
						clearSelection={clearSelection}
					/>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Table
						size="small"
						columns={columns}
						dataSource={data}
						loading={loading}
						onChange={e => setPageNo(e.current)}
						pagination={{
							current: pageNo,
							showSizeChanger: false,
							total: paginationInfo.rowCount,
						}}
						rowSelection={rowSelection}
					/>
				</Col>
			</Row>
		</>
	);
}

export default AllOrders;

const optionsStatus = [
	{ label: "Delivered", value: "delivered" },
	{ label: "Ghost", value: "ghost" },
	{ label: "In Progress", value: "in_progress" },
	{ label: "Pending", value: "pending" },
	{ label: "Pending Prescription", value: "pending_prescription" },
	{ label: "Shipped", value: "shipped" },
	{ label: "Shipping Pending", value: "shipping_pending" },
	{ label: "Unknown", value: "unknown" },
	{ label: "Void", value: "void" },
];
